import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
  LABEL_POSITION_TOP,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import { Fragment } from "react";
import { Transition, Dialog, Menu } from "@headlessui/react";
import { XIcon, DotsHorizontalIcon } from "@heroicons/react/outline";
import toast, { Toaster } from "react-hot-toast";
import { isViewAllDetailsModalOpen } from "../actions";

function ViewDetails(props) {
  const history = useHistory();
  const cancelButtonRef = useRef(null);
  const {
    session,
    isLoggedIn,
    localLanguage,
    selectedProduct,
    productViewAll,
    selectedVariant,
  } = props;
  const [viewMore, setViewMore] = useState(true);
  const closeSlideOvers = () => {
    props.isViewAllDetailsModalOpen(false);
  };
  const moreView = () => {
    setViewMore(!viewMore);
  };
  //console.log("selectedProduct", selectedProduct);
  let productDetails = "";

  if (selectedProduct && selectedProduct.product_desc_en) {
    productDetails = ProductTextLocalized(
      selectedProduct.product_desc_en,
      selectedProduct.product_desc_ar,
      localLanguage
    );
  }
  return (
    <>
      <Transition.Root show={productViewAll} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          initialFocus={cancelButtonRef}
          open={productViewAll}
          onClose={() => closeSlideOvers()}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="bg-white h-full flex flex-col  shadow-xl">
                    <div className="min-h-0 flex-1 flex flex-col pt-3 overflow-y-scroll">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="slider-modal-headtext">
                            <h2
                              id="slide-over-heading"
                              className="text-xl font-normal text-vendorLink"
                            >
                              {LocaleStrings.product_specification}
                            </h2>
                          </Dialog.Title>
                          <div
                            className="ml-3 h-7 flex items-center"
                            ref={cancelButtonRef}
                          >
                            <button
                              onClick={() => closeSlideOvers()}
                              className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-1 relative flex-1 px-4 sm:px-6">
                        <div className="pt-7">
                          <div>{LocaleStrings.product_desc}</div>
                          <div
                            className="mt-1 text-left text-sm text-primary font-normal"
                            id="view_details"
                          >
                            {viewMore
                              ? productDetails.substr(0, 200)
                              : productDetails}
                            ...
                            <a
                              className="text-vendorLink cursor-pointer"
                              onClick={() => moreView()}
                            >
                              {viewMore
                                ? LocaleStrings.readmore
                                : LocaleStrings.readless}
                            </a>
                          </div>
                          <div className="mt-8 mb-3">
                            {selectedProduct.categories &&
                            selectedProduct.categories.length > 0 ? (
                              <div className="flex">
                                <div className="text-cutoff font-normal text-sm">
                                  {LocaleStrings.category}:
                                </div>
                                <div className="text-primary  font-normal text-sm pl-3">
                                  {ProductTextLocalized(
                                    selectedProduct.categories[0]
                                      .categoryname_en,
                                    selectedProduct.categories[0]
                                      .categoryname_ar,
                                    localLanguage
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {selectedProduct.subcategories &&
                            selectedProduct.subcategories.length > 0 ? (
                              <div className="flex">
                                <div className="text-cutoff font-normal text-sm">
                                  {LocaleStrings.sub_category}:
                                </div>
                                <div className="text-primary  font-normal text-sm pl-3">
                                  {ProductTextLocalized(
                                    selectedProduct.subcategories[0]
                                      .subcategoryname_en,
                                    selectedProduct.subcategories[0]
                                      .subcategoryname_ar,
                                    localLanguage
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {selectedProduct.product_condition &&
                            selectedProduct.product_condition.condition_en ? (
                              <div className="flex">
                                <div className="text-cutoff font-normal text-sm">
                                  {LocaleStrings.condition}:
                                </div>
                                <div className="text-primary  font-normal text-sm pl-3">
                                  {ProductTextLocalized(
                                    selectedProduct.product_condition
                                      .condition_en,
                                    selectedProduct.product_condition
                                      .condition_ar,
                                    localLanguage
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {selectedVariant && selectedVariant.length > 0 ? (
                              <div className="flex">
                                <div className="text-cutoff font-normal text-sm">
                                  {LocaleStrings.selected_variant}:
                                </div>
                                <div className="text-primary  font-normal text-sm pl-3">
                                  {ProductTextLocalized(
                                    selectedVariant[0].name_en,
                                    selectedVariant[0].name_ar,
                                    localLanguage
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="mt-3">
                            {LocaleStrings.product_spec}
                          </div>
                          <div
                            className="mt-1 text-left text-sm text-primary font-normal"
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            {ProductTextLocalized(
                              selectedProduct.specification_desc_en,
                              selectedProduct.specification_desc_ar,
                              localLanguage
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const validate = (values, ownProps) => {
  var errors = {};

  return errors;
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    localLanguage: state.localLanguage,
    productViewAll: state.productViewAll,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ isViewAllDetailsModalOpen }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "QuestionForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ViewDetails)
);
