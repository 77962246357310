import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import { fetchSettings } from "../../../actions/";
import _ from "lodash";

function BreadCrumb(props) {
  const history = useHistory();
  const location = useLocation();
  const { localLanguage, selectedProduct } = props;

  const redirect = (page) => {
    history.push(page);
    window.scrollTo(0, 0);
  };

  const viewCategory = (categoryid) => {
    props.fetchSettings(props.session, (res) => {});
    // history.push("/view-category/" + categoryid);
  };

  const renderSub = (subcategories) => {
    let subArr = [];
    _.map(subcategories, (obj, index) => {
      subArr.push(
        ProductTextLocalized(
          obj.subcategoryname_en,
          obj.subcategoryname_ar,
          localLanguage
        )
      );
    });

    return subArr.join(", ");
  };

  //console.log("selectedProduct", selectedProduct);
  return (
    <div className="text-left text-primary text-xs">
      <a href="/" className="cursor-pointer">
        Home
      </a>
      &nbsp; /&nbsp;
      {location &&
      location.state &&
      location.state.from &&
      location.state.pagetitle &&
      location.state.from != "/" ? (
        <>
          <a
            className="cursor-pointer"
            //onClick={() => redirect("/" + location.state.from)}
            href={`/${location.state.from}`}
          >
            {location.state.pagetitle}
          </a>
          &nbsp; /&nbsp;
        </>
      ) : (
        ""
      )}
      {selectedProduct &&
      selectedProduct.categories &&
      selectedProduct.categories.length > 0 ? (
        <a
          className="cursor-pointer"
          onClick={() => viewCategory(selectedProduct.categories[0].id)}
          href={`/view-category/${selectedProduct.categories[0].id}`}
        >
          {ProductTextLocalized(
            selectedProduct.categories[0].categoryname_en,
            selectedProduct.categories[0].categoryname_ar,
            localLanguage
          )}
        </a>
      ) : (
        ""
      )}
      &nbsp; /&nbsp;
      {selectedProduct &&
      selectedProduct.subcategories &&
      selectedProduct.subcategories.length > 0
        ? renderSub(selectedProduct.subcategories)
        : ""}
    </div>
  );
}
const mapStateToProps = (state) => ({
  // isLoggedIn: state.isLoggedIn,
  // session: state.session
  localLanguage: state.localLanguage,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchSettings }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumb);
