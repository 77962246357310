import axios from "axios";
import _ from "lodash";

import { INSTANCE_URL, APP_API_KEY } from "../../../components/Common/constant";

import {
  getRequestAccess,
  postRequestAccess,
  deleteRequest,
  getRequest,
  postRequest,
  patchRequest,
  postRequestWithoutKey,
} from "../../../components/Common/network-call";
import { getHeaders } from "../../../components/Common/common-utils";

export const IS_COUPON_MODAL_OPEN = "IS_COUPON_MODAL_OPEN";
export const COUPON_LIST = "COUPON_LIST";
export const APPLIED_COUPON = "APPLIED_COUPON";
export const COUNTRY_LIST = "COUNTRY_LIST";
export const DEFAULT_COUNTRY = "DEFAULT_COUNTRY";
export const USER_ALL_ADDRESS = "USER_ALL_ADDRESS";
export const IS_CHANGE_ADDRESS_MODAL_OPEN = "IS_CHANGE_ADDRESS_MODAL_OPEN";
export const IS_NEW_ADDRESS_MODAL_OPEN = "IS_NEW_ADDRESS_MODAL_OPEN";
export const ZONE_LIST = "ZONE_LIST";

export function updateQuantity(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/updatequantity`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.result == "failed") {
          callback({ success: 0 });
        } else {
          callback({ success: 1 });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function togglecartSelection(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/togglecartselection`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function deleteCartItem(session, cartid, callback) {
  var deleteUrl = `${INSTANCE_URL}/api/v2/shopez/_table/usercart/${cartid}`;
  return (dispatch) => {
    deleteRequest(
      deleteUrl,
      session,
      dispatch,
      (delres) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function isCouponlistModalOpen(isOpen) {
  return { type: IS_COUPON_MODAL_OPEN, payload: isOpen };
}

export function availableCoupons(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/availablecoupons`;

  return (dispatch) => {
    postRequest(
      url,
      null,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: COUPON_LIST,
          payload: {
            data: response.coupons,
          },
        });
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function applyCoupon(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/applycoupon`;
  //let values = { couponid: couponid, currencyrate: currencyrate };
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.error_code) {
          callback({ success: 0, errorcode: response.error_code });
        } else {
          dispatch({
            type: APPLIED_COUPON,
            payload: {
              data: response.coupon,
            },
          });
          callback({ success: 1 });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function applyCouponCode(session, code, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/getcouponfromcode`;
  let values = { code: code };
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.result == "success") {
          callback({ success: 1, coupon: response.coupon });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function removeCouponCode(session, couponid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/removecoupon`;
  let values = { couponid: couponid, couponcode: "" };
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.result == "success") {
          callback({ success: 1, coupon: response.coupon });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function setdefaultCountry(countrycode) {
  return { type: DEFAULT_COUNTRY, payload: countrycode };
}

export function fetchCountry(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/country`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        //console.log("data", data.resource);
        dispatch({ type: COUNTRY_LIST, payload: data.resource });
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
}

export function isChangeAddressModal(isOpen) {
  return { type: IS_CHANGE_ADDRESS_MODAL_OPEN, payload: isOpen };
}

export function getUserAddressAll(session, callback) {
  var filter = encodeURI(`(useridfk=${session.userid})`);

  var url = `${INSTANCE_URL}/api/v2/shopez/_table/user_address?related=zone_master_by_zoneno&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        //console.log("data", data.resource);
        dispatch({ type: USER_ALL_ADDRESS, payload: data.resource });
        callback({ success: 1, data: data.resource });
      },
      (error) => {}
    );
  };
}

export function updateCart(session, resource, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/usercart`;
  var body = {
    resource: resource,
  };
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function makeDefaultAddress(session, values, id, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/user_address/${id}`;
  var filter = encodeURI(`(useridfk=${session.userid})`);
  var urlall = `${INSTANCE_URL}/api/v2/shopez/_table/user_address?filter=(${filter})`;
  var body = {
    resource: [{ defaultaddress: 0 }],
  };
  return (dispatch) => {
    patchRequest(
      urlall,
      body,
      session,
      dispatch,
      (response) => {
        patchRequest(
          url,
          values,
          session,
          dispatch,
          (response) => {
            callback({ success: 1 });
          },
          (error) => {
            callback({ success: 0, message: error });
          }
        );
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function useOnceAddress(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/addressactions`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.result == "success") {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function isNewAddressModalOpen(isOpen) {
  return { type: IS_NEW_ADDRESS_MODAL_OPEN, payload: isOpen };
}

export function createOrder(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/createorder`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (
          response.success &&
          response.checkout_url
        ) {
          callback({ success: 1, checkout_url: response.checkout_url });
        } else if(response.success){
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function paymentBypass(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendemail/paymentbypass`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.success == true) {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function makePayment(session, values, callback) {
  var url = `https://shopez.teks.co.in/payment/pay.php`;
  return (dispatch) => {
    postRequestWithoutKey(
      url,
      values,
      null,
      dispatch,
      (response) => {
        console.log("response", response);
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function fetchZone(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/zone_master?flter=(isactive=1)`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        //console.log("data", data.resource);
        dispatch({ type: ZONE_LIST, payload: data.resource });
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
}

export function getPartnerPrice(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/partnerscript/calculateprice`;
  return (dispatch) => {
    postRequest(
      url,
      {},
      session,
      dispatch,
      (response) => {
        if (response.result == "success") {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}
