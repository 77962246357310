import axios from "axios";
import _ from "lodash";

import { INSTANCE_URL, APP_API_KEY } from "../../../components/Common/constant";

import {
  getRequestAccess,
  postRequestAccess,
  deleteRequest,
  getRequest,
  postRequest,
  patchRequest,
} from "../../../components/Common/network-call";
import { getHeaders } from "../../../components/Common/common-utils";

export const SELLER_PROFILE = "SELLER_PROFILE";
export const STORE_MAP_MODAL_OPEN = "STORE_MAP_MODAL_OPEN";
export const SELLER_PROFILE_SEARCH = "SELLER_PROFILE_SEARCH";
export const IS_SELLER_SHARE_MODAL_OPEN = "IS_SELLER_SHARE_MODAL_OPEN";

export function emptyTopItems(callback) {
  return (dispatch) => {
    dispatch({
      type: SELLER_PROFILE,
      payload: {},
    });
  };
}

export function fetchSellerProfile(session, sellerid, sortby = "", callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/sellerprofile`;
  var params = { sellerid: parseInt(sellerid) };
  if (sortby != "") {
    params.sortby = sortby;
  }

  if (session) {
    return (dispatch) => {
      postRequest(
        url,
        params,
        session,
        dispatch,
        (response) => {
          dispatch({
            type: SELLER_PROFILE,
            payload: {
              data: response.data,
            },
          });
          callback({ success: 1 });
        },
        (error) => {
          //callback({success: 0, error: error});
        }
      );
    };
  } else {
    return (dispatch) => {
      postRequestAccess(
        url,
        params,
        null,
        dispatch,
        (response) => {
          dispatch({
            type: SELLER_PROFILE,
            payload: {
              data: response.data,
            },
          });
          callback({ success: 1 });
        },
        (error) => {
          //callback({success: 0, error: error});
        }
      );
    };
  }
}

export function isStoreInfoMapModalOpen(isOpen) {
  return { type: STORE_MAP_MODAL_OPEN, payload: isOpen };
}

export function emptySellerSearch(callback) {
  return (dispatch) => {
    dispatch({
      type: SELLER_PROFILE_SEARCH,
      payload: {},
    });
  };
}

export function fetchSellerSearch(session, profileid, searchterm, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/searchitemsofseller`;
  var params = { profileid: parseInt(profileid), searchterm: searchterm };
  return (dispatch) => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: SELLER_PROFILE_SEARCH,
          payload: {
            data: response.data,
          },
        });
        callback({ success: 1 });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function isSellerShareModalOpen(isOpen) {
  return { type: IS_SELLER_SHARE_MODAL_OPEN, payload: { showModal: isOpen } };
}
