import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  SMALL_DATE_FORMAT,
} from "../../../components/Common/constant";
import PaginationIcon from "../../../assets/images/pagination.svg";
import AskQLogo from "../../../assets/images/qaicon.svg";
import SearchIcon from "../../../assets/images/search.svg";
import RightArrow from "../../../assets/images/right-arrow.svg";
import { Fragment } from "react";
import { Transition, Dialog, Menu } from "@headlessui/react";
import { XIcon, DotsHorizontalIcon } from "@heroicons/react/outline";
import {
  askQuestionModal,
  questionListByProduct,
  questionListProductStore,
  isQuestionlistModalOpen,
  openReportModal,
} from "../actions";
import AskQuestion from "./ask-question";
import QuestionList from "./question-list";
import toast, { Toaster } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";

function QuestionAnswer(props) {
  const history = useHistory();
  const {
    session,
    productid,
    selectedProduct,
    openAskQuestion,
    questionList,
    isQuestionListOpen,
    isLoggedIn,
    setQuestionData,
    questionData,
  } = props;
  const [pagenumber, setPagenumber] = useState(1);
  const [addNew, setAddNew] = useState(false);

  // let sortedData = [];
  // if (questionList && questionList.data && questionList.data.length > 0) {
  //   sortedData = _.orderBy(questionList.data, ["updatedat"], ["desc"]);
  // }

  const askQuestion = () => {
    //console.log("selectedProduct", selectedProduct);
    if (isLoggedIn) {
      let vendorid =
        selectedProduct && selectedProduct.store && selectedProduct.store.userid
          ? selectedProduct.store.userid
          : selectedProduct.store.createdby;

      props.askQuestionModal(true, productid, vendorid);
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  const questionListAll = () => {
    props.questionListByProduct(props.session, productid, (res) => {});
    props.isQuestionlistModalOpen(true);
  };

  const fetchData = (validflag, pagenumber) => {
    if (validflag) {
      props.questionListByProduct(session, productid, "", pagenumber, (res) => {
        if (res.data) {
          setQuestionData(res.data);
          setPagenumber(pagenumber);
        }
      });
    } else {
      props.questionListByProduct(
        session,
        productid,
        "",
        pagenumber + 1,
        (res) => {
          if (res.data) {
            let newArr = _.concat(questionData, res.data);
            setQuestionData(newArr);
            setPagenumber(pagenumber + 1);
          }
        }
      );
    }
  };

  const reportAbusive = (qaid, type) => {
    if (isLoggedIn) {
      confirmAlert({
        title:
          type == "question"
            ? LocaleStrings.report_confirm_question_title
            : LocaleStrings.report_confirm_answer_title,
        message:
          type == "question"
            ? LocaleStrings.report_confirm_question_message
            : LocaleStrings.report_confirm_answer_message,
        buttons: [
          {
            label: LocaleStrings.button_yes,
            onClick: () => {
              props.openReportModal(true, qaid, type);
            },
          },
          {
            label: LocaleStrings.button_no,
            onClick: () => {},
          },
        ],
      });
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  const handleSearch = (e) => {
    let keyword = e.target.value;
    // setSearch(keyword);
    if (keyword.length >= 2) {
      props.questionListByProduct(
        props.session,
        productid,
        keyword,
        1,
        (res) => {
          if (res.data) {
            setQuestionData(res.data);
            setPagenumber(1);
          }
        }
      );
    } else {
      props.questionListByProduct(
        props.session,
        productid,
        "",
        pagenumber,
        (res) => {
          if (res.data) {
            setQuestionData(res.data);
            setPagenumber(pagenumber);
          }
        }
      );
    }
  };

  //console.log("questionData", questionData);
  //console.log("questionList", questionList);
  return (
    <>
      <div className="py-4 text-left text-numbid font-bold text-base md:text-xl lg:text-22 border-t border-b border-header">
        {LocaleStrings.question_and_answer}
      </div>

      <>
        <div className="py-5">
          <div className="grid grid-cols-3 md:grid-cols-5 gap-6 md:gap-4">
            <div className="col-span-2 text-footers font-medium text-lg flex items-center">
              {`${questionList.totalrows} ${LocaleStrings.all_question_text}`}
            </div>
            <div className="col-span-1 flex items-center"></div>
            <div className="col-span-3 md:col-span-2 flex items-center">
              <div className="relative w-full text-ternary focus-within:text-secondary ">
                <input
                  id="search-field"
                  className="block bg-review_search border border-header w-full h-10 pl-2 pr-3 py-2 text-review_search_text placeholder-review_search_text focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                  placeholder={LocaleStrings.review_search_text}
                  type="search"
                  name="search"
                  //value={getSearchKey.key ? getSearchKey.key : ""}
                  onChange={(e) => handleSearch(e)}
                  //onKeyDown={(e) => keyPress(e)}
                />
                <div className="absolute inset-y-0 right-4 flex items-center cursor-pointer px-1">
                  <img
                    src={SearchIcon}
                    className="w-5"
                    //onClick={() => handleClick()}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="text-left divide-y divide-header">
            {questionData.length > 0 ? (
              questionData.map((qa, index) => {
                return (
                  <div className="flex pb-5 pt-27" key={qa.id}>
                    <div className="bg-qalogo rounded-full w-12 h-12 flex items-center justify-center">
                      <img src={AskQLogo} className="w-8" />
                    </div>
                    <div className="pl-15 align-top">
                      <div className="align-top text-footers text-base font-medium">
                        {qa.question}
                      </div>
                      <div className="text-search text-sm pt-2">
                        {moment(qa.createdat).format(SMALL_DATE_FORMAT)}
                      </div>
                      <div className="flex pt-4">
                        {qa.answer ? (
                          <>
                            <div className="text-footers text-base font-bold">
                              Answer :
                            </div>
                            <div className="text-footers text-base font-normal">
                              {qa.answer}
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        {qa.answer ? (
                          <div
                            className="w-wishdetailbox text-right pl-18"
                            key={qa.id}
                          >
                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              {({ open }) => (
                                <>
                                  <div className="">
                                    <Menu.Button className="btn-card">
                                      <span className="sr-only">
                                        Open options
                                      </span>
                                      <DotsHorizontalIcon
                                        className="h-5 w-5 hover:shadow"
                                        aria-hidden="true"
                                      />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      static
                                      className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer z-50"
                                    >
                                      <div className="py-1 divide-y z-50">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <a
                                              onClick={() =>
                                                reportAbusive(qa.id, "answer")
                                              }
                                              className={classNames(
                                                active ? "bg-quaternary " : " ",
                                                "block px-4 py-2 text-sm text-primary"
                                              )}
                                            >
                                              {LocaleStrings.report_as_abusive}
                                            </a>
                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </>
                              )}
                            </Menu>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {qa.askedby != session.userid ? (
                      <div className="w-wishdetailbox text-right" key={qa.id}>
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          {({ open }) => (
                            <>
                              <div className="">
                                <Menu.Button className="btn-card">
                                  <span className="sr-only">Open options</span>
                                  <DotsHorizontalIcon
                                    className="h-5 w-5 hover:shadow"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>

                              <Transition
                                show={open}
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items
                                  static
                                  className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer z-50"
                                >
                                  <div className="py-1 divide-y z-50">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          onClick={() =>
                                            reportAbusive(qa.id, "question")
                                          }
                                          className={classNames(
                                            active ? "bg-quaternary " : " ",
                                            "block px-4 py-2 text-sm text-primary"
                                          )}
                                        >
                                          {LocaleStrings.report_as_abusive}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </>
                          )}
                        </Menu>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })
            ) : (
              <h1 className="text-base text-secondary truncate">
                {LocaleStrings.no_question_found}
              </h1>
            )}
          </div>

          {questionList.totalrows > questionData.length ? (
            <div className="mt-5 border-t border-header ">
              <div
                className="text-center pt-md pb-md cursor-pointer"
                onClick={() => fetchData(false, pagenumber)}
              >
                <div className="flex items-center justify-center">
                  <div className="pr-3">
                    <img src={PaginationIcon} className=" w-3" />
                  </div>
                  <div className="text-link text-base font-medium">
                    {LocaleStrings.view_all_question_link}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </>

      <div className="text-left pt-2.5 text-numbid font-medium text-lg">
        {LocaleStrings.question_and_answer_subheading}
      </div>
      <div className="text-left pt-2.5">
        <button
          className="px-5 py-2 bg-white border border-variantSel text-vendorLink rounded font-bold text-base btn-light"
          onClick={() => askQuestion()}
        >
          {LocaleStrings.ask_question_btn}
        </button>
      </div>

      {openAskQuestion && openAskQuestion.showModal ? (
        <AskQuestion
          setQuestionData={setQuestionData}
          setPagenumber={setPagenumber}
        />
      ) : (
        ""
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  openAskQuestion: state.openAskQuestion,
  questionList: state.questionList,
  isQuestionListOpen: state.isQuestionListOpen,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      askQuestionModal,
      questionListByProduct,
      questionListProductStore,
      isQuestionlistModalOpen,
      openReportModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(QuestionAnswer);
