import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index";

// Import BaseLoader
import Loader from "../../../components/Common/loader";

// Import Component
import SuggestionEdit from "./suggestionedit";

class AppSuggestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loaderDesc: LocaleStrings.preparing_app_suggestions,
    };

    // All Binded Functions
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <Loader />
          </>
        ) : (
          <SuggestionEdit />
        )}
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default connect(mapStateToProps, {})(AppSuggestions);
