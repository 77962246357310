import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
  LABEL_POSITION_TOP,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
  currencyRate,
} from "../../../components/Common/product-model";
import Loader from "../../../components/Common/loader";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import toast, { Toaster } from "react-hot-toast";
import CouponBg from "../../../assets/images/coupon-bg.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  isCouponlistModalOpen,
  applyCoupon,
  applyCouponCode,
} from "../actions";
import { fetchCart } from "../../Product/actions";

function CouponList(props) {
  const history = useHistory();
  const {
    session,
    isLoggedIn,
    localLanguage,
    couponModal,
    getCoupon,
    totalCal,
    localCurrency,
  } = props;
  //console.log("totalCal", totalCal);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const closeSlideOvers = () => {
    props.isCouponlistModalOpen(false);
  };

  const applycoupon = (item) => {
    //console.log("item", item);
    let validflag = true;
    let min_cart_value = 0;
    // if (
    //   item.criteria &&
    //   item.criteria.rule &&
    //   item.criteria.rule == "min_cart_val"
    // ) {
    //   min_cart_value = item.min_cart_value;
    //   //console.log("min_cart_value", min_cart_value);
    //   if (totalCal < min_cart_value) {
    //     validflag = false;
    //   } else {
    //     validflag = true;
    //   }
    // } else {
    //   validflag = true;
    // }
    //console.log("validflag", validflag);
    if (validflag) {
      let currencyrate = currencyRate(localCurrency);
      props.applyCoupon(session, item.id, currencyrate, (response) => {
        if (response.success == 1) {
          props.fetchCart(session, (res) => {
            setCode("");
            setLoading(false);
            closeSlideOvers();
          });
          toast.success(LocaleStrings.cart_coupon_applied_success);
        } else {
          if (response.errorcode == "error_01") {
            toast.error(LocaleStrings.cart_coupon_error1);
          } else if (response.errorcode == "error_02") {
            toast.error(LocaleStrings.cart_coupon_error2);
          } else if (response.errorcode == "error_03") {
            toast.error(LocaleStrings.cart_coupon_error3);
          } else if (response.errorcode == "error_04") {
            toast.error(LocaleStrings.cart_coupon_error4);
          }
        }
        setCode("");
        setLoading(false);
        closeSlideOvers();
      });
    } else {
      // toast.error(LocaleStrings.cart_coupon_min_error + min_cart_value);
    }
  };

  const handleCouponCode = (e) => {
    let code = e.target.value;
    setCode(code);
  };

  const applyCouponCode = () => {
    if (code) {
      setLoading(true);
      props.applyCouponCode(session, code, (response) => {
        if (response.success == 1) {
          if (response.coupon) {
            let coupon = response.coupon;
            if (coupon.available == 1) {
              if (coupon.expired == 0) {
                applycoupon(coupon);
              } else {
                toast.error(LocaleStrings.cart_coupon_has_expired);
              }
            } else {
              toast.error(LocaleStrings.cart_coupon_not_available);
            }
          } else {
            toast.error(LocaleStrings.cart_coupon_not_exist);
          }
        } else {
          toast.error(LocaleStrings.cart_coupon_not_exist);
          setCode("");
          setLoading(false);
          closeSlideOvers();
        }
      });
    }
  };

  const copyCode = () => {
    toast.success(LocaleStrings.cart_coupon_copied);
  };

  const CouponList = (item) => {
    //console.log("item", item);
    return (
      <div className="relative pt-7 flex justify-center">
        <img src={CouponBg} />
        <div class="absolute pt-4 px-4  lg:px-12 pb-4 leading-4 w-full">
          <div className="text-primary text-sm font-normal">{item.title}</div>
          <div className="text-cardfooter text-10 font-normal mt-1">
            Applied on {item.rule.categoryname_en} -{" "}
            {item.rule.subcategoryname_en}
          </div>
          <div className="text-cardfooter text-xs font-medium mt-2">
            {item.discounttype == "percentage"
              ? item.discount + "% " + LocaleStrings.cart_discount
              : item.discount +
                " " +
                LocaleStrings.cart_flat_discount +
                " " +
                LocaleStrings.cart_discount}
          </div>
          <div className="flex mt-2">
            <div className="w-cartleft pr-3">
              {item.expired == 1 ? (
                <div className="border-gray-200 text-gray-400 border-dashed border  flex items-center justify-center  text-xs rounded h-7 w-11/12 disabled:opacity-50">
                  {item.code}
                </div>
              ) : (
                <CopyToClipboard text={item.code} onCopy={() => copyCode()}>
                  <div className="border-variantSel text-primary cursor-pointer border-dashed border  flex items-center justify-center  text-xs rounded h-7 w-11/12 disabled:opacity-50">
                    {item.code}
                  </div>
                </CopyToClipboard>
              )}
            </div>
            <div className="w-cartright flex justify-end">
              {item.expired == 1 ? (
                <div className="bg-progress_red text-white flex items-center justify-center text-xs rounded font-medium h-7 w-5/6 ">
                  {LocaleStrings.cart_coupon_expired}
                </div>
              ) : (
                <div
                  className="bg-ask border border-variantSel text-vendorLink flex items-center justify-center text-xs rounded font-medium h-7 w-5/6 cursor-pointer"
                  onClick={() => applycoupon(item)}
                >
                  {LocaleStrings.cart_coupon_apply}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Transition.Root show={couponModal} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          open={couponModal}
          onClose={() => closeSlideOvers()}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-md flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="bg-white h-full flex flex-col  shadow-xl">
                    <div className="min-h-0 flex-1 flex flex-col pt-3 overflow-y-scroll">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="slider-modal-headtext">
                            <h2
                              id="slide-over-heading"
                              className="text-xl font-normal text-vendorLink"
                            >
                              {LocaleStrings.cart_available_coupons}
                            </h2>
                          </Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              onClick={() => closeSlideOvers()}
                              className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="bg-white my-4 relative px-4 sm:px-6 grid grid-cols-4 gap-2">
                        <div className="col-span-3">
                          <input
                            type="text"
                            className="w-full h-7 border border-gray-200 rounded"
                            onChange={(e) => handleCouponCode(e)}
                          />
                        </div>
                        <div className="">
                          <button
                            className="w-full h-7 pl-sm pr-sm bg-btn text-white rounded font-medium text-sm cursor-pointer"
                            onClick={() => applyCouponCode()}
                          >
                            {LocaleStrings.cart_coupon_apply}
                          </button>
                        </div>
                      </div>
                      <div className="bg-newarrival relative flex-1 px-4 sm:px-6">
                        <div className="">
                          {getCoupon && getCoupon.data ? (
                            getCoupon.data.length > 0 ? (
                              getCoupon.data.map((item, index) => {
                                return CouponList(item);
                              })
                            ) : (
                              ""
                            )
                          ) : (
                            <div className="h-50">
                              <Loader />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  couponModal: state.couponModal,
  getCoupon: state.getCoupon,
  localCurrency: state.localCurrency,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { isCouponlistModalOpen, applyCoupon, fetchCart, applyCouponCode },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CouponList);
