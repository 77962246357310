import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "../../../../../languages/index";
import toast, { Toaster } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";

// Import Components

import {
  renderPhonewithFlag,
  renderFieldText,
  renderOTP,
} from "../../../../../components/Common/base-component";

// Import Actions
import {
  fetchUsersDeatils,
  isProfileinfoCommonOpen,
  updateStoreUsers,
  sendSms,
  verifyOtp,
} from "../../../actions/index";

// Functions Import
import {
  classNames,
  isValidQatarPhoneNumber,
} from "../../../../../components/Common/constant";

class AddPhoneSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      loaderDesc: LocaleStrings.preparing_profile_information,
      verificStateCode: "",
      fieldsEmptyState: true,
      isVerified: false,
      phone: "",
      country: "",
      sendLoader: false,
      verifyLoader: false,
    };

    // All Binded Functions
  }

  componentDidMount() {}

  _handleVerify() {
    let { isVerified, verificStateCode } = this.state;

    let verifiaction_1 = document.getElementsByName("verifiaction_1");
    let verifiaction_2 = document.getElementsByName("verifiaction_2");
    let verifiaction_3 = document.getElementsByName("verifiaction_3");
    let verifiaction_4 = document.getElementsByName("verifiaction_4");
    let verifiaction_5 = document.getElementsByName("verifiaction_5");
    let verifiaction_6 = document.getElementsByName("verifiaction_6");

    let verficationEntryCode =
      verifiaction_1[0].value +
      verifiaction_2[0].value +
      verifiaction_3[0].value +
      verifiaction_4[0].value +
      verifiaction_5[0].value +
      verifiaction_6[0].value;

    if (verficationEntryCode === verificStateCode) {
      this.setState({ isVerified: true });
    } else {
      toast.error(LocaleStrings.verification_code_mismatch);
      this.setState({ isVerified: false });
    }
  }

  /**** On Verification Code Entry ****/
  _onVerficationCodeEntry(otp) {
    console.log("otp", otp);
  }

  handleChange = (otp) => {
    if (otp.length == 6) {
      this.setState({ fieldsEmptyState: false });
    } else {
      this.setState({ fieldsEmptyState: true });
    }
  };

  /***** Form Submit *****/
  onFormSubmit(values) {
    //console.log("values", values);
    let actiontaken = values.actiontaken;
    if (actiontaken == "send") {
      if (values.phone) {
        this.setState({ sendLoader: true });
        let phone = values.phone;
        phone = phone.replace("-", "");
        phone = phone.replace(" ", "");
        this.props.sendSms(this.props.session, phone, false, (callback) => {
          this.setState({ sendLoader: false });
          if (callback.success === 1) {
            toast.success(LocaleStrings.send_code_success);
          } else {
            toast.error(callback.message);
          }
        });
      } else {
        toast.error(LocaleStrings.verification_code_mismatch);
      }
    } else if (actiontaken == "verify") {
      if (!this.state.isVerified) {
        this.setState({ verifyLoader: true });
        let otp = values.otp;
        let obj = {};
        obj.code = otp;
        obj.ischange = true;
        this.props.verifyOtp(this.props.session, obj, (callback) => {
          this.setState({ verifyLoader: false });
          if (callback.success === 1) {
            this.setState({ isVerified: true });
            this.setState({ fieldsEmptyState: true });
            toast.success(LocaleStrings.otp_verified_successfully);
          } else {
            this.setState({ fieldsEmptyState: true });
            toast.error(LocaleStrings.otp_not_verified);
          }
        });
      }
    } else if (actiontaken == "update") {
      if (this.state.isVerified) {
        this.setState({ btnLoader: true });
        let data = {};
        let phone = values.phone;
        phone = phone.replace("-", "");
        phone = phone.replace(" ", "");
        data.phone = phone;
        this.props.updateStoreUsers(this.props.session, data, (callback) => {
          if (callback.status === 1) {
            this.setState({ btnLoader: false });
            this.setState({ verificStateCode: "" });
            this.setState({ fieldsEmptyState: true });
            this.setState({ isVerified: false });

            toast.success(LocaleStrings.phone_updated_successfully);

            this.props.isProfileinfoCommonOpen(false);

            // Fetch Vendor Details With LoginIdStore
            this.props.fetchUsersDeatils(
              this.props.session,
              (callBackResponce) => {}
            );
          }
        });
      } else {
        toast.error(LocaleStrings.mobile_not_verified);
      }
    }
  }

  clickSubmit = (key) => {
    //console.log("key: ", key);
    this.props.autofill("actiontaken", key);
  };

  phoneChange = (value, country, e, formattedValue) => {
    //console.log("value", value);
    this.setState({ phone: value, country: country.dialCode });
  };

  render() {
    let { initialValues, language, handleSubmit, user } = this.props;

    return (
      <>
        <form
          className=""
          onSubmit={handleSubmit(this.onFormSubmit.bind(this))}
        >
          <div className="px-0 md:px-6 space-y-8">
            <div>
              <p className="text-xl text-custblack">
                {LocaleStrings.current_number}
              </p>
              <p className=" text-sm font-medium text-primary">
                {user.phone !== null &&
                user.phone !== "null" &&
                user.phone !== ""
                  ? user.phone
                  : LocaleStrings.add_phone_number}
              </p>
              <p className="font-semibold text-sm text-primary">
                {LocaleStrings.update_phone_number_text}
              </p>
            </div>
            <div className="">
              <Field
                name={"phone"}
                label={LocaleStrings.phone_number}
                mandatory="true"
                type="text"
                component={renderPhonewithFlag}
                className="cust-input-field"
                classNameLabel="text-box-lable"
                phone={this.state.phone}
                phoneChange={this.phoneChange}
              />
            </div>
            {/* Send Code Button */}
            <div>
              <button
                className="rounded py-3 px-5 w-full flex items-center justify-center bg-btn text-white font-medium text-sm cursor-pointer"
                type="submit"
                onClick={() => this.clickSubmit("send")}
                disabled={this.state.sendLoader ? true : false}
              >
                {this.state.sendLoader ? (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  ""
                )}
                <span className="text-sm">{LocaleStrings.send_code}</span>
              </button>
            </div>
            <div>
              <p className=" text-sm font-medium text-custblack">
                {LocaleStrings.phone_number_verfication_text}
              </p>
            </div>
            {/* Verification input boxes */}
            <div className="flex justify-center space-x-4">
              <Field
                name="otp"
                type="text"
                component={renderOTP}
                mandatory="false"
                maxlength="1"
                onChange={this.handleChange}
                inputstyle={{
                  width: this.props.deviceSize.isMobile ? 28 : 38,
                  height: this.props.deviceSize.isMobile ? 28 : 38,
                  border: "1px solid #d9d9d9",
                  borderRadius: 4,
                  margin: this.props.deviceSize.isMobile ? "0 6px" : "0 15px",
                }}
              />
            </div>
            {/* Verify button */}
            <div>
              <button
                className={classNames(
                  this.state.fieldsEmptyState
                    ? "cursor-not-allowed bg-gray-300"
                    : "bg-btn",
                  "rounded py-3 px-5 w-full flex items-center justify-center text-white font-medium text-sm cursor-pointer"
                )}
                type="submit"
                onClick={() => this.clickSubmit("verify")}
                disabled={this.state.fieldsEmptyState ? true : false}
              >
                {this.state.verifyLoader ? (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  ""
                )}
                <span className="text-sm">
                  {LocaleStrings.verify_phone_number}
                </span>
              </button>
            </div>
          </div>
          <div className="px-0 md:px-6 py-12 border-t-2 border-primary">
            {/* Footer */}
            <button
              className="rounded py-3 px-5 w-full flex items-center justify-center bg-btn text-white font-medium text-sm cursor-pointer"
              type="submit"
              onClick={() => this.clickSubmit("update")}
            >
              {this.state.btnLoader ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                ""
              )}
              <span className="text-sm">
                {LocaleStrings.update_phone_number}
              </span>
            </button>
          </div>
        </form>
      </>
    );
  }
}

function validate(values, ownProps) {
  var errors = {};
  console.log("ownProps", ownProps);
  if (values.actiontaken == "send") {
    let countrycode = ownProps.phoneLengthCountry;

    if (values.phone) {
      values.phone = values.phone.replace(/[- )( +]/g, "");
    }
    console.log("countrycode", countrycode);
    console.log(
      "isValidQatarPhoneNumber",
      isValidQatarPhoneNumber("+" + values.phone)
    );
    if (!values.phone) {
      errors.phone = LocaleStrings.form_validation_phone_no_required;
    } else if (countrycode == "974") {
      if (isValidQatarPhoneNumber("+" + values.phone) == false) {
        errors.phone = LocaleStrings.form_validation_phone_no_valid;
      }
    }
  }

  return errors;
}

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.myProfile.data,
    session: state.session,
    isOpenProfileInfoModal: state.isOpenProfileInfoModal,
    initialValues: {
      userid: state.myProfile
        ? state.myProfile.data
          ? state.myProfile.data.userid
          : ""
        : "",
    },
    deviceSize: state.deviceSize,
    phoneLengthCountry: state.phoneLengthCountry,
  };
}

export default connect(mapStateToProps, {
  fetchUsersDeatils,
  isProfileinfoCommonOpen,
  updateStoreUsers,
  sendSms,
  verifyOtp,
})(
  reduxForm({
    validate,
    form: "updatePhoneNumForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AddPhoneSection)
);
