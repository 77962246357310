import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import Dropzone from "react-dropzone";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
  CURRENCY,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import Loader from "../../../components/Common/loader";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import ImgUploader from "../../../assets/images/attach-file.svg";
import {
  isRateProductModalOpen,
  updateOrder,
  fetchOrderDetails,
  addReview,
  updateReview,
  addReviewMedia,
  uploadMedia,
} from "../actions";
import {
  renderFieldTextarea,
  renderFieldCheckbox,
  renderFieldRadio2,
} from "../../../components/Common/base-component";
import toast, { Toaster } from "react-hot-toast";
import StarRatings from "react-star-ratings";
import { fetchAllReviews } from "../../Product/actions";

function ReturnReplace(props) {
  const {
    session,
    isLoggedIn,
    handleSubmit,
    localLanguage,
    rateProduct,
    orderDetails,
    deviceSize,
    setReviewData,
    reviewData,
  } = props;
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    productrating: rateProduct.productrating
      ? parseFloat(rateProduct.productrating)
      : 0,
    rating1: rateProduct.item_as_described_rating
      ? parseFloat(rateProduct.item_as_described_rating)
      : 0,
    rating2: rateProduct.communication_rating
      ? parseFloat(rateProduct.communication_rating)
      : 0,
    rating3: rateProduct.shipping_time_rating
      ? parseFloat(rateProduct.shipping_time_rating)
      : 0,
  });
  const [fileArr, setFileArr] = useState([]);

  const closeSlideOvers = () => {
    props.isRateProductModalOpen({ show: false });
  };

  const onSubmitForm = (values) => {
    if (state.productrating > 0) {
      setLoading(true);
      let updateData = {};
      updateData.productrating = state.productrating;
      updateData.item_as_described_rating = state.rating1;
      updateData.communication_rating = state.rating2;
      updateData.shipping_time_rating = state.rating3;
      let totalrating = 0;
      if (state.productrating > 0) {
        totalrating = totalrating + parseInt(state.productrating);
      }
      if (state.rating1 > 0) {
        totalrating = totalrating + parseInt(state.rating1);
      }
      if (state.rating2 > 0) {
        totalrating = totalrating + parseInt(state.rating2);
      }
      if (state.rating3 > 0) {
        totalrating = totalrating + parseInt(state.rating3);
      }
      updateData.avg_rating = Math.round(totalrating / 4).toFixed(2);
      updateData.feedback = values.feedback ? values.feedback : null;
      updateData.feedback_anonymous = values.feedback_anonymous ? 1 : 0;
      updateData.ratedby = session.userid;
      updateData.productid = rateProduct.productid
        ? rateProduct.productid
        : orderDetails.productid;
      updateData.sellerid = rateProduct.sellerid
        ? rateProduct.sellerid
        : orderDetails.seller.userid;
      console.log("updateData", updateData);
      console.log("productrating", state.productrating);
      debugger;
      //console.log("fileArr length", fileArr.length);
      //console.log("fileArr", fileArr);

      let filetobeupload = [];
      if (fileArr.length > 0) {
        fileArr.map((item, index) => {
          let ext = item.name.split(".").pop();
          let ts = new Date().getTime();
          let covermedia = ts + index + "." + ext;
          let coverJson = {
            medianame: covermedia,
            media: item.url,
          };
          filetobeupload.push(coverJson);
        });
      }

      let counter = filetobeupload.length;
      let uploaderCounter = 0;
      let arrPromise = [];

      // console.log("filetobeupload", filetobeupload);
      // debugger;
      if (rateProduct.id) {
        let reviewid = rateProduct.id;
        let productid = rateProduct.productid;
        props.updateReview(session, updateData, reviewid, (callback) => {
          if (callback.success == 1) {
            //console.log("first if", callback);

            if (filetobeupload.length > 0) {
              //console.log("if reviewid", reviewid);
              filetobeupload.map((item, index) => {
                arrPromise.push(
                  new Promise((resolve, reject) => {
                    props.uploadMedia(session, item, (response) => {
                      if (response.success === 0) {
                        resolve();
                      } else {
                        item.reviewid = reviewid;
                        props.addReviewMedia(session, item, (feedback) => {
                          if (feedback.success === 0) {
                            resolve();
                          } else {
                            uploaderCounter++;
                            resolve();
                          }
                        });
                      }
                    });
                  })
                );
              });

              Promise.all(arrPromise).then(() => {
                setLoading(false);
                if (counter == uploaderCounter) {
                  props.fetchAllReviews(
                    props.session,
                    productid,
                    null,
                    null,
                    1,
                    (res) => {
                      if (res.data) {
                        setReviewData(res.data);
                      }
                    }
                  );
                  toast.success(LocaleStrings.review_submit_success);
                } else {
                  toast.error(LocaleStrings.common_fail_message);
                }
                closeSlideOvers();
              });
            } else {
              //console.log("else reviewid", reviewid);
              setLoading(false);
              props.fetchAllReviews(
                props.session,
                productid,
                null,
                null,
                1,
                (res) => {
                  if (res.data) {
                    setReviewData(res.data);
                  }
                }
              );
              toast.success(LocaleStrings.review_submit_success);
              closeSlideOvers();
            }
          } else {
            setLoading(false);
            toast.error(LocaleStrings.common_fail_message);
            closeSlideOvers();
          }
        });
      } else {
        props.addReview(session, updateData, (callback) => {
          if (callback.success == 1) {
            //console.log("first if", callback);
            let reviewid = callback.reviewid;

            if (filetobeupload.length > 0) {
              //console.log("if reviewid", reviewid);
              filetobeupload.map((item, index) => {
                arrPromise.push(
                  new Promise((resolve, reject) => {
                    props.uploadMedia(session, item, (response) => {
                      if (response.success === 0) {
                        resolve();
                      } else {
                        item.reviewid = reviewid;
                        props.addReviewMedia(session, item, (feedback) => {
                          if (feedback.success === 0) {
                            resolve();
                          } else {
                            uploaderCounter++;
                            resolve();
                          }
                        });
                      }
                    });
                  })
                );
              });

              Promise.all(arrPromise).then(() => {
                setLoading(false);
                if (counter == uploaderCounter) {
                  props.fetchOrderDetails(
                    session,
                    orderDetails.orderdetailsid,
                    (res) => {}
                  );
                  toast.success(LocaleStrings.review_submit_success);
                } else {
                  toast.error(LocaleStrings.common_fail_message);
                }
                closeSlideOvers();
              });
            } else {
              //console.log("else reviewid", reviewid);
              setLoading(false);
              props.fetchOrderDetails(
                session,
                orderDetails.orderdetailsid,
                (res) => {}
              );
              toast.success(LocaleStrings.review_submit_success);
              closeSlideOvers();
            }
          } else {
            setLoading(false);
            toast.error(LocaleStrings.common_fail_message);
            closeSlideOvers();
          }
        });
      }
    } else {
      toast.error(LocaleStrings.rate_product_error);
    }
  };

  const rating = (newRating, name) => {
    setState((prevState) => ({ ...prevState, [name]: newRating }));
  };

  const onDrop = (files) => {
    let filearr = fileArr;
    for (var i = 0; i < files.length; i++) {
      var file = files[i];
      var picReader = new FileReader();
      picReader.addEventListener("load", function (event) {
        var picFile = event.target;
        filearr.push({ name: file.name, url: picFile.result });
        setFileArr(filearr);
      });
      //Read the image
      picReader.readAsDataURL(file);
    }
  };

  const _handleSelect = (event) => {
    let filearr = [...fileArr];
    console.log("filearr", filearr);
    if (filearr.length < 5) {
      const reader = new FileReader(); // Define reader
      let file = event.target.files[0]; // Take Selected file
      reader.readAsArrayBuffer(file); // Read the file

      // On File Load
      reader.onload = (e) => {
        file.url = e.target.result;
      };

      let ext = file.name.split(".").pop(); // File extension
      filearr.push(file);
      setFileArr(filearr);
    }
  };

  /**** RED cross Click ****/
  const _handleUnSelect = (e) => {
    setFileArr([]);
  };

  console.log("rateProduct", rateProduct);
  return (
    <>
      <Transition.Root show={rateProduct.show} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          open={rateProduct.show}
          onClose={() => closeSlideOvers()}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <form
                    onSubmit={handleSubmit(onSubmitForm)}
                    style={{
                      height: deviceSize.height,
                    }}
                  >
                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                      <div className="flex flex-col bg-white py-3">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="slider-modal-headtext">
                              <h2
                                id="slide-over-heading"
                                className="text-xl font-normal text-link"
                              >
                                {LocaleStrings.rate_product}
                              </h2>
                            </Dialog.Title>
                            <div className="ml-3 h-7 flex items-center">
                              <button
                                type="button"
                                onClick={() => closeSlideOvers()}
                                className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="min-h-0 flex-1 flex flex-col overflow-y-scroll">
                        <div className="relative flex-1 bg-white">
                          <div className="rounded my-5 px-4">
                            <div className="text-loginLabel font-normal text-sm py-3">
                              {LocaleStrings.rate_product_rating}
                            </div>
                            <div className="">
                              <StarRatings
                                rating={state.productrating}
                                starRatedColor="#009CDE"
                                starEmptyColor="#D5DBE7"
                                starHoverColor="#009CDE"
                                numberOfStars={5}
                                name="productrating"
                                starDimension="20px"
                                starSpacing="1px"
                                changeRating={(newRating, name) =>
                                  rating(newRating, name)
                                }
                              />
                            </div>
                            <div className="text-loginLabel font-normal text-sm mt-md">
                              {LocaleStrings.rate_product_desc}
                            </div>
                            <div className="text-loginLabel font-normal text-sm mt-md">
                              {LocaleStrings.rate_product_point1}
                            </div>
                            <div className="text-loginLabel font-normal text-sm">
                              <StarRatings
                                rating={state.rating1}
                                starRatedColor="#009CDE"
                                starEmptyColor="#D5DBE7"
                                starHoverColor="#009CDE"
                                numberOfStars={5}
                                name="rating1"
                                starDimension="20px"
                                starSpacing="1px"
                                changeRating={(newRating, name) =>
                                  rating(newRating, name)
                                }
                              />
                            </div>
                            <div className="text-loginLabel font-normal text-sm mt-md">
                              {LocaleStrings.rate_product_point2}
                            </div>
                            <div className="text-loginLabel font-normal text-sm">
                              <StarRatings
                                rating={state.rating2}
                                starRatedColor="#009CDE"
                                starEmptyColor="#D5DBE7"
                                starHoverColor="#009CDE"
                                numberOfStars={5}
                                name="rating2"
                                starDimension="20px"
                                starSpacing="1px"
                                changeRating={(newRating, name) =>
                                  rating(newRating, name)
                                }
                              />
                            </div>
                            <div className="text-loginLabel font-normal text-sm mt-md">
                              {LocaleStrings.rate_product_point3}
                            </div>
                            <div className="text-loginLabel font-normal text-sm">
                              <StarRatings
                                rating={state.rating3}
                                starRatedColor="#009CDE"
                                starEmptyColor="#D5DBE7"
                                starHoverColor="#009CDE"
                                numberOfStars={5}
                                name="rating3"
                                starDimension="20px"
                                starSpacing="1px"
                                changeRating={(newRating, name) =>
                                  rating(newRating, name)
                                }
                              />
                            </div>
                            <div className="text-loginLabel font-normal text-sm mt-md">
                              {LocaleStrings.rate_product_point4}
                            </div>
                            <div className="text-loginLabel font-normal text-sm mt-md pb-3">
                              <Field
                                name="feedback"
                                placeholder={LocaleStrings.explain_placeholder}
                                type="textarea"
                                component={renderFieldTextarea}
                                className="p-3 appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm text-loginLabel"
                                rows="8"
                              />
                            </div>
                            <div className="">
                              <div className="grid grid-cols-3">
                                <div className="col-span-2 mt-md">
                                  <div className="flex items-center">
                                    <span className="text-sm text-ternary">
                                      {_.size(fileArr) > 0
                                        ? _.size(fileArr)
                                        : ""}{" "}
                                      {_.size(fileArr) > 1
                                        ? LocaleStrings.files_attached
                                        : _.size(fileArr) > 0
                                        ? LocaleStrings.file_attached
                                        : ""}
                                    </span>
                                    <img
                                      className={classNames(
                                        _.size(fileArr) > 0 ? "" : "hidden",
                                        "h-4 w-4 mx-1 cursor-pointer"
                                      )}
                                      src={"../images/red-cross.svg"}
                                      onClick={(e) => _handleUnSelect(e)}
                                    />
                                  </div>
                                </div>
                                <div className="text-center py-2 border border-blue-200 rounded-md text-loginLabel font-normal text-sm mt-md">
                                  {/* <Dropzone
                                    onDrop={(e) => onDrop(e)}
                                    accept="image/jpeg, image/png ,image.jpg"
                                    multiple={true}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div
                                        {...getRootProps()}
                                        className="h-full"
                                      >
                                        <input {...getInputProps()} />

                                        <div className="cursor-pointer flex items-center justify-center paragraph-seconday text-xs">
                                          <img
                                            src={ImgUploader}
                                            className="w-6"
                                          />
                                          {" " +
                                            LocaleStrings.rate_product_attach}
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone> */}

                                  <label
                                    htmlFor="attachment"
                                    className="relative cursor-pointer flex items-center justify-center"
                                  >
                                    <img src={ImgUploader} className="w-2.5" />

                                    <span className="pl-2">
                                      {LocaleStrings.rate_product_attach}
                                    </span>
                                    <input
                                      id="attachment"
                                      name="attachment"
                                      type="file"
                                      accept="image/*"
                                      className="sr-only"
                                      onChange={(e) => _handleSelect(e)}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="mt-md">
                              <Field
                                name="feedback_anonymous"
                                label={LocaleStrings.rate_product_anonymus}
                                type="checkbox"
                                className="focus:ring-primary h-4 w-4 text-primary border-ternary rounded"
                                component={renderFieldCheckbox}
                                classNameLabel="text-loginLabel text-sm font-normal"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex-shrink-0 px-4 py-3.5 flex relative">
                        <button
                          type="submit"
                          className="text-white h-44 bg-btn text-sm font-normal rounded w-full inline-flex items-center justify-center"
                        >
                          {loading ? (
                            <svg
                              className="animate-spin h-5 w-5 rounded-full mr-3"
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                          ) : (
                            ""
                          )}
                          <span className="text-sm">
                            {LocaleStrings.submit_rating}
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const validate = (values, ownProps) => {
  var errors = {};

  return errors;
};

const mapStateToProps = (state) => {
  var initVals = {};

  if (state.rateProduct) {
    initVals = state.rateProduct;
  }

  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    localLanguage: state.localLanguage,
    deviceSize: state.deviceSize,
    rateProduct: state.rateProduct,
    orderDetails: state.orderDetails,
    initialValues: initVals,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isRateProductModalOpen,
      updateOrder,
      fetchOrderDetails,
      addReview,
      addReviewMedia,
      uploadMedia,
      updateReview,
      fetchAllReviews,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "PostRating",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ReturnReplace)
);
