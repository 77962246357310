import axios from "axios";
import _ from "lodash";

import { INSTANCE_URL, APP_API_KEY } from "../../../components/Common/constant";

import {
  getRequestAccess,
  postRequestAccess,
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../components/Common/network-call";
import { getHeaders } from "../../../components/Common/common-utils";

export const WATCH_LIST = "WATCH_LIST";

export function emptyMyWatchlist(callback) {
  return (dispatch) => {
    dispatch({
      type: WATCH_LIST,
      payload: {},
    });
  };
}

export function fetchMyWatchlist(session, selectedTab, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/watchlist`;
  let values = {};
  values.tab = selectedTab;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.result == "success") {
          dispatch({
            type: WATCH_LIST,
            payload: { data: response.data },
          });
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function removeWatchlist(session, ids, callback) {
  var deleteUrl = `${INSTANCE_URL}/api/v2/shopez/_table/watchlist?filter=((userid=${session.userid})AND(productid IN (${ids})))`;

  return (dispatch) => {
    deleteRequest(
      deleteUrl,
      session,
      dispatch,
      (delres) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}
