import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
function NegotiationList(props) {
  const {
    session,
    isLoggedIn,
    localLanguage,
    negotiationDetails,
    negotiateThread,
    item,
  } = props;
  // console.log("senderid", parseInt(item.senderid));
  // console.log("session.userid", session.userid);
  return (
    <>
      {parseInt(item.senderid) == session.userid ? (
        <div className="flex justify-end">
          <div>
            <div
              className="bg-btn text-white px-3 py-2"
              style={{ borderRadius: "22px 22px 5px 22px" }}
            >
              {item.isprice == "1" ? `${item.currency} ${parseFloat(item.price).toFixed(2)}` : `${item.message}`}
            </div>
            <div className="text-timef text-xs">{timeAgo(item.createdat)}</div>
          </div>
        </div>
      ) : (
        <div className="flex justify-start">
          <div>
            <div
              className="bg-white px-3.5 py-3"
              style={{ borderRadius: "22px 22px 22px 5px" }}
            >
              <div className="flex">
                <div className="text-primary text-base font-normal">
                  {LocaleStrings.negotiation_status} :&nbsp;
                </div>
                <div
                  className={classNames(
                    item.status == "rejected"
                      ? "text-rejected "
                      : "text-accepted ",
                    "text-base font-normal"
                  )}
                >
                  {_.startCase(item.status)}
                </div>
              </div>
            </div>
            <div className="text-timef text-xs">{timeAgo(item.createdat)}</div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
  negotiateThread: state.negotiateThread,
  negotiationDetails: state.negotiationDetails,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NegotiationList);
