import React, { Component } from "react";
import "../../../assets/css/ToggleSwitch.css";

const ToggleSwitch = ({
  label,
  notificationchecked,
  onChange,
  disabled,
  field,
}) => (
  <div className="container">
    <div className="toggle-switch">
      <input
        type="checkbox"
        className="checkbox"
        name={label}
        id={label}
        checked={notificationchecked}
        onChange={(e) => onChange(e.target.checked, field)}
        disabled={disabled}
      />
      <label className="label" htmlFor={label}>
        <span className="inner" />
        <span className="switch" />
      </label>
    </div>
  </div>
);

export default ToggleSwitch;
