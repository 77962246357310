import React, { useState, useEffect, Fragment, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  renderFieldText,
  renderFieldTextarea,
} from "../../../components/Common/base-component";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon, XIcon } from "@heroicons/react/outline";
import { isForgotPasswordOpen, initiateReset } from "../actions";
import toast, { Toaster } from "react-hot-toast";

function ForgotPassword(props) {
  const history = useHistory();
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  let { session, deviceSize, handleSubmit, localLanguage, forgotPwdOpen } =
    props;

  const closeModal = () => {
    props.isForgotPasswordOpen(false);
  };

  useEffect(() => {
    return () => {
      props.isForgotPasswordOpen(false);
    };
  }, []);

  const onSubmitForm = (values) => {
    setLoading(true);
    props.initiateReset(values, (response) => {
      setLoading(false);
      //console.log("response :- ", response);
      setLoading(false);
      if (response.success === 1) {
        toast.success(LocaleStrings.forgot_password_success);
      } else {
        toast.error(LocaleStrings.forgot_password_form_error_messages);
      }
      props.reset();
    });
  };

  return (
    <Transition.Root show={forgotPwdOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={forgotPwdOpen}
        onClose={() => closeModal()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div
                className="absolute top-0 right-0 pt-4 pr-4"
                ref={cancelButtonRef}
              >
                <button
                  type="button"
                  className="btn-closeModal"
                  onClick={() => closeModal()}
                >
                  <span className="sr-only">{LocaleStrings.close}</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {LocaleStrings.forgot_password}
                  </Dialog.Title>
                  <div className="mt-2 mr-14">
                    <p className="text-sm text-gray-500">
                      {LocaleStrings.password_reset_link_text}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mx-6  md:mx-14 my-4 sm:flex-row-reverse">
                <form onSubmit={handleSubmit(onSubmitForm)}>
                  <div>
                    <div className="mt-1">
                      <Field
                        name="email"
                        placeholder={LocaleStrings.email}
                        type="email"
                        component={renderFieldText}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="pt-3.5">
                    <button
                      type="submit"
                      className="text-white h-44 bg-btn text-sm font-normal rounded w-full inline-flex items-center justify-center"
                    >
                      {loading ? (
                        <svg
                          className="animate-spin h-5 w-5 rounded-full mr-3"
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      ) : (
                        ""
                      )}
                      <span className="text-sm">
                        {LocaleStrings.send_reset_link}
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function validate(values) {
  var errors = {};

  if (!values.email) {
    errors.email = LocaleStrings.login_form_validation_email_required;
  }

  return errors;
}

var mapStateToProps = (state) => ({
  session: state.session,
  deviceSize: state.deviceSize,
  localLanguage: state.localLanguage,
  forgotPwdOpen: state.forgotPwdOpen,
});

export default reduxForm({
  validate,
  form: "ForgotPasswordForm",
})(
  connect(mapStateToProps, {
    isForgotPasswordOpen,
    initiateReset,
  })(ForgotPassword)
);
