import { CONSUMER_SETTINGS_MASTER } from "../actions/index";

import LocaleStrings from "../../../languages/index";

/**** Legal Doc Static List ****/
export var consumerLegalDocsList = (state = [], action) => {
  var state = [
    {
      name: LocaleStrings.consumer_legal_docs_list_text1,
      subname: LocaleStrings.consumer_legal_docs_list_subtext1,
      href: "termsncons",
      icon: "termsandcons",
      current: false,
      position: 0,
    },
    {
      name: LocaleStrings.consumer_legal_docs_list_text2,
      subname: LocaleStrings.consumer_legal_docs_list_subtext2,
      href: "privacypolicy",
      icon: "privacypolicy",
      current: false,
      position: 1,
    },
    {
      name: LocaleStrings.return_policy,
      subname: LocaleStrings.consumer_legal_return_subtext2,
      href: "returnpolicy",
      icon: "salespolicy",
      current: false,
      position: 2,
    },
    {
      name: LocaleStrings.shipment_policy,
      subname: LocaleStrings.consumer_legal_shipment_subtext2,
      href: "shipmentpolicy",
      icon: "privacypolicy",
      current: false,
      position: 3,
    },
    {
      name: LocaleStrings.intellectual_property,
      subname: LocaleStrings.consumer_legal_shipment_subtext2,
      href: "intellectual",
      icon: "privacypolicy",
      current: false,
      position: 4,
    },
  ];

  return state;
};

/**** Fetch SETTINGS_MASTER data  *****/
export var settingsMasterConsumer = (state = {}, action) => {
  switch (action.type) {
    case CONSUMER_SETTINGS_MASTER:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
