import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ShimmerEffect from "../../../components/shimmer-effect";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
} from "../../../components/Common/constant";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { fetchSettings } from "../../../actions/index";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Banner(props) {
  const history = useHistory();
  const { homePageData } = props;

  const viewCategory = (item) => {
    if (item.linktype == "category") {
      history.push("/view-category/" + item.categoryid);
    } else if (item.linktype == "subcategory") {
      history.push("/product-view/" + item.subcategoryid);
    } else if (item.linktype == "product") {
      history.push(
        "/product-details/" +
          _.kebabCase(item.productname_en) +
          "-" +
          item.productid
      );
    } else {
      window.open(item.externallink, "_blank").focus();
    }
  };

  let headerBanner = [];
  if (homePageData && homePageData.data && homePageData.data.length > 0) {
    headerBanner = _.filter(homePageData.data, function (o) {
      return o.section == "banner" && o.bannertype == "header";
    });
  }

  //console.log("headerBanner", headerBanner);

  return headerBanner &&
    headerBanner.length > 0 &&
    headerBanner[0].data &&
    headerBanner[0].data.length > 0 ? (
    <div
      className="mt-0 md:mt-22 mb-0 xl:mb-30 lg:mb-30 md:mb-30 rounded custom_container grid grid-cols-4"
      style={
        props.deviceSize.isMobile ? { paddingLeft: 0, paddingRight: 0 } : {}
      }
    >
      <div className="col-span-4 md:col-span-3 ltr-grid">
        <Carousel
          autoPlay={true}
          showArrows={true}
          showThumbs={false}
          infiniteLoop={true}
          showStatus={false}
        >
          {_.map(headerBanner[0].data, (item, index) => {
            return (
              <div
                className="relative cursor-pointer bannerwrapper"
                onClick={() => viewCategory(item)}
              >
                <LazyLoadImage
                  src={`${FILES_URL}/${item.image}?api_key=${APP_API_KEY}`}
                  className={classNames(
                    props.deviceSize.isMobile ? "" : "rounded ",
                    "w-auto h-150 md:h-318 object-cover"
                  )}
                  effect="blur"
                />
              </div>
            );
          })}
        </Carousel>
      </div>
      <div className="pl-4 hidden md:block">
        {headerBanner &&
        headerBanner.length > 0 &&
        headerBanner[0].staticdata &&
        headerBanner[0].staticdata.image ? (
          <div
            className="relative cursor-pointer bannerwrapper"
            onClick={() => viewCategory(headerBanner[0].staticdata)}
          >
            <LazyLoadImage
              src={`${FILES_URL}/${headerBanner[0].staticdata.image}?api_key=${APP_API_KEY}`}
              className="w-auto object-cover rounded"
              effect="blur"
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  ) : !homePageData?.data ? (
    <div
      className="mt-0 md:mt-22 mb-0 xl:mb-30 lg:mb-30 md:mb-30 rounded custom_container grid grid-cols-4"
      style={
        props.deviceSize.isMobile ? { paddingLeft: 0, paddingRight: 0 } : {}
      }
    >
      <div className="col-span-4 md:col-span-3 ltr-grid">
        <ShimmerEffect
          containerClass="banner-shimmer mb-4"
          simmerType1={
            props.deviceSize.isMobile
              ? "mobrectangle-simmer"
              : "rectangle-simmer"
          }
        />
      </div>
      <div className="pl-4 hidden md:block">
        <ShimmerEffect
          containerClass="banner-shimmer mb-4"
          simmerType1="rectangle-simmer"
        />
      </div>
    </div>
  ) : (
    ""
  );
}

const mapStateToProps = (state) => ({
  homePageData: state.homePageData,
  adsReducer: state.adsReducer,
  deviceSize: state.deviceSize,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchSettings }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
