import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  renderFieldText,
  renderFieldCheckbox,
} from "../../../components/Common/base-component";
import { Dialog, Transition } from "@headlessui/react";
import {
  askQuestionModal,
  addQuestion,
  questionListByProduct,
} from "../actions";
import toast, { Toaster } from "react-hot-toast";

function PlaceBid(props) {
  const {
    handleSubmit,
    session,
    isLoggedIn,
    openAskQuestion,
    setPagenumber,
    setQuestionData,
    setAddNew,
  } = props;
  const [loading, setLoading] = useState(false);
  const cancelButtonRef = useRef(null);

  const onSubmitForm = (values) => {
    setLoading(true);
    let productid = parseInt(openAskQuestion.productid);
    let vendorid = parseInt(openAskQuestion.vendorid);
    values.isanswer = 0;
    values.askedto = vendorid;
    values.productid = productid;
    props.addQuestion(session, values, (res) => {
      setLoading(false);
      if (res.success == 0) {
        toast.error(LocaleStrings.common_fail_message);
      } else {
        props.questionListByProduct(
          props.session,
          productid,
          null,
          1,
          (res) => {
            if (res.data && res.data.length > 0) {
              setQuestionData(res.data);
              setPagenumber(1);
            }
          }
        );
        props.askQuestionModal(false);
        toast.success(LocaleStrings.ask_question_success);
      }
    });
  };

  return (
    <Transition.Root
      show={openAskQuestion && openAskQuestion.showModal}
      as={Fragment}
    >
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={openAskQuestion && openAskQuestion.showModal}
        onClose={() => props.askQuestionModal(false)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg pt-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="px-5 grid grid-cols-2 gap-1 border-b pb-3">
                <div className="text-left text-primary font-normal text-xl">
                  {LocaleStrings.ask_question_btn}
                </div>
                <div
                  className="text-right text-link font-normal text-base cursor-pointer"
                  onClick={() => props.askQuestionModal(false)}
                  ref={cancelButtonRef}
                >
                  {LocaleStrings.cancel}
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <div className="pt-4">
                  <div className="mt-3 ml-4 mr-4">
                    <div className="mt-md">
                      <Field
                        name="question"
                        placeholder={LocaleStrings.ask_question_placeholder}
                        type="text"
                        component={renderFieldText}
                        className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <div className="mt-md">
                      <button
                        type="submit"
                        className="text-white h-44 bg-btn text-sm font-normal rounded w-full inline-flex items-center justify-center "
                      >
                        {loading ? (
                          <svg
                            className="animate-spin h-5 w-5 rounded-full mr-3"
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                        ) : (
                          ""
                        )}
                        <span className="text-sm">{LocaleStrings.ask_btn}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const validate = (values, ownProps) => {
  var errors = {};
  var regex = /^\s+$/;
  let question = values.question;
  if (!question) {
    errors.question = LocaleStrings.ask_question_form_error;
  } else if (question) {
    if (question.match(regex)) {
      errors.question = LocaleStrings.ask_question_form_error;
    }
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    localLanguage: state.localLanguage,
    deviceSize: state.deviceSize,
    openAskQuestion: state.openAskQuestion,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { askQuestionModal, addQuestion, questionListByProduct },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "BidForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(PlaceBid)
);
