import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
  SMALL_DATE_FORMAT,
  shareUrl,
} from "../../../components/Common/constant";
import {
  currencyCode,
  currencyRate,
  ProductTextLocalized,
} from "../../../components/Common/product-model";
import Loader from "../../../components/Common/loader";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import NoImg from "../../../assets/images/noimg.png";
import { isProductShareModalOpen } from "../actions";
import toast, { Toaster } from "react-hot-toast";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
  WhatsappIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FilecopyLogo from "../../../assets/images/share/file_copy.png";

function Share(props) {
  const { productShareModal, selectedProduct, localLanguage, variantId } =
    props;
  const cancelButtonRef = useRef(null);

  const [copied, setCopied] = useState(false);

  const copyUrl = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
      props.isProductShareModalOpen(false);
    }, 500);
  };

  //console.log("selectedProduct", selectedProduct);

  const url = shareUrl;
  var sectionName = ProductTextLocalized(
    selectedProduct.name_en,
    selectedProduct.name_ar,
    localLanguage
  );
  var sectionUrl =
    "product-details/" +
    _.kebabCase(selectedProduct.productname_en) +
    "-" +
    selectedProduct.id +
    "-" +
    variantId;

  return (
    <Transition.Root
      show={productShareModal && productShareModal.showModal}
      as={Fragment}
    >
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={productShareModal && productShareModal.showModal}
        onClose={() => props.isProductShareModalOpen(false)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                props.deviceSize.isMobile ? "w-full " : "",
                "inline-block align-bottom bg-white rounded-lg pt-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:mx-auto sm:w-full sm:max-w-md"
              )}
            >
              <div className="px-5 grid grid-cols-2 gap-1 border-b pb-3">
                <div className="text-left text-primary font-normal text-xl">
                  {LocaleStrings.share_heading}
                </div>
                <div
                  className="text-right text-link font-normal text-base cursor-pointer"
                  onClick={() => props.isProductShareModalOpen(false)}
                  ref={cancelButtonRef}
                >
                  {LocaleStrings.cancel}
                </div>
              </div>
              <div className="pt-4 ">
                <div className="mt-3 mx-4  pb-3.5 divide-y">
                  {/* <div>
                    <FacebookShareButton
                      url={url + sectionUrl}
                      quote={sectionName}
                      //hashtag={"#" + _.startCase(props.event.category)}
                      className="m-2"
                    >
                      <div className="flex items-center">
                        <div>
                          <FacebookIcon size={32} round={true} />
                        </div>
                        <div style={{ paddingLeft: 13 }}>
                          <a className="backlink">Facebook</a>
                        </div>
                      </div>
                    </FacebookShareButton>
                  </div> */}
                  <div>
                    <TwitterShareButton
                      url={url + sectionUrl}
                      quote={sectionName}
                      //hashtag={"#" + _.startCase(props.event.category)}
                      className="m-2"
                    >
                      <div className="flex items-center">
                        <div>
                          <TwitterIcon size={32} round={true} />
                        </div>
                        <div style={{ paddingLeft: 13 }}>
                          <a className="backlink">Twitter</a>
                        </div>
                      </div>
                    </TwitterShareButton>
                  </div>
                  <div>
                    <EmailShareButton
                      url={url + sectionUrl}
                      quote={sectionName}
                      //hashtag={"#" + _.startCase(props.event.category)}
                      className="m-2"
                    >
                      <div className="flex items-center">
                        <div>
                          <EmailIcon size={32} round={true} />
                        </div>
                        <div style={{ paddingLeft: 13 }}>
                          <a className="backlink">Email</a>
                        </div>
                      </div>
                    </EmailShareButton>
                  </div>
                  <div>
                    <WhatsappShareButton
                      url={url + sectionUrl}
                      quote={sectionName}
                      //hashtag={"#" + _.startCase(props.event.category)}
                      className="m-2"
                    >
                      <div className="flex items-center">
                        <div>
                          <WhatsappIcon size={32} round={true} />
                        </div>
                        <div style={{ paddingLeft: 13 }}>
                          <a className="backlink">Whatsapp</a>
                        </div>
                      </div>
                    </WhatsappShareButton>
                  </div>
                  <div>
                    <CopyToClipboard
                      text={url + sectionUrl}
                      onCopy={() => copyUrl()}
                    >
                      <div className="grid grid-cols-2">
                        <div className="flex items-center m-2">
                          <div>
                            <img src={FilecopyLogo} className="w-8" />
                          </div>
                          <div className="pl-3">
                            <a className="backlink">
                              {LocaleStrings.copy_link}
                            </a>
                          </div>
                        </div>
                        {copied ? (
                          <div className="flex items-center m-2 justify-end">
                            {LocaleStrings.copied}
                          </div>
                        ) : null}
                      </div>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const validate = (values, ownProps) => {};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    localLanguage: state.localLanguage,
    deviceSize: state.deviceSize,
    productShareModal: state.productShareModal,
    bidSuggestion: state.bidSuggestion,
    productCounter: state.productCounter,
    localCurrency: state.localCurrency,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isProductShareModalOpen,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "ShareForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Share)
);
