import React, { useState, useRef, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import Dropzone from "react-dropzone";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  currencyCode,
} from "../../../components/Common/product-model";
import Loader from "../../../components/Common/loader";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import DeliverytoIcon from "../../../assets/images/deliverto.svg";
import ImgUploader from "../../../assets/images/attach-file.svg";
import {
  isReturnReplaceOpen,
  updateOrder,
  fetchOrderDetails,
  uploadStatusMedia,
  makeReplaceOrder,
  makeReplaceRefund,
  makeReplaceNoPay,
} from "../actions";
import {
  renderFieldTextarea,
  renderFieldCheckbox,
  renderFieldRadio2,
} from "../../../components/Common/base-component";
import toast, { Toaster } from "react-hot-toast";
import { parseWithOptions } from "date-fns/fp";

function ReturnReplace(props) {
  const variantRef = useRef();
  const {
    session,
    isLoggedIn,
    handleSubmit,
    localLanguage,
    returnReplaceModal,
    orderDetails,
    deviceSize,
    initialValues,
    orderReplaceVariant,
    selectedType,
    setSelectedType,
    localCurrency,
    setValidationVar,
    validationVar,
    rproductVariants,
    rsetProductVariants,
    rallVariants,
    rsetAllVariants,
    rvariantImageId,
    rsetVariantImageId,
    rselectedVariant,
    rsetSelectedVariant,
    setVariantId,
    variantId,
  } = props;

  const [loading, setLoading] = useState(false);
  const [fileArr, setFileArr] = useState([]);
  //const [selectedVariant, setSelectedVariant] = useState({});
  const [pay, setPay] = useState(false);
  const [refund, setRefund] = useState(false);
  const [amount, setAmount] = useState(0);
  const [makeneworder, setMakeneworder] = useState(false);
  const [isOutofstock, setIsOutofstock] = useState(false);
  const [allVariants, setAllVariants] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [reason, setReason] = useState([]);
  const [otherReason, setOtherReason] = useState(false);

  let reasonOptions = [
    {
      text: LocaleStrings.reason_item_match,
      value: "item_match",
    },
    {
      text: LocaleStrings.reason_item_color,
      value: "item_color",
    },
    {
      text: LocaleStrings.reason_item_damage,
      value: "item_damage",
    },
    { text: LocaleStrings.reason_other, value: "other" },
  ];

  useEffect(() => {
    console.log("validationVar", validationVar);
    console.log("rselectedVariant", rselectedVariant);
    if (validationVar) {
      if (
        rselectedVariant &&
        rselectedVariant.length > 0 &&
        rselectedVariant[0].id
      ) {
        setPay(rselectedVariant[0].pay);
        setRefund(rselectedVariant[0].refund);
        setAmount(rselectedVariant[0].amount);
      }
    }
  }, []);

  const closeSlideOvers = () => {
    props.reset();
    emptyFiles();
    props.isReturnReplaceOpen(false);
  };

  const onSubmitForm = (values) => {
    let filetobeupload = [];
    if (fileArr.length > 0) {
      fileArr.map((item, index) => {
        let ext = item.name.split(".").pop();
        let ts = new Date().getTime();
        let covermedia = ts + index + "." + ext;
        let coverJson = {
          medianame: covermedia,
          media: item.url,
        };
        filetobeupload.push(coverJson);
      });
    }

    let returnreason = "";
    if (values.item_match) {
      let filter = _.filter(reasonOptions, (obj) => {
        return obj.value == "item_match";
      });
      if (filter.length > 0) {
        returnreason += filter[0].text;
      }
    }
    if (values.item_color) {
      let filter = _.filter(reasonOptions, (obj) => {
        return obj.value == "item_color";
      });
      if (filter.length > 0) {
        returnreason += filter[0].text;
      }
    }
    if (values.item_damage) {
      let filter = _.filter(reasonOptions, (obj) => {
        return obj.value == "item_damage";
      });
      if (filter.length > 0) {
        returnreason += filter[0].text;
      }
    }
    if (values.reasontext) {
      returnreason += returnreason
        ? ". " + values.reasontext
        : values.reasontext;
    }

    // console.log("values", values);
    // console.log("returnreason", returnreason);
    // debugger;

    let updateData = {};
    updateData.status =
      refund || pay || makeneworder ? "return" : values.status;
    updateData.returnreason = returnreason;
    updateData.orderid = orderDetails.orderid;
    updateData.orderdetailsid = orderDetails.orderdetailsid;

    console.log("updateData", updateData);
    console.log("validationVar", validationVar);
    console.log("updateData", updateData);
    console.log("rselectedVariant", rselectedVariant);
    console.log("orderDetails", orderDetails);
    debugger;

    if (validationVar) {
      if (
        rselectedVariant &&
        rselectedVariant.length > 0 &&
        rselectedVariant[0].id
      ) {
        setLoading(true);

        if (filetobeupload.length > 0) {
          let counter = filetobeupload.length;
          let uploaderCounter = 0;
          let arrPromise = [];
          let statusmedia = [];

          filetobeupload.map((item, index) => {
            arrPromise.push(
              new Promise((resolve, reject) => {
                props.uploadStatusMedia(session, item, (response) => {
                  if (response.success === 0) {
                    resolve();
                  } else {
                    uploaderCounter++;
                    resolve();
                    statusmedia.push(item.medianame);
                  }
                });
              })
            );
          });

          Promise.all(arrPromise).then(() => {
            if (counter == uploaderCounter) {
              updateData.statusmedia = statusmedia;
              props.updateOrder(session, updateData, (response) => {
                if (response.success == 1) {
                  // make new payment
                  if (pay) {
                    let replaceData = {};
                    replaceData.replace_orderdetailsid =
                      orderDetails.orderdetailsid;
                    replaceData.variantid = rselectedVariant[0].id;
                    props.makeReplaceOrder(session, replaceData, (feedback) => {
                      if (feedback.checkout_url) {
                        closeSlideOvers();
                        window.location.href = feedback.checkout_url;
                      } else {
                        setLoading(false);
                        closeSlideOvers();
                        toast.error(LocaleStrings.common_fail_message);
                      }
                    });
                  } else if (refund) {
                    // Refund

                    let replaceData = {};
                    replaceData.replace_orderdetailsid =
                      orderDetails.orderdetailsid;
                    replaceData.variantid = rselectedVariant[0].id;
                    props.makeReplaceRefund(
                      session,
                      replaceData,
                      (feedback) => {
                        setLoading(false);

                        if (feedback.success == 1) {
                          props.fetchOrderDetails(
                            session,
                            orderDetails.orderdetailsid,
                            (res) => {
                              toast.success(
                                orderDetails?.refund_type == "1"
                                  ? LocaleStrings.return_success
                                  : LocaleStrings.replace_success
                              );
                              closeSlideOvers();
                            }
                          );
                        } else {
                          closeSlideOvers();
                          toast.error(LocaleStrings.common_fail_message);
                        }
                      }
                    );
                  } else if (makeneworder) {
                    // sama price other variant
                    let replaceData = {};
                    replaceData.replace_orderdetailsid =
                      orderDetails.orderdetailsid;
                    replaceData.variantid = rselectedVariant[0].id;
                    // console.log("replaceData", replaceData);
                    // debugger;

                    props.makeReplaceNoPay(session, replaceData, (feedback) => {
                      setLoading(false);

                      if (feedback.success == 1) {
                        props.fetchOrderDetails(
                          session,
                          orderDetails.orderdetailsid,
                          (res) => {
                            toast.success(
                              orderDetails?.refund_type == "1"
                                ? LocaleStrings.return_success
                                : LocaleStrings.replace_success
                            );
                            closeSlideOvers();
                          }
                        );
                      } else {
                        closeSlideOvers();
                        toast.error(LocaleStrings.common_fail_message);
                      }
                    });
                  } else if (rselectedVariant[0].id == orderDetails.variantid) {
                    let replaceData = {};
                    replaceData.replace_orderdetailsid =
                      orderDetails.orderdetailsid;
                    replaceData.variantid = rselectedVariant[0].id;
                    // console.log("replaceData", replaceData);
                    // debugger;

                    props.makeReplaceNoPay(session, replaceData, (feedback) => {
                      setLoading(false);

                      if (feedback.success == 1) {
                        props.fetchOrderDetails(
                          session,
                          orderDetails.orderdetailsid,
                          (res) => {
                            toast.success(
                              orderDetails?.refund_type == "1"
                                ? LocaleStrings.return_success
                                : LocaleStrings.replace_success
                            );
                            closeSlideOvers();
                          }
                        );
                      } else {
                        closeSlideOvers();
                        toast.error(LocaleStrings.common_fail_message);
                      }
                    });
                  } else {
                    props.fetchOrderDetails(
                      session,
                      orderDetails.orderdetailsid,
                      (res) => {
                        toast.success(
                          updateData.status == "replace"
                            ? LocaleStrings.replace_success
                            : LocaleStrings.return_success
                        );
                        setLoading(false);
                        closeSlideOvers();
                      }
                    );
                  }
                } else {
                  setLoading(false);
                  toast.error(LocaleStrings.common_fail_message);
                  closeSlideOvers();
                }
              });
            } else {
              setLoading(false);
              toast.error(LocaleStrings.common_fail_message);
              closeSlideOvers();
            }
          });
        } else {
          props.updateOrder(session, updateData, (response) => {
            setLoading(false);
            if (response.success == 1) {
              // make new payment
              if (pay) {
                let replaceData = {};
                replaceData.replace_orderdetailsid =
                  orderDetails.orderdetailsid;
                replaceData.variantid = rselectedVariant[0].id;
                props.makeReplaceOrder(session, replaceData, (feedback) => {
                  setLoading(false);

                  if (feedback.checkout_url) {
                    closeSlideOvers();
                    window.location.href = feedback.checkout_url;
                  } else {
                    closeSlideOvers();
                    toast.error(LocaleStrings.common_fail_message);
                  }
                });
              } else if (refund) {
                // Refund

                let replaceData = {};
                replaceData.replace_orderdetailsid =
                  orderDetails.orderdetailsid;
                replaceData.variantid = rselectedVariant[0].id;
                props.makeReplaceRefund(session, replaceData, (feedback) => {
                  setLoading(false);

                  if (feedback.success == 1) {
                    props.fetchOrderDetails(
                      session,
                      orderDetails.orderdetailsid,
                      (res) => {
                        toast.success(
                          updateData.status == "replace"
                            ? LocaleStrings.replace_success
                            : LocaleStrings.return_success
                        );
                        closeSlideOvers();
                      }
                    );
                  } else {
                    closeSlideOvers();
                    toast.error(LocaleStrings.common_fail_message);
                  }
                });
              } else if (makeneworder) {
                // sama price other variant
                let replaceData = {};
                replaceData.replace_orderdetailsid =
                  orderDetails.orderdetailsid;
                replaceData.variantid = rselectedVariant[0].id;
                // console.log("replaceData", replaceData);
                // debugger;

                props.makeReplaceNoPay(session, replaceData, (feedback) => {
                  setLoading(false);

                  if (feedback.success == 1) {
                    props.fetchOrderDetails(
                      session,
                      orderDetails.orderdetailsid,
                      (res) => {
                        toast.success(
                          updateData.status == "replace"
                            ? LocaleStrings.replace_success
                            : LocaleStrings.return_success
                        );
                        closeSlideOvers();
                      }
                    );
                  } else {
                    closeSlideOvers();
                    toast.error(LocaleStrings.common_fail_message);
                  }
                });
              } else if (rselectedVariant[0].id == orderDetails.variantid) {
                let replaceData = {};
                replaceData.replace_orderdetailsid =
                  orderDetails.orderdetailsid;
                replaceData.variantid = rselectedVariant[0].id;
                // console.log("replaceData", replaceData);
                // debugger;

                props.makeReplaceNoPay(session, replaceData, (feedback) => {
                  setLoading(false);

                  if (feedback.success == 1) {
                    props.fetchOrderDetails(
                      session,
                      orderDetails.orderdetailsid,
                      (res) => {
                        toast.success(
                          orderDetails?.refund_type == "1"
                            ? LocaleStrings.return_success
                            : LocaleStrings.replace_success
                        );
                        closeSlideOvers();
                      }
                    );
                  } else {
                    closeSlideOvers();
                    toast.error(LocaleStrings.common_fail_message);
                  }
                });
              } else {
                props.fetchOrderDetails(
                  session,
                  orderDetails.orderdetailsid,
                  (res) => {
                    toast.success(
                      updateData.status == "replace"
                        ? LocaleStrings.replace_success
                        : LocaleStrings.return_success
                    );
                    setLoading(false);
                    closeSlideOvers();
                  }
                );
              }
            } else {
              toast.error(LocaleStrings.common_fail_message);
              closeSlideOvers();
            }
          });
        }
      } else {
        variantRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        toast.error(LocaleStrings.variant_not_selected);
      }
    } else {
      // console.log("else updateData", updateData);
      // debugger;
      setLoading(true);

      if (filetobeupload.length > 0) {
        let counter = filetobeupload.length;
        let uploaderCounter = 0;
        let arrPromise = [];
        let statusmedia = [];

        filetobeupload.map((item, index) => {
          arrPromise.push(
            new Promise((resolve, reject) => {
              props.uploadStatusMedia(session, item, (response) => {
                if (response.success === 0) {
                  resolve();
                } else {
                  uploaderCounter++;
                  resolve();
                  statusmedia.push(item.medianame);
                }
              });
            })
          );
        });

        Promise.all(arrPromise).then(() => {
          if (counter == uploaderCounter) {
            updateData.statusmedia = statusmedia;
            props.updateOrder(session, updateData, (response) => {
              setLoading(false);
              if (response.success == 1) {
                props.fetchOrderDetails(
                  session,
                  orderDetails.orderdetailsid,
                  (res) => {
                    toast.success(
                      updateData.status == "replace"
                        ? LocaleStrings.replace_success
                        : LocaleStrings.return_success
                    );
                    closeSlideOvers();
                  }
                );
              } else {
                toast.error(LocaleStrings.common_fail_message);
                closeSlideOvers();
              }
            });
          } else {
            setLoading(false);
            toast.error(LocaleStrings.common_fail_message);
            closeSlideOvers();
          }
        });
      } else {
        props.updateOrder(session, updateData, (response) => {
          setLoading(false);
          if (response.success == 1) {
            props.fetchOrderDetails(
              session,
              orderDetails.orderdetailsid,
              (res) => {
                toast.success(
                  updateData.status == "replace"
                    ? LocaleStrings.replace_success
                    : LocaleStrings.return_success
                );
                closeSlideOvers();
              }
            );
          } else {
            toast.error(LocaleStrings.common_fail_message);
            closeSlideOvers();
          }
        });
      }
    }
  };

  const reasonHandle = (e, value) => {
    let reasonArr = reason;

    if (e.target.checked == true && value != "other") {
      reasonArr.push(value);
      setReason(reasonArr);
    } else {
      let filter = _.filter(reasonArr, (obj) => {
        return obj != value;
      });
      setReason(filter);
    }
  };

  const onDrop = (files) => {
    let filearr = [...fileArr];

    for (var i = 0; i < files.length; i++) {
      var file = files[i];
      var picReader = new FileReader();
      picReader.addEventListener("load", function (event) {
        var picFile = event.target;
        filearr.push({ name: file.name, url: picFile.result });
        setFileArr(filearr);
      });
      //Read the image
      picReader.readAsDataURL(file);
    }
  };

  /**** Input File select ****/
  const _handleSelect = (event) => {
    let filearr = [...fileArr];
    const reader = new FileReader(); // Define reader
    let file = event.target.files[0]; // Take Selected file
    reader.readAsArrayBuffer(file); // Read the file

    // On File Load
    reader.onload = (e) => {
      file.url = e.target.result;
    };

    let ext = file.name.split(".").pop(); // File extension
    filearr.push(file);
    setFileArr(filearr);
  };

  /**** RED cross Click ****/
  const _handleUnSelect = (e) => {
    setFileArr([]);
  };

  const emptyFiles = () => {
    setFileArr([]);
  };

  const renderVariantMedia = (variantnames, pos) => {
    if (variantnames && variantnames.length > 0) {
      return _.map(variantnames, (vari, index) => {
        return (
          <>
            <div
              className={classNames(
                rselectedVariant.product_variant_id == vari.media[0].idvariant
                  ? "border border-variantSel"
                  : "",
                "rounded flex justify-center items-center p-4"
              )}
            >
              <img
                className="object-cover cursor-pointer"
                src={`${FILES_URL}/vendor/variations/${vari.media[0].thumbfilename}?api_key=${APP_API_KEY}`}
                alt=""
              />
            </div>
          </>
        );
      });
    }
  };

  const renderProductVariant = (variantnames, pos) => {
    if (variantnames && variantnames.length > 0) {
      return _.map(variantnames, (vari, index) => {
        return (
          <div
            onClick={
              vari.selected
                ? null
                : () => makeVariantSelection(vari.refkey, pos)
            }
            className={classNames(
              vari.selected
                ? "border border-variantSel text-vendorLink bg-white"
                : " text-footers bg-shipping ",
              "px-2 py-6 rounded text-xs md:text-base font-medium flex items-center justify-center cursor-pointer"
            )}
            key={index}
          >
            {ProductTextLocalized(vari.name_en, vari.name_ar, localLanguage)}
          </div>
        );
      });
    }
  };

  // const makeVariantSelection = (obj) => {
  //   let sVariantid = obj.product_variant_id;
  //   let amount = obj.amount;
  //   let refund = obj.refund;
  //   let pay = obj.pay;
  //   setAmount(amount);
  //   setRefund(refund);
  //   setPay(pay);
  //   setSelectedVariant(obj);
  // };

  const makeVariantSelection = (refkey, pos) => {
    if (rproductVariants && rproductVariants.length > 0) {
      let productVariants = [];
      let selectedVariantKeys = [];
      _.map(rproductVariants, (obj, row) => {
        let variantnames = [];
        if (obj.variantnames && obj.variantnames.length > 0) {
          _.map(obj.variantnames, (data, index) => {
            if (pos == row) {
              data.selected = data.refkey == refkey ? true : false;
              if (data.selected) {
                selectedVariantKeys.push(refkey);
              }
            } else {
              data.selected = data.selected;
              if (data.selected) {
                selectedVariantKeys.push(data.refkey);
              }
            }

            variantnames.push(data);
          });
        }

        obj.variantnames = variantnames;
        productVariants.push(obj);
      });
      rsetProductVariants(productVariants);

      let selectedVariantKey = selectedVariantKeys.join("-");
      //console.log("selectedVariantKey", selectedVariantKey);
      if (rallVariants && rallVariants.length > 0) {
        let allVariants = [];

        _.map(rallVariants, (obj, index) => {
          obj.selected = obj.refkey == selectedVariantKey ? true : false;
          allVariants.push(obj);
        });
        rsetAllVariants(allVariants);
        let selectedVariant = _.filter(allVariants, function (o) {
          return o.selected;
        });
        if (selectedVariant.length > 0) {
          let amount = selectedVariant[0].amount;
          let refund = selectedVariant[0].refund;
          let pay = selectedVariant[0].pay;
          setAmount(amount);
          setRefund(refund);
          setPay(pay);
          rsetSelectedVariant(selectedVariant);
          let outofstock = selectedVariant[0].outofstock == 1 ? true : false;
          setIsOutofstock(outofstock);

          if (
            pay == false &&
            refund == false &&
            selectedVariant[0].id != orderDetails.variantid
          ) {
            setMakeneworder(true);
          } else {
            setMakeneworder(false);
          }
        }

        if (
          selectedVariant &&
          selectedVariant.length > 0 &&
          selectedVariant[0].media &&
          selectedVariant[0].media.length > 0
        ) {
          let variantImageId = selectedVariant[0].media[0].id;
          //console.log("variantImageId", variantImageId);
          rsetVariantImageId(variantImageId);
        }
      }
    }
  };

  const getStatus = (e) => {
    //console.log("value", e.target.value);
    setSelectedType(e.target.value);
    if (e.target.value == "return") {
      // setAmount(0);
      // setRefund(false);
      // setPay(false);
      // rsetSelectedVariant({});
      // setValidationVar(false);
    } else {
      if (rproductVariants && rproductVariants.length > 0) {
        setValidationVar(true);

        if (rallVariants && rallVariants.length > 0) {
          let selectedVariant = _.filter(rallVariants, function (o) {
            return o.selected;
          });
          if (selectedVariant.length > 0) {
            let outofstock = selectedVariant[0].outofstock == 1 ? true : false;
            setIsOutofstock(outofstock);
          }
        }
      } else {
        setValidationVar(false);
      }
    }
  };

  let returnOptions = [];
  if (
    !_.isEmpty(orderDetails) &&
    orderDetails.order_details &&
    orderDetails.order_details.relatedorder &&
    orderDetails.order_details.relatedorder.replace_orderdetailsid
  ) {
    returnOptions.push({ text: LocaleStrings.return_product, value: "return" });
  } else if (orderDetails?.refund_type == "1") {
    returnOptions.push({ text: LocaleStrings.return_product, value: "return" });
    returnOptions.push({
      text: LocaleStrings.replace_product,
      value: "replace",
    });
  } else {
    returnOptions.push({
      text: LocaleStrings.replace_product,
      value: "replace",
    });
    // returnOptions.push({ text: LocaleStrings.return_product, value: "return" });
  }

  let currency = currencyCode(localCurrency);
  //console.log("fileArr", fileArr);

  let selectedVariantImage = [];
  if (rselectedVariant && rselectedVariant.length > 0 && rvariantImageId) {
    selectedVariantImage = _.filter(rselectedVariant[0].media, function (o) {
      return o.id == rvariantImageId;
    });
  }
  console.log("rselectedVariant", rselectedVariant);
  return (
    <>
      <Transition.Root show={returnReplaceModal} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          open={returnReplaceModal}
          onClose={() => closeSlideOvers()}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <form
                    onSubmit={handleSubmit(onSubmitForm)}
                    style={{
                      height: deviceSize.height,
                    }}
                  >
                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                      <div className="flex flex-col bg-white py-3">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="slider-modal-headtext">
                              <h2
                                id="slide-over-heading"
                                className="text-xl font-normal text-link"
                              >
                                {LocaleStrings.return_title}
                              </h2>
                            </Dialog.Title>
                            <div className="ml-3 h-7 flex items-center">
                              <button
                                type="button"
                                onClick={() => closeSlideOvers()}
                                className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="min-h-0 flex-1 flex flex-col overflow-y-scroll">
                        <div className="relative flex-1 bg-newarrival">
                          <div className="bg-white rounded my-5">
                            <div className="pl-15 py-sm border-b border-primary font-medium text-sm text-primary">
                              {LocaleStrings.order_id}&nbsp;:&nbsp;
                              {orderDetails.invoice_no}
                            </div>
                            <div className="flex px-15 pt-4 pb-4 ">
                              <div className="flex items-center justify-center w-113 h-113 p-5 rounded-md border border-primary">
                                <img
                                  className="rounded-md object-cover"
                                  src={`${FILES_URL}/vendor/variations/${orderDetails.productimage}?api_key=${APP_API_KEY}`}
                                />
                              </div>
                              <div className="w-4/5 pl-4">
                                <div className="font-medium text-primary text-sm">
                                  {ProductTextLocalized(
                                    orderDetails.productname_en,
                                    orderDetails.productname_ar,
                                    localLanguage
                                  )}
                                </div>
                                <div className="text-cardfooter text-sm">
                                  {LocaleStrings.seller}&nbsp;:&nbsp;
                                  {orderDetails?.seller?.businessname}
                                </div>
                                <div className="text-cardfooter text-sm">
                                  {LocaleStrings.sku}&nbsp;:&nbsp;
                                  {orderDetails?.sku}
                                </div>
                                <div className="text-cardfooter text-sm">
                                  {LocaleStrings.quantity}&nbsp;:&nbsp;
                                  {orderDetails?.quantity}
                                </div>
                                <div className="text-primary text-sm font-medium">
                                  {currency}
                                  {orderDetails?.originalprice}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="bg-white rounded my-5 px-4">
                            <div className="text-loginLabel font-normal text-sm py-3">
                              {LocaleStrings.return_heading_text}
                            </div>
                            <div className="">
                              {_.map(returnOptions, (opt, index) => {
                                return (
                                  <Field
                                    name="status"
                                    label={opt.text}
                                    value={opt.value}
                                    type="radio"
                                    component={renderFieldRadio2}
                                    classNameLabel="pl-3 text-loginLabel text-sm font-normal"
                                    onChange={(e) => getStatus(e)}
                                  />
                                );
                              })}
                            </div>
                            <div className="text-loginLabel font-normal text-sm mt-md">
                              {LocaleStrings.select_reason_text}
                            </div>
                            <div className="text-loginLabel font-normal text-sm">
                              ({LocaleStrings.applicable_text})
                            </div>
                            <div className="mt-md">
                              {_.map(reasonOptions, (opt, index) => {
                                return (
                                  <Field
                                    name={opt.value}
                                    label={opt.text}
                                    type="checkbox"
                                    className="focus:ring-primary h-4 w-4 text-primary border-ternary rounded"
                                    component={renderFieldCheckbox}
                                    classNameLabel="text-loginLabel text-sm font-normal"
                                    onChange={(e) =>
                                      reasonHandle(
                                        e,
                                        index == 3 ? opt.value : opt.text
                                      )
                                    }
                                  />
                                );
                              })}
                            </div>

                            <div className="mt-md">
                              <Field
                                name="reasontext"
                                placeholder={LocaleStrings.explain_placeholder}
                                type="textarea"
                                component={renderFieldTextarea}
                                className="p-3 appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm text-loginLabel"
                                rows="8"
                              />
                            </div>

                            <div className="mt-md pb-3">
                              <div className="grid grid-cols-3">
                                <div className="col-span-2  py-2 mt-md text-loginLabel font-normal text-sm">
                                  <div className=" flex">
                                    <span className="text-sm text-ternary">
                                      {_.size(fileArr) > 0
                                        ? _.size(fileArr)
                                        : ""}{" "}
                                      {_.size(fileArr) > 1
                                        ? LocaleStrings.files_attached
                                        : _.size(fileArr) > 0
                                        ? LocaleStrings.file_attached
                                        : ""}
                                    </span>
                                    <img
                                      className={classNames(
                                        _.size(fileArr) > 0 ? "" : "hidden",
                                        "h-4 w-4 mx-1 cursor-pointer"
                                      )}
                                      src={"../images/red-cross.svg"}
                                      onClick={(e) => _handleUnSelect(e)}
                                    />
                                  </div>
                                </div>
                                <div className="text-center py-2 border border-blue-200 rounded-md text-loginLabel font-normal text-sm mt-md">
                                  {/* <Dropzone
                                    onDrop={(e) => onDrop(e)}
                                    accept="image/jpeg, image/png ,image.jpg"
                                    multiple={true}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div
                                        {...getRootProps()}
                                        className="h-full"
                                      >
                                        <input {...getInputProps()} />

                                        <div className="cursor-pointer flex items-center justify-center paragraph-seconday text-xs">
                                          <img
                                            src={ImgUploader}
                                            className="w-6"
                                          />
                                          {" " +
                                            LocaleStrings.rate_product_attach}
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone> */}

                                  <label
                                    htmlFor="attachment"
                                    className="relative cursor-pointer flex items-center justify-center"
                                  >
                                    <img src={ImgUploader} className=" w-3" />

                                    <span className="pl-2">
                                      {LocaleStrings.rate_product_attach}
                                    </span>
                                    <input
                                      id="attachment"
                                      name="attachment"
                                      type="file"
                                      accept="image/*"
                                      className="sr-only"
                                      onChange={(e) => _handleSelect(e)}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* variant selection for replace */}
                          {selectedType == "replace" && validationVar ? (
                            <div className="bg-white rounded mb-4 px-15 py-sm">
                              <div className="grid grid-cols-1 md:grid-cols-2 gap-1 mb-3">
                                <div className="flex items-center text-left text-primary text-base font-normal">
                                  {LocaleStrings.choose_variant}
                                </div>
                              </div>
                              <div ref={variantRef}>
                                {orderReplaceVariant &&
                                orderReplaceVariant.hasOwnProperty(
                                  "product_variants"
                                ) ? (
                                  <div className="">
                                    {rproductVariants &&
                                    rproductVariants.length > 0
                                      ? _.map(
                                          rproductVariants,
                                          (prodV, index) => {
                                            return (
                                              <div key={index} className="pt-5">
                                                <div className="text-left text-variant font-medium text-sm md:text-base">
                                                  {ProductTextLocalized(
                                                    prodV.name_en,
                                                    prodV.name_ar,
                                                    localLanguage
                                                  )}
                                                </div>
                                                <div
                                                  className={classNames(
                                                    index + 1 <
                                                      rproductVariants.length
                                                      ? ""
                                                      : "",
                                                    "mt-2"
                                                  )}
                                                >
                                                  <div className="flex space-x-4">
                                                    {renderProductVariant(
                                                      prodV.variantnames,
                                                      index
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )
                                      : ""}

                                    <div className="flex text-left mt-5 text-primary font-normal text-sm">
                                      <div className="text-left text-cutoff font-normal text-sm">
                                        {LocaleStrings.selected_variant} :
                                        &nbsp;
                                      </div>
                                      <div className="text-left text-primary font-normal text-sm">
                                        {rselectedVariant &&
                                        rselectedVariant.length > 0
                                          ? ProductTextLocalized(
                                              rselectedVariant[0].name_en,
                                              rselectedVariant[0].name_ar,
                                              localLanguage
                                            )
                                          : ""}
                                      </div>
                                    </div>
                                    {selectedVariantImage &&
                                    selectedVariantImage.length > 0 ? (
                                      <div className="mt-5 flex items-center justify-center w-113 h-113 p-5 rounded-md border border-primary">
                                        <img
                                          className="rounded-md object-cover"
                                          src={`${FILES_URL}/vendor/variations/${selectedVariantImage[0].mediafilename}?api_key=${APP_API_KEY}`}
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="mt-5">
                                {pay && amount > 0 ? (
                                  <>
                                    <div className="text-sm text-black">
                                      {`${LocaleStrings.selected_price} ${currency} ${rselectedVariant[0].price}`}
                                    </div>
                                    <div className="text-sm text-black">
                                      {`${LocaleStrings.previous_paid} ${currency} ${rselectedVariant[0].orderprice}`}
                                    </div>
                                    <div className="text-sm text-link font-medium">
                                      {`${LocaleStrings.replace_pay} ${currency} ${amount}`}
                                    </div>
                                  </>
                                ) : refund && amount > 0 ? (
                                  <>
                                    <div className="text-sm text-black">
                                      {`${LocaleStrings.previous_paid} ${currency} ${rselectedVariant[0].orderprice}`}
                                    </div>
                                    <div className="text-sm text-black">
                                      {`${LocaleStrings.selected_price} ${currency} ${rselectedVariant[0].price}`}
                                    </div>
                                    <div className="text-sm text-link font-medium">
                                      {`${LocaleStrings.replace_refund} ${currency} ${amount}`}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="text-sm text-black">
                                      {`${LocaleStrings.previous_paid} ${currency} ${rselectedVariant[0].orderprice}`}
                                    </div>
                                    <div className="text-sm text-black">
                                      {`${LocaleStrings.selected_price} ${currency} ${rselectedVariant[0].price}`}
                                    </div>
                                    <div className="text-sm text-link font-medium">
                                      {`${LocaleStrings.no_payment_required}`}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="bg-white rounded mb-4 px-15 py-sm">
                            <div className="font-medium text-sm text-primary">
                              {LocaleStrings.deliverto}
                            </div>
                            <div className="flex pb-3.5 pt-1">
                              <div className="pr-3.5">
                                <div className="bg-bid rounded-full w-46 h-46 flex items-center justify-center">
                                  <img src={DeliverytoIcon} className="w-5" />
                                </div>
                              </div>
                              <div>
                                {!_.isEmpty(
                                  orderDetails?.order_details?.delivery_address
                                ) ? (
                                  <>
                                    <div className="text-primary font-normal text-base">
                                      {_.startCase(
                                        orderDetails?.order_details
                                          ?.delivery_address?.addresstype
                                      )}
                                      {" : "}
                                      {
                                        orderDetails?.order_details
                                          ?.delivery_address?.username
                                      }
                                    </div>
                                    {orderDetails?.order_details
                                      ?.delivery_address?.address ? (
                                      <div className="text-cardfooter font-normal text-xs">
                                        {
                                          orderDetails?.order_details
                                            ?.delivery_address?.address
                                        }
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex-shrink-0 px-4 py-3.5 flex relative">
                        {selectedType == "replace" && isOutofstock ? (
                          <button
                            disabled
                            className="text-white h-44 bg-progress_red text-sm font-normal rounded w-full inline-flex items-center justify-center"
                          >
                            {LocaleStrings.out_of_stock}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="text-white h-44 bg-btn text-sm font-normal rounded w-full inline-flex items-center justify-center"
                          >
                            {loading ? (
                              <svg
                                className="animate-spin h-5 w-5 rounded-full mr-3"
                                viewBox="0 0 1024 1024"
                                focusable="false"
                                data-icon="loading"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                              </svg>
                            ) : (
                              ""
                            )}
                            <span className="text-sm">
                              {pay && amount > 0 && selectedType == "replace"
                                ? LocaleStrings.confirm_and_pay
                                : refund && amount > 0
                                ? LocaleStrings.confirm_request
                                : LocaleStrings.confirm_request}
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const validate = (values, ownProps) => {
  var errors = {};
  if (
    !values.item_match &&
    !values.item_color &&
    !values.item_damage & !values.other
  ) {
    errors.reasontext = LocaleStrings.select_reason_error;
  } else if (values.other && !values.reasontext) {
    errors.reasontext = LocaleStrings.return_reason_error;
  }
  return errors;
};

const mapStateToProps = (state) => {
  var initVals = {};
  //console.log("initVals", state.orderDetails);
  if (
    state.orderDetails &&
    state.orderDetails.order_details &&
    state.orderDetails.order_details.relatedorder &&
    state.orderDetails.order_details.relatedorder.replace_orderdetailsid
  ) {
    initVals["status"] = "return";
  } else if (
    state.orderDetails &&
    state.orderDetails.refund_type &&
    state.orderDetails.refund_type == "1"
  ) {
    initVals["status"] = "return";
  } else {
    initVals["status"] = "replace";
  }

  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    localLanguage: state.localLanguage,
    deviceSize: state.deviceSize,
    returnReplaceModal: state.returnReplaceModal,
    orderDetails: state.orderDetails,
    initialValues: initVals,
    orderReplaceVariant: state.orderReplaceVariant,
    localCurrency: state.localCurrency,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isReturnReplaceOpen,
      updateOrder,
      fetchOrderDetails,
      uploadStatusMedia,
      makeReplaceOrder,
      makeReplaceRefund,
      makeReplaceNoPay,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "ReturnForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ReturnReplace)
);
