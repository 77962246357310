import React, { Component } from "react";
import LocaleStrings from "../../../languages/index";
class FAQNContactus extends Component {
  constructor(props) {
    super(props);
  }

  // All Binded Functions
  render() {
    return (
      <div className="w-full bottom-0">
        <ul role="list" className="my-5 divide-y divide-gray-200">
          <li
            key={"need-more-key"}
            className="py-4 bg-custWhite px-6 table-hover-transition "
          >
            <div className="flex items-center space-x-4">
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-primary">
                  {LocaleStrings.faq_footer_head_text}
                </p>
              </div>
            </div>
          </li>
          {/* Call Us */}
          <li
            key={"call-us-key"}
            className="py-4 bg-custWhite px-6  table-hover-transition "
          >
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <img
                  className="h-6 w-6"
                  src={"../profile_settings_images/telephone.svg"}
                  alt=""
                />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-ternary  ">
                  <a href="tel:+000 254698754" className="cursor-pointer">
                    {LocaleStrings.call_us} +000 254698754
                  </a>
                </p>
              </div>
            </div>
          </li>
          {/* Write to us */}
          <li
            key={"write-to-us-key"}
            className="py-4 bg-custWhite px-6 table-hover-transition"
          >
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <img
                  className="h-6 w-6"
                  src={"../profile_settings_images/envelope.svg"}
                  alt=""
                />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-ternary  ">
                  <a
                    href="mailto:support.email@gmail.com"
                    className="cursor-pointer"
                  >
                    {LocaleStrings.write_to_us} support.email@gmail.com
                  </a>
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default FAQNContactus;
