import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../languages";
import _ from "lodash";
// Import Actions
import { isVerficationModalOpen } from "../../actions";

// Component Import
import VerifyEmail from "./verifyemail";
import VerifyPhone from "./verifyphone";

/* This example requires Tailwind CSS v2.0+ */
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

class VendorVerification extends Component {
  constructor(props) {
    super(props);
    //console.log("props", props);
    this.state = {
      loading: true,
      btnLoader: false,
      step:
        props.consumer.emailverified === false
          ? 1
          : props.consumer.phoneverified === false
          ? 2
          : 0,
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.changeStep = this.changeStep.bind(this);
    this.skipEmailVerification = this.skipEmailVerification.bind(this);
  }

  changeStep(nextStep) {
    this.setState({ step: nextStep });
    if (nextStep === 0) {
      this.props.isVerficationModalOpen(false);
    }
  }

  skipEmailVerification() {
    this.setState({ step: 2 });
  }

  componentDidMount() {}

  /**** Fake Close ****/
  closeModal1() {}

  /**** Close Modal  ****/
  closeModal() {
    //this.props.isVerficationModalOpen(false);
  }

  render() {
    //console.log("isVerficationOpen", this.props.isVerficationOpen);
    let { ilanguage } = this.props;
    let { step } = this.state;
    let modalHeadText =
      step === 1
        ? LocaleStrings.verification_header_text1
        : step === 2
        ? LocaleStrings.verification_header_text2
        : LocaleStrings.verification_header_text3;
    let modalsubHeadText =
      step === 1
        ? LocaleStrings.verification_subheader_text1
        : step === 2
        ? LocaleStrings.verification_subheader_text1
        : LocaleStrings.verification_subheader_text1;
    //console.log("step", this.state.step);
    return (
      <>
        <Transition.Root show={this.props.isVerficationOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.isVerficationOpen}
            onClose={this.closeModal1}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div
                  className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: 0 }}
                >
                  {/* MODAL HEADER */}
                  {/* <div className="grid grid-cols-3 border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div></div>
                    <div className="flex flex-wrap content-center justify-center mt-4">
                      <div className="text-lg leading-6 h-6"></div>
                    </div>
                    <div className="absolute top-0 right-0 pt-4 pr-4 "></div>
                  </div> */}
                  {step === 1 ? (
                    <VerifyEmail
                      changeStep={this.changeStep}
                      skipEmailVerification={this.skipEmailVerification}
                    />
                  ) : step === 2 ? (
                    <VerifyPhone changeStep={this.changeStep} />
                  ) : (
                    ""
                  )}
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    localLanguage: state.localLanguage,
    isVerficationOpen: state.isVerficationOpen,
    consumer: state.user,
  };
}

export default connect(mapStateToProps, {
  isVerficationModalOpen,
})(VendorVerification);
