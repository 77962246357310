import LocaleStrings from "../../../languages/index";
import {
  SELECTED_PROFILESETTINGS_MENU,
  PROFILESETTINGS_MYFOLLOWERS,
  IS_NEGO_SLIDEOVERS_MODAL_OPEN,
  PROFILESETTINGS_MYFOLLOWERS_CURRENT,
  PROFILESETTINGS_MYFOLLOWERS_CURRENT_USERDATA,
  PROFILESETTINGS_CURUSER_SHAREDWISHLIST,
  PROFILESETTINGS_STOREDETAILS,
  PROFILESETTINGS_STORECONTACTS,
  IS_STOREINFO_MAP_MODAL_OPEN,
  MYPROFILE,
  IS_PROFILEINFO_COMMON_MODAL_OPEN,
  PROFILEINFO_SELECTED_GENDER,
  ACTSETTINGS_COUNTRIES,
  CAN_RECEIVED_NOTIFICATION,
  USER_NOTIFICATION_SETTINGS,
  EDIT_ADDRESS,
  USER_ACCOUNT,
} from "../actions/index";

/**** Profile Settings List ****/
export var profileSettingsList = (state = [], action) => {
  var state = [
    {
      name: LocaleStrings.profile_settings_list_text1,
      subname: LocaleStrings.profile_settings_list_subtext1,
      href: "",
      icon: "profile-information",
      current: false,
      position: 0,
    },
    {
      name: LocaleStrings.profile_settings_list_text2,
      subname: LocaleStrings.profile_settings_list_subtext2,
      href: "",
      icon: "address",
      current: false,
      position: 1,
    },
    // {
    //   name: LocaleStrings.profile_settings_list_text3,
    //   subname: LocaleStrings.profile_settings_list_subtext3,
    //   href: "",
    //   icon: "notification",
    //   current: false,
    //   position: 2,
    // },
    {
      name: LocaleStrings.profile_settings_list_text4,
      subname: LocaleStrings.profile_settings_list_subtext4,
      href: "",
      icon: "account-setting",
      current: false,
      position: 3,
    },
  ];

  return state;
};

/**** Store Selected Profile settings menu ****/
export var ProfileSettingsSelectedMenu = (state = 9, action) => {
  if (action.type == SELECTED_PROFILESETTINGS_MENU) {
    state = action.payload;
  }
  return state;
};

/**** Store My Followers List ****/
export var storeMyFollowersData = (state = {}, action) => {
  if (action.type == PROFILESETTINGS_MYFOLLOWERS) {
    state = action.payload;
  }
  return state;
};

/**** Store IS modal Open Or not Followers Details ****/
export var isFollowersDetailsModalOpen = (state = false, action) => {
  if (action.type === IS_NEGO_SLIDEOVERS_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/**** Store My Followers List For Selected User****/
export var storeCurrentMyFollowersData = (state = {}, action) => {
  if (action.type == PROFILESETTINGS_MYFOLLOWERS_CURRENT) {
    state = action.payload;
  }
  return state;
};

/**** Store My Selected Follower Users details ****/
export var storeCurrentMyFollowersUserData = (state = {}, action) => {
  if (action.type == PROFILESETTINGS_MYFOLLOWERS_CURRENT_USERDATA) {
    state = action.payload;
  }
  return state;
};

/**** Profile Settings My Followers Tabs  ****/
export var myFolloersTabList = (state = [], action) => {
  var state = [
    {
      name: LocaleStrings.profile_settings_myfollowers_sheardwhishlist,
      href: "",
      current: false,
      position: 0,
    },
    {
      name: LocaleStrings.profile_settings_myfollowers_Following,
      href: "",
      current: false,
      position: 1,
    },
  ];

  return state;
};

/**** Store My Followers selected User(curr) Shared Wishlist****/
export var currentShearedWishlist = (state = {}, action) => {
  if (action.type == PROFILESETTINGS_CURUSER_SHAREDWISHLIST) {
    state = action.payload;
  }
  return state;
};

/****** STORE INFORMATION ******/

/**** Store store Information ****/
export var StoreDetails = (state = {}, action) => {
  if (action.type == PROFILESETTINGS_STOREDETAILS) {
    state = action.payload;
  }
  return state;
};

/**** Store store Contacts ****/
export var StoreContacts = (state = {}, action) => {
  if (action.type == PROFILESETTINGS_STORECONTACTS) {
    state = action.payload;
  }
  return state;
};

/**** Store Info Map Modal ****/
export var isOpenStoreInfoMapModal = (state = false, action) => {
  if (action.type === IS_STOREINFO_MAP_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/* Store Verfication */

/**** User Details (users atble) with session Id Storage ****/
export var myProfile = (state = {}, action) => {
  if (action.type === MYPROFILE) {
    state = action.payload;
  }

  return state;
};

/* profile information */

/**** Profile Info Map Modal ****/
export var isOpenProfileInfoModal = (state = false, action) => {
  if (action.type === IS_PROFILEINFO_COMMON_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/**** Store selected gender ****/
export var storeSelectedGender = (state = "", action) => {
  if (action.type === PROFILEINFO_SELECTED_GENDER) {
    state = action.payload;
  }

  return state;
};

//ACCOUNT SETTINGS
/**** Store Countries List ****/
export var storeCountries = (state = {}, action) => {
  if (action.type == ACTSETTINGS_COUNTRIES) {
    state = action.payload;
  }
  return state;
};

export var canReceivedNotification = (state = "", action) => {
  if (action.type === CAN_RECEIVED_NOTIFICATION) {
    state = action.payload;
  }

  return state;
};

export var notificationSetting = (state = {}, action) => {
  if (action.type == USER_NOTIFICATION_SETTINGS) {
    state = action.payload;
  }
  return state;
};

export var editAddress = (state = {}, action) => {
  if (action.type == EDIT_ADDRESS) {
    state = action.payload;
  }
  return state;
};

export var bankAccount = (state = {}, action) => {
  if (action.type == USER_ACCOUNT) {
    state = action.payload;
  }
  return state;
};
