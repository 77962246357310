import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import { emailVerify } from "../actions";
import toast, { Toaster } from "react-hot-toast";
import EmailVerify from "../../../assets/images/email_verified.png";
import EmailAlreadyVerify from "../../../assets/images/already_verified.png";
import LogoEn from "../../../assets/images/logo_en.png";
import LogoAr from "../../../assets/images/logo_ar.png";
function VerifyEmail(props) {
  const history = useHistory();
  const location = useLocation();
  let { session, deviceSize, handleSubmit, localLanguage, forgotPwdOpen } =
    props;

  const [loading, setLoading] = useState(0);

  useEffect(() => {
    const email = props.match.params.email;
    let values = {};
    values.email = email;
    props.emailVerify(values, (res) => {
      if (res.success == 1) {
        setLoading(1);
        //toast.success(LocaleStrings.verifyemail_success);
      } else if (res.success == 2) {
        setLoading(2);
        //toast.error(LocaleStrings.verifyemail_already);
      } else {
        setLoading(3);
        //toast.error(LocaleStrings.common_fail_message);
      }
    });
  }, []);

  return (
    <>
      <div className="flex items-center justify-center pt-4">
        <img
          src={(localLanguage = "ar" ? LogoAr : LogoEn)}
          className="w-100 h-8 cursor-pointer"
        />
      </div>
      <div
        className="rounded-lg flex items-center justify-center py-5 custom_container"
        style={{ height: "100vh" }}
      >
        {loading == 1 ? (
          <img src={EmailVerify} />
        ) : loading == 2 ? (
          <img src={EmailAlreadyVerify} />
        ) : loading == 3 ? (
          <img src={EmailAlreadyVerify} />
        ) : (
          <Loader data={LocaleStrings.loader_message} />
        )}
      </div>
    </>
  );
}
const validate = (values) => {
  var errors = {};

  return errors;
};

const mapStateToProps = (state, ownprops) => {
  var initVals = {};

  return {
    // isLoggedIn: state.isLoggedIn,
    session: state.session,
    deviceSize: state.deviceSize,
    localLanguage: state.localLanguage,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ emailVerify }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "LoginForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(VerifyEmail)
);
