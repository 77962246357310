import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import {
  renderFieldText,
  renderSelect,
} from "../../../../components/Common/base-component";
import {
  classNames,
  isEmpty,
  LABEL_POSITION_TOP,
} from "../../../../components/Common/constant";
import toast, { Toaster } from "react-hot-toast";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import LocaleStrings from "../../../../languages/index";
import {
  fetchCountries,
  updateAccount,
  addAccount,
  fetchAccount,
} from "../../actions/";
// Component Import

/* This example requires Tailwind CSS v2.0+ */

class ActSettingsEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
    };

    // All Binded Functions
  }

  componentDidMount() {
    let { session } = this.props;
    this.props.fetchAccount(session, (callBackResponce) => {});
    this.props.fetchCountries(session, (res) => {});
  }

  componentWillReceiveProps(nextProps) {}

  /**** Modal Open Function  ****/

  /***** Form Submit *****/
  onFormSubmit(values) {
    if (this.props.initialValues && this.props.initialValues.id) {
      this.setState({ btnLoader: true });
      this.setState({ isBtnDisable: true });
      values.iban_code =
        values.country_code + values.bank_code + values.bank_account_number;
      this.props.updateAccount(
        this.props.session,
        values,
        this.props.initialValues.id,
        (res) => {
          if (res.success == 1) {
            this.props.fetchAccount(this.props.session, (callBackResponce) => {
              this.setState({ btnLoader: false });
              this.setState({ isBtnDisable: false });
              toast.success(LocaleStrings.account_update_success);
            });
          } else {
            this.setState({ btnLoader: false });
            this.setState({ isBtnDisable: false });
            toast.error(LocaleStrings.common_fail_message);
          }
        }
      );
    } else {
      this.setState({ btnLoader: true });
      this.setState({ isBtnDisable: true });
      values.useridfk = this.props.session.userid;
      values.iban_code =
        values.country_code + values.bank_code + values.bank_account_number;
      this.props.addAccount(this.props.session, values, (res) => {
        if (res.success == 1) {
          this.props.fetchAccount(this.props.session, (callBackResponce) => {
            this.setState({ btnLoader: false });
            this.setState({ isBtnDisable: false });
            toast.success(LocaleStrings.account_add_success);
          });
        } else {
          this.setState({ btnLoader: false });
          this.setState({ isBtnDisable: false });
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    }
  }

  handleChange = (e) => {
    console.log("value", e.target.value);
    console.log("name", e.target.name);
    console.log("initialValues", this.props.initialValues);
  };

  onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) event.preventDefault();
  };

  render() {
    var countriesOptions = []; // Countries
    let {
      initialValues,
      language,
      handleSubmit,
      storeCountries,
      bankAccount,
      editMode,
    } = this.props;

    //Discount Area Object Creation
    _.forEach(storeCountries, function (value) {
      var obj = {
        name: value.name,
        value: value.countrycode,
      };

      countriesOptions.push(obj);
    });

    return (
      <>
        <div className=" px-4 ">
          <div className="flow-root mt-8 bg-custWhite">
            <form
              className=" "
              onSubmit={handleSubmit(this.onFormSubmit.bind(this))}
            >
              <div className="p-0 md:p-10 space-y-8">
                <div className="">
                  <p className="text-sm font-medium text-secondary pb-4">
                    {LocaleStrings.profile_settings_actsettings_terms_text}
                  </p>
                </div>
                <Field
                  name="benificiary_name"
                  label={LocaleStrings.benificiary_name}
                  placeholder={LocaleStrings.benificiary_name}
                  type="text"
                  component={renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                />
                <Field
                  name="benificiary_bankname"
                  label={LocaleStrings.benificiary_bankname}
                  placeholder={LocaleStrings.benificiary_bankname}
                  type="text"
                  component={renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                />
                <Field
                  name="bank_account_number"
                  label={LocaleStrings.account_number}
                  placeholder={LocaleStrings.account_number}
                  type="text"
                  component={renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  maxlength="21"
                  classNameLabel="text-sm font-medium text-secondary"
                />
                <Field
                  name="bank_code"
                  label={LocaleStrings.bank_code}
                  placeholder={LocaleStrings.bank_code}
                  type="text"
                  component={renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  maxlength="4"
                  classNameLabel="text-sm font-medium text-secondary"
                />
                <Field
                  name="country_code"
                  label={LocaleStrings.country_code}
                  placeholder={LocaleStrings.country_code}
                  component={renderSelect}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  opts={countriesOptions}
                  //className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                  classNameLabel="text-sm font-medium text-secondary"
                  className="appearance-none block w-full h-38 px-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                  //onChange={this.handleChange.bind(this)}
                />
                <Field
                  name="benificiary_contactno"
                  label={LocaleStrings.benificiary_contactno}
                  placeholder={LocaleStrings.benificiary_contactno}
                  type="text"
                  component={renderFieldText}
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-secondary"
                />
                {/* <Field
                  name="check_code"
                  label={LocaleStrings.check_digits}
                  placeholder={LocaleStrings.check_digits}
                  type="number"
                  component={renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  maxlength="2"
                  onKeyPress={this.onKeyPress}
                  classNameLabel="text-sm font-medium text-secondary"
                  //onChange={this.handleChange.bind(this)}
                /> */}

                {/* <Field
                  name="iban_code"
                  label={LocaleStrings.iban}
                  placeholder={LocaleStrings.iban}
                  type="text"
                  component={renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  readonly={true}
                  classNameLabel="text-sm font-medium text-secondary"
                /> */}
              </div>
              <div className="p-6 border-t-2 border-quaternary">
                <div className="flex justify-center">
                  <button
                    disabled={this.state.isBtnDisable}
                    className={classNames(
                      this.state.isBtnDisable ? "cursor-not-allowed" : "",
                      "text-white bg-btn text-sm font-normal pt-2.5 pb-2.5 pl-9 pr-9 rounded btn-heavy"
                    )}
                    type="submit"
                  >
                    <svg
                      className={classNames(
                        this.state.btnLoader ? "" : "sr-only",
                        "animate-spin-medium h-5 w-5 rounded-full mx-2"
                      )}
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="loading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                    {editMode
                      ? LocaleStrings.update_account
                      : LocaleStrings.add_account}
                  </button>
                </div>

                {/* Footer */}
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  var regex = /^\s+$/;
  var myRegEx = /[^A-Z\d]/i;

  if (!values.benificiary_name) {
    errors.benificiary_name = LocaleStrings.required;
  }
  if (!values.benificiary_bankname) {
    errors.benificiary_bankname = LocaleStrings.required;
  }

  let check = /^[A-Z0-9-]*$/.test(values.bank_account_number);
  if (!values.bank_account_number) {
    errors.bank_account_number = LocaleStrings.required;
  } else if (!check) {
    errors.bank_account_number = "digits and capital letters only";
  } else if (values.bank_account_number.length != 21) {
    errors.bank_account_number = LocaleStrings.invalid_length;
  }

  if (!values.bank_code) {
    errors.bank_code = LocaleStrings.required;
  } else if (values.bank_code.match(regex)) {
    errors.bank_code = LocaleStrings.required;
  } else if (values.bank_code.length != 4) {
    errors.bank_code = LocaleStrings.invalid_length;
  }

  if (!values.country_code) {
    errors.country_code = LocaleStrings.required;
  }

  // if (!values.check_code) {
  //   errors.check_code = LocaleStrings.required;
  // } else if (values.check_code.match(regex)) {
  //   errors.check_code = LocaleStrings.required;
  // } else if (values.check_code.length != 2) {
  //   errors.check_code = LocaleStrings.invalid_length;
  // }

  //   if (!values.iban_code) {
  //     errors.iban_code = LocaleStrings.required;
  //   }
  return errors;
}

function mapStateToProps(state) {
  var { session, user, language, isLoggedIn } = state;
  var initVals = {};
  var editMode = false;
  if (
    state.bankAccount &&
    state.bankAccount.data &&
    state.bankAccount.data.length > 0 &&
    state.bankAccount.data[0].id
  ) {
    initVals = state.bankAccount.data[0];
    editMode = true;
  }
  //console.log("state.bankAccount", state.bankAccount);
  //console.log("initVals", initVals);
  return {
    session,
    user,
    isLoggedIn,
    language,
    initialValues: initVals,
    storeCountries: !_.isEmpty(state.storeCountries)
      ? !_.isEmpty(state.storeCountries.data)
        ? state.storeCountries.data
        : []
      : [],
    bankAccount: state.bankAccount,
    editMode: editMode,
  };
}

export default connect(mapStateToProps, {
  fetchCountries,
  updateAccount,
  addAccount,
  fetchAccount,
})(
  reduxForm({
    validate,
    form: "accountSettingsForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ActSettingsEdit)
);
