import axios from "axios";
import _ from "lodash";

import {
  INSTANCE_URL,
  APP_API_KEY,
  FILES_URL,
} from "../../../components/Common/constant";

import {
  getRequestAccess,
  postRequestAccess,
  deleteRequest,
  getRequest,
  postRequest,
  patchRequest,
  uploadEncodedFile,
} from "../../../components/Common/network-call";
import { getHeaders } from "../../../components/Common/common-utils";

export const SUBCATEGORY_LIST = "SUBCATEGORY_LIST";

export function emptySubList(callback) {
  return (dispatch) => {
    dispatch({
      type: SUBCATEGORY_LIST,
      payload: {},
    });
  };
}

export function fetchSubcategory(id, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript/getsubcategories`;
  let params = { categoryid: id };

  return (dispatch) => {
    postRequestAccess(
      url,
      params,
      null,
      dispatch,
      (response) => {
        dispatch({
          type: SUBCATEGORY_LIST,
          payload: { data: response.data, ads: response.ads },
        });
        callback({
          success: 1,
        });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}
