import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index";
import _ from "lodash";
// Import Components
import Loader from "../../../../components/Common/loader";
import { confirmAlert } from "react-confirm-alert";
import toast, { Toaster } from "react-hot-toast";
import CommonHeaderProfileSettings from "../commonheaderprofilesettings";
// Import Actions
import {
  getUserAddressAll,
  makeDefaultAddress,
  isNewAddressModalOpen,
  fetchCountry,
  fetchZone,
} from "../../../Cart/actions";
import { deleteAddress, editUserAddress } from "../../actions/";
import NewAddress from "../../../Cart/components/new-address";
import { classNames } from "../../../../components/Common/constant";

class Address extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_profile_information,
      btnLoader: false,
      editcountryId: null,
    };

    // All Binded Functions
  }

  componentDidMount() {
    // Fetch Vendor Details With LoginIdStore
    this.props.getUserAddressAll(this.props.session, (callBackResponce) => {
      this.setState({ loading: false });
    });
  }

  addNewAddress = () => {
    this.props.fetchCountry(this.props.session);
    this.props.fetchZone(this.props.session);
    this.props.editUserAddress({});
    this.props.isNewAddressModalOpen(true);
  };

  makeDefault = (addressid) => {
    confirmAlert({
      title: LocaleStrings.make_default_title,
      message: LocaleStrings.make_default_msg,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.setState({ loading: true });
            let values = {};
            values.defaultaddress = 1;
            let id = parseInt(addressid);

            this.props.makeDefaultAddress(
              this.props.session,
              values,
              id,
              (res) => {
                if (res.success == 1) {
                  this.props.getUserAddressAll(
                    this.props.session,
                    (callBackResponce) => {
                      this.setState({ loading: false });
                    }
                  );
                } else {
                  this.setState({ loading: false });
                  toast.error(LocaleStrings.common_fail_message);
                }
              }
            );
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  deleteAddress = (addressid) => {
    confirmAlert({
      title: LocaleStrings.address_delete_title,
      message: LocaleStrings.address_delete_msg,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.setState({ loading: true });

            this.props.deleteAddress(this.props.session, addressid, (res) => {
              if (res.success == 1) {
                this.props.getUserAddressAll(
                  this.props.session,
                  (callBackResponce) => {
                    this.setState({ loading: false });
                  }
                );
              } else {
                this.setState({ loading: false });
                toast.error(LocaleStrings.common_fail_message);
              }
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  editAddress = (item) => {
    this.props.fetchCountry(this.props.session);
    this.props.fetchZone(this.props.session);
    this.props.editUserAddress(item);
    this.setState({ editcountryId: item.country_id });
    this.props.isNewAddressModalOpen(true);
  };

  render() {
    let { userAddressAll } = this.props;
    //console.log("userAddressAll", userAddressAll);
    let defaultCount = _.filter(userAddressAll, function (o) {
      return o.defaultaddress;
    });
    return (
      <>
        <CommonHeaderProfileSettings
          commonHeaderText={LocaleStrings.profile_settings_list_text2}
        />
        {this.state.loading ? (
          <div className="py-6">
            <Loader />
          </div>
        ) : (
          <div className="px-6">
            <div className="pb-4">
              {_.map(userAddressAll, (item, index) => {
                return item.isbillingaddress == 0 ? (
                  <div className="pb-4">
                    <div className="flex">
                      <div className="pr-4">
                        <input
                          type="radio"
                          checked={
                            item.defaultaddress
                              ? item.defaultaddress
                              : // : index == 0 && defaultCount == 0
                                // ? true
                                false
                          }
                          value=""
                          onChange={() => this.makeDefault(item.id)}
                        />
                      </div>
                      <div>
                        <div className="text-primary font-normal text-base">
                          {_.startCase(item.addresstype)}
                          {" : "}
                          {item.username}
                        </div>
                        <div className="text-cardfooter font-normal text-xs">
                          {item.address}
                        </div>
                        <div className="flex mt-4">
                          <div className="pr-3">
                            <button
                              className="h-8 pl-sm pr-sm bg-ask border border-variantSel text-vendorLink rounded font-normal text-xs"
                              onClick={() => this.editAddress(item)}
                            >
                              {LocaleStrings.button_edit}
                            </button>
                          </div>
                          <div>
                            <button
                              className="h-8 pl-sm pr-sm bg-btn text-white rounded font-medium text-xs"
                              onClick={() => this.deleteAddress(item.id)}
                            >
                              {LocaleStrings.button_delete}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                );
              })}
            </div>
            <div className="py-6 grid grid-cols-3 gap-4">
              <div className="md:col-start-2 md:col-span-1 col-start-1 col-span-3">
                <button
                  className="w-full text-white bg-btn text-sm font-normal pt-2.5 pb-2.5 pl-9 pr-9 rounded border border-variantSel btn-heavy"
                  type="button"
                  onClick={() => this.addNewAddress()}
                >
                  {LocaleStrings.button_add_new_address}
                </button>
              </div>
            </div>
          </div>
        )}
        <NewAddress editcountryId={this.state.editcountryId} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.myProfile,
    session: state.session,
    userAddressAll: state.userAddressAll,
  };
}

export default connect(mapStateToProps, {
  getUserAddressAll,
  makeDefaultAddress,
  isNewAddressModalOpen,
  fetchCountry,
  deleteAddress,
  editUserAddress,
  fetchZone,
})(Address);
