import React, { useState, useEffect, useRef } from "react";
import { createPopper } from "@popperjs/core";

export default function TooltipRender(props, ref) {
  //console.log("props", props);
  //console.log("ref", ref);
  const { tooltipShow, tooltext } = props;
  const tooltipRef = useRef(null);

  useEffect(() => {
    // createPopper(ref.current, tooltipRef.current, {
    //   placement: "top",
    // });
  }, []);

  return (
    <>
      <div className="w-full text-center">
        <div
          className={
            (tooltipShow ? "" : "hidden ") +
            "bg-white border-0 mb-3 block z-100 font-normal leading-normal text-sm text-left no-underline rounded-lg"
          }
          ref={tooltipRef}
        >
          <div>
            <div className="text-primary text-sm p-3">{tooltext}</div>
          </div>
        </div>
      </div>
    </>
  );
}
