import {
  COUPON_LIST,
  IS_COUPON_MODAL_OPEN,
  APPLIED_COUPON,
  COUNTRY_LIST,
  DEFAULT_COUNTRY,
  IS_CHANGE_ADDRESS_MODAL_OPEN,
  USER_ALL_ADDRESS,
  IS_NEW_ADDRESS_MODAL_OPEN,
  ZONE_LIST,
} from "../actions/index";

export function couponModal(state = false, action) {
  switch (action.type) {
    case IS_COUPON_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
}

export function getCoupon(state = [], action) {
  switch (action.type) {
    case COUPON_LIST:
      return action.payload;
    default:
      return state;
  }
}

export function appliedCoupon(state = {}, action) {
  switch (action.type) {
    case APPLIED_COUPON:
      return action.payload;
    default:
      return state;
  }
}
export function countryList(state = [], action) {
  switch (action.type) {
    case COUNTRY_LIST:
      return action.payload;
    default:
      return state;
  }
}

export function defaultCountry(state = "QA", action) {
  switch (action.type) {
    case DEFAULT_COUNTRY:
      return action.payload;
    default:
      return state;
  }
}

export function changeAddressModal(state = false, action) {
  switch (action.type) {
    case IS_CHANGE_ADDRESS_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
}

export function userAddressAll(state = [], action) {
  switch (action.type) {
    case USER_ALL_ADDRESS:
      return action.payload;
    default:
      return state;
  }
}

export function newAddresModal(state = false, action) {
  switch (action.type) {
    case IS_NEW_ADDRESS_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
}

export function zoneList(state = [], action) {
  switch (action.type) {
    case ZONE_LIST:
      return action.payload;
    default:
      return state;
  }
}
