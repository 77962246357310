import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import Shoe from "../../../assets/images/ads/shoe.png";
import Bag from "../../../assets/images/ads/bag.png";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
} from "../../../components/Common/constant";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Ads(props) {
  const history = useHistory();
  let { item } = props;
  //console.log("item", item);

  const desiredPage = (item) => {
    if (item.linktype == "category") {
      history.push("/view-category/" + item.categoryid);
    } else if (item.linktype == "subcategory") {
      history.push("/product-view/" + item.subcategoryid);
    } else if (item.linktype == "product") {
      history.push(
        "/product-details/" +
          _.kebabCase(item.productname_en) +
          "-" +
          item.productid
      );
    } else {
      window.open(item.externallink, "_blank").focus();
    }
  };
  return (
    <div
      className="rounded cursor-pointer flex  justify-center md:flex-shrink-0"
      onClick={() => desiredPage(item)}
    >
      <LazyLoadImage
        src={`${FILES_URL}/${item.image}?api_key=${APP_API_KEY}`}
        className="h-full w-full rounded "
        effect="blur"
      />
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Ads);
