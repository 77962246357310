import {
  SELLER_PROFILE,
  STORE_MAP_MODAL_OPEN,
  SELLER_PROFILE_SEARCH,
  IS_SELLER_SHARE_MODAL_OPEN,
} from "../actions";

export function sellerProfile(state = {}, action) {
  switch (action.type) {
    case SELLER_PROFILE:
      return action.payload;
    default:
      return state;
  }
}

export function storeInfoMapModal(state = false, action) {
  if (action.type === STORE_MAP_MODAL_OPEN) {
    state = action.payload;
  }
  return state;
}

export function sellerSearch(state = false, action) {
  if (action.type === SELLER_PROFILE_SEARCH) {
    state = action.payload;
  }
  return state;
}

export function sellerShareModal(state = false, action) {
  switch (action.type) {
    case IS_SELLER_SHARE_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
}
