import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import ShopezLogoEn from "../assets/images/logo_en.png";
import ShopezLogoAr from "../assets/images/logo_ar.png";
import AddressLogo from "../assets/images/address.svg";
import PhoneLogo from "../assets/images/telephone.svg";
import MailLogo from "../assets/images/envelope.svg";
import InstagramLogo from "../assets/images/instaf.svg";
import TwitterLogo from "../assets/images/twf.svg";
// import YoutubeLogo from "../assets/images/Youtube.png";
import FacebookLogo from "../assets/images/fbf.svg";
import LinkedinLogo from "../assets/images/linkedinf.svg";
import AppstoreLogo from "../assets/images/app_store.svg";
import GoogleplayLogo from "../assets/images/google_play.svg";
import LocaleStrings from "../languages";

function FooterNav(props) {
  const history = useHistory();

  const redirectPage = () => {
    if (props.isLoggedIn) {
      history.push("/profile");
    } else {
      history.push("/login");
    }
  };

  const redirectOrder = () => {
    if (props.isLoggedIn) {
      history.push("/myorders");
    } else {
      history.push("/login");
    }
  };
  return (
    <div className="bg-white  items-center pt-89 lg:pt-89 pb-9 lg:pb-9">
      <div className="w-full">
        <div
          className="custom_container"
          style={
            props.deviceSize.isMobile
              ? { paddingLeft: 10, paddingRight: 10 }
              : {}
          }
        >
          <div className="grid grid-cols-1  md:grid-cols-6 xl:grid-cols-6 lg:grid-cols-6 gap-4 bg-white ">
            <div className="col-span-3 text-left align-top mb-40 xl:mb-0 lg:mb-0 md:mb-0">
              <div>
                <img
                  src={
                    props.localLanguage == "ar" ? ShopezLogoAr : ShopezLogoEn
                  }
                  className="w-logow"
                />
              </div>
              <div className="text-footernavtext text-sm pt-5">
                Shopez is your marketplace. It’s the perfect place to go to
                declutter or discover items that will breathe new life into your
                space. Letting go has never felt so good – it’s almost as good
                as finding your new favorite thing. And we’re here to help.
              </div>

              <div className="flex pb-lg pt-8 gap-5">
                <div className="">
                  <img src={FacebookLogo} className="w-4" />
                </div>
                <div className="">
                  <img src={TwitterLogo} className="w-4" />
                </div>
                <div className="">
                  <img src={LinkedinLogo} className="w-4" />
                </div>
                <div>
                  <img src={InstagramLogo} className="w-4" />
                </div>
              </div>
            </div>
            <div className="text-left align-top mb-40 xl:mb-0 lg:mb-0 md:mb-0">
              <div className="text-primary text-base font-bold pb-5">Shop</div>

              <div
                className="text-footernavtext pb-2.5 cursor-pointer text-sm"
                //onClick={() => redirectOrder()}
              >
                <a href={props.isLoggedIn ? `/myorders` : `/login`}>
                  {LocaleStrings.returns_order}
                </a>
              </div>
              {/* <div className="text-footernavtext pb-2.5">History</div> */}
              <div
                className="text-footernavtext pb-2.5 cursor-pointer text-sm"
                //onClick={() => redirectPage()}
              >
                <a href={props.isLoggedIn ? `/profile` : `/login`}>
                  {LocaleStrings.footer_my_account}
                </a>
              </div>
              {/* <div className="text-footernavtext">Unsubscribe</div> */}
            </div>
            <div className="text-left align-top mb-40 xl:mb-0 lg:mb-0 md:mb-0">
              <div className="text-primary text-base font-bold pb-5 cursor-pointer">
                {LocaleStrings.support}
              </div>
              <div
                className="text-footernavtext pb-2.5 cursor-pointer text-sm"
                //onClick={() => history.push("/faq")}
              >
                <a href="/faq">{LocaleStrings.contact_us}</a>
              </div>
              <div
                className="text-footernavtext pb-2.5 cursor-pointer text-sm"
                //onClick={() => history.push("/legaldocs/termsncons")}
              >
                <a href="/legaldocs/intellectual">
                  {LocaleStrings.intellectual_property}
                </a>
              </div>

              {/* <div className="text-footernavtext pb-2.5">Warranty</div> */}
              <div
                className="text-footernavtext cursor-pointer text-sm"
                //onClick={() => history.push("/faq")}
              >
                <a href="/faq"> {LocaleStrings.faq}</a>
              </div>
            </div>
            <div className="text-left align-top">
              <div className="text-primary text-base font-bold pb-5">
                {LocaleStrings.policy_center}
              </div>

              <div
                className="text-footernavtext pb-2.5 cursor-pointer text-sm"
                //onClick={() => history.push("/legaldocs/privacypolicy")}
              >
                <a href="/legaldocs/privacypolicy">
                  {LocaleStrings.privacy_policy}
                </a>
              </div>
              <div
                className="text-footernavtext pb-2.5 cursor-pointer text-sm"
                //onClick={() => history.push("/legaldocs/termsncons")}
              >
                <a href="/legaldocs/termsncons">
                  {LocaleStrings.terms_condition}
                </a>
              </div>

              <div
                className="text-footernavtext pb-2.5 cursor-pointer text-sm"
                //onClick={() => history.push("/legaldocs/privacypolicy")}
              >
                <a href="/legaldocs/returnpolicy">
                  {LocaleStrings.return_policy}
                </a>
              </div>

              <div
                className="text-footernavtext pb-2.5 cursor-pointer text-sm"
                //onClick={() => history.push("/legaldocs/privacypolicy")}
              >
                <a href="/legaldocs/shipmentpolicy">
                  {LocaleStrings.shipment_policy}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FooterNav);
