import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import NoImg from "../../../assets/images/noimg.png";
import YouNodata from "../../../assets/images/you-nodat.svg";
import PublicIcon from "../../../assets/images/public.svg";
import PrivateIcon from "../../../assets/images/private.svg";

function SharedWishlist(props) {
  const history = useHistory();
  const { session, isLoggedIn, profileSharedWishlist } = props;

  const listDetails = (obj) => {
    //console.log("obj", obj);
    if (obj.createdby == session.userid) {
      history.push("/wishlist-details/" + obj.id);
    } else {
      history.push("/shared-wishlist/" + obj.createdby + "/" + obj.id);
    }
  };

  const renderList = () => {
    var item = _.map(profileSharedWishlist.data, (valObj, index) => {
      let itemCount = valObj.items.length;

      return (
        <li
          key={index}
          className="my-2 cursor-pointer border border-primary rounded-md mb-md"
          onClick={() => listDetails(valObj)}
        >
          <div className="p-3">
            <div className="text-primary text-base font-normal pb-xs">
              {valObj.name}
            </div>
            <div
              className={classNames(
                itemCount > 0 ? "divide-x divide-primary " : " ",
                "flex"
              )}
            >
              <div className="flex items-center text-gray-300 text-sm pr-md">
                <div className="pr-2">
                  <img
                    src={valObj.privacy == "public" ? PublicIcon : PrivateIcon}
                  />
                </div>
                <div>{_.startCase(valObj.privacy)}</div>
              </div>

              {itemCount > 0 ? (
                <div className="pl-md text-gray-300 text-sm">
                  {itemCount}&nbsp;
                  {itemCount == 1 ? LocaleStrings.item : LocaleStrings.items}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </li>
      );
    });

    return item;
  };

  return (
    <div className="px-6">
      <div className="flow-root my-8">
        <ul role="list" className=" ">
          {renderList()}
        </ul>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  profileSharedWishlist: state.profileSharedWishlist,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SharedWishlist);
