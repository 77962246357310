import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CircleSpinner } from "react-spinners-kit";
import ShopezLoader from "../../assets/images/shopez-loader.gif";

const Loading = (props) => {
  return (
    // <div className="flex items-center justify-center">
    //   {/* <CircleSpinner size={40} color={"#009CDE"} loading={true} /> */}
    //   <img src={ShopezLoader} className="h-20 w-20" alt="loader" />
    //   {/* <p className="text-base font-normal text-primary text-center tracking-tight mt-4">
    //     {this.props.data}
    //   </p> */}
    // </div>
    <div className="px-4 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <div className="sm:ml-6">
            <div
              className="--sm:border-l sm:border-ternary sm:pl-6 transition ease-in-out duration-700"
              align="center"
            >
              <img src={ShopezLoader} className="h-20 w-20" alt="loader" />
              <p className="text-base font-normal text-link text-center tracking-tight mt-4">
                {props.data ? props.data : null}
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
