import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
  timeAgo,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import toast, { Toaster } from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import CartBlack from "../../../assets/images/cart-black.svg";
import HammerBlack from "../../../assets/images/hammer-black.svg";
import NegotationBlack from "../../../assets/images/negotiation-black.svg";
import { fetchMyBuyingHistory, emptyMyBuyingHistory } from "../actions";
import RightArrow from "../../../assets/images/right-arrow.svg";

function BuyingHistory(props) {
  const history = useHistory();
  const {
    session,
    isLoggedIn,
    deviceSize,
    myBuyingHistory,
    setBuyingHistoryOpen,
    buyingHistoryOpen,
  } = props;
  const cancelButtonRef = useRef(null);

  //console.log("myBuyingHistory", myBuyingHistory);
  return (
    <div className="mt-5 mb-2.5 custom_container ">
      {myBuyingHistory && myBuyingHistory.data ? (
        typeof myBuyingHistory.data === "object" &&
        myBuyingHistory.data !== null ? (
          <div className="grid grid-cols-1 gap-4">
            <div
              className="bg-white rounded-md p-4 grid grid-cols-12  cursor-pointer"
              onClick={() => window.open("/myorders", "_blank")}
            >
              <div className="col-span-11">
                <div className="text-buyingtitle text-xs uppercase">
                  {LocaleStrings.buying_history_purchases}
                </div>
                <div className="flex items-center">
                  <div className="bg-buyingimg w-10 h-10 rounded-full flex justify-center items-center">
                    <img src={CartBlack} className="w-5" />
                  </div>
                  <div className="ml-3">
                    <div className="text-ads text-3xl font-normal">
                      {myBuyingHistory.data.myorders}
                    </div>
                    <div className="text-primary text-base font-medium">
                      {LocaleStrings.buying_history_purchase_made}
                    </div>
                  </div>
                </div>
                <div className="pt-2 text-cardfooter text-xs font-normal">
                  {myBuyingHistory.data.myorders == 0
                    ? LocaleStrings.buying_history_no_purchases
                    : LocaleStrings.buying_history_last_purchases +
                      " " +
                      timeAgo(myBuyingHistory.data.last_order_date)}
                </div>
                {/* <div className="pt-2.5">
                  <button
                    className="text-white h-30 bg-btn text-sm font-normal rounded w-127 inline-flex items-center justify-center"
                    onClick={() => history.push("/myorders")}
                  >
                    {LocaleStrings.view_my_orders}
                  </button>
                </div> */}
              </div>
              <div className="col-span-1  flex items-center justify-center">
                <img src={RightArrow} />
              </div>
            </div>
            <div
              className="bg-white rounded-md p-4 grid grid-cols-12 cursor-pointer"
              onClick={() => window.open("/mybids", "_blank")}
            >
              <div className="col-span-11">
                <div className="text-buyingtitle text-xs uppercase">
                  {LocaleStrings.buying_history_bids}
                </div>
                <div className="flex items-center">
                  <div className="bg-buyingimg w-10 h-10 rounded-full flex justify-center items-center">
                    <img src={HammerBlack} className="w-5" />
                  </div>
                  <div className="ml-3">
                    <div className="text-ads text-3xl font-normal">
                      {myBuyingHistory.data.bids}
                    </div>
                    <div className="text-primary text-base font-medium">
                      {LocaleStrings.buying_history_bids_placed}
                    </div>
                  </div>
                </div>
                <div className="pt-2 text-cardfooter text-xs font-normal">
                  {myBuyingHistory.data.bids == 0
                    ? LocaleStrings.buying_history_no_bids
                    : LocaleStrings.buying_history_last_bid +
                      " " +
                      timeAgo(myBuyingHistory.data.last_bid_date)}
                </div>
                {/* <div className="pt-2.5">
                  <button
                    className="text-white h-30 bg-btn text-sm font-normal rounded w-127 inline-flex items-center justify-center"
                    onClick={() => history.push("/mybids")}
                  >
                    {LocaleStrings.view_my_bids}
                  </button>
                </div> */}
              </div>
              <div
                className="col-span-1  flex items-center justify-center cursor-pointer"
                //onClick={() => history.push("/mybids")}
              >
                <img src={RightArrow} />
              </div>
            </div>
            <div
              className="bg-white rounded-md p-4 grid grid-cols-12 cursor-pointer"
              onClick={() => window.open("/negotiation", "_blank")}
            >
              <div className="col-span-11">
                <div className="text-buyingtitle text-xs uppercase">
                  {LocaleStrings.buying_history_offers}
                </div>
                <div className="flex items-center">
                  <div className="bg-buyingimg w-10 h-10 rounded-full flex justify-center items-center">
                    <img src={NegotationBlack} className="w-5" />
                  </div>
                  <div className="ml-3">
                    <div className="text-ads text-3xl font-normal">
                      {myBuyingHistory.data.negotiations}
                    </div>
                    <div className="text-primary text-base font-medium">
                      {LocaleStrings.buying_history_offers_negotiation}
                    </div>
                  </div>
                </div>
                <div className="pt-2 text-cardfooter text-xs font-normal">
                  {myBuyingHistory.data.negotiations == 0
                    ? LocaleStrings.buying_history_no_negotiation
                    : LocaleStrings.buying_history_last_offer +
                      " " +
                      timeAgo(myBuyingHistory.data.last_nego_date)}
                </div>
                {/* <div className="pt-2.5">
                  <button
                    className="text-white h-30 bg-btn text-sm font-normal rounded w-127 inline-flex items-center justify-center"
                    onClick={() => history.push("/negotiation")}
                  >
                    {LocaleStrings.view_negotiation}
                  </button>
                </div> */}
              </div>
              <div
                className="col-span-1  flex items-center justify-center"
                //onClick={() => history.push("/negotiation")}
              >
                <img src={RightArrow} />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center">
            {LocaleStrings.mybids_not_found}
          </div>
        )
      ) : (
        <Loader data={LocaleStrings.loader_message} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  myBuyingHistory: state.myBuyingHistory,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchMyBuyingHistory, emptyMyBuyingHistory }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BuyingHistory);
