import {
  BUYING_HISTORY,
  RECENT_VIEWED,
  MY_FOLLOWER,
  PROFILE_MODAL,
  PROFILE_FOLLOWERS,
  PROFILE_FOLLOWERS_DETAILS,
  PROFILE_SHAREDWISHLIST,
  ACTIVITY_FEED,
  NAVIGATE_PROFILE_VIEW,
  PUBLIC_PROFILE,
  CHANGE_PROFILE,
} from "../actions";

export function navigateUser(state = [], action) {
  switch (action.type) {
    case NAVIGATE_PROFILE_VIEW:
      return action.payload;
    default:
      return state;
  }
}

export function myBuyingHistory(state = {}, action) {
  switch (action.type) {
    case BUYING_HISTORY:
      return action.payload;
    default:
      return state;
  }
}

export function myRecentViewed(state = [], action) {
  switch (action.type) {
    case RECENT_VIEWED:
      return action.payload;
    default:
      return state;
  }
}

export function myFollower(state = [], action) {
  switch (action.type) {
    case MY_FOLLOWER:
      return action.payload;
    default:
      return state;
  }
}

export function profileModal(state = false, action) {
  switch (action.type) {
    case PROFILE_MODAL:
      return action.payload;
    default:
      return state;
  }
}

export function profileFollower(state = [], action) {
  switch (action.type) {
    case PROFILE_FOLLOWERS:
      return action.payload;
    default:
      return state;
  }
}

export function profileFollowerDetails(state = [], action) {
  switch (action.type) {
    case PROFILE_FOLLOWERS_DETAILS:
      return action.payload;
    default:
      return state;
  }
}

export function profileSharedWishlist(state = [], action) {
  switch (action.type) {
    case PROFILE_SHAREDWISHLIST:
      return action.payload;
    default:
      return state;
  }
}

export function activityFeed(state = [], action) {
  switch (action.type) {
    case ACTIVITY_FEED:
      return action.payload;
    default:
      return state;
  }
}

export function publicProfile(state = [], action) {
  switch (action.type) {
    case PUBLIC_PROFILE:
      return action.payload;
    default:
      return state;
  }
}

export function profileViewChange(state = false, action) {
  switch (action.type) {
    case CHANGE_PROFILE:
      return action.payload;
    default:
      return state;
  }
}
