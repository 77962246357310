import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import Loader from "../../../components/Common/loader";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
  CURRENCY_RATE,
  CURRENCY,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import CartEmpty from "../../../assets/images/cart_empty.svg";

function PayCancel(props) {
  const history = useHistory();
  const { session, isLoggedIn, localLanguage, deviceSize } = props;

  useEffect(() => {}, []);

  return (
    <>
      <div className="bg-newarrival py-25">
        <div className="custom_container">
          <div className="bg-white rounded py-4 flex items-center justify-center">
            <div>
              <div className="flex items-center justify-center pb-5">
                <img src={CartEmpty} />
              </div>
              <div className="flex items-center justify-center text-cutoff text-base font-medium">
                {LocaleStrings.pay_cancelled_title}
              </div>
              <div className="flex items-center justify-center text-cutoff text-sm mt-3">
                {LocaleStrings.pay_cancelled_desc}
              </div>
              <div className="flex items-center justify-center mt-6">
                <button
                  type="button"
                  className="h-8 pl-md pr-md bg-btn text-white rounded font-medium text-sm cursor-pointer"
                  onClick={() => history.push("/")}
                >
                  {LocaleStrings.shop_now}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PayCancel);
