import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
  LABEL_POSITION_TOP,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import {
  renderFieldText,
  renderFieldRadio1,
  renderDatalistSelect,
} from "../../../components/Common/base-component";
import Loader from "../../../components/Common/loader";
import AskQLogo from "../../../assets/images/askquestion.svg";
import SendbtnLogo from "../../../assets/images/send_btn.svg";
import { Fragment } from "react";
import { Transition, Dialog, Menu } from "@headlessui/react";
import { XIcon, DotsHorizontalIcon } from "@heroicons/react/outline";
import toast, { Toaster } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import {
  questionListProductStore,
  isQuestionlistModalOpen,
  questionListByProduct,
  addQuestion,
  openReportModal,
} from "../actions";

function QuestionList(props) {
  const history = useHistory();
  const cancelButtonRef = useRef(null);
  const {
    session,
    isLoggedIn,
    localLanguage,
    isQuestionListOpen,
    questionList,
    productid,
    selectedProduct,
  } = props;
  const [addNewSection, setAddNewSection] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const closeSlideOvers = () => {
    setAddNewSection(false);
    props.isQuestionlistModalOpen(false);
  };

  const closePopUp = () => {
    setAddNewSection(false);
  };

  const askQuestion = () => {
    if (isLoggedIn) {
      setAddNewSection(true);
    } else {
      closeSlideOvers();
      history.push("/login");
    }
  };

  const handleMessage = (e) => {
    let msg = e.target.value;
    var regex = /^\s+$/;
    if (!msg.match(regex)) {
      setMessage(msg);
    }
  };
  const askQuestionPost = () => {
    if (message != "") {
      setLoading(true);
      let values = {};
      values.question = message;
      let vendorid =
        selectedProduct && selectedProduct.store && selectedProduct.store.userid
          ? selectedProduct.store.userid
          : "";
      values.isanswer = 0;
      values.askedto = vendorid;
      values.productid = productid;
      props.addQuestion(session, values, (res) => {
        setLoading(false);
        setAddNewSection(false);
        setMessage("");
        if (res.success == 0) {
          toast.error(LocaleStrings.common_fail_message);
        } else {
          props.questionListByProduct(props.session, productid, (res) => {});
          toast.success(LocaleStrings.ask_question_success);
        }
      });
    }
  };

  const reportAbusive = (qaid, type) => {
    if (isLoggedIn) {
      confirmAlert({
        title:
          type == "question"
            ? LocaleStrings.report_confirm_question_title
            : LocaleStrings.report_confirm_answer_title,
        message:
          type == "question"
            ? LocaleStrings.report_confirm_question_message
            : LocaleStrings.report_confirm_answer_message,
        buttons: [
          {
            label: LocaleStrings.button_yes,
            onClick: () => {
              props.openReportModal(true, qaid, type);
            },
          },
          {
            label: LocaleStrings.button_no,
            onClick: () => {},
          },
        ],
      });
    }
  };

  return (
    <>
      <Transition.Root show={isQuestionListOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          initialFocus={cancelButtonRef}
          open={isQuestionListOpen}
          onClose={() => closeSlideOvers()}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div
                    className={classNames(
                      addNewSection ? "bg-white " : "bg-white ",
                      "h-full flex flex-col  shadow-xl"
                    )}
                  >
                    <div className="min-h-0 flex-1 flex flex-col pt-3 overflow-y-scroll">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="slider-modal-headtext">
                            <h2
                              id="slide-over-heading"
                              className="text-xl font-normal text-vendorLink"
                            >
                              {LocaleStrings.question_and_answer}
                            </h2>
                          </Dialog.Title>
                          <div
                            className="ml-3 h-7 flex items-center"
                            ref={cancelButtonRef}
                          >
                            <button
                              onClick={() => closeSlideOvers()}
                              className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-1 relative flex-1 px-4 sm:px-6">
                        <div className="text-left pt-2.5 text-vendorLink font-normal text-base">
                          {LocaleStrings.question_and_answer_subheading}
                        </div>
                        <div className="text-left pt-2.5">
                          <button
                            className="h-8 pl-sm pr-sm bg-ask border border-variantSel text-vendorLink rounded font-normal text-sm"
                            onClick={() => askQuestion()}
                          >
                            {LocaleStrings.ask_question_btn}
                          </button>
                        </div>
                        <div className="pt-7">
                          {questionList && questionList.data ? (
                            questionList.data.length > 0 ? (
                              questionList.data.map((qa, index) => {
                                return (
                                  <div className="flex mb-lg" key={qa.id}>
                                    <div className="w-wishdetailbox">
                                      <img
                                        src={AskQLogo}
                                        style={{ width: 26 }}
                                      />
                                    </div>
                                    <div className="w-4/5 pl-15 align-top">
                                      <div className="align-top text-primary text-sm font-normal">
                                        {qa.question}
                                      </div>
                                      <div className="grid grid-cols-3 gap-1 text-cardfooter text-xs font-normal">
                                        <div className="col-span-2">
                                          {qa.answer}
                                        </div>
                                        <div>
                                          {qa.askedby != session.userid &&
                                          qa.answer ? (
                                            <div
                                              className="text-right"
                                              key={qa.id}
                                            >
                                              <Menu
                                                as="div"
                                                className="relative inline-block text-left"
                                              >
                                                {({ open }) => (
                                                  <>
                                                    <div className="">
                                                      <Menu.Button className="btn-card">
                                                        <span className="sr-only">
                                                          Open options
                                                        </span>
                                                        <DotsHorizontalIcon
                                                          className="h-5 w-5 hover:shadow"
                                                          aria-hidden="true"
                                                        />
                                                      </Menu.Button>
                                                    </div>

                                                    <Transition
                                                      show={open}
                                                      as={Fragment}
                                                      enter="transition ease-out duration-100"
                                                      enterFrom="transform opacity-0 scale-95"
                                                      enterTo="transform opacity-100 scale-100"
                                                      leave="transition ease-in duration-75"
                                                      leaveFrom="transform opacity-100 scale-100"
                                                      leaveTo="transform opacity-0 scale-95"
                                                    >
                                                      <Menu.Items
                                                        static
                                                        className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer z-50"
                                                      >
                                                        <div className="py-1 divide-y z-50">
                                                          <Menu.Item>
                                                            {({ active }) => (
                                                              <a
                                                                onClick={() =>
                                                                  reportAbusive(
                                                                    qa.id,
                                                                    "answer"
                                                                  )
                                                                }
                                                                className={classNames(
                                                                  active
                                                                    ? "bg-quaternary "
                                                                    : " ",
                                                                  "block px-4 py-2 text-sm text-primary"
                                                                )}
                                                              >
                                                                {
                                                                  LocaleStrings.report_as_abusive
                                                                }
                                                              </a>
                                                            )}
                                                          </Menu.Item>
                                                        </div>
                                                      </Menu.Items>
                                                    </Transition>
                                                  </>
                                                )}
                                              </Menu>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {qa.askedby != session.userid ? (
                                      <div
                                        className="w-wishdetailbox text-right"
                                        key={qa.id}
                                      >
                                        <Menu
                                          as="div"
                                          className="relative inline-block text-left"
                                        >
                                          {({ open }) => (
                                            <>
                                              <div className="">
                                                <Menu.Button className="btn-card">
                                                  <span className="sr-only">
                                                    Open options
                                                  </span>
                                                  <DotsHorizontalIcon
                                                    className="h-5 w-5 hover:shadow"
                                                    aria-hidden="true"
                                                  />
                                                </Menu.Button>
                                              </div>

                                              <Transition
                                                show={open}
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                              >
                                                <Menu.Items
                                                  static
                                                  className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer z-50"
                                                >
                                                  <div className="py-1 divide-y z-50">
                                                    <Menu.Item>
                                                      {({ active }) => (
                                                        <a
                                                          onClick={() =>
                                                            reportAbusive(
                                                              qa.id,
                                                              "question"
                                                            )
                                                          }
                                                          className={classNames(
                                                            active
                                                              ? "bg-quaternary "
                                                              : " ",
                                                            "block px-4 py-2 text-sm text-primary"
                                                          )}
                                                        >
                                                          {
                                                            LocaleStrings.report_as_abusive
                                                          }
                                                        </a>
                                                      )}
                                                    </Menu.Item>
                                                  </div>
                                                </Menu.Items>
                                              </Transition>
                                            </>
                                          )}
                                        </Menu>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                              })
                            ) : (
                              ""
                            )
                          ) : (
                            <div className="h-50">
                              <Loader />
                            </div>
                          )}
                        </div>
                      </div>

                      <article
                        className={
                          addNewSection
                            ? "vs_popup_show bg-white rounded-t-3xl border-t "
                            : "vs_popup_hide "
                        }
                        style={
                          addNewSection
                            ? {
                                top: "75%",
                                boxShadow: "0px -4px 3px rgba(0, 0, 0, 0.30)",
                              }
                            : {}
                        }
                      >
                        <div className="mt-md grid grid-cols-2">
                          <div className="text-left text-base text-primary font-normal"></div>
                          <div className="text-right">
                            <button
                              className="vs_popup_close_btn text-link text-sm"
                              onClick={() => closePopUp()}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                        <div className="flex-shrink-0 px-4 py-3.5 flex relative">
                          <input
                            name="price"
                            type="text"
                            onChange={(e) => handleMessage(e)}
                            value={message}
                            placeholder={LocaleStrings.ask_question_placeholder}
                            className="bg-newarrival w-full rounded-full h-44 text-loginLabel text-15 pl-5 focus:outline-none"
                          />
                          <div
                            className="absolute right-7 top-6 cursor-pointer"
                            onClick={() => askQuestionPost()}
                          >
                            {loading ? (
                              <svg
                                className="animate-spin h-5 w-5 rounded-full mr-3"
                                viewBox="0 0 1024 1024"
                                focusable="false"
                                data-icon="loading"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                              </svg>
                            ) : (
                              <img src={SendbtnLogo} />
                            )}
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const validate = (values, ownProps) => {
  var errors = {};
  if (!values.name) {
    errors.name = LocaleStrings.create_new_wishlist_name_required;
  } else if (values.question.trim() == "") {
    errors.question = LocaleStrings.ask_question_form_error;
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    localLanguage: state.localLanguage,
    openAskQuestion: state.openAskQuestion,
    questionList: state.questionList,
    isQuestionListOpen: state.isQuestionListOpen,
    questionListProductId: state.questionListProductId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      questionListProductStore,
      isQuestionlistModalOpen,
      questionListByProduct,
      addQuestion,
      openReportModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "QuestionForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(QuestionList)
);
