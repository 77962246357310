import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index";
import _ from "lodash";
import renderHTML from "react-render-html";
// Import BaseLoader

// Import Component
import CommonHeaderLegalDocs from "../commonheaderlegaldocs.js";
//Import action
import { fetchSettingsMaster } from "../../actions/index";
// Import BaseLoader
import Loader from "../../../../components/Common/loader";

class LegalDocsAboutUS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_legal_docs,
    };

    // All Binded Functions
  }

  //Arrow function for renderLoader
  componentDidMount() {
    this.props.fetchSettingsMaster(this.props.session, (callback) => {
      this.setState({ loading: false });
    });
    window.scrollTo(0, 0);
  }

  render() {
    let { settingsMasterConsumer, language } = this.props;

    let dataContent = "";

    if (!_.isEmpty(settingsMasterConsumer)) {
      dataContent =
        language === "en"
          ? settingsMasterConsumer.vendor_sales_policy_en
          : settingsMasterConsumer.vendor_sales_policy_ar;
    }

    return (
      <>
        <div className="bg-newarrival pl-3 md:pl-5 lg:px-89 xl:px-139 2xl:px-298 pr-3 md:pr-5 pb-25">
          <div className="grid grid-cols-1 pt-25">
            <div className="bg-white rounded px-10">
              <CommonHeaderLegalDocs
                history={this.props.history}
                commonHeaderText={LocaleStrings.consumer_legal_docs_list_text4}
              />
              <div className="policyHeader">
                <p className="text-xl font-medium">
                  {LocaleStrings.consumer_legal_docs_list_text4}
                </p>
                <p className="font-normal">
                  {LocaleStrings.consumer_legal_docs_list_subtext4}
                </p>
              </div>
              {this.state.loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <div className="py-10 bg-white">{renderHTML(dataContent)}</div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

// var mapStateToProps = (state) => ({
//     isLoggedIn: state.isLoggedIn,
//     user: state.user,
//     session: state.session,
//
//   });

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    language: state.localLanguage,
    settingsMasterConsumer: state.settingsMasterConsumer
      ? state.settingsMasterConsumer.data !== "undefined"
        ? state.settingsMasterConsumer.data
        : []
      : [],
  };
}

export default connect(mapStateToProps, {
  fetchSettingsMaster,
})(LegalDocsAboutUS);
