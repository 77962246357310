import React, { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LocaleStrings from "../../../languages/index";
import _ from "lodash";
import GoogleMapReact from "google-map-react";
// Component Import
import {
  APP_API_KEY,
  FILES_URL,
  GOOGLE_MAP_KEY,
} from "../../../components/Common/constant";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import styled from "styled-components";
import Marker from "./Marker";
import { isStoreInfoMapModalOpen } from "../actions";

const Wrapper = styled.main`
  width: 100%;
  height: 98%;
`;

const AnyReactComponent = ({ text }) => <div>{text}</div>;
function Storemap(props) {
  const cancelButtonRef = useRef(null);

  const { storeInfoMapModal, intialData } = props;
  const [center, setCenter] = useState([]);
  const [zoom, SetZoom] = useState(14);
  const [address, setAddress] = useState("");
  const [draggable, setDraggable] = useState(true);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  useEffect(() => {
    setCurrentLocation();
  }, []);

  const closeModal1 = () => {};

  const closeModal = () => {
    // Clear Single Discount Object (Pointing to)
    props.isStoreInfoMapModalOpen(false);
    setCenter([]);
    setAddress("");
    setLat(null);
    setLng(null);
  };

  const setCurrentLocation = () => {
    setCenter([
      parseFloat(intialData.latitude),
      parseFloat(intialData.longitude),
    ]);
    setLat(parseFloat(intialData.latitude));
    setLng(parseFloat(intialData.longitude));
    setAddress(intialData.location);
  };

  return (
    <>
      <Transition.Root show={storeInfoMapModal} as={Fragment}>
        <Dialog
          // initialFocus={this.myRef}
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={storeInfoMapModal}
          onClose={closeModal1}
          initialFocus={cancelButtonRef}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                style={{
                  padding: 0,
                  width: "100%",
                }}
              >
                {/* MOdal Header */}
                <div className="grid grid-cols-4 border-b border-primary pb-6 sticky-header z-10">
                  <div></div>
                  <div className="col-span-2 flex flex-wrap content-center justify-center mt-4">
                    <div className="text-lg leading-6 font-medium text-secondary">
                      {LocaleStrings.map}
                    </div>
                  </div>
                  <div className="absolute top-0 right-0 pt-4 pr-4 ">
                    <button
                      type="button"
                      className="btn-closeModal focus:outline-none"
                      onClick={() => closeModal()}
                      ref={cancelButtonRef}
                    >
                      <span className="sr-only">{LocaleStrings.close}</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                {/* Modal Body */}

                <div className="mx-6 mt-2">
                  {/* Important! Always set the container height explicitly */}
                  <div style={{ height: "70vh", width: "100%" }}>
                    <Wrapper>
                      <GoogleMapReact
                        center={center}
                        zoom={zoom}
                        draggable={draggable}
                        bootstrapURLKeys={{
                          key: GOOGLE_MAP_KEY,
                          libraries: ["places", "geometry"],
                        }}
                        yesIWantToUseGoogleMapApiInternals
                      >
                        <Marker address={address} lat={lat} lng={lng} />
                      </GoogleMapReact>
                    </Wrapper>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
  storeInfoMapModal: state.storeInfoMapModal,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ isStoreInfoMapModalOpen }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Storemap);
