import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeDiffCalc,
  timeDiffRemaining,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import EyeGreyIcon from "../../../assets/images/eye.svg";
import {
  isbidPlacedSliderOpen,
  fetchPublicBids,
  emptyPublicBids,
  addCart,
  fetchCart,
  validateUserCart,
} from "../../Product/actions";
import { openSliderPop } from "../../../actions";
import { fetchMybids } from "../actions";
import toast, { Toaster } from "react-hot-toast";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ListItem(props) {
  const history = useHistory();
  const {
    session,
    isLoggedIn,
    localLanguage,
    item,
    bidprice,
    data,
    status,
    setProductId,
    setVariantId,
    setAllVariants,
    setProductVariants,
    setSelectedProduct,
    setSelectedVariant,
    all_variants,
    getUserCart,
    localCurrency,
  } = props;
  const [timeText, setTimeText] = useState("");
  const [remainingTime, setRemainingTime] = useState({});
  const [timeInterval, setTimeInterval] = useState(0);
  const [selid, setSelid] = useState("");

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 2000);

  //console.log("status", status);
  var auctionText = "";
  var endDate = "";
  if (item.saletype == "auction") {
    let currentDate = moment();
    if (
      item.auction_start_date &&
      item.auction_end_date &&
      currentDate.isAfter(moment(item.auction_start_date)) &&
      currentDate.isBefore(moment(item.auction_end_date))
    ) {
      auctionText = LocaleStrings.ends_in;
      endDate = item.auction_end_date;
    } else if (
      item.auction_start_date &&
      item.auction_end_date &&
      currentDate.isBefore(moment(item.auction_start_date)) &&
      currentDate.isBefore(moment(item.auction_end_date))
    ) {
      auctionText = LocaleStrings.starts_in;
      endDate = item.auction_start_date;
    } else if (
      item.auction_start_date &&
      item.auction_end_date &&
      currentDate.isAfter(moment(item.auction_start_date)) &&
      currentDate.isAfter(moment(item.auction_end_date))
    ) {
      auctionText = LocaleStrings.expired;
      endDate = "";
    }
  }

  useEffect(() => {
    if (endDate) {
      let timeText = timeDiffCalc(
        new Date(endDate.replace(/-/g, "/")),
        new Date()
      );
      setTimeText(timeText);
      let remObj = timeDiffRemaining(
        new Date(endDate.replace(/-/g, "/")),
        new Date()
      );
      setRemainingTime(remObj);
    }
  }, [timeInterval, endDate]);

  var discountpercent = 0.0;
  var strProductDiscount = "";
  let originalprice = 0.0;
  let prodprice = 0.0;
  // if (item) {
  //   let variantid = item.variantid;
  //   let priceArr = _.filter(item.all_variants, (obj) => {
  //     return (obj.id = variantid);
  //   });
  //   if (priceArr.length > 0) {
  //     originalprice = priceArr[0].price_unit;
  //   }
  // }

  const viewDetails = (productId) => {
    history.push({
      pathname: "/product-details/" + productId,
      state: { from: "/" },
    });
  };
  //console.log("endDate", endDate);
  const bidPlaced = (variantid) => {
    props.emptyPublicBids();
    props.fetchPublicBids(session, variantid);
    props.isbidPlacedSliderOpen(true);
  };

  const addtoCart = (item, data) => {
    let productid = data.productid;
    let variantId = data.variantid;

    let alreadyinCart = [];
    if (getUserCart && getUserCart.items) {
      alreadyinCart = _.filter(getUserCart.items, function (o) {
        return o.productid == productid && o.variantid == variantId;
      });
    }

    if (alreadyinCart.length > 0 && alreadyinCart[0].negotiated == 1) {
      toast.error(LocaleStrings.negotiated_won_variant_error);
    } else if (alreadyinCart.length > 0 && alreadyinCart[0].addedfrombid == 1) {
      toast.error(LocaleStrings.negotiated_won_variant_error);
    } else {
      setSelid(data.id);
      props.validateUserCart(
        session,
        { variantid: parseInt(variantId) },
        (res) => {
          if (res.success && res.valid) {
            let values = {};
            values.productid = parseInt(productid);
            values.variantid = parseInt(variantId);
            values.quantity = 1;
            values.addedfrombid = 1;
            values.bidprice = data.bidprice;
            values.hasdiscount = item.discountactive ? 1 : 0;
            if (parseInt(item.discountactive) == 1) {
              values.discount = item.discount;
            }

            props.addCart(session, values, (res) => {
              setSelid("");
              if (res.success == 0) {
                toast.error(LocaleStrings.common_fail_message);
              } else {
                props.fetchMybids(session, "won");
                props.fetchCart(session);
                toast.success(LocaleStrings.add_to_cart_success);
              }
            });
          } else {
            setSelid("");
            toast.error(LocaleStrings.quantity_not_available);
          }
        }
      );
    }
  };

  // const addtoCart = (item, bidprice) => {
  //   //console.log("bidprice", bidprice);

  //   let selectedProduct = item;
  //   setSelectedProduct(selectedProduct);
  //   setProductId(selectedProduct.productid);
  //   setVariantId(selectedProduct.variantid);

  //   if (selectedProduct && selectedProduct.all_variants.length > 0) {
  //     let allVariants = [];
  //     _.map(selectedProduct.all_variants, (obj, index) => {
  //       obj.selected = obj.id == selectedProduct.variantid ? true : false;
  //       allVariants.push(obj);
  //     });
  //     //console.log("allVariants", allVariants);
  //     setAllVariants(allVariants);
  //   }

  //   let selectedVariant = _.filter(selectedProduct.all_variants, function (o) {
  //     return o.id == selectedProduct.variantid;
  //   });

  //   if (selectedVariant && selectedVariant.length > 0) {
  //     selectedVariant[0].bidprice = bidprice;
  //     selectedVariant[0].addedfrombid = 1;
  //     setSelectedVariant(selectedVariant);
  //   }
  //   //console.log("selectedVariant", selectedVariant);
  //   //debugger;
  //   if (
  //     selectedProduct.product_variants &&
  //     selectedProduct.product_variants.length > 0
  //   ) {
  //     let productVariants = [];
  //     _.map(selectedProduct.product_variants, (obj, index) => {
  //       let variantnames = [];
  //       if (obj.variantnames && obj.variantnames.length > 0) {
  //         _.map(obj.variantnames, (data, index) => {
  //           data.selected =
  //             selectedVariant &&
  //             selectedVariant.length > 0 &&
  //             selectedVariant[0].refkey.indexOf(data.refkey) !== -1
  //               ? true
  //               : false;
  //           variantnames.push(data);
  //         });
  //       }
  //       obj.variantnames = variantnames;
  //       productVariants.push(obj);
  //     });
  //     setProductVariants(productVariants);
  //   }

  //   props.openSliderPop(true);
  // };
  return (
    <>
      <div
        key={item.id}
        className="w-168 flex flex-col rounded-md overflow-hidden"
      >
        <div className="border border-primary rounded-md flex-shrink-0 relative">
          {/* <img
            className="h-cardimg w-full object-cover cursor-pointer rounded-md"
            src={`${FILES_URL}/vendor/variations/${item.productimage}?api_key=${APP_API_KEY}`}
            alt=""
            onClick={() =>
              viewDetails(
                _.kebabCase(item.productname_en) +
                  "-" +
                  item.id +
                  "-" +
                  item.currentvariant.id
              )
            }
          /> */}

          <LazyLoadImage
            className="h-cardimg w-full object-cover cursor-pointer rounded-md"
            src={`${FILES_URL}/vendor/variations/${item.productimage}?api_key=${APP_API_KEY}`}
            alt=""
            onClick={() =>
              viewDetails(
                _.kebabCase(item.productname_en) +
                  "-" +
                  item.id +
                  "-" +
                  item.currentvariant.id
              )
            }
            effect="blur"
          />
        </div>
        <div className="flex-1 bg-white flex flex-col justify-between pb-1">
          <div className="text-left">
            <a
              onClick={() =>
                viewDetails(
                  _.kebabCase(item.productname_en) +
                    "-" +
                    item.id +
                    "-" +
                    item.currentvariant.id
                )
              }
              className="block mt-2 cursor-pointer"
            >
              <p className="text-sm font-normal text-primary">
                {ProductTextLocalized(
                  item.productname_en,
                  item.productname_ar,
                  localLanguage
                )}
              </p>
            </a>
          </div>
        </div>
        <div className="bg-white rounded-md rounded-t-none pb-3">
          <p className="text-xs font-normal text-primary">
            {localCurrency}
            {bidprice}
          </p>
        </div>
        <div className="grid grid-cols-2 gap-1 pb-sm">
          {auctionText ? (
            endDate ? (
              <div>
                <div className="text-xs text-secondary">{auctionText}</div>
                <div>
                  {remainingTime.days <= 0 && remainingTime.hours <= 24 ? (
                    <div
                      className="flex items-center justify-center bg-red-500 w-full text-10 md:text-base text-white  rounded-2xl px-2 py-1"
                      style={{ fontSize: 10 }}
                    >
                      {timeText}
                    </div>
                  ) : (
                    <div
                      className="flex items-center justify-center bg-topnav w-full text-white rounded-xl pt-1 pb-1"
                      style={{ fontSize: 10 }}
                    >
                      {timeText}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <div className="text-xs text-secondary">
                  {item.bidcancelled || status == "cancelled"
                    ? LocaleStrings.cancelled
                    : auctionText}
                </div>
                <div>
                  <div
                    className="flex items-center justify-center bg-red-600 w-full text-white rounded-xl pt-1 pb-1"
                    style={{ fontSize: 10 }}
                  >
                    {LocaleStrings.bid_over}
                  </div>
                </div>
              </div>
            )
          ) : (
            ""
          )}

          {/* {endDate ? (
            <div>
              <div>
                <div className="flex items-center justify-center bg-topnav w-full text-white text-xs rounded-xl pt-1 pb-1">
                  {timeText}
                </div>
              </div>
            </div>
          ) : (
            ""
          )} */}

          <div className="text-right grid grid-cols-2 gap-3">
            <div
              className="text-xs flex items-center cursor-pointer"
              onClick={() =>
                status == "cancelled" ? null : bidPlaced(item.currentvariant.id)
              }
            >
              <div className="pr-0.5 text-xs text-secondary">
                {item.numbids}
              </div>
              <div className="text-xs text-secondary"> {LocaleStrings.bid}</div>
            </div>
            <div className="text-xs flex items-center">
              <div className="pr-0.5">
                <img src={EyeGreyIcon} className="w-6" />
              </div>
              <div className="text-xs text-cardfooter">{item.numwatching}</div>
            </div>
          </div>
        </div>
        {status == "won" && parseInt(data.itemalreadypurchased) == 0 ? (
          <div className="mt-2">
            <button
              className="text-white h-44 bg-btn text-sm font-normal rounded w-full inline-flex items-center justify-center"
              onClick={() => addtoCart(item, data)}
              disabled={data.id == selid}
            >
              {data.id == selid ? (
                <svg
                  className="animate-spin h-5 w-5 rounded-full mr-3"
                  viewBox="0 0 1024 1024"
                  focusable="false"
                  data-icon="loading"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                </svg>
              ) : (
                ""
              )}
              <span className="text-sm">{LocaleStrings.add_to_cart_btn}</span>
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
  getUserCart: state.getUserCart,
  localCurrency: state.localCurrency,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isbidPlacedSliderOpen,
      fetchPublicBids,
      emptyPublicBids,
      openSliderPop,
      addCart,
      fetchCart,
      validateUserCart,
      fetchMybids,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
