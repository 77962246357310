import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
  BASE_VIDEO_URL,
} from "../../../components/Common/constant";
import WishlistIcon from "../../../assets/images/wishlisted.svg";
import LikeIcon from "../../../assets/images/wishlist.svg";
import { likeUnlike, logActivity, isProductShareModalOpen } from "../actions";
import ReactImageMagnify from "react-image-magnify";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ShareIcon from "../../../assets/images/shareicon.svg";
function ImageSection(props) {
  const history = useHistory();
  const {
    productList,
    localLanguage,
    selectedProduct,
    setSelectedVariant,
    selectedVariant,
    variantImageId,
    setVariantImageId,
    session,
    isLoggedIn,
    setSelectedProduct,
    likes,
    setLikes,
    deviceSize,
    liked,
    setLiked,
    numLikes,
    setNumLikes,
  } = props;

  //console.log("selectedVariant", selectedVariant);
  //console.log("variantImageId", variantImageId);

  let selectedVariantImage = [];
  if (props.selectedVariant && props.selectedVariant.length > 0) {
    selectedVariantImage = _.filter(selectedVariant[0].media, function (o) {
      return o.id == variantImageId;
    });
  }

  const renderCarousel = () => {
    return _.map(selectedVariant[0].media, (item, index) => {
      return (
        <div className="text-center">
          {item.type == "video" ? (
            <video
              className="photo-leftimg"
              loop
              autoPlay
              playsInline
              muted
              controls
              src={`${BASE_VIDEO_URL}/vendor/variations/${item.mediafilename}?api_key=${APP_API_KEY}`}
              poster={`${FILES_URL}/vendor/variations/${item.thumbfilename}?api_key=${APP_API_KEY}`}
              type="video/mp4"
            />
          ) : (
            <img
              className="object-cover cursor-pointer"
              src={`${FILES_URL}/vendor/variations/${item.mediafilename}?api_key=${APP_API_KEY}`}
              alt=""
            />
          )}
        </div>
      );
    });
  };

  const renderThumb = () => {
    if (
      selectedVariant &&
      selectedVariant.length > 0 &&
      selectedVariant[0].media &&
      selectedVariant[0].media.length > 0
    ) {
      return _.map(selectedVariant[0].media, (media, index) => {
        return (
          <div
            className={classNames(
              //variantImageId == media.id ? "bg-thumbselected" : "bg-newarrival",
              "rounded flex justify-center items-center p-3 border border-header w-82 h-82"
            )}
            key={index}
          >
            <img
              className=" object-cover cursor-pointer  max-h-14 "
              src={`${FILES_URL}/vendor/variations/${media.thumbfilename}?api_key=${APP_API_KEY}`}
              alt=""
              onMouseEnter={(e) => setVariantImageId(media.id)}
              onTouchStart={(e) => setVariantImageId(media.id)}
            />
          </div>
        );
      });
    }
  };

  const likeUnlike = () => {
    if (isLoggedIn) {
      let productid = parseInt(selectedProduct.id);
      let values = {};
      values.productid = productid;
      props.likeUnlike(session, values, (res) => {
        if (res.success == 1) {
          if (res.likes) {
            setLikes(res.likes);
            setNumLikes(liked ? numLikes - 1 : numLikes + 1);
            setLiked(!liked);
          }
        }
      });
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };
  //console.log("selectedVariantImage", selectedVariantImage);
  const share = () => {
    props.isProductShareModalOpen(true);
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    className: "center",
    swipeToSlide: false,
    arrows: false,
  };

  let likeClass = liked ? LikeIcon : WishlistIcon;
  return (
    <>
      {selectedVariantImage && selectedVariantImage.length > 0 ? (
        !deviceSize.isMobile ? (
          <>
            <div className="h-variantImgmobBox  md:h-variantImgmdBox  lg:h-variantImglgBox xl:h-variantImgBox w-variantImgmobBox md:w-full  lg:w-full border border-primary rounded relative flex items-center justify-center">
              {/* <img
                  className="object-cover cursor-pointer"
                  src={`${FILES_URL}/vendor/variations/${selectedVariantImage[0].mediafilename}?api_key=${APP_API_KEY}`}
                  alt=""
                  style={{ width: "90%" }}
                /> */}
              {selectedVariantImage[0].type == "video" ? (
                <video
                  className="photo-leftimg max-h-maximg max-w-sm"
                  loop
                  autoPlay
                  playsInline
                  muted
                  controls
                  src={`${BASE_VIDEO_URL}/vendor/variations/${selectedVariantImage[0].mediafilename}?api_key=${APP_API_KEY}`}
                  poster={`${FILES_URL}/vendor/variations/${selectedVariantImage[0].thumbfilename}?api_key=${APP_API_KEY}`}
                  type="video/mp4"
                />
              ) : (
                // <ReactImageMagnify
                //   {...{
                //     smallImage: {
                //       alt: "",
                //       isFluidWidth: true,
                //       src: `${FILES_URL}/vendor/variations/${selectedVariantImage[0].thumbfilename}?api_key=${APP_API_KEY}`,
                //       sizes:
                //         "(min-width: 1200px) 1000px, (max-width: 900px) and (min-width: 400px) 50em,( not (orientation: portrait) ) 300px,( (orientation: landscape) or (min-width: 1000px) ) 50vw, 100vw",
                //     },
                //     largeImage: {
                //       alt: "",
                //       src: `${FILES_URL}/vendor/variations/${selectedVariantImage[0].mediafilename}?api_key=${APP_API_KEY}`,
                //       width: 1000,
                //       height: 1000,
                //     },
                //     isHintEnabled: true,
                //     //shouldUsePositiveSpaceLens: true,
                //     enlargedImageContainerDimensions: {
                //       width: "100%",
                //       height: "100%",
                //     },
                //     enlargedImageContainerStyle: {
                //       zIndex: 9999,
                //       background: "#f2f2f2",
                //       marginLeft: 0,
                //     },
                //     lensStyle: {
                //       width: 100,
                //       height: 100,
                //     },
                //     //imageClassName: "magnifyimage",
                //     //className: "magnifyroot",
                //     //enlargedImageClassName: "magnifyroot",
                //     //enlargedImageContainerClassName: "magnifyimage",
                //   }}
                // >
                //   {/* <img
                //   className="object-cover cursor-pointer"
                //   src={`${FILES_URL}/vendor/variations/${selectedVariantImage[0].mediafilename}?api_key=${APP_API_KEY}`}
                //   alt=""
                //   //style={{ width: "90%" }}
                // /> */}
                //   <LazyLoadImage
                //     className="object-cover cursor-pointer"
                //     src={`${FILES_URL}/vendor/variations/${selectedVariantImage[0].mediafilename}?api_key=${APP_API_KEY}`}
                //     alt=""
                //     effect="blur"
                //   />
                // </ReactImageMagnify>

                <LazyLoadImage
                  className="object-cover cursor-pointer max-h-maximg max-w-sm"
                  src={`${FILES_URL}/vendor/variations/${selectedVariantImage[0].mediafilename}?api_key=${APP_API_KEY}`}
                  alt=""
                  effect="blur"
                />
              )}
              <div className="absolute top-1 grid grid-cols-2 w-full">
                <div className="flex justify-start">
                  {/* <div
                    className={`flex items-center justify-center mt-1 ml-1 cursor-pointer border border-header rounded-lg p-3.5`}
                    onClick={() => share()}
                  >
                    <img src={ShareIcon} className="w-8" />
                  </div> */}
                  <div className={`flex items-start justify-start mt-1 ml-1`}>
                    {selectedProduct &&
                    selectedProduct.saletype != "auction" &&
                    selectedVariant &&
                    selectedVariant.length > 0 &&
                    selectedVariant[0].outofstock == 0 &&
                    selectedVariant[0].quantity > 0 &&
                    selectedVariant[0].quantity <= 5 ? (
                      <div className="bg-topnav text-white text-xs p-1 rounded">
                        {"Last " + selectedVariant[0].quantity + " items left"}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="flex justify-end">
                  <div
                    className={`flex items-center justify-center mt-1 mr-1 cursor-pointer border border-header rounded-lg p-2`}
                    onClick={() => likeUnlike()}
                  >
                    <img
                      src={liked ? WishlistIcon : LikeIcon}
                      className="w-7"
                      onMouseEnter={(e) => {
                        e.target.src = liked ? LikeIcon : WishlistIcon;
                      }}
                      onMouseLeave={(e) => {
                        e.target.src = liked ? WishlistIcon : LikeIcon;
                      }}
                      onTouchStart={(e) => {
                        e.target.src = liked ? LikeIcon : WishlistIcon;
                      }}
                      onTouchEnd={(e) => {
                        e.target.src = liked ? WishlistIcon : LikeIcon;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 flex space-x-4">{renderThumb()}</div>
          </>
        ) : (
          <div className="h-variantImgmobBox  w-variantImgmobBox border border-primary rounded">
            <div className="relative">
              <Slider {...settings}>{renderCarousel()}</Slider>
              <div className="absolute top-1 grid grid-cols-2 w-full">
                <div className="flex justify-start">
                  <div className={`flex items-start justify-start mt-1 ml-1`}>
                    {selectedProduct &&
                    selectedProduct.saletype != "auction" &&
                    selectedVariant &&
                    selectedVariant.length > 0 &&
                    selectedVariant[0].outofstock == 0 &&
                    selectedVariant[0].quantity > 0 &&
                    selectedVariant[0].quantity <= 5 ? (
                      <div className="bg-topnav text-white text-xs p-1 rounded">
                        {"Last " + selectedVariant[0].quantity + " items left"}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <div
                    className={`flex items-center justify-center mt-1 ml-1 cursor-pointer border border-header rounded-lg p-3.5`}
                    onClick={() => share()}
                  >
                    <img src={ShareIcon} className="w-8" />
                  </div> */}
                </div>
                <div className="flex justify-end">
                  <div
                    className={`flex items-center justify-center mt-1 mr-1 cursor-pointer border border-header rounded-lg p-2`}
                    onClick={() => likeUnlike()}
                  >
                    <img
                      src={liked ? WishlistIcon : LikeIcon}
                      className="w-7"
                      onMouseEnter={(e) => {
                        e.target.src = liked ? LikeIcon : WishlistIcon;
                      }}
                      onMouseLeave={(e) => {
                        e.target.src = liked ? WishlistIcon : LikeIcon;
                      }}
                      onTouchStart={(e) => {
                        e.target.src = liked ? LikeIcon : WishlistIcon;
                      }}
                      onTouchEnd={(e) => {
                        e.target.src = liked ? WishlistIcon : LikeIcon;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        ""
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  productList: state.productList,
  deviceSize: state.deviceSize,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { likeUnlike, logActivity, isProductShareModalOpen },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ImageSection);
