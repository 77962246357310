import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import { confirmAlert } from "react-confirm-alert";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { fetchSharedWishList, emptyWishList } from "../../Product/actions";
import ItemList from "../../Home/components/item-list";
import toast, { Toaster } from "react-hot-toast";
import PublicIcon from "../../../assets/images/public.svg";
import PrivateIcon from "../../../assets/images/private.svg";

function SharedDetails(props) {
  const history = useHistory();
  const listid = props.match.params.listid;
  const profileid = props.match.params.profileid;
  //console.log("profileid", profileid);
  // console.log("listid", listid);
  const { session, isLoggedIn, wishListed, deviceSize } = props;
  const [listDetails, setListDetails] = useState([]);
  const [delItem, setDelItem] = useState(false);
  const [delloading, setDelloading] = useState(false);
  const [checkedItem, setCheckedItem] = useState([]);

  useEffect(() => {
    if (profileid && listid) {
      props.emptyWishList();
      props.fetchSharedWishList(session, profileid, (res) => {
        if (res.success == 1 && res.data && res.data.length > 0) {
          let detailsArr = _.filter(res.data, function (o) {
            return o.id == listid;
          });
          setListDetails(detailsArr);
        }
      });
    }
  }, [profileid, listid]);

  const renderList = () => {
    return _.map(listDetails[0].items, (item, index) => {
      return (
        <ItemList
          item={item.productdetails}
          itemid={item.id}
          type="similar"
          prevpage="wishlist"
          pagetitle={LocaleStrings.wishlist_txt}
          delItem={delItem}
          checkedItem={checkedItem}
          setCheckedItem={setCheckedItem}
        />
      );
    });
  };

  //console.log("checkedItem", checkedItem);
  return (
    <>
      <div className="mt-5 mb-60 custom_container">
        {wishListed && wishListed.data ? (
          wishListed.data.length > 0 && listDetails.length > 0 ? (
            <div className="mt-2">
              <div className="p-3 flex items-center">
                <div className="w-wishdetailbox">
                  <ArrowLeftIcon
                    className="h-6 w-6 cursor-pointer"
                    onClick={() => history.goBack()}
                    aria-hidden="true"
                  />
                </div>
                <div className="w-4/5">
                  <div className="text-primary text-base font-normal pb-xs">
                    {listDetails[0].name}
                  </div>
                  <div
                    className={classNames(
                      listDetails[0].items && listDetails[0].items.length > 0
                        ? "divide-x divide-primary "
                        : " ",
                      "flex"
                    )}
                  >
                    <div className="flex items-center text-gray-300 text-sm pr-md">
                      <div className="pr-2">
                        <img
                          src={
                            listDetails[0].privacy == "public"
                              ? PublicIcon
                              : PrivateIcon
                          }
                        />
                      </div>
                      <div>{_.startCase(listDetails[0].privacy)}</div>
                    </div>

                    {listDetails[0].items && listDetails[0].items.length > 0 ? (
                      <div className="pl-md text-gray-300 text-sm">
                        {listDetails[0].items.length}&nbsp;Items
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="w-wishdetailbox text-right"></div>
              </div>
            </div>
          ) : (
            ""
          )
        ) : (
          <div className="h-50">
            <Loader />
          </div>
        )}
        <div className="my-3">
          {listDetails.length > 0 ? (
            listDetails[0].items && listDetails[0].items.length > 0 ? (
              <>
                <div className="grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 gap-5">
                  {renderList()}
                </div>
              </>
            ) : (
              <div className="flex items-center justify-center">
                {LocaleStrings.wishlist_no_product}
              </div>
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  wishListed: state.wishListed,
  deviceSize: state.deviceSize,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSharedWishList,
      emptyWishList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SharedDetails);
