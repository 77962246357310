import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
  LABEL_POSITION_TOP,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import {
  renderFieldText,
  renderFieldRadio1,
  renderDatalistSelect,
} from "../../../components/Common/base-component";
import {
  fetchWishList,
  addWishlist,
  isWishlistCreateNew,
} from "../../Product/actions";
import Loader from "../../../components/Common/loader";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import PublicIcon from "../../../assets/images/public.svg";
import PrivateIcon from "../../../assets/images/private.svg";
import toast, { Toaster } from "react-hot-toast";

function WishlistNew(props) {
  const {
    session,
    isLoggedIn,
    localLanguage,
    isWishlistCreateNewOpen,
    wishlistProductId,
    handleSubmit,
    wishListed,
    setAddtoWishlist,
    addtoWishlist,
    setIschanged,
  } = props;
  const [addNewSection, setAddNewSection] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeSlideOvers = () => {
    props.isWishlistCreateNew(false);
    props.reset();
  };

  const onSubmitForm = (values) => {
    setLoading(true);
    values.createdby = session.userid;
    props.addWishlist(session, values, (res) => {
      if (res.success == 1) {
        props.fetchWishList(session);
      } else {
        toast.error(LocaleStrings.common_fail_message);
      }
      closeSlideOvers();
    });
  };

  const privacyOptions = [
    { text: LocaleStrings.create_new_wishlist_public, value: "public" },
    { text: LocaleStrings.create_new_wishlist_private, value: "private" },
  ];

  //console.log("wishListed", wishListed);
  return (
    <>
      <Transition.Root show={isWishlistCreateNewOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          open={isWishlistCreateNewOpen}
          onClose={() => closeSlideOvers()}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div
                    className={classNames(
                      addNewSection ? "bg-white " : "bg-white ",
                      "h-full flex flex-col  shadow-xl"
                    )}
                  >
                    <div className="min-h-0 flex-1 flex flex-col pt-3 overflow-y-scroll">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="slider-modal-headtext">
                            <h2
                              id="slide-over-heading"
                              className="text-xl font-normal text-primary"
                            >
                              {LocaleStrings.create_new_wishlist}
                            </h2>
                          </Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              onClick={() => closeSlideOvers()}
                              className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 relative flex-1 px-4 sm:px-6">
                        <form onSubmit={handleSubmit(onSubmitForm)}>
                          <div className="mt-md">
                            <Field
                              name="name"
                              label={LocaleStrings.create_new_wishlist_name}
                              placeholder={
                                LocaleStrings.create_new_wishlist_name
                              }
                              type="text"
                              component={renderFieldText}
                              classNameLabel="text-gray-300 pb-sm text-base"
                              labelposition={LABEL_POSITION_TOP}
                              className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="mt-md">
                            <label className="text-gray-300 pb-sm text-base">
                              {LocaleStrings.create_new_wishlist_privacy}
                            </label>
                            <div className="mt-1 grid grid-cols-3">
                              {_.map(privacyOptions, (opt, index) => {
                                return (
                                  <Field
                                    name="privacy"
                                    label={opt.text}
                                    value={opt.value}
                                    lebelAlign="R"
                                    type="radio"
                                    component={renderFieldRadio1}
                                  />
                                );
                              })}
                            </div>
                          </div>
                          <div className="mt-md">
                            <button
                              type="submit"
                              className="text-white h-44 bg-btn text-sm font-normal rounded w-full inline-flex items-center justify-center"
                            >
                              {loading ? (
                                <svg
                                  className="animate-spin h-5 w-5 rounded-full mr-3"
                                  viewBox="0 0 1024 1024"
                                  focusable="false"
                                  data-icon="loading"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                </svg>
                              ) : (
                                ""
                              )}
                              <span className="text-sm">
                                {LocaleStrings.create_new_wishlist_btn}
                              </span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const validate = (values, ownProps) => {
  var errors = {};
  if (!values.name || values.name.trim() == "") {
    errors.name = LocaleStrings.create_new_wishlist_name_required;
  } else if (values.name.length > 30) {
    errors.name = LocaleStrings.wishlist_name_limit_error;
  }

  return errors;
};

const mapStateToProps = (state) => {
  var initVals = {};
  initVals = { privacy: "public" };

  return {
    initialValues: initVals,
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    localLanguage: state.localLanguage,
    deviceSize: state.deviceSize,
    isWishlistCreateNewOpen: state.isWishlistCreateNewOpen,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchWishList,
      addWishlist,
      isWishlistCreateNew,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "WishlistNewForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(WishlistNew)
);
