import { RAISETICKET_MODAL_OPEN, CONSUMER_FAQ_LIST } from "../actions/index";

/**** Raise Ticket Modal  ****/
export var raiseTicketOpen = (state = false, action) => {
  if (action.type === RAISETICKET_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

/**** FAQ Data List  ****/
export var faqList = (state = {}, action) => {
  if (action.type === CONSUMER_FAQ_LIST) {
    state = action.payload;
  }

  return state;
};
