import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
} from "../../../components/Common/constant";
import { SMALL_DATE_FORMAT } from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/outline";
import NoProduct from "../../../assets/images/no-product.svg";
import SubCate from "../../../assets/images/subcat.png";
import NoImg from "../../../assets/images/noimg.png";
import Collapsible from "react-collapsible";
import OnHoverScrollContainer from "../../../components/CustomScrollDiv";
import { emptySubList, fetchSubcategory } from "../actions";
import { fetchSettings } from "../../../actions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Carousel } from "react-responsive-carousel";

function CategoryView(props) {
  const history = useHistory();
  const { session, appSettings, localLanguage, deviceSize, subCategoryList } =
    props;
  const [catid, setCatid] = useState("");
  const [subcat, setSubcat] = useState("");
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    let id = props.match.params.id;
    if (id) {
      setCatid(id);
      props.emptySubList((res) => {});
      props.fetchSubcategory(id, (res) => {});
      window.scrollTo(0, 0);
    } else {
      props.fetchSettings(props.session, (res) => {
        if (res.data && res.data.categories && res.data.categories.length > 0) {
          id = res.data.categories[0].id;
          setCatid(id);
          props.emptySubList((res) => {});
          props.fetchSubcategory(id, (res) => {});
          window.scrollTo(0, 0);
        }
      });
    }
  }, []);

  const viewSubCategory = (catid) => {
    setCatid(catid);
    props.emptySubList((res) => {});
    props.fetchSubcategory(catid, (res) => {});
    window.scrollTo(0, "smooth");
  };

  const catRender = () => {
    return appSettings.categories.map(function (cat) {
      return deviceSize.isMobile ? (
        <div
          className={classNames(
            catid == cat.id ? "bg-newarrival" : "",
            "category-item cursor-pointer"
          )}
          onClick={() => viewSubCategory(cat.id)}
        >
          <div className="flex justify-center items-center">
            <div className="flex-shrink-0">
              <img
                src={`${FILES_URL}/vendor/productcategories/${cat.image}?api_key=${APP_API_KEY}`}
                className="w-10 h-auto"
              />
            </div>
          </div>
          <div className="flex justify-center text-primary text-10 md:text-sm font-normal mt-2">
            {ProductTextLocalized(
              cat.categoryname_en,
              cat.categoryname_ar,
              localLanguage
            )}
          </div>
        </div>
      ) : (
        <div
          className={classNames(
            catid == cat.id ? "bg-newarrival" : "",
            "flex cursor-pointer p-3"
          )}
          onClick={() => viewSubCategory(cat.id)}
        >
          <div className="w-1/4 flex justify-start items-center">
            <div className="flex-shrink-0">
              <img
                src={`${FILES_URL}/vendor/productcategories/${cat.image}?api_key=${APP_API_KEY}`}
                className="w-catsmaller h-auto"
              />
            </div>
          </div>
          <div className="text-primary text-10 md:text-sm font-normal w-3/4 flex justify-start items-center pl-0 md:pl-2 lg:pl-2">
            {ProductTextLocalized(
              cat.categoryname_en,
              cat.categoryname_ar,
              localLanguage
            )}
          </div>
        </div>
      );
    });
  };

  const renderSub = (subcategories) => {
    return _.map(subcategories, (data, index) => {
      return (
        <div
          className="cursor-pointer mt-5"
          onClick={() => productList(data.id)}
        >
          <div className="">
            <img
              src={`${FILES_URL}/vendor/productcategories/${data.image}?api_key=${APP_API_KEY}`}
              //className="w-10 h-auto"
              className="w-3/4 border border-primary rounded"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = SubCate;
              }}
            />
          </div>
          <div className="text-primary text-xs md:text-sm font-medium mt-3">
            {ProductTextLocalized(
              data.subcategoryname_en,
              data.subcategoryname_ar,
              localLanguage
            )}
          </div>
        </div>
      );
    });
  };

  const handleTrigegr = (subcatid) => {
    setSubcat(subcatid);
    setTrigger(!trigger);
  };

  const productList = (catid) => {
    history.push("/product-view/" + catid);
  };

  const renderList = () => {
    let subCatFilter = _.filter(subCategoryList.data, function (obj) {
      return obj.subcategories.length > 0;
    });

    let catFilter = _.filter(subCategoryList.data, function (obj) {
      return obj.subcategories.length == 0;
    });
    let childrenHtml = [];

    if (catFilter.length > 0) {
      let objHtml = [];
      _.map(catFilter, (item, index) => {
        objHtml.push(
          <div className="cursor-pointer" onClick={() => productList(item.id)}>
            <div className="">
              <img
                src={`${FILES_URL}/vendor/productcategories/${item.image}?api_key=${APP_API_KEY}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = SubCate;
                }}
                className="w-3/4 border border-primary rounded"
              />
            </div>
            <div className="text-primary text-xs md:text-sm font-medium mt-3">
              {ProductTextLocalized(
                item.subcategoryname_en,
                item.subcategoryname_ar,
                localLanguage
              )}
            </div>
          </div>
        );
      });
      childrenHtml.push(
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {objHtml}
        </div>
      );
    }
    if (subCatFilter.length > 0) {
      let objHtml = [];
      let childrenHtmlLength = childrenHtml.length;
      _.map(subCatFilter, (item, index) => {
        let html = (
          <div
            className={classNames(
              childrenHtmlLength > 0 ? "mt-5 " : " ",
              "flex items-center cursor-pointer"
            )}
          >
            <Collapsible
              trigger={
                <div className="flex items-center cursor-pointer">
                  <div className="w-11/12 text-primary text-xs md:text-sm font-medium">
                    {ProductTextLocalized(
                      item.subcategoryname_en,
                      item.subcategoryname_ar,
                      localLanguage
                    )}
                  </div>
                  <div className="text-right">
                    {trigger && subcat == item.id ? (
                      <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                    ) : (
                      <ChevronRightIcon
                        className="h-4 w-4"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                </div>
              }
              onOpen={() => handleTrigegr(item.id)}
              onClose={() => handleTrigegr("")}
              open={true}
            >
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                {renderSub(item.subcategories)}
              </div>
            </Collapsible>
          </div>
        );

        objHtml.push(html);
      });
      childrenHtml.push(objHtml);
    }

    return childrenHtml;
  };

  const desiredPage = (item) => {
    if (item.add_area == "category") {
      //history.push("/view-category/" + item.categoryids);
      viewSubCategory(item.categoryids);
    } else if (item.add_area == "subcategory") {
      // history.push("/view-category/" + item.subcategoryids);
      history.push("/product-view/" + item.subcategoryids);
    } else if (item.add_area == "product") {
      history.push(
        "/product-details/" +
          _.kebabCase(item.product.productname_en) +
          "-" +
          item.productid
      );
    } else if (item.externallink) {
      window.open(item.externallink, "_blank").focus();
    }
  };

  //console.log("subCategoryList", subCategoryList);
  return (
    <>
      {/* <div className="mt-2 mb-2 pl-5 md:pl-5 lg:pl-139 xl:pl-139 2xl:pl-298 pr-5 md:pr-5 lg:pr-139 xl:pr-139 2xl:pr-298">
        <div>{LocaleStrings.my_orders}</div>
      </div> */}
      <div className="custom_container py-25">
        <div className="flex">
          <div className="w-mobprodviewleft md:w-mdprodviewleft  lg:w-lgprodviewleft xl:w-xlprodviewleft 2xl:w-xxlprodviewleft divide-y divide-primary h-screen md:h-auto overflow-x-auto md:overflow-hidden no-scrollbar border border-primary rounded p-3 md:p-0">
            {appSettings &&
            appSettings.categories &&
            appSettings.categories.length > 0
              ? catRender()
              : ""}
          </div>
          <div className="w-mobprodviewright md:w-mdprodviewright lg:w-lgprodviewright xl:w-xlprodviewright 2xl:w-xxlprodviewright pl-0 md:pl-4">
            {subCategoryList &&
            subCategoryList.ads &&
            subCategoryList.ads.length > 0 ? (
              <div className="pl-5">
                <Carousel
                  autoPlay={true}
                  showArrows={true}
                  showThumbs={false}
                  infiniteLoop={true}
                  showStatus={false}
                >
                  {_.map(subCategoryList.ads, (item, index) => {
                    return (
                      <div
                        className="relative cursor-pointer bannerwrapper"
                        onClick={() => desiredPage(item)}
                      >
                        <LazyLoadImage
                          src={`${FILES_URL}/adbanners/${item.banner}?api_key=${APP_API_KEY}`}
                          className={classNames(
                            props.deviceSize.isMobile ? "" : "rounded ",
                            "w-auto h-150 md:h-318 object-cover"
                          )}
                          effect="blur"
                        />
                      </div>
                    );
                  })}
                </Carousel>

                {/* <div
                  className="rounded-lg cursor-pointer flex  justify-center md:flex-shrink-0"
                  onClick={() => desiredPage(subCategoryList.ads[0])}
                >
                  <LazyLoadImage
                    src={`${FILES_URL}/adbanners/${subCategoryList.ads[0].banner}?api_key=${APP_API_KEY}`}
                    className="w-full rounded-lg h-196 object-cover"
                    effect="blur"
                  />
                </div> */}
              </div>
            ) : (
              ""
            )}
            {subCategoryList && subCategoryList.data ? (
              subCategoryList.data.length > 0 ? (
                <div className="pl-5">{renderList()}</div>
              ) : (
                <div className="flex h-screen lg:h-catview items-center justify-center">
                  <div>
                    <div className="flex items-center justify-center pb-5">
                      <img src={NoProduct} />
                    </div>
                    <div className="flex items-center justify-center text-cutoff text-base font-medium">
                      {LocaleStrings.no_product_title}
                    </div>
                    <div className="flex items-center justify-center text-cutoff text-sm">
                      {LocaleStrings.no_product_desc}
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="flex h-screen lg:h-catview items-center justify-center">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  deviceSize: state.deviceSize,
  appSettings: state.appSettings,
  localLanguage: state.localLanguage,
  subCategoryList: state.subCategoryList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { emptySubList, fetchSubcategory, fetchSettings },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CategoryView);
