import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index";
import _ from "lodash";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
  itemCount,
} from "../../../components/Common/constant";

import { ProductTextLocalized } from "../../../components/Common/product-model";
// Import BaseLoader
import Loader from "../../../components/Common/loader";

//Icons
import SearchIcon from "../../../assets/images/search.svg";

// Import Modal Page
import RaiseTicketAdd from "./raiseticketadd.js";

// Import Action
import { isRaiseTicketModalopen, fetchFaq, searchFaq } from "../actions/index";

// Import Componets
import FAQNContactus from "./contactus";

import Pagination from "react-js-pagination";

const arrayData = [
  { text: "Not sure How evreything works?View Tutorial again" },
  { text: "I made a payment but order was not successfull" },
  { text: "I am not able to add my credit in the accounts section" },
  { text: "I cannot share my wishlist with other people" },
];

function multiIncludes(text, values) {
  var re = new RegExp(values.join("|"));
  return re.test(text);
}

class FAQNSupport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_faq_support,
      searchText: "",
      pageNumber: 1,
    };

    // All Binded Functions
  }

  componentDidMount() {
    // Table List Call
    this.props.fetchFaq(this.props.session, 0, (CallBackResponse) => {
      this.setState({ loading: false });
    });
  }

  //Arrow function for
  openModal() {
    if (this.props.isLoggedIn) {
      this.props.isRaiseTicketModalopen(true);
    } else {
      this.props.history.push({
        pathname: "/login",
        state: { from: "/faq" },
      });
    }
  }

  /*** Show Hide Answers ***/
  handelAnswers = (e, index) => {
    let clname = document.getElementById("answer_" + index).className;
    let arrow_clname = document.getElementById("arrow_" + index).className;
    if (multiIncludes(clname, ["hidden"])) {
      document.getElementById("answer_" + index).className =
        "text-sm font-medium text-gray-500";
      document.getElementById("arrow_" + index).className =
        "h-8 transform rotate-90 px-2 animation cursor-pointer transition duration-500 ease-in-out";
    } else {
      document.getElementById("answer_" + index).className =
        "text-sm font-medium text-gray-500 hidden";
      document.getElementById("arrow_" + index).className =
        "h-8 transform px-2 cursor-pointer animation duration-500 ease-in-out";
    }
  };

  // Render List
  renderList() {
    let { faqList } = this.props;

    var item = _.map(faqList, (value, index) => {
      return (
        <li
          key={index}
          className="py-4 bg-white md:px-6 transition ease-in-out duration-500 hover:bg-newarrival"
        >
          <div
            className="flex items-center space-x-4 cursor-pointer"
            onClick={(e) => this.handelAnswers(e, index)}
          >
            <div className="flex-1 min-w-0 cursor-pointer">
              <p className="text-sm text-primary">
                {ProductTextLocalized(
                  value.question_en,
                  value.question_ar,
                  this.props.localLanguage
                )}
              </p>
              <p
                className="text-sm font-medium text-primary hidden"
                id={"answer_" + index}
              >
                {ProductTextLocalized(
                  value.answer_en,
                  value.answer_ar,
                  this.props.localLanguage
                )}
              </p>
            </div>
            <div className="w-24" align="center">
              <img
                className="h-8 transform px-2 cursor-pointer animation duration-500 ease-in-out"
                id={"arrow_" + index}
                src="../profile_settings_images/arrow-right.svg"
              />
            </div>
          </div>
        </li>
      );
    });

    return item;
  }

  /*** Handel Search ***/
  handelChange = (e) => {
    var pageNumber = 1;
    let searchText = e.target.value;

    if (searchText.trim() === "") {
      this.props.fetchFaq(this.props.session, 0, (CallBackResponse) => {});
    } else {
      this.props.searchFaq(this.props.session, searchText);
    }

    this.setState({ searchText, pageNumber });
  };

  paginationCallback = (pageNumber) => {
    if (this.state.searchText.trim() === "") {
      this.props.fetchFaq(this.props.session, pageNumber, (response) => {});
    } else {
      this.props.searchFaq(
        this.props.session,
        this.state.searchText,
        pageNumber
      );
    }

    this.setState({ ...this.state, pageNumber });
  };

  render() {
    let { faqList, count, banAccount } = this.props;
    console.log("banAccount", banAccount);
    return (
      <>
        <div className="bg-newarrival py-25">
          {this.state.loading ? (
            <>
              <Loader />
            </>
          ) : (
            <div className="static bg-white rounded custom_container">
              {/* Header Part Start*/}
              <div className="grid  grid-cols-1 md:grid-cols-2 gap-3 px-6 pt-4">
                <div className="flex items-center border border-primary rounded">
                  <label htmlFor="search-field" className="sr-only">
                    {LocaleStrings.search}
                  </label>
                  <div className="relative w-full text-ternary focus-within:text-secondary ">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-1">
                      <img src={SearchIcon} className="w-6" />
                    </div>
                    <input
                      id="search-field"
                      className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                      placeholder={LocaleStrings.search_text_faq_support}
                      type="search"
                      name="search"
                      onChange={(e) => this.handelChange(e)}
                    />
                  </div>
                </div>

                <div className="flex md:justify-end">
                  <button
                    type="button"
                    className="text-white bg-btn text-sm font-normal pt-2.5 pb-2.5 pl-9 pr-9 rounded border border-variantSel btn-heavy"
                    onClick={(e) => this.openModal()}
                  >
                    {LocaleStrings.raise_a_ticket}
                  </button>
                </div>
              </div>
              {/* Header Part Ends*/}
              <div>
                <ul role="list" className="my-5 divide-y divide-gray-200">
                  {this.renderList()}
                </ul>
              </div>
              <Pagination
                activePage={this.state.pageNumber}
                itemsCountPerPage={itemCount}
                totalItemsCount={!_.isEmpty(faqList) ? count : 0}
                pageRangeDisplayed={10}
                onChange={this.paginationCallback}
              />
              {/* Footer Part*/}
              <FAQNContactus />

              <RaiseTicketAdd />
            </div>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    faqList: state.faqList.data,
    count: state.faqList.count,
    localLanguage: state.localLanguage,
    banAccount: state.banAccount,
  };
}

export default connect(mapStateToProps, {
  isRaiseTicketModalopen,
  fetchFaq,
  searchFaq,
})(FAQNSupport);
