import { ChevronUpIcon } from "@heroicons/react/outline";
const GoTop = (props) => {
  return (
    <>
      <div className={props.showGoTop} onClick={props.scrollUp}>
        <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
        &nbsp;&nbsp;Back to top
      </div>
    </>
  );
};
export default GoTop;
