import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
  BIG_DATE_FORMAT,
} from "../../../components/Common/constant";
import {
  currencyCode,
  currencyRate,
  ProductTextLocalized,
} from "../../../components/Common/product-model";
import Loader from "../../../components/Common/loader";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import NoImg from "../../../assets/images/noimg.png";
import { isbidPlacedSliderOpen } from "../actions";
import toast, { Toaster } from "react-hot-toast";
import {
  profileModalOpen,
  selectedFollowerUserDetails,
  selectedFollowersDetails,
  selectedFollowersShearedWishlist,
  emptySharedWishlist,
  profileViewNavigation,
  viewPublicProfile,
} from "../../Activity/actions";

function PublicBid(props) {
  const {
    session,
    isLoggedIn,
    localLanguage,
    publicBidModal,
    publicBids,
    localCurrency,
    navigateUser,
    viewProfileModal
  } = props;

  let currency = currencyCode(localCurrency);
  let currencyrate = currencyRate(localCurrency);

  const [loading, setLoading] = useState(false);

  const closeSlideOvers = () => {
    props.isbidPlacedSliderOpen(false);
  };

  const viewProfileModalmediator = (userid) => {
      closeSlideOvers();
      viewProfileModal(userid);
  }

  // const viewProfileModal = (userid) => {
  //   let userArr = navigateUser;
  //   closeSlideOvers();
  //   props.profileModalOpen(true, false);
  //   props.emptySharedWishlist();
  //   props.selectedFollowerUserDetails(session, userid, (callback) => {});
  //   props.selectedFollowersDetails(session, userid, (callback) => {});
  //   props.selectedFollowersShearedWishlist(session, userid, (callback) => {});
  //   props.viewPublicProfile(session, userid);
  //   if (!_.includes(userArr, userid)) {
  //     userArr.push(userid);
  //     props.profileViewNavigation(userArr);
  //   }
  // };

  const renderBids = (item) => {
    //console.log("item", item);
    return item.isprivate == 1 ? (
      <div className="grid grid-cols-4">
        <div className="col-span-3 flex pb-6">
          <div className="pr-3">
            <img
              key={item.id}
              className="max-w-none h-10 w-10 rounded-full ring-2 ring-white"
              src={NoImg}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = NoImg;
              }}
            />
          </div>
          <div>
            <div className="text-uname text-base font-normal">
              {LocaleStrings.anonymous}
            </div>
            {/* <div className="text-uname text-xs font-normal">
              {ProductTextLocalized(
                item.product.productname_en,
                item.product.productname_ar,
                localLanguage
              )}
              (
              {ProductTextLocalized(
                item.product.currentvariant.name_en,
                item.product.currentvariant.name_ar,
                localLanguage
              )}
              )
            </div> */}
            <div className="text-cardfooter text-xs font-normal">
              {moment(item.createdat).format(BIG_DATE_FORMAT)}
            </div>
          </div>
        </div>
        <div className="text-link text-base font-normal text-right">
          {currency}
          {item.bidprice.toFixed(2)}
        </div>
      </div>
    ) : item.isprivate == 0 ? (
      <div className="grid grid-cols-4">
        <div className="col-span-3 flex pb-6">
          <div
            className="pr-3 cursor-pointer"
            onClick={() => viewProfileModalmediator(item.userid)}
          >
            <img
              key={item.id}
              className="max-w-none h-10 w-10 rounded-full ring-2 ring-white"
              src={`${FILES_URL}/users/${item.userid}.jpg?api_key=${APP_API_KEY}`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = NoImg;
              }}
            />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => viewProfileModalmediator(item.userid)}
          >
            <div className="text-uname text-base font-normal">
              {item.businessname ? item.businessname : item.username}
            </div>
            {/* <div className="text-uname text-xs font-normal">
              {ProductTextLocalized(
                item.product.productname_en,
                item.product.productname_ar,
                localLanguage
              )}
              (
              {ProductTextLocalized(
                item.product.currentvariant.name_en,
                item.product.currentvariant.name_ar,
                localLanguage
              )}
              )
            </div> */}
            <div className="text-cardfooter text-xs font-normal">
              {moment(item.createdat).format(BIG_DATE_FORMAT)}
            </div>
          </div>
        </div>
        <div className="text-link text-base font-normal text-right">
          {currency}
          {item.bidprice}
        </div>
      </div>
    ) : (
      ""
    );
  };
  //console.log("publicBids", publicBids);
  return (
    <>
      <Transition.Root show={publicBidModal} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          open={publicBidModal}
          onClose={() => closeSlideOvers()}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                    <div className="min-h-0 flex-1 flex flex-col pt-3 overflow-y-scroll">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="slider-modal-headtext">
                            <h2
                              id="slide-over-heading"
                              className="text-xl font-normal text-primary"
                            >
                              {LocaleStrings.buying_history_bids_placed}
                              {publicBids &&
                              publicBids.data &&
                              publicBids.data.length > 0
                                ? " (" + publicBids.data.length + ")"
                                : ""}
                            </h2>
                          </Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              onClick={() => closeSlideOvers()}
                              className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="relative flex-1 mt-3 space-y-2">
                          {publicBids && publicBids.data ? (
                            publicBids.data.length > 0 ? (
                              publicBids.data.map((item, index) => {
                                return renderBids(item);
                              })
                            ) : (
                              ""
                            )
                          ) : (
                            <Loader />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
  publicBidModal: state.publicBidModal,
  publicBids: state.publicBids,
  localCurrency: state.localCurrency,
  navigateUser: state.navigateUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isbidPlacedSliderOpen,
      profileModalOpen,
      selectedFollowerUserDetails,
      selectedFollowersDetails,
      selectedFollowersShearedWishlist,
      emptySharedWishlist,
      profileViewNavigation,
      viewPublicProfile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PublicBid);
