import moment from "moment";
// import momentz from "moment-timezone";
import _ from "lodash";
export var MAIN_URL = "http://shopez.teks.co.in/"; // Dev
export var INSTANCE_URL = "http://shopez.teks.co.in"; // Dev
export var FILES_URL = `${INSTANCE_URL}/api/v2/files`; // Dev
export var BASE_VIDEO_URL = `${INSTANCE_URL}/api/v2/getvideo`; // Dev

//export var APP_API_KEY =
// "23617836dfd5176076cac6b2827dda6e948b262a85b691248498ed8640171a86"; // Private Key

export var APP_API_KEY =
  "55161aa8bc4d87d7ee880ac2d10ede8a3e16432ee9829df4211d605451223df4"; // Public Key

export var CURRENCY_RATE = 1;
export var CURRENCY = "$";

export var CURRENCY_RATE_USD = 1;
export var CURRENCY_RATE_QR = 3.64;
export var CURRENCY_USD = "USD";
export var CURRENCY_QR = "QAR";

export var LABEL_POSITION_TOP = "TOP";
export var LABEL_POSITION_LEFT = "LEFT";
export var DATE_FORMAT = "YYYY-MM-DD";
export var DATE_FORMAT_SM = "yyyy-MM-dd";
export var DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";
export var DATE_TIME_FORMAT_WITH_SECOND = "YYYY-MM-DD HH:mm:ss";
export var SMALL_DATE_FORMAT = "D MMM YYYY";
export var BIG_DATE_FORMAT = "D MMM YYYY hh:mm A";
export var LOGIN = "LOGIN";

export var GOOGLE_MAP_KEY = "AIzaSyBgJvAdkyu3daZSAbLZK1Mzz8x_S6ptSOc";

export var FACEBOOKID = "902050527004908";
// export var GOOGLEID =
//   "33967385789-co4qj2c93c5t35tpog2ln83pumns1c0c.apps.googleusercontent.com";

export var GOOGLEID =
  "1021002109811-l61h4qtkraneoj90htmuq3u9g6lip71q.apps.googleusercontent.com";

export var CONSUMER_KEY = "nFvgJ8XPaZoIc19WxNyjIaUnB";
export var CONSUMER_SECRET =
  "z5bB5zJy387ZK7AmFYRpfYowJHwDBNurclLZIVjXDztNeK9gVn";
export var TWITTER_CALLBACK_URL = "nFvgJ8XPaZoIc19WxNyjIaUnB://";
export var SOCIALPWD = "&@c9[x~#7>TTUv";

export var itemCount = calculateItemCount();
export var shareUrl = "http://shopez.staging.teks.co.in/";

export var VENDORSINGUP = "http://shopezvendor.staging.teks.co.in/register";
export var VENDORMODE =
  "http://shopezvendor.staging.teks.co.in/dashboard/salesmanagement";

export function timeDiffCalc(dateFuture, dateNow) {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";
  if (days > 0) {
    difference += `${days}D `;
  }

  difference += `${hours}h `;

  difference += `${minutes}m`;

  return difference;
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function timeAgo(dateParam) {
  let now = moment();
  let then = moment.utc(dateParam);
  then = then.local();

  let diff = {};
  diff.unit = "just now";
  diff.value = now.diff(then, "seconds");

  if (diff.value > 1) {
    diff.unit = "secs";
  }

  if (diff.unit == "secs" && diff.value > 59) {
    diff.unit = "min";
    diff.value = now.diff(then, "minutes");

    if (diff.value > 1) {
      diff.unit = "mins";
    }
  }
  if (diff.unit == "mins" && diff.value > 59) {
    diff.unit = "hour";
    diff.value = now.diff(then, "hours");

    if (diff.value > 1) {
      diff.unit = "hours";
    }
  }
  if (diff.unit == "hours" && diff.value > 24) {
    diff.unit = "day";
    diff.value = now.diff(then, "days");

    if (diff.value > 1) {
      diff.unit = "days";
    }
  }
  if (diff.unit == "days" && diff.value > 7) {
    diff.unit = "week";
    diff.value = now.diff(then, "weeks");

    if (diff.value > 1) {
      diff.unit = "weeks";
    }
  }
  if (diff.unit == "weeks" && diff.value > 4) {
    diff.unit = "month";
    diff.value = now.diff(then, "months");

    if (diff.value > 1) {
      diff.unit = "months";
    }
  }
  if (diff.unit == "months" && diff.value > 12) {
    diff.unit = "year";
    diff.value = now.diff(then, "years");

    if (diff.value > 1) {
      diff.unit = "years";
    }
  }
  return diff.unit != "just now"
    ? diff.value + " " + diff.unit + " ago"
    : diff.unit;
}

export function converDateIntoLocal(date) {
  var stillUtc = moment.utc(date).toDate();
  var local = moment(stillUtc).local();
  // .format('YYYY-MM-DD HH:mm:ss');
  return local;
}

export function randomString(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getRandomNum() {
  return _.random(10000);
}

export function pad(pad, str, padLeft) {
  if (typeof str === "undefined") return pad;
  if (padLeft) {
    return (pad + str).slice(-pad.length);
  } else {
    return (str + pad).substring(0, pad.length);
  }
}

/**** Is Empty Check ****/
export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function calculateItemCount() {
  //dynamically calcualting number of items to show as per height of the screen
  var height = window.innerHeight - 300; //250 is calculated to deduct extra space used other than just table
  var maxrowheight = 50;

  var count = Math.ceil(height / maxrowheight);

  return count < 1 ? 1 : count;
}

export function validateEmail(email) {
  var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  return re.test(email);
}

export var phoneCount = (str, ch) => _.countBy(str)[ch] || 0;

export function timeDiffRemaining(dateFuture, dateNow) {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let obj = {};

  obj.days = days;

  obj.hours = hours;

  obj.minutes = minutes;

  return obj;
}

export function isValidQatarPhoneNumber(phonenumber) {
  var regex = new RegExp(/^(\+974)(3|4|5|6|7)([0-9]{7})$/);

  if (phonenumber == null) {
    return false;
  }

  if (regex.test(phonenumber) == true) {
    return true;
  } else {
    return false;
  }
}
