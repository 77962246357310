import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import ItemList from "../../Home/components/item-list";
import RightArrow from "../../../assets/images/right-arrow.svg";
import { searchProduct, emptySearch, setSearchKey } from "../../Home/actions";

function ProductSearch(props) {
  const history = useHistory();
  const location = useLocation();
  const { searchList, getSearchKey } = props;

  useEffect(() => {
    const searchterm = props.match.params.searchterm;
    // console.log("searchterm", searchterm);
    // console.log("props", props);
    if (searchterm) {
      let catid = "";
      if (props.match?.params?.catid) {
        catid = props.match?.params?.catid;
      }
      props.setSearchKey(searchterm);
      props.emptySearch();
      props.searchProduct(props.session, searchterm, catid);
    }
    window.scrollTo(0, 0);
  }, []);

  const itemRender = () => {
    return _.map(searchList.data, (post, index) => {
      return <ItemList item={post} />;
    });
  };

  return (
    <>
      <div className="py-25 text-left text-primary text-xs custom_container">
        <div className="mb-2.5">
          <a href="/" className="cursor-pointer">
            {LocaleStrings.breadcrumb_home}
          </a>
          / {LocaleStrings.breadcrumb_search}
        </div>
      </div>
      <div className="custom_container">
        {searchList && searchList.data ? (
          searchList.data.length > 0 ? (
            <div
              className="bg-newarrival grid grid-cols-2 gap-1 px-5 rounded-10"
              style={{
                background: `linear-gradient(180deg, #edf1f8 0%, #FFFFFF 100%)`,
              }}
            >
              <div className="col-span-2 pb-5 pt-5">
                <div className="grid grid-cols-2  md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 md:gap-8 lg:gap-8 xl:gap-9 2xl:gap-4">
                  {itemRender()}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <div className="">
                <div className="mt-6 flex items-center justify-center text-base text-primary">
                  {LocaleStrings.search_not_found + " " + getSearchKey.key}
                </div>
                <div className="mt-2 flex items-center justify-center text-sm text-primary">
                  Try checking your spelling or use more general terms
                </div>
                <div className="mt-3 flex items-center justify-center text-base text-primary">
                  NEED HELP?
                </div>
                <div className="mt-1 mb-8 flex items-center justify-center">
                  <>
                    Visit the&nbsp;
                    <a
                      className="text-base text-vendorlink cursor-pointer"
                      onClick={() => history.push("/faq")}
                    >
                      FAQ & Support
                    </a>
                    &nbsp;section
                  </>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="h-50">
            <Loader />
          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  // isLoggedIn: state.isLoggedIn,
  // session: state.session
  localLanguage: state.localLanguage,
  searchList: state.searchList,
  getSearchKey: state.getSearchKey,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ searchProduct, emptySearch, setSearchKey }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);
