import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EnglishLogo from "../assets/images/english_white.svg";
import ArabicLogo from "../assets/images/arabic_white.svg";
import Mastercard from "../assets/images/mastercard.svg";
import Mastercard1 from "../assets/images/mastercard1.svg";
import Visa from "../assets/images/visa.svg";
import American from "../assets/images/american.svg";
import LocaleStrings from "../languages";

function copyright(props) {
  let langOptions = [];
  langOptions.push(
    <option value="en" selected={props.localLanguage == "en" ? "SELECTED" : ""}>
      English
    </option>
  );
  langOptions.push(
    <option value="ar" selected={props.localLanguage == "ar" ? "SELECTED" : ""}>
      عربى
    </option>
  );
  let currencyOptions = [];
  currencyOptions.push(
    <option
      value="USD"
      selected={props.localCurrency == "USD" ? "SELECTED" : ""}
    >
      {LocaleStrings.dollar}
    </option>
  );
  currencyOptions.push(
    <option
      value="QAR"
      selected={props.localCurrency == "QAR" ? "SELECTED" : ""}
    >
      {LocaleStrings.qatari_riyal}
    </option>
  );
  return (
    <div className="bg-white flex items-center">
      <div className="w-full">
        <div
          className="custom_container border-t border-header"
          style={
            props.deviceSize.isMobile
              ? { paddingLeft: 10, paddingRight: 10 }
              : {}
          }
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5  pt-lg pb-lg md:pb-24">
            <div className="pl-0 flex items-center justify-center md:justify-start  text-sm md:text-13 text-numbid">
              © Shopez E-Commerce L.L.C - 2023. The trademarks and designs
              appearing here are exclusively owned by Shopez E-Commerce L.L.C,
              and/or its licensors, and are protected. Unauthorized use will
              invite legal action.
            </div>
            <div className="text-left flex items-center justify-center md:justify-end">
              <div>
                <img src={Mastercard1} />
              </div>
              <div>
                <img src={Mastercard} />
              </div>
              <div>
                <img src={Visa} />
              </div>
              <div>
                <img src={American} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
  localCurrency: state.localCurrency,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(copyright);
