import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import Loader from "../../../components/Common/loader";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
  CURRENCY_RATE,
  CURRENCY,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
  currencyCode,
} from "../../../components/Common/product-model";

import {
  ChevronRightIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import CartEmpty from "../../../assets/images/cart_empty.svg";
import { useLocation } from "react-router-dom";
import { fetchOrderDetailsbyMasterId, makePaymentonly } from "../actions";
import toast, { Toaster } from "react-hot-toast";

function PayFail(props) {
  const location = useLocation();
  const history = useHistory();

  const {
    session,
    isLoggedIn,
    localLanguage,
    deviceSize,
    orderMasterDetails,
    localCurrency,
  } = props;
  const [description, setDescription] = useState(null);
  const [masterId, setMasterId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymethod, setPaymethod] = useState("");
  const [appSession, setAppSession] = useState(false);

  let CURRENCY = currencyCode(localCurrency);
  useEffect(() => {
    let search = new URLSearchParams(location.search);
    let description = search.get("description");
    //console.log("description", description);
    let order_master_id = search.get("order_id");
    //console.log("order_id", order_id);

    if (order_master_id) {
      setMasterId(order_master_id);
      setDescription(description);
      window.scrollTo(0, 0);
      let session_token = search.get("session_token");
      if (session_token) {
        setAppSession(true);
        let seObj = {};
        seObj.apiKey = APP_API_KEY;
        seObj.sessionToken = session_token;
        props.fetchOrderDetailsbyMasterId(
          seObj,
          order_master_id,
          true,
          (res) => {}
        );
      } else {
        props.fetchOrderDetailsbyMasterId(
          session,
          order_master_id,
          false,
          (res) => {}
        );
      }
    }
  }, []);

  const payNow = (type) => {
    if (masterId) {
      setLoading(true);
      setPaymethod(type);
      let values = {};
      values.order_master_id = masterId;
      values.payment_method = type;
      values.callfrom = "web";
      props.makePaymentonly(session, values, (response) => {
        if (response.success == 1) {
          if (values.payment_method == "cash") {
            setLoading(false);
            setPaymethod("");
            toast.success(LocaleStrings.order_placed_success);
            history.push("/myorders");
          } else {
            setPaymethod("");
            setLoading(false);
            if (response.checkout_url) {
              window.location.href = response.checkout_url;
            } else {
              toast.error(LocaleStrings.common_fail_message);
            }
          }
        }else{
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    }
  };

  //console.log("orderMasterDetails", orderMasterDetails);
  let product_total = 0.0;
  let discount = 0.0;
  let coupon_discount = 0.0;
  let shipping = 0.0;
  let tax = 0.0;
  let grand_total = 0.0;
  let isCashonDelivery = false;
  if (orderMasterDetails.length > 0) {
    orderMasterDetails.map((orderDetails, index) => {
      product_total += parseFloat(
        orderDetails?.order_details?.summary?.product_total
      );
      discount += parseFloat(orderDetails?.order_details?.summary?.discount);
      coupon_discount += parseFloat(
        orderDetails?.order_details?.summary?.coupon_discount
      );
      shipping += parseFloat(orderDetails?.order_details?.summary?.shipping);
      tax += parseFloat(orderDetails?.order_details?.summary?.tax);
      grand_total += parseFloat(
        orderDetails?.order_details?.summary?.grand_total
      );
    });

    let cashArr = _.filter(orderMasterDetails, function (obj) {
      return obj.cashondelivery == "no";
    });
    isCashonDelivery = cashArr.length > 0 ? false : true;
  }

  return (
    <>
      {!appSession ? (
        <div className="mt-2 mb-2 custom_container flex items-center">
          <a
            className="text-link cursor-pointer"
            onClick={() => history.push("/")}
          >
            {LocaleStrings.breadcrumb_home}
          </a>
          <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
          <a
            className="text-link cursor-pointer"
            onClick={() => history.push("/myorders")}
          >
            {LocaleStrings.my_orders}
          </a>
          <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
          {LocaleStrings.order_details}
        </div>
      ) : (
        ""
      )}
      {orderMasterDetails.length > 0 ? (
        <>
          <div className="bg-newarrival py-25">
            <div className="bg-white rounded-lg text-center mx-md my-md py-5 custom_container">
              <div className=" flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    fill="red"
                  />
                </svg>
                &nbsp;
                <div className="text-18">
                  Payment failed, order is not yet placed
                </div>
              </div>
              <div className="text-sm">{description}</div>
            </div>

            <div className="block md:flex custom_container">
              {deviceSize.isMobile ? (
                <div className="fixed inset-x-0 bottom-0 p-5 bg-white z-50 ">
                  <div>
                    <button
                      className="px-sm py-3 bg-btn text-white font-medium text-sm cursor-pointer rounded w-full flex items-center justify-center"
                      onClick={() => payNow("card")}
                    >
                      {loading && paymethod == "card" ? (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        ""
                      )}
                      <span className="text-base">
                        {LocaleStrings.cart_payment_card}
                      </span>
                    </button>
                  </div>
                  {isCashonDelivery ? (
                    <div className="mt-4">
                      <button
                        className="px-sm py-3 bg-btn text-white font-medium text-sm cursor-pointer rounded w-full flex items-center justify-center"
                        onClick={() => payNow("cash")}
                      >
                        {loading && paymethod == "cash" ? (
                          <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              stroke-width="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        ) : (
                          ""
                        )}
                        <span className="text-base">
                          {LocaleStrings.cart_payment_cash}
                        </span>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              <div className="w-full md:w-cartleft mb-4 md:mr-4 ">
                <div className="bg-white rounded mb-4 pb-4">
                  {orderMasterDetails.map((orderDetails, index) => {
                    return (
                      <div className="relative flex pl-15 pt-4 pb-4">
                        {orderDetails.currentstatus &&
                        orderDetails.currentstatus.status &&
                        orderDetails.currentstatus.status == "pending" ? (
                          <div className="absolute -rotate-45 text-red-500 text-2xl font-medium transform -left-1.5 ">
                            {LocaleStrings.payment_pending}
                          </div>
                        ) : (
                          ""
                        )}
                        <div
                          //className="flex items-center justify-center p-5 rounded-md border border-primary w-82 h-82"
                          className={classNames(
                            orderDetails.currentstatus &&
                              orderDetails.currentstatus.status &&
                              orderDetails.currentstatus.status == "pending"
                              ? "opacity-30"
                              : "opacity-100",
                            "  flex items-center justify-center p-5 rounded-md border border-primary w-82 h-82"
                          )}
                        >
                          <a
                            href={`/product-details/${
                              _.kebabCase(orderDetails.productname_en) +
                              "-" +
                              orderDetails.productid +
                              "-" +
                              orderDetails.variantid
                            }`}
                            className="cursor-pointer"
                          >
                            <img
                              className=" object-cover cursor-pointer  max-h-14 "
                              src={`${FILES_URL}/vendor/variations/${orderDetails.productimage}?api_key=${APP_API_KEY}`}
                            />
                          </a>
                        </div>
                        <div
                          // className="w-4/5 pl-4"
                          className={classNames(
                            orderDetails.currentstatus &&
                              orderDetails.currentstatus.status &&
                              orderDetails.currentstatus.status == "pending"
                              ? "opacity-30 "
                              : "opacity-100",
                            "   w-4/5 pl-4"
                          )}
                        >
                          <div className="font-medium text-primary text-sm">
                            <a
                              href={`/product-details/${
                                _.kebabCase(orderDetails.productname_en) +
                                "-" +
                                orderDetails.productid +
                                "-" +
                                orderDetails.variantid
                              }`}
                              className="cursor-pointer"
                            >
                              {ProductTextLocalized(
                                orderDetails.productname_en,
                                orderDetails.productname_ar,
                                localLanguage
                              )}
                            </a>
                          </div>
                          <div className="text-cardfooter text-sm">
                            {LocaleStrings.seller}&nbsp;:&nbsp;
                            {orderDetails?.seller?.username}
                          </div>
                          <div className="text-cardfooter text-sm">
                            {LocaleStrings.sku}&nbsp;:&nbsp;
                            {orderDetails?.sku}
                          </div>
                          <div className="text-cardfooter text-sm">
                            {LocaleStrings.quantity}&nbsp;:&nbsp;
                            {orderDetails?.quantity}
                          </div>
                          <div className="text-primary text-sm font-medium">
                            {CURRENCY}
                            {orderDetails?.originalprice}
                          </div>
                          <div className="mt-4"></div>
                        </div>
                      </div>
                    );
                  })}
                  {!deviceSize.isMobile ? (
                    <div className="px-15 flex items-center ">
                      <div className="w-1/2">
                        <button
                          className="px-sm py-3 bg-btn text-white font-medium text-sm cursor-pointer rounded w-full lg:w-3/4 flex items-center justify-center"
                          onClick={() => payNow("card")}
                        >
                          {loading && paymethod == "card" ? (
                            <div>
                              <svg
                                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  stroke-width="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="text-base">
                            {LocaleStrings.cart_payment_card}
                          </div>
                        </button>
                      </div>
                      {isCashonDelivery ? (
                        <div className="w-1/2 ml-3">
                          <button
                            className="px-sm py-3 bg-btn text-white font-medium text-sm cursor-pointer rounded w-full lg:w-3/4 flex items-center justify-center"
                            onClick={() => payNow("cash")}
                          >
                            {loading && paymethod == "cash" ? (
                              <div>
                                <svg
                                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    stroke-width="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="text-base">
                              {LocaleStrings.cart_payment_cash}
                            </div>
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="w-full md:w-cartright">
                <div className="bg-white rounded mb-4 px-md py-md">
                  <div className="bg-white rounded px-md py-md opacity-30">
                    <div className="font-medium text-sm text-primary">
                      {LocaleStrings.cart_order_summery}
                    </div>
                    <div className="pt-6">
                      <div className="grid grid-cols-4 mb-2">
                        <div className="col-span-3 text-primary text-xs font-medium">
                          {LocaleStrings.cart_product_price}
                        </div>
                        <div className="text-right text-primary font-medium text-xs">
                          {CURRENCY}
                          {product_total.toFixed(2)}
                        </div>
                      </div>
                      <div className="grid grid-cols-4 mb-2">
                        <div className="col-span-3 text-primary text-xs font-medium">
                          {LocaleStrings.cart_product_discount}
                        </div>
                        <div className="text-right text-primary font-medium text-xs">
                          - {CURRENCY}
                          {discount.toFixed(2)}
                        </div>
                      </div>
                      {coupon_discount > 0 ? (
                        <div className="grid grid-cols-4 mb-2">
                          <div className="col-span-3 text-primary text-xs font-medium">
                            {LocaleStrings.cart_coupon_discount}
                          </div>
                          <div className="text-right text-primary font-medium text-xs">
                            {CURRENCY}
                            {coupon_discount.toFixed(2)}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="grid grid-cols-4 mb-2">
                        <div className="col-span-3 text-primary text-xs font-medium">
                          {LocaleStrings.cart_shipping_fee}
                        </div>
                        <div className="text-right text-primary font-medium text-xs">
                          {CURRENCY}
                          {shipping.toFixed(2)}
                        </div>
                      </div>
                      <div className="grid grid-cols-4 pb-2 mb-2 border-b border-primary">
                        <div className="col-span-3 text-primary text-xs font-medium">
                          {LocaleStrings.cart_tax_charges}
                        </div>
                        <div className="text-right text-primary font-medium text-xs">
                          {CURRENCY}
                          {tax.toFixed(2)}
                        </div>
                      </div>
                      <div className="grid grid-cols-4">
                        <div className="col-span-3 text-primary text-xs font-medium">
                          {LocaleStrings.cart_total}
                        </div>
                        <div className="text-right text-primary font-medium text-xs">
                          {CURRENCY}

                          {grand_total.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="bg-newarrival py-25">
          <div className="bg-white rounded-lg text-center mx-md my-md py-5 custom_container">
            <div className=" flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                  fill="red"
                />
              </svg>
              &nbsp;
              <div className="text-18">This is invalid order</div>
            </div>
            <div className="text-sm">
              The order is either already placed or this is not belongs to you.
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
  orderMasterDetails: state.orderMasterDetails,
  localCurrency: state.localCurrency,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchOrderDetailsbyMasterId, makePaymentonly },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PayFail);
