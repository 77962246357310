import React from "react";
import NewsLetter from "./newsletter";
import FooterAdd from "./footer-add";
import FooterNav from "./footer-nav";
import Copyright from "./copyright";

export const footer = () => {
  return (
    <>
      <div
      // style={{
      //   boxShadow: "2px 1px 8px #00000030",
      // }}
      >
        {/* <FooterAdd />
      <NewsLetter /> */}
        <FooterNav />
        <Copyright />
      </div>
    </>
  );
};

export default footer;
