import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import {
  addCart,
  fetchCart,
  initiateNegotiationAction,
  validateUserCart,
  negotiationDetailsAction,
} from "../actions";
import {
  isNegotiationOpen,
  negotiationThread,
} from "../../Negotiation/actions";
import { openSliderPop } from "../../../actions";
import toast, { Toaster } from "react-hot-toast";
import { fetchMybids } from "../../Mybids/actions";

function ChooseVariant(props) {
  const {
    session,
    isLoggedIn,
    localLanguage,
    productid,
    variantId,
    allVariants,
    productVariants,
    renderProductVariant,
    selectedVariant,
    selectedProduct,
    originate,
    getUserCart,
    setIschanged,
  } = props;
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const incrementValue = () => {
    var currentVal = quantity;

    if (!isNaN(currentVal)) {
      let finalQuantity = currentVal + 1;
      if (
        selectedVariant &&
        selectedVariant.length > 0 &&
        parseInt(selectedVariant[0].quantity) > 0 &&
        parseInt(selectedVariant[0].quantity) >= finalQuantity
      ) {
        setQuantity(currentVal + 1);
      } else {
        toast.error(LocaleStrings.quantity_not_available);
      }
    } else {
      setQuantity(1);
    }
  };
  const decrementValue = () => {
    var currentVal = quantity;

    if (!isNaN(currentVal) && currentVal > 0) {
      setQuantity(currentVal == 1 ? currentVal : currentVal - 1);
    } else {
      setQuantity(1);
    }
  };

  const addtoCart = () => {
    let alreadyinCart = [];
    if (getUserCart && getUserCart.items) {
      alreadyinCart = _.filter(getUserCart.items, function (o) {
        return o.productid == productid && o.variantid == variantId;
      });
    }

    if (alreadyinCart.length > 0 && alreadyinCart[0].negotiated == 1) {
      toast.error(LocaleStrings.negotiated_won_variant_error);
    } else if (alreadyinCart.length > 0 && alreadyinCart[0].addedfrombid == 1) {
      toast.error(LocaleStrings.negotiated_won_variant_error);
    } else {
      let values = {};
      setLoading(true);
      props.validateUserCart(
        session,
        { variantid: parseInt(variantId) },
        (res) => {
          if (res.success && res.valid) {
            values.productid = parseInt(productid);
            values.variantid = parseInt(variantId);
            values.quantity = quantity;
            if (
              selectedVariant.length > 0 &&
              selectedVariant[0].addedfrombid &&
              selectedVariant[0].addedfrombid == 1
            ) {
              values.addedfrombid = 1;
              values.bidprice = selectedVariant[0].bidprice;
            }
            values.hasdiscount = selectedProduct.discountactive ? 1 : 0;
            if (parseInt(selectedProduct.discountactive) == 1) {
              values.discount = selectedProduct.discount;
            }

            props.addCart(session, values, (res) => {
              setLoading(false);
              props.openSliderPop(false);
              window.scrollTo(0, 0);
              if (res.success == 0) {
                toast.error(LocaleStrings.common_fail_message);
              } else {
                if (values.addedfrombid) {
                  props.fetchMybids(session, "won");
                }

                props.fetchCart(session);
                toast.success(LocaleStrings.add_to_cart_success);
              }
            });
          } else {
            setLoading(false);

            toast.error(LocaleStrings.quantity_not_available);
          }
        }
      );
    }

    //console.log("values", values);
  };

  const initiateNegotiation = () => {
    let values = {};
    values.productid = parseInt(productid);
    values.variantid = parseInt(variantId);
    values.quantity = quantity;
    if (parseInt(selectedProduct.discountactive) == 1) {
      values.discount = selectedProduct.discount;
    }
    setLoading(true);
    props.initiateNegotiationAction(session, values, (res) => {
      //console.log("res", res);
      setLoading(false);
      if (res.success == 0) {
        toast.error(LocaleStrings.common_fail_message);
      } else {
        //props.updateBeingNegotiated();
        if (setIschanged) {
          setIschanged(true);
        }
        props.openSliderPop(false);
        let threadid = res.threadid;
        //toast.success(LocaleStrings.negotiation_ini_success_message);

        props.negotiationDetailsAction(session, threadid, (res) => {});
        props.negotiationThread(threadid);
        props.isNegotiationOpen(true);
      }
    });
  };
  //console.log("productid", productid);
  // console.log("variantid", variantId);
  //console.log("allVariants", allVariants);

  //console.log("getUserCart", getUserCart);
  return (
    <div className="">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-1 mb-3">
        <div className="flex items-center text-left text-primary text-base font-normal">
          {LocaleStrings.choose_variant}
        </div>
        <div className="text-left md:text-right">
          <div className="input-group">
            <input
              type="button"
              value="-"
              className="button-minus rounded-l cursor-pointer"
              data-field="quantity"
              onClick={() =>
                selectedVariant.length > 0 &&
                selectedVariant[0].addedfrombid &&
                selectedVariant[0].addedfrombid == 1
                  ? null
                  : decrementValue()
              }
            />
            <input
              type="number"
              step="1"
              max=""
              min="1"
              value={quantity}
              name="quantity"
              className="variant quantity-field"
            />
            <input
              type="button"
              value="+"
              className="button-plus rounded-r cursor-pointer"
              data-field="quantity"
              onClick={() =>
                selectedVariant.length > 0 &&
                selectedVariant[0].addedfrombid &&
                selectedVariant[0].addedfrombid == 1
                  ? null
                  : incrementValue()
              }
            />
          </div>
        </div>
      </div>
      <div>
        {productVariants && productVariants.length > 0
          ? _.map(productVariants, (prodV, index) => {
              return (
                <>
                  <div className="text-left mt-1 text-primary font-normal text-sm">
                    {ProductTextLocalized(
                      prodV.name_en,
                      prodV.name_ar,
                      localLanguage
                    )}
                  </div>
                  <div
                    className={classNames(
                      index + 1 < productVariants.length
                        ? "border-b border-primary"
                        : "",
                      "mt-2.5"
                    )}
                  >
                    <div className="pb-2.5 grid grid-cols-3 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-4">
                      {renderProductVariant(prodV.variantnames, index)}
                    </div>
                  </div>
                </>
              );
            })
          : ""}
      </div>
      <div className="flex mt-6">
        <div className="text-left text-cutoff font-normal text-sm">
          {LocaleStrings.selected_variant} : &nbsp;
        </div>
        <div className="text-left text-primary font-normal text-sm">
          {selectedVariant && selectedVariant.length > 0
            ? ProductTextLocalized(
                selectedVariant[0].name_en,
                selectedVariant[0].name_ar,
                localLanguage
              )
            : ""}
        </div>
      </div>
      <div className="mt-4 pl-3 grid grid-cols-3 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-5">
        {allVariants && allVariants.length > 0
          ? _.map(allVariants, (vari, index) => {
              return (
                <div
                  className={classNames(
                    variantId == parseInt(vari.id)
                      ? "border border-variantSel"
                      : "",
                    "rounded flex justify-center items-center p-4"
                  )}
                >
                  <img
                    className="object-cover cursor-pointer"
                    src={`${FILES_URL}/vendor/variations/${vari.media[0].thumbfilename}?api_key=${APP_API_KEY}`}
                    alt=""
                  />
                </div>
              );
            })
          : ""}
      </div>
      <div className="mt-7">
        {originate == "negotiateini" &&
        selectedVariant &&
        selectedVariant.length > 0 &&
        selectedVariant[0].beingnegotiated == 1 ? (
          ""
        ) : (
          <button
            className="text-white h-44 bg-btn text-sm font-normal rounded w-full inline-flex items-center justify-center"
            onClick={
              originate == "negotiateini"
                ? () => initiateNegotiation()
                : () => addtoCart()
            }
            disabled={loading}
          >
            {loading ? (
              <svg
                className="animate-spin h-5 w-5 rounded-full mr-3"
                viewBox="0 0 1024 1024"
                focusable="false"
                data-icon="loading"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
              </svg>
            ) : (
              ""
            )}
            <span className="text-sm">
              {originate == "negotiateini"
                ? LocaleStrings.negotiate_btn
                : LocaleStrings.add_to_cart_btn}
            </span>
          </button>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
  getUserCart: state.getUserCart,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addCart,
      fetchCart,
      openSliderPop,
      initiateNegotiationAction,
      validateUserCart,
      fetchMybids,
      isNegotiationOpen,
      negotiationThread,
      negotiationDetailsAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChooseVariant);
