import {
  BID_MODAL,
  BIDDING_PRICE,
  PRODUCT_COUNTER,
  USER_DEFAULT_ADDRESS,
  USER_CART,
  USER_NEGOTIATE_THREAD,
  NEGOTIATION_DETAILS,
  ASK_QUESTION_MODAL,
  QUESTION_LIST,
  IS_LIKES_MODAL_OPEN,
  LIKES_LIST,
  IS_WISHLIST_PRODUCT_VIEW_MODAL_OPEN,
  WISHLIST_PRODUCT_VIEW,
  WISHLIST_LIST,
  IS_COMMENT_MODAL_OPEN,
  COMMENT_LIST,
  MENTION_USER_LIST,
  STORE_MESSAGE_OPEN,
  MESSAGE_THREAD,
  MESSAGE_LIST,
  IS_QUESION_LIST_PRODUCT_VIEW_MODAL_OPEN,
  QUESION_LIST_PRODUCT_VIEW,
  IS_BID_PLACED_MODAL_OPEN,
  PUBLIC_BIDS,
  ALL_REVIEWS,
  IS_REVIEW_LIST_PRODUCT_VIEW_MODAL_OPEN,
  REPORT_ABUSE_MODAL,
  IS_BID_WATCHING_MODAL_OPEN,
  PRODUCT_WATCHERS,
  IS_PRODUCT_VIEW_MODAL_OPEN,
  IS_WISHLIST_CREATE_NEW,
  IS_PRODUCT_SHARE_MODAL_OPEN,
  OPEN_MEDIA_LIGHTBOX,
  PUBLIC_WISHLIST,
  HIGHLIGHT_COMMENT,
  STORED_CART,
} from "../actions/index";

export function bidPlaceModal(state = {}, action) {
  switch (action.type) {
    case BID_MODAL:
      return action.payload;
    default:
      return state;
  }
}

export function bidSuggestion(state = {}, action) {
  switch (action.type) {
    case BIDDING_PRICE:
      return action.payload;
    default:
      return state;
  }
}

export function productCounter(state = {}, action) {
  switch (action.type) {
    case PRODUCT_COUNTER:
      return action.payload;
    default:
      return state;
  }
}

export function userDefaultAddress(state = {}, action) {
  switch (action.type) {
    case USER_DEFAULT_ADDRESS:
      return action.payload;
    default:
      return state;
  }
}

export function getUserCart(state = {}, action) {
  switch (action.type) {
    case USER_CART:
      return action.payload;
    default:
      return state;
  }
}

export function negotiateThread(state = {}, action) {
  switch (action.type) {
    case USER_NEGOTIATE_THREAD:
      return action.payload;
    default:
      return state;
  }
}

export function negotiationDetails(state = {}, action) {
  switch (action.type) {
    case NEGOTIATION_DETAILS:
      return action.payload;
    default:
      return state;
  }
}

export function openAskQuestion(state = {}, action) {
  switch (action.type) {
    case ASK_QUESTION_MODAL:
      return action.payload;
    default:
      return state;
  }
}

export function questionList(state = {}, action) {
  switch (action.type) {
    case QUESTION_LIST:
      return action.payload;
    default:
      return state;
  }
}

export function likesSliderOpen(state = false, action) {
  switch (action.type) {
    case IS_LIKES_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
}

export function likesList(state = {}, action) {
  switch (action.type) {
    case LIKES_LIST:
      return action.payload;
    default:
      return state;
  }
}

export function isWishlistOpen(state = false, action) {
  switch (action.type) {
    case IS_WISHLIST_PRODUCT_VIEW_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
}

export function isWishlistCreateNewOpen(state = false, action) {
  switch (action.type) {
    case IS_WISHLIST_CREATE_NEW:
      return action.payload;
    default:
      return state;
  }
}

export function wishlistProductId(state = "", action) {
  switch (action.type) {
    case WISHLIST_PRODUCT_VIEW:
      return action.payload;
    default:
      return state;
  }
}

export function wishListed(state = [], action) {
  switch (action.type) {
    case WISHLIST_LIST:
      return action.payload;
    default:
      return state;
  }
}

export function isCommentOpen(state = false, action) {
  switch (action.type) {
    case IS_COMMENT_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
}

export function commentList(state = [], action) {
  switch (action.type) {
    case COMMENT_LIST:
      return action.payload;
    default:
      return state;
  }
}

export function mentionUserList(state = [], action) {
  switch (action.type) {
    case MENTION_USER_LIST:
      return action.payload;
    default:
      return state;
  }
}

export function storeMessageOpen(state = false, action) {
  switch (action.type) {
    case STORE_MESSAGE_OPEN:
      return action.payload;
    default:
      return state;
  }
}

export function storeThreadList(state = [], action) {
  switch (action.type) {
    case MESSAGE_THREAD:
      return action.payload;
    default:
      return state;
  }
}

export function storeMessageList(state = [], action) {
  switch (action.type) {
    case MESSAGE_LIST:
      return action.payload;
    default:
      return state;
  }
}

export function isQuestionListOpen(state = false, action) {
  switch (action.type) {
    case IS_QUESION_LIST_PRODUCT_VIEW_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
}

export function questionListProductId(state = "", action) {
  switch (action.type) {
    case QUESION_LIST_PRODUCT_VIEW:
      return action.payload;
    default:
      return state;
  }
}

export function publicBidModal(state = false, action) {
  switch (action.type) {
    case IS_BID_PLACED_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
}

export function publicBids(state = [], action) {
  switch (action.type) {
    case PUBLIC_BIDS:
      return action.payload;
    default:
      return state;
  }
}

export function reviewModal(state = false, action) {
  switch (action.type) {
    case IS_REVIEW_LIST_PRODUCT_VIEW_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
}

export function allReviews(state = [], action) {
  switch (action.type) {
    case ALL_REVIEWS:
      return action.payload;
    default:
      return state;
  }
}

export function reportAbuseModal(state = {}, action) {
  switch (action.type) {
    case REPORT_ABUSE_MODAL:
      return action.payload;
    default:
      return state;
  }
}

export function productWatchingModal(state = false, action) {
  switch (action.type) {
    case IS_BID_WATCHING_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
}

export function productWatchers(state = [], action) {
  switch (action.type) {
    case PRODUCT_WATCHERS:
      return action.payload;
    default:
      return state;
  }
}

export function productViewAll(state = false, action) {
  switch (action.type) {
    case IS_PRODUCT_VIEW_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
}

export function productShareModal(state = false, action) {
  switch (action.type) {
    case IS_PRODUCT_SHARE_MODAL_OPEN:
      return action.payload;
    default:
      return state;
  }
}

export function mediaLightBox(state = {}, action) {
  switch (action.type) {
    case OPEN_MEDIA_LIGHTBOX:
      return action.payload;
    default:
      return state;
  }
}

export function publicWishlist(state = [], action) {
  switch (action.type) {
    case PUBLIC_WISHLIST:
      return action.payload;
    default:
      return state;
  }
}

export function highlightComment(state = {}, action) {
  switch (action.type) {
    case HIGHLIGHT_COMMENT:
      return action.payload;
    default:
      return state;
  }
}

export function getStoredCart(state = {}, action) {
  switch (action.type) {
    case STORED_CART:
      return action.payload;
    default:
      return state;
  }
}
