import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
} from "../../../components/Common/network-call";

import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  MAIN_URL,
  DEVELOPMENT_TYPE,
} from "../../../components/Common/constant";

import _ from "lodash";

import LocaleStrings from "../../../languages/index";

export const CONSUMER_SETTINGS_MASTER = "CONSUMER_SETTINGS_MASTER";

/**** Fetch data from  "settings_master" table ****/
export function fetchSettingsMaster(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/settings_master`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: CONSUMER_SETTINGS_MASTER,
          payload: { data: response.resource[0] },
        });

        callback({ status: 1 });
      },
      (error) => {
        callback({ status: 0 });
      }
    );
  };
}
