import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, useHistory } from "react-router-dom";
import { FILES_URL, APP_API_KEY, classNames } from "./Common/constant";
import NavLeft from "./nav-left";
import NavRight from "./nav-right";
import Search from "./search-new";
import Sidebar from "./Common/Sidebar";
import NotificationSider from "../pages/Notification/components/notification";
import MessageSider from "../pages/Notification/components/message";
import LogoEn from "../assets/images/logo_en.png";
import LogoAr from "../assets/images/logo_ar.png";
import Menu from "../assets/images/menu.svg";
import CartIcon from "../assets/images/cart.svg";
import NotificationIcon from "../assets/images/notification.svg";
import MessageIcon from "../assets/images/message.svg";
import LocaleStrings from "../languages";
import { isSideMenuOpen } from "../actions";
import {
  isNotificationModalOpen,
  showNotificatonCount,
  isSendMessageModalOpen,
} from "../pages/Notification/actions";
import { fetchCart } from "../pages/Product/actions";
import { profileChange } from "../pages/Activity/actions";
import { isVerficationModalOpen } from "../actions";
import ConsumerVerification from "../pages/Blocker/vendorverification";

function Header(props) {
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname;
  const activityArr = [
    "/activity",
    "/recent-viewed",
    "/my-follower",
    "/watchlist",
    "/wishlist",
    "/myorders",
    "/mybids",
    "/negotiation",
  ];

  const isActivity = activityArr.includes(pathname) ? true : false;
  const {
    deviceSize,
    isLoggedIn,
    session,
    appcounters,
    isVerficationOpen,
    consumer,
    localLanguage,
  } = props;
  const [timeInterval, setTimeInterval] = useState(0);
  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 15000);

  useEffect(() => {
    if (isLoggedIn) {
      props.showNotificatonCount(session);
    }
  }, [timeInterval]);

  useEffect(() => {
    if (isLoggedIn) {
      if (consumer.phoneverified === false) {
        props.isVerficationModalOpen(true);
      }
    }
  }, [isLoggedIn]);

  const redirectActivity = (isActivity) => {
    if (isLoggedIn) {
      let page = isActivity ? "/" : "/activity";
      if (!isActivity) {
        props.profileChange(true);
      }

      history.push(page);
    } else {
      history.push("/login");
    }
  };
  const gotoCart = () => {
    if (isLoggedIn) {
      history.push("/cart");
    } else {
      history.push("/login");
    }
  };
  const gotoNotification = () => {
    if (isLoggedIn) {
      props.isNotificationModalOpen(true);
    } else {
      history.push("/login");
    }
  };

  const gotoMessage = () => {
    if (isLoggedIn) {
      props.isSendMessageModalOpen(true);
    } else {
      history.push("/login");
    }
  };

  //console.log("consumer", consumer);
  return (
    <>
      {!deviceSize.isMobile ? (
        <div className="">
          <div className="bg-topnav h-52 flex items-center">
            <div className="custom_container w-full">
              <div className="grid grid-cols-2 gap-2">
                <NavLeft />
                <NavRight />
              </div>
            </div>
          </div>

          <div
            className="bg-white h-89 flex items-center border-b border-header"
            // style={{
            //   boxShadow: "2px 1px 8px #00000030",
            // }}
          >
            <div className="w-full">
              <div className="custom_container">
                <div className="grid grid-cols-4 gap-5 ">
                  <div className="text-left flex items-center">
                    <div
                      className="md:flex-shrink-0 pr-lg cursor-pointer"
                      onClick={() => props.isSideMenuOpen(true)}
                    >
                      <img src={Menu} className="w-8 object-cover" />
                    </div>
                    <div className="">
                      <img
                        src={localLanguage == "ar" ? LogoAr : LogoEn}
                        className="cursor-pointer"
                        onClick={() => history.push("/")}
                      />
                    </div>
                  </div>
                  <div className="col-span-2  flex justify-center items-center pl-5 lg:pl-24 xl:pl-16 2xl:pl-0">
                    <Search />
                  </div>
                  <div className="grid grid-cols-4 gap-2">
                    <div className="flex items-center justify-center">
                      <a
                        className="text-primary text-base font-semibold cursor-pointer hover:underline"
                        onClick={() => redirectActivity(isActivity)}
                      >
                        {isActivity
                          ? LocaleStrings.shop
                          : LocaleStrings.activity}
                      </a>
                    </div>

                    {isLoggedIn ? (
                      <>
                        <div className="relative flex items-center justify-center">
                          <div
                            className=" flex items-center justify-center cursor-pointer border border-header rounded-full w-8 lg:w-12 h-8 lg:h-12"
                            onClick={() => gotoMessage()}
                          >
                            <img
                              src={MessageIcon}
                              className="w-4 lg:w-5 h-4 lg:h-5 icon-hover-transition"
                            />
                          </div>
                          <div
                            className={classNames(
                              appcounters?.data?.message_count > 0
                                ? ""
                                : "hidden",
                              "top-0 right-1 text-xs font-normal leading-none text-red-100 transform  bg-red-600 absolute w-5 h-5 rounded-full flex justify-center items-center"
                            )}
                            aria-hidden="true"
                          >
                            {appcounters?.data?.message_count}
                          </div>
                        </div>
                        <div className="relative flex items-center justify-center">
                          <div
                            className=" flex items-center justify-center cursor-pointer border border-header rounded-full w-8 lg:w-12 h-8 lg:h-12"
                            onClick={() => gotoNotification()}
                          >
                            <img
                              src={NotificationIcon}
                              className="w-4 lg:w-5 h-4 lg:h-5 icon-hover-transition"
                            />
                          </div>
                          <div
                            className={classNames(
                              appcounters?.data?.notifications_count > 0
                                ? ""
                                : "hidden",
                              "top-0 right-1 text-xs font-normal leading-none text-red-100 transform  bg-red-600 absolute w-5 h-5 rounded-full flex justify-center items-center"
                            )}
                            aria-hidden="true"
                          >
                            {appcounters?.data?.notifications_count}
                          </div>
                        </div>
                        <div className="relative flex items-center justify-center">
                          <div
                            className=" flex items-center justify-center cursor-pointer  border border-header rounded-full w-8 lg:w-12 h-8 lg:h-12"
                            onClick={() => gotoCart()}
                          >
                            <img
                              src={CartIcon}
                              className="w-4 lg:w-5 h-4 lg:h-5"
                            />
                          </div>
                          {isLoggedIn && appcounters?.data?.cart_count > 0 ? (
                            <div className="absolute top-0 right-1 text-xs font-normal leading-none text-red-100 transform  bg-red-600 w-5 h-5 rounded-full flex justify-center items-center">
                              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600 opacity-75"></span>
                              {appcounters?.data?.cart_count}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <div
            className="grid grid-cols-1 bg-white pt-2"
            // style={{
            //   boxShadow: "2px 1px 8px #00000030",
            // }}
          >
            <div className="grid grid-cols-2 gap-2">
              <div className="pl-5 lg:pl-89 xl:pl-139 text-left flex items-center">
                <div
                  className="md:flex-shrink-0 pr-5 cursor-pointer"
                  onClick={() => props.isSideMenuOpen(true)}
                >
                  <img src={Menu} className="w-8 object-cover" />
                </div>
                <div className="md:flex-shrink-0">
                  <img
                    src={localLanguage == "ar" ? LogoAr : LogoEn}
                    className="cursor-pointer"
                    onClick={() => history.push("/")}
                  />
                </div>
              </div>
              <div className="grid grid-cols-4 gap-2">
                <div className="flex items-center justify-center pr-4">
                  <a
                    className="text-primary text-sm font-semibold cursor-pointer hover:underline"
                    onClick={() => redirectActivity(isActivity)}
                  >
                    {isActivity ? LocaleStrings.shop : LocaleStrings.activity}
                  </a>
                </div>
                {isLoggedIn ? (
                  <>
                    <div className="flex items-center justify-center">
                      <div
                        className="relative flex items-center justify-center cursor-pointer border border-header rounded-full w-8 h-8"
                        onClick={() => gotoMessage()}
                      >
                        <img
                          src={MessageIcon}
                          className="w-4 h-4 icon-hover-transition"
                        />
                        <div
                          className={classNames(
                            appcounters?.data?.message_count > 0
                              ? ""
                              : "hidden",
                            "top-3 right-1 text-xs font-normal leading-none text-red-100 transform  bg-red-600 absolute w-4 h-4 rounded-full flex justify-center items-center"
                          )}
                          aria-hidden="true"
                        >
                          {appcounters?.data?.message_count}
                        </div>
                      </div>
                    </div>
                    <div className="relative flex items-center justify-center">
                      <div
                        className=" cursor-pointer flex items-center justify-center border border-header rounded-full w-8 h-8"
                        onClick={() => gotoNotification()}
                      >
                        <img src={NotificationIcon} className="w-4 h-4" />
                      </div>
                      <div
                        className={classNames(
                          appcounters?.data?.notifications_count > 0
                            ? ""
                            : "hidden",
                          "top-1 right-1 text-xs font-normal leading-none text-red-100 transform  bg-red-600 absolute w-4 h-4 rounded-full flex justify-center items-center"
                        )}
                        aria-hidden="true"
                      >
                        {appcounters?.data?.notifications_count}
                      </div>
                    </div>
                    <div className="relative flex items-center justify-center">
                      <div
                        className=" cursor-pointer flex items-center justify-center border border-header rounded-full w-8 h-8"
                        onClick={() => gotoCart()}
                      >
                        <img src={CartIcon} className="w-4 h-4" />
                      </div>
                      {isLoggedIn && appcounters?.data?.cart_count > 0 ? (
                        <div className="absolute top-1 right-1 text-xs font-normal leading-none text-red-100 transform  bg-red-600 w-4 h-4 rounded-full flex justify-center items-center">
                          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600 opacity-75"></span>
                          {appcounters?.data?.cart_count}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 gap-1 mt-3.5 mb-3.5">
              <div className="px-4 text-left">
                <Search />
              </div>
            </div>
          </div>
        </div>
      )}

      <Sidebar />
      <NotificationSider />
      <MessageSider />
      {isVerficationOpen ? <ConsumerVerification /> : ""}
    </>
  );
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  deviceSize: state.deviceSize,
  localLanguage: state.localLanguage,
  appcounters: state.appcounters,
  isVerficationOpen: state.isVerficationOpen,
  consumer: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isSideMenuOpen,
      isNotificationModalOpen,
      showNotificatonCount,
      fetchCart,
      isVerficationModalOpen,
      isSendMessageModalOpen,
      profileChange,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
