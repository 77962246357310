import { SUBCATEGORY_LIST } from "../actions";

export function subCategoryList(state = [], action) {
  switch (action.type) {
    case SUBCATEGORY_LIST:
      return action.payload;
    default:
      return state;
  }
}
