import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
  SMALL_DATE_FORMAT,
} from "../../../components/Common/constant";
import PaginationIcon from "../../../assets/images/pagination.svg";
import YellowStar from "../../../assets/images/big-yellow-star.svg";
import GreyStar from "../../../assets/images/gray-star.svg";
import NoImg from "../../../assets/images/noimg.png";
import SearchIcon from "../../../assets/images/search.svg";
import {
  isReviewlistModalOpen,
  fetchAllReviews,
  openReportModal,
} from "../actions";
import { isRateProductModalOpen } from "../../Myorders/actions";
import ReviewsList from "./reviews-list";
import { confirmAlert } from "react-confirm-alert";
import { Transition, Dialog, Menu } from "@headlessui/react";
import { XIcon, DotsHorizontalIcon } from "@heroicons/react/outline";
import MediaLightbox from "./lightbox";
import { openLightboxImage } from "../actions";
import StarRatings from "react-star-ratings";
import PostRating from "../../Myorders/components/post-rating";

function Reviews(props) {
  const [sortBy, setSortBy] = useState("newest");
  const [pagenumber, setPagenumber] = useState(1);
  const [search, setSearch] = useState(null);

  const {
    selectedProduct,
    productid,
    isLoggedIn,
    session,
    allReviews,
    setReviewData,
    reviewData,
    rateProduct,
  } = props;
  let avgRating =
    selectedProduct &&
    selectedProduct.ratings &&
    selectedProduct.ratings.avgrating
      ? selectedProduct.ratings.avgrating
      : 0;
  let remRating = 5 - avgRating;

  const ProgressBar = (rating, total) => {
    let progressPercentage = (rating / total) * 100;

    return (
      <div className=" h-1.5 w-full bg-progress_gray rounded-md">
        {progressPercentage > 0 ? (
          <div
            // className={`h-full rounded-md ${
            //   progressPercentage > 30
            //     ? "bg-progress_green"
            //     : progressPercentage > 20 && progressPercentage < 30
            //     ? "bg-progress_red"
            //     : "bg-progress_orange"
            // }`}
            className="h-full rounded-md bg-progress_green"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const reportReview = (reviewid) => {
    if (isLoggedIn) {
      confirmAlert({
        title: LocaleStrings.report_confirm_review_title,
        message: LocaleStrings.report_confirm_review_message,
        buttons: [
          {
            label: LocaleStrings.button_yes,
            onClick: () => {
              props.openReportModal(true, reviewid, "review");
            },
          },
          {
            label: LocaleStrings.button_no,
            onClick: () => {},
          },
        ],
      });
    }
  };
  const editReview = (item) => {
    item.show = true;
    props.isRateProductModalOpen(item);
  };

  const ReviewList = (item) => {
    //console.log("item", item);
    return (
      <div className="pb-5 pt-27">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div>
              <img
                src={`${FILES_URL}/users/${item.ratedby.userid}.jpg?api_key=${APP_API_KEY}`}
                className="max-w-none h-12 w-12 rounded-full ring-2 ring-white"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = NoImg;
                }}
              />
            </div>

            <div className="pl-15 align-top">
              <div className="text-footers text-lg font-medium">
                {item.feedback_anonymous === 1
                  ? LocaleStrings.anonymous
                  : item.ratedby && item.ratedby.username
                  ? item.ratedby.username
                  : ""}
              </div>
              <div className="text-search text-sm pt-6">
                {moment(item.createdat).format(SMALL_DATE_FORMAT)}
              </div>
            </div>
          </div>

          <div className="">
            <div className="flex">
              <StarRatings
                rating={Number(item.productrating)}
                starRatedColor="#FF9D2B"
                starEmptyColor="#E8EDF1"
                numberOfStars={5}
                starDimension="18px"
                starSpacing="2px"
              />
              {/* {item.avg_rating && item.avg_rating > 0
                ? _.times(item.avg_rating, (i) => {
                    return (
                      <div className="pr-xs">
                        <img src={YellowStar} className="w-4" />
                      </div>
                    );
                  })
                : ""}

              {item.avg_rating && item.avg_rating > 0 && 5 - item.avg_rating > 0
                ? _.times(5 - item.avg_rating, (i) => {
                    return (
                      <div className="pr-xs">
                        <img src={GreyStar} className="w-4" />
                      </div>
                    );
                  })
                : ""} */}
            </div>
            <div className="text-numbid text-base pt-6 text-right">
              {Number(item.productrating)}
            </div>
          </div>
        </div>

        <div className="w-full flex mt-22">
          <div className="text-numbid text-base font-normal w-11/12">
            {item.feedback}
          </div>
          {item.ratedby.userid != session.userid && item.feedback ? (
            <div className="w-wishdetailbox text-right" key={item.id}>
              <Menu as="div" className="relative inline-block text-left">
                {({ open }) => (
                  <>
                    <div className="">
                      <Menu.Button className="btn-card">
                        <span className="sr-only">Open options</span>
                        <DotsHorizontalIcon
                          className="h-5 w-5 hover:shadow"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer z-50"
                      >
                        <div className="py-1 divide-y z-50">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                onClick={() => reportReview(item.id)}
                                className={classNames(
                                  active ? "bg-quaternary " : " ",
                                  "block px-4 py-2 text-sm text-primary"
                                )}
                              >
                                {LocaleStrings.report_as_abusive}
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          ) : item.ratedby.userid == session.userid ? (
            <div className="w-wishdetailbox text-right" key={item.id}>
              <Menu as="div" className="relative inline-block text-left">
                {({ open }) => (
                  <>
                    <div className="">
                      <Menu.Button className="btn-card">
                        <span className="sr-only">Open options</span>
                        <DotsHorizontalIcon
                          className="h-5 w-5 hover:shadow"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer z-50"
                      >
                        <div className="py-1 divide-y z-50">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                onClick={() => editReview(item)}
                                className={classNames(
                                  active ? "bg-quaternary " : " ",
                                  "block px-4 py-2 text-sm text-primary"
                                )}
                              >
                                {LocaleStrings.button_edit}
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          ) : (
            ""
          )}
        </div>
        {item.media && item.media.length > 0 ? (
          <div className="w-full flex mt-5 space-x-3.5">
            {item.media.map((img, index) => {
              return (
                <div className="rounded flex justify-center items-center p-2 border border-header w-16 h-16">
                  <a
                    className="cursor-pointer"
                    onClick={() => viewImage(item.media, index)}
                  >
                    <img
                      src={`${FILES_URL}/reviews/${img}?api_key=${APP_API_KEY}`}
                      className="object-cover cursor-pointer"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = NoImg;
                      }}
                    />
                  </a>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const reviewListAll = () => {
    props.fetchAllReviews(props.session, productid, (res) => {});
    props.isReviewlistModalOpen(true);
  };

  const fetchData = (validflag, pagenumber) => {
    if (validflag) {
      props.fetchAllReviews(
        session,
        productid,
        sortBy,
        "",
        pagenumber,
        (res) => {
          if (res.data) {
            setReviewData(res.data);
            setPagenumber(pagenumber);
          }
        }
      );
    } else {
      props.fetchAllReviews(
        session,
        productid,
        sortBy,
        "",
        pagenumber + 1,
        (res) => {
          if (res.data) {
            let newArr = _.concat(reviewData, res.data);
            setReviewData(newArr);
            setPagenumber(pagenumber + 1);
          }
        }
      );
    }
  };

  const viewImage = (media, curIndex) => {
    let sliderArr = [];
    media.map((img) => {
      sliderArr.push(`${FILES_URL}/reviews/${img}?api_key=${APP_API_KEY}`);
    });
    props.openLightboxImage(true, sliderArr, curIndex);
  };

  const sorting = (e) => {
    let sortby = e.target.value;
    setSortBy(sortby);
    props.fetchAllReviews(
      props.session,
      productid,
      sortby,
      "",
      pagenumber,
      (res) => {
        if (res.data) {
          setReviewData(res.data);
        }
      }
    );
    //console.log("sortby", sortby);
  };

  const handleSearch = (e) => {
    let keyword = e.target.value;

    if (keyword.length >= 2) {
      setSearch(keyword);
      props.fetchAllReviews(
        props.session,
        productid,
        sortBy,
        keyword,
        pagenumber,
        (res) => {
          if (res.data) {
            setReviewData(res.data);
          }
        }
      );
    } else {
      setSearch(null);
      props.fetchAllReviews(
        props.session,
        productid,
        sortBy,
        "",
        pagenumber,
        (res) => {
          if (res.data) {
            setReviewData(res.data);
          }
        }
      );
    }
  };

  const sortbyArr = [
    { key: "newest", text: LocaleStrings.review_sortby_newest_first },
    {
      key: "avg_rating asc",
      text: LocaleStrings.review_sortby_low_to_high,
    },
    {
      key: "avg_rating desc",
      text: LocaleStrings.review_sortby_high_to_low,
    },
  ];

  //console.log({ allReviews });
  //console.log("reviewData", reviewData);
  return (
    <>
      <div className="py-4 text-left text-numbid font-bold text-base md:text-xl lg:text-22 border-t border-b border-header">
        {LocaleStrings.reviews}
      </div>

      <>
        <div className="text-left py-5 grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-4 lg:gap-12 divide-y md:divide-y-0 divide-header">
          <div>
            <div className="flex items-center">
              {avgRating && avgRating > 0 ? (
                <div>
                  <img src={YellowStar} />
                </div>
              ) : (
                ""
              )}

              {/* {avgRating && avgRating > 0 && remRating > 0
              ? _.times(remRating, (i) => {
                  return (
                    <div>
                      <img src={GreyStar} />
                    </div>
                  );
                })
              : ""} */}

              {avgRating && avgRating > 0 ? (
                <div className="flex items-center pl-18">
                  <div className="text-footers text-46 font-medium">
                    {avgRating}
                  </div>
                  <div className="text-footers text-28 font-normal pl-xs">
                    {LocaleStrings.review_all_text}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              {selectedProduct &&
              selectedProduct.ratings &&
              selectedProduct.ratings.totalratings &&
              selectedProduct.ratings.totalreviews ? (
                <div className="bg-progress_gray rounded-md w-3/4 px-4 py-2 mt-12">
                  <div className="font-medium text-sm md:text-base text-footers">
                    {LocaleStrings.review_recommeded_text}
                  </div>
                  <div className=" pt-1.5 text-sm md:text-base text-numbid">
                    {selectedProduct.ratings.totalratings}{" "}
                    {LocaleStrings.rating} {LocaleStrings.and}{" "}
                    {selectedProduct.ratings.totalreviews}{" "}
                    {LocaleStrings.reviews}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="pt-3 md:pt-0">
            {selectedProduct &&
            selectedProduct.ratings &&
            selectedProduct.ratings.totalratings &&
            selectedProduct.ratings.totalreviews
              ? _.times(5, (i) => {
                  return (
                    <div className="text-left pt-5 grid grid-cols-6 gap-3">
                      <div className=" text-primary text-sm w-8">
                        {(5 - i).toFixed(1)}
                      </div>
                      <div className="text-left col-span-4  flex  pl-3 items-center">
                        <div className="text-left">
                          <img src={YellowStar} className="w-3" />
                        </div>
                        <div className="pl-3 md:pl-21 w-240 md:w-">
                          {selectedProduct.ratings
                            ? ProgressBar(
                                selectedProduct.ratings[`rating${5 - i}`],
                                selectedProduct.ratings.totalratings
                              )
                            : ""}
                        </div>
                      </div>
                      <div className="pl-3 md:pl-5 text-cardfooter text-sm text-right">
                        {selectedProduct.ratings
                          ? selectedProduct.ratings[`rating${5 - i}`]
                          : 0}
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
        <div className="hidden md:block border-t border-header pb-0 md:pb-5">
          &nbsp;
        </div>

        <div className="grid grid-cols-3 md:grid-cols-5 gap-6 md:gap-4">
          <div className="col-span-1 text-footers font-medium text-lg flex items-center">
            {LocaleStrings.all_review_text}
          </div>
          <div className="col-span-2 flex items-center">
            <div className="text-search text-sm">{LocaleStrings.sortby}:</div>
            <div className="pl-18 w-3/4">
              <select
                id="sortby"
                name="sortby"
                className="w-full h-10 bg-review_search border border-header rounded-md text-review_search_text"
                onChange={(e) => sorting(e)}
              >
                {sortbyArr.map((item, tabIdx) => (
                  <option
                    value={item.key}
                    selected={sortBy == item.key ? "selected" : ""}
                  >
                    {item.text}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-span-3 md:col-span-2 flex items-center">
            <div className="relative w-full text-ternary focus-within:text-secondary ">
              <input
                id="search-field"
                className="block bg-review_search border border-header w-full h-10 pl-2 pr-3 py-2 text-review_search_text placeholder-review_search_text focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                placeholder={LocaleStrings.review_search_text}
                type="search"
                name="search"
                //value={getSearchKey.key ? getSearchKey.key : ""}
                onChange={(e) => handleSearch(e)}
                //onKeyDown={(e) => keyPress(e)}
              />
              <div className="absolute inset-y-0 right-4 flex items-center cursor-pointer px-1">
                <img
                  src={SearchIcon}
                  className="w-5"
                  //onClick={() => handleClick()}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="text-left divide-y divide-header">
          {reviewData.length > 0 ? (
            reviewData.map((item, index) => {
              return ReviewList(item);
            })
          ) : (
            <div className="py-2.5">
              <div className="">
                <h1 className="text-base text-secondary truncate">
                  {search
                    ? LocaleStrings.no_review_found
                    : LocaleStrings.no_review_header}
                </h1>
              </div>
            </div>
          )}
        </div>

        {allReviews.totalrows > reviewData.length ? (
          <div className="mt-5 border-t border-header ">
            <div
              className="text-center pt-md pb-md cursor-pointer"
              onClick={() => fetchData(false, pagenumber)}
            >
              <div className="flex items-center justify-center">
                <div className="pr-3">
                  <img src={PaginationIcon} className=" w-3" />
                </div>
                <div className="text-link text-base font-medium">
                  {LocaleStrings.view_all_review_link}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </>

      {props.mediaLightBox && props.mediaLightBox.showModal ? (
        <MediaLightbox />
      ) : (
        ""
      )}
      {rateProduct.show ? (
        <PostRating reviewData={reviewData} setReviewData={setReviewData} />
      ) : (
        ""
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  mediaLightBox: state.mediaLightBox,
  allReviews: state.allReviews,
  rateProduct: state.rateProduct,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isReviewlistModalOpen,
      fetchAllReviews,
      openReportModal,
      openLightboxImage,
      isRateProductModalOpen,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
