import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import Loader from "../../../components/Common/loader";
import {
  FILES_URL,
  APP_API_KEY,
  BIG_DATE_FORMAT,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import { InformationCircleIcon } from "@heroicons/react/outline";
import NoImg from "../../../assets/images/noimg.png";
import SellerTitle from "../../../assets/images/seller-title.svg";
import GoldIcon from "../../../assets/images/goldtick.svg";
import BlueIcon from "../../../assets/images/bluetick.jpeg";
import BlueStar from "../../../assets/images/blue_star.svg";
import YouLogo from "../../../assets/images/you.svg";
import ShareIcon from "../../../assets/images/share.svg";
import SearchLogo from "../../../assets/images/search.svg";
import NoProduct from "../../../assets/images/no-product.svg";
import {
  fetchSellerProfile,
  isSellerShareModalOpen,
  isStoreInfoMapModalOpen,
  fetchSellerSearch,
  emptySellerSearch,
} from "../actions";
import {
  followUnfollow,
  isSendMessageStoreOpen,
  messageInitiate,
  fetchMessageThread,
} from "../../Product/actions";
import { isSendMessageModalOpen } from "../../Notification/actions";
import { fetchSettings } from "../../../actions/index";
import ItemList from "../../Home/components/item-list";
import Storemape from "./storemap";
import ShareModal from "./share-modal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CopyIcon from "../../../assets/images/copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import NoContentSlideOver from "../../../components/Common/no-content-slideover";
import BannedIcon from "../../../assets/images/banned.svg";

function SellerProfile(props) {
  const history = useHistory();
  const sellerid = props.match.params.sellerid;
  const {
    sellerProfile,
    session,
    isLoggedIn,
    localLanguage,
    deviceSize,
    storeInfoMapModal,
    sellerSearch,
    sellerShareModal,
  } = props;
  const [loading, setLoading] = useState(false);
  const [followLoader, setFollowLoader] = useState(false);
  const [search, setSearch] = useState("");

  const productPerItem =
    deviceSize && deviceSize.width && deviceSize.width > 1366
      ? 12
      : deviceSize.width >= 768 && deviceSize.width <= 1366
      ? 8
      : deviceSize.width >= 768 && deviceSize.width <= 1024
      ? 6
      : 4;

  const handleSearch = (e) => {
    let keyword = e.target.value;
    if (keyword.length == 0) {
      setLoading(false);
    }
    setSearch(keyword);
  };
  const keyPress = (e) => {
    if (e.keyCode == 13) {
      if (search.length > 0) {
        handleClick();
      } else {
        setLoading(false);
      }
    }
  };
  const handleClick = () => {
    if (search.length > 0) {
      props.emptySellerSearch();
      setLoading(true);
      props.fetchSellerSearch(props.session, sellerid, search);
    } else {
      setLoading(false);
    }
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    initialSlide: 0,
    className: "center",
    swipeToSlide: false,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          dots: false,
        },
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isLoggedIn) {
      props.fetchSellerProfile(session, sellerid);
    } else {
      history.push("/login");
    }
  }, [sellerid]);

  const viewCategory = (id) => {
    props.fetchSettings(props.session, (res) => {});
    if (id) {
      history.push("/view-category/" + id);
    } else {
      history.push("/view-category");
    }
  };

  const catRender = (categories) => {
    return categories.map(function (cat) {
      return (
        <a
          className="flex-item item cursor-pointer categorybox focus:outline-none"
          onClick={() => viewCategory(cat.id)}
        >
          <div className="flex justify-center items-center">
            <div className="text-center">
              <img
                src={`${FILES_URL}/vendor/productcategories/${cat.image}?api_key=${APP_API_KEY}`}
                className="w-50 lg:w-100 h-50 lg:h-100 rounded-full"
              />
            </div>
          </div>
          <div className="flex items-center justify-center text-primary text-11 md:text-sm font-normal mt-1">
            <div className="text-center">
              {ProductTextLocalized(
                cat.categoryname_en,
                cat.categoryname_ar,
                localLanguage
              )}
            </div>
          </div>
        </a>
      );
    });
  };

  const itemRender = (items) => {
    return _.map(items.slice(0, productPerItem), (post, index) => {
      return <ItemList item={post} />;
    });
  };

  const followUnfollow = () => {
    if (isLoggedIn) {
      setFollowLoader(true);
      props.followUnfollow(session, parseInt(sellerid), (res) => {
        if (res.success == 1) {
          props.fetchSellerProfile(session, sellerid, "", (response) => {
            setFollowLoader(false);
          });
        } else {
          setFollowLoader(false);
        }
      });
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/seller" },
      });
    }
  };

  const sendMessage = () => {
    if (isLoggedIn) {
      props.isSendMessageModalOpen(true);
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/seller" },
      });
    }
  };

  const openStoreInfoMapModal = () => {
    props.isStoreInfoMapModalOpen(true);
  };

  const clearSearch = () => {
    setSearch("");
    setLoading(false);
  };

  const share = () => {
    props.isSellerShareModalOpen(true);
  };

  const copyCode = () => {
    toast.success(LocaleStrings.cart_coupon_copied);
  };
  // console.log("followLoader", followLoader);
  // console.log("sellerProfile", sellerProfile);

  return (
    <>
      {sellerProfile && sellerProfile.data ? (
        !sellerProfile.data.isbanned && sellerProfile.data.storename ? (
          <>
            <div
              className="mt-1 md:mt-5 mb-30 custom_container"
              style={
                deviceSize.isMobile ? { paddingLeft: 10, paddingRight: 10 } : {}
              }
            >
              <div className="w-full">
                <img
                  src={`${FILES_URL}/store/${sellerProfile.data.coverimage}?api_key=${APP_API_KEY}`}
                  className="w-full h-135 md:h-391 object-cover"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = NoImg;
                  }}
                />
              </div>
              <div className="mt-4 md:mt-11 ml-5 md:ml-0 mr-5 md:mr-0 flex-none md:flex">
                <div className="grid grid-cols-3 md:grid-cols-4 gap-1 w-full md:w-3/5">
                  <div
                    className="flex absolute md:relative"
                    style={deviceSize.isMobile ? { top: 218, left: 16 } : {}}
                  >
                    <div className="md:pr-7 flex-shrink-0">
                      <img
                        src={`${FILES_URL}/users/${sellerid}.jpg?api_key=${APP_API_KEY}`}
                        className="rounded-full w-70 md:w-114 h-70 md:h-114 object-cover"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = NoImg;
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div className="flex items-center pb-2 pt-5 md:pt-0">
                      <div className="pr-2.5">
                        {sellerProfile.data.isgold ? (
                          <img src={GoldIcon} className="w-7" />
                        ) : sellerProfile.data.verified ? (
                          <img src={BlueIcon} className="w-7" />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="text-primary text-base">
                        {sellerProfile.data.storename}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-1 pb-2">
                      <div className="flex">
                        <div className="pr-2.5">
                          <img src={BlueStar} className="w-5" />
                        </div>
                        <div className="text-cardfooter text-xs md:text-sm pr-2 md:pr-7">
                          {sellerProfile.data.info.avgrating +
                            " " +
                            LocaleStrings.rating_from +
                            " " +
                            sellerProfile.data.info.totalratings +
                            " " +
                            LocaleStrings.rating}
                        </div>
                      </div>
                      <div className="flex pb-2 md:pb-0">
                        <div className="pr-2.5">
                          <img src={YouLogo} className="w-4" />
                        </div>
                        <div className="text-cardfooter text-xs md:text-sm">
                          {sellerProfile.data.info.totalfollowers +
                            " " +
                            LocaleStrings.follower}
                        </div>
                      </div>
                    </div>
                    <div className="flex pb-8">
                      <div className="pr-2.5 text-primary text-10 md:text-sm">
                        <div>
                          <div className="">
                            {LocaleStrings.item_as_described}
                          </div>
                          <div className="flex items-center">
                            <div className="pr-2.5">
                              <img src={BlueStar} className="w-3" />
                            </div>
                            <div className="text-cardfooter">
                              {sellerProfile.data.info.item_as_described_rating
                                ? parseFloat(
                                    sellerProfile.data.info
                                      .item_as_described_rating
                                  ).toFixed(2)
                                : 0}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-primary text-10 md:text-sm pr-2.5">
                        <div>
                          <div>{LocaleStrings.communication}</div>
                          <div className="flex items-center">
                            <div className="pr-2.5">
                              <img src={BlueStar} className="w-3" />
                            </div>
                            <div className="text-cardfooter">
                              {sellerProfile.data.info.communication_rating
                                ? parseFloat(
                                    sellerProfile.data.info.communication_rating
                                  ).toFixed(2)
                                : 0}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-primary text-10 md:text-sm">
                        <div>
                          <div>{LocaleStrings.shipping_time}</div>
                          <div className="flex items-center">
                            <div className="pr-2.5">
                              <img src={BlueStar} className="w-3" />
                            </div>
                            <div className="text-cardfooter">
                              {sellerProfile.data.info.shipping_time_rating
                                ? parseFloat(
                                    sellerProfile.data.info.shipping_time_rating
                                  ).toFixed(2)
                                : 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center justify-between md:justify-start">
                      <div className="pr-2.5">
                        <button
                          className="h-30 w-100 pl-sm pr-sm bg-btn text-white rounded font-normal text-xs"
                          onClick={() => followUnfollow()}
                        >
                          {followLoader ? (
                            <svg
                              className="animate-spin h-5 w-5 rounded-full mr-3"
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                          ) : sellerProfile.data.following ? (
                            LocaleStrings.unfollow_btn
                          ) : (
                            LocaleStrings.follow_btn
                          )}
                        </button>
                      </div>
                      <div className="pr-2.5">
                        <button
                          className="h-30 w-100 pl-sm pr-sm bg-white border border-variantSel text-vendorLink rounded font-normal text-xs"
                          onClick={() => sendMessage()}
                        >
                          {LocaleStrings.send_message_btn}
                        </button>
                      </div>
                      <div
                        className="flex border border-variant rounded-md p-1.5 cursor-pointer"
                        onClick={() => share()}
                      >
                        <img src={ShareIcon} className="w-5" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-2/5 text-left md:text-right mt-10 md:mt-0">
                  <div className="relative w-full">
                    <p>
                      <input
                        placeholder={LocaleStrings.search_text}
                        className="h-42 w-11/12 rounded-bl rounded-tl pl-sm pr-73 md:pr-20 lg:pr-20 xl:pr-16 2xl:pr-14"
                        style={{
                          boxShadow: "-1px 2px 8px #00000014",
                          border: "1px solid #D8D8D8",
                          borderRight: "unset",
                          backdropFilter: "blur(30px)",
                        }}
                        type="text"
                        name="keyword"
                        value={search}
                        onChange={(e) => handleSearch(e)}
                        onKeyDown={(e) => keyPress(e)}
                      />
                    </p>
                    {search.length > 0 ? (
                      <div
                        className="absolute right-14 md:right-8 lg:right-12 xl:right-8 v top-1/4 cursor-pointer text-vendorLink h-5 w-5 border-2 border-variantSel rounded-full flex items-center justify-center"
                        onClick={() => clearSearch()}
                      >
                        X
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className="absolute right-0 md:-right-1 top-1 cursor-pointer"
                      onClick={() => handleClick()}
                    >
                      <img src={SearchLogo} style={{ width: 38 }} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 md:mt-11 ml-5 md:ml-0 mr-5 md:mr-0">
                <div className="text-left text-primary text-xl font-normal pb-3">
                  {LocaleStrings.topcategories}
                </div>

                <div className="">
                  {sellerProfile &&
                  sellerProfile.data &&
                  sellerProfile.data.topcategories &&
                  sellerProfile.data.topcategories.length > 0 ? (
                    <Slider {...settings}>
                      {catRender(sellerProfile.data.topcategories)}
                    </Slider>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {sellerProfile &&
              sellerProfile.data &&
              sellerProfile.data.coupons &&
              sellerProfile.data.coupons.length > 0 ? (
                <div className="mt-4 md:mt-11 ml-5 md:ml-0 mr-5 md:mr-0">
                  <div className="text-left text-primary text-xl font-normal pb-3">
                    {LocaleStrings.product_coupons}
                  </div>
                  <div className="mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                    {_.map(sellerProfile.data.coupons, (cpn, index) => {
                      return (
                        <div
                          key={index}
                          className="mt-2 rounded bg-coupon p-3 grid grid-cols-3"
                        >
                          <div className="flex items-center justify-between bg-white rounded border border-dashed border-coupon px-2 md:px-3.5 py-1 ">
                            <div className="pr-2 md:pr-1 text-xs md:text-sm font-medium text-coupon">
                              {cpn.code}
                            </div>
                            {!deviceSize.isMobile ? (
                              <div class="group cursor-pointer relative">
                                <InformationCircleIcon
                                  className="h-4 w-4 cursor-pointer"
                                  aria-hidden="true"
                                />
                                <div class="group-hover:opacity-100 transition-opacity p-2 top-1 left-4 -translate-x-1/2 translate-y-full absolute w-72 rounded-md z-50 bg-white  shadow-lg opacity-0">
                                  <div className="">
                                    <span className="text-numbid font-normal text-sm">
                                      Description:&nbsp;
                                    </span>
                                    <span className="text-sold font-normal text-sm">
                                      {cpn.coupon_desc}
                                    </span>
                                  </div>
                                  <div className="">
                                    <span className="text-numbid font-normal text-sm">
                                      Discount:&nbsp;
                                    </span>
                                    <span className="text-sold font-normal text-sm">
                                      {cpn.discount}
                                      {cpn.discounttype == "percentage"
                                        ? "%"
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-span-2 pl-3.5 text-base text-primary">
                            {cpn.title}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            {loading && sellerSearch && sellerSearch.data ? (
              <div className="bg-newarrival">
                <div
                  className="custom_container"
                  style={
                    deviceSize.isMobile
                      ? { paddingLeft: 10, paddingRight: 10 }
                      : {}
                  }
                >
                  <div className="pb-5 pt-5 text-primary text-sm md:text-xl font-normal">
                    {LocaleStrings.top_selling_item}
                  </div>
                  {sellerSearch.data.length > 0 ? (
                    <div className="pb-5">
                      <div className="grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 gap-5">
                        {itemRender(sellerSearch.data)}
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <div className="mt-6 flex items-center justify-center text-base text-primary">
                        {LocaleStrings.search_not_found + " " + search}
                      </div>
                      <div className="mt-2 flex items-center justify-center text-sm text-primary">
                        Try checking your spelling or use more general terms
                      </div>
                      <div className="mt-3 flex items-center justify-center text-base text-primary">
                        NEED HELP?
                      </div>
                      <div className="mt-1 pb-12 flex items-center justify-center">
                        <>
                          Visit the&nbsp;
                          <a
                            className="text-base text-vendorlink cursor-pointer"
                            onClick={() => history.push("/faq")}
                          >
                            FAQ & Support
                          </a>
                          &nbsp;section
                        </>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="bg-newarrival">
                <div
                  className="custom_container"
                  style={
                    deviceSize.isMobile
                      ? { paddingLeft: 10, paddingRight: 10 }
                      : {}
                  }
                ></div>
              </div>
            )}
            {!loading &&
            sellerProfile &&
            sellerProfile.data &&
            sellerProfile.data.topitems &&
            sellerProfile.data.topitems.length > 0 ? (
              <div className="bg-newarrival">
                <div
                  className="custom_container"
                  style={
                    deviceSize.isMobile
                      ? { paddingLeft: 10, paddingRight: 10 }
                      : {}
                  }
                >
                  <div className="grid grid-cols-2">
                    <div className="pb-5 pt-5 text-primary text-xl font-normal">
                      {LocaleStrings.top_selling_item}
                    </div>
                    {sellerProfile.data.topitems.length > productPerItem ? (
                      <div
                        onClick={() =>
                          history.push("/seller-product/" + sellerid)
                        }
                        className="flex justify-end pb-5 pt-5 text-link text-base cursor-pointer hover:text-ads"
                      >
                        {LocaleStrings.show_all}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pb-5">
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6   gap-5">
                      {itemRender(sellerProfile.data.topitems)}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {sellerProfile &&
            sellerProfile.data &&
            sellerProfile.data.mapimage ? (
              <div
                className="mt-5 custom_container"
                style={
                  deviceSize.isMobile
                    ? { paddingLeft: 10, paddingRight: 10 }
                    : {}
                }
              >
                <div className="text-left text-primary text-xl font-normal pb-3">
                  {LocaleStrings.location}
                </div>
                <div className="flex w-full">
                  <div className="flex-shrink-0 w-full">
                    <img
                      src={`${FILES_URL}/store/${sellerProfile.data.mapimage}?api_key=${APP_API_KEY}`}
                      className="w-full h-180 md:h-327 object-cover cursor-pointer"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = NoImg;
                      }}
                      onClick={(e) => openStoreInfoMapModal()}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div
              className="mt-2.5 mb-60 custom_container"
              style={
                deviceSize.isMobile ? { paddingLeft: 10, paddingRight: 10 } : {}
              }
            >
              <div className="text-left text-primary text-sm font-normal pb-1">
                {sellerProfile.data.storename}
              </div>
              <div className="text-left text-primary text-xs font-normal pb-1">
                {LocaleStrings.contact_name}:-&nbsp;
                {sellerProfile.data.contactname}
              </div>
              <div className="text-left text-primary text-xs font-normal pb-1">
                {LocaleStrings.contact_no}-&nbsp;
                <a href={"tel:" + sellerProfile.data.contactnumber}>
                  {sellerProfile.data.contactnumber}
                </a>
              </div>
              {sellerProfile.data.awaystartdate &&
              sellerProfile.data.awayenddate ? (
                <div className="text-left text-primary text-xs font-normal pb-1">
                  {LocaleStrings.away_timing}:-&nbsp;
                  {moment(sellerProfile.data.awaystartdate).format(
                    BIG_DATE_FORMAT
                  )}
                  &nbsp; to &nbsp;
                  {moment(sellerProfile.data.awayenddate).format(
                    BIG_DATE_FORMAT
                  )}
                </div>
              ) : (
                ""
              )}

              <div className="text-left text-feedtype text-xs font-normal pb-2">
                {sellerProfile.data.location}
              </div>
              <div className="text-left text-link text-xs font-normal">
                {LocaleStrings.member_shopez +
                  " " +
                  moment(sellerProfile.data.createdat).format("YYYY")}
              </div>
            </div>
          </>
        ) : (
          <NoContentSlideOver
            imageprop={BannedIcon}
            type="banned"
            headerprop={LocaleStrings.banned_item_header}
            subheaderprop={LocaleStrings.banned_profile_subheader}
          />
        )
      ) : (
        <Loader data={LocaleStrings.loader_message} />
      )}

      {storeInfoMapModal ? (
        <Storemape
          intialData={{
            location: sellerProfile.data.location,
            latitude: sellerProfile.data.latitude,
            longitude: sellerProfile.data.longitude,
          }}
        />
      ) : (
        ""
      )}
      {sellerShareModal && sellerShareModal.showModal ? (
        <ShareModal sellerid={sellerid} />
      ) : (
        ""
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  sellerProfile: state.sellerProfile,
  deviceSize: state.deviceSize,
  storeInfoMapModal: state.storeInfoMapModal,
  sellerSearch: state.sellerSearch,
  sellerShareModal: state.sellerShareModal,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSellerProfile,
      followUnfollow,
      isSendMessageModalOpen,
      messageInitiate,
      fetchMessageThread,
      isStoreInfoMapModalOpen,
      fetchSettings,
      fetchSellerSearch,
      emptySellerSearch,
      isSellerShareModalOpen,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SellerProfile);
