import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages";
import { FILES_URL, APP_API_KEY } from "../../../components/Common/constant";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { openLightboxImage } from "../actions";

function MediaLightbox(props) {
  const { session, openLightboxImage, mediaLightBox } = props;
  const [photoIndex, setPhotoIndex] = useState(
    mediaLightBox && mediaLightBox.currentindex ? mediaLightBox.currentindex : 0
  );

  return (
    <>
      <Lightbox
        mainSrc={
          mediaLightBox && mediaLightBox.photo
            ? mediaLightBox.photo[photoIndex]
            : ""
        }
        nextSrc={
          mediaLightBox.photo[(photoIndex + 1) % mediaLightBox.photo.length]
        }
        prevSrc={
          mediaLightBox.photo[
            (photoIndex + mediaLightBox.photo.length - 1) %
              mediaLightBox.photo.length
          ]
        }
        onCloseRequest={() => openLightboxImage(false)}
        onMovePrevRequest={() =>
          setPhotoIndex(
            (photoIndex + mediaLightBox.photo.length - 1) %
              mediaLightBox.photo.length
          )
        }
        onMoveNextRequest={() =>
          setPhotoIndex((photoIndex + 1) % mediaLightBox.photo.length)
        }
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
    mediaLightBox: state.mediaLightBox,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ openLightboxImage }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MediaLightbox);
