import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  FocusScope,
  OverlayContainer,
  useOverlay,
  useModal,
  useDialog,
} from "react-aria";
import { motion, AnimatePresence } from "framer-motion";
import LocaleStrings from "../../../languages";
import _ from "lodash";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import { XIcon } from "@heroicons/react/outline";
import { openSliderPop } from "../../../actions";
import {
  negotiationDetailsAction,
  sendNegotiationPrice,
  negotiationPurchase,
  fetchCart,
  updateNegotationQuantity,
} from "../actions";
import SendbtnLogo from "../../../assets/images/send_btn.svg";
import ItemList from "./negotiation-list";
import toast, { Toaster } from "react-hot-toast";

function SlideOverContent(props) {
  const {
    session,
    children,
    title,
    handleClose,
    negotiationDetails,
    negotiateThread,
    LocaleStrings,
    handleSubmit,
    purchaseSubmit,
    cancelOffer,
    setPrice,
    price,
    loading,
    ploading,
    cloading,
  } = props;

  const ref = useRef();
  const { overlayProps } = useOverlay(props, ref);
  const { modalProps } = useModal(props, ref);
  const { dialogProps, titleProps } = useDialog(props, ref);
  //console.log("negotiationDetails main", negotiationDetails);

  const handlePrice = (e) => {
    let price = e.target.value;
    //console.log("price", price);
    //console.log("isNaN", isNaN(price));
    if (!isNaN(price) && price > 0) {
      setPrice(price);
    }
  };
  const sendPrice = () => {
    if (
      !isNaN(price) &&
      price > 0 &&
      negotiateThread &&
      negotiateThread.threadid
    ) {
      let values = {};
      values.price = parseFloat(price);
      values.threadid = negotiateThread.threadid;
      handleSubmit(values);
      //console.log("values", values);
    }
  };

  const proceedToPurchase = () => {
    let values = {};
    values.threadid = negotiateThread.threadid;
    purchaseSubmit(values);
  };

  const makeCancel = () => {
    let values = {};
    values.type = "status";
    values.status = "rejected";
    values.threadid = negotiateThread.threadid;
    cancelOffer(values);
  };

  console.log("negotiationDetails", negotiationDetails);
  return (
    <div className="fixed inset-0 overflow-hidden z-50">
      <div className="absolute inset-0 overflow-hidden">
        <motion.div
          className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        ></motion.div>
        <motion.section
          className="absolute inset-y-0 right-0 pl-10 max-w-full flex"
          aria-labelledby="slide-over-heading"
          ref={ref}
          {...overlayProps}
          {...dialogProps}
          {...modalProps}
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%" }}
          transition={{ ease: "easeInOut", duration: 0.5 }}
        >
          <FocusScope contain restoreFocus autoFocus>
            <div className="relative w-screen max-w-md">
              <div className="h-full flex flex-col bg-white shadow-xl">
                <div className="min-h-0 flex-1 flex flex-col pt-3 overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <div>
                        <h2
                          id="slide-over-heading"
                          className="text-xl font-normal text-primary"
                          {...titleProps}
                        >
                          {title}
                        </h2>
                      </div>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          onClick={handleClose}
                          className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="relative flex-1 mt-3 bg-newarrival">
                    {children}
                  </div>
                </div>
                <div className="flex-shrink-0 px-4 py-3.5 flex relative">
                  {negotiationDetails &&
                  negotiationDetails.thread &&
                  negotiationDetails.thread.status == "negotiated" ? (
                    <button
                      className="text-white h-44 bg-btn text-sm font-normal rounded w-full inline-flex items-center justify-center"
                      onClick={() => proceedToPurchase()}
                    >
                      {ploading ? (
                        <svg
                          className="animate-spin h-5 w-5 rounded-full mr-3"
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      ) : (
                        LocaleStrings.negotiation_proceed_purchase_btn
                      )}
                    </button>
                  ) : negotiationDetails &&
                    negotiationDetails.thread &&
                    parseInt(negotiationDetails.thread.attempts) > 0 &&
                    negotiationDetails.thread.status == "responded" ? (
                    <>
                      <input
                        name="price"
                        type="number"
                        onChange={(e) => handlePrice(e)}
                        value={price}
                        placeholder={
                          LocaleStrings.negotiation_enter_price_text +
                          "(" +
                          negotiationDetails.thread.attempts +
                          "/5" +
                          LocaleStrings.negotiation_attempt_left_text +
                          ")"
                        }
                        className="bg-newarrival w-full rounded-full h-44 text-loginLabel text-11 md:text-15 pl-5 focus:outline-none"
                      />
                      <div
                        className="absolute right-7 top-6 cursor-pointer"
                        onClick={() => sendPrice()}
                      >
                        {loading ? (
                          <svg
                            className="animate-spin h-5 w-5 rounded-full mr-3"
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                        ) : (
                          <img src={SendbtnLogo} />
                        )}
                      </div>
                    </>
                  ) : negotiationDetails &&
                    negotiationDetails.thread &&
                    parseInt(negotiationDetails.thread.attempts) == 5 &&
                    negotiationDetails.thread.status == "initiated" ? (
                    <>
                      <input
                        name="price"
                        type="number"
                        onChange={(e) => handlePrice(e)}
                        value={price}
                        placeholder={
                          LocaleStrings.negotiation_enter_price_text +
                          "(" +
                          negotiationDetails.thread.attempts +
                          "/5" +
                          LocaleStrings.negotiation_attempt_left_text +
                          ")"
                        }
                        className="bg-newarrival w-full rounded-full h-44 text-loginLabel text-11 md:text-15 pl-5 focus:outline-none"
                      />
                      <div
                        className="absolute right-7 top-6 cursor-pointer"
                        onClick={() => sendPrice()}
                      >
                        {loading ? (
                          <svg
                            className="animate-spin h-5 w-5 rounded-full mr-3"
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                        ) : (
                          <img src={SendbtnLogo} />
                        )}
                      </div>
                    </>
                  ) : negotiationDetails &&
                    negotiationDetails.thread &&
                    parseInt(negotiationDetails.thread.attempts) > 0 &&
                    negotiationDetails.thread.status == "requested" ? (
                    <button
                      className="text-white h-44 bg-btn text-sm font-normal rounded w-full inline-flex items-center justify-center"
                      onClick={() => makeCancel()}
                    >
                      {cloading ? (
                        <svg
                          className="animate-spin h-5 w-5 rounded-full mr-3"
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      ) : (
                        LocaleStrings.negotiation_cancel
                      )}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </FocusScope>
        </motion.section>
      </div>
    </div>
  );
}

function Negotiate(props) {
  const {
    session,
    isLoggedIn,
    localLanguage,
    productid,
    variantId,
    allVariants,
    productVariants,
    renderProductVariant,
    selectedVariant,
    selectedProduct,
    negotiationDetails,
    negotiateThread,
    title,
    openSliderPop,
    isSliderPop,
  } = props;
  const [loading, setLoading] = useState(false);
  const [ploading, setPloading] = useState(false);
  const [cloading, setCloading] = useState(false);
  const [incLoader, setIncLoader] = useState(false);
  const [price, setPrice] = useState("");
  const [isVariant, setIsVariant] = useState(true);
  const [quantity, setQuantity] = useState();
  const [timeInterval, setTimeInterval] = useState(0);

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 2000);

  useEffect(() => {
    if (negotiateThread && negotiateThread.threadid) {
      props.negotiationDetailsAction(session, negotiateThread.threadid);
    }
    if (isVariant) {
      if (
        negotiationDetails &&
        negotiationDetails.thread &&
        negotiationDetails.thread.quantity
      ) {
        setQuantity(negotiationDetails.thread.quantity);
        setIsVariant(false);
      }
    }
  }, [timeInterval, isVariant]);

  //console.log("negotiateThread", negotiateThread);
  const handleClose = () => {
    openSliderPop(false);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    props.sendNegotiationPrice(session, values, (res) => {
      setPrice("");
      setLoading(false);
      //console.log("res", res);
      if (res.success == 1) {
        props.negotiationDetailsAction(session, negotiateThread.threadid);
      } else {
      }
    });
  };

  const purchaseSubmit = (values) => {
    setPloading(true);
    props.negotiationPurchase(session, values, (res) => {
      setPloading(false);
      props.openSliderPop(false);
      window.scrollTo(0, 0);
      if (res.success == 0) {
        toast.error(LocaleStrings.common_fail_message);
      } else {
        props.fetchCart(session);
        toast.success(LocaleStrings.add_to_cart_success);
      }
    });
  };

  const cancelOffer = (values) => {
    setCloading(true);
    props.updateNegotationQuantity(session, values, (res) => {
      setCloading(false);
      if (res.success == 1) {
        props.negotiationDetailsAction(session, negotiateThread.threadid);
      } else {
      }
    });
  };

  var discountpercent = 0.0;
  var strProductDiscount = "";
  let originalprice = 0.0;
  let prodprice = 0.0;
  let units = "";
  if (negotiationDetails && negotiationDetails.thread) {
    originalprice = negotiationDetails.thread.originalprice
      ? negotiationDetails.thread.originalprice
      : 0;
    prodprice = originalprice;
    units = negotiationDetails.thread.units
      ? negotiationDetails.thread.units
      : "";
    if (
      negotiationDetails.thread.discountactive &&
      negotiationDetails.thread.discountactive == true
    ) {
      if (
        negotiationDetails.thread.discount &&
        negotiationDetails.thread.discount.discount_percentage
      ) {
        discountpercent =
          negotiationDetails.thread.discount.discount_percentage;
      }
      // prodprice = parseFloat(originalprice) - parseFloat(discountpercent);
      if (
        negotiationDetails.thread.discount &&
        negotiationDetails.thread.discount.discounttype
      ) {
        let discounttype = negotiationDetails.thread.discount.discounttype;
        if (discounttype == "fixed") {
          strProductDiscount = discountpercent + " OFF";
          prodprice = originalprice - discountpercent;
        } else {
          strProductDiscount = discountpercent + "% OFF";
          prodprice = originalprice - originalprice * (discountpercent / 100);
        }
      } else {
        strProductDiscount = discountpercent + "% OFF";
        prodprice = originalprice - originalprice * (discountpercent / 100);
      }
    } else {
      prodprice = originalprice;
    }
  }

  // console.log("originalprice", originalprice);
  // console.log("prodprice", prodprice);

  const incrementValue = () => {
    var currentVal = quantity;

    if (!isNaN(currentVal)) {
      setIncLoader(true);
      let values = {};
      values.quantity = parseInt(currentVal) + 1;
      values.type = "quantity";
      values.threadid = negotiateThread.threadid;
      props.updateNegotationQuantity(session, values, (res) => {
        setIncLoader(false);
        //console.log("res", res);
        if (res.success == 1) {
          props.negotiationDetailsAction(session, negotiateThread.threadid);
          setQuantity(parseInt(currentVal) + 1);
        } else {
        }
      });
    } else {
      setQuantity(1);
    }
  };
  const decrementValue = () => {
    var currentVal = quantity;

    if (!isNaN(currentVal) && currentVal > 0) {
      if (currentVal == 1) {
        setQuantity(1);
      } else {
        setIncLoader(true);
        let values = {};
        values.quantity = parseInt(currentVal) - 1;
        values.type = "quantity";
        values.threadid = negotiateThread.threadid;
        props.updateNegotationQuantity(session, values, (res) => {
          setIncLoader(false);
          //console.log("res", res);
          if (res.success == 1) {
            props.negotiationDetailsAction(session, negotiateThread.threadid);
            setQuantity(parseInt(currentVal) - 1);
          } else {
          }
        });
      }
    } else {
      setQuantity(1);
    }
  };

  const renderList = () => {
    if (
      negotiationDetails &&
      negotiationDetails.negotiations &&
      negotiationDetails.negotiations.length > 0
    ) {
      return _.map(negotiationDetails.negotiations, (item, index) => {
        return <ItemList item={item} />;
      });
    }
  };
  //console.log("negotiationDetails", negotiationDetails);
  return (
    <OverlayContainer>
      <AnimatePresence>
        {isSliderPop &&
          isSliderPop.showModal &&
          negotiationDetails &&
          negotiationDetails.thread && (
            <SlideOverContent
              title={title}
              handleClose={handleClose}
              isDismissable
              negotiationDetails={negotiationDetails}
              negotiateThread={negotiateThread}
              session={session}
              LocaleStrings={LocaleStrings}
              handleSubmit={handleSubmit}
              purchaseSubmit={purchaseSubmit}
              cancelOffer={cancelOffer}
              setPrice={setPrice}
              price={price}
              loading={loading}
              ploading={ploading}
              cloading={cloading}
            >
              <div className="px-4 sm:px-6 py-3">
                <div
                  className={classNames(
                    negotiationDetails.thread.status == "initiated"
                      ? "rounded "
                      : "rounded-t ",
                    "bg-white p-3 flex"
                  )}
                >
                  <div className="pr-4">
                    <img
                      className="w-100 h-100 object-cover rounded border border-primary"
                      src={`${FILES_URL}/vendor/variations/${negotiationDetails.thread.productimage}?api_key=${APP_API_KEY}`}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="text-primary text-sm md:text-base font-normal">
                      {ProductTextLocalized(
                        negotiationDetails.thread.productname_en,
                        negotiationDetails.thread.productname_ar,
                        localLanguage
                      )}
                    </div>

                    <div className="flex">
                      {originalprice == prodprice ? (
                        <div className="text-left text-sm font-normal text-primary">
                          ${prodprice} {units ? "/" + units : ""}
                        </div>
                      ) : (
                        <>
                          <div className="text-left text-sm font-normal text-primary">
                            ${prodprice} {units ? "/" + units : ""}
                          </div>

                          <div className="pl-18 text-sm text-medium text-cutoff line-through">
                            ${originalprice}
                          </div>
                        </>
                      )}

                      <div className="pl-18 text-sm font-normal text-discount">
                        {strProductDiscount}
                      </div>
                    </div>
                    <div className="pt-3">
                      <div className="text-xs font-light text-ternary">
                        <span className="">* </span>
                        {LocaleStrings.shipping_and_taxes_extra}
                      </div>
                    </div>
                    <div className="pt-3">
                      {negotiationDetails.thread.status == "initiated" ? (
                        <div className="input-group">
                          {incLoader || isVariant ? (
                            <svg
                              className="animate-spin h-5 w-5 rounded-full mr-3"
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                          ) : (
                            <>
                              <input
                                type="button"
                                value="-"
                                className="button-minus rounded-l cursor-pointer"
                                data-field="quantity"
                                onClick={() => decrementValue()}
                              />

                              <input
                                type="number"
                                step="1"
                                max=""
                                min="1"
                                value={quantity}
                                name="quantity"
                                className="variant quantity-field"
                              />

                              <input
                                type="button"
                                value="+"
                                className="button-plus rounded-r cursor-pointer"
                                data-field="quantity"
                                onClick={() => incrementValue()}
                              />
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="flex">
                          <div className="text-left text-cutoff font-normal text-sm">
                            {LocaleStrings.negotiation_quantity_text} : &nbsp;
                          </div>
                          <div className="text-left text-primary font-normal text-sm">
                            {negotiationDetails.thread.quantity}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {negotiationDetails.thread.status == "requested" ? (
                  <>
                    <div className="bg-negotiationPending text-center text-base font-normal text-negotiationPending py-2.5 rounded-b">
                      {LocaleStrings.negotiation_pending_text}
                    </div>
                    <div className="text-cardfooter text-sm pt-5">
                      {LocaleStrings.negotiation_note}
                    </div>
                  </>
                ) : negotiationDetails.thread.status == "negotiated" ? (
                  <div className="bg-negotiationAccept text-center text-base font-normal text-white py-2.5 rounded-b">
                    {LocaleStrings.negotiation_accepted_text}
                  </div>
                ) : (
                  ""
                )}

                <div className="pt-15">{renderList()}</div>
              </div>
            </SlideOverContent>
          )}
      </AnimatePresence>
    </OverlayContainer>
  );
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
  isSliderPop: state.isSliderPop,
  negotiateThread: state.negotiateThread,
  negotiationDetails: state.negotiationDetails,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openSliderPop,
      negotiationDetailsAction,
      sendNegotiationPrice,
      negotiationPurchase,
      fetchCart,
      updateNegotationQuantity,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Negotiate);
