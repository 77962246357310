import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
  currencyCode,
} from "../../../components/Common/product-model";
import Loader from "../../../components/Common/loader";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import {
  isNegotiationOpen,
  negotiationThread,
  fetchNegotiation,
} from "../actions";
import {
  emptyNegotiationDetails,
  negotiationDetailsAction,
  sendNegotiationPrice,
  negotiationPurchase,
  fetchCart,
  updateNegotationQuantity,
} from "../../Product/actions";
import SendbtnLogo from "../../../assets/images/send_btn.svg";
import ItemList from "../../Product/components/negotiation-list";
import toast, { Toaster } from "react-hot-toast";

function Negotiate(props) {
  const {
    session,
    isLoggedIn,
    localLanguage,
    isNegoOpen,
    negotiationDetails,
    negotiationView,
    currentTab,
    localCurrency,
  } = props;
  const [timeInterval, setTimeInterval] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ploading, setPloading] = useState(false);
  const [cloading, setCloading] = useState(false);
  const [incLoader, setIncLoader] = useState(false);
  const [price, setPrice] = useState("");
  const [isVariant, setIsVariant] = useState(true);
  const [quantity, setQuantity] = useState();

  let currency = currencyCode(localCurrency);

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 2000);
  useEffect(() => {
    if (negotiationView && negotiationView.threadid) {
      props.negotiationDetailsAction(session, negotiationView.threadid);
    }
    if (isVariant) {
      if (
        negotiationDetails &&
        negotiationDetails.thread &&
        negotiationDetails.thread.quantity
      ) {
        setQuantity(negotiationDetails.thread.quantity);
        setIsVariant(false);
      }
    }
  }, [timeInterval, isVariant]);

  const closeSlideOvers = () => {
    props.emptyNegotiationDetails();
    props.negotiationThread("");
    props.isNegotiationOpen(false);
  };

  const renderList = () => {
    if (
      negotiationDetails &&
      negotiationDetails.negotiations &&
      negotiationDetails.negotiations.length > 0
    ) {
      return _.map(negotiationDetails.negotiations, (item, index) => {
        return <ItemList item={item} />;
      });
    }
  };

  const proceedToPurchase = () => {
    let values = {};
    values.threadid = negotiationView.threadid;
    setPloading(true);
    props.negotiationPurchase(session, values, (res) => {
      if (res.success == 0) {
        toast.error(LocaleStrings.common_fail_message);
      } else {
        props.fetchCart(session);
        props.fetchNegotiation(session, currentTab);
        toast.success(LocaleStrings.add_to_cart_success);
      }
      setPloading(false);
      closeSlideOvers();
      window.scrollTo(0, 0);
    });
  };

  const handlePrice = (e) => {
    let price = e.target.value;
    setPrice(price);
  };

  const sendPrice = () => {
    if (
      !isNaN(price) &&
      price > 0 &&
      negotiationView &&
      negotiationView.threadid
    ) {
      let values = {};
      values.price = parseFloat(price);
      values.threadid = negotiationView.threadid;
      setLoading(true);
      props.sendNegotiationPrice(session, values, (res) => {
        setPrice("");
        setLoading(false);
        //console.log("res", res);
        if (res.success == 1) {
          props.negotiationDetailsAction(session, negotiationView.threadid);
        } else {
        }
      });
    }
  };

  const makeCancel = () => {
    setCloading(true);
    let values = {};
    values.type = "status";
    values.status = "rejected";
    values.threadid = negotiationView.threadid;
    console.log("values", values);
    props.updateNegotationQuantity(session, values, (res) => {
      setCloading(false);
      if (res.success == 1) {
        props.negotiationDetailsAction(session, negotiationView.threadid);
      } else {
      }
    });
  };

  var discountpercent = 0.0;
  var strProductDiscount = "";
  let originalprice = 0.0;
  let prodprice = 0.0;
  let units = "";
  if (negotiationDetails && negotiationDetails.thread) {
    originalprice = negotiationDetails.thread.originalprice
      ? negotiationDetails.thread.originalprice
      : 0;
    units = negotiationDetails.thread.units
      ? negotiationDetails.thread.units
      : "";

    if (
      negotiationDetails.thread.discountactive &&
      negotiationDetails.thread.discountactive == true
    ) {
      if (
        negotiationDetails.thread.discount &&
        negotiationDetails.thread.discount.discount_percentage
      ) {
        discountpercent =
          negotiationDetails.thread.discount.discount_percentage;
      }

      if (
        negotiationDetails.thread.discount &&
        negotiationDetails.thread.discount.discounttype
      ) {
        let discounttype = negotiationDetails.thread.discount.discounttype;
        if (discounttype == "fixed") {
          //strProductDiscount = CURRENCY_RATE + discountpercent + " OFF";
          strProductDiscount = discountpercent + " OFF";
          prodprice = parseFloat(originalprice) - parseFloat(discountpercent);
        } else {
          strProductDiscount = discountpercent + "% OFF";
          prodprice =
            parseFloat(originalprice) -
            parseFloat((discountpercent / 100) * originalprice);
        }
      } else {
        strProductDiscount = discountpercent + "% OFF";
        prodprice =
          parseFloat(originalprice) -
          parseFloat((discountpercent / 100) * originalprice);
      }
    } else {
      prodprice = originalprice;
    }
  }

  // console.log("originalprice", originalprice);
  // console.log("prodprice", prodprice);

  const incrementValue = () => {
    var currentVal = quantity;

    if (!isNaN(currentVal)) {
      if (
        negotiationDetails &&
        negotiationDetails.thread &&
        negotiationDetails.thread.maxquantity &&
        parseInt(negotiationDetails.thread.maxquantity) >
          parseInt(currentVal) + 1
      ) {
        setIncLoader(true);

        let values = {};
        values.quantity = parseInt(currentVal) + 1;
        values.type = "quantity";
        values.threadid = negotiationView.threadid;
        props.updateNegotationQuantity(session, values, (res) => {
          setIncLoader(false);
          //console.log("res", res);
          if (res.success == 1) {
            props.negotiationDetailsAction(session, negotiationView.threadid);
            setQuantity(parseInt(currentVal) + 1);
          } else {
          }
        });
      }
    } else {
      setQuantity(1);
    }
  };
  const decrementValue = () => {
    var currentVal = quantity;

    if (!isNaN(currentVal) && currentVal > 0) {
      if (currentVal == 1) {
        setQuantity(1);
      } else {
        setIncLoader(true);
        let values = {};
        values.quantity = parseInt(currentVal) - 1;
        values.type = "quantity";
        values.threadid = negotiationView.threadid;
        props.updateNegotationQuantity(session, values, (res) => {
          setIncLoader(false);
          //console.log("res", res);
          if (res.success == 1) {
            props.negotiationDetailsAction(session, negotiationView.threadid);
            setQuantity(parseInt(currentVal) - 1);
          } else {
          }
        });
      }
    } else {
      setQuantity(1);
    }
  };

  console.log("negotiationDetails", negotiationDetails);
  return (
    <>
      <Transition.Root show={isNegoOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          open={isNegoOpen}
          onClose={() => closeSlideOvers()}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                    <div className="min-h-0 flex-1 flex flex-col pt-3 overflow-y-scroll">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="slider-modal-headtext">
                            <h2
                              id="slide-over-heading"
                              className="text-xl font-normal text-primary"
                            >
                              {LocaleStrings.slider_title}
                            </h2>
                          </Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              onClick={() => closeSlideOvers()}
                              className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 relative flex-1 px-4 sm:px-6 bg-newarrival">
                        {negotiationDetails &&
                        negotiationDetails.thread &&
                        negotiationDetails.thread.id ? (
                          <div className="px-4 sm:px-6 py-3">
                            <div
                              className={classNames(
                                negotiationDetails &&
                                  negotiationDetails.thread &&
                                  negotiationDetails.thread.status ==
                                    "initiated"
                                  ? "rounded "
                                  : "rounded-t ",
                                "bg-white p-3 flex"
                              )}
                            >
                              <div className="pr-4">
                                <img
                                  className="w-100 h-100 object-cover rounded border border-primary"
                                  src={`${FILES_URL}/vendor/variations/${negotiationDetails.thread.productimage}?api_key=${APP_API_KEY}`}
                                  alt=""
                                />
                              </div>
                              <div>
                                <div className="text-primary text-sm md:text-base font-normal">
                                  {ProductTextLocalized(
                                    negotiationDetails.thread.productname_en,
                                    negotiationDetails.thread.productname_ar,
                                    localLanguage
                                  )}
                                </div>

                                <div className="flex">
                                  {originalprice == prodprice ? (
                                    <div className="text-left text-sm font-normal text-primary">
                                      {currency}
                                      {prodprice} {units ? "/" + units : ""}
                                    </div>
                                  ) : (
                                    <>
                                      <div className="text-left text-sm font-normal text-primary">
                                        {currency}
                                        {prodprice} {units ? "/" + units : ""}
                                      </div>

                                      <div className="pl-18 text-sm text-medium text-cutoff line-through">
                                        {currency}
                                        {originalprice}
                                      </div>
                                    </>
                                  )}
                                  <div className="pl-18 text-sm font-normal  text-discount">
                                    {strProductDiscount}
                                  </div>
                                </div>
                                <div className="pt-3">
                                  <div className="text-xs font-light text-ternary">
                                    <span className="">* </span>
                                    {LocaleStrings.shipping_and_taxes_extra}
                                  </div>
                                </div>
                                <div className="pt-3">
                                  {negotiationDetails.thread.status ==
                                  "initiated" ? (
                                    <div className="input-group">
                                      {incLoader || isVariant ? (
                                        <svg
                                          className="animate-spin h-5 w-5 rounded-full mr-3"
                                          viewBox="0 0 1024 1024"
                                          focusable="false"
                                          data-icon="loading"
                                          width="1em"
                                          height="1em"
                                          fill="currentColor"
                                          aria-hidden="true"
                                        >
                                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                        </svg>
                                      ) : (
                                        <>
                                          <input
                                            type="button"
                                            value="-"
                                            className="button-minus rounded-l cursor-pointer"
                                            data-field="quantity"
                                            onClick={() => decrementValue()}
                                          />

                                          <input
                                            type="number"
                                            step="1"
                                            max=""
                                            min="1"
                                            value={quantity}
                                            name="quantity"
                                            className="variant quantity-field"
                                          />

                                          <input
                                            type="button"
                                            value="+"
                                            className="button-plus rounded-r cursor-pointer"
                                            data-field="quantity"
                                            onClick={() => incrementValue()}
                                          />
                                        </>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="flex">
                                      <div className="text-left text-cutoff font-normal text-sm">
                                        {
                                          LocaleStrings.negotiation_quantity_text
                                        }{" "}
                                        : &nbsp;
                                      </div>
                                      <div className="text-left text-primary font-normal text-sm">
                                        {negotiationDetails.thread.quantity}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {negotiationDetails.thread.status == "requested" ? (
                              <>
                                <div className="bg-negotiationPending text-center text-base font-normal text-negotiationPending py-2.5 rounded-b">
                                  {LocaleStrings.negotiation_pending_text}
                                </div>
                                <div className="text-cardfooter text-sm pt-5">
                                  {LocaleStrings.negotiation_note}
                                </div>
                              </>
                            ) : negotiationDetails.thread.status ==
                              "negotiated" ? (
                              <div className="bg-negotiationAccept text-center text-base font-normal text-white py-2.5 rounded-b">
                                {LocaleStrings.negotiation_accepted_text}
                              </div>
                            ) : negotiationDetails.thread.status == "closed" ? (
                              <div className="bg-negotiationPending text-center text-base font-normal text-negotiationPending py-2.5 rounded-b">
                                {LocaleStrings.negotiation_closed_text}
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="pt-15">{renderList()}</div>
                          </div>
                        ) : (
                          <div className="mt-8">
                            <Loader />
                          </div>
                        )}
                      </div>
                    </div>

                    {negotiationDetails &&
                    negotiationDetails.thread &&
                    negotiationDetails.thread.status == "negotiated" &&
                    parseInt(negotiationDetails.itemalreadypurchased) == 0 ? (
                      <div className="flex-shrink-0 px-4 py-3.5 flex relative">
                        <button
                          className="text-white h-44 bg-btn text-sm font-normal rounded w-full inline-flex items-center justify-center"
                          onClick={() => proceedToPurchase()}
                        >
                          {ploading ? (
                            <svg
                              className="animate-spin h-5 w-5 rounded-full mr-3"
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                          ) : (
                            LocaleStrings.add_to_cart_btn
                          )}
                        </button>
                      </div>
                    ) : negotiationDetails &&
                      negotiationDetails.thread &&
                      parseInt(negotiationDetails.thread.attempts) > 0 &&
                      negotiationDetails.thread.status == "responded" ? (
                      <div className="flex-shrink-0 px-4 py-3.5 flex relative">
                        <input
                          name="price"
                          type="text"
                          onChange={(e) => handlePrice(e)}
                          value={price}
                          placeholder={
                            LocaleStrings.negotiation_enter_price_text +
                            "(" +
                            negotiationDetails.thread.attempts +
                            "/5" +
                            LocaleStrings.negotiation_attempt_left_text +
                            ")"
                          }
                          className="bg-newarrival w-full rounded-full h-44 text-loginLabel text-11 md:text-15 pl-5 focus:outline-none"
                        />
                        <div
                          className="absolute right-7 top-6 cursor-pointer"
                          onClick={() => sendPrice()}
                        >
                          {loading ? (
                            <svg
                              className="animate-spin h-5 w-5 rounded-full mr-3"
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                          ) : (
                            <img src={SendbtnLogo} />
                          )}
                        </div>
                      </div>
                    ) : negotiationDetails &&
                      negotiationDetails.thread &&
                      parseInt(negotiationDetails.thread.attempts) == 5 &&
                      negotiationDetails.thread.status == "initiated" ? (
                      <div className="flex-shrink-0 px-4 py-3.5 flex relative">
                        <input
                          name="price"
                          type="text"
                          onChange={(e) => handlePrice(e)}
                          value={price}
                          placeholder={
                            LocaleStrings.negotiation_enter_price_text +
                            "(" +
                            negotiationDetails.thread.attempts +
                            "/5" +
                            LocaleStrings.negotiation_attempt_left_text +
                            ")"
                          }
                          className="bg-newarrival w-full rounded-full h-44 text-loginLabel text-11 md:text-15 pl-5 focus:outline-none"
                        />
                        <div
                          className="absolute right-7 top-6 cursor-pointer"
                          onClick={() => sendPrice()}
                        >
                          {loading ? (
                            <svg
                              className="animate-spin h-5 w-5 rounded-full mr-3"
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                          ) : (
                            <img src={SendbtnLogo} />
                          )}
                        </div>
                      </div>
                    ) : negotiationDetails &&
                      negotiationDetails.thread &&
                      parseInt(negotiationDetails.thread.attempts) > 0 &&
                      negotiationDetails.thread.status == "requested" ? (
                      <div className="flex-shrink-0 px-4 py-3.5 flex relative">
                        <button
                          className="text-white h-44 bg-btn text-sm font-normal rounded w-full inline-flex items-center justify-center"
                          onClick={() => makeCancel()}
                        >
                          {cloading ? (
                            <svg
                              className="animate-spin h-5 w-5 rounded-full mr-3"
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                          ) : (
                            LocaleStrings.negotiation_cancel
                          )}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
  isNegoOpen: state.isNegotiationopen,
  negotiationDetails: state.negotiationDetails,
  negotiationView: state.negotiationView,
  localCurrency: state.localCurrency,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isNegotiationOpen,
      negotiationThread,
      emptyNegotiationDetails,
      negotiationDetailsAction,
      sendNegotiationPrice,
      negotiationPurchase,
      fetchCart,
      updateNegotationQuantity,
      fetchNegotiation,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Negotiate);
