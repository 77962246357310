import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
} from "../../../components/Common/constant";
import { SMALL_DATE_FORMAT } from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import { ChevronRightIcon } from "@heroicons/react/outline";
import toast, { Toaster } from "react-hot-toast";
import NoOrder from "../../../assets/images/noorder.svg";
import { emptyOrderDetails, fetchMyorders, orderPlacedMail } from "../actions";

const colors = {
  delivered: {
    bg: "bg-delivered",
    text: "text-delivered",
  },
  intransit: {
    bg: "bg-intransit",
    text: "text-intransit",
  },
  dispatched: {
    bg: "bg-dispatched",
    text: "text-dispatched",
  },
  placed: {
    bg: "bg-placed",
    text: "text-placed",
  },
  cancelled: {
    bg: "bg-cancelled",
    text: "text-cancelled",
  },
  returnreceived: {
    bg: "bg-returnreceived",
    text: "text-returnreceived",
  },
  returnpicked: {
    bg: "bg-returnpicked",
    text: "text-returnpicked",
  },
  initiatepickup: {
    bg: "bg-initiatepickup",
    text: "text-initiatepickup",
  },
  return: {
    bg: "bg-return",
    text: "text-return",
  },
  replace: {
    bg: "bg-replace",
    text: "text-replace",
  },
  refund: {
    bg: "bg-refund",
    text: "text-refund",
  },
  reship: {
    bg: "bg-reship",
    text: "text-reship",
  },
  pending: {
    bg: "bg-pending",
    text: "text-pending",
  },
  refundrejected: {
    bg: "bg-refundrejected",
    text: "text-refundrejected",
  },
  refunded: {
    bg: "bg-refunded",
    text: "text-refunded",
  },
  reshipdelivered: {
    bg: "bg-reshipdelivered",
    text: "text-reshipdelivered",
  },
  cancelrefund: {
    bg: "bg-cancelled",
    text: "text-cancelled",
  },
  cancelrefunded: {
    bg: "bg-cancelled",
    text: "text-cancelled",
  },
  cancelrefundrejected: {
    bg: "bg-cancelled",
    text: "text-cancelled",
  },
  cancelreturn: {
    bg: "bg-cancelled",
    text: "text-cancelled",
  },
  cancelledrefundprocessing: {
    bg: "bg-return",
    text: "text-return",
  },
  returnrefundprocessing: {
    bg: "bg-return",
    text: "text-return",
  },
};

function MyOrders(props) {
  const history = useHistory();
  const { session, isLoggedIn, myOrder, localLanguage, deviceSize } = props;
  const [sentmail, setSentmail] = useState(true);

  const orderStatus = [
    { status: "delivered", stausText: LocaleStrings.order_delivered },
    { status: "intransit", stausText: LocaleStrings.in_transit },
    { status: "dispatched", stausText: LocaleStrings.order_dispatched },
    { status: "placed", stausText: LocaleStrings.order_Placed },
    { status: "cancelled", stausText: LocaleStrings.order_cancelled },
    { status: "returnreceived", stausText: LocaleStrings.return_received },
    { status: "returnpicked", stausText: LocaleStrings.return_picked },
    { status: "initiatepickup", stausText: LocaleStrings.initiate_pickup },
    { status: "return", stausText: LocaleStrings.return_requested },
    { status: "replace", stausText: LocaleStrings.replacement_requested },
    { status: "refund", stausText: LocaleStrings.refund_initiated },
    { status: "pending", stausText: LocaleStrings.payment_pending },
    { status: "refundrejected", stausText: LocaleStrings.refund_rejected },
    { status: "refunded", stausText: LocaleStrings.refunded },
    { status: "reshipdelivered", stausText: LocaleStrings.reshipdelivered },
    { status: "reship", stausText: LocaleStrings.reship },
    { status: "cancelrefund", stausText: LocaleStrings.cancel_refund },
    { status: "cancelrefunded", stausText: LocaleStrings.cancel_refunded },
    {
      status: "cancelrefundrejected",
      stausText: LocaleStrings.cancel_refundrejected,
    },
    {
      status: "cancelreturn",
      stausText: LocaleStrings.cancel_return,
    },
    {
      status: "cancelledrefundprocessing",
      stausText: LocaleStrings.refund_processing,
    },
    {
      status: "returnrefundprocessing",
      stausText: LocaleStrings.refund_processing,
    },
  ];

  useEffect(() => {
    if (isLoggedIn) {
      const code = props.match.params.code;
      if (code && sentmail) {
        props.orderPlacedMail(session, code);
        setSentmail(!sentmail);
        toast.success(LocaleStrings.pay_success);
      }

      window.scrollTo(0, 0);
      props.fetchMyorders(session, (res) => {});
      props.emptyOrderDetails();
    } else {
      history.push("/login");
    }
  }, []);

  const orderDetails = (orderdetailsid) => {
    history.push("/order-details/" + orderdetailsid);
  };

  const renderOrder = (item) => {
    //console.log("item", item);

    let statusText = _.filter(orderStatus, function (obj) {
      return obj.status == item.currentstatus.status;
    });

    //console.log("statusText", statusText);
    return (
      <div
        className="flex items-center gap-4 p-4 cursor-pointer"
        key={item.id}
        onClick={() => orderDetails(item.orderdetailsid)}
      >
        <div className="flex items-center justify-center w-catwidth p-3 rounded-md border border-primary">
          <img
            key={item.id}
            className="rounded-md object-cover"
            src={`${FILES_URL}/vendor/variations/${item.productimage}?api_key=${APP_API_KEY}`}
          />
        </div>
        <div className="w-4/5 pl-3">
          <div key={item.id}>
            <div className="font-medium text-primary text-sm">
              {ProductTextLocalized(
                item.productname_en,
                item.productname_ar,
                localLanguage
              )}
            </div>
            <div className="font-normal text-loginLabel text-xs">
              {LocaleStrings.order_id}&nbsp;:&nbsp;{item.invoice_no}
            </div>
            <div className="mt-4 ">
              <div className="flex">
                <div
                  className={`${colors[item.currentstatus.status].bg} ${
                    colors[item.currentstatus.status].text
                  } rounded-xl text-xs font-medium flex items-center justify-center px-2 py-0.5`}
                >
                  {statusText[0].stausText}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!deviceSize.isMobile ? (
          <div className="text-right ">
            <div
              className="flex items-center justify-center border border-primary rounded p-2 font-medium text-primary text-sm cursor-pointer"
              onClick={() => orderDetails(item.orderdetailsid)}
            >
              <div className="pl-2">
                {item.currentstatus.status == "pending"
                  ? LocaleStrings.order_details_pay_now
                  : LocaleStrings.order_details_btn}
              </div>
              <div>
                <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <>
      <div className="bg-newarrival py-25">
        <div className="custom_container pb-25">
          <div>{LocaleStrings.my_orders}</div>
        </div>
        <div className="bg-white custom_container">
          {myOrder && myOrder.data ? (
            myOrder.data.length > 0 ? (
              <div className="divide-y divide-gray-200">
                {myOrder.data.map((item, index) => {
                  return renderOrder(item);
                })}
              </div>
            ) : (
              <div className="flex h-cardimg items-center justify-center">
                <div>
                  <div className="flex items-center justify-center pb-5">
                    <img src={NoOrder} />
                  </div>
                  <div className="flex items-center justify-center text-cutoff text-base font-medium">
                    {LocaleStrings.no_order_title}
                  </div>
                  <div className="flex items-center justify-center text-cutoff text-sm">
                    {LocaleStrings.no_order_desc}
                  </div>
                </div>
              </div>
            )
          ) : (
            <Loader data={LocaleStrings.loader_message} />
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  myOrder: state.myOrder,
  deviceSize: state.deviceSize,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchMyorders, emptyOrderDetails, orderPlacedMail },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyOrders);
