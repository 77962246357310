import axios from "axios";
import _ from "lodash";

import { INSTANCE_URL, APP_API_KEY } from "../../../components/Common/constant";

import {
  getRequestAccess,
  postRequestAccess,
  deleteRequest,
  getRequest,
  postRequest,
  patchRequest,
} from "../../../components/Common/network-call";
import { getHeaders } from "../../../components/Common/common-utils";

export const BID_MODAL = "BID_MODAL";
export const BIDDING_PRICE = "BIDDING_PRICE";
export const PRODUCT_COUNTER = "PRODUCT_COUNTER";
export const USER_DEFAULT_ADDRESS = "USER_DEFAULT_ADDRESS";
export const USER_CART = "USER_CART";
export const USER_NEGOTIATE_THREAD = "USER_NEGOTIATE_THREAD";
export const NEGOTIATION_DETAILS = "NEGOTIATION_DETAILS";
export const ASK_QUESTION_MODAL = "ASK_QUESTION_MODAL";
export const QUESTION_LIST = "QUESTION_LIST";
export const IS_LIKES_MODAL_OPEN = "IS_LIKES_MODAL_OPEN";
export const LIKES_LIST = "LIKES_LIST";
export const IS_WISHLIST_PRODUCT_VIEW_MODAL_OPEN =
  "IS_WISHLIST_PRODUCT_VIEW_MODAL_OPEN";
export const WISHLIST_PRODUCT_VIEW = "WISHLIST_PRODUCT_VIEW";
export const WISHLIST_LIST = "WISHLIST_LIST";
export const IS_COMMENT_MODAL_OPEN = "IS_COMMENT_MODAL_OPEN";
export const COMMENT_LIST = "COMMENT_LIST";
export const MENTION_USER_LIST = "MENTION_USER_LIST";
export const STORE_MESSAGE_OPEN = "STORE_MESSAGE_OPEN";
export const MESSAGE_THREAD = "MESSAGE_THREAD";
export const MESSAGE_LIST = "MESSAGE_LIST";
export const IS_QUESION_LIST_PRODUCT_VIEW_MODAL_OPEN =
  "IS_QUESION_LIST_PRODUCT_VIEW_MODAL_OPEN";
export const QUESION_LIST_PRODUCT_VIEW = "QUESION_LIST_PRODUCT_VIEW";
export const IS_BID_PLACED_MODAL_OPEN = "IS_BID_PLACED_MODAL_OPEN";
export const PUBLIC_BIDS = "PUBLIC_BIDS";
export const IS_REVIEW_LIST_PRODUCT_VIEW_MODAL_OPEN =
  "IS_REVIEW_LIST_PRODUCT_VIEW_MODAL_OPEN";
export const ALL_REVIEWS = "ALL_REVIEWS";
export const REPORT_ABUSE_MODAL = "REPORT_ABUSE_MODAL";
export const IS_BID_WATCHING_MODAL_OPEN = "IS_BID_WATCHING_MODAL_OPEN";
export const PRODUCT_WATCHERS = "PRODUCT_WATCHERS";
export const IS_PRODUCT_VIEW_MODAL_OPEN = "IS_PRODUCT_VIEW_MODAL_OPEN";
export const IS_WISHLIST_CREATE_NEW = "IS_WISHLIST_CREATE_NEW";
export const IS_PRODUCT_SHARE_MODAL_OPEN = "IS_PRODUCT_SHARE_MODAL_OPEN";
export const OPEN_MEDIA_LIGHTBOX = "OPEN_MEDIA_LIGHTBOX";
export const PUBLIC_WISHLIST = "PUBLIC_WISHLIST";
export const HIGHLIGHT_COMMENT = "HIGHLIGHT_COMMENT";
export const STORED_CART = "STORED_CART";

export function openBidPlaceModal(
  showModal = false,
  productId = "",
  variantId = "",
  auction_start_date = "",
  auction_end_date = "",
  product_name = ""
) {
  return {
    type: BID_MODAL,
    payload: {
      showModal: showModal,
      productid: productId,
      variantid: variantId,
      auction_start_date: auction_start_date,
      auction_end_date: auction_end_date,
      product_name: product_name,
    },
  };
}

export function emptyBiddingPrice() {
  return (dispatch) => {
    dispatch({
      type: BIDDING_PRICE,
      payload: {
        suggestions: [],
        minbidprice: "",
      },
    });
  };
}

export function getBiddingPrice(session, productId, variantId, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/getbiddingprice`;
  var params = { productid: `${productId}`, variantid: `${variantId}` };
  return (dispatch) => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: BIDDING_PRICE,
          payload: {
            suggestions: response.suggestions,
            minbidprice: response.minbidprice,
            lastbiddingprice: response.lastbiddingprice
              ? response.lastbiddingprice
              : response.minbidprice,
          },
        });
        callback({ success: 1 });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function getProductCounter(session, productId, variantid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/productcounter`;
  var params = { productid: `${productId}`, variantid: `${variantid}` };
  return (dispatch) => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PRODUCT_COUNTER,
          payload: {
            numbids: response.numbids,
            numcomments: response.numcomments,
            numwatching: response.numwatching,
            quantityleft: response.quantityleft,
          },
        });
        callback({ success: 1 });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function placeBid(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/placeabid`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function getUserAddress(session, callback) {
  var filter = encodeURI(`(useridfk=${session.userid})`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/user_address?related=zone_master_by_zoneno&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        //console.log("data", data.resource);
        dispatch({ type: USER_DEFAULT_ADDRESS, payload: data.resource });
        callback({ success: 1, data: data.resource });
      },
      (error) => {}
    );
  };
}

export function addCart(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/addcart`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function fetchCart(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/cart`;

  return (dispatch) => {
    postRequest(
      url,
      null,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.result == "success") {
          dispatch({
            type: USER_CART,
            payload: {
              items: response.items,
              maxhandlingdays: response.maxhandlingdays,
              currencyrate: response.currencyrate,
            },
          });
          callback({
            success: 1,
            data: response.items,
            maxhandlingdays: response.maxhandlingdays,
            currencyrate: response.currencyrate,
          });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function emptyNegotiateThread(callback) {
  return (dispatch) => {
    dispatch({
      type: USER_NEGOTIATE_THREAD,
      payload: {},
    });
  };
}

export function emptyNegotiationDetails(callback) {
  return (dispatch) => {
    dispatch({
      type: NEGOTIATION_DETAILS,
      payload: { thread: {} },
    });
  };
}

export function initiateNegotiationAction(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/initiatenegotiation`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.result == "success") {
          if (response.details && response.details.negotiationthreadid) {
            let threadid = response.details.negotiationthreadid;

            // dispatch(negotiationDetailsAction(session, threadid, callback));
            dispatch({
              type: USER_NEGOTIATE_THREAD,
              payload: {
                threadid: threadid,
              },
            });
            callback({ success: 1, threadid: threadid });
          } else {
            callback({ success: 0 });
          }
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function negotiationDetailsAction(
  session,
  negotiationthreadid,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/negotiationdetails`;
  let values = {};
  values.threadid = negotiationthreadid;
  values.usertype = "consumer";
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.result == "success") {
          dispatch({
            type: NEGOTIATION_DETAILS,
            payload: {
              thread: response.data.thread,
              negotiations: response.data.negotiations,
              itemalreadypurchased: response.data.itemalreadypurchased,
            },
          });
          callback({
            success: 1,
            threadid: negotiationthreadid,
            thread: response.data.thread,
          });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function sendNegotiationPrice(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/sendnegotiation`;
  //console.log("values", values);
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.result == "success") {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function negotiationPurchase(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/negotiation_addtocart`;
  //console.log("values", values);
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.result == "success") {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function updateNegotationQuantity(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/updatenegotiation`;
  //console.log("values", values);
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.result == "success") {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function askQuestionModal(
  showModal = false,
  productId = "",
  vendorId = ""
) {
  return {
    type: ASK_QUESTION_MODAL,
    payload: {
      showModal: showModal,
      productid: productId,
      vendorid: vendorId,
    },
  };
}

export function questionListByProduct(
  session,
  productid,
  keyword = "",
  pagenumber = 1,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/appscript/qa`;
  let values = {};
  values.productid = productid;
  values.withlimit = 0;
  if (keyword) {
    values.searchterm = keyword;
  }
  values.page = pagenumber;
  return (dispatch) => {
    postRequestAccess(
      url,
      values,
      null,
      dispatch,
      (response) => {
        dispatch({
          type: QUESTION_LIST,
          payload: {
            data: response.data,
            totalrows: response.totalrows,
          },
        });
        callback({ success: 1, data: response.data });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function addQuestion(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/questionanswer`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function likeUnlike(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/likeunlike`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, likes: response.likes });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function isLikesSliderOpen(isOpen) {
  return { type: IS_LIKES_MODAL_OPEN, payload: isOpen };
}

export function likesByProductId(session, productid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/productlikes`;
  let values = {};
  values.productid = productid;

  if (session) {
    return (dispatch) => {
      postRequest(
        url,
        values,
        session,
        dispatch,
        (response) => {
          dispatch({
            type: LIKES_LIST,
            payload: {
              data: response.likes,
            },
          });
          callback({ success: 1 });
        },
        (error) => {
          //callback({success: 0, error: error});
        }
      );
    };
  } else {
    return (dispatch) => {
      postRequestAccess(
        url,
        values,
        null,
        dispatch,
        (response) => {
          dispatch({
            type: LIKES_LIST,
            payload: {
              data: response.likes,
            },
          });
          callback({ success: 1 });
        },
        (error) => {
          //callback({success: 0, error: error});
        }
      );
    };
  }
}

export function addWatchlist(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/watchlist`;
  var body = { resource: [values] };
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (response.resource && response.resource.length > 0) {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function removeWatchlist(session, productid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/removefromwatch`;
  let values = {};
  values.productid = productid;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.result == "success") {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function removeWishlist(session, id, callback) {
  var deleteUrl = `${INSTANCE_URL}/api/v2/shopez/_table/wishlistitems?ids=${id}`;
  return (dispatch) => {
    deleteRequest(
      deleteUrl,
      session,
      dispatch,
      (delres) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function wishlistProductStore(productid = "") {
  return { type: WISHLIST_PRODUCT_VIEW, payload: { productid: productid } };
}

export function isWishlistModalOpen(isOpen) {
  return { type: IS_WISHLIST_PRODUCT_VIEW_MODAL_OPEN, payload: isOpen };
}

export function isWishlistCreateNew(isOpen) {
  return { type: IS_WISHLIST_CREATE_NEW, payload: isOpen };
}

export function emptyWishList(callback) {
  return (dispatch) => {
    dispatch({
      type: WISHLIST_LIST,
      payload: {},
    });
  };
}

export function fetchWishList(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/wishlists`;
  let values = {};

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: WISHLIST_LIST,
          payload: {
            data: response.data,
          },
        });
        callback({ success: 1, data: response.data });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function fetchSharedWishList(session, profileid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/usersharedwishlists`;
  let values = { profileid: profileid };

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: WISHLIST_LIST,
          payload: {
            data: response.data,
          },
        });
        callback({ success: 1, data: response.data });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function addWishlist(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/wishlist`;
  var body = { resource: [values] };
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (response.resource && response.resource.length > 0) {
          callback({ success: 1, wishlistid: response.resource[0].id });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function addWishlistItem(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/wishlistitems`;
  var body = { resource: [values] };
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (response.resource && response.resource.length > 0) {
          callback({ success: 1, wishlistid: response.resource[0].id });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function deletWishlist(session, id, callback) {
  var deleteUrl = `${INSTANCE_URL}/api/v2/shopez/_table/wishlist/${id}`;
  return (dispatch) => {
    deleteRequest(
      deleteUrl,
      session,
      dispatch,
      (delres) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function clearWishlist(session, id, callback) {
  var deleteUrl = `${INSTANCE_URL}/api/v2/shopez/_table/wishlistitems?filter=(wishlistid=${id})`;
  return (dispatch) => {
    deleteRequest(
      deleteUrl,
      session,
      dispatch,
      (delres) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function isCommentModalOpen(isOpen) {
  return { type: IS_COMMENT_MODAL_OPEN, payload: isOpen };
}

export function fetchMentionUser(session, searchtext, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript/searchtaggable`;
  let values = { searchtext: searchtext };

  return (dispatch) => {
    postRequestAccess(
      url,
      values,
      null,
      dispatch,
      (response) => {
        dispatch({
          type: MENTION_USER_LIST,
          payload: {
            data: response.users,
          },
        });
        callback({ success: 1 });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function searchTaggable(session, searchtext, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/searchtaggable`;
  let values = { searchtext: searchtext };

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, users: response.users });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function emptyCommentList(callback) {
  return (dispatch) => {
    dispatch({
      type: COMMENT_LIST,
      payload: {},
    });
  };
}

export function fetchCommentList(session, productid, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript/comments`;
  let values = { productid: productid };
  if (session) {
    return (dispatch) => {
      postRequest(
        url,
        values,
        session,
        dispatch,
        (response) => {
          dispatch({
            type: COMMENT_LIST,
            payload: {
              data: response.comments,
            },
          });
          callback({ success: 1 });
        },
        (error) => {
          //callback({success: 0, error: error});
        }
      );
    };
  } else {
    return (dispatch) => {
      postRequestAccess(
        url,
        values,
        null,
        dispatch,
        (response) => {
          dispatch({
            type: COMMENT_LIST,
            payload: {
              data: response.comments,
            },
          });
          callback({ success: 1 });
        },
        (error) => {
          //callback({success: 0, error: error});
        }
      );
    };
  }
}

export function addComment(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/product_comments`;
  var body = { resource: [values] };
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (response.resource && response.resource.length > 0) {
          callback({ success: 1, id: response.resource[0].id });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function mentionEmail(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendemail/mention`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function updateComment(session, values, id, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/product_comments/${id}`;
  return (dispatch) => {
    patchRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function deletComment(session, id, callback) {
  var deleteUrl = `${INSTANCE_URL}/api/v2/shopez/_table/product_comments/${id}`;
  return (dispatch) => {
    deleteRequest(
      deleteUrl,
      session,
      dispatch,
      (delres) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function logActivity(session, type, id, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/logactivity`;
  let params = {};
  params.activitytype = type;
  if (type == "comment") {
    params.commentid = id;
  } else if (type == "following") {
    params.followingid = id;
  } else if (type == "liked") {
    params.likeid = id;
  } else {
    params.productid = id;
  }

  return (dispatch) => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function shareWishlist(session, wishlistid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/sharewishlist`;
  let values = {};
  values.wishlistid = wishlistid;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.result == "success") {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function followUnfollow(session, storeid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/followunfollow`;
  let values = {};
  values.followeduser = storeid;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.result == "success") {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function isSendMessageStoreOpen(isOpen) {
  return { type: STORE_MESSAGE_OPEN, payload: isOpen };
}

export function messageInitiate(session, storeid, productid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/initiatemessage`;
  let values = { receiverid: storeid, productid: productid };

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.result == "success") {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function fetchMessageThread(session, profileid = "", callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/messagethreads`;
  let values = {};
  //console.log("profileid", profileid);
  if (profileid != "") {
    values.profileid = profileid;
  }
  // console.log("values", values);
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.result == "success") {
          dispatch({
            type: MESSAGE_THREAD,
            payload: {
              threads: response.threads,
            },
          });
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function emptyMessageList(callback) {
  return (dispatch) => {
    dispatch({
      type: MESSAGE_LIST,
      payload: {},
    });
  };
}

export function fetchMessageList(session, threadid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/threadmessages`;
  let values = { threadid: threadid };

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        console.log("response", response);
        if (response.result == "success") {
          dispatch({
            type: MESSAGE_LIST,
            payload: {
              messages: response.messages,
            },
          });
          callback({ success: 1, data: response.thread });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function sendMessageStore(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/messages`;
  var body = { resource: [values] };
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (response.resource && response.resource.length > 0) {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function isQuestionlistModalOpen(isOpen) {
  return { type: IS_QUESION_LIST_PRODUCT_VIEW_MODAL_OPEN, payload: isOpen };
}

export function questionListProductStore(productid = "") {
  return { type: QUESION_LIST_PRODUCT_VIEW, payload: { productid: productid } };
}

export function isbidPlacedSliderOpen(isOpen) {
  return { type: IS_BID_PLACED_MODAL_OPEN, payload: isOpen };
}

export function emptyPublicBids(callback) {
  return (dispatch) => {
    dispatch({
      type: PUBLIC_BIDS,
      payload: {},
    });
  };
}

export function fetchPublicBids(session, variantid, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript/publicbids`;
  let values = {};
  values.variantid = variantid;

  if (session) {
    return (dispatch) => {
      postRequest(
        url,
        values,
        session,
        dispatch,
        (response) => {
          dispatch({
            type: PUBLIC_BIDS,
            payload: {
              data: response.bids,
            },
          });
          callback({ success: 1 });
        },
        (error) => {
          //callback({success: 0, error: error});
        }
      );
    };
  } else {
    return (dispatch) => {
      postRequestAccess(
        url,
        values,
        null,
        dispatch,
        (response) => {
          dispatch({
            type: PUBLIC_BIDS,
            payload: {
              data: response.bids,
            },
          });
          callback({ success: 1 });
        },
        (error) => {
          //callback({success: 0, error: error});
        }
      );
    };
  }
}

export function isReviewlistModalOpen(isOpen) {
  return { type: IS_REVIEW_LIST_PRODUCT_VIEW_MODAL_OPEN, payload: isOpen };
}

export function fetchAllReviews(
  session,
  productid,
  sortby,
  keyword,
  pagenumber = 1,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/appscript/allreviews`;
  let values = {};
  values.productid = productid;
  if (sortby) {
    values.sortby = sortby;
  }
  if (keyword) {
    values.searchterm = keyword;
  }
  values.page = pagenumber;

  return (dispatch) => {
    postRequestAccess(
      url,
      values,
      null,
      dispatch,
      (response) => {
        dispatch({
          type: ALL_REVIEWS,
          payload: {
            data: response.reviews,
          },
        });
        callback({ success: 1, data: response.reviews });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function reportAbuse(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/reportabuse`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.result == "success") {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function openReportModal(showModal = false, typeid = "", type = "") {
  return {
    type: REPORT_ABUSE_MODAL,
    payload: {
      showModal: showModal,
      typeid: typeid,
      type: type,
    },
  };
}

export function updateViews(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/updateviews`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function isbidWatchingSliderOpen(isOpen) {
  return { type: IS_BID_WATCHING_MODAL_OPEN, payload: isOpen };
}

export function getProductWatchers(session, productid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/watchers`;
  var params = { productid: `${productid}` };
  return (dispatch) => {
    postRequestAccess(
      url,
      params,
      null,
      dispatch,
      (response) => {
        dispatch({
          type: PRODUCT_WATCHERS,
          payload: {
            data: response.data,
          },
        });
        callback({ success: 1 });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function isViewAllDetailsModalOpen(isOpen) {
  return { type: IS_PRODUCT_VIEW_MODAL_OPEN, payload: isOpen };
}

export function validateUserCart(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/validateusercart`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, valid: response.valid });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function isProductShareModalOpen(isOpen) {
  return { type: IS_PRODUCT_SHARE_MODAL_OPEN, payload: { showModal: isOpen } };
}

export const openLightboxImage = (
  showModal = false,
  item = [],
  currentindex = ""
) => {
  return (dispatch) => {
    dispatch({
      type: OPEN_MEDIA_LIGHTBOX,
      payload: {
        showModal: showModal,
        photo: item,
        currentindex: currentindex,
      },
    });
  };
};

export function fetchPublicWishList(session, wishlistid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/publicwishlists`;
  let values = { wishlistid: wishlistid };

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PUBLIC_WISHLIST,
          payload: {
            data: response.data,
          },
        });
        callback({ success: 1, data: response.data });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function emptyPublicWishList(callback) {
  return (dispatch) => {
    dispatch({
      type: PUBLIC_WISHLIST,
      payload: {},
    });
  };
}

export function makeHighlightComment(commentid) {
  return (dispatch) => {
    dispatch({
      type: HIGHLIGHT_COMMENT,
      payload: { commentid: commentid },
    });
  };
}

export function outofStockReg(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/outofstockregister`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function storeCart(values) {
  return (dispatch) => {
    dispatch({
      type: STORED_CART,
      payload: values,
    });
  };
}

export function makePrivateWishlist(session, wishlistid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/makeprivatewishlist`;
  let values = {};
  values.wishlistid = wishlistid;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.result == "success") {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}
