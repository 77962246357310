import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
  LABEL_POSITION_TOP,
  SMALL_DATE_FORMAT,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import Loader from "../../../components/Common/loader";
import { Fragment } from "react";
import { Transition, Dialog, Menu } from "@headlessui/react";
import { XIcon, DotsHorizontalIcon } from "@heroicons/react/outline";
import toast, { Toaster } from "react-hot-toast";
import BlueStar from "../../../assets/images/blue_star.svg";
import GreyStar from "../../../assets/images/grey_star.svg";
import NoImg from "../../../assets/images/noimg.png";
import { isReviewlistModalOpen } from "../actions";
import { confirmAlert } from "react-confirm-alert";

function ReviewsList(props) {
  const history = useHistory();
  const { session, isLoggedIn, localLanguage, reviewModal, allReviews } = props;
  const [loading, setLoading] = useState(false);
  const closeSlideOvers = () => {
    props.isReviewlistModalOpen(false);
  };

  const reportReview = (reviewid) => {
    if (isLoggedIn) {
      confirmAlert({
        title: LocaleStrings.report_confirm_review_title,
        message: LocaleStrings.report_confirm_review_message,
        buttons: [
          {
            label: LocaleStrings.button_yes,
            onClick: () => {
              props.openReportModal(true, reviewid, "review");
            },
          },
          {
            label: LocaleStrings.button_no,
            onClick: () => {},
          },
        ],
      });
    }
  };

  const ReviewList = (item) => {
    console.log("media", item.media);
    return (
      <div className="pb-9">
        <div className="flex">
          <div>
            <img
              src={`${FILES_URL}/users/${item.ratedby.userid}.jpg?api_key=${APP_API_KEY}`}
              className="max-w-none h-10 w-10 rounded-full ring-2 ring-white"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = NoImg;
              }}
            />
          </div>

          <div className="w-9/12 pl-15 align-top">
            <div className="text-primary text-sm font-normal">
              {item.feedback_anonymous === 1
                ? LocaleStrings.anonymous
                : item.ratedby && item.ratedby.username
                ? item.ratedby.username
                : ""}
            </div>
            <div className="text-cardfooter text-xs">
              {moment(item.createdat).format(SMALL_DATE_FORMAT)}
            </div>
          </div>
        </div>
        <div className="flex pt-2">
          {item.productrating && item.productrating > 0
            ? _.times(item.productrating, (i) => {
                return (
                  <div className="pr-xs">
                    <img src={BlueStar} className="w-5" />
                  </div>
                );
              })
            : ""}

          {item.productrating &&
          item.productrating > 0 &&
          5 - item.productrating > 0
            ? _.times(5 - item.productrating, (i) => {
                return (
                  <div className="pr-xs">
                    <img src={GreyStar} className="w-5" />
                  </div>
                );
              })
            : ""}
        </div>
        <div className="pt-3 text-primary text-sm font-medium">
          {LocaleStrings.review_txt}
        </div>
        <div className="flex">
          <div className="w-4/5 text-primary text-sm font-normal">
            {item.feedback}
          </div>

          {item.ratedby.userid != session.userid && item.feedback ? (
            <div className="w-wishdetailbox  text-right" key={item.id}>
              <Menu as="div" className="relative inline-block text-left">
                {({ open }) => (
                  <>
                    <div className="">
                      <Menu.Button className="btn-card">
                        <span className="sr-only">Open options</span>
                        <DotsHorizontalIcon
                          className="h-5 w-5 hover:shadow"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer z-50"
                      >
                        <div className="py-1 divide-y z-50">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                onClick={() => reportReview(item.id)}
                                className={classNames(
                                  active ? "bg-quaternary " : " ",
                                  "block px-4 py-2 text-sm text-primary"
                                )}
                              >
                                {LocaleStrings.report_as_abusive}
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          ) : (
            ""
          )}
        </div>
        {item.media && item.media.length > 0 ? (
          <div className="flex mt-3">
            {item.media.map((img, index) => {
              return (
                <div>
                  <img
                    src={`${FILES_URL}/reviews/${img}?api_key=${APP_API_KEY}`}
                    className="max-w-none h-12 w-12 rounded opacity-30"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = NoImg;
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <>
      <Transition.Root show={reviewModal} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          open={reviewModal}
          onClose={() => closeSlideOvers()}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="bg-white h-full flex flex-col  shadow-xl">
                    <div className="min-h-0 flex-1 flex flex-col pt-3 overflow-y-scroll">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="slider-modal-headtext">
                            <h2
                              id="slide-over-heading"
                              className="text-xl font-normal text-vendorLink"
                            >
                              {LocaleStrings.reviews_txt}
                            </h2>
                          </Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              onClick={() => closeSlideOvers()}
                              className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-1 relative flex-1 px-4 sm:px-6">
                        <div className="pt-7">
                          {allReviews && allReviews.data ? (
                            allReviews.data.length > 0 ? (
                              allReviews.data.map((item, index) => {
                                return ReviewList(item);
                              })
                            ) : (
                              ""
                            )
                          ) : (
                            <div className="h-50">
                              <Loader />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  reviewModal: state.reviewModal,
  allReviews: state.allReviews,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ isReviewlistModalOpen }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsList);
