import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { PushSpinner } from "react-spinners-kit";
import LocaleStrings from "../../../languages/index";

// Import BaseLoader
import Loader from "../../../components/Common/loader";
// Import Component
import LegalDocsList from "./legaldocslist";
// Import Action
import { fetchSettingsMaster } from "../actions/index";

class LegalDocs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_legal_docs,
    };

    // All Binded Functions
  }

  componentDidMount() {
    this.props.fetchSettingsMaster(this.props.session, (callback) => {
      this.setState({ loading: false });
    });
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div className="bg-newarrival pb-25">
          {this.state.loading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              <div className="grid grid-cols-1 pt-25 custom_container">
                <div className="bg-white rounded">
                  <div className="py-6">
                    <LegalDocsList history={this.props.history} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default connect(mapStateToProps, {
  fetchSettingsMaster,
})(LegalDocs);
