import axios from "axios";
import _ from "lodash";

import { INSTANCE_URL, APP_API_KEY } from "../../../components/Common/constant";

import {
  getRequestAccess,
  postRequestAccess,
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../components/Common/network-call";
import { getHeaders } from "../../../components/Common/common-utils";

export const PRODUCT_LISTING = "PRODUCT_LISTING";
export const SIMILAR_PRODUCT = "SIMILAR_PRODUCT";
export const SEARCH_PRODUCT = "SEARCH_PRODUCT";
export const PRODUCT_DETAILS = "PRODUCT_DETAILS";
export const HOME_PAGE_DATA = "HOME_PAGE_DATA";
export const SEARCH_KEY = "SEARCH_KEY";

export function fetchHomePageData(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript/homelayoutdata`;
  var params = {};
  return (dispatch) => {
    postRequestAccess(
      url,
      params,
      null,
      dispatch,
      (response) => {
        dispatch({
          type: HOME_PAGE_DATA,
          payload: { data: response.data },
        });
        callback({
          success: 1,
        });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function fetchProducts(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript/products`;
  var params = { page: "home", curpage: 1 };
  if (session) {
    return (dispatch) => {
      postRequest(
        url,
        params,
        session,
        dispatch,
        (response) => {
          //console.log("res", response);
          //var newproducts = JSON.parse(JSON.stringify(response.newproducts));
          //console.log("newproducts", newproducts);
          dispatch({
            type: PRODUCT_LISTING,
            payload: { data: JSON.parse(JSON.stringify(response.newproducts)) },
          });
          callback({
            success: 1,
            products: JSON.parse(JSON.stringify(response.newproducts)),
          });
        },
        (error) => {
          //callback({success: 0, error: error});
        }
      );
    };
  } else {
    return (dispatch) => {
      postRequestAccess(
        url,
        params,
        null,
        dispatch,
        (response) => {
          //console.log("res", response);
          //var newproducts = JSON.parse(JSON.stringify(response.newproducts));
          //console.log("newproducts", newproducts);
          dispatch({
            type: PRODUCT_LISTING,
            payload: JSON.parse(JSON.stringify(response.newproducts)),
          });
          callback({
            success: 1,
            products: JSON.parse(JSON.stringify(response.newproducts)),
          });
        },
        (error) => {
          //callback({success: 0, error: error});
        }
      );
    };
  }
}

export function fetchProductByID(session, productid, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript/productbyid`;
  var params = { productid: productid };
  if (session) {
    return (dispatch) => {
      postRequest(
        url,
        params,
        session,
        dispatch,
        (response) => {
          dispatch({
            type: PRODUCT_DETAILS,
            payload: JSON.parse(JSON.stringify(response.product)),
          });
          callback({
            success: 1,
            products: JSON.parse(JSON.stringify(response.product)),
          });
        },
        (error) => {
          //callback({success: 0, error: error});
        }
      );
    };
  } else {
    return (dispatch) => {
      postRequestAccess(
        url,
        params,
        null,
        dispatch,
        (response) => {
          dispatch({
            type: PRODUCT_DETAILS,
            payload: JSON.parse(JSON.stringify(response.product)),
          });
          callback({
            success: 1,
            products: JSON.parse(JSON.stringify(response.product)),
          });
        },
        (error) => {
          //callback({success: 0, error: error});
        }
      );
    };
  }
}

export function fetchSimilarProducts(session, productid, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript/similarproducts`;
  var params = { productid: `${productid}`, withlimit: 0 };
  //console.log("session", session);
  return (dispatch) => {
    postRequestAccess(
      url,
      params,
      null,
      dispatch,
      (response) => {
        dispatch({
          type: SIMILAR_PRODUCT,
          payload: response,
        });
        callback({ success: 1 });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function emptySimilar(callback) {
  return (dispatch) => {
    dispatch({
      type: SIMILAR_PRODUCT,
      payload: {},
    });
  };
}

export function searchProduct(session, searchterm, catid = "", callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript/products`;
  var params = { searchterm: `${searchterm}` };
  if (catid) {
    params.categoryid = catid;
  }
  return (dispatch) => {
    postRequestAccess(
      url,
      params,
      null,
      dispatch,
      (response) => {
        dispatch({
          type: SEARCH_PRODUCT,
          payload: { data: JSON.parse(JSON.stringify(response.products)) },
        });
        callback({ success: 1 });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function emptySearch(callback) {
  return (dispatch) => {
    dispatch({
      type: SEARCH_PRODUCT,
      payload: {},
    });
  };
}

export function setSearchKey(key = "", callback) {
  return (dispatch) => {
    dispatch({
      type: SEARCH_KEY,
      payload: { key: key },
    });
  };
}

export function predictiveSearch(session, searchtext, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript/predictivesearch`;
  let values = { searchterm: searchtext };

  return (dispatch) => {
    postRequestAccess(
      url,
      values,
      null,
      dispatch,
      (response) => {
        callback({ success: 1, data: response.data });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}
