import { postRequest } from "../../../components/Common/network-call";

import { INSTANCE_URL, FILES_URL } from "../../../components/Common/constant";

export function sendInvitation(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendemail`;
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      object,
      session,
      dispatch,
      (response) => {
        callback({ status: 1 });
      },
      (error) => {
        callback({ status: 0 });
      }
    );
  };
}
