import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import LocaleStrings from "../languages";
import SigninLogo from "../assets/images/signin_white.svg";
import SignupLogo from "../assets/images/signup_white.svg";
import MyaccountLogo from "../assets/images/myaccount_white.svg";
import { confirmAlert } from "react-confirm-alert";

import { logout, resetStore } from "../../src/pages/Login/actions";

function Navright(props) {
  const history = useHistory();
  const { isLoggedIn, session } = props;

  const logOut = () => {
    confirmAlert({
      title: LocaleStrings.logout_confirm_title,
      message: LocaleStrings.logout_confirm_message,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            props.logout();
            history.push("/login");
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <>
      <div className="flex justify-end items-center gap-2">
        {isLoggedIn ? (
          <>
            <div
              className="text-white text-sm flex pr-lg items-center cursor-pointer gap-1"
              onClick={() => history.push("/profile")}
            >
              <span className="pr-sm">
                <img src={MyaccountLogo} />
              </span>
              <span>{LocaleStrings.my_account}</span>
            </div>
            <div
              className="text-white text-sm flex cursor-pointer items-center gap-1"
              onClick={() => logOut()}
            >
              <span className="pr-sm">
                <img src={SigninLogo} />
              </span>
              <span>{LocaleStrings.signout}</span>
            </div>
          </>
        ) : (
          <>
            <div
              className="text-white text-sm flex pr-lg items-center cursor-pointer gap-1"
              onClick={() => history.push("/signup")}
            >
              <span className="pr-sm">
                <img src={SignupLogo} />
              </span>
              <span>{LocaleStrings.create_a_new_account}</span>
            </div>
            <div
              className="text-white text-sm flex cursor-pointer items-center gap-1"
              onClick={() => history.push("/login")}
            >
              <span className="pr-sm">
                <img src={SigninLogo} />
              </span>
              <span>{LocaleStrings.signin}</span>
            </div>
          </>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Navright);
