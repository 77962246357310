import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "../../../languages/index";
import _ from "lodash";
import { renderFieldTextarea } from "../../../components/Common/base-component";
import toast from "react-hot-toast";
// Import Actions
import { isRaiseTicketModalopen } from "../actions/index";
import {
  sendsuggestions,
  uploadMediaLargeAppSuggestion,
} from "../../Suggestions/actions";

// Functions Import
import { classNames } from "../../../components/Common/constant";
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import ImgUploader from "../../../assets/images/attach-file.svg";

class RaiseTicketAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
      fileName: "",
      fileArr: [],
      problem: false,
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {}

  /**** Fake Close ****/
  closeModal1() {}

  /**** Close Modal  ****/
  closeModal() {
    // Clear Single Discount Object (Pointing to)

    this.props.isRaiseTicketModalopen(false);
  }

  /**** Input File select ****/
  _handleSelect(event) {
    let { fileArr } = this.state;
    const reader = new FileReader(); // Define reader
    let file = event.target.files[0]; // Take Selected file
    reader.readAsArrayBuffer(file); // Read the file

    // On File Load
    reader.onload = (e) => {
      file.url = e.target.result;
    };

    let ext = file.name.split(".").pop(); // File extension

    this.setState({ fileName: file.name });

    fileArr.push(file);
    this.setState({ fileArr: fileArr });
  }

  /**** RED cross Click ****/
  _handleUnSelect = (e) => {
    //let { fileArr } = this.state;

    //fileArr.splice(0, fileArr.length);

    this.setState({ fileArr: [] });
  };

  /***** Form Submit *****/
  onFormSubmit(values) {
    if (values.problem) {
      this.setState({ isBtnDisable: true, problem: false });
      this.setState({ btnLoader: true });

      let { fileArr } = this.state;

      values["userid"] = this.props.session.userid;
      values["requesttype"] = "ticket";
      values["email"] = this.props.session.emailid;
      if (this.props.orderid) {
        values["orderid"] = this.props.orderid;
      }

      // Upload Section
      let uploaderCounter = 0;
      let filetobeupload = [];
      let arrPromise = [];
      let attachmentNames = [];

      _.forEach(fileArr, function (value, index) {
        let ext = value.name.split(".").pop();
        let ts = new Date().getTime();
        let covermedia = ts + index + "." + ext;

        let coverJson = {
          type: value.type,
          name: covermedia,
          media: value.url,
        };
        attachmentNames.push(covermedia);
        filetobeupload.push(coverJson); //
      });

      let counter = filetobeupload.length;

      // Store File to server first
      filetobeupload.map((item, index) => {
        arrPromise.push(
          new Promise((resolve, reject) => {
            this.props.uploadMediaLargeAppSuggestion(
              this.props.session,
              item,
              (response) => {
                if (response.success === 0) {
                  resolve();
                } else {
                  uploaderCounter++;
                  resolve();
                }
              }
            );
          })
        );
      });

      if (counter > 0) {
        //Save With Files
        Promise.all(arrPromise).then(() => {
          if (uploaderCounter === counter) {
            values["attachment"] = _.join(attachmentNames, ",");

            // After file upload
            this.props.sendsuggestions(
              this.props.session,
              values,
              (callback) => {
                if (callback.status === 1) {
                  this.setState({ isBtnDisable: false });
                  this.setState({ btnLoader: false });
                  this.setState({ fileName: "" });
                  this.setState({ fileArr: [] });
                  this.props.reset();
                  toast.success(LocaleStrings.faq_support_submit_success);

                  this.closeModal();
                } else {
                  this.setState({ isBtnDisable: false });
                  this.setState({ btnLoader: false });

                  toast.error(LocaleStrings.common_fail_message);
                }
              }
            );
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      } else {
        //Save With out Files
        values["attachment"] = null;

        this.props.sendsuggestions(this.props.session, values, (callback) => {
          if (callback.status === 1) {
            this.setState({ isBtnDisable: false });
            this.setState({ btnLoader: false });
            this.setState({ fileName: "" });
            this.setState({ fileArr: [] });
            this.props.reset();
            toast.success(LocaleStrings.faq_support_submit_success);

            this.closeModal();
          } else {
            this.setState({ isBtnDisable: false });
            this.setState({ btnLoader: false });

            toast.error(LocaleStrings.common_fail_message);
          }
        });
      }
    } else {
      this.setState({ problem: true });
    }
  }

  render() {
    let { initialValues, language, handleSubmit } = this.props;
    let { isBtnDisable, fileArr, problem } = this.state;

    return (
      <>
        <Transition.Root show={this.props.raiseTicketOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.raiseTicketOpen}
            onClose={this.closeModal1}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div
                  className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: 0, width: "100%" }}
                >
                  {/* MOdal Header */}
                  <div className="grid grid-cols-2 border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div className="col-span-2 flex flex-wrap justify-center mt-4 pl-4">
                      <div className="text-lg leading-6 font-medium text-ads">
                        {LocaleStrings.raise_a_ticket}
                      </div>
                    </div>

                    <div className="block absolute top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="btn-closeModal"
                        onClick={this.closeModal}
                      >
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className=" "
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}
                  >
                    {/* MOdal Header Ends*/}
                    <div className="p-4 md:p-10 space-y-8">
                      {/* Body Start */}
                      <div className="">
                        <p className="text-sm text-ternary pb-4">
                          {LocaleStrings.raise_tacket_sub_header_text}
                        </p>
                      </div>
                      <Field
                        name={"problem"}
                        label={LocaleStrings.write_your_problems}
                        placeholder={LocaleStrings.write_your_questions}
                        mandatory="true"
                        component={renderFieldTextarea}
                        //labelposition={LABEL_POSITION_TOP}
                        classNameLabel="text-box-lable"
                        className="py-3 px-4 block w-full shadow-sm text-secondary border border-warm-gray-300 rounded-md"
                      />
                      {problem ? (
                        <div className="text-error" style={{ marginTop: 0 }}>
                          Required
                        </div>
                      ) : (
                        ""
                      )}
                      {/* File Attachment */}
                      <div className="flex justify-between">
                        <div className=" flex">
                          <span className="text-sm text-ternary">
                            {_.size(fileArr) > 0 ? _.size(fileArr) : ""}{" "}
                            {_.size(fileArr) > 1
                              ? LocaleStrings.files_attached
                              : _.size(fileArr) > 0
                              ? LocaleStrings.file_attached
                              : ""}
                          </span>
                          <img
                            className={classNames(
                              _.size(fileArr) > 0 ? "" : "hidden",
                              "h-4 w-4 mx-1 cursor-pointer"
                            )}
                            src={"../images/red-cross.svg"}
                            onClick={(e) => this._handleUnSelect(e)}
                          />
                        </div>
                        <div className="p-3 border border-blue-200 rounded-md text-loginLabel font-normal text-sm">
                          <label
                            htmlFor="attachment"
                            className="relative cursor-pointer flex items-center justify-center"
                          >
                            <img src={ImgUploader} className="w-2.5" />

                            <span className="pl-2">
                              {LocaleStrings.rate_product_attach}
                            </span>
                            <input
                              id="attachment"
                              name="attachment"
                              type="file"
                              accept="image/*"
                              className="sr-only"
                              onChange={(e) => this._handleSelect(e)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* Body Ends*/}

                    <div className="p-6 border-t-2 border-quaternary mt-4 flex items-center justify-center">
                      {/* Footer */}
                      <button
                        type="submit"
                        disabled={this.state.isBtnDisable}
                        className="rounded py-3 px-5 w-1/2 flex items-center justify-center bg-btn text-white font-medium text-sm cursor-pointer"
                      >
                        {this.state.btnLoader ? (
                          <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              stroke-width="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        ) : (
                          ""
                        )}
                        <span className="text-sm md:text-base">
                          {LocaleStrings.raise_a_ticket}
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  if (!values.problem) {
    errors.problem = LocaleStrings.required;
  }

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    raiseTicketOpen: state.raiseTicketOpen,
    initialValues: {},
  };
}

export default connect(mapStateToProps, {
  isRaiseTicketModalopen,
  sendsuggestions,
  uploadMediaLargeAppSuggestion,
})(
  reduxForm({
    validate,
    form: "addCouponForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(RaiseTicketAdd)
);
