import React, { Component } from "react";
import { connect } from "react-redux";
import { PushSpinner } from "react-spinners-kit";
import LocaleStrings from "../../../languages/index";
import _ from "lodash";
import ProfileIcon from "../../../assets/images/profile-setting.svg";
import ToggleSwitch from "./ToggleSwitch";
// Import Components

import { itemCount, classNames } from "../../../components/Common/constant";

// Import Actions
import {
  selectedProfileSettingsMenu,
  setReceivedNotification,
  fetchMyProfile,
  updateStoreUsers,
} from "../actions/index";

class ProfileSettingsList extends Component {
  constructor(props) {
    super(props);

    this.state = { notificationchecked: false };

    // All Binded Functions
  }

  componentDidMount() {}

  /**** On Profile Menu Select ****/
  onProfileMenuSelect(curProfileSelect) {
    //console.log("curProfileSelect", curProfileSelect);
    this.props.selectedProfileSettingsMenu(
      this.props.session,
      curProfileSelect
    );
    window.scrollTo(0, "smooth");
  }

  onNotificationChecked = (checked, field) => {
    //this.setState({ notificationchecked: checked });
    let object = {
      userid: this.props.session.userid,
      //canreceivenotification: checked ? 1 : 0,
    };
    object[field] = checked ? 1 : 0;

    this.props.updateStoreUsers(this.props.session, object, (callback) => {
      if (callback.status === 1) {
        // Fetch Vendor Details With LoginIdStore
        this.props.fetchMyProfile(this.props.session, (callBackResponce) => {});
      }
    });
  };

  /**** Render Profile settings Div List *****/
  renderList() {
    let { profileSettingsList, session, canReceivedNotification } = this.props;
    //console.log("canReceivedNotification", canReceivedNotification);
    var item = _.map(this.props.profileSettingsList, (valObj, index) => {
      let position = valObj.position;
      return valObj.icon == "notification" ? (
        <li
          key={index}
          className={classNames(
            session.usertype === "personal" &&
              (position === 1 || position === 2 || position === 3)
              ? "hidden"
              : "",
            "py-4 bg-white px-4 cursor-pointer transition ease-in-out duration-500 hover:bg-newarrival"
          )}
        >
          <div className="flex items-center space-x-4">
            <div
              className="flex-shrink-0"
              onClick={(e) => this.onProfileMenuSelect(valObj.position)}
            >
              <img
                className="h-8 w-8"
                src={"../profile_settings_images/" + valObj.icon + ".svg"}
                alt=""
              />
            </div>
            <div
              className="flex-1 min-w-0"
              onClick={(e) => this.onProfileMenuSelect(valObj.position)}
            >
              <p className="text-sm font-medium text-gray-900 truncate">
                {valObj.name}
              </p>
              <p className="text-sm text-gray-500 truncate">{valObj.subname}</p>
            </div>
            <div>
              <ToggleSwitch
                label="notification"
                notificationchecked={canReceivedNotification}
                onChange={this.onNotificationChecked}
                disabled={false}
                field="canreceivenotification"
              />
            </div>
          </div>
        </li>
      ) : (
        <li
          key={index}
          className={classNames(
            session.usertype === "personal" &&
              (position === 1 || position === 2 || position === 3)
              ? "hidden"
              : "",
            "py-4 bg-white px-4 cursor-pointer transition ease-in-out duration-500 hover:bg-newarrival"
          )}
          onClick={(e) => this.onProfileMenuSelect(valObj.position)}
        >
          <div className="flex items-center space-x-4">
            <div className="flex-shrink-0">
              <img
                className="h-8 w-8"
                src={"../profile_settings_images/" + valObj.icon + ".svg"}
                alt=""
              />
            </div>
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-900 truncate">
                {valObj.name}
              </p>
              <p className="text-sm text-gray-500 truncate">{valObj.subname}</p>
            </div>
            <div>
              <img
                className="h-8 transform rotate-180 px-2 "
                src={"../profile_settings_images/arrow.svg"}
              />
            </div>
          </div>
        </li>
      );
    });

    return item;
  }

  render() {
    return (
      <>
        <div className="pl-5">
          <div className="flow-root my-6">
            <ul role="list" className="divide-y divide-gray-200">
              {this.renderList()}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    uDetails: state.StoreDetails.data,
    session: state.session,
    profileSettingsList: state.profileSettingsList,
    canReceivedNotification:
      state.canReceivedNotification && state.canReceivedNotification.data
        ? state.canReceivedNotification.data
        : false,
  };
}

export default connect(mapStateToProps, {
  selectedProfileSettingsMenu,
  setReceivedNotification,
  fetchMyProfile,
  updateStoreUsers,
})(ProfileSettingsList);
