import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { ProductTextLocalized } from "../components/Common/product-model";
import { setSearchKey, predictiveSearch } from "../pages/Home/actions";
import LocaleStrings from "../languages";
import SearchIcon from "../assets/images/search.svg";
import AutoSuggest from "react-autosuggest";
import _ from "lodash";
import { XIcon } from "@heroicons/react/outline";

function Search(props) {
  const { appSettings, localLanguage, getSearchKey } = props;
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");

  const handleSearch = (e) => {
    let keyword = e.target.value;
    setSearch(keyword);
    props.setSearchKey(keyword);
    if (keyword.length == 0) {
      history.push("/");
    }
  };
  const keyPress = (e) => {
    console.log("e.keyCode", e.keyCode);
    if (e.keyCode == 8) {
      if (value.length == 1) handleClick();
    }
  };
  const handleClick = () => {
    setValue("");
    history.push({
      pathname: "/",
      state: { searchterm: "" },
    });
  };

  const clearSearch = () => {
    setSearch("");
  };
  let catOptions = [];
  catOptions.push(<option>All Categories</option>);
  if (
    appSettings &&
    appSettings.categories &&
    appSettings.categories.length > 0
  ) {
    appSettings.categories.map(function (cat) {
      catOptions.push(
        <option>
          {ProductTextLocalized(
            cat.categoryname_en,
            cat.categoryname_ar,
            localLanguage
          )}
        </option>
      );
    });
  }

  const getSuggestionValue = (suggestion) => {
    console.log("suggestion", suggestion);
  };

  const renderSuggestion = (suggestion, searchterm) => {
    let categories =
      appSettings && appSettings.categories && appSettings.categories.length > 0
        ? appSettings.categories
        : [];

    let cat = _.filter(categories, function (o) {
      return o.id == suggestion;
    });
    let searchStr = "";
    if (cat.length > 0) {
      searchStr = (
        <span className="font-medium text-link text-xs md:text-sm">
          &nbsp;in&nbsp;
          {ProductTextLocalized(
            cat[0].categoryname_en,
            cat[0].categoryname_ar,
            localLanguage
          )}
        </span>
      );
    }

    return cat.length > 0 ? (
      <div className="flex items-center">
        <div
          className="text-xs md:text-sm"
          onClick={() => searchbyPredict(cat, searchterm)}
        >
          {searchterm} {searchStr}
        </div>
      </div>
    ) : (
      ""
    );
  };
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const onSuggestionsFetchRequested = ({ value }) => {
    let inputValue = value;
    if (inputValue.length >= 3) {
      props.predictiveSearch(props.session, inputValue, (res) => {
        if (res.success == 1 && res.data && res.data.length > 0) {
          setSuggestions(res.data);
        }
      });
    }
  };

  const onChange = (e, { newValue }) => {
    //console.log("newValue", newValue);
    if (newValue != undefined) {
      var regex = /^\s+$/;
      if (!newValue.match(regex)) {
        setValue(newValue);
      }
    }
  };

  const searchbyPredict = (cat, searchterm) => {
    setValue(
      searchterm +
        " in " +
        ProductTextLocalized(
          cat[0].categoryname_en,
          cat[0].categoryname_ar,
          localLanguage
        )
    );
    history.push({
      pathname: "/product-search/" + searchterm + "/" + cat[0].id,
      state: { searchterm: search },
    });
    //window.open("/product-search/" + searchterm + "/" + cat[0].id, "_blank");
    //window.open("/product-search/" + searchterm + "/" + cat[0].id);
  };

  const inputProps = {
    placeholder: "",
    value,
    onChange: onChange,
  };

  const renderInputComponent = (props) => {
    return (
      <input
        {...props}
        onKeyDown={(e) => keyPress(e)}
        id="search-field"
        placeholder={LocaleStrings.search_text}
        type="search"
        name="search"
        className="block bg-search border border-header  w-full h-44 pl-2 pr-3 py-2 text-search placeholder-search focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
      />
    );
  };

  return (
    <div className="w-full flex justify-evenly">
      <div className="relative w-3/4 text-ternary focus-within:text-secondary searchsuggest">
        <AutoSuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={(value) =>
            onSuggestionsFetchRequested(value)
          }
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={(e) => renderSuggestion(e, value)}
          renderInputComponent={renderInputComponent}
          inputProps={inputProps}
        />

        {/* <input
          id="search-field"
          className="block bg-search border border-header  w-full h-44 pl-2 pr-3 py-2 text-search placeholder-search focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
          placeholder={LocaleStrings.search_text}
          type="search"
          name="search"
          value={getSearchKey.key ? getSearchKey.key : ""}
          onChange={(e) => handleSearch(e)}
          onKeyDown={(e) => keyPress(e)}
        /> */}
        {value.length > 0 && localLanguage == "ar" ? (
          // <div className="absolute inset-y-0 left-1.5 flex items-center cursor-pointer px-1">
          //   <img
          //     src={SearchIcon}
          //     className="w-5"
          //     onClick={() => handleClick()}
          //   />
          // </div>
          <div className="absolute inset-y-0 left-1.5 flex items-center cursor-pointer px-1">
            <XIcon
              className="h-6 w-6"
              aria-hidden="true"
              onClick={() => handleClick()}
            />
          </div>
        ) : value.length > 0 ? (
          <>
            {/* <div className="absolute inset-y-0 right-6 flex items-center cursor-pointer px-1">
              <img
                src={SearchIcon}
                className="w-5"
                onClick={() => handleClick()}
              />
            </div> */}

            <div className="absolute inset-y-0 right-4 flex items-center cursor-pointer px-1">
              <XIcon
                className="h-6 w-6"
                aria-hidden="true"
                onClick={() => handleClick()}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </div>

      {/* <div className="w-1/4 z-50 -ml-2">
        <select
          id="category"
          name="category"
          className="w-full h-44 py-0 pl-2 pr-7 bg-white border border-header rounded-md"
        >
          {catOptions}
        </select>
      </div> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  // isLoggedIn: state.isLoggedIn,
  // session: state.session
  deviceSize: state.deviceSize,
  localLanguage: state.localLanguage,
  appSettings: state.appSettings,
  getSearchKey: state.getSearchKey,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setSearchKey, predictiveSearch }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Search);
