import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
  LABEL_POSITION_TOP,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import Loader from "../../../components/Common/loader";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import toast, { Toaster } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import { fetchCart } from "../../Product/actions";
import {
  isChangeAddressModal,
  makeDefaultAddress,
  updateCart,
  fetchCountry,
  fetchZone,
  isNewAddressModalOpen,
  useOnceAddress,
} from "../actions";

function UserAddress(props) {
  const history = useHistory();
  const {
    session,
    isLoggedIn,
    localLanguage,
    changeAddressModal,
    getUserCart,
    userAddressAll,
    useraddressid,
    setIschanged,
  } = props;

  const [loading, setLoading] = useState(false);
  const [addressid, setAddressid] = useState("");

  useEffect(() => {
    if (useraddressid) {
      setAddressid(useraddressid);
    } else {
      setAddressid(
        getUserCart?.items?.length > 0
          ? parseInt(getUserCart.items[0].addressid)
          : ""
      );
    }
  }, [useraddressid]);

  const closeSlideOvers = () => {
    props.isChangeAddressModal(false);
  };

  const changeAddress = (e) => {
    setAddressid(e.target.value);
  };

  const isProductDeliverable = (address, cartitem) => {
    // console.log("address", address);
    // console.log("deliverablearea", cartitem.deliverablearea);

    if (
      cartitem.deliverablearea &&
      cartitem.deliverablearea.length > 0 &&
      address.length > 0
    ) {
      //console.log("deliverablearea", cartitem.deliverablearea);
      if (address[0].zip) {
        let pincodeArr = _.filter(cartitem.deliverablearea, function (obj) {
          return obj.pin_code == address[0].zip;
        });
        return pincodeArr.length > 0 ? true : false;
      } else if (address[0].zoneno) {
        let zoneArr = _.filter(cartitem.deliverablearea, function (obj) {
          return obj.zone == address[0].zoneno;
        });
        //console.log("zoneArr", zoneArr);
        return zoneArr.length > 0 ? true : false;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const addNewAddress = () => {
    closeSlideOvers();
    props.fetchZone(session);
    props.fetchCountry(props.session);
    props.isNewAddressModalOpen(true);
  };

  const addressList = (item) => {
    return item.isbillingaddress == 0 ? (
      <div className="pt-7">
        <div className="flex text-primary font-normal text-base">
          <div className="pr-4">
            <input
              type="radio"
              name="address"
              value={item.id}
              checked={addressid == parseInt(item.id) ? true : false}
              onChange={(e) => changeAddress(e)}
            />
          </div>
          <div>
            <div>
              {_.startCase(item.addresstype)}
              {" : "}
              {item.username}
            </div>
            <div className="text-cardfooter font-normal text-xs">
              {item.address}
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  };

  const saveAddress = () => {
    confirmAlert({
      title: LocaleStrings.make_default_title,
      message: LocaleStrings.make_default_msg,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            let values = {};
            values.defaultaddress = 1;
            let id = parseInt(addressid);
            props.makeDefaultAddress(session, values, id, (res) => {
              if (res.success == 1) {
                setIschanged(true);
                closeSlideOvers();
              } else {
                toast.error(LocaleStrings.common_fail_message);
              }
            });
          },
        },
        {
          label: LocaleStrings.button_once,
          onClick: () => {
            let values = { addressid: parseInt(addressid), action: "toggle" };
            props.useOnceAddress(session, values, (res) => {
              if (res.success == 1) {
                setIschanged(true);
                closeSlideOvers();
              } else {
                toast.error(LocaleStrings.common_fail_message);
              }
            });
          },
        },
      ],
    });
  };
  //console.log("useraddressid", useraddressid);
  return (
    <>
      <Transition.Root show={changeAddressModal} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          open={changeAddressModal}
          onClose={() => closeSlideOvers()}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-md flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="bg-white h-full flex flex-col  shadow-xl">
                    <div className="min-h-0 flex-1 flex flex-col pt-3 overflow-y-scroll">
                      <div className="p-2 md:px-4 md:pt-0 md:pb-4">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="slider-modal-headtext">
                            <h2
                              id="slide-over-heading"
                              className="text-xl font-normal text-vendorLink"
                            >
                              {LocaleStrings.available_address}
                            </h2>
                            <a
                              onClick={() => addNewAddress()}
                              className="text-vendorLink underline text-sm"
                            >
                              {LocaleStrings.button_add_new_address}
                            </a>
                          </Dialog.Title>

                          <div className="ml-3 h-7 flex items-center">
                            <button
                              onClick={() => closeSlideOvers()}
                              className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="bg-newarrival relative flex-1 px-2 md:px-4">
                        <div className="">
                          {userAddressAll.length > 0
                            ? userAddressAll.map((item, index) => {
                                return addressList(item);
                              })
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="text-center py-3.5">
                      <button
                        onClick={() => saveAddress()}
                        className="h-8 pl-sm pr-sm bg-btn text-white rounded font-medium text-sm cursor-pointer"
                      >
                        {LocaleStrings.done}
                      </button>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  changeAddressModal: state.changeAddressModal,
  getUserCart: state.getUserCart,
  userAddressAll: state.userAddressAll,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isChangeAddressModal,
      makeDefaultAddress,
      updateCart,
      fetchCart,
      fetchCountry,
      fetchZone,
      isNewAddressModalOpen,
      useOnceAddress,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserAddress);
