import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
  BIG_DATE_FORMAT,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import NoImg from "../../../assets/images/noimg.png";
import ProfileView from "./profile-view";
import {
  profileModalOpen,
  selectedFollowerUserDetails,
  selectedFollowersDetails,
  selectedFollowersShearedWishlist,
  emptySharedWishlist,
  fetchActivity,
  profileChange,
  profileViewNavigation,
  viewPublicProfile,
} from "../actions";
import {
  isCommentModalOpen,
  makeHighlightComment,
} from "../../Product/actions";
import InfiniteScroll from "react-infinite-scroll-component";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ActivityFeed(props) {
  const history = useHistory();
  const [buyingHistoryOpen, setBuyingHistoryOpen] = useState(false);
  const {
    session,
    isLoggedIn,
    activityFeed,
    localLanguage,
    profileModal,
    feedData,
    setFeedData,
    setIschanged,
    navigateUser,
    setPagenumber,
    pagenumber,
  } = props;
  //const [pagenumber, setPagenumber] = useState(1);

  const desiredLoc = (item) => {
    //console.log("item", item);
    if (item.type == "liked") {
      let product_str =
        _.kebabCase(item.likedata.product.productname_en) +
        "-" +
        item.likedata.product.id +
        "-" +
        item.likedata.product.variantid;

      history.push({
        pathname: "/product-details/" + product_str,
        state: { from: "activity", pagetitle: LocaleStrings.activity },
      });
    } else if (item.type == "newupload") {
      let product_str =
        _.kebabCase(item.product.productname_en) +
        "-" +
        item.product.id +
        "-" +
        item.product.variantid;

      // history.push({
      //   pathname: "/product-details/" + product_str,
      //   state: { from: "activity", pagetitle: LocaleStrings.activity },
      // });

      window.open("/product-details/" + product_str, "_blank");
    } else if (item.type == "following") {
      // props.profileModalOpen(true, false);
      // props.emptySharedWishlist();
      props.selectedFollowerUserDetails(
        session,
        item.followed.userid,
        (callback) => {}
      );
      props.selectedFollowersDetails(
        session,
        item.followed.userid,
        (callback) => {}
      );
      props.selectedFollowersShearedWishlist(
        session,
        item.followed.userid,
        (callback) => {}
      );
    } else if (item.type == "wishlist") {
      //history.push("public-wishlist/" + item.wishlistdata.id);
      window.open("public-wishlist/" + item.wishlistdata.id, "_blank");
    } else if (
      item.type == "discount" &&
      item.discountdata &&
      item.discountdata.createdby
    ) {
      //history.push("seller-profile/" + item.discountdata.createdby);
      window.open("seller-profile/" + item.discountdata.createdby, "_blank");
    } else if (
      item.type == "coupon" &&
      item.coupondata &&
      item.coupondata.createdby
    ) {
      //history.push("seller-profile/" + item.coupondata.createdby);
      window.open("seller-profile/" + item.coupondata.createdby, "_blank");
    }
  };
  const profilePage = (user) => {
    console.log({ user });
    if (user.usertype == "vendor" || user.usertype == "homebiz") {
      window.open("seller-profile/" + user.userid, "_blank");
    } else {
      let userArr = navigateUser;
      props.profileModalOpen(true, false);
      props.selectedFollowerUserDetails(session, user.userid, (callback) => {});
      props.selectedFollowersDetails(session, user.userid, (callback) => {});
      props.selectedFollowersShearedWishlist(
        session,
        user.userid,
        (callback) => {}
      );
      props.viewPublicProfile(session, user.userid);
      if (!_.includes(userArr, user.userid)) {
        userArr.push(user.userid);
        props.profileViewNavigation(userArr);
      }
    }
    // props.profileChange(true);
    // history.push("activity/" + userid);
  };

  const redirectYou = (e, item) => {
    let product_str =
      _.kebabCase(item.commentdata.product.productname_en) +
      "-" +
      item.commentdata.product.id;

    history.push({
      pathname: "/product-details/" + product_str,
      state: { from: "activity", pagetitle: LocaleStrings.activity },
    });
    if (isLoggedIn) {
      props.isCommentModalOpen(true);
      props.makeHighlightComment(item.commentid);
    }
  };

  const replaceTags = (comment, tags) => {
    let tagsArr = tags.split(",");
    let returnText = comment;
    _.map(tagsArr, (item, index) => {
      let sent1 = "<a href='http://" + parseInt(item) + "'>";
      if (returnText.indexOf(sent1) >= 0) {
        returnText = returnText.replace(sent1, "");
        returnText = returnText.replace("</a>", "");
      } else {
        returnText = "";
      }
    });
    let finalArr = returnText.split(" ");

    return finalArr;
  };

  const makeMentionStr = (item) => {
    let comment = item.commentdata.comment;
    let tags = item.commentdata.tags;
    let rawcomment = item.commentdata.rawcomment;
    let regex = /[^a-zA-Z]/gi;
    let result = rawcomment.replace(regex, "");

    let titleStrArr = replaceTags(comment.replace(result, ""), tags);
    let tagsArr = tags.split(",");
    let finalArr = _.chunk(titleStrArr, tagsArr.length);

    return finalArr.map((word, k) => {
      return (
        <span
          className="text-link pr-1 cursor-pointer"
          key={`title_key_${k}`}
          onClick={(e) => redirectYou(e, item)}
        >
          {word.join(" ")}
        </span>
      );
    });
  };

  const renderFeeds = (item) => {
    //console.log("itemitem", item);
    return (
      <div className="flex py-4">
        <div className="pr-3">
          {/* <img
            key={item.id}
            className="max-w-none h-10 w-10 rounded-full ring-2 ring-white"
            src={`${FILES_URL}/users/${item.userid}.jpg?api_key=${APP_API_KEY}`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = NoImg;
            }}
          /> */}
          <LazyLoadImage
            className="max-w-none h-10 w-10 rounded-full ring-2 ring-white"
            src={`${FILES_URL}/users/${item.userid}.jpg?api_key=${APP_API_KEY}`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = NoImg;
            }}
            effect="blur"
          />
        </div>
        <div>
          <div className="flex">
            <div
              className="text-link text-sm md:text-base font-normal pr-2 cursor-pointer"
              onClick={() => profilePage(item.user)}
            >
              {item.user.username}
            </div>
            <div className="text-numbid text-sm md:text-base font-normal pr-2">
              {item.type == "following"
                ? LocaleStrings.followed
                : item.type == "wishlist"
                ? LocaleStrings.public_wishlist_feed
                : item.type == "comment"
                ? item.commentdata && item.commentdata.tags
                  ? LocaleStrings.public_mention_feed
                  : item.type + " in "
                : item.type == "newupload"
                ? LocaleStrings.new_product_feed
                : item.type == "discount"
                ? LocaleStrings.discount_feed
                : item.type == "coupon"
                ? LocaleStrings.coupon_feed
                : item.type}
            </div>
            <div
              className="text-link text-sm md:text-base font-normal cursor-pointer"
              onClick={() => desiredLoc(item)}
            >
              {item.type == "liked"
                ? item.likedata && item.likedata.product
                  ? ProductTextLocalized(
                      item.likedata.product.productname_en,
                      item.likedata.product.productname_ar,
                      localLanguage
                    )
                  : ""
                : item.type == "purchased"
                ? item.product
                  ? ProductTextLocalized(
                      item.product.productname_en,
                      item.product.productname_ar,
                      localLanguage
                    )
                  : ""
                : item.type == "following"
                ? item.followed && item.followed.username
                  ? item.followed.username
                  : ""
                : item.type == "wishlist"
                ? item.wishlistdata && item.wishlistdata.name
                  ? item.wishlistdata.name
                  : ""
                : item.type == "comment"
                ? item.commentdata && item.commentdata.tags
                  ? makeMentionStr(item)
                  : ProductTextLocalized(
                      item.commentdata.product.productname_en,
                      item.commentdata.product.productname_ar,
                      localLanguage
                    )
                : item.type == "newupload"
                ? item.product && item.product.productname_en
                  ? ProductTextLocalized(
                      item.product.productname_en,
                      item.product.productname_ar,
                      localLanguage
                    )
                  : ""
                : item.type == "discount"
                ? item.discountdata && item.discountdata.title
                  ? item.discountdata.title
                  : ""
                : item.type == "coupon"
                ? item.coupondata && item.coupondata.title
                  ? item.coupondata.title
                  : ""
                : ""}
            </div>
          </div>
          <div className="text-cardfooter text-xs font-normal">
            {moment(item.createdat).format(BIG_DATE_FORMAT)}
          </div>
        </div>
      </div>
    );
  };

  const fetchData = (validflag, pagenumber) => {
    if (validflag) {
      props.fetchActivity(session, pagenumber, (res) => {
        if (res.data) {
          setFeedData(res.data);
          setPagenumber(pagenumber);
        }
      });
    } else {
      props.fetchActivity(session, pagenumber + 1, (res) => {
        if (res.data) {
          let newArr = _.concat(feedData, res.data);
          setFeedData(newArr);
          setPagenumber(pagenumber + 1);
        }
      });
    }
  };

  return (
    <>
      <div className="mt-5 mb-2.5 custom_container ">
        {activityFeed && activityFeed.data ? (
          feedData.length > 0 ? (
            <>
              {/* <div className="text-primary text-base font-bold">
                {LocaleStrings.based_interest}
              </div> */}
              <div className="relative flex-1 mt-3 divide-y divide-primary">
                <InfiniteScroll
                  dataLength={feedData.length} //This is important field to render the next data
                  next={() => fetchData(false, pagenumber)}
                  hasMore={activityFeed.data.length > 0 ? true : false}
                  loader={
                    <div className="flex">
                      <div className="pl-2">
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-link"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </div>
                      <h4 className="text-link text-base font-normal">
                        Loading...
                      </h4>
                    </div>
                  }
                  endMessage={
                    <p className="text-primary text-base font-normal text-center">
                      Yay! that's all folk
                    </p>
                  }
                  refreshFunction={() => fetchData(true, 1)}
                  pullDownToRefresh
                  pullDownToRefreshThreshold={20}
                  pullDownToRefreshContent={
                    <h3 style={{ textAlign: "center" }}>
                      &#8595; Pull down to refresh
                    </h3>
                  }
                  releaseToRefreshContent={
                    <h3 style={{ textAlign: "center" }}>
                      &#8593; Release to refresh
                    </h3>
                  }
                >
                  {feedData.map((item, index) => {
                    return renderFeeds(item);
                  })}
                </InfiniteScroll>
              </div>
            </>
          ) : (
            ""
          )
        ) : (
          <Loader data={LocaleStrings.loader_message} />
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  activityFeed: state.activityFeed,
  profileModal: state.profileModal,
  navigateUser: state.navigateUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      profileModalOpen,
      selectedFollowerUserDetails,
      selectedFollowersDetails,
      selectedFollowersShearedWishlist,
      emptySharedWishlist,
      fetchActivity,
      isCommentModalOpen,
      makeHighlightComment,
      profileChange,
      profileViewNavigation,
      viewPublicProfile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ActivityFeed);
