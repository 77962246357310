import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index";

// Import Components
import Loader from "../../../../components/Common/loader";
import CommonHeaderProfileSettings from "../commonheaderprofilesettings";
import ActSettingsEdit from "./actsettingsedit";

class AccountSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loaderDesc: LocaleStrings.preparing_account_settings,
    };

    // All Binded Functions
  }

  render() {
    return (
      <>
        <CommonHeaderProfileSettings
          commonHeaderText={LocaleStrings.profile_settings_list_text4}
        />
        {this.state.loading ? (
          <Loader data={this.state.loaderDesc} />
        ) : (
          <>
            <ActSettingsEdit />
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
  };
}

export default connect(mapStateToProps, {})(AccountSettings);
