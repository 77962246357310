import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index";

// Import Components
import Loader from "../../../../components/Common/loader";
import ChangePassSection from "./tabs/changepasssection";
import AddPhoneSection from "./tabs/addPhoneSection";
import AddEmailSection from "./tabs/addEmailSection";
import GenderSection from "./tabs/gendersection";

// Import Actions
import {
  fetchUsersDeatils,
  isProfileinfoCommonOpen,
} from "../../actions/index";

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";

class ProfileInfoCommonModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_profile_information,
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {}

  /**** Fake Close ****/
  closeModal1() {}

  /**** Close Modal  ****/
  closeModal() {
    this.props.isProfileinfoCommonOpen(false);
  }

  render() {
    let { headerText, footerText, callFrom } = this.props;

    return (
      <>
        <Transition.Root show={this.props.isOpenProfileInfoModal} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.isOpenProfileInfoModal}
            onClose={this.closeModal1}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div
                  className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: 0 }}
                >
                  {/* MOdal Header */}
                  <div className="grid grid-cols-2 border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div className="col-span-2 flex flex-wrap justify-start md:justify-center mt-4 pl-4">
                      <div className="text-lg leading-6 font-medium text-ads">
                        {headerText}
                      </div>
                    </div>

                    <div className="block absolute  top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="btn-closeModal"
                        onClick={this.closeModal}
                      >
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  {/* MOdal Header Ends*/}

                  <div className="p-2 md:p-6 space-y-8">
                    {callFrom === "list-gender-key" ? (
                      <GenderSection selectedData={this.props.genderData} />
                    ) : callFrom === "list-phone-key" ? (
                      <AddPhoneSection />
                    ) : callFrom === "list-pass-key" ? (
                      <ChangePassSection />
                    ) : callFrom === "list-email-key" ? (
                      <AddEmailSection />
                    ) : (
                      ""
                    )}
                  </div>
                  {/* Footer */}
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let { headerText, footerText, genderData } = ownProps;
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    isOpenProfileInfoModal: state.isOpenProfileInfoModal,
    headerText,
    footerText,
    genderData,
  };
}

export default connect(mapStateToProps, {
  fetchUsersDeatils,
  isProfileinfoCommonOpen,
})(ProfileInfoCommonModal);
