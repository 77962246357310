import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
  LABEL_POSITION_TOP,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { isMobileFilterOpen } from "../actions";
import FilterOption from "./filter-options";

function MobileFilter(props) {
  const {
    session,
    isLoggedIn,
    localLanguage,
    mobileFilter,
    openOption,
    setOpenoption,
    categoryid,
    setCategoryid,
    filterParams,
    setFilterParams,
    isSending,
    setIsSending,
    priceRange,
    setPriceRange,
    domain,
    setClearAll,
    setPagenumber,
  } = props;

  const closeSlideOvers = () => {
    props.isMobileFilterOpen(false);
  };
  //console.log("mobileFilter", mobileFilter);
  return (
    <>
      <Transition.Root show={mobileFilter} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          open={mobileFilter}
          onClose={() => closeSlideOvers()}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-sidemenumobile">
                  <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                    <div className="min-h-0 flex-1 flex flex-col pt-3 overflow-y-scroll">
                      <div className="px-4">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="slider-modal-headtext"></Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              onClick={() => closeSlideOvers()}
                              className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex-1 px-4">
                        <FilterOption
                          openOption={openOption}
                          setOpenoption={setOpenoption}
                          categoryid={categoryid}
                          setCategoryid={setCategoryid}
                          filterParams={filterParams}
                          setFilterParams={setFilterParams}
                          isSending={isSending}
                          setIsSending={setIsSending}
                          priceRange={priceRange}
                          domain={domain}
                          setPriceRange={setPriceRange}
                          setClearAll={setClearAll}
                          setPagenumber={setPagenumber}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  deviceSize: state.deviceSize,
  localLanguage: state.localLanguage,
  mobileFilter: state.mobileFilter,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ isMobileFilterOpen }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MobileFilter);
