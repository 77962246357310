import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import Loader from "../../../components/Common/loader";
import { FILES_URL, APP_API_KEY } from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import NoImg from "../../../assets/images/noimg.png";
import SellerTitle from "../../../assets/images/seller-title.svg";
import BlueStar from "../../../assets/images/blue_star.svg";
import YouLogo from "../../../assets/images/you.svg";
import ShareIcon from "../../../assets/images/share.svg";
import SearchLogo from "../../../assets/images/search.svg";
import NoProduct from "../../../assets/images/no-product.svg";
import { fetchSellerProfile, emptyTopItems } from "../actions";
import {
  followUnfollow,
  isSendMessageStoreOpen,
  messageInitiate,
  fetchMessageThread,
} from "../../Product/actions";
import { isStoreInfoMapModalOpen, fetchSellerSearch } from "../actions";
import { fetchSettings } from "../../../actions/index";
import ItemList from "../../Home/components/item-list";
import Storemape from "./storemap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ProductList(props) {
  const history = useHistory();
  const sellerid = props.match.params.sellerid;
  const {
    sellerProfile,
    session,
    isLoggedIn,
    localLanguage,
    deviceSize,
    storeInfoMapModal,
    sellerSearch,
  } = props;
  const [loading, setLoading] = useState(false);
  const [followLoader, setFollowLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("topsel DESC");

  const sortbyArr = [
    {
      key: "topsel ASC",
      text: LocaleStrings.topsel_low_to_high,
    },
    {
      key: "topsel DESC",
      text: LocaleStrings.topsel_high_to_low,
    },
    { key: "products.updatedon DESC", text: LocaleStrings.sortby_newest_first },
  ];

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    className: "center",
    swipeToSlide: false,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          dots: false,
        },
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    props.fetchSellerProfile(session, sellerid, "topsel DESC");
  }, [sellerid]);

  const itemRender = (items) => {
    return _.map(items, (post, index) => {
      return <ItemList item={post} />;
    });
  };

  const sorting = (e) => {
    let sortby = e.target.value;
    setSortBy(sortby);
    props.emptyTopItems();
    props.fetchSellerProfile(session, sellerid, sortby);
  };

  const openStoreInfoMapModal = () => {
    props.isStoreInfoMapModalOpen(true);
  };

  //console.log("loading", loading);

  return (
    <>
      <div className="bg-newarrival">
        <div className="mt-5 mb-60 custom_container">
          <div className="text-left text-primary text-xs">
            <a onClick={() => history.push("/")} className="cursor-pointer">
              {LocaleStrings.breadcrumb_home}
            </a>
            &nbsp;/&nbsp;
            <a
              onClick={() => history.push("/seller-profile/" + sellerid)}
              className="cursor-pointer"
            >
              {LocaleStrings.seller_profile}
            </a>
            &nbsp;/&nbsp; {LocaleStrings.top_selling_item}
          </div>
          {sellerProfile && sellerProfile.data ? (
            sellerProfile.data.storename ? (
              <>
                {sellerProfile &&
                sellerProfile.data &&
                sellerProfile.data.topitems &&
                sellerProfile.data.topitems.length > 0 ? (
                  <div className="">
                    <div className="">
                      <div className="grid grid-cols-2">
                        <div className="flex justify-start items-center pb-5 pt-5 text-primary text-sm md:text-xl font-normal">
                          {LocaleStrings.top_selling_item}
                        </div>
                        <div className="flex justify-end items-center text-primary text-sm md:text-base font-medium pl-4 pb-5 pt-5">
                          {deviceSize.isMobile
                            ? ""
                            : LocaleStrings.sortby + ":"}

                          <select
                            className="text-sm md:text-base text-primary font-normal bg-newarrival focus:outline-none cursor-pointer"
                            onChange={(e) => sorting(e)}
                          >
                            {sortbyArr.map((item, tabIdx) => (
                              <option
                                value={item.key}
                                selected={item.key == sortBy ? "SELECTED" : ""}
                              >
                                {item.text}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="pb-5">
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6   gap-5">
                          {itemRender(sellerProfile.data.topitems)}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )
          ) : (
            <div className="flex h-cardimg items-center justify-center">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  sellerProfile: state.sellerProfile,
  deviceSize: state.deviceSize,
  storeInfoMapModal: state.storeInfoMapModal,
  sellerSearch: state.sellerSearch,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSellerProfile,
      followUnfollow,
      isSendMessageStoreOpen,
      messageInitiate,
      fetchMessageThread,
      isStoreInfoMapModalOpen,
      fetchSettings,
      fetchSellerSearch,
      emptyTopItems,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
