import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import {
  fetchWishList,
  emptyWishList,
  isWishlistCreateNew,
} from "../../Product/actions";
import WishListItem from "../../Product/components/wishlist-item";
import CreateNew from "./create-new";
import toast, { Toaster } from "react-hot-toast";
import YouNodata from "../../../assets/images/you-nodat.svg";
import PublicIcon from "../../../assets/images/public.svg";
import PrivateIcon from "../../../assets/images/private.svg";

function Wishlist(props) {
  const history = useHistory();
  const { session, isLoggedIn, wishListed, deviceSize } = props;

  const productPerItem =
    deviceSize && deviceSize.width && deviceSize.width > 1440
      ? 9
      : deviceSize.width >= 768
      ? 7
      : 4;

  useEffect(() => {
    //console.log("outside");
    if (isLoggedIn) {
      props.emptyWishList();
      props.fetchWishList(session);
      //console.log("inside");
    } else {
      history.push("/login");
    }
  }, []);

  const renderList = (items) => {
    let remLength =
      items.length > productPerItem
        ? parseInt(items.length - productPerItem)
        : 0;

    let finalArr = remLength > 0 ? items.slice(0, 4) : items;
    return finalArr.map((item, position) => {
      return (
        <WishListItem
          item={item.productdetails}
          position={position}
          remLength={remLength}
          origin="list"
          source={props.source ? props.source : null}
        />
      );
    });
  };

  return (
    <>
      <div className="mt-5 mb-2.5 custom_container">
        {props.source && props.source == "activity" ? (
          <>
            <div className="invisible md:visible flex items-center justify-end">
              <button
                className="text-white h-44 w-40 bg-btn text-sm font-normal rounded inline-flex items-center justify-center"
                onClick={() => props.isWishlistCreateNew(true)}
              >
                {LocaleStrings.create_new_wishlist}
              </button>
            </div>

            <div className="block md:hidden fixed inset-x-0 bottom-0 p-5 bg-white z-50 ">
              <button
                className="text-white h-44 w-full bg-btn text-sm font-normal rounded inline-flex items-center justify-center"
                onClick={() => props.isWishlistCreateNew(true)}
              >
                {LocaleStrings.create_new_wishlist}
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-2 md:grid-cols-3">
              <div className="col-span-1  md:col-span-2 flex items-center  justify-start text-primary text-xs pb-5">
                <a
                  onClick={() => history.push("/activity")}
                  className="cursor-pointer"
                >
                  {LocaleStrings.activity}
                </a>
                &nbsp;/&nbsp; {LocaleStrings.wishlist_txt}
              </div>
              <div className="invisible md:visible flex items-center justify-end">
                <button
                  className="text-white h-44 w-40 bg-btn text-sm font-normal rounded inline-flex items-center justify-center"
                  onClick={() => props.isWishlistCreateNew(true)}
                >
                  {LocaleStrings.create_new_wishlist}
                </button>
              </div>
            </div>
            <div className="block md:hidden fixed inset-x-0 bottom-0 p-5 bg-white z-50 ">
              <button
                className="text-white h-44 w-full bg-btn text-sm font-normal rounded inline-flex items-center justify-center"
                onClick={() => props.isWishlistCreateNew(true)}
              >
                {LocaleStrings.create_new_wishlist}
              </button>
            </div>
          </>
        )}

        <div className="my-2">
          {wishListed && wishListed.data ? (
            wishListed.data.length > 0 ? (
              _.map(wishListed.data, (item, index) => {
                return (
                  <div
                    className=" rounded-md mb-md cursor-pointer bg-white"
                    onClick={() =>
                      props.source && props.source == "activity"
                        ? window.open("wishlist-details/" + item.id, "_blank")
                        : history.push("wishlist-details/" + item.id)
                    }
                  >
                    {item.items && item.items.length > 0 ? (
                      <div className="grid grid-cols-4 md:grid-cols-7 lg:grid-cols-8 xl:grid-cols-9 gap-3 md:gap-4   border-b border-primary p-3">
                        {renderList(item.items)}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="p-3">
                      <div className="text-primary text-base font-normal pb-xs">
                        {item.name}
                      </div>
                      <div
                        className={classNames(
                          item.items && item.items.length > 0
                            ? "divide-x divide-primary "
                            : " ",
                          "flex"
                        )}
                      >
                        <div className="flex items-center text-gray-300 text-sm pr-md">
                          <div className="pr-2">
                            <img
                              src={
                                item.privacy == "public"
                                  ? PublicIcon
                                  : PrivateIcon
                              }
                            />
                          </div>
                          <div>{_.startCase(item.privacy)}</div>
                        </div>
                        {item.items && item.items.length > 0 ? (
                          <div className="pl-md text-gray-300 text-sm">
                            {item.items.length}&nbsp;
                            {item.items.length == 1
                              ? LocaleStrings.item
                              : LocaleStrings.items}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex h-cardimg items-center justify-center">
                <div>
                  <div className="flex items-center justify-center pb-5">
                    <img src={YouNodata} />
                  </div>
                  <div className="flex items-center justify-center text-cutoff text-base font-medium">
                    {LocaleStrings.no_wishlist_title}
                  </div>
                  <div className="flex items-center justify-center text-cutoff text-sm">
                    {LocaleStrings.no_wishlist_desc}
                  </div>
                </div>
              </div>
            )
          ) : (
            <Loader data={LocaleStrings.loader_message} />
          )}
        </div>
      </div>
      <CreateNew />
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  wishListed: state.wishListed,
  deviceSize: state.deviceSize,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchWishList, emptyWishList, isWishlistCreateNew },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
