import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import Shimmer from "react-shimmer-effect";

const ShimmerEffect = (props) => {
  var {
    source,
    containerClass,
    simmerType1,
    simmerType2,
    simmerType3,
    simmerType4,
  } = props;
  // console.log('source :- ', source);

  const _rendarSimmer = () => {
    return (
      <Shimmer>
        <div className={simmerType1}></div>
        <div className={simmerType2}></div>
        <div className={simmerType3}></div>
        <div className={simmerType4}></div>
      </Shimmer>
    );
  };

  return <div className={containerClass}>{_rendarSimmer()}</div>;
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ShimmerEffect);
