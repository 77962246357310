import axios from "axios";
import _ from "lodash";

import { INSTANCE_URL, APP_API_KEY } from "../components/Common/constant";

import {
  getRequestAccess,
  deleteRequest,
  getRequest,
  postRequest,
  patchRequest,
} from "../components/Common/network-call";
import { getHeaders } from "../components/Common/common-utils";
import LocaleStrings from "../languages";

import { SESSION_TOKEN } from "../pages/Login/actions";

export const DEVICE_SIZE = "DEVICE_SIZE";
export const APP_SETTINGS = "APP_SETTINGS";
export const PRODUCT_LISTING = "PRODUCT_LISTING";
export const LOCAL_LANGUAGE = "LOCAL_LANGUAGE";
export const SLIDER_OVER = "SLIDER_OVER";
export const IS_MENU_OPEN = "IS_MENU_OPEN";
export const IS_LANGUAGE_OPEN = "IS_LANGUAGE_OPEN";
export const ADS = "ADS";
export const SET_CURRENCY = "SET_CURRENCY";
export const IS_CURRENCY_OPEN = "IS_CURRENCY_OPEN";
export var IS_VERFICATION_MODAL_OPEN = "IS_VERFICATION_MODAL_OPEN";
export var PHONE_VERIFIED = "PHONE_VERIFIED";
export var EMAIL_VERIFIED = "EMAIL_VERIFIED";
export var COUNTRY_PHONE_LENGTH = "COUNTRY_PHONE_LENGTH";

export function deviceSize(obj) {
  return (dispatch) => {
    dispatch({ type: DEVICE_SIZE, payload: obj });
  };
}

export function countryPhoneLength(length = "974") {
  return (dispatch) => {
    dispatch({ type: COUNTRY_PHONE_LENGTH, payload: length });
  };
}

export function fetchSettings(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript?type=appsettings`;
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };
  var response = axios.post(url, null, config);

  return (dispatch) => {
    response
      .then(({ data }) => {
        dispatch({ type: APP_SETTINGS, payload: data });
        callback({ success: 1, data: data });
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function setLocaleLanguage(session, lang, callback) {
  if (session.userid) {
    var url = `${INSTANCE_URL}/api/v2/shopez/_table/users/${session.userid}`;
    let values = {};
    values.languagechoice = lang;
    // Actual Data Call
    return (dispatch) => {
      patchRequest(
        url,
        values,
        session,
        dispatch,
        (response) => {
          dispatch({ type: LOCAL_LANGUAGE, payload: lang });
          LocaleStrings.setLanguage(lang);
          callback({ status: 1 });
        },
        (error) => {
          callback({ status: 0 });
        }
      );
    };
  } else {
    return (dispatch) => {
      dispatch({ type: LOCAL_LANGUAGE, payload: lang });
      LocaleStrings.setLanguage(lang);
      callback({ status: 1 });
    };
  }
}

export function setCurrency(session, currency, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users/${session.userid}`;
  let values = {};
  values.currencychoice = currency;
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        dispatch({ type: SET_CURRENCY, payload: currency });
        callback({ status: 1 });
      },
      (error) => {
        callback({ status: 0 });
      }
    );
  };
}

export function openSliderPop(showModal = false) {
  return {
    type: SLIDER_OVER,
    payload: {
      showModal: showModal,
    },
  };
}

export function isSideMenuOpen(isOpen) {
  return { type: IS_MENU_OPEN, payload: isOpen };
}

export function languageModalOpen(isOpen) {
  return { type: IS_LANGUAGE_OPEN, payload: isOpen };
}

export function fetchAds(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/adsdata`;
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };
  var response = axios.post(url, null, config);

  return (dispatch) => {
    response
      .then(({ data }) => {
        //console.log("data", data);
        dispatch({ type: ADS, payload: data.data });
        callback({ success: 1 });
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}
export function currencyModalOpen(isOpen) {
  return { type: IS_CURRENCY_OPEN, payload: isOpen };
}

export function isVerficationModalOpen(isOpen) {
  return { type: IS_VERFICATION_MODAL_OPEN, payload: isOpen };
}

/**** To verify email call PATCH ****/
export function verifyEmail(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch({ type: EMAIL_VERIFIED, payload: true });
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/**** o verify phone call PATCH ****/
export function VerifyPhoneStat(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch({ type: PHONE_VERIFIED, payload: true });
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/*** Varify phone number [from db -> ALready exist or not] ***/
export function phoneValidate(session, phoneNum, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript/phonevalidate`;

  return (dispatch) => {
    postRequest(
      url,
      {
        phone: phoneNum,
      },
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.something_went_wrong,
          data: error,
        });
      }
    );
  };
}

/* Check supplied email is alraedy exist or not for df */
export function emailExistOrNot(session, email, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?fields=*&filter=email=${email}`;

  // Actual Data Call
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response.resource.length,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/* This will check get [emailverified & phoneverified] column value from "users" table */
export function checkVerificationStatus(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?fields=emailverified,phoneverified&filter=userid=${session.userid}`;

  // Actual Data Call
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        if (response.resource[0].emailverified) {
          /* Phone Number IS verified */
          dispatch({ type: EMAIL_VERIFIED, payload: true });
        }

        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response.resource,
        });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.something_went_wrong });
      }
    );
  };
}

/* Sent Veriofication link to registerd email address  */
export function reSentEmail(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendemail`;

  return (dispatch) => {
    postRequest(
      url,
      {
        emailtype: "verifyemail",
      },
      session,
      dispatch,
      (response) => {
        callback({
          status: 1,
          message: LocaleStrings.api_success_status,
          data: response,
        });
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.something_went_wrong,
          data: error,
        });
      }
    );
  };
}

/* This fc will replace the old email with new one and sent a verification link to it */
export function changeEmail(session, newEmail, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendemail`;

  return (dispatch) => {
    postRequest(
      url,
      {
        email: newEmail,
        emailtype: "changeemail",
      },
      session,
      dispatch,
      (response) => {
        if (response.result == "success" && response.data) {
          dispatch({ type: SESSION_TOKEN, payload: response.data });

          callback({
            status: 1,
          });
        } else {
          callback({
            status: 0,
          });
        }
      },
      (error) => {
        callback({
          status: 0,
          message: LocaleStrings.something_went_wrong,
          data: error,
        });
      }
    );
  };
}

export function fetchUsersDeatils(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?filter=userid=${session.userid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        dispatch({
          type: SET_CURRENCY,
          payload: response.resource[0].currencychoice,
        });
        dispatch({
          type: LOCAL_LANGUAGE,
          payload: response.resource[0].languagechoice,
        });

        callback({ status: 1 });
      },
      (error) => {
        callback({ status: 0 });
      }
    );
  };
}
