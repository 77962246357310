import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index";

// Import Components

// Import Actions

class CommonHeaderLegalDocs extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }

  /**** Handle Back Button ****/
  _handleBack(e) {
    this.props.history.push("/legaldocs");
  }

  render() {
    return (
      <>
        <div className="py-6 bg-custWhite">
          {/* Header Part */}
          <div className="grid gap-4 grid-cols-1 md:grid-cols-3 gap-3 sm:grid-cols-2 ">
            <div
              align="left"
              className="flex cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-0 hover:scale-110"
              onClick={(e) => this._handleBack(e)}
            >
              <img
                className={" h-6 w-6 "}
                src={"../profile_settings_images/back-button.svg"}
              />
              <p className="text-primary mx-2 text-l font-bold">
                {this.props.commonHeaderText}
              </p>
            </div>
            <div className="justify-items-center"></div>
            <div className="mx-5 mt-2 flex gap-4 flex-row-reverse "></div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let { commonHeaderText } = ownProps;
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    commonHeaderText,
  };
}

export default connect(mapStateToProps, {})(CommonHeaderLegalDocs);
