import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
  uploadEncodedFileLarge,
  postRequestAccess,
} from "../../../components/Common/network-call";

import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  MAIN_URL,
  DEVELOPMENT_TYPE,
  FILES_URL,
  LOGIN,
} from "../../../components/Common/constant";

import { SESSION_TOKEN, USER_NAME } from "../../Login/actions";
import { PHONE_VERIFIED, EMAIL_VERIFIED } from "../../../actions";

import _ from "lodash";

import LocaleStrings from "../../../languages/index";

export var SELECTED_PROFILESETTINGS_MENU = "SELECTED_PROFILESETTINGS_MENU";
export var PROFILESETTINGS_MYFOLLOWERS = "PROFILESETTINGS_MYFOLLOWERS";
export var PROFILESETTINGS_MYFOLLOWERS_CURRENT =
  "PROFILESETTINGS_MYFOLLOWERS_CURRENT";
export var IS_NEGO_SLIDEOVERS_MODAL_OPEN = "IS_NEGO_SLIDEOVERS_MODAL_OPEN";
export var PROFILESETTINGS_MYFOLLOWERS_CURRENT_USERDATA =
  "PROFILESETTINGS_MYFOLLOWERS_CURRENT_USERDATA";
export var PROFILESETTINGS_CURUSER_SHAREDWISHLIST =
  "PROFILESETTINGS_CURUSER_SHAREDWISHLIST";
export var IS_STOREINFO_MAP_MODAL_OPEN = "IS_STOREINFO_MAP_MODAL_OPEN";

export var PROFILESETTINGS_STOREDETAILS = "PROFILESETTINGS_STOREDETAILS";
export var PROFILESETTINGS_STORECONTACTS = "PROFILESETTINGS_STORECONTACTS";

export var MYPROFILE = "MYPROFILE";

export var IS_PROFILEINFO_COMMON_MODAL_OPEN =
  "IS_PROFILEINFO_COMMON_MODAL_OPEN";
export var PROFILEINFO_SELECTED_GENDER = "PROFILEINFO_SELECTED_GENDER";
export var ACTSETTINGS_COUNTRIES = "ACTSETTINGS_COUNTRIES";
export var CAN_RECEIVED_NOTIFICATION = "CAN_RECEIVED_NOTIFICATION";
export var USER_NOTIFICATION_SETTINGS = "USER_NOTIFICATION_SETTINGS";
export var EDIT_ADDRESS = "EDIT_ADDRESS";
export var USER_ACCOUNT = "USER_ACCOUNT";

/**** On Click Select Profile Settings Menu ****/
export function selectedProfileSettingsMenu(session, selectedMenu) {
  return (dispatch) => {
    dispatch({ type: SELECTED_PROFILESETTINGS_MENU, payload: selectedMenu });
  };
}

/**** Fetch My Followers *****/
export function fetchMyFollowers(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users_followers?related=*&fields=*&filter=userid=${session.userid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PROFILESETTINGS_MYFOLLOWERS,
          payload: { data: response.resource },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/***** Is Followers Deatls Modal open or not *****/
export function isFollowersDetailsOpen(isOpen) {
  return { type: IS_NEGO_SLIDEOVERS_MODAL_OPEN, payload: isOpen };
}

/**** Fetch Selected Followrs Details ****/
export function selectedFollowersDetails(session, followerid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/userfollowers`;
  //var url = `${INSTANCE_URL}/api/v2/shopez/_table/users_followers?related=*&fields=*&filter=userid=${followerid}`;

  return (dispatch) => {
    postRequest(
      url,
      {
        profileid: followerid,
      },
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PROFILESETTINGS_MYFOLLOWERS_CURRENT,
          payload: { data: response.followers },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Fetch Current Selected Follower User Deatils ****/
export function selectedFollowerUserDetails(session, followerid, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?&filter=userid=${followerid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PROFILESETTINGS_MYFOLLOWERS_CURRENT_USERDATA,
          payload: { data: response.resource },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Selected Followers Sheared Wishlist ****/
export function selectedFollowersShearedWishlist(
  session,
  followerid,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/usersharedwishlists`;

  return (dispatch) => {
    postRequest(
      url,
      {
        profileid: followerid,
      },
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PROFILESETTINGS_CURUSER_SHAREDWISHLIST,
          payload: { data: response.data },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Follow/Unfollow ****/
export function setFollowunfollow(session, followerid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/followunfollow`;

  return (dispatch) => {
    postRequest(
      url,
      {
        followeduser: followerid,
      },
      session,
      dispatch,
      (response) => {
        //  dispatch({

        //   type: PROFILESETTINGS_CURUSER_SHAREDWISHLIST,
        //   payload: { data: response.data },

        // });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Fetch My Following List ****/

export function fetchMyFolloings(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/userfollowers`;

  return (dispatch) => {
    postRequest(
      url,
      {
        profileid: session.userid,
      },
      session,
      dispatch,
      (response) => {
        //  dispatch({

        //   type: PROFILESETTINGS_CURUSER_SHAREDWISHLIST,
        //   payload: { data: response.data },

        // });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/****** STORE INFORMATION ******/

/**** fetch store details ****/
export function fetchMyProfile(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?filter=userid=${session.userid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        if (response.resource.length > 0) {
          let storeid = response.resource[0].storeid;
          dispatch({
            type: PROFILESETTINGS_STOREDETAILS,
            payload: { data: response.resource[0] },
          });
          dispatch(setSelectedGender(response.resource[0].gender));
          dispatch(
            setReceivedNotification(response.resource[0].canreceivenotification)
          );
          // Call Contacts deytails (storeid)
          // dispatch(fetchStoreContacts(session, storeid));
        }

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        console.log("error", error);
        callback({ status: 0, message: LocaleStrings.common_fail_message });
      }
    );
  };
}

/****  Fetch Contacts ****/
export function fetchStoreContacts(session, storeid) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/store_contacts?filter=storeid=${storeid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PROFILESETTINGS_STORECONTACTS,
          payload: { data: response.resource[0] },
        });

        ///callback({ status: 1 ,message: LocaleStrings.api_success_status});
      },
      (error) => {
        //callback({ status: 0 ,message: LocaleStrings.common_fail_message});
      }
    );
  };
}

export function uploadData(session, data, callback) {
  return { allgood: "yes" };
}

/**** Upload store Media ****/

export function uploadMediaLarge(session, uploadFiles, callback) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.name;
  var filetype = uploadFiles.type;

  const dataMedia = new FormData();
  if (filetype == "video") {
    var blob = new Blob([filecontent], { type: "video/mp4" });
  } else if (filetype == "audio") {
    var blob = new Blob([filecontent], { type: "audio/mp3" });
  } else {
    var blob = new Blob([filecontent], { type: "image/jpeg" });
  }

  dataMedia.append("files", blob, filename);

  return (dispatch) => {
    uploadEncodedFileLarge(
      `${FILES_URL}/store/`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        callback({ success: 1, message: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

/*** ADD Store Data ***/
export function addStore(session, object, objcontact, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/store`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        objcontact.storeid = response.resource[0].storeid;

        // Store Contacts ()
        dispatch(addStoreContacts(session, objcontact, (innercallback) => {}));

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        console.log("error", error);
        callback({ status: 0, message: LocaleStrings.common_fail_message });
      }
    );
  };
}

/**** Update Store Data ****/
export function updateStore(session, object, objcontact, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/store`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        // Store Contacts ()
        dispatch(
          updateStoreContacts(session, objcontact, (innercallback) => {})
        );

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        console.log("error", error);
        callback({ status: 0, message: LocaleStrings.common_fail_message });
      }
    );
  };
}

/**** Update Store Contact Data ****/
export function updateStoreContacts(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/store_contacts`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.common_fail_message });
      }
    );
  };
}
/**** Update Store Contact Data ****/
export function addStoreContacts(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/store_contacts`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.common_fail_message });
      }
    );
  };
}

/***** Is Store Info Map Modal open or not *****/
export function isStoreInfoMapModalOpen(isOpen) {
  return { type: IS_STOREINFO_MAP_MODAL_OPEN, payload: isOpen };
}

/* Store Verfication */

/**** User Details (users atble) with session Id ****/
export function fetchUsersDeatils(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?filter=userid=${session.userid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        dispatch({
          type: MYPROFILE,
          payload: { data: response.resource[0] },
        });
        dispatch({
          type: USER_NAME,
          payload: response.resource[0].username,
        });

        dispatch({
          type: PHONE_VERIFIED,
          payload: response.resource[0].phoneverified,
        });

        dispatch({
          type: EMAIL_VERIFIED,
          payload: response.resource[0].emailverified,
        });

        callback({ status: 1 });
      },
      (error) => {
        callback({ status: 0 });
      }
    );
  };
}

/**** Upload store Media ****/

export function uploadMediaLargeStoreVerfic(session, uploadFiles, callback) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.name;
  var filetype = uploadFiles.type;

  const dataMedia = new FormData();
  if (filetype == "video") {
    var blob = new Blob([filecontent], { type: "video/mp4" });
  } else if (filetype == "audio") {
    var blob = new Blob([filecontent], { type: "audio/mp3" });
  } else if (filetype == "image") {
    var blob = new Blob([filecontent], { type: "image/jpeg" });
  } else {
    var blob = new Blob([filecontent], { type: filetype });
  }

  dataMedia.append("files", blob, filename);

  return (dispatch) => {
    uploadEncodedFileLarge(
      `${FILES_URL}/store/`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        callback({ success: 1, message: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

/**** Store Users Updated ****/
export function updateStoreUsers(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users/${session.userid}`;
  // Actual Data Call
  return (dispatch) => {
    patchRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ status: 1 });
      },
      (error) => {
        callback({ status: 0 });
      }
    );
  };
}

/* Profile Information */

export function uploadMediaLargeProfileInfo(session, uploadFiles, callback) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.name;
  var filetype = uploadFiles.type;

  const dataMedia = new FormData();
  if (filetype == "video") {
    var blob = new Blob([filecontent], { type: "video/mp4" });
  } else if (filetype == "audio") {
    var blob = new Blob([filecontent], { type: "audio/mp3" });
  } else if (filetype == "image") {
    var blob = new Blob([filecontent], { type: "image/jpg" });
  } else {
    var blob = new Blob([filecontent], { type: filetype });
  }

  dataMedia.append("files", blob, filename);

  return (dispatch) => {
    uploadEncodedFileLarge(
      `${FILES_URL}/users/`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        callback({ success: 1, message: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

/***** Is PROFILE INFORMATION COMMON Modal open or not *****/
export function isProfileinfoCommonOpen(isOpen) {
  return { type: IS_PROFILEINFO_COMMON_MODAL_OPEN, payload: isOpen };
}

/**** MAKE GENDER SELECTED ****/
export function setSelectedGender(gender) {
  return { type: PROFILEINFO_SELECTED_GENDER, payload: { data: gender } };
}

export function setReceivedNotification(can) {
  return { type: CAN_RECEIVED_NOTIFICATION, payload: { data: can } };
}

// ACCOUNT SETTINGS

/**** Fetch Contries ****/
export function fetchCountries(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/country?order=name%20ASC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: ACTSETTINGS_COUNTRIES,
          payload: { data: response.resource },
        });

        callback({ status: 1, message: LocaleStrings.api_success_status });
      },
      (error) => {
        callback({ status: 0, message: LocaleStrings.common_fail_message });
      }
    );
  };
}

export function sendSms(session, phone, resend, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendsms/sendotp`;
  var params = { mobile: `${phone}`, resend: resend };
  return (dispatch) => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.success && response.success == "fail") {
          callback({ success: 0, message: response.message });
        } else {
          callback({ success: 1 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function verifyOtp(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendsms/verifyotp`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        callback({ success: response.success ? 1 : 0 });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function changePassword(session, values, callback) {
  //var url = `${INSTANCE_URL}/api/v2/user/password`;
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/resetpassword`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);

        if (response.result == "success") {
          var returnData = {
            success: 1,
            data: response.message,
          };
          callback(returnData);
        } else {
          var returnData = {
            success: 0,
            data: response.message,
          };
          callback(returnData);
        }
      },
      (error) => {
        //console.log("error", error);
        var returnData = { success: 0, data: error.message };
        callback(returnData);
      }
    );
  };
}

export function fetchNotificationSetting(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/user_notifications_settings?filter=userid=${session.userid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: USER_NOTIFICATION_SETTINGS,
          payload: { data: response.resource[0] },
        });

        callback({ status: 1 });
      },
      (error) => {
        //callback({ status: 0 ,message: LocaleStrings.common_fail_message});
      }
    );
  };
}

export function updateNotificationSetting(session, object, callback) {
  var filter = encodeURI(`(userid=${session.userid})`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/user_notifications_settings?filter=(${filter})`;
  var body = {
    resource: [object],
  };
  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ status: 1 });
      },
      (error) => {
        callback({ status: 0 });
      }
    );
  };
}

export function deleteAddress(session, addressid, callback) {
  var deleteUrl = `${INSTANCE_URL}/api/v2/shopez/_table/user_address/${addressid}`;
  return (dispatch) => {
    deleteRequest(
      deleteUrl,
      session,
      dispatch,
      (delres) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function addAddress(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/user_address`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (
          response.resource &&
          response.resource.length > 0 &&
          response.resource[0].id
        ) {
          callback({ success: 1, addressid: response.resource[0].id });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        console.log("error", error);
        callback({ status: 0 });
      }
    );
  };
}

export function editUserAddress(obj) {
  return { type: EDIT_ADDRESS, payload: obj };
}

export function updateAddress(session, values, id, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/user_address/${id}`;

  return (dispatch) => {
    patchRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function fetchAccount(session, callback) {
  var filter = encodeURI(`(useridfk=${session.userid})`);

  var url = `${INSTANCE_URL}/api/v2/shopez/_table/user_account?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: USER_ACCOUNT,
          payload: { data: response.resource },
        });

        callback({ status: 1 });
      },
      (error) => {
        callback({ status: 0 });
      }
    );
  };
}

export function addAccount(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/user_account`;
  let body = { resource: object };
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (
          response.resource &&
          response.resource.length > 0 &&
          response.resource[0].id
        ) {
          callback({ success: 1, accountid: response.resource[0].id });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //console.log("error", error);
        callback({ status: 0 });
      }
    );
  };
}

export function updateAccount(session, values, id, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/user_account/${id}`;

  return (dispatch) => {
    patchRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function sendEmailOtp(session, email, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendemail`;
  var params = { email: `${email}`, emailtype: "sendotp" };
  return (dispatch) => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.success && response.success == "fail") {
          callback({ success: 0, message: response.message });
        } else {
          callback({ success: 1 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function verifyEmailOtp(session, otp, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendemail`;
  var params = { code: otp, emailtype: "verifyotp" };
  return (dispatch) => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        callback({ success: response.success ? 1 : 0 });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}
