import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import Loader from "../../../components/Common/loader";
import { classNames, timeAgo } from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";

// Import Actions
import {
  isNotificationModalOpen,
  fetchNotificationDetails,
  markallread,
  isSendMessageModalOpen,
  markRead,
  fetchProductByQa,
  notificationMessageThread,
  fetchProductByComment,
  fetchBidDetails,
} from "../actions/index";
import {
  isNegotiationOpen,
  negotiationThread,
} from "../../Negotiation/actions";
import {
  negotiationDetailsAction,
  questionListByProduct,
  isQuestionlistModalOpen,
  isSendMessageStoreOpen,
  fetchMessageList,
  isCommentModalOpen,
} from "../../Product/actions";
import NotificationNodata from "../../../assets/images/notification-nodata.svg";
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import toast, { Toaster } from "react-hot-toast";

//import NoContentSlideOver from '../../../../common/no-content-slideover'; // Common No Content For Slide Over Modals
//import ViewComments1 from "../../productmanagement/components/productdetails/components/viewcomments1"; // Import Component From [Product Deatils Page To Show Mension Comments]
//import QaWithOutLimit1 from "../../productmanagement/components/productdetails/components/qawithoutlimit1"; // Import Component From [Product Deatils Page To Show Mension Comments]

class Notification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_sales_notifications,
    };

    // All Binded Functions
    this.closeSlideOvers = this.closeSlideOvers.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      setInterval(() => {
        if (this.props.isNotificationOpen) {
          this.props.fetchNotificationDetails(
            this.props.session,
            (callBack) => {
              this.setState({ loading: false });
            }
          );
        }
      }, 1500);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**** Close Slide Over  ****/
  closeSlideOvers() {
    this.props.isNotificationModalOpen(false);
  }

  _handelRedirectNotification = (e, obj) => {
    //console.log({ obj });
    if (
      obj.notifications_by_notificationid.notificationtype === "negotiation"
    ) {
      let negotiationthreadid =
        obj.notifications_by_notificationid.negotiationthreadid;

      // this.props.negotiationDetailsAction(
      //   this.props.session,
      //   negotiationthreadid,
      //   (res) => {}
      // );
      // this.props.negotiationThread(negotiationthreadid);
      // this.props.isNegotiationOpen(true);
      this.props.markRead(this.props.session, obj.notificationid, (res) => {
        this.closeSlideOvers();
        //this.props.history.push("/negotiation");
        window.open("/negotiation/" + negotiationthreadid, "_blank");
      });
    } else if (
      obj.notifications_by_notificationid.notificationtype === "newmessage"
    ) {
      let threadid = obj.notifications_by_notificationid.threadid;
      this.props.notificationMessageThread(threadid);
      this.props.markRead(this.props.session, obj.notificationid, (res) => {});
      this.props.fetchMessageList(
        this.props.session,
        parseInt(threadid),
        (res) => {
          if (res.success == 1 && res.data && res.data.productdetails) {
            let productid =
              _.kebabCase(res.data.productdetails.productname_en) +
              "-" +
              res.data.productid;
            this.closeSlideOvers(); // Close SlideOver

            this.props.isSendMessageStoreOpen(true);
            window.open("/product-details/" + productid + "/message", "_blank");
          }
        }
      );
    } else if (
      obj.notifications_by_notificationid.notificationtype === "mention"
    ) {
      let commentid = obj.notifications_by_notificationid.commentid;
      this.props.fetchProductByComment(this.props.session, commentid, (res) => {
        //console.log("res", res);
        if (res.status == 1 && res.data && res.data.length > 0) {
          let productid =
            _.kebabCase(res.data[0].products_by_productid.productname_en) +
            "-" +
            res.data[0].productid;
          this.props.isCommentModalOpen(true);
          this.props.markRead(
            this.props.session,
            obj.notificationid,
            (res) => {}
          );
          //this.props.history.push("/product-details/" + productid);
          this.closeSlideOvers(); // Close SlideOver
          window.open("/product-details/" + productid + "/message", "_blank");
        }
      });

      this.closeSlideOvers(); // Close SlideOver
    } else if (
      obj.notifications_by_notificationid.notificationtype === "answer"
    ) {
      let qaid = obj.notifications_by_notificationid.qaid;
      this.props.fetchProductByQa(this.props.session, qaid, (res) => {
        if (res.status == 1 && res.data && res.data.id) {
          let productid =
            _.kebabCase(res.data.productname_en) + "-" + res.data.id;

          this.props.questionListByProduct(
            this.props.session,
            res.data.id,
            (res) => {}
          );
          this.props.isQuestionlistModalOpen(true);
          this.props.markRead(
            this.props.session,
            obj.notificationid,
            (res) => {}
          );
          //this.props.history.push("/product-details/" + productid);
          this.closeSlideOvers(); // Close SlideOver
          window.open("/product-details/" + productid, "_blank");
        }
      });
    } else if (obj.notificationtype === "admin") {
      this.props.markRead(this.props.session, obj.notificationid, (res) => {});
    } else if (
      obj.notifications_by_notificationid.notificationtype === "banproduct"
    ) {
      this.props.markRead(this.props.session, obj.notificationid, (res) => {
        this.closeSlideOvers();
        window.open(
          "/product-details/" +
            "banproduct-" +
            obj.notifications_by_notificationid.productid,
          "_blank"
        );
      });
    } else if (
      obj.notifications_by_notificationid.notificationtype === "orderupdate"
    ) {
      let orderdetailid = obj.notifications_by_notificationid.orderdetailid;
      this.props.markRead(this.props.session, obj.notificationid, (res) => {
        this.closeSlideOvers(); // Close SlideOver
        window.open("/order-details/" + orderdetailid, "_blank");
      });
    } else if (
      obj.notifications_by_notificationid.notificationtype === "instock"
    ) {
      this.props.markRead(this.props.session, obj.notificationid, (res) => {
        this.closeSlideOvers(); // Close SlideOver
      });
    } else if (
      obj.notifications_by_notificationid.notificationtype === "following"
    ) {
      this.props.markRead(this.props.session, obj.notificationid, (res) => {
        this.closeSlideOvers(); // Close SlideOver
        window.open("/activity", "_blank");
      });
    } else if (obj.notifications_by_notificationid.notificationtype === "bid") {
      let bidid = obj.notifications_by_notificationid.bidid;
      this.props.fetchBidDetails(this.props.session, bidid, (res) => {
        if (res.status == 1 && res.data && res.data.length > 0) {
          //console.log("res.data", res.data);
          let productid =
            _.kebabCase(res.data[0].products_by_productid.productname_en) +
            "-" +
            res.data[0].productid +
            "-" +
            res.data[0].variantid;

          this.props.markRead(
            this.props.session,
            obj.notificationid,
            (res) => {}
          );

          this.closeSlideOvers(); // Close SlideOver
          window.open("/product-details/" + productid + "/bid", "_blank");
        }
      });
    } else if (
      obj.notifications_by_notificationid.notificationtype === "cancelbid"
    ) {
      let bidid = obj.notifications_by_notificationid.bidid;
      this.props.fetchBidDetails(this.props.session, bidid, (res) => {
        if (res.status == 1 && res.data && res.data.length > 0) {
          //console.log("res.data", res.data);
          let productid =
            _.kebabCase(res.data[0].products_by_productid.productname_en) +
            "-" +
            res.data[0].productid +
            "-" +
            res.data[0].variantid;

          this.props.markRead(
            this.props.session,
            obj.notificationid,
            (res) => {}
          );

          this.closeSlideOvers(); // Close SlideOver
          window.open("/product-details/" + productid, "_blank");
        }
      });
    } else if (
      obj.notifications_by_notificationid.notificationtype ===
      "wonbids_not_purchased"
    ) {
      let bidid = obj.notifications_by_notificationid.bidid;
      this.props.fetchBidDetails(this.props.session, bidid, (res) => {
        if (res.status == 1 && res.data && res.data.length > 0) {
          //console.log("res.data", res.data);
          let productid =
            _.kebabCase(res.data[0].products_by_productid.productname_en) +
            "-" +
            res.data[0].productid +
            "-" +
            res.data[0].variantid;

          this.props.markRead(
            this.props.session,
            obj.notificationid,
            (res) => {}
          );
          //this.props.history.push("/product-details/" + productid);
          this.closeSlideOvers(); // Close SlideOver
          window.open("/product-details/" + productid + "/bid", "_blank");
        }
      });
    } else if (
      obj.notifications_by_notificationid.notificationtype === "auctionupdate"
    ) {
      let bidid = obj.notifications_by_notificationid.bidid;
      this.props.fetchBidDetails(this.props.session, bidid, (res) => {
        if (res.status == 1 && res.data && res.data.length > 0) {
          //console.log("res.data", res.data);
          let productid =
            _.kebabCase(res.data[0].products_by_productid.productname_en) +
            "-" +
            res.data[0].productid +
            "-" +
            res.data[0].variantid;

          this.props.markRead(
            this.props.session,
            obj.notificationid,
            (res) => {}
          );
          //this.props.history.push("/product-details/" + productid);
          this.closeSlideOvers(); // Close SlideOver
          window.open("/product-details/" + productid + "/bid", "_blank");
        }
      });
    } else if (
      obj.notifications_by_notificationid.notificationtype === "approveduser"
    ) {
      this.props.markRead(this.props.session, obj.notificationid, (res) => {});
    }
  };

  // Arrow Function for Notification List
  renderNotificationList = (notificationList) => {
    let { localLanguage } = this.props;
    var item = _.map(notificationList, (valObj, index) => {
      let title = valObj?.notifications_by_notificationid?.notificationtype;
      let replaced_title = title.replace(/_/g, " ");
      return (
        <li
          key={index}
          className="py-1 bg-white px-4 cursor-pointer table-hover-transition hover:bg-quaternary"
          onClick={(e) => this._handelRedirectNotification(e, valObj)}
        >
          <div className="flex items-center space-x-4">
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-secondary  capitalize">
                {replaced_title} {LocaleStrings.update}
              </p>

              {valObj.notifications_by_notificationid.notificationtype ==
              "admin" ? (
                <div className="flex">
                  <p className="text-sm text-ternary ">
                    {ProductTextLocalized(
                      valObj.notifications_by_notificationid
                        .notificationtitle_en,
                      valObj.notifications_by_notificationid
                        .notificationtitle_ar,
                      localLanguage
                    )}
                  </p>
                </div>
              ) : (
                ""
              )}

              <div className="flex">
                <p className="text-sm text-ternary ">
                  {ProductTextLocalized(
                    valObj.notifications_by_notificationid.notificationtext_en,
                    valObj.notifications_by_notificationid.notificationtext_ar,
                    localLanguage
                  )}{" "}
                </p>
                <div
                  className={classNames(
                    valObj.isread === false ? "" : "hidden",
                    "flex-shrink-0 w-2.5 h-2.5 rounded-full bg-yellow-500 mx-2 mt-1.5"
                  )}
                  aria-hidden="true"
                ></div>
              </div>
            </div>
            <div className="flex-shrink-0 text-sm text-ternary">
              {timeAgo(valObj.notifications_by_notificationid.createdat)}
            </div>
          </div>
        </li>
      );
    });

    return item;
  };

  //**** Mark Read All Notification ****/
  _handelReadAllNotification(e) {
    let { session } = this.props;
    this.props.markallread(session, (callBack) => {
      toast.success(LocaleStrings.mark_all_messages_as_read);
    });
  }

  render() {
    let { isNotificationOpen, localLanguage, notificationList } = this.props;
    let { loading } = this.state;

    return (
      <>
        <Transition.Root show={isNotificationOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 overflow-hidden z-50"
            open={isNotificationOpen}
            onClose={this.closeSlideOvers}
          >
            <div className="absolute inset-0 overflow-hidden">
              <Dialog.Overlay className="absolute inset-0" />

              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="w-screen max-w-md">
                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                      <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="slider-modal-headtext">
                              {LocaleStrings.notifications}
                              <span
                                className="mx-2 cursor-pointer"
                                onClick={(e) =>
                                  this._handelReadAllNotification(e)
                                }
                              >
                                ({LocaleStrings.read_all})
                              </span>
                            </Dialog.Title>
                            <div className="ml-3 h-7 flex items-center">
                              <button
                                type="button"
                                onClick={this.closeSlideOvers}
                                className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="mt-6 relative flex-1 px-4 sm:px-6">
                          <ul className="divide-y divide-gray-100">
                            {!loading ? (
                              <>
                                {_.isEmpty(notificationList) ? (
                                  <div className="flex h-cardimg items-center justify-center pt-5">
                                    <div>
                                      <div className="flex items-center justify-center pb-5">
                                        <img
                                          src={NotificationNodata}
                                          className="w-12"
                                        />
                                      </div>
                                      <div className="flex items-center justify-center text-cutoff text-base font-medium">
                                        {LocaleStrings.no_notification_header}
                                      </div>
                                      <div className="flex items-center justify-center text-cutoff text-sm">
                                        {
                                          LocaleStrings.no_notification_subheader
                                        }
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  this.renderNotificationList(notificationList)
                                )}
                              </>
                            ) : (
                              <Loader />
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="flex-shrink-0 px-4 py-4 flex justify-end"></div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        {/* View Comments */}
        {this.props.isCommentsNotificOpen ? (
          <>
            {/* <ViewComments1
              productIDNoti={
                this.props.notificationDetailsStore.commentdetails.productid
              }
            /> */}
          </>
        ) : (
          ""
        )}
        {/* Quation and answer */}
        {this.props.isQaNotificOpen ? (
          <>
            {/* <QaWithOutLimit1
              productID={this.props.notificationDetailsStore.qadata.productid}
            /> */}
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    localLanguage: state.localLanguage,
    isNotificationOpen: state.isNotificationOpen,
    isCommentsNotificOpen: state.isCommentsNotificOpen,
    isQaNotificOpen: state.isQaNotificOpen,
    notificationList: state.notificationList
      ? state.notificationList.data
        ? state.notificationList.data
        : {}
      : {},
    notificationDetailsStore: state.notificationDetailsStore
      ? state.notificationDetailsStore.data
        ? state.notificationDetailsStore.data
        : []
      : [],
    notifiSingleObjectStore: _.isEmpty(state.notifiSingleObjectStore)
      ? []
      : state.notifiSingleObjectStore,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    isNotificationModalOpen,
    fetchNotificationDetails,
    markallread,
    isSendMessageModalOpen,
    markRead,
    isNegotiationOpen,
    negotiationThread,
    negotiationDetailsAction,
    fetchProductByQa,
    questionListByProduct,
    isQuestionlistModalOpen,
    notificationMessageThread,
    isSendMessageStoreOpen,
    fetchMessageList,
    fetchProductByComment,
    isCommentModalOpen,
    fetchBidDetails,
  })(Notification)
);
