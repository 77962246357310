import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import YouNodata from "../../../assets/images/you-nodat.svg";
import NoImg from "../../../assets/images/noimg.png";
import {
  emptyProfileUser,
  profileModalOpen,
  emptySharedWishlist,
  selectedFollowerUserDetails,
  selectedFollowersDetails,
  selectedFollowersShearedWishlist,
  profileViewNavigation,
  viewPublicProfile,
} from "../actions";
import { openReportModal, followUnfollow } from "../../Product/actions";
import SharedWishlist from "./shared-wishlist";
import FollowingList from "./following-list";
import { Transition, Dialog, Menu } from "@headlessui/react";
import {
  XIcon,
  QuestionMarkCircleIcon,
  DotsHorizontalIcon,
} from "@heroicons/react/outline";
import { confirmAlert } from "react-confirm-alert";
import { ArrowLeftIcon } from "@heroicons/react/outline";

function ProfileView(props) {
  const history = useHistory();
  const {
    session,
    isLoggedIn,
    profileFollower,
    profileSharedWishlist,
    profileFollowerDetails,
    profileModal,
    myprofile,
    reportAbuseModal,
    navigateUser,
    publicProfile,
    checkFollowUnfollow,
  } = props;
  const cancelButtonRef1 = useRef(null);
  const [currentTab, setCurrenTab] = useState("shared");
  const [userNavigation, setUserNavigation] = useState([]);
  const [followLoader, setFollowLoader] = useState(false);
  const tabArr = [
    { key: "shared", text: LocaleStrings.shared_wishlist_tab },
    { key: "following", text: LocaleStrings.following_tab },
  ];

  const closeModal = () => {
    props.emptyProfileUser();
    props.profileModalOpen(false);
    props.profileViewNavigation([]);
  };

  const previousView = () => {
    // console.log("navigateUser", navigateUser);
    let userArr = navigateUser;
    if (userArr.length >= 1) {
      // console.log("length", userArr.length);
      let userid = _.nth(userArr, -2);
      //console.log("userid", userid);
      props.emptySharedWishlist();
      props.selectedFollowerUserDetails(session, userid, (callback) => {});
      props.selectedFollowersDetails(session, userid, (callback) => {});
      props.selectedFollowersShearedWishlist(session, userid, (callback) => {});
      let newArr = userArr.filter((el) => el !== userid);
      props.profileViewNavigation(newArr);
    }
  };

  const _handleDateTab = (e, key) => {
    setCurrenTab(key);
  };

  const _handleFollowUnfollow = (followerid) => {
    props.setFollowunfollow(this.props.session, followerid, (callback) => {
      if (callback.status) {
        this.props.selectedFollowerUserDetails(
          this.props.session,
          followerid,
          (callback) => {}
        );

        this.props.selectedFollowersDetails(
          this.props.session,
          followerid,
          (callback) => {}
        );
      }
    });
  };

  let modalHeadText = "";
  let modalFooterText = "";
  let productImageUrl = "";
  let followerName = "";
  let userid = "";
  let followbtn = false;
  if (
    profileFollowerDetails &&
    profileFollowerDetails.data &&
    profileFollowerDetails.data.length > 0
  ) {
    let valueObject = profileFollowerDetails.data[0];

    modalHeadText =
      valueObject.userid == session.userid
        ? LocaleStrings.activity_you
        : valueObject.username + "'s Profile";
    productImageUrl = `${FILES_URL}/users/${valueObject.userid}.jpg?api_key=${APP_API_KEY}`;
    followerName = valueObject.username;
    userid = valueObject.userid;
    followbtn = valueObject.userid == session.userid ? false : true;
  }

  //console.log("profileFollower", profileFollower);
  //console.log("profileSharedWishlist", profileSharedWishlist);
  //console.log("profileFollowerDetails", profileFollowerDetails);
  const reportAbusive = (userid) => {
    if (isLoggedIn) {
      confirmAlert({
        title: LocaleStrings.report_confirm_consumer_title,
        message: LocaleStrings.report_confirm_consumer_message,
        buttons: [
          {
            label: LocaleStrings.button_yes,
            onClick: () => {
              props.openReportModal(true, userid, "consumer");
            },
          },
          {
            label: LocaleStrings.button_no,
            onClick: () => {},
          },
        ],
      });
    }
  };

  const followUnfollow = (userid) => {
    if (isLoggedIn) {
      setFollowLoader(true);
      props.followUnfollow(session, parseInt(userid), (res) => {
        if (res.success == 1) {
          if (checkFollowUnfollow) {
            checkFollowUnfollow();
          }
          props.viewPublicProfile(session, userid, (res) => {
            setFollowLoader(false);
          });
        } else {
          setFollowLoader(false);
        }
      });
    } else {
      history.push("/login");
    }
  };
  //console.log("publicProfile", publicProfile);
  return (
    <>
      <Transition.Root
        show={profileModal && profileModal.showModal}
        as={Fragment}
      >
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef1}
          open={profileModal && profileModal.showModal}
          onClose={() => closeModal()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                style={{ padding: 0 }}
              >
                {/* MOdal Header */}
                <div className="grid grid-cols-4 border-b-2 border-quaternary pb-4 sticky-header z-10">
                  <div className="flex flex-wrap items-center content-center justify-center mt-4">
                    {navigateUser.length > 1 ? (
                      <ArrowLeftIcon
                        className="h-6 w-6 cursor-pointer"
                        aria-hidden="true"
                        onClick={() => previousView()}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-span-2 flex flex-wrap content-center justify-center mt-4">
                    <div className="text-lg leading-6 font-medium text-ads">
                      {modalHeadText}
                    </div>
                  </div>
                  <div className="absolute top-0 right-0 pt-4 pr-4 ">
                    <button
                      type="button"
                      className="btn-closeModal focus:outline-none"
                      onClick={() => closeModal()}
                      ref={cancelButtonRef1}
                    >
                      <span className="sr-only">{LocaleStrings.close}</span>
                      <XIcon className="h-6 w-6 " aria-hidden="true" />
                    </button>
                  </div>
                </div>
                {/* Modal Body */}
                <div className="bg-white">
                  <div className="max-w-7xl mx-auto py-2 px-4 text-center sm:px-6 lg:px-8 lg:py-2">
                    <div className="space-y-12">
                      {profileFollowerDetails && profileFollowerDetails.data ? (
                        profileFollowerDetails.data.length > 0 ? (
                          <div>
                            {userid != session.userid ? (
                              <div className="flex justify-end items-end">
                                <Menu
                                  as="div"
                                  className="relative inline-block text-left"
                                >
                                  {({ open }) => (
                                    <>
                                      <div className="">
                                        <Menu.Button className="btn-card">
                                          <span className="sr-only">
                                            Open options
                                          </span>
                                          <DotsHorizontalIcon
                                            className="h-5 w-5 hover:shadow"
                                            aria-hidden="true"
                                          />
                                        </Menu.Button>
                                      </div>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                      >
                                        <Menu.Items
                                          static
                                          className="origin-top-right absolute right-0 mt-2 w-148 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer z-50"
                                        >
                                          <div className="py-1 divide-y z-50">
                                            <Menu.Item>
                                              {({ active }) => (
                                                <a
                                                  onClick={() =>
                                                    reportAbusive(userid)
                                                  }
                                                  className={classNames(
                                                    active
                                                      ? "bg-quaternary "
                                                      : " ",
                                                    "block px-4 py-2 text-sm text-primary"
                                                  )}
                                                >
                                                  {
                                                    LocaleStrings.report_as_abusive
                                                  }
                                                </a>
                                              )}
                                            </Menu.Item>
                                          </div>
                                        </Menu.Items>
                                      </Transition>
                                    </>
                                  )}
                                </Menu>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                              <img
                                className="mx-auto h-20 w-20 rounded-full xl:w-40 xl:h-40"
                                src={productImageUrl}
                                alt=""
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = NoImg;
                                }}
                              />
                              <p className="text-xl text-primary">
                                {followerName}
                              </p>
                            </div>
                            {followbtn && publicProfile?.data?.length > 0 ? (
                              <div className="mt-5">
                                <button
                                  className="pt-2.5 pb-2.5 pl-9 pr-9  bg-btn text-white rounded font-normal text-sm inline-flex items-center justify-center"
                                  onClick={() =>
                                    followUnfollow(publicProfile.data[0].userid)
                                  }
                                >
                                  {followLoader ? (
                                    <svg
                                      className="animate-spin h-5 w-5 rounded-full mr-3"
                                      viewBox="0 0 1024 1024"
                                      focusable="false"
                                      data-icon="loading"
                                      width="1em"
                                      height="1em"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                    </svg>
                                  ) : publicProfile.data[0].following ? (
                                    LocaleStrings.unfollow_btn
                                  ) : (
                                    LocaleStrings.follow_btn
                                  )}
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        <div className="flex h-cardimg items-center justify-center">
                          <Loader />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Tabs */}
                {profileFollowerDetails &&
                profileFollowerDetails.data &&
                profileFollowerDetails.data.length > 0 ? (
                  <div className="mx-6 mt-2">
                    <nav
                      className="relative z-0 flex divide-x divide-gray-200"
                      aria-label="Tabs"
                    >
                      {tabArr.map((tab, tabIdx) => (
                        <a
                          key={tab.key}
                          className={classNames(
                            currentTab == tab.key
                              ? "text-primary "
                              : "text-tabinactive hover:text-primary ",
                            "relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-normal text-center hover:bg-quaternary focus:z-10 cursor-pointer"
                          )}
                          aria-current={
                            currentTab == tab.key ? "page" : undefined
                          }
                          onClick={(e) => _handleDateTab(e, tab.key)}
                        >
                          <span>{tab.text}</span>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              currentTab == tab.key
                                ? "bg-primary"
                                : "bg-transparent",
                              "absolute inset-x-0 bottom-0 h-0.5"
                            )}
                          />
                        </a>
                      ))}
                    </nav>
                  </div>
                ) : (
                  ""
                )}
                {currentTab == "shared" ? (
                  profileSharedWishlist && profileSharedWishlist.data ? (
                    profileSharedWishlist.data.length > 0 ? (
                      <SharedWishlist />
                    ) : (
                      <div className="flex h-cardimg items-center justify-center bg-white">
                        <div>
                          <div className="flex items-center justify-center pb-5">
                            <img src={YouNodata} />
                          </div>
                          <div className="flex items-center justify-center text-cutoff text-base font-normal">
                            {LocaleStrings.shared_no_data_title}
                          </div>
                          <div className="flex items-center justify-center text-cutoff text-sm font-normal">
                            {profileModal && profileModal.isyou
                              ? LocaleStrings.you_shared_no_data_desc
                              : LocaleStrings.shared_no_data_desc}
                          </div>
                        </div>
                      </div>
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {currentTab == "following" ? (
                  profileFollower && profileFollower.data ? (
                    profileFollower.data.length > 0 ? (
                      <FollowingList
                        userNavigation={userNavigation}
                        setUserNavigation={setUserNavigation}
                      />
                    ) : (
                      <div className="flex h-cardimg items-center justify-center bg-white">
                        <div>
                          <div className="flex items-center justify-center pb-5">
                            <img src={YouNodata} />
                          </div>
                          <div className="flex items-center justify-center text-cutoff text-base font-normal">
                            {LocaleStrings.following_no_data_title}
                          </div>
                          <div className="flex items-center justify-center text-cutoff text-sm font-normal">
                            {profileModal && profileModal.isyou
                              ? LocaleStrings.you_following_no_data_desc
                              : LocaleStrings.following_no_data_desc}
                          </div>
                        </div>
                      </div>
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  myFollower: state.myFollower,
  profileModal: state.profileModal,
  profileFollower: state.profileFollower,
  profileFollowerDetails: state.profileFollowerDetails,
  profileSharedWishlist: state.profileSharedWishlist,
  navigateUser: state.navigateUser,
  publicProfile: state.publicProfile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      profileModalOpen,
      emptyProfileUser,
      openReportModal,
      emptySharedWishlist,
      selectedFollowerUserDetails,
      selectedFollowersDetails,
      selectedFollowersShearedWishlist,
      profileViewNavigation,
      followUnfollow,
      viewPublicProfile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
