import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import Loader from "../../../components/Common/loader";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import NoContentSlideOver from "../../../components/Common/no-content-slideover"; // Common No Content For Slide Over Modals
import NoImg from "../../../assets/images/noimg.png";
import SendbtnLogo from "../../../assets/images/send_btn.svg";
import toast, { Toaster } from "react-hot-toast";
import {
  isSendMessageStoreOpen,
  fetchMessageList,
  emptyMessageList,
  sendMessageStore,
  fetchMessageThread,
} from "../actions";
//import { notificationMessageThread } from "../../Notification/actions";

function SendMessage(props) {
  const cancelButtonRef = useRef(null);
  const {
    session,
    isLoggedIn,
    localLanguage,
    storeMessageOpen,
    storeThreadList,
    storeMessageList,
    isMessageThread,
    sellerid,
  } = props;

  const [threadid, setThreadId] = useState("");
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [timeInterval, setTimeInterval] = useState(0);
  const [receiverName, setReceiverName] = useState("");
  const [productName, setProductName] = useState("");

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 2000);

  useEffect(() => {
    if (step == 2) {
      props.fetchMessageList(session, parseInt(threadid), (res) => {
        let product_name = "";
        let receiver_name = "";
        if (res && res.data && res.data.productdetails) {
          product_name = ProductTextLocalized(
            res.data.productdetails.productname_en,
            res.data.productdetails.productname_ar,
            localLanguage
          );
          receiver_name = res.data.receiver.username;
        }

        setReceiverName(receiver_name);
        setProductName(product_name);
      });
    }
    if (isMessageThread != "") {
      setStep(2);
      setThreadId(isMessageThread);
    }
  }, [timeInterval, step]);

  const closeSlideOvers = () => {
    //console.log("closeSlideOvers", closeSlideOvers);
    setThreadId("");
    setStep(1);
    setReceiverName("");
    setProductName("");
    props.isSendMessageStoreOpen(false);
    //props.notificationMessageThread("");
  };

  const viewMessage = (threadid) => {
    setThreadId(threadid);
    props.emptyMessageList();
    props.fetchMessageList(session, parseInt(threadid), (res) => {
      let product_name = "";
      let receiver_name = "";
      if (res && res.data && res.data.productdetails) {
        product_name = ProductTextLocalized(
          res.data.productdetails.productname_en,
          res.data.productdetails.productname_ar,
          localLanguage
        );
        receiver_name = res.data.receiver.username;
      }

      setReceiverName(receiver_name);
      setProductName(product_name);
    });
    setStep(2);
  };

  const goBack = () => {
    if (props.sellerid) {
      props.fetchMessageThread(session, props.sellerid);
    } else {
      props.fetchMessageThread(session);
    }

    setStep(1);
    setThreadId("");
  };

  const finish = () => {};

  const renderMessageList = (item) => {
    return parseInt(item.senderid) == session.userid ? (
      <div className="flex justify-end pt-3.5">
        <div>
          <div
            className="bg-btn text-white px-3 py-2 text-sm"
            style={{ borderRadius: "22px 22px 5px 22px" }}
          >
            {item.text}
          </div>
          <div className="text-timef text-xs text-right">
            {timeAgo(item.createdat)}
          </div>
        </div>
      </div>
    ) : (
      <div className="flex justify-start">
        <div className="pr-3">
          <img
            src={`${FILES_URL}/users/${item.senderid}.jpg?api_key=${APP_API_KEY}`}
            className="rounded-full w-42 h-42 object-cover"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = NoImg;
            }}
          />
          <div className="text-ternary text-xs mt-2">{receiverName}</div>
        </div>
        <div>
          <div
            className="bg-white px-3.5 py-3 text-sm"
            style={{ borderRadius: "22px 22px 22px 5px" }}
          >
            {item.text}
          </div>
          <div className="text-timef text-xs">{timeAgo(item.createdat)}</div>
        </div>
      </div>
    );
  };

  const handleMessage = (e) => {
    let msg = e.target.value;
    var regex = /^\s+$/;
    if (!msg.match(regex)) {
      setMessage(msg);
    }
  };
  const sendMessage = () => {
    if (message != "") {
      setLoading(true);
      let values = {};
      values.threadid = threadid;
      values.senderid = session.userid;
      values.type = "text";
      values.text = message;
      props.sendMessageStore(session, values, (res) => {
        setMessage("");

        //console.log("res", res);
        if (res.success == 1) {
          props.fetchMessageThread(session);
          props.emptyMessageList();
          props.fetchMessageList(session, parseInt(threadid), (res) => {
            setLoading(false);
          });
        } else {
        }
      });
    }else{
      toast.error(LocaleStrings.type_message);
      return false;
    }
  };
  //console.log("storeMessageOpen", storeMessageOpen);
  return (
    <>
      <Transition.Root show={storeMessageOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          open={storeMessageOpen}
          onClose={() => closeSlideOvers()}
          initialFocus={cancelButtonRef}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                    <div className="min-h-0 flex-1 flex flex-col pt-3 overflow-y-scroll">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="slider-modal-headtext">
                            {step == 1 ? (
                              <h2
                                id="slide-over-heading"
                                className="text-xl font-normal text-link"
                              >
                                {LocaleStrings.message_title}
                              </h2>
                            ) : (
                              <>
                                <div className="flex items-center">
                                  {isMessageThread == "" ? (
                                    <ArrowLeftIcon
                                      className="h-6 w-6 cursor-pointer text-link"
                                      onClick={() => goBack()}
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <h2
                                    id="slide-over-heading"
                                    className="pl-4 text-xl font-normal text-link"
                                  >
                                    {LocaleStrings.send_message}
                                  </h2>
                                </div>
                              </>
                            )}
                          </Dialog.Title>
                          <div
                            // ref={cancelButtonRef}
                            className="ml-3 h-7 flex items-center"
                          >
                            <button
                              onClick={() => closeSlideOvers()}
                              className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className={classNames(
                          step == 1 ? "divide-y divide-grey-100 " : " ",
                          "mt-3 relative flex-1 px-4 sm:px-6 bg-newarrival"
                        )}
                      >
                        {step == 2 ? (
                          <div className="items-center">
                            <div class="text-sm font-medium text-gray-900 --truncate mt-4">
                              {productName}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {step == 1 ? (
                          storeThreadList && storeThreadList.threads ? (
                            storeThreadList.threads.length > 0 ? (
                              _.map(storeThreadList.threads, (item, index) => {
                                return (
                                  <div
                                    className="grid grid-cols-5 mb-md pt-3 cursor-pointer"
                                    onClick={() => viewMessage(item.id)}
                                  >
                                    <div className="">
                                      <img
                                        className="w-60 object-cover rounded-md"
                                        src={`${FILES_URL}/vendor/variations/${item.productdetails.productimage}?api_key=${APP_API_KEY}`}
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-span-3">
                                      <div className="">
                                        <div className="text-primary text-base font-medium">
                                          {ProductTextLocalized(
                                            item.productdetails.productname_en,
                                            item.productdetails.productname_ar,
                                            localLanguage
                                          )}
                                        </div>
                                        {item.lastmessage ? (
                                          <div className="text-buyingtitle text-sm font-normal">
                                            {item.lastmessage}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    <div className="text-cardfooter text-xs text-right">
                                      {item.lastmessagesentat
                                        ? timeAgo(item.lastmessagesentat)
                                        : ""}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <NoContentSlideOver
                                imageprop={"../images/Message.svg"}
                                headerprop={LocaleStrings.no_message_header}
                                subheaderprop={
                                  LocaleStrings.no_message_subheader
                                }
                              />
                            )
                          ) : (
                            <div className="flex h-cardimg items-center justify-center">
                              <Loader />
                            </div>
                          )
                        ) : storeMessageList && storeMessageList.messages ? (
                          storeMessageList.messages.length > 0 ? (
                            _.map(storeMessageList.messages, (item, index) => {
                              return renderMessageList(item);
                            })
                          ) : (
                            <NoContentSlideOver
                              imageprop={"../images/Message.svg"}
                              headerprop={LocaleStrings.no_message_header}
                              subheaderprop={LocaleStrings.no_message_subheader}
                            />
                          )
                        ) : (
                          <div className="flex h-cardimg items-center justify-center">
                            <Loader />
                          </div>
                        )}
                      </div>
                    </div>
                    {step == 2 ? (
                      <div className="flex-shrink-0 px-4 py-3.5 flex relative">
                        <input
                          name="price"
                          type="text"
                          onChange={(e) => handleMessage(e)}
                          value={message}
                          placeholder={LocaleStrings.type_message}
                          className="bg-newarrival w-full rounded-full h-44 text-loginLabel text-15 pl-5 pr-12 focus:outline-none"
                        />
                        <div
                          className="absolute right-7 top-6 cursor-pointer"
                          onClick={() => sendMessage()}
                        >
                          {loading ? (
                            <svg
                              className="animate-spin h-5 w-5 rounded-full mr-3"
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                          ) : (
                            <img src={SendbtnLogo} />
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
  storeMessageOpen: state.storeMessageOpen,
  storeThreadList: state.storeThreadList,
  storeMessageList: state.storeMessageList,
  isMessageThread: state.isMessageThread,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isSendMessageStoreOpen,
      fetchMessageList,
      emptyMessageList,
      sendMessageStore,
      fetchMessageThread,
      //notificationMessageThread,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SendMessage);
