import LocalizedStrings from "react-localization";

const LocaleStrings = new LocalizedStrings({
  en: {
    btn_save: "Save",
    search_text: "What are you looking for?",
    required: "Required",
    done: "Done",
    search_not_found: "No results for",
    breadcrumb_home: "Home",
    breadcrumb_search: "Search",
    email: "Email",
    password: "Password",
    conf_password: "Confirm Password",
    signup: "Signup",
    first_name: "First Name",
    last_name: "Last Name",
    nick_name: "Nick Name",
    date_of_birth: "Date of Birth",
    phone_no: "Phone Number",
    confirm_password: "Confirm Password",
    gender: "Gender",
    signin: "Signin",
    login_btn_text: "Login",
    forgot_your_password: "Forgot password?",
    already_account_text: "Already have an account?",
    dont_have_account_text: "Don't have an account?",
    login: "Login",
    signout: "Log Out",
    logout_confirm_title: "Logout",
    logout_confirm_message: "Are you sure you want to logout?",
    invalid_user: "Invalid User!",
    button_logout: "Logout",
    invalid_email_password: "Incorrect email or, password!",
    invalid_user: "Invalid User!",
    login_form_validation_email_required: "Please enter your email address!",
    login_form_validation_password_required: "Please enter your password!",
    form_validation_first_name_required: "Please enter your first name!",
    form_validation_last_name_required: "Please enter your last name!",
    form_validation_phone_no_required: "Please enter your phone no!",
    form_validation_phone_no_valid: "Please enter valid phone no!",
    form_validation_conf_password_required: "Please enter confirm password!",
    form_validation_password_not_match:
      "Password and confirm password does not match!",
    email_password: "Enter your email & password to login",
    create_a_new_account: "Create an account",
    my_account: "My Account",
    categories: "Categories",
    subcategories: "Sub Category",
    show_all: "Show All",
    product_desc: "Product Description",
    product_overview: "Overview",
    variant: "Variant",
    shipping: "Shipping",
    product_spec: "Specification",

    variant_not_selected: "Please select a variant to replace",
    common_fail_message: "Something went wrong!",
    signup_error_already_registered: "email already registered",
    signup_success: "Account created successfully!",
    login_success: "Successfully! logged in",
    connect_social_media_text: "Connect with social media",
    signup_error_phone_already_registered: "Phoneno already registered",
    signup_heading: "Lets get started by creating a new account",

    // Forgot Password Modal
    forgot_password: "Forgot Password",
    password_reset_link_text:
      "Please enter the registered email and will send you a password reset link",
    send_reset_link: "Send Reset Link",
    close: "Close",
    forgot_password_success: "Password reset link sent successfully",
    reset_btn: "Reset",
    reset_password_heading: "Reset Password",
    reset_confirm_code: "Confirm Code",
    reset_password_success: "Password updated successfully",
    forgot_password_form_error_messages: "email id not registered with Shopez",

    // Product Details
    products: "Products",
    product_specification: "Product Specification",
    ask_question_placeholder: "Type your question",
    ask_btn: "Submit",
    ask_question_success: "Question Added",
    ask_question_form_error: "Question Required",
    question_and_answer: "Questions & Answers",
    question_and_answer_subheading: "Want to Know More About the Product?",
    ask_question_btn: "Ask a Question",
    view_all_qa_link: "View All Questions & Answers",
    all_question_text: "Question(s)",
    view_all_question_link: "Load more questions",
    no_question_found: "No question found",
    reviews: "Reviews",
    view_all_review_link: "Load more reviews",
    all_review_text: "All Reviews",
    view_all_details: "View All Details",
    reviews_txt: "Reviews",
    review_txt: "Review",
    review_all_text: "/5.0",
    review_recommeded_text: "Recommended",
    review_search_text: "Search",
    review_sortby_low_to_high: "Negative First",
    review_sortby_high_to_low: "Positive First",
    review_sortby_newest_first: "Newest First",
    sold_txt: "sold",
    wishlist_txt: "Add to Wishlist",
    wishlisted_txt: "Wishlisted",
    watchlist_txt: "Watchlist",
    watching_txt: "Watching",
    watchlist_remove_success: "Item removed from watchlist",
    watchlist_add_success: "Item added to watchlist",
    watchlist_delete_item_success: "Item deleted from Watchlist",
    product_quantity_text: "Quantity",
    wishlist_clear_success: "Item cleared from Wishlist",
    wishlist_delete_success: "Wishlist deleted",
    wishlist_delete_item_success: "Item deleted from Wishlist",

    wishlist_delete_title: "Delete Wishlist",
    wishlist_delete_msg: "Are you sure you want to delete this wishlist?",
    wishlist_clear_title: "Clear Wishlist Item",
    wishlist_clear_msg: "Are you sure you want to clear items?",
    button_yes: "Yes",
    button_no: "No",
    button_ok: "Ok",
    button_delete: "Delete",
    button_edit: "Edit",
    button_edit_profile: "Edit Profile",
    item: "Item",
    items: "Items",
    wishlist_share_success: "Wishlist shared",
    wishlist_item_delete_option: "Delete Items",
    wishlist_clear_option: "Clear Wishlist",
    wishlist_delete_option: "Delete Wishlist",
    wishlist_share_option: "Share Wishlist",
    wishlist_no_product: "There's no Product(s) added yet",
    wishlist_remove_success: "Item removed from Wishlist",
    wishlist_add_success: "Item added to Wishlist",
    wishlist_slider_title: "Add to Wishlist",
    create_new_wishlist: "Create New Wishlist",
    create_new_wishlist_name: "Wishlist Name",
    create_new_wishlist_privacy: "Privacy",
    create_new_wishlist_public: "Public",
    create_new_wishlist_private: "Private",
    create_new_wishlist_btn: "Save Wishlist",
    create_new_wishlist_name_required: "Name required",
    wishlist_name_limit_error: "30 characters only",
    category: "Category",
    sub_category: "Sub Category",
    condition: "Condition",
    make_private: "Make Private",
    wishlist_private_success: "Successfully convert to private",

    share_txt: "share",
    shop_now: "Shop now",
    similar_products: "Similar Products",
    similar_products_not_found: "No Similar Products Found",
    view_all_similar_products: "View All Similar Products",
    you_may_like_products: "Products You May Like",
    rating: "Rating",
    and: "and",
    star: "Star",
    share_heading: "Share",

    starts_in: "Starts in",
    ends_in: "Ends in",
    bid_not_started: "Bid not Started",
    bid_over: "Ended",
    expired: "Expired",
    out_of_stock: "Out of Stock",
    notify_available_text: "Notify when available",
    cancelled: "Cancelled",
    not_deliverable: "Not Deliverable",
    temporarily_unavailable: "Temporarily unavailable",

    place_a_bid: "Place a Bid",
    cancel: "Cancel",
    make_offer: "Make your offer",
    place_bid: "Place Bid",
    bid_private_text: "Keep my name anonymous",
    bid_commited_text:
      "By placing a bid you are committed to purchase the product if you win.",
    place_a_bid_price_error: "Price Required",
    place_a_bid_min_price_error:
      "Price should be equal/greater than highest bid price",
    highest_bid: "Highest Bid",
    your_last_bid: "Your Last Bid",
    starting_bid: "Starting Bid",
    bid: "Bids",
    nobid: "Bid",
    place_a_bid_commited_error: "Required",
    place_re_bid: "Re Bid",

    view_comments_btn: "Comment",
    comment: "Comments",
    likes_text: "Likes",
    like_text: "Like",

    delivery: "Delivery",
    deliverto: "Deliver To",
    deliveryby: "Delivery By",
    soldby: "Sold By",
    follower: "Followers",
    following: "Followings",
    follow_btn: "Follow",
    unfollow_btn: "Unfollow",
    send_message_btn: "Send Message",
    return_policy: "Return Policy",
    replacement_policy: "Replacement Policy",

    //Add to Cart
    add_to_cart_success: "Item added to Cart",
    choose_variant: "Choose Variants",
    selected_variant: "Selected Variant",
    add_to_cart_btn: "Add to Cart",
    slider_title: "Negotiate",
    negotiate_btn: "Negotiate & Buy",
    quantity_not_available: "Quantity not available",
    negotiate_view_btn: "View Negotiation",
    order_placed_success: "Order Successfully Placed",
    step1: "Cart",
    step2: "Address",
    step3: "Payment",

    //Negotiation
    negotiation_ini_success_message: "Negotiation initiatiated",
    negotiation_quantity_text: "Quantity",
    negotiation_pending_text: "Pending Confirmation",
    negotiation_rejected_text: "Offer Rejected",
    negotiation_accepted_text: "Offer Accepted",
    negotiation_closed_text: "Offer Closed",
    negotiation_enter_price_text: "Enter your price ",
    negotiation_attempt_left_text: " attempt left",
    negotiation_proceed_purchase_btn: "Proceed to Purchase",
    negotiation_status: "Status",
    negotiated_won_variant_error: "Negotiated won variant already in the cart",
    shipping_and_taxes_extra: "Shipping and Taxes extra",
    negotiation_cancel: "Cancel",

    //Negotiation List
    negotiation_text: "Negotiation",
    negotiation_not_found: "No Negotiation(s)",
    negotiation_active: "Active",
    negotiation_won: "Won",
    negotiation_lost: "Lost",
    negotiation_won_text: "Negotiation Won",

    negotiation_won_title: "No negotiation(s) Won",
    negotiation_won_desc: "You haven't won any negotiation(s)",
    negotiation_lost_title: "No negotiation(s) Lost",
    negotiation_lost_desc: "You haven't lost any negotiation(s)",
    negotiation_active_title: "No negotiation(s)",
    negotiation_active_desc: "There's no product negotiated yet",
    negotiation_note: "You will get notified once seller responded.",

    //Watchlist
    watchlist_tab_watching: "Watching",
    watchlist_tab_mybids: "My Bids",
    mybids_not_found: "No bid(s)",

    //Mention
    mention_slider_title: "Mentions",
    mention_no_comment: "There's no Comment(s) yet",
    mention_add_success: "comment posted successfully",
    mention_update_success: "comment updated successfully",
    type_your_message: "Type your message",

    //Activity
    activity: "Activity",
    shop: "Shop",
    activity_you: "You",
    buying_history: "Buying History",
    activity_followers: "Followers",
    acitvity_recently_viewed: "Recently Viewed",
    acitvity_watchlist: "Watchlist",
    acitvity_wishlist: "Wishlist",
    recently_viewed_not_found: "No Recently Viewed(s)",

    buying_history_purchases: "Purchases",
    buying_history_purchase_made: "Purchases Made",
    buying_history_no_purchases: "No purchase(s) made yet",
    buying_history_last_purchases: "Last purchase was made ",
    buying_history_bids: "bids",
    buying_history_bids_placed: "Bids Placed",
    buying_history_no_bids: "No bids(s) placed yet",
    buying_history_last_bid: "Last bid placed ",
    buying_history_offers: "Offers",
    buying_history_offers_negotiation: "Negotiation Offer",
    buying_history_no_negotiation: "No negotiations made yet",
    buying_history_last_offer: "Last offer was made",
    buying_ago: " ago",

    watcher_list: "Watcher List",

    view_my_orders: "View My Orders",
    view_my_bids: "View My Bids",
    view_negotiation: "View Negotiations",
    public_wishlist_feed: "created public wishlist",
    public_mention_feed: "mention",
    new_product_feed: "created new product",
    coupon_feed: "created an upcoming coupon",
    discount_feed: "created an upcoming discount",

    //My Bids
    no_bids_won_title: "No bid(s) Won",
    no_bids_won_desc: "You haven't won any bid(s)",
    no_bids_lost_title: "No bid(s) Lost",
    no_bids_lost_desc: "You haven't lost any bid(s)",
    no_bids_active_title: "No bid(s) Active",
    no_bids_active_desc: "You have no active bid(s)",

    no_bids_title: "No bid(s)",
    no_bids_desc: "You are not bidding on any products(s)",
    no_watching_title: "No Products(s)",
    no_watching_desc: "You are not watching any bid(s)",
    no_wishlist_title: "No Shared Wishlist(s)",
    no_wishlist_desc: "You don't have any shared wishlist yet",

    //My Followers
    my_followers: "My Followers",
    my_followers_no_data_title: "No Follower(s)",
    my_followers_no_data_desc: "You don't have any followers yet",

    shared_wishlist_tab_key: "shared",
    following_tab_key: "following",
    shared_wishlist_tab: "Shared Wishlist",
    following_tab: "Following",

    shared_wishlist_tab: "Shared Wishlist",
    following_tab: "Following",

    shared_no_data_title: "No Shared Wishlist(s)",
    shared_no_data_desc: "The user haven't shared any wishlist",
    you_shared_no_data_desc: "You don't have any shared wishlist yet",

    following_no_data_title: "No Following(s)",
    following_no_data_desc: "The user is not following any user",
    you_following_no_data_desc: "You are not following any user",

    //Feed
    based_interest: "Based on Your Interest",
    followed: "followed",

    //Sidemenu
    menu_home: "Home",
    menu_profile: "Profile Settings",
    menu_language: "Language",
    menu_currency: "Currency",
    menu_categories: "All Categories",
    menu_order: "My Orders",
    menu_bidplace: "Bid Placed",
    menu_negotiation: "Negotiations",
    menu_coupon: "My Coupons",
    menu_invitation: "My Invitations",
    menu_suggestion: "Suggestions",
    menu_faq: "FAQ & Support",
    menu_legal: "Legal Docs",
    menu_signin: "Signin",
    menu_signout: "Signout",

    //Messages
    message_title: "Messages",
    send_message: "Send Message",
    type_message: "Type your message",
    no_message_header: "No messages yet",
    no_message_subheader: "Stay tuned! Messages will show up here.",
    message_tab_product: "Products",
    message_tab_order: "Orders",

    no_review_header: "No reviews yet",
    no_review_found: "No reviews found",

    //Seller Profile
    seller_profile: "Seller Profile",
    topcategories: "Top Categories",
    top_selling_item: "Top Selling Items",
    rating_from: "from",
    location: "Location",
    member_shopez: "Member of Shopez Since",
    item_as_described: "Item as Described",
    communication: "Communication",
    shipping_time: "Shipping Time",
    map: "Map",
    select_language: "Select Language",
    topsel_low_to_high: "Top Selling low to high",
    topsel_high_to_low: "Top Selling high to low",
    away_timing: "Away timing",

    anonymous: "Anonymous",

    //Cart
    cart_address: "Address",
    cart_text: "Shopping Cart",
    cart_breadcrumb: "Cart",
    cart_applied_coupons: "Applied Coupons",
    cart_get_coupon: "Get Coupon",
    cart_proceed_checkout: "Proceed to Checkout",
    cart_continue_shopping: " < Continue Shopping",
    cart_continue: "Continue",
    cart_order_summery: "Order Summary",
    cart_discount: "Discount",
    cart_coupon_code: "Coupon Code",
    cart_shipping_fee: "Shipping Fee",
    cart_tax_charges: "Tax & Charges",
    cart_total: "Total",
    cart_shipping_address: "Shipping Address",
    cart_payment: "Payment",
    cart_payment_method: "Payment Method",
    cart_payment_card: "Pay with card",
    cart_payment_cash: "Pay with cash",
    cart_payment_cash_not_available: "Pay with cash is not available",
    cart_address_change_btn: "Change",
    cart_new_address: "New Address",
    cart_item_selected: "Items Selected",
    cart_sku: "SKU",
    cart_remove: "Remove",
    cart_product_price: "Product Price",
    cart_product_discount: "Discount",
    cart_coupon_discount: "Coupon Discount",
    cart_available_coupons: "Available Coupons",
    cart_flat_discount: "Flat",
    cart_coupon_apply: "Apply",
    cart_coupon_expired: "Expired",
    cart_coupon_min_error:
      "Minimum cart value should be equal or greater than ",
    cart_item_not_selected: "No item is selected",
    cart_coupon_applied_success: "coupon code applied successfully",
    cart_coupon_error1: "Coupon already used!",
    cart_coupon_error2: "Cannot be applied on existing items!",
    cart_coupon_error3: "Coupon quota exhausted!",
    cart_coupon_error4: "Coupon cannot be applied!",
    cart_coupon_copied: "Coupon copied",
    cart_coupon_not_available: "Coupon is not available!",
    cart_coupon_has_expired: "Coupon has Expired!",
    cart_coupon_not_exist: "Coupon code not exist!",
    cart_billing_address: "Billing Address",
    cart_review_items: "Review Items",
    same_as_shipping: "Same as Shipping address",
    cart_select_shipping_address: "SELECT SHIPPING ADDRESS",
    contact_name: "Contact Name",
    country: "Country",
    address: "Address",
    city: "City",
    zip: "Zip",
    state: "State",
    review_order: "Review Your Order",
    make_default_title: "Make Default",
    make_default_msg: "Do you want to make this as a default address",
    button_once: "No, use once",
    button_add_new_address: "Add New Address",
    button_edit_address: "Edit Address",
    button_update_address: "Update",
    button_save_address: "Save Address",
    empty_cart_title: "Your Cart is empty!",
    empty_cart_desc: "Add items to it now",
    cart_coupon_applied: "Applied Coupon",
    cart_place_order: "Place Order",
    cart_no_address: "No Address",
    cart_add_address: "Please add a address",
    available_address: "Address",
    buildingno: "Building No",
    zoneno: "Zone Number",
    streetno: "Street Number",
    unitno: "Unit No",
    poboxno: "P.O Box",
    order_not_proceed:
      "Some of your item(s) either out of stock or temporarily unavailable",

    //Report Abuse
    report_as_abusive: "Report as abusive",
    report_title: "Report",
    report_button: "Report",
    report_confirm_product_title: "Report Product",
    report_confirm_product_message:
      "Are you sure you want to report about this product?",
    report_success: "You have successfully reported the comment as abusive",
    report_confirm_comment_title: "Report Comment",
    report_confirm_comment_message:
      "Are you sure you want to report about this comment?",

    report_confirm_review_title: "Report Review",
    report_confirm_review_message:
      "Are you sure you want to report about this review?",

    report_confirm_question_title: "Report Question",
    report_confirm_question_message:
      "Are you sure you want to report about this question?",

    report_confirm_answer_title: "Report Answer",
    report_confirm_answer_message:
      "Are you sure you want to report about this answer?",

    report_confirm_consumer_title: "Report Consumer",
    report_confirm_consumer_message:
      "Are you sure you want to report about this user?",

    //Coupon
    coupon_copy: "Copy",
    my_coupons: "My Coupons",
    product_coupons: "Coupons",

    //My Order
    my_orders: "My Orders",
    order_id: "Order ID",
    order_details_pay_now: "Pay Now",
    order_details_btn: "Details",
    order_details: "Order Details",
    order_delivered: "Delivered",
    order_dispatched: "Dispatched",
    order_Placed: "Placed",
    order_cancelled: "Cancelled",
    initiate_refund: "Initiate Refund",
    initiate_pickup: "Pickup Initiated",
    return_received: "Return Received",
    return_picked: "Return Picked",
    return_requested: "Return requested",
    replacement_requested: "Replacement requested",
    refund_initiated: "Refund Initiated",
    refunded: "Refund",
    payment_pending: "Pending",
    refund_rejected: "Refund Rejected",
    reshipdelivered: "Order Delivered",
    reship: "Replace reshippped",
    in_transit: "In Transit",
    seller: "Seller",
    sku: "SKU",
    quantity: "QTY",
    inclusive_text: "Inclusive all taxes and charges.",
    qar_total_text: "You'll be charged in QAR and total = ",
    order_status: "Order Status",
    contact_no: "Contact No : ",
    payment_method: "Payment",
    rate_delivaery: "Rate Delivery",
    btn_return: "Return",
    btn_cancel: "Cancel",
    btn_return_replace: "Return/Replace",
    btn_replace: "Replace",
    btn_reorder: "Reorder",
    confirm_return_replace: "Are you sure do you want to return/replace?",
    confirm_refund: "Are you sure do you want to return?",
    confirm_replace: "Are you sure do you want to replace?",
    confirm_cancel: "Are you sure do you want to cancel?",
    cancel_reason: "Cancel Reason:",
    refund_cancel_reason: "Refund Reject Reason:",
    order_cancel: "Order Cancelled",
    cancel_note: "You will get notified once status updated",
    reject_reason: "Refund Reject Reason:",
    return_reason: "Return/Replace Reason:",

    //Refund Retrun/Replace
    return_title: "Return Item",
    return_heading_text: "What would you like to do with the product?",
    replace_product: "Replace Product",
    return_product: "Return Product",
    confirm_request: "Confirm Request",
    confirm_and_pay: "Confirm & Pay",
    select_reason_text: "Select reason to replace",
    applicable_text: "Check all applicable reasons",
    explain_placeholder: "Explain the reasons",
    reason_item_match: "Item was damaged when received",
    reason_item_color: "Wrong colour/model received",
    reason_item_damage: "Item was damaged when received",
    reason_other: "Some other reason? Please write your reason",
    return_status_error: "Status Required",
    return_reason_error: "Reason Required",
    select_reason_error: "Select any reason",
    cancel_success: "Product Cancelled Succefully",
    return_success: "Return request done successfully",
    replace_success: "Replace request done successfully",
    cancel_request: "Cancel Request",
    cancel_btn: "Cancel",
    no_order_title: "No Order(s)",
    no_order_desc: "You have not placed any order yet",
    order_success: "Order placed successfully",

    rate_product: "Rate Product",
    rate_product_rating: "Rate Product (rating low to high)",
    rate_product_desc:
      "Please rate the below criteria as per your experience (rating low to hight)",
    rate_product_point1: "1. How accurate was the product description?",
    rate_product_point2:
      "2. How satisfied were you with the seller's Communication?",
    rate_product_point3: "3. How quickly did the seller ship the item?",
    rate_product_point4: "4. You can leave your feedabck",
    rate_product_attach: "Attach files",
    rate_product_anonymus: "Submit feedback as anonymous",
    rate_product_error: "Please rate the product",
    submit_rating: "Submit Rating",
    review_submit_success: "Review submitted successfully",

    cancel_refund: "Cancel refund initiated",
    cancel_refunded: "Cancel refunded",
    cancel_refundrejected: "Refund Rejected",
    cancel_return: "Cancel Return",
    refund_processing: "Refund Processing",

    //Category View
    no_product_title: "No Products(s)",
    no_product_desc: "There's no Product(s) added yet",

    // Product View
    product_title: "Products",
    item_text: "item",
    items_text: "items",
    sortby: "Sort by",
    sortby_low_to_high: "Price Low to High",
    sortby_high_to_low: "Price High to Low",
    sortby_popularity: "Popularity",
    sortby_newest_first: "Newest First",
    sortby_bestmatch: "Best Match",
    sortby_endingsoonest: "Ending Soonest",
    price_title: "Price",
    filter_text: "Filter",
    clear_all: "Clear All",
    no_product_found: "No Product(s) found",
    all_category: "All Category",

    /**** Profile Settings ****/
    profile_settings_list_text1: "Profile Information",
    profile_settings_list_subtext1: "Manage your account",

    profile_settings_list_text2: "Address",
    profile_settings_list_subtext2: "Manage your address",

    profile_settings_list_text3: "Notification",
    profile_settings_list_subtext3: "Turned on",

    profile_settings_list_text4: "Account Settings",
    profile_settings_list_subtext4: "Manage payment accounts",

    profile_settings_list_text5: "My Followers",
    profile_settings_list_subtext5: "See who follows you",

    update_account: "Update Account",
    profile_settings_actsettings_terms_text:
      "All your earnings are collected on shopez and will be sent to you as per 7 days cycle.Please add your bank account details where you whould like to receive the payments",

    beneficiary_name: "Beneficiary Name",
    beneficiary_full_name: "Beneficiary full name",

    bank_name: "Bank Name",
    beneficiary_bank_name: "Beneficiary bank name",

    act_settings_act_number_lbl: "Account Number/IBAN",
    act_settings_act_number_placeholder: "Beneficiary account no./IBAN",

    act_settings_swift_code_lbl: "Swift code/Bic code/sort code",
    act_settings_swift_code_placeholder: "Swift code/Bic code/sort code",

    select_country: "Select Country",

    act_settings_contact_number_lbl: "Contact Number",
    act_settings_contact_number_placeholder:
      "Beneficiary contact no.(optional)",

    // My Followers
    text_you: "you",
    btn_follow: "Follow",
    btn_unfollow: "Unfollow",

    profile_settings_myfollowers_sheardwhishlist: "Shared Wishlist",
    profile_settings_myfollowers_Following: "Following",

    no_shared_wishlist: "No Shared wishlist(s)",
    user_havenot_shared_wishlist: "The user haven't shared any wishlist",

    no_following: "No Following(s)",
    user_not_following_user: "The user is not following any user",

    items: "Items",

    // Profile Information
    update_profile: "Update Profile",
    get_verified: "Get verified",
    no_rating: "No Rating",
    your_profile_is_unverified: "Your profile is Unverified",
    profile_updated_successfully: "Profile information updated successfully",
    add_phone_number: "Add phone number",
    update_password: "Update Password",
    update_phone_number: "Update Phone Number",
    verify_phone_number: "Verify",
    phone_updated_successfully: "Phone number updated successfully",
    otp_verified_successfully: "OTP verified successfully",
    otp_not_verified: "OTP not verified",
    mobile_not_verified: "You have not verified mobile number yet.",

    update_email: "Update Email",
    email_updated_successfully: "Email updated successfully",
    email_not_verified: "You have not verified email yet.",

    // Store Information
    update_store_info: "Update store info",
    contact_person_label: "Contact Person's",
    contact_person_placeholder: "Contact person's name",
    contact_number_label: "Contact number",
    another_number_label: "Another number",
    add_another_number_btn: "Add another number",
    select_away_time: "Select away time",
    store_info_start_date_label: "Start date",
    store_info_end_date_label: "End date",
    set_automatic_response_text: "Set Automatic response",
    set_automatic_response_subtext:
      "(Automatic response is for anyone who contacts through messages during time away)",
    set_automatic_response_placeholder: "hey hello how's your day going hey",
    store_updated_successfully: "Store information updated successfully",
    delete_contact_confirm: "Delete Contact Number",
    delete_contact_number_confirm_text:
      "Are you sure you wish to delete it? This action cannot be undone",
    contact_deleted_successfully: "Contact Deleted successfully",
    plus_add_another_number: "+ Add another number",
    location_header: "Location",
    store_location_text: "Store location",
    search_places: "Search Places ...",
    search_address: "Search Address",
    search_your_location: "Search your location",
    latitude: "Latitude",
    longitude: "Longitude",
    zoom: "Zoom",
    address: "Address",
    current_password: "Current Password",
    confirm_password: "Confirm Password",
    security_code: "Current Password",
    phone_number: "Phone Number",
    update_phone_number_text: "Please enter the number you wish to update",
    current_number: "Current Number",
    send_code: "Send Code",
    send_link: "Send Link",
    phone_number_verfication_text:
      "Please enter the verification code that we have sent to your phone number.",
    verification_code_mismatch: "Verification code does not match",
    store_location_verfication_text: "Please enter store location",

    send_code_success: "OTP sent to given Number",
    otp_has_been_sent_success: "OTP has been sent",

    // Store Verification
    submit_verification: "Submit Verification",
    attach_files: "Attach files",
    attach_document: "Attach document",
    commercial_registration_document: "Commercial registration document",
    agreement_template_duly_filled_and_signed:
      "Agreement template duly filled and signed",
    store_verification_form_header:
      "If you want to become a trusted vendor and earn more custom, get your account verified on shopez!",
    store_verification_form_subheader:
      "Upload the documents and earn a verified mark",
    on_your_profile: "on your profile",
    vendor_verified_text: "You are an approved and verified User",
    download_agreement_template: "Download agreement template",
    valid_id_proof: "Valid ID proof",
    upload_only_doc_and_pdf_file_error_text:
      "Not a valid file! upload only doc and pdf file",
    attachfile_to_update_error_text: "Attach files to update",

    // App Suggestions
    app_suggestions_header_text: "Select your suggestions",

    app_suggestions_lbl_email_address: "Email Address",
    app_suggestions_plshldr_email_address: "Email Address",

    app_suggestions_lbl_suggestion_desc: "Suggestion Description",
    app_suggestions_plshldr_suggestion_desc: "Add Description",

    app_suggestions_footer_text:
      "Your suggestion will be emailed to Shopez and necessary actions will be taken",
    send_suggestion: "Send Suggestion",

    file_attached: "file attached",
    files_attached: "files attached",

    bug: "Bug",
    app_feature: "Feature",
    app_suggestions_submit_success: "Your suggestions submitted successfully",

    // FAQ & Support
    raise_a_ticket: "Raise a Ticket",
    faq_footer_head_text: "Need more help? Contact Us",
    call_us: "Call Us:",
    write_to_us: "Write to us:",
    raise_tacket_sub_header_text:
      "If you haven't got the answer to your problems, please write to us.",
    write_your_problems: "Write your problems",
    write_your_questions: "Write your question?",
    search_text_faq_support: "Search our FAQs",

    faq_support_submit_success: "Your concern was submitted successfully",

    // Legal Docs
    consumer_legal_docs_list_text1: "Terms & Conditions",
    consumer_legal_docs_list_subtext1: "View our terms & conditions",

    consumer_legal_docs_list_text2: "Privacy Policy",
    consumer_legal_docs_list_subtext2: "View our privacy policy",

    consumer_legal_docs_list_text3: "Sales Policy",
    consumer_legal_docs_list_subtext3:
      "View our return / exchange / bidding policy",

    consumer_legal_docs_list_text4: "About us",
    consumer_legal_docs_list_subtext4: "View our story",

    male: "Male",
    female: "Female",
    rather_not_say: "Rather Not Say",
    password_updated_success: "Password Changed Successfully",

    //Notification Settings
    nsetting_auction_updates: "Notify me when item send auction updates",
    nsetting_aubid_or_win: "Notify when I am outbid or win and item",
    nsetting_interested_updated:
      "When an update is made in the item I am interested in",
    nsetting_order_update:
      "Order updates - when an update is made to an order I have made",
    nsetting_receive_message: "When I receive message from Shopez member",
    nsetting_top_deal: "Shopez top deal of the day",
    nsetting_followed: "Someone follows me",
    nsetting_invited: "Someone invited by me joins",
    nsetting_mentioned: "Someone mentions me on the product",
    nsetting_violation: "Violation message recived from admin",

    // Payment Cancel/ Fail / Success
    pay_pending_title: "Payment Pending",
    pay_pending_desc: "Transaction Pending",
    pay_pending_bank_title: "Payment Pending",
    pay_pending_bank_desc: "Transaction pending confirmation from bank",
    pay_fail_title: "Payment Failed",
    pay_fail_desc: "Transaction failed",
    pay_cancelled_title: "Payment Cancelled",
    pay_cancelled_desc: "Transaction Cancelled",
    pay_success: "Your Transaction is successfull",

    address_delete_title: "Delete Address",
    address_delete_msg: "Are you sure want to delete this address?",
    address_add_success: "Address added",
    address_update_success: "Address updated",

    drop_or_select: "Drop or select",
    phone_exist: "Phone number already exist",

    // Notification
    notifications: "Notifications",
    read_all: "Read All",
    update: "Update",
    mark_all_messages_as_read: "Mark all messages as read",
    no_notification_header: "No Notification(s)",
    no_notification_subheader: "No notification received yet",

    //Change Currency
    change_currency: "Change Currency",
    select_currency: "Select Currency",
    qatari_riyal: "Qatari Riyal",
    dollar: "United States Dollar",

    //Account
    country_code: "Select Country",
    check_digits: "Check Digits",
    bank_code: "SWIFT Code/BIC Code/Sort Code",
    account_number: "Benificiary Account No./IBAN",
    iban: "IBAN",
    add_account: "Add Account",
    invalid_length: "Invalid",
    account_add_success: "Account added successfully",
    account_update_success: "Account updated successfully",
    benificiary_name: "Benificiary Name",
    benificiary_bankname: "Benificiary Bank Name",
    benificiary_contactno: "Benificiary Contact No.",

    // My Invitation
    my_invitation: "My Invitation",
    invite_message: "Invite Message",
    email_invitation:
      "Invite your friends by adding emails. You can add multiple emails seperated by a comma(,) and send invites all at once.",
    send_invitation: "Send Invitation",
    invitation_submit_success: "Invitation sent seccessfully",

    readmore: "ReadMore",
    readless: "ReadLess",

    signup_as_vendor: "Signup as Vendor",
    vendor_mode: "Vendor mode",

    verifyemail_success: "email verified successfully",
    verifyemail_already: "email already verified",

    //Blocker
    skip: "Skip",
    verification_header_text1: "Verify email",
    verification_subheader_text1: "Verify your email address",
    verification_header_text2: "Verify",
    verification_subheader_text2: "Verify your phone number",
    verification_header_text3: "Upload Documents",
    verification_subheader_text3:
      "Before you can start using Shopez, Please upload the following:",

    verification_email_note_text1:
      "In order for us to activate your account and let you login, we need to verify that the email address. We have sent an email to (",
    verification_email_note_text2:
      ") which contains a link you need to click on...",

    verification_email_note_text3:
      "You have entered the wrong email id? You can change the email now",

    verification_email_note_text4:
      "Your new email has been successfully updated! Please relogin to verify your new email.",

    verification_email_resend_btn: "Haven't received the email? Resend",

    verification_phone_change_number: "Change Number?",
    verification_phone_change_number_field_name: "Change Number",
    verification_phone_change_number_field_placeholder: "Enter your number",

    enter_wrong_emailid: "You have entered the wrong email id",
    email_varified_successfully: "email verified successfully",
    phone_varified_successfully: "Phone number verified successfully",
    resent_email_alert_text:
      "email has been sent to your register email address !",
    email_exist_alert_text: "email already exist ! Enter another",

    resend: "Resend",
    email_address: "Email",
    legal_docs_accept_tnc_text: "I accept the Terms and Conditions",
    terms_error: "Please select terms & conditions",
    copy_link: "Copy Link",
    copied: "Copied",
    refunded: "Refunded",
    email_terms_text: "Email me a copy of Terms & Conditions",

    outofstock_notify_success: "Successfully Registered",
    outofstock_already_opted: "You will get notified",

    loader_message: "Please Wait",
    banned_item_header: "Not Found",
    banned_item_subheader:
      "Sorry! the item you are looking for is temporarily unavailable",
    banned_profile_subheader:
      "Sorry! the profile you are looking for is temporarily unavailable",

    //Change Currency
    currency_of_preference: "Currency of Preference",
    change_currency: "Change Currency",
    select_currency: "Select Currency",
    qatari_riyal: "Qatari Riyal",
    dollar: "United States Dollar",

    // Language
    language_of_preference: "Language of Preference",
    english: "English",
    arabic: "Arabic",

    //Email update
    update_email_text: "Please enter the email you wish to update",
    current_email: "Current Email",
    email_send_code: "Send Code",
    email_verfication_text:
      "Please enter the verification code that we have sent to your email.",
    verification_code_mismatch: "Verification code does not match",
    email_send_code_success: "OTP sent to given Email",
    email_otp_has_been_sent_success: "OTP has been sent",
    verify_email_button: "Verify Email",
    form_validation_email_required: "Please enter valid email address!",

    replace_pay: "You have to pay ",
    replace_refund: "You will be refunded ",
    current_status: "Current Status",
    exchange_order: "Exchange Order",
    exchange_product: "Exchanged Product",
    previous_paid: "Previously Paid",
    selected_price: "Selected Price",
    original_product_price: "Original Product",
    order_details_paid: "Paid Amount",
    order_details_refund: "Refund Amount",
    download_invoice: "Download Invoice",
    no_payment_required: "No payments required",

    ban_account_text: "Your account is temporarily disabled",
    contact_support: "Contact Support",

    //Footer Nav
    contact_us: "Contact Us",
    returns_order: "Returns Order",
    footer_my_account: "My Account",
    support: "Support",
    privacy_policy: "Privacy Policy",
    terms_condition: "Terms & Condition",
    faq: "FAQ",
    return_policy: "Return Policy",
    shipment_policy: "Shipment Policy",
    policy_center: "Policy Center",
    consumer_legal_return_subtext2: "View our return policy",
    consumer_legal_shipment_subtext2: "View our shipment policy",
    intellectual_property: "Intellectual Property Claims",
  },
  ar: {
    btn_save: "Save",
    search_text: "What are you looking for?",
    required: "Required",
    done: "Done",
    search_not_found: "No results for",
    breadcrumb_home: "Home",
    breadcrumb_search: "Search",
    email: "بريد إلكتروني",
    password: "كلمه السر",
    conf_password: "Confirm Password",
    signup: "Signup",
    first_name: "First Name",
    last_name: "Last Name",
    nick_name: "Nick Name",
    date_of_birth: "Date of Birth",
    phone_no: "Phone Number",
    confirm_password: "Confirm Password",
    gender: "Gender",
    signin: "تسجيل الدخول",
    forgot_your_password: "نسيت رقمك السري؟",
    already_account_text: "Already have an account?",
    dont_have_account_text: "Don't have an account?",
    create_a_new_account: "انشاء حساب جديد",
    login: "تسجيل الدخول",
    login_btn_text: "تسجيل الدخول",
    signout: "خروج",
    logout_confirm_title: "Logout",
    logout_confirm_message: "Are you sure you want to logout?",
    invalid_user: "مستخدم غير صالح!",
    button_logout: "تسجيل خروج",
    invalid_email_password: "بريد أو كلمة مرورغير صحيحة!",
    invalid_user: "مستخدم غير صالح!",
    login_form_validation_email_required:
      "الرجاء إدخال عنوان البريد الإلكتروني الخاص بك!",
    login_form_validation_password_required: "من فضلك أدخل رقمك السري!",
    form_validation_first_name_required: "Please enter your first name!",
    form_validation_last_name_required: "Please enter your last name!",
    form_validation_phone_no_required: "Please enter your phone no!",
    form_validation_phone_no_valid: "Please enter valid phone no!",
    form_validation_conf_password_required: "Please enter confirm password!",
    form_validation_password_not_match:
      "Password and confirm password does not match!",
    email_password: "Enter your email & password to login",
    my_account: "My Account",
    categories: "التصنيفات",
    subcategories: "Sub Category",
    show_all: "عرض الكل",
    product_desc: "Product Description",
    product_overview: "Overview",
    variant: "Variant",
    shipping: "Shipping",
    product_spec: "Specification",

    variant_not_selected: "Please select a variant to replace",
    common_fail_message: "Something went wrong!",
    signup_error_already_registered: "email already registered",
    signup_success: "Account created successfully!",
    login_success: "Successfully! logged in",
    connect_social_media_text: "Connect with social media",
    signup_error_phone_already_registered: "Phoneno already registered",
    signup_heading: "Lets get started by creating a new account",

    // Forgot Password Modal
    forgot_password: "Forgot Password",
    password_reset_link_text:
      "Please enter the registered email and will send you a password reset link",
    send_reset_link: "Send Reset Link",
    close: "Close",
    forgot_password_success: "Password reset link sent successfully",
    reset_btn: "Reset",
    reset_password_heading: "Reset Password",
    reset_confirm_code: "Confirm Code",
    reset_password_success: "Password updated successfully",
    forgot_password_form_error_messages: "email id not registered with Shopez",

    // Product Details
    products: "Products",
    product_specification: "Product Specification",
    ask_question_placeholder: "Type your question",
    ask_btn: "Submit",
    ask_question_form_error: "Question Required",
    ask_question_success: "Question Added",
    question_and_answer: "Questions & Answers",
    question_and_answer_subheading: "Want to Know More About the Product?",
    ask_question_btn: "Ask a Question",
    view_all_qa_link: "View All Questions & Answers",
    all_question_text: "Question(s)",
    view_all_question_link: "Load more questions",
    no_question_found: "No question found",
    reviews: "Reviews",
    view_all_review_link: "Load more reviews",
    all_review_text: "All Reviews",
    view_all_details: "View All Details",
    reviews_txt: "Reviews",
    review_txt: "Review",
    review_all_text: "/5.0",
    review_recommeded_text: "Recommended",
    review_search_text: "Search",
    review_sortby_low_to_high: "Negative First",
    review_sortby_high_to_low: "Positive First",
    review_sortby_newest_first: "Newest First",
    sold_txt: "sold",
    wishlist_txt: "Add to Wishlist",
    wishlisted_txt: "Wishlisted",
    watchlist_txt: "Watchlist",
    watching_txt: "Watching",
    watchlist_remove_success: "Item removed from watchlist",
    watchlist_add_success: "Item added to watchlist",
    watchlist_delete_item_success: "Item deleted from Watchlist",
    product_quantity_text: "Quantity",

    wishlist_clear_success: "Item cleared from Wishlist",
    wishlist_delete_success: "Wishlist deleted",
    wishlist_delete_item_success: "Item deleted from Wishlist",

    wishlist_delete_title: "Delete Wishlist",
    wishlist_delete_msg: "Are you sure you want to delete this wishlist?",
    wishlist_clear_title: "Clear Wishlist Item",
    wishlist_clear_msg: "Are you sure you want to clear items?",
    button_yes: "Yes",
    button_no: "No",
    button_ok: "Ok",
    button_delete: "Delete",
    button_edit: "Edit",
    button_edit_profile: "Edit Profile",
    item: "Item",
    items: "Items",
    wishlist_share_success: "Wishlist shared",
    wishlist_item_delete_option: "Delete Items",
    wishlist_clear_option: "Clear Wishlist",
    wishlist_delete_option: "Delete Wishlist",
    wishlist_share_option: "Share Wishlist",
    wishlist_no_product: "There's no Product(s) added yet",
    wishlist_remove_success: "Item removed from Wishlist",
    wishlist_add_success: "Item added to Wishlist",
    wishlist_slider_title: "Add to Wishlist",
    create_new_wishlist: "Create New Wishlist",
    create_new_wishlist_name: "Wishlist Name",
    create_new_wishlist_privacy: "Privacy",
    create_new_wishlist_public: "Public",
    create_new_wishlist_private: "Private",
    create_new_wishlist_btn: "Save Wishlist",
    create_new_wishlist_name_required: "Name required",
    wishlist_name_limit_error: "30 characters only",
    category: "Category",
    sub_category: "Sub Category",
    condition: "Condition",
    make_private: "Make Private",
    wishlist_private_success: "Successfully convert to private",

    share_txt: "share",
    shop_now: "Shop now",
    similar_products: "Similar Products",
    similar_products_not_found: "No Similar Products Found",
    view_all_similar_products: "View All Similar Products",
    you_may_like_products: "Products You May Like",
    rating: "Rating",
    and: "and",
    star: "Star",
    share_heading: "Share",

    starts_in: "Starts in",
    ends_in: "Ends in",
    bid_not_started: "Bid not Started",
    bid_over: "Ended",
    expired: "Expired",
    cancelled: "Cancelled",
    out_of_stock: "Out of Stock",
    notify_available_text: "Notify when available",
    not_deliverable: "Not Deliverable",
    temporarily_unavailable: "Temporarily unavailable",

    place_a_bid: "Place a Bid",
    cancel: "Cancel",
    make_offer: "Make your offer",
    place_bid: "Place Bid",
    bid_private_text: "Keep my name anonymous",
    bid_commited_text:
      "By placing a bid you are committed to purchase the product if you win.",
    place_a_bid_price_error: "Price Required",
    place_a_bid_min_price_error:
      "Price should be equal/greater than highest bid price",
    highest_bid: "Highest Bid",
    your_last_bid: "Your Last Bid",
    starting_bid: "Starting Bid",
    bid: "Bids",
    nobid: "Bid",
    place_a_bid_commited_error: "Required",
    place_re_bid: "Re Bid",

    view_comments_btn: "Comment",
    comment: "comments",
    likes_text: "Likes",
    like_text: "Like",

    delivery: "Delivery",
    deliverto: "Deliver To",
    deliveryby: "Delivery By",
    soldby: "Sold By",
    follower: "Followers",
    following: "Followings",
    follow_btn: "Follow",
    unfollow_btn: "Unfollow",
    send_message_btn: "Send Message",
    return_policy: "Return Policy",
    replacement_policy: "Replacement Policy",

    //Add to Cart
    add_to_cart_success: "Item added to Cart",
    choose_variant: "Choose Variants",
    selected_variant: "Selected Variant",
    add_to_cart_btn: "Add to Cart",
    slider_title: "Negotiate",
    negotiate_btn: "Negotiate & Buy",
    quantity_not_available: "Quantity not available",
    negotiate_view_btn: "View Negotiation",
    order_placed_success: "Order Successfully Placed",
    step1: "Cart",
    step2: "Address",
    step3: "Payment",

    //Negotiation
    negotiation_ini_success_message: "Negotiation initiatiated",
    negotiation_quantity_text: "Quantity",
    negotiation_pending_text: "Pending Confirmation",
    negotiation_rejected_text: "Offer Rejected",
    negotiation_accepted_text: "Offer Accepted",
    negotiation_closed_text: "Offer Closed",
    negotiation_enter_price_text: "Enter your price ",
    negotiation_attempt_left_text: " attempt left",
    negotiation_proceed_purchase_btn: "Proceed to Purchase",
    negotiation_status: "Status",
    negotiated_won_variant_error: "Negotiated won variant already in the cart",
    shipping_and_taxes_extra: "Shipping and Taxes extra",
    negotiation_cancel: "Cancel",

    //Negotiation List
    negotiation_text: "Negotiation",
    negotiation_not_found: "No Negotiation(s)",
    negotiation_active: "Active",
    negotiation_won: "Won",
    negotiation_lost: "Lost",
    negotiation_won_text: "Negotiation Won",

    negotiation_won_title: "No negotiation(s) Won",
    negotiation_won_desc: "You haven't won any negotiation(s)",
    negotiation_lost_title: "No negotiation(s) Lost",
    negotiation_lost_desc: "You haven't lost any negotiation(s)",
    negotiation_active_title: "No negotiation(s)",
    negotiation_active_desc: "There's no product negotiated yet",
    negotiation_note: "You will get notified once seller responded.",

    //Watchlist
    watchlist_tab_watching: "Watching",
    watchlist_tab_mybids: "My Bids",
    mybids_not_found: "No bid(s)",

    //Mention
    mention_slider_title: "Mentions",
    mention_no_comment: "There's no Comment(s) yet",
    mention_add_success: "comment posted successfully",
    mention_update_success: "comment updated successfully",
    type_your_message: "Type your message",

    //Activity
    activity: "Activity",
    shop: "Shop",
    activity_you: "You",
    buying_history: "Buying History",
    activity_followers: "Followers",
    acitvity_recently_viewed: "Recently Viewed",
    acitvity_watchlist: "Watchlist",
    acitvity_wishlist: "Wishlist",
    recently_viewed_not_found: "No Recently Viewed(s)",

    buying_history_purchases: "Purchases",
    buying_history_purchase_made: "Purchases Made",
    buying_history_no_purchases: "No purchase(s) made yet",
    buying_history_last_purchases: "Last purchase was made ",
    buying_history_bids: "bids",
    buying_history_bids_placed: "Bids Placed",
    buying_history_no_bids: "No bids(s) placed yet",
    buying_history_last_bid: "Last bid placed ",
    buying_history_offers: "Offers",
    buying_history_offers_negotiation: "Negotiation Offer",
    buying_history_no_negotiation: "No negotiations made yet",
    buying_history_last_offer: "Last offer was made",
    buying_ago: " ago",

    watcher_list: "Watcher List",

    view_my_orders: "View My Orders",
    view_my_bids: "View My Bids",
    view_negotiation: "View Negotiations",
    public_wishlist_feed: "created public wishlist",
    public_mention_feed: "mention",
    new_product_feed: "created new product",
    coupon_feed: "created an upcoming coupon",
    discount_feed: "created an upcoming discount",

    //My Bids
    no_bids_won_title: "No bid(s) Won",
    no_bids_won_desc: "You haven't won any bid(s)",
    no_bids_lost_title: "No bid(s) Lost",
    no_bids_lost_desc: "You haven't lost any bid(s)",
    no_bids_active_title: "No bid(s) Active",
    no_bids_active_desc: "You have no active bid(s)",

    no_bids_title: "No bid(s)",
    no_bids_desc: "You are not bidding on any products(s)",
    no_watching_title: "No Products(s)",
    no_watching_desc: "You are not watching any bid(s)",

    no_wishlist_title: "No Shared Wishlist(s)",
    no_wishlist_desc: "You don't have any shared wishlist yet",

    //My Followers
    my_followers: "My Followers",
    my_followers_no_data_title: "No Follower(s)",
    my_followers_no_data_desc: "You don't have any followers yet",

    shared_wishlist_tab: "Shared Wishlist",
    following_tab: "Following",

    shared_no_data_title: "No Shared Wishlist(s)",
    shared_no_data_desc: "The user haven't shared any wishlist",
    you_shared_no_data_desc: "You don't have any shared wishlist yet",

    following_no_data_title: "No Following(s)",
    following_no_data_desc: "The user is not following any user",
    you_following_no_data_desc: "You are not following any user",

    //Feed
    based_interest: "Based on Your Interest",
    followed: "followed",

    //Sidemenu
    menu_home: "Home",
    menu_profile: "Profile Settings",
    menu_language: "Language",
    menu_currency: "Currency",
    menu_categories: "All Categories",
    menu_order: "My Orders",
    menu_bidplace: "Bid Placed",
    menu_negotiation: "Negotiations",
    menu_coupon: "My Coupons",
    menu_invitation: "My Invitations",
    menu_suggestion: "Suggestions",
    menu_faq: "FAQ & Support",
    menu_legal: "Legal Docs",
    menu_signin: "Signin",
    menu_signout: "Signout",

    //Messages
    message_title: "Messages",
    send_message: "Send Message",
    type_message: "Type your message",
    no_message_header: "No messages yet",
    no_message_subheader: "Stay tuned! Messages will show up here.",
    message_tab_product: "Products",
    message_tab_order: "Orders",

    no_review_header: "No reviews yet",
    no_review_found: "No reviews found",

    //Seller Profile
    seller_profile: "Seller Profile",
    topcategories: "Top Categories",
    top_selling_item: "Top Selling Items",
    location: "Location",
    rating_from: "from",
    member_shopez: "Member of Shopez Since",
    item_as_described: "Item as Described",
    communication: "Communication",
    shipping_time: "Shipping Time",
    map: "Map",
    topsel_low_to_high: "Top Selling low to high",
    topsel_high_to_low: "Top Selling high to low",
    away_timing: "Away timing",

    select_language: "Select Language",
    anonymous: "Anonymous",

    //Cart
    cart_address: "Address",
    cart_text: "Shopping Cart",
    cart_breadcrumb: "Cart",
    cart_applied_coupons: "Applied Coupons",
    cart_get_coupon: "Get Coupon",
    cart_proceed_checkout: "Proceed to Checkout",
    cart_continue_shopping: "< Continue Shopping",
    cart_continue: "Continue",
    cart_order_summery: "Order Summary",
    cart_discount: "Discount",
    cart_coupon_code: "Coupon Code",
    cart_shipping_fee: "Shipping Fee",
    cart_tax_charges: "Tax & Charges",
    cart_total: "Total",
    cart_shipping_address: "Shipping Address",
    cart_payment: "Payment Method",
    cart_payment_method: "Payment Method",
    cart_payment_card: "Pay with card",
    cart_payment_cash: "Pay with cash",
    cart_payment_cash_not_available: "Pay with cash is not available",
    cart_address_change_btn: "Change",
    cart_new_address: "New Address",
    cart_item_selected: "Items Selected",
    cart_sku: "SKU",
    cart_remove: "Remove",
    cart_product_price: "Product Price",
    cart_product_discount: "Discount",
    cart_coupon_discount: "Coupon Discount",
    cart_available_coupons: "Available Coupons",
    cart_flat_discount: "Flat",
    cart_coupon_apply: "Apply",
    cart_coupon_expired: "Expired",
    cart_item_not_selected: "No item is selected",
    cart_coupon_applied_success: "coupon code applied successfully",
    cart_coupon_error1: "Coupon already used!",
    cart_coupon_error2: "Cannot be applied on existing items!",
    cart_coupon_error3: "Coupon quota exhausted!",
    cart_coupon_error4: "Coupon cannot be applied!",
    cart_coupon_min_error:
      "Minimum cart value should be equal or greater than ",
    cart_coupon_copied: "Coupon copied",
    cart_coupon_not_available: "Coupon is not available!",
    cart_coupon_has_expired: "Coupon has Expired!",
    cart_coupon_not_exist: "Coupon code not exist!",
    cart_billing_address: "Billing Address",
    cart_review_items: "Review Items",
    same_as_shipping: "Same as Shipping address",
    cart_select_shipping_address: "SELECT SHIPPING ADDRESS",
    contact_name: "Contact Name",
    country: "Country",
    address: "Address",
    city: "City",
    zip: "Zip",
    state: "State",
    review_order: "Review Your Order",
    make_default_title: "Make Default",
    make_default_msg: "Do you want to make this as a default address",
    button_once: "No, use once",
    button_add_new_address: "Add New Address",
    button_edit_address: "Edit Address",
    button_update_address: "Update",
    button_save_address: "Save Address",
    empty_cart_title: "Your Cart is empty!",
    empty_cart_desc: "Add items to it now",
    cart_coupon_applied: "Applied Coupon",
    cart_place_order: "Place Order",
    cart_no_address: "No Address",
    cart_add_address: "Please add a address",
    available_address: "Address",
    buildingno: "Building No",
    zoneno: "Zone Number",
    streetno: "Street Number",
    unitno: "Unit No",
    poboxno: "P.O Box",
    order_not_proceed:
      "Some of your item(s) either out of stock or temporarily unavailable",

    //Report Abuse
    report_as_abusive: "Report as abusive",
    report_title: "Report",
    report_button: "Report",
    report_confirm_product_title: "Report Product",
    report_confirm_product_message:
      "Are you sure you want to report about this product?",
    report_success: "You have successfully reported",

    report_confirm_comment_title: "Report Comment",
    report_confirm_comment_message:
      "Are you sure you want to report about this comment?",

    report_confirm_review_title: "Report Review",
    report_confirm_review_message:
      "Are you sure you want to report about this review?",

    report_confirm_question_title: "Report Question",
    report_confirm_question_message:
      "Are you sure you want to report about this question?",

    report_confirm_answer_title: "Report Answer",
    report_confirm_answer_message:
      "Are you sure you want to report about this answer?",

    report_confirm_consumer_title: "Report Consumer",
    report_confirm_consumer_message:
      "Are you sure you want to report about this user?",

    //Coupon
    coupon_copy: "Copy",
    my_coupons: "My Coupons",
    product_coupons: "Coupons",

    //My Order
    my_orders: "My Orders",
    order_id: "Order ID",
    order_details_pay_now: "Pay Now",
    order_details_btn: "Details",
    order_details: "Order Details",
    order_delivered: "Delivered",
    order_dispatched: "Dispatched",
    order_Placed: "Placed",
    order_cancelled: "Cancelled",
    initiate_refund: "Initiate Refund",
    initiate_pickup: "Pickup Initiated",
    return_received: "Return Received",
    return_picked: "Return Picked",
    return_requested: "Return requested",
    replacement_requested: "Replacement requested",
    refund_initiated: "Refund Initiated",
    refunded: "Refund",
    payment_pending: "Pending",
    refund_rejected: "Refund Rejected",
    reshipdelivered: "Order Delivered",
    reship: "Replace reshippped",
    in_transit: "In Transit",
    seller: "Seller",
    sku: "SKU",
    quantity: "QTY",
    inclusive_text: "Inclusive all taxes and charges.",
    qar_total_text: "You'll be charged in QAR and total = ",
    order_status: "Order Status",
    contact_no: "Contact No : ",
    payment_method: "Payment",
    rate_delivaery: "Rate Delivery",
    btn_return: "Return",
    btn_cancel: "Cancel",
    btn_return_replace: "Return/Replace",
    btn_replace: "Replace",
    btn_reorder: "Reorder",
    confirm_return_replace: "Are you sure do you want to return/replace?",
    confirm_refund: "Are you sure do you want to return?",
    confirm_replace: "Are you sure do you want to replace?",
    confirm_cancel: "Are you sure do you want to cancel?",
    cancel_reason: "Cancel Reason:",
    refund_cancel_reason: "Refund Reject Reason:",
    order_cancel: "Order Cancelled",
    cancel_note: "You will get notified once status updated",
    reject_reason: "Refund Reject Reason:",
    return_reason: "Return/Replace Reason:",

    //Refund Retrun/Replace
    return_title: "Return Item",
    return_heading_text: "What would you like to do with the product?",
    replace_product: "Replace Product",
    return_product: "Return Product",
    confirm_request: "Confirm Request",
    confirm_and_pay: "Confirm & Pay",
    select_reason_text: "Select reason to replace",
    applicable_text: "Check all applicable reasons",
    explain_placeholder: "Explain the reasons",
    reason_item_match: "Item was damaged when received",
    reason_item_color: "Wrong colour/model received",
    reason_item_damage: "Item was damaged when received",
    reason_other: "Some other reason? Please write your reason",
    return_status_error: "Status Required",
    return_reason_error: "Reason Required",
    select_reason_error: "Select any reason",
    cancel_success: "Product Cancelled Succefully",
    return_success: "Return request done successfully",
    replace_success: "Replace request done successfully",
    cancel_request: "Cancel Request",
    cancel_btn: "Cancel",
    no_order_title: "No Order(s)",
    no_order_desc: "You have not placed any order yet",
    order_success: "Order placed successfully",

    rate_product: "Rate Product",
    rate_product_rating: "Rate Product (rating low to high)",
    rate_product_desc:
      "Please rate the below criteria as per your experience (rating low to hight)",
    rate_product_point1: "1. How accurate was the product description?",
    rate_product_point2:
      "2. How satisfied were you with the seller's Communication?",
    rate_product_point3: "3. How quickly did the seller ship the item?",
    rate_product_point4: "4. You can leave your feedabck",
    rate_product_attach: "Attach files",
    rate_product_anonymus: "Submit feedback as anonymous",
    rate_product_error: "Please rate the product",
    submit_rating: "Submit Rating",
    review_submit_success: "Review submitted successfully",

    cancel_refund: "بدأ إلغاء استرداد",
    cancel_refunded: "إلغاء ردها",
    cancel_refundrejected: "تم رفض رد الأموال",
    cancel_return: "Cancel Return",
    refund_processing: "Refund Processing",

    //Category View
    no_product_title: "No Products(s)",
    no_product_desc: "There's no Product(s) added yet",

    // Product View
    product_title: "Products",
    item_text: "item",
    items_text: "items",
    sortby: "Sort by",
    sortby_low_to_high: "Price Low to High",
    sortby_high_to_low: "Price High to Low",
    sortby_popularity: "Popularity",
    sortby_newest_first: "Newest First",
    sortby_bestmatch: "Best Match",
    sortby_endingsoonest: "Ending Soonest",
    price_title: "Price",
    filter_text: "Filter",
    clear_all: "Clear All",
    no_product_found: "No Product(s) found",
    all_category: "All Category",

    /**** Profile Settings ****/
    profile_settings_list_text1: "Profile Information",
    profile_settings_list_subtext1: "Manage your account",

    profile_settings_list_text2: "Address",
    profile_settings_list_subtext2: "Manage your address",

    profile_settings_list_text3: "Notification",
    profile_settings_list_subtext3: "Turned on",

    profile_settings_list_text4: "Account Settings",
    profile_settings_list_subtext4: "Manage payment accounts",

    profile_settings_list_text5: "My Followers",
    profile_settings_list_subtext5: "See who follows you",

    update_account: "Update Account",
    profile_settings_actsettings_terms_text:
      "All your earnings are collected on shopez and will be sent to you as per 7 days cycle.Please add your bank account details where you whould like to receive the payments",

    beneficiary_name: "Beneficiary Name",
    beneficiary_full_name: "Beneficiary full name",

    bank_name: "Bank Name",
    beneficiary_bank_name: "Beneficiary bank name",

    act_settings_act_number_lbl: "Account Number/IBAN",
    act_settings_act_number_placeholder: "Beneficiary account no./IBAN",

    act_settings_swift_code_lbl: "Swift code/Bic code/sort code",
    act_settings_swift_code_placeholder: "Swift code/Bic code/sort code",

    select_country: "Select Country",

    act_settings_contact_number_lbl: "Contact Number",
    act_settings_contact_number_placeholder:
      "Beneficiary contact no.(optional)",

    // My Followers
    text_you: "you",
    btn_follow: "Follow",
    btn_unfollow: "Unfollow",

    profile_settings_myfollowers_sheardwhishlist: "Shared Wishlist",
    profile_settings_myfollowers_Following: "Following",

    no_shared_wishlist: "No Shared wishlist(s)",
    user_havenot_shared_wishlist: "The user haven't shared any wishlist",

    no_following: "No Following(s)",
    user_not_following_user: "The user is not following any user",

    items: "Items",

    // Profile Information
    update_profile: "Update Profile",
    get_verified: "Get verified",
    no_rating: "No Rating",
    your_profile_is_unverified: "Your profile is Unverified",
    profile_updated_successfully: "Profile information updated successfully",
    add_phone_number: "Add phone number",
    update_password: "Update Password",
    update_phone_number: "Update Phone Number",
    verify_phone_number: "Verify",
    phone_updated_successfully: "Phone number updated successfully",
    otp_verified_successfully: "OTP verified successfully",
    otp_not_verified: "OTP not verified",
    mobile_not_verified: "You have not verified mobile number yet.",

    update_email: "Update Email",
    email_updated_successfully: "Email updated successfully",
    email_not_verified: "You have not verified email yet.",

    // Store Information
    update_store_info: "Update store info",
    contact_person_label: "Contact Person's",
    contact_person_placeholder: "Contact person's name",
    contact_number_label: "Contact number",
    another_number_label: "Another number",
    add_another_number_btn: "Add another number",
    select_away_time: "Select away time",
    store_info_start_date_label: "Start date",
    store_info_end_date_label: "End date",
    set_automatic_response_text: "Set Automatic response",
    set_automatic_response_subtext:
      "(Automatic response is for anyone who contacts through messages during time away)",
    set_automatic_response_placeholder: "hey hello how's your day going hey",
    store_updated_successfully: "Store information updated successfully",
    delete_contact_confirm: "Delete Contact Number",
    delete_contact_number_confirm_text:
      "Are you sure you wish to delete it? This action cannot be undone",
    contact_deleted_successfully: "Contact Deleted successfully",
    plus_add_another_number: "+ Add another number",
    location_header: "Location",
    store_location_text: "Store location",
    search_places: "Search Places ...",
    search_address: "Search Address",
    search_your_location: "Search your location",
    latitude: "Latitude",
    longitude: "Longitude",
    zoom: "Zoom",
    address: "Address",
    current_password: "Current Password",
    confirm_password: "Confirm Password",
    security_code: "Current Password",
    phone_number: "Phone Number",
    update_phone_number_text: "Please enter the number you wish to update",
    current_number: "Current Number",
    send_code: "Send Code",
    send_link: "Send Link",
    phone_number_verfication_text:
      "Please enter the verification code that we have sent to your phone number.",
    verification_code_mismatch: "Verification code does not match",
    store_location_verfication_text: "Please enter store location",
    send_code_success: "OTP sent to given Number",
    otp_has_been_sent_success: "OTP has been sent",

    // Store Verification
    submit_verification: "Submit Verification",
    attach_files: "Attach files",
    attach_document: "Attach document",
    commercial_registration_document: "Commercial registration document",
    agreement_template_duly_filled_and_signed:
      "Agreement template duly filled and signed",
    store_verification_form_header:
      "If you want to become a trusted vendor and earn more custom, get your account verified on shopez!",
    store_verification_form_subheader:
      "Upload the documents and earn a verified mark",
    on_your_profile: "on your profile",
    vendor_verified_text: "You are an approved and verified User",
    download_agreement_template: "Download agreement template",
    valid_id_proof: "Valid ID proof",
    upload_only_doc_and_pdf_file_error_text:
      "Not a valid file! upload only doc and pdf file",
    attachfile_to_update_error_text: "Attach files to update",

    // App Suggestions
    app_suggestions_header_text: "Select your suggestions",

    app_suggestions_lbl_email_address: "Email Address",
    app_suggestions_plshldr_email_address: "Email Address",

    app_suggestions_lbl_suggestion_desc: "Suggestion Description",
    app_suggestions_plshldr_suggestion_desc: "Add Description",

    app_suggestions_footer_text:
      "Your suggestion will be emailed to Shopez and necessary actions will be taken",
    send_suggestion: "Send Suggestion",

    file_attached: "file attached",
    files_attached: "files attached",

    bug: "Bug",
    app_feature: "Feature",
    app_suggestions_submit_success: "Your suggestions submitted successfully",

    // FAQ & Support
    raise_a_ticket: "Raise a Ticket",
    faq_footer_head_text: "Need more help? Contact Us",
    call_us: "Call Us:",
    write_to_us: "Write to us:",
    raise_tacket_sub_header_text:
      "If you haven't got the answer to your problems, please write to us.",
    write_your_problems: "Write your problems",
    write_your_questions: "Write your question?",
    search_text_faq_support: "Search our FAQs",

    faq_support_submit_success: "Your concern was submitted successfully",

    // Legal Docs
    consumer_legal_docs_list_text1: "Terms & Conditions",
    consumer_legal_docs_list_subtext1: "View our terms & conditions",

    consumer_legal_docs_list_text2: "Privacy Policy",
    consumer_legal_docs_list_subtext2: "View our privacy policy",

    consumer_legal_docs_list_text3: "Sales Policy",
    consumer_legal_docs_list_subtext3:
      "View our return / exchange / bidding policy",

    consumer_legal_docs_list_text4: "About us",
    consumer_legal_docs_list_subtext4: "View our story",

    male: "Male",
    female: "Female",
    rather_not_say: "Rather Not Say",
    password_updated_success: "Password Changed Successfully",

    //Notification Settings
    nsetting_auction_updates: "Notify me when item send auction updates",
    nsetting_aubid_or_win: "Notify when I am outbid or win and item",
    nsetting_interested_updated:
      "When an update is made in the item I am interested in",
    nsetting_order_update:
      "Order updates - when an update is made to an order I have made",
    nsetting_receive_message: "When I receive message from Shopez member",
    nsetting_top_deal: "Shopez top deal of the day",
    nsetting_followed: "Someone follows me",
    nsetting_invited: "Someone invited by me joins",
    nsetting_mentioned: "Someone mentions me on the product",
    nsetting_violation: "Violation message recived from admin",

    // Payment Cancel/ Fail / Success
    pay_pending_title: "Payment Pending",
    pay_pending_desc: "Transaction Pending",
    pay_pending_bank_title: "Payment Pending",
    pay_pending_bank_desc: "Transaction pending confirmation from bank",
    pay_fail_title: "Payment Failed",
    pay_fail_desc: "Transaction failed",
    pay_cancelled_title: "Payment Cancelled",
    pay_cancelled_desc: "Transaction Cancelled",
    pay_success: "Your transaction is successfull",

    address_delete_title: "Delete Address",
    address_delete_msg: "Are you sure want to delete this address?",
    address_add_success: "Address added",
    address_update_success: "Address updated",

    drop_or_select: "Drop or select",
    phone_exist: "Phone number already exist",

    // Notification
    notifications: "Notifications",
    read_all: "Read All",
    update: "Update",
    mark_all_messages_as_read: "Mark all messages as read",
    no_notification_header: "No Notification(s)",
    no_notification_subheader: "No notification received yet",

    //Change Currency
    change_currency: "Change Currency",
    select_currency: "Select Currency",
    qatari_riyal: "Qatari Riyal",
    dollar: "United States Dollar",

    //Account
    country_code: "Select Country",
    check_digits: "Check Digits",
    bank_code: "SWIFT Code/BIC Code/Sort Code",
    account_number: "Benificiary Account No./IBAN",
    iban: "IBAN",
    add_account: "Add Account",
    invalid_length: "Invalid",
    account_add_success: "Account added successfully",
    account_update_success: "Account updated successfully",
    benificiary_name: "Benificiary Name",
    benificiary_bankname: "Benificiary Bank Name",
    benificiary_contactno: "Benificiary Contact No.",

    // My Invitation
    my_invitation: "My Invitation",
    invite_message: "Invite Message",
    email_invitation:
      "Invite your friends by adding emails.You can add multiple emails seperated by a comma(,) and send invites all at once.",
    send_invitation: "Send Invitation",
    invitation_submit_success: "Invitation sent seccessfully",

    readmore: "ReadMore",
    readless: "ReadLess",
    verifyemail_success: "email verified successfully",
    verifyemail_already: "email already verified",

    //Blocker
    skip: "Skip",
    verification_header_text1: "Verify email",
    verification_subheader_text1: "Verify your email address",
    verification_header_text2: "Verify",
    verification_subheader_text2: "Verify your phone number",
    verification_header_text3: "Upload Documents",
    verification_subheader_text3:
      "Before you can start using Shopez, Please upload the following:",

    verification_email_note_text1:
      "In order for us to activate your account and let you login, we need to verify that the email address. We have sent an email to (",
    verification_email_note_text2:
      ") which contains a link you need to click on...",

    verification_email_note_text3:
      "You have entered the wrong email id? You can change the email now",

    verification_email_note_text4:
      "Your new email has been successfully updated! Please relogin to verify your new email.",

    verification_email_resend_btn: "Haven't received the email? Resend",

    verification_phone_change_number: "Change Number?",
    verification_phone_change_number_field_name: "Change Number",
    verification_phone_change_number_field_placeholder: "Enter your number",

    enter_wrong_emailid: "You have entered the wrong email id",
    email_varified_successfully: "email verified successfully",
    phone_varified_successfully: "Phone number verified successfully",
    resent_email_alert_text:
      "email has been sent to your register email address !",
    email_exist_alert_text: "email already exist ! Enter another",
    resend: "Resend",
    email_address: "Email",

    legal_docs_accept_tnc_text: "I accept the Terms and Conditions",
    terms_error: "Please select terms & conditions",

    copy_link: "Copy Link",
    copied: "Copied",
    refunded: "Refunded",
    email_terms_text: "Email me a copy of Terms & Conditions",
    outofstock_notify_success: "Successfully Registered",
    outofstock_already_opted: "You will get notified",

    loader_message: "Please Wait",
    banned_item_header: "Not Found",
    banned_item_subheader:
      "Sorry! the item you are looking for is temporarily unavailable",
    banned_profile_subheader:
      "Sorry! the profile you are looking for is temporarily unavailable",

    //Change Currency
    currency_of_preference: "Currency of Preference",
    change_currency: "Change Currency",
    select_currency: "Select Currency",
    qatari_riyal: "Qatari Riyal",
    dollar: "United States Dollar",

    // Language
    language_of_preference: "Language of Preference",
    english: "English",
    arabic: "Arabic",

    //Email update
    update_email_text: "Please enter the email you wish to update",
    current_email: "Current Email",
    email_send_code: "Send Code",
    email_verfication_text:
      "Please enter the verification code that we have sent to your email.",
    verification_code_mismatch: "Verification code does not match",
    email_send_code_success: "OTP sent to given Email",
    email_otp_has_been_sent_success: "OTP has been sent",
    verify_email_button: "Verify Email",
    form_validation_email_required: "Please enter valid email address!",

    replace_pay: "You have to pay ",
    replace_refund: "You will be refunded ",
    current_status: "Current Status",
    exchange_order: "Exchange Order",
    exchange_product: "Exchanged Product",
    previous_paid: "Previously Paid",
    selected_price: "Selected Price",
    original_product_price: "Original Product",
    order_details_paid: "Paid Amount",
    order_details_refund: "Refund Amount",
    download_invoice: "Download Invoice",
    no_payment_required: "No payments required",

    ban_account_text: "Your account is temporarily disabled",
    contact_support: "Contact Support",

    //Footer Nav
    contact_us: "Contact Us",
    returns_order: "Returns Order",
    footer_my_account: "My Account",
    support: "Support",
    privacy_policy: "Privacy Policy",
    terms_condition: "Terms & Condition",
    faq: "FAQ",
    return_policy: "Return Policy",
    shipment_policy: "Shipment Policy",
    policy_center: "Policy Center",
    consumer_legal_return_subtext2: "View our return policy",
    consumer_legal_shipment_subtext2: "View our shipment policy",
    intellectual_property: "Intellectual Property Claims",
  },
});

// module.exports = LocaleStrings;
export default LocaleStrings;
