import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import ItemList from "../../Home/components/item-list";
import { ChevronRightIcon } from "@heroicons/react/outline";
import ShimmerEffect from "../../../components/shimmer-effect";
import VisibilitySensor from "react-visibility-sensor";
import { fetchSimilarProducts, emptySimilar } from "../../Home/actions";

function SimilarProduct(props) {
  const {
    similarProductList,
    setIschanged,
    deviceSize,
    productid,
    isChanged,
    session,
  } = props;

  useEffect(() => {
    props.emptySimilar(session);
  }, []);
  //console.log("similarProductList", similarProductList);

  const itemRender = (items) => {
    return _.map(items, (post, index) => {
      return (
        <ItemList
          key={post.id}
          item={post}
          type="similar"
          setIschanged={setIschanged}
        />
      );
    });
  };

  const renderShimmer = () => {
    let children = [];
    const shimmerCount =
      deviceSize && deviceSize.width && deviceSize.width > 1440
        ? 6
        : deviceSize && deviceSize.width && deviceSize.width > 1366
        ? 5
        : deviceSize.width >= 1024 && deviceSize.width <= 1366
        ? 4
        : deviceSize.width >= 768 && deviceSize.width <= 1024
        ? 4
        : 2;
    _.times(shimmerCount, (i) => {
      children.push(
        <ShimmerEffect
          containerClass="product-shimmer mb-4"
          simmerType1="square-simmer"
          simmerType2="line-simmer1"
          simmerType3="line-simmer2"
          simmerType4="line-simmer3"
        />
      );
    });
    return children;
  };

  const onChange = (isVisible) => {
    if (isVisible && productid) {
      if (similarProductList?.data?.length > 0) {
      } else {
        props.fetchSimilarProducts(props.session, productid, (res) => {});
      }
    }
  };

  return (
    <VisibilitySensor
      onChange={onChange}
      scrollCheck={true}
      partialVisibility={true}
    >
      <div
        className="rounded-10"
        style={
          props.deviceSize.isMobile
            ? {
                paddingLeft: 14,
                paddingRight: 14,
                background: `linear-gradient(180deg, rgb(237, 241, 248) 0%, #FFFFFF 100%)`,
              }
            : {
                background: `linear-gradient(180deg, rgb(237, 241, 248) 0%, #FFFFFF 100%)`,
              }
        }
      >
        <div className="pt-18 md:pt-30 pb-3.5 md:pb-25  pl-0 md:pl-18 pr-0 md:pr-18">
          <div className="grid grid-cols-2">
            <div className="flex items-center justify-start text-black text-base  md:text-22 font-bold">
              {similarProductList?.likedata?.length > 0
                ? LocaleStrings.you_may_like_products
                : LocaleStrings.similar_products}
            </div>
            <div className="flex items-center justify-end"></div>
          </div>
        </div>
        {similarProductList && similarProductList.data ? (
          similarProductList.data.length > 0 ? (
            <div className="px-0 md:px-18 pb-10 md:pb-18">
              <div className="mt-18 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-5">
                {itemRender(similarProductList.data)}
              </div>
            </div>
          ) : similarProductList?.likedata?.length > 0 ? (
            <div className="px-0 md:px-18 pb-10 md:pb-18">
              <div className="mt-18 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-5">
                {itemRender(similarProductList.likedata)}
              </div>
            </div>
          ) : (
            ""
          )
        ) : (
          <div className="px-0 md:px-18 pb-10 md:pb-18">
            <div className="mt-18 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-5">
              {renderShimmer()}
            </div>
          </div>
        )}
      </div>

      {/* {similarProductList && similarProductList.length > 0 ? (
        <div
          className="rounded-10"
          style={
            props.deviceSize.isMobile
              ? {
                  paddingLeft: 14,
                  paddingRight: 14,
                  background: `linear-gradient(180deg, rgb(237, 241, 248) 0%, #FFFFFF 100%)`,
                }
              : {
                  background: `linear-gradient(180deg, rgb(237, 241, 248) 0%, #FFFFFF 100%)`,
                }
          }
        >
          <div className="pt-18 md:pt-30 pb-3.5 md:pb-25  pl-0 md:pl-18 pr-0 md:pr-18">
            <div className="grid grid-cols-2">
              <div className="flex items-center justify-start text-black text-base  md:text-22 font-bold">
                {LocaleStrings.similar_products}
              </div>
              <div className="flex items-center justify-end"></div>
            </div>
          </div>
          <div className="px-0 md:px-18 pb-10 md:pb-18">
            <div className="mt-18 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-5">
              {itemRender(similarProductList)}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {!similarProductList ? (
        <div className="bg-newarrival ">
          <div
            className="py-5 custom_container"
            style={
              props.deviceSize.isMobile
                ? { paddingLeft: 10, paddingRight: 10 }
                : {}
            }
          >
            <div className="grid grid-cols-2  md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 md:gap-8 lg:gap-8 xl:gap-9 2xl:gap-4">
              {renderShimmer()}
            </div>
          </div>
        </div>
      ) : (
        ""
      )} */}
    </VisibilitySensor>
  );
}

const mapStateToProps = (state) => ({
  // isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  similarProductList: state.similarProductList,
  deviceSize: state.deviceSize,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchSimilarProducts, emptySimilar }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SimilarProduct);
