import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  renderFieldText,
  renderFieldTextarea,
} from "../../../components/Common/base-component";
import AppleLogo from "../../../assets/images/apple.svg";
import FacebookLogo from "../../../assets/images/facebook.svg";
import TwitterLogo from "../../../assets/images/twitter.svg";
import GoogleLogo from "../../../assets/images/google-plus.svg";
import toast, { Toaster } from "react-hot-toast";
import { passwordReset, getSource } from "../actions";

function Resetpwd(props) {
  const history = useHistory();
  const location = useLocation();
  let { session, deviceSize, handleSubmit, localLanguage, forgotPwdOpen } =
    props;
  const [show, setShow] = useState(false);
  const [cshow, setCShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // let search = new URLSearchParams(location.search);
    // let email = search.get("email");
    // props.getSource(email, (res) => {
    //   console.log("res", res);
    // });
  });

  const onSubmitForm = (values) => {
    setLoading(true);
    props.passwordReset(values, (response) => {
      if (response.success === 0) {
        setLoading(false);
        toast.error(LocaleStrings.invalid_email_password);
        props.reset();
      } else {
        props.getSource(values.email, (res) => {
          //console.log("res", res);
          if (res.success == 1 && res.data) {
            setLoading(false);
            toast.success(LocaleStrings.reset_password_success);
            if (res.data.source == "consumerweb") {
              history.push("/login");
            } else if (res.data.source == "vendorweb") {
              window.open("http://shopezvendor.staging.teks.co.in/#/login");
            } else if (res.data.source == "superadmin") {
              window.open("http://shopezsuperadmin.staging.teks.co.in/#/");
            } else if (res.data.source == "iosapp") {
            }
          }
        });
      }
    });
  };

  return (
    <>
      <div className="bg-newarrival flex justify-center py-0 md:py-28">
        <div className="bg-white w-11/12  xl:w-4/12  lg:w-5/12 md:w-7/12 mt-30 mb-30 rounded p-40">
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="text-primary font-normal text-3xl">
              {LocaleStrings.reset_password_heading}
            </div>
            <div className="pt-38">
              <label htmlFor="email" className="block text-xs text-loginLabel">
                {LocaleStrings.email}
              </label>
              <div className="mt-1">
                <Field
                  name="email"
                  placeholder={LocaleStrings.email}
                  type="email"
                  readonly={true}
                  autoComplete="off"
                  component={renderFieldText}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="pt-3.5">
              <label
                htmlFor="password"
                className="block text-xs text-loginLabel"
              >
                {LocaleStrings.reset_confirm_code}
              </label>
              <div className="mt-1">
                <Field
                  name="code"
                  placeholder={LocaleStrings.reset_confirm_code}
                  readonly={true}
                  autoComplete="off"
                  component={renderFieldText}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="pt-3.5">
              <label
                htmlFor="password"
                className="block text-xs text-loginLabel"
              >
                {LocaleStrings.password}
              </label>
              <div className="mt-1 relative">
                <Field
                  name="password"
                  placeholder={LocaleStrings.password}
                  type="password"
                  show={show}
                  autoComplete="off"
                  setShow={setShow}
                  component={renderFieldText}
                  classNameEye="absolute flex items-center text-sm top-3.5 right-3.5 cursor-pointer"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="pt-3.5">
              <label
                htmlFor="password"
                className="block text-xs text-loginLabel"
              >
                {LocaleStrings.conf_password}
              </label>
              <div className="mt-1 relative">
                <Field
                  name="confirm_password"
                  placeholder={LocaleStrings.conf_password}
                  type="password"
                  show={cshow}
                  setShow={setCShow}
                  component={renderFieldText}
                  classNameEye="absolute flex items-center text-sm top-3.5 right-3.5 cursor-pointer"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="pt-3.5">
              <button
                type="submit"
                className="text-white h-44 bg-btn text-sm font-normal rounded w-full inline-flex items-center justify-center"
              >
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 rounded-full mr-3"
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                ) : (
                  ""
                )}
                <span className="text-sm">{LocaleStrings.reset_btn}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

const validate = (values) => {
  var errors = {};

  if (!values.email) {
    errors.email = "required";
  }
  if (!values.code) {
    errors.code = "required";
  }
  if (!values.password) {
    errors.password = "required";
  } else if (values.password.length < 6) {
    errors.password = "minimum 6 character length";
  }
  if (!values.confirm_password) {
    errors.confirm_password = "required";
  }

  if (values.password && values.confirm_password) {
    if (values.confirm_password != values.password) {
      errors.confirm_password = "Password and Confirm Password Mismatch";
    }
  }

  return errors;
};

const mapStateToProps = (state, ownprops) => {
  var initVals = {};
  let search = new URLSearchParams(ownprops.location.search);
  let email = search.get("email");
  let code = search.get("code");
  initVals = { code: code, email: email };

  return {
    // isLoggedIn: state.isLoggedIn,
    session: state.session,
    deviceSize: state.deviceSize,
    localLanguage: state.localLanguage,
    forgotPwdOpen: state.forgotPwdOpen,
    initialValues: initVals,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ passwordReset, getSource }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "LoginForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Resetpwd)
);
