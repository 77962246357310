import axios from "axios";
import _ from "lodash";

import {
  INSTANCE_URL,
  APP_API_KEY,
  LOGIN,
} from "../../../components/Common/constant";

import {
  getRequestAccess,
  postRequestAccess,
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../components/Common/network-call";
import {
  PHONE_VERIFIED,
  EMAIL_VERIFIED,
  SET_CURRENCY,
  LOCAL_LANGUAGE,
} from "../../../actions";

export var ACTION_LOGOUT = "ACTION_LOGOUT";
export var SESSION_TOKEN = "SESSION_TOKEN";
export var IS_FORGOT_PASSWORD_OPEN = "IS_FORGOT_PASSWORD_OPEN";
export var CONSUMER_VERIFIED = "CONSUMER_VERIFIED";
export var USER_NAME = "USER_NAME";
export var ACTION_BAN = "ACTION_BAN";

export function mainLogin(values, callback) {
  //This function is used on Login screen only
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };

  // var data = {
  //   email: values.email,
  //   password: values.password,
  //   type: "email",
  // };

  var url = `${INSTANCE_URL}/api/v2/user/session`;
  var response = axios.post(url, values, config);
  return (dispatch) => {
    response
      .then(({ data }) => {
        //console.log("data", data);
        //Validate if the user is admin or, not

        dispatch({ type: SESSION_TOKEN, payload: data });
        dispatch({ type: PHONE_VERIFIED, payload: data.phoneverified });
        dispatch({ type: EMAIL_VERIFIED, payload: data.emailverified });
        dispatch({ type: USER_NAME, payload: data.username });
        dispatch({ type: SET_CURRENCY, payload: data.currencychoice });
        dispatch({ type: LOCAL_LANGUAGE, payload: data.languagechoice });
        dispatch({ type: LOGIN, payload: true });
        let sesObj = {};
        sesObj.sessionToken = data.session_token;
        sesObj.apiKey = APP_API_KEY;
        callback({ success: 1, session: sesObj });
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function logout(session) {
  return (dispatch) => {
    dispatch({ type: ACTION_LOGOUT, payload: false });
    if (session) {
      dispatch(banAction(true));
    }
    dispatch({ type: "RESET" });
  };
}

export function banAction(action) {
  return (dispatch) => {
    dispatch({ type: ACTION_BAN, payload: action });
  };
}

export function resetStore() {
  return (dispatch) => {
    dispatch({ type: "RESET" });
    dispatch({ type: SESSION_TOKEN, payload: {} });
  };
}

export function addConsumer(values, callback) {
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };
  //console.log("values", values);
  var url = `${INSTANCE_URL}/api/v2/user/register?login=true`;
  var response = axios.post(url, values, config);
  return (dispatch) => {
    response
      .then(({ data }) => {
        let user = data.user;
        user.session_token = data.session_token;
        dispatch({ type: SESSION_TOKEN, payload: user });
        dispatch({ type: PHONE_VERIFIED, payload: user.phoneverified });
        dispatch({ type: EMAIL_VERIFIED, payload: user.emailverified });
        dispatch({ type: USER_NAME, payload: user.username });
        dispatch({ type: SET_CURRENCY, payload: user.currencychoice });
        dispatch({ type: LOCAL_LANGUAGE, payload: user.languagechoice });
        dispatch({ type: LOGIN, payload: true });
        let sesObj = {};
        sesObj.sessionToken = user.session_token;
        sesObj.apiKey = APP_API_KEY;
        callback({ success: 1, session: sesObj });
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function validateEmailConsumer(values, callback) {
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };

  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?filter=(email=${values.email})`;
  var response = axios.get(url, config);

  return (dispatch) => {
    response
      .then(({ data }) => {
        if (data.resource.length > 0) {
          callback({ success: 2 });
        } else {
          callback({ success: 1 });
        }
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function validatePhoneConsumer(values, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript?type=phonevalidate`;
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };
  var response = axios.post(url, values, config);

  return (dispatch) => {
    response
      .then(({ data }) => {
        if (data.phoneexist > 0) {
          callback({ success: 2 });
        } else {
          callback({ success: 1 });
        }
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function isForgotPasswordOpen(isOpen) {
  return { type: IS_FORGOT_PASSWORD_OPEN, payload: isOpen };
}

export function validateSocialId(socialID, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript?type=verifysocialid`;
  var params = { uniqueid: socialID };
  return (dispatch) => {
    postRequestAccess(
      url,
      params,
      null,
      dispatch,
      (response) => {
        if (response.result == "success" && response.email) {
          callback({
            success: 1,
            email: response.email,
          });
        } else {
          callback({
            success: 0,
          });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function socialSignupAvailable(email, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript?type=socialsignupavailable`;
  var params = { email: email };
  return (dispatch) => {
    postRequestAccess(
      url,
      params,
      null,
      dispatch,
      (response) => {
        if (response.result == "success" && response.available === 1) {
          callback({
            success: 1,
          });
        } else {
          callback({
            success: 0,
          });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function initiateReset(values, callback) {
  //This function is used To initiate Reset
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };

  var data = {
    email: values.email,
    source: "consumerweb",
  };
  var url = `${INSTANCE_URL}/api/v2/appscript/updatesource`;
  var response = axios.post(url, data, config);
  return (dispatch) => {
    response
      .then(({ data }) => {
        callback({ success: 1, message: "Success" });
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function passwordReset(values, callback) {
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };

  var data = {
    email: values.email,
    code: values.code,
    new_password: values.password,
  };

  var url = `${INSTANCE_URL}/api/v2/user/password`;
  var response = axios.post(url, data, config);
  return (dispatch) => {
    response
      .then(({ data }) => {
        callback({ success: 1, message: "Success" });
      })
      .catch((error) => {
        callback({ success: 0, message: error.response.data.error.message });
      });
  };
}

export function getSource(email, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript/getsource`;

  var params = {
    email: email,
  };
  return (dispatch) => {
    postRequestAccess(
      url,
      params,
      null,
      dispatch,
      (response) => {
        if (response.result == "success") {
          callback({
            success: 1,
            data: response.data,
          });
        } else {
          callback({
            success: 0,
          });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function emailVerify(values, callback) {
  var url = `${INSTANCE_URL}/api/v2/appscript?type=emailapprove`;
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };
  var response = axios.post(url, values, config);

  return (dispatch) => {
    response
      .then(({ data }) => {
        if (data.verify == 1) {
          callback({ success: 1 });
        } else if (data.verify == 2) {
          callback({ success: 2 });
        } else {
          callback({ success: 0 });
        }
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function emailTerms(values, callback) {
  var config = {
    headers: {
      contentType: "application/json; charset=utf-8",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };
  //console.log("values", values);
  var url = `${INSTANCE_URL}/api/v2/sendemail`;
  var response = axios.post(url, values, config);
  return (dispatch) => {
    response
      .then(({ data }) => {
        callback({ success: 1 });
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}
