import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { loadState, reset } from "./localStorage";

import { LOGIN, APP_API_KEY } from "../components/Common/constant";
import {
  SESSION_TOKEN,
  ACTION_LOGOUT,
  IS_FORGOT_PASSWORD_OPEN,
  CONSUMER_VERIFIED,
  USER_NAME,
  ACTION_BAN,
} from "../../src/pages/Login/actions";
import {
  APP_SETTINGS,
  DEVICE_SIZE,
  LOCAL_LANGUAGE,
  SLIDER_OVER,
  IS_MENU_OPEN,
  IS_LANGUAGE_OPEN,
  ADS,
  SET_CURRENCY,
  IS_CURRENCY_OPEN,
  PHONE_VERIFIED,
  EMAIL_VERIFIED,
  IS_VERFICATION_MODAL_OPEN,
  COUNTRY_PHONE_LENGTH,
} from "../actions";

import {
  productList,
  similarProductList,
  searchList,
  selectedProduct,
  homePageData,
  getSearchKey,
} from "../pages/Home/reducers";

import {
  bidPlaceModal,
  bidSuggestion,
  productCounter,
  userDefaultAddress,
  getUserCart,
  negotiateThread,
  negotiationDetails,
  openAskQuestion,
  questionList,
  likesSliderOpen,
  likesList,
  isWishlistOpen,
  wishlistProductId,
  wishListed,
  isCommentOpen,
  commentList,
  mentionUserList,
  storeMessageOpen,
  storeThreadList,
  storeMessageList,
  isQuestionListOpen,
  questionListProductId,
  publicBidModal,
  publicBids,
  reviewModal,
  allReviews,
  reportAbuseModal,
  productWatchingModal,
  productWatchers,
  productViewAll,
  isWishlistCreateNewOpen,
  productShareModal,
  mediaLightBox,
  publicWishlist,
  highlightComment,
  getStoredCart,
} from "../pages/Product/reducers/index";

import {
  myNegotiation,
  isNegotiationopen,
  negotiationView,
} from "../pages/Negotiation/reducers/index";

import { myWatchList } from "../pages/Watchlist/reducers/index";
import {
  myBuyingHistory,
  myRecentViewed,
  myFollower,
  profileModal,
  profileFollower,
  profileFollowerDetails,
  profileSharedWishlist,
  activityFeed,
  navigateUser,
  publicProfile,
  profileViewChange,
} from "../pages/Activity/reducers/index";

import { myBid } from "../pages/Mybids/reducers/index";
import {
  sellerProfile,
  storeInfoMapModal,
  sellerSearch,
  sellerShareModal,
} from "../pages/Seller/reducers/index";

import {
  couponModal,
  getCoupon,
  appliedCoupon,
  countryList,
  defaultCountry,
  changeAddressModal,
  userAddressAll,
  newAddresModal,
  zoneList,
} from "../pages/Cart/reducers/index";

import { subCategoryList } from "../pages/Category/reducers/index";

import {
  myOrder,
  orderDetails,
  returnReplaceModal,
  orderCancelModal,
  rateProduct,
  orderReplaceVariant,
  orderMasterDetails,
} from "../pages/Myorders/reducers/index";

import {
  productBySubcategory,
  filterOptions,
  mobileFilter,
  mobileSort,
} from "../pages/Productview/reducers/index";

import {
  profileSettingsList,
  ProfileSettingsSelectedMenu,
  storeMyFollowersData,
  isFollowersDetailsModalOpen,
  storeCurrentMyFollowersData,
  storeCurrentMyFollowersUserData,
  myFolloersTabList,
  currentShearedWishlist,
  StoreDetails,
  StoreContacts,
  isOpenStoreInfoMapModal,
  isOpenProfileInfoModal,
  myProfile,
  storeSelectedGender,
  storeCountries,
  canReceivedNotification,
  notificationSetting,
  editAddress,
  bankAccount,
} from "../pages/Profile/reducers/index";

import { raiseTicketOpen, faqList } from "../pages/Faq/reducers/index";
import {
  settingsMasterConsumer,
  consumerLegalDocsList,
} from "../pages/Legaldoc/reducers/index";
import {
  appcounters,
  isNotificationOpen,
  notificationList,
  notificationSingleObjectStore,
  notificationDetailsStore,
  isMessageThread,
  storeThreadMessageList,
  isMessageOpen,
  messageList,
  storeOrdMessageList,
  basicOrderThreadData,
  currentOrderStep,
  selectedMessageTab,
  orderMessageList,
} from "../pages/Notification/reducers/index";

var LoginReducer = (state = true, action) => {
  // need to change it to false
  var state = loadState();

  if (state === undefined) {
    state = {
      isLoggedIn: false,
    };
  }

  if (action.type === LOGIN) {
    state.isLoggedIn = action.payload;
  }

  if (action.type === ACTION_LOGOUT) {
    state.isLoggedIn = action.payload;
  }

  return state.isLoggedIn;
};

// stores Data for session also on localstorage
var TokensReducer = (state = {}, action) => {
  var state = loadState();

  if (state === undefined || state.session === undefined) {
    state = {
      session: {
        apiKey: APP_API_KEY,
        sessionToken: "",
        emailid: "",
        userid: "",
        usertype: "",
        username: "",
        emailverified: "",
        phoneverified: "",
        verified: "",
      },
    };
  }

  if (action.type === SESSION_TOKEN) {
    if (action.payload.session_token) {
      state.session.sessionToken = action.payload.session_token;
      state.session.emailverified = action.payload.emailverified;
      state.session.phoneverified = action.payload.phoneverified;
      state.session.verified = action.payload.verified;
      state.session.emailid = action.payload.email;
      state.session.usertype = action.payload.usertype;
      state.session.userid = action.payload.userid;
      state.session.username = action.payload.username;
    }
  }

  if (action.type === ACTION_LOGOUT) {
    state.session.sessionToken = "";
    state.session.emailid = "";
    state.session.userid = "";
    state.session.usertype = "";
    state.session.username = "";
    state.session.emailverified = "";
    state.session.phoneverified = "";
    state.session.verified = "";
    reset();
  }

  return state.session;
};

// Stores User Related data
var UserReducer = (state = {}, action) => {
  var state = loadState();
  if (state === undefined || state.user === undefined) {
    state = {
      user: {
        phoneverified: false,
        emailverified: false,
        username: "",
      },
    };
  }

  if (action.type === EMAIL_VERIFIED) {
    state.user.emailverified = action.payload;
  }
  if (action.type === PHONE_VERIFIED) {
    state.user.phoneverified = action.payload;
  }

  if (action.type === USER_NAME) {
    state.user.username = action.payload;
  }

  if (action.type === ACTION_LOGOUT) {
    state.user.phoneverified = false;
    state.user.emailverified = false;
    state.user.username = "";
  }
  //console.log("state.user", state.user);
  return state.user;
};

var localCurrency = (state = "QAR", action) => {
  var state = loadState();
  if (state === undefined || state.currency === undefined) {
    state = "QAR";
  } else {
    state = state.currency;
  }

  if (action.type === SET_CURRENCY) {
    state = action.payload;
  }
  return state;
};

var deviceSize = (state = {}, action) => {
  if (action.type === DEVICE_SIZE) {
    return action.payload;
  }
  return state;
};

var appSettings = (state = {}, action) => {
  if (action.type === APP_SETTINGS) {
    return action.payload;
  }
  return state;
};

var localLanguage = (state = "en", action) => {
  var state = loadState();
  if (state === undefined || state.language === undefined) {
    state = "en";
  } else {
    state = state.language;
  }

  if (action.type === LOCAL_LANGUAGE) {
    state = action.payload;
  }
  
  return state;
};

var isSliderPop = (state = {}, action) => {
  if (action.type === SLIDER_OVER) {
    state = action.payload;
  }

  return state;
};

var sideMenu = (state = false, action) => {
  if (action.type === IS_MENU_OPEN) {
    state = action.payload;
  }

  return state;
};

var languageModal = (state = false, action) => {
  if (action.type === IS_LANGUAGE_OPEN) {
    state = action.payload;
  }

  return state;
};

var currencyModal = (state = false, action) => {
  if (action.type === IS_CURRENCY_OPEN) {
    state = action.payload;
  }

  return state;
};
// Is Forgot password modal open
var forgotPwdOpen = (state = false, action) => {
  if (action.type === IS_FORGOT_PASSWORD_OPEN) {
    state = action.payload;
  }
  return state;
};

var adsReducer = (state = {}, action) => {
  if (action.type === ADS) {
    return action.payload;
  }
  return state;
};

// Is Verfication Modal Open
var isVerficationOpen = (state = false, action) => {
  if (action.type === IS_VERFICATION_MODAL_OPEN) {
    state = action.payload;
  }

  return state;
};

var phoneLengthCountry = (state = 17, action) => {
  if (action.type === COUNTRY_PHONE_LENGTH) {
    state = action.payload;
  }

  return state;
};

var banAccount = (state = false, action) => {
  if (action.type === ACTION_BAN) {
    state = action.payload;
  }

  return state;
};

const rootReducer = combineReducers({
  isLoggedIn: LoginReducer,
  session: TokensReducer,
  user: UserReducer,
  form: formReducer,
  forgotPwdOpen: forgotPwdOpen,
  isVerficationOpen: isVerficationOpen,

  banAccount: banAccount,
  phoneLengthCountry: phoneLengthCountry,
  deviceSize: deviceSize,
  localLanguage: localLanguage,
  isSliderPop: isSliderPop,
  sideMenu: sideMenu,
  languageModal: languageModal,
  adsReducer: adsReducer,
  localCurrency: localCurrency,
  currencyModal: currencyModal,

  appSettings: appSettings,
  productList: productList,
  similarProductList: similarProductList,
  searchList: searchList,
  selectedProduct: selectedProduct,
  homePageData: homePageData,
  getSearchKey: getSearchKey,

  //Product
  bidPlaceModal: bidPlaceModal,
  bidSuggestion: bidSuggestion,
  productCounter: productCounter,
  userDefaultAddress: userDefaultAddress,
  getUserCart: getUserCart,
  negotiateThread: negotiateThread,
  negotiationDetails: negotiationDetails,
  openAskQuestion: openAskQuestion,
  questionList: questionList,
  likesSliderOpen: likesSliderOpen,
  likesList: likesList,
  isWishlistOpen: isWishlistOpen,
  wishlistProductId: wishlistProductId,
  wishListed: wishListed,
  isCommentOpen: isCommentOpen,
  commentList: commentList,
  mentionUserList: mentionUserList,
  storeMessageOpen: storeMessageOpen,
  storeThreadList: storeThreadList,
  storeMessageList: storeMessageList,
  isQuestionListOpen: isQuestionListOpen,
  questionListProductId: questionListProductId,
  publicBidModal: publicBidModal,
  publicBids: publicBids,
  reviewModal: reviewModal,
  allReviews: allReviews,
  reportAbuseModal: reportAbuseModal,
  productWatchingModal: productWatchingModal,
  productWatchers: productWatchers,
  productViewAll: productViewAll,
  isWishlistCreateNewOpen: isWishlistCreateNewOpen,
  productShareModal: productShareModal,
  mediaLightBox: mediaLightBox,
  publicWishlist: publicWishlist,
  highlightComment: highlightComment,
  getStoredCart,

  // MY Negotiation
  myNegotiation: myNegotiation,
  isNegotiationopen: isNegotiationopen,
  negotiationView: negotiationView,

  //My Watchlist
  myWatchList: myWatchList,

  // Activity
  myBuyingHistory: myBuyingHistory,
  myRecentViewed: myRecentViewed,
  myFollower: myFollower,
  profileModal: profileModal,
  profileFollower: profileFollower,
  profileFollowerDetails: profileFollowerDetails,
  profileSharedWishlist: profileSharedWishlist,
  activityFeed: activityFeed,
  navigateUser: navigateUser,
  publicProfile: publicProfile,
  profileViewChange: profileViewChange,

  //Mybids
  myBid: myBid,

  //Seller Profile
  sellerProfile: sellerProfile,
  storeInfoMapModal: storeInfoMapModal,
  sellerSearch: sellerSearch,
  sellerShareModal: sellerShareModal,

  //Cart
  couponModal,
  getCoupon,
  appliedCoupon,
  countryList,
  defaultCountry,
  changeAddressModal,
  userAddressAll,
  newAddresModal,
  zoneList,

  //MyOrder
  myOrder,
  orderDetails,
  returnReplaceModal,
  orderCancelModal,
  rateProduct,
  orderReplaceVariant,
  orderMasterDetails,

  //Category View
  subCategoryList,

  //Product By Subcategory
  productBySubcategory,
  filterOptions,
  mobileFilter,
  mobileSort,

  //Profile

  profileSettingsList,
  ProfileSettingsSelectedMenu,
  storeMyFollowersData,
  isFollowersDetailsModalOpen,
  storeCurrentMyFollowersData,
  storeCurrentMyFollowersUserData,
  myFolloersTabList,
  currentShearedWishlist,
  StoreDetails,
  StoreContacts,
  isOpenStoreInfoMapModal,
  isOpenProfileInfoModal,
  myProfile,
  storeSelectedGender,
  storeCountries,
  canReceivedNotification,
  notificationSetting,
  editAddress,
  bankAccount,

  //FAQ
  raiseTicketOpen,
  faqList,

  //Legal Docs
  settingsMasterConsumer,
  consumerLegalDocsList,

  //Notification
  appcounters,
  isNotificationOpen,
  notificationList,
  notificationSingleObjectStore,
  notificationDetailsStore,
  isMessageThread,
  storeThreadMessageList,
  isMessageOpen,
  messageList,
  storeOrdMessageList,
  basicOrderThreadData,
  currentOrderStep,
  selectedMessageTab,
  orderMessageList,
});

export default rootReducer;
