import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
  LABEL_POSITION_TOP,
  phoneCount,
  isValidQatarPhoneNumber,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import Loader from "../../../components/Common/loader";
import {
  renderFieldText,
  renderFieldCheckbox,
  renderPhonewithFlag,
  renderSelect,
  renderFieldTextarea,
  renderFieldRadio1,
} from "../../../components/Common/base-component";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import toast, { Toaster } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import { fetchCart } from "../../Product/actions";
import {
  isNewAddressModalOpen,
  makeDefaultAddress,
  updateCart,
  getUserAddressAll,
} from "../actions";
import { addAddress, updateAddress } from "../../Profile/actions";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { countryPhoneLength } from "../../../actions";

function NewAddress(props) {
  const history = useHistory();
  const {
    session,
    isLoggedIn,
    localLanguage,
    newAddresModal,
    getUserCart,
    userAddressAll,
    countryList,
    handleSubmit,
    deviceSize,
    initialValues,
    editMode,
    pristine,
    invalid,
    submitting,
    zoneList,
    callbackAdrress,
    editcountryId,
    setIschanged,
  } = props;

  const [loading, setLoading] = useState(false);
  const [addressid, setAddressid] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [geolocObject, setGeolocObject] = useState({
    location: "",
    latitude: "",
    longitude: "",
    city: "",
    country: "",
    state: "",
    zipcode: "",
    place_id: "",
  });
  const [country, setCountry] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [countryid, setCountryId] = useState(null);
  useEffect(() => {
    setAddressid(
      getUserCart?.items?.length > 0 ? getUserCart.items[0].addressid : ""
    );

    if (initialValues && initialValues.phone) {
      setPhone(initialValues.phone);
    }
    //console.log("editcountryId", editcountryId);
    if (editcountryId) {
      setCountryId(editcountryId);
    }

    return () => {
      setPhone("");
      setCountry("");
      setAddress("");
      setCountryId(null);
    };

    //props.getUserAddressAll(props.session, false, (callBackResponce) => {});
  }, [editcountryId]);

  const closeSlideOvers = () => {
    props.isNewAddressModalOpen(false);
    props.reset();
    setPhone("");
    setCountry("");
    setAddress("");
    // setCountryId(null);
  };

  const phoneChange = (value, country, e, formattedValue) => {
    //console.log("country", country);
    setPhone(value);
    setCountry(country.dialCode);
    //props.setdefaultCountry(country.countryCode);
    // let pLength = phoneCount(country.format, ".");
    // if (country.countryCode == "qa") {
    //   pLength = 11;
    // }
    props.countryPhoneLength(country.dialCode);
  };

  const changeAddress = (e) => {
    setAddressid(e.target.value);
  };

  const addressList = (item) => {
    return (
      <div className="pt-7">
        <div className="flex text-primary font-normal text-base">
          <div className="pr-4">
            <input
              type="radio"
              name="address"
              value={item.id}
              checked={addressid == item.id ? true : false}
              onChange={(e) => changeAddress(e)}
            />
          </div>
          <div>
            <div>
              {_.startCase(item.addresstype)}
              {" : "}
              {item.username}
            </div>
            <div className="text-cardfooter font-normal text-xs">
              {item.address}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const saveAddress = () => {};

  const handleChange = (address) => {
    //console.log(address);
    setAddress(address);
    // this.setState({ flag1: false });
  };

  const handleSelect = (address) => {
    //console.log(address);
    setAddress(address);
    let location = address;
    let zipcode = "";
    let place_id = "";
    let country = "";
    let state = "";
    let city = "";
    let latitude = "";
    let longitude = "";
    geocodeByAddress(address)
      .then((results) => {
        _.forEach(results[0].address_components, function (value) {
          // postal_code
          if (_.indexOf(value.types, "postal_code") > -1) {
            zipcode = value.long_name;
          }
          // country
          if (_.indexOf(value.types, "country") > -1) {
            country = value.long_name;
          }
          // state / administrative_area_level_1
          if (_.indexOf(value.types, "administrative_area_level_1") > -1) {
            state = value.long_name;
          }
          // state / administrative_area_level_2
          if (_.indexOf(value.types, "administrative_area_level_2") > -1) {
            city = value.long_name;
          }
        });
        //console.log("country", country);
        if (country != "Qatar") {
          place_id = results[0].place_id; // place_id / zipcode
          geolocObject["zipcode"] = zipcode;
          geolocObject["place_id"] = place_id;
          geolocObject["country"] = country;
          geolocObject["state"] = state;
          geolocObject["city"] = city;
          geolocObject["location"] = location;

          let countryFilter = _.filter(countryList, function (o) {
            return o.name == country;
          });
          if (countryFilter.length > 0) {
            props.autofill("country_id", countryFilter[0].id);
          }
          props.autofill("zip", zipcode);

          props.autofill("state", state);
          props.autofill("city", city);
          props.autofill("address", location);

          return getLatLng(results[0]);
        } else {
          let countryFilter = _.filter(countryList, function (o) {
            return o.name == country;
          });
          if (countryFilter.length > 0) {
            setCountryId(countryFilter[0].id);
            props.autofill("country_id", countryFilter[0].id);
          }
        }
      })
      .then((latLng) => {
        latitude = latLng.lat;
        longitude = latLng.lng;
        geolocObject["latitude"] = latitude;
        geolocObject["longitude"] = longitude;
        setGeolocObject(geolocObject);
      })
      .catch((error) => console.error("Error", error));
  };

  let countryOptions = [];
  if (countryList?.length > 0) {
    countryList.map((item, index) => {
      let obj = { name: item.name, value: item.id };
      countryOptions.push(obj);
    });
  }

  const isProductDeliverable = (address, cartitem) => {
    // console.log("address", address);
    // console.log("deliverablearea", cartitem.deliverablearea);

    if (
      cartitem.deliverablearea &&
      cartitem.deliverablearea.length > 0 &&
      address.length > 0
    ) {
      //console.log("deliverablearea", cartitem.deliverablearea);
      if (address[0].zip) {
        let pincodeArr = _.filter(cartitem.deliverablearea, function (obj) {
          return obj.pin_code == address[0].zip;
        });
        return pincodeArr.length > 0 ? true : false;
      } else if (address[0].zoneno) {
        let zoneArr = _.filter(cartitem.deliverablearea, function (obj) {
          return obj.zone == address[0].zoneno;
        });
        //console.log("zoneArr", zoneArr);
        return zoneArr.length > 0 ? true : false;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const onSubmitForm = (values) => {
    //console.log("form values", values);
    if (initialValues && initialValues.id) {
      setSubmitLoader(true);
      if (values.phone) {
        values.phone = values.phone.replace(/[- )( +]/g, "");
      }
      props.updateAddress(session, values, initialValues.id, (res) => {
        if (res.success == 1) {
          props.getUserAddressAll(props.session, (callBackResponce) => {
            if (
              callBackResponce.success &&
              callBackResponce.data &&
              callBackResponce.data.length > 0
            ) {
              if (props.source && props.source == "cart") {
                props.fetchCart(session, (feedback) => {
                  let defaultaddress = _.filter(
                    callBackResponce.data,
                    function (obj) {
                      return obj.id == initialValues.id;
                    }
                  );
                  //console.log("defaultaddress", defaultaddress);
                  if (defaultaddress.length > 0) {
                    callbackAdrress(defaultaddress);
                  }
                });
              }

              setSubmitLoader(false);
              toast.success(LocaleStrings.address_update_success);
              closeSlideOvers();
            }
          });
        } else {
          setSubmitLoader(false);
          toast.error(LocaleStrings.common_fail_message);
          closeSlideOvers();
        }
      });
    } else {
      setSubmitLoader(true);
      values.useridfk = session.userid;

      if (values.phone) {
        values.phone = values.phone.replace(/[- )( +]/g, "");
      }
      if (userAddressAll && userAddressAll.length == 0) {
        values.defaultaddress = 1;
      }
      props.addAddress(session, values, (res) => {
        if (res.success == 1) {
          props.getUserAddressAll(props.session, (callBackResponce) => {
            if (
              callBackResponce.success &&
              callBackResponce.data &&
              callBackResponce.data.length > 0
            ) {
              if (
                props.source &&
                props.source == "cart" &&
                callBackResponce.data.length == 1
              ) {
                let updateResource = [];
                _.map(getUserCart.items, (item, index) => {
                  let obj = {
                    id: item.cartid,
                    addressid: res.addressid,
                  };
                  updateResource.push(obj);
                });
                props.updateCart(session, updateResource, (updateres) => {
                  if (updateres.success == 1) {
                    props.fetchCart(session, (feedback) => {
                      let defaultaddress = _.filter(
                        callBackResponce.data,
                        function (obj) {
                          return obj.id == res.addressid;
                        }
                      );
                      //console.log("defaultaddress", defaultaddress);
                      if (defaultaddress.length > 0) {
                        callbackAdrress(defaultaddress);
                      }
                    });
                  }
                });
              } else if (props.source && props.source == "product") {
                setIschanged(true);
              }

              setSubmitLoader(false);
              toast.success(LocaleStrings.address_add_success);
              closeSlideOvers();
            }
          });
        } else {
          setSubmitLoader(false);
          toast.error(LocaleStrings.common_fail_message);
          closeSlideOvers();
        }
      });
    }
  };

  const handleCountry = (e) => {
    let countryid = e.target.value;
    //console.log("countryid", countryid);
    setCountryId(countryid);
  };

  const homeTypeOptions = [
    { text: "Home", value: "home" },
    { text: "Office", value: "office" },
    { text: "Other", value: "other" },
  ];
  let pointedAddress = "";
  if (address === "" || address === null) {
    // if (!_.isEmpty(initialValues)) {
    //   pointedAddress = initialValues.address;
    // } else {
    //   pointedAddress = "";
    // }
  } else {
    pointedAddress = address;
  }

  let zoneOptions = [];
  if (zoneList?.length > 0) {
    zoneList.map((item, index) => {
      let obj = { name: item.zone, value: item.id };
      zoneOptions.push(obj);
    });
  }

  const renderForm = useCallback(() => {
    return (
      <form
        onSubmit={handleSubmit(onSubmitForm)}
        style={{
          height: deviceSize.height,
        }}
      >
        <div className="bg-white h-full flex flex-col  shadow-xl">
          <div className="min-h-0 flex-1 flex flex-col pt-3 overflow-y-scroll">
            <div className="px-2 md:px-4">
              <div className="flex items-start justify-between">
                <Dialog.Title className="slider-modal-headtext">
                  <h2
                    id="slide-over-heading"
                    className="text-xl font-normal text-vendorLink"
                  >
                    {editMode
                      ? LocaleStrings.button_edit_address
                      : LocaleStrings.button_add_new_address}
                  </h2>
                </Dialog.Title>
                <div className="ml-3 h-7 flex items-center">
                  <button
                    type="button"
                    onClick={() => closeSlideOvers()}
                    className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>

            <div className="bg-newarrival relative flex-1 px-2 md:px-4">
              <div className="py-sm">
                <div className="mt-1 grid grid-cols-3">
                  {_.map(homeTypeOptions, (opt, index) => {
                    return (
                      <Field
                        name="addresstype"
                        label={opt.text}
                        value={opt.value}
                        //checked={index == 0 ? true : false}
                        //radioOnchange={radioOnchange}
                        lebelAlign="R"
                        type="radio"
                        component={renderFieldRadio1}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="py-sm">
                <Field
                  name="username"
                  placeholder={LocaleStrings.contact_name}
                  label={LocaleStrings.contact_name}
                  type="text"
                  component={renderFieldText}
                  className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div className="py-sm">
                <Field
                  name="phone"
                  placeholder={LocaleStrings.phone_no}
                  label={LocaleStrings.phone_no}
                  type="text"
                  phone={
                    phone != ""
                      ? phone
                      : initialValues && initialValues.phone
                      ? initialValues.phone
                      : ""
                  }
                  phoneChange={phoneChange}
                  component={renderPhonewithFlag}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div className="py-sm">
                <Field
                  name="country_id"
                  placeholder="Select from options"
                  label={LocaleStrings.country}
                  opts={countryOptions}
                  component={renderSelect}
                  onChange={(e) => handleCountry(e)}
                  className="appearance-none block w-full h-38 px-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                />
              </div>
              {countryid == 187 ? (
                <>
                  <div className="py-sm">
                    <Field
                      name="buildingno"
                      placeholder={LocaleStrings.buildingno}
                      label={LocaleStrings.buildingno}
                      type="text"
                      component={renderFieldText}
                      className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div className="py-sm">
                    <Field
                      name="zoneno"
                      placeholder="Select from options"
                      label={LocaleStrings.zoneno}
                      opts={zoneOptions}
                      component={renderSelect}
                      className="appearance-none block w-full h-38 px-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div className="py-sm">
                    <Field
                      name="streetno"
                      placeholder={LocaleStrings.streetno}
                      label={LocaleStrings.streetno}
                      type="text"
                      component={renderFieldText}
                      className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div className="py-sm">
                    <Field
                      name="unitno"
                      placeholder={LocaleStrings.unitno}
                      label={LocaleStrings.unitno}
                      type="text"
                      component={renderFieldText}
                      className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div className="py-sm">
                    <Field
                      name="poboxno"
                      placeholder={LocaleStrings.poboxno}
                      label={LocaleStrings.poboxno}
                      type="text"
                      component={renderFieldText}
                      className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="py-sm">
                    <PlacesAutocomplete
                      value={pointedAddress}
                      onChange={(e) => handleChange(e)}
                      onSelect={(add) => handleSelect(add)}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: LocaleStrings.search_places,
                              className:
                                "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm",
                            })}
                          />
                          <div
                            className={
                              Object.keys(suggestions).length > 0
                                ? "autocomplete-dropdown-container"
                                : "hidden"
                            }
                          >
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  key={suggestion.index}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>

                  <div className="py-sm">
                    <Field
                      name="address"
                      placeholder={LocaleStrings.address}
                      label={LocaleStrings.address}
                      type="textarea"
                      component={renderFieldTextarea}
                      className="appearance-none block w-full px-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div className="py-sm grid grid-cols-2 gap-4">
                    <div>
                      <Field
                        name="city"
                        placeholder={LocaleStrings.city}
                        label={LocaleStrings.city}
                        type="text"
                        component={renderFieldText}
                        className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <Field
                        name="zip"
                        placeholder={LocaleStrings.zip}
                        label={LocaleStrings.zip}
                        type="text"
                        component={renderFieldText}
                        className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="py-sm">
                    <Field
                      name="state"
                      placeholder={LocaleStrings.state}
                      label={LocaleStrings.state}
                      type="text"
                      component={renderFieldText}
                      className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="text-center p-2">
            <button
              type="submit"
              //className="h-8 pl-sm pr-sm bg-btn text-white rounded font-medium text-sm cursor-pointer"
              className="rounded py-3 px-5 w-full flex items-center justify-center bg-btn text-white font-medium text-sm cursor-pointer"
              disabled={pristine || invalid || submitting || submitLoader}
            >
              {submitLoader ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                ""
              )}
              <span className="text-sm">
                {editMode
                  ? LocaleStrings.button_update_address
                  : LocaleStrings.button_save_address}
              </span>
            </button>
          </div>
        </div>
      </form>
    );
  }, [
    editMode,
    pristine,
    invalid,
    submitting,
    submitLoader,
    homeTypeOptions,
    phone,
    initialValues,
    pointedAddress,
    loading,
    countryid,
    editcountryId,
  ]);

  //console.log("source", props.source);
  return (
    <>
      <Transition.Root show={newAddresModal} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          open={newAddresModal}
          onClose={() => closeSlideOvers()}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-md flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">{renderForm()}</div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const validate = (values, ownProps) => {
  var errors = {};
  //console.log("values", values);
  var regex = /^\s+$/;
  // if (!values.addresstype) {
  //   errors.addresstype = LocaleStrings.required;
  // }
  let onlyPhone = "";
  let countrycode = ownProps.phoneLengthCountry;
  if (values.phone) {
    values.phone = values.phone.replace(/[- )( +]/g, "");
    onlyPhone = values.phone.substring(ownProps.phoneLengthCountry.length);
  }

  if (!values.username) {
    errors.username = LocaleStrings.required;
  } else if (values.username.match(regex)) {
    errors.username = LocaleStrings.required;
  }
  if (!values.phone) {
    errors.phone = LocaleStrings.form_validation_phone_no_required;
  } else if (countrycode == "974") {
    if (isValidQatarPhoneNumber("+" + values.phone) == false) {
      errors.phone = LocaleStrings.form_validation_phone_no_valid;
    }
  }
  if (!values.country_id) {
    errors.country_id = LocaleStrings.required;
  }
  if (values.country_id == 187) {
    if (!values.buildingno) {
      errors.buildingno = LocaleStrings.required;
    } else if (values.buildingno && values.buildingno.match(regex)) {
      errors.buildingno = LocaleStrings.required;
    }
    if (!values.zoneno) {
      errors.zoneno = LocaleStrings.required;
    }
    // } else if (values.zoneno && values.zoneno.match(regex)) {
    //   errors.zoneno = LocaleStrings.required;
    // }
    if (!values.streetno) {
      errors.streetno = LocaleStrings.required;
    } else if (values.streetno && values.streetno.match(regex)) {
      errors.streetno = LocaleStrings.required;
    }

    if (!values.unitno) {
      errors.unitno = LocaleStrings.required;
    } else if (values.unitno && values.unitno.match(regex)) {
      errors.unitno = LocaleStrings.required;
    }
    // if (!values.poboxno) {
    //   errors.poboxno = LocaleStrings.required;
    // } else if (values.poboxno && values.poboxno.match(regex)) {
    //   errors.poboxno = LocaleStrings.required;
    // }
  } else {
    if (!values.address) {
      errors.address = LocaleStrings.required;
    } else if (values.address.match(regex)) {
      errors.address = LocaleStrings.required;
    }
    if (!values.city) {
      errors.city = LocaleStrings.required;
    } else if (values.city.match(regex)) {
      errors.city = LocaleStrings.required;
    }
    if (!values.zip) {
      errors.zip = LocaleStrings.required;
    } else if (values.zip.match(regex)) {
      errors.zip = LocaleStrings.required;
    }
    if (!values.state) {
      errors.state = LocaleStrings.required;
    } else if (values.state.match(regex)) {
      errors.state = LocaleStrings.required;
    }
  }

  return errors;
};

const mapStateToProps = (state) => {
  var initVals = {};
  var editMode = false;
  //console.log("state.editAddress", state.editAddress);
  if (state.editAddress && state.editAddress.id) {
    initVals = state.editAddress;
    editMode = true;
  } else {
    initVals.addresstype = "home";
  }
  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    localLanguage: state.localLanguage,
    newAddresModal: state.newAddresModal,
    getUserCart: state.getUserCart,
    userAddressAll: state.userAddressAll,
    countryList: state.countryList,
    deviceSize: state.deviceSize,
    initialValues: initVals,
    editMode: editMode,
    phoneLengthCountry: state.phoneLengthCountry,
    zoneList: state.zoneList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isNewAddressModalOpen,
      makeDefaultAddress,
      updateCart,
      fetchCart,
      addAddress,
      getUserAddressAll,
      updateAddress,
      countryPhoneLength,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "NewCartForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(NewAddress)
);
