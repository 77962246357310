import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages";
import _, { isEmpty } from "lodash";
import {
  classNames,
  timeAgo,
  APP_API_KEY,
  FILES_URL,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";

// Import Actions
import {
  isSendMessageModalOpen,
  fetchMessageList,
  fetchThreadMessagesList,
  makeThreadMessagesBlank,
  sendMessageStore,
  NotificationSingleObject,
  makeNotificationDetailsEmpty,
  changeMessageTabName,
  updateOrderStep, // Fc to change order step
  fetchOrderThreadMsgList,
  updateBasicOrderthreadData,
  sendOrderMessageToStore,
  fetchOrderMessageList,
  getOrderThreadWithId,
} from "../actions/index";
// Component Import
import NoContentSlideOver from "../../../components/Common/no-content-slideover"; // Common No Content For Slide Over Modals
import Loader from "../../../components/Common/loader";
import SendbtnLogo from "../../../assets/images/send_btn.svg";
import NoImg from "../../../assets/images/noimg.png";

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import toast, { Toaster } from "react-hot-toast";

class Message extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      messageText: "",
      stateThreadid: "",
      loading: true,
      btnLoader: false,
      loaderDesc: LocaleStrings.preparing_messages,
      receiverName: "",
      productName: "",
    };

    // All Binded Functions
    this.closeSlideOvers = this.closeSlideOvers.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      setInterval(() => {
        if (this.props.isMessageOpen) {
          this.props.fetchMessageList(this.props.session, (callBack) => {
            this.setState({ loading: false });
          });
          // Orders
          this.props.fetchOrderMessageList(
            this.props.session,
            (callBack) => {}
          );
        }
      }, 3000);
    }
  }

  componentDidUpdate() {
    let { notifiSingleObjectStore } = this.props; // For Notification

    if (!_.isEmpty(notifiSingleObjectStore)) {
      if (
        notifiSingleObjectStore.notifications_by_notificationid
          .notificationtype === "newmessage"
      ) {
        let threadid =
          notifiSingleObjectStore.notifications_by_notificationid.threadid;
        this.viewMessage(threadid);
        this.props.NotificationSingleObject({}); // When Close Modal Blank Already selected Notification Object
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState({ step: 1, receiverName: "", productName: "" });
  }

  /**** Close Slide Over  ****/
  closeSlideOvers() {
    this.props.isSendMessageModalOpen(false);
    this.props.NotificationSingleObject({}); // When Close Modal Blank Already selected Notification Object
    this.props.makeNotificationDetailsEmpty({}); // When Close Modal Blank Already selected Notification Object
    this.props.makeThreadMessagesBlank((res) => {});
    this.setState({ step: 1 });
  }

  // Arrow Function for Message List
  renderProductList = (messageList) => {
    let { language } = this.props;

    var itemx = _.map(messageList, (item, index) => {
      let productImageUrl = `${FILES_URL}/vendor/variations/${item.productdetails.productimage}?api_key=${APP_API_KEY}`;

      return (
        <li key={index} className="py-1 px-4 cursor-pointer">
          <div
            className="grid grid-cols-5 mb-md pt-3 cursor-pointer"
            onClick={() => this.viewMessage(item.id)}
          >
            <div className="">
              <img
                className="w-60 object-cover rounded-md"
                src={productImageUrl}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = NoImg;
                }}
                alt="Image"
              />
            </div>
            <div className="col-span-3">
              <div className="flex">
                <div className="w-48">
                  <div className="text-sm font-medium text-primary truncate">
                    {ProductTextLocalized(
                      item.productdetails.productname_en,
                      item.productdetails.productname_ar,
                      language
                    )}
                  </div>
                  {item.lastmessage ? (
                    <div className="text-sm text-ternary truncate">
                      {item.lastmessage}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={classNames(
                    item.unreadcount === 1 || item.unreadcount === "1"
                      ? ""
                      : "hidden",
                    "flex-shrink-0 w-2.5 h-2.5 rounded-full bg-yellow-500 mx-2 mt-1.5"
                  )}
                  aria-hidden="true"
                ></div>
              </div>
            </div>
            <div className="text-xs text-ternary  text-right">
              {item.lastmessagesentat ? timeAgo(item.lastmessagesentat) : ""}
            </div>
          </div>
        </li>
      );
    });

    return itemx;
  };

  viewMessage = (threadid) => {
    this.setState({ loading: true });
    let { session, language } = this.props;

    this.setState({ stateThreadid: threadid });
    // props.emptyMessageList();
    this.props.fetchThreadMessagesList(session, parseInt(threadid), (res) => {
      let product_name = "";
      let receiver_name = "";

      if (
        res &&
        res.data &&
        res.data.thread &&
        res.data.thread.productdetails
      ) {
        product_name = ProductTextLocalized(
          res.data.thread.productdetails.productname_en,
          res.data.thread.productdetails.productname_ar,
          language
        );
        receiver_name = res.data.thread.receiver.username;
        //res.data.thread.receiver.username
      }

      this.setState({
        loading: false,
        receiverName: receiver_name,
        productName: product_name,
      });
    });
    this.setState({ step: 2 });
  };

  handleMessage = (e) => {
    let msg = e.target.value;
    this.setState({ messageText: msg });
  };

  sendMessage = () => {
    this.setState({ btnLoader: true });
    let { session, selectedMessageTab, basicOrderThreadData } = this.props;
    let { messageText, stateThreadid } = this.state;

    messageText = _.trim(messageText);

    if (isEmpty(messageText)) {
      toast.error(LocaleStrings.type_message);
      this.setState({ btnLoader: false });
      return false;
    }
    if (selectedMessageTab === "product") {
      let values = {};

      values.threadid = stateThreadid;
      values.senderid = session.userid;
      values.type = "text";
      values.text = messageText;

      this.props.sendMessageStore(session, values, (res) => {
        this.setState({ messageText: "" });

        if (res.success == 1) {
          this.props.fetchMessageList(this.props.session, (callBack) => {});

          this.props.fetchThreadMessagesList(
            session,
            parseInt(stateThreadid),
            (res) => {
              this.setState({ btnLoader: false });
            }
          );
        } else {
        }
      });
    } else {
      let values = {};

      values.threadid = basicOrderThreadData.threadid;
      values.senderid = session.userid;
      values.type = "text";
      values.text = messageText;

      this.props.sendOrderMessageToStore(session, values, (res) => {
        this.setState({ messageText: "" });

        if (res.success == 1) {
          this.props.fetchOrderThreadMsgList(
            session,
            parseInt(basicOrderThreadData.threadid),
            (res) => {
              this.setState({ btnLoader: false });
            }
          );
        } else {
        }
      });
    }
  };

  /**** Back To Step 1 [Variant List] ****/
  goBack = () => {
    // props.fetchMessageThread(session);
    this.setState({ step: 1 });
    this.props.updateOrderStep(1);
    this.setState({ stateThreadid: "" });
  };

  /*** Render Thread Messages ***/
  renderThreadMessageList = (item) => {
    let { session } = this.props;
    return parseInt(item.senderid) == session.userid ? (
      <div className="flex justify-end pt-3.5">
        <div>
          <div
            className="bg-btn text-white px-3 py-2 text-sm"
            style={{ borderRadius: "22px 22px 5px 22px" }}
          >
            {item.text}
          </div>
          <div className="text-timef text-xs">{timeAgo(item.createdat)}</div>
        </div>
      </div>
    ) : (
      <div className="flex justify-start">
        <div className="pr-3">
          <img
            src={`${FILES_URL}/users/${item.senderid}.jpg?api_key=${APP_API_KEY}`}
            className="rounded-full h-10 w-10"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = NoImg;
            }}
          />
          <div className="text-ternary text-xs mt-2">
            {this.state.receiverName}
          </div>
        </div>
        <div>
          <div
            className="bg-white text-secondary px-3.5 py-3"
            style={{ borderRadius: "22px 22px 22px 5px" }}
          >
            {item.text}
          </div>
          <div className="text-timef text-xs mt-2">
            {timeAgo(item.createdat)}
          </div>
        </div>
      </div>
    );
  };

  /*** Render ThreadMessageList ***/
  renderOrdThreadMegList = (item) => {
    // console.log({ item });
    let { session } = this.props;
    return parseInt(item.senderid) == session.userid ? (
      <div className="flex justify-end pt-3.5">
        <div>
          <div
            className="bg-btn text-white px-3 py-2"
            style={{ borderRadius: "22px 22px 5px 22px" }}
          >
            {item.text}
          </div>
          <div className="text-ternary text-xs">{timeAgo(item.createdat)}</div>
        </div>
      </div>
    ) : (
      <div className="flex justify-start">
        <div className="pr-3">
          <img
            loading="lazy"
            src={`${FILES_URL}/users/${item.senderid}.jpg?api_key=${APP_API_KEY}`}
            className="user-img-rounded"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = NoImg;
            }}
          />
          <div className="text-ternary text-xs mt-2">
            {item.users_by_senderid.username}
          </div>
        </div>
        <div>
          <div
            className="bg-newarrival text-secondary px-3 py-2"
            style={{ borderRadius: "22px 22px 22px 5px" }}
          >
            {item.text}
          </div>

          <div className="text-ternary text-xs mt-2">
            {timeAgo(item.createdat)}
          </div>
        </div>
      </div>
    );
  };

  /* View order messages */
  viewOrdMessage = (thread) => {
    // console.log("orderdata", thread);
    this.setState({ loading: true });
    let { session, language } = this.props;

    this.props.getOrderThreadWithId(session, parseInt(thread.id), (res) => {});
    this.props.fetchOrderThreadMsgList(
      session,
      parseInt(thread.id),
      (res) => {}
    );

    this.props.updateBasicOrderthreadData({
      threadid: parseInt(thread?.id),
      sku: thread?.orderdata[0]?.sku,
      firstuserid: thread?.orderdata[0]?.firstuserid,
      seconduserid: thread?.orderdata[0]?.seconduserid,
      invoice_no: thread?.orderdata[0]?.invoice_no,
      productname_en: thread?.orderdata[0]?.productname_en,
      productname_ar: thread?.orderdata[0]?.productname_ar,
      seller: thread?.orderdata[0]?.seller?.username,
    });
    this.props.updateOrderStep(2);
  };

  /* Render order message thread list */
  renderOrderList = (odDataList) => {
    let { language } = this.props;

    let itemod = _.map(odDataList, (item, index) => {
      let orddetails = item?.orderdata[0];
      let productImageUrl = `${FILES_URL}/vendor/variations/${item?.orderdata[0]?.productimage}?api_key=${APP_API_KEY}`;

      return (
        <li key={index} className="py-1 px-4 cursor-pointer">
          <div
            className="grid grid-cols-5 mb-md pt-3 cursor-pointer"
            onClick={() => this.viewOrdMessage(item)}
          >
            <div className="">
              <img
                loading="lazy"
                className="w-60 object-cover rounded-md"
                src={productImageUrl}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = NoImg;
                }}
                alt="Image"
              />
            </div>
            <div className="col-span-3">
              <div className="flex">
                <div className="w-48">
                  <div className="text-sm font-medium text-primary truncate">
                    {ProductTextLocalized(
                      orddetails.productname_en,
                      orddetails.productname_ar,
                      language
                    )}
                  </div>
                  {item.lastmessage ? (
                    <div className="text-sm text-ternary truncate">
                      {item.lastmessage}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* Unread count message circel style */}
                <div
                  className={classNames(
                    item.unreadcount === 1 || item.unreadcount === "1"
                      ? ""
                      : "hidden",
                    "flex-shrink-0 w-2.5 h-2.5 rounded-full bg-yellow-500 mx-2 mt-1.5"
                  )}
                  aria-hidden="true"
                ></div>
              </div>
            </div>
            <div className="text-xs text-ternary  text-right pt-0.5">
              {item.lastmessagesentat ? timeAgo(item.lastmessagesentat) : ""}
            </div>
          </div>
        </li>
      );
    });

    return itemod;
  };

  render() {
    let {
      isMessageOpen,
      language,
      messageList,
      storeThreadMessageList,
      orderMessageList,
      storeOrdMessageList,
      selectedMessageTab,
      currentOrderStep,
      basicOrderThreadData,
    } = this.props;
    let { loading, step, btnLoader } = this.state;

    let footerStep = selectedMessageTab === "product" ? step : currentOrderStep;
    //console.log("orderMessageList", orderMessageList);
    //console.log("storeOrdMessageList", storeOrdMessageList);
    return (
      <>
        <Transition.Root show={isMessageOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 overflow-hidden z-50"
            open={isMessageOpen}
            onClose={this.closeSlideOvers}
          >
            <div className="absolute inset-0 overflow-hidden">
              <Dialog.Overlay className="absolute inset-0" />

              <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="w-screen max-w-md">
                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                      <div className="min-h-0 flex-1 flex flex-col pt-3 overflow-y-scroll">
                        <div className="px-4 sm:px-6">
                          {/* Header part [Starts] */}
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="slider-modal-headtext">
                              {footerStep === 1 ? (
                                LocaleStrings.message_modal_header_text
                              ) : (
                                <>
                                  <div className="flex items-center">
                                    <div>
                                      <ArrowLeftIcon
                                        className="h-6 w-6 cursor-pointer text-link"
                                        onClick={() => this.goBack()}
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div>
                                      <h2
                                        id="slide-over-heading"
                                        className="pl-4 text-xl font-normal text-link"
                                      >
                                        {LocaleStrings.send_message}
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="items-center">
                                    <div className="text-sm font-medium text-numbid --truncate mt-4 pl-3">
                                      <p>
                                        {selectedMessageTab === "product"
                                          ? this.state.productName
                                          : ProductTextLocalized(
                                              basicOrderThreadData?.productname_en,
                                              basicOrderThreadData?.productname_ar,
                                              language
                                            )}
                                      </p>
                                      <p>{basicOrderThreadData?.invoice_no}</p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </Dialog.Title>
                            <div className="ml-3 h-7 flex items-center">
                              <button
                                type="button"
                                className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                                onClick={this.closeSlideOvers}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                          {/* Header part [Ends] */}
                          {/* Toggle from product to order */}

                          <span
                            className={classNames(
                              step === 2 || currentOrderStep === 2
                                ? "sr-only"
                                : "",
                              "relative z-0 inline-flex shadow-sm rounded-md my-2 w-full"
                            )}
                          >
                            <button
                              type="button"
                              className={classNames(
                                selectedMessageTab === "product"
                                  ? "bg-secondary"
                                  : "",
                                "messages-tab-button  rounded-l-md w-full text-center"
                              )}
                              onClick={() =>
                                this.props.changeMessageTabName("product")
                              }
                            >
                              {LocaleStrings.message_tab_product}
                            </button>

                            <button
                              type="button"
                              className={classNames(
                                selectedMessageTab === "order"
                                  ? "bg-secondary"
                                  : "",
                                "messages-tab-button -ml-px rounded-r-md w-full text-center"
                              )}
                              onClick={() =>
                                this.props.changeMessageTabName("order")
                              }
                            >
                              {LocaleStrings.message_tab_order}
                            </button>
                          </span>
                        </div>
                        {selectedMessageTab === "product" ? (
                          /* Product list */
                          <div className="mt-2 relative flex-1 px-4 sm:px-6 ">
                            {/* Message List Section [step - 1] */}
                            {step === 1 ? (
                              <ul className="bg-newarrival divide-y divide-grey-100 m-0">
                                {!loading ? (
                                  <>
                                    {_.isEmpty(messageList) ? (
                                      <NoContentSlideOver
                                        imageprop={"../images/message.svg"}
                                        headerprop={
                                          LocaleStrings.no_message_header
                                        }
                                        subheaderprop={
                                          LocaleStrings.no_message_subheader
                                        }
                                      />
                                    ) : (
                                      this.renderProductList(messageList)
                                    )}
                                  </>
                                ) : (
                                  <Loader data={LocaleStrings.loader_message} />
                                )}
                              </ul>
                            ) : !loading ? (
                              !_.isEmpty(storeThreadMessageList) ? (
                                _.map(storeThreadMessageList, (item, index) => {
                                  {
                                    /* Message Thread List Section [step - 2] */
                                  }
                                  return this.renderThreadMessageList(item);
                                })
                              ) : (
                                <NoContentSlideOver
                                  imageprop={"../images/Message.svg"}
                                  headerprop={LocaleStrings.no_message_header}
                                  subheaderprop={
                                    LocaleStrings.no_message_subheader
                                  }
                                />
                              )
                            ) : (
                              <Loader data={LocaleStrings.loader_message} />
                            )}
                          </div>
                        ) : (
                          /* Order thread list */
                          <div className="mt-2 relative flex-1 px-4 sm:px-6 ">
                            {currentOrderStep === 1 ? (
                              <ul className="bg-newarrival divide-y divide-grey-100 m-0">
                                {!loading ? (
                                  <>
                                    {_.isEmpty(orderMessageList) ? (
                                      <NoContentSlideOver
                                        imageprop={"../images/message.svg"}
                                        headerprop={
                                          LocaleStrings.no_message_header
                                        }
                                        subheaderprop={
                                          LocaleStrings.no_message_subheader
                                        }
                                      />
                                    ) : (
                                      this.renderOrderList(orderMessageList)
                                    )}
                                  </>
                                ) : (
                                  <Loader data={LocaleStrings.loader_message} />
                                )}
                              </ul>
                            ) : !loading ? (
                              !_.isEmpty(storeOrdMessageList) ? (
                                _.map(storeOrdMessageList, (item, index) => {
                                  {
                                    /* Message Thread List Section [step - 2] */
                                  }
                                  return this.renderOrdThreadMegList(item);
                                })
                              ) : (
                                <NoContentSlideOver
                                  imageprop={"../images/message.svg"}
                                  headerprop={LocaleStrings.no_message_header}
                                  subheaderprop={
                                    LocaleStrings.no_message_subheader
                                  }
                                />
                              )
                            ) : (
                              <Loader data={LocaleStrings.loader_message} />
                            )}
                          </div>
                        )}
                      </div>
                      {/* Footer */}
                      <div
                        className={classNames(
                          footerStep === 1 ? "hidden" : "",
                          "flex-shrink-0 px-4 py-4  justify-center"
                        )}
                      >
                        {footerStep === 1 ? (
                          ""
                        ) : (
                          /* Question Section */
                          <div>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              <input
                                type="text"
                                name="comment_of_question_input"
                                id="comment_of_question_input"
                                className="bg-newarrival w-full rounded-full h-44 text-loginLabel text-15 pl-5 pr-12 focus:outline-none"
                                placeholder={LocaleStrings.type_message}
                                value={this.state.messageText}
                                onChange={(e) => this.handleMessage(e)}
                              />
                              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                                {btnLoader ? (
                                  <svg
                                    className="animate-spin-slow h-5 w-5 rounded-full mr-3"
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                ) : (
                                  <img
                                    src={SendbtnLogo}
                                    className="cursor-pointer"
                                    onClick={(e) => this.sendMessage(e)}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    isMessageOpen: state.isMessageOpen,
    storeThreadMessageList: state.storeThreadMessageList
      ? state.storeThreadMessageList.data
        ? state.storeThreadMessageList.data
        : {}
      : {},
    messageList: state.messageList
      ? state.messageList.data
        ? state.messageList.data
        : {}
      : {},
    notifiSingleObjectStore: _.isEmpty(state.notifiSingleObjectStore)
      ? []
      : state.notifiSingleObjectStore,
    selectedMessageTab: state.selectedMessageTab,
    currentOrderStep: state.currentOrderStep,
    basicOrderThreadData: state.basicOrderThreadData,
    orderMessageList: state.orderMessageList
      ? state.orderMessageList.data
        ? state.orderMessageList.data
        : {}
      : {},
    storeOrdMessageList: state.storeOrdMessageList
      ? state.storeOrdMessageList.data
        ? state.storeOrdMessageList.data
        : {}
      : {},
  };
}

export default connect(mapStateToProps, {
  isSendMessageModalOpen,
  fetchMessageList,
  fetchThreadMessagesList,
  makeThreadMessagesBlank, // Use make thread messages blank [when unmount]
  sendMessageStore,
  NotificationSingleObject,
  makeNotificationDetailsEmpty,
  changeMessageTabName,
  updateOrderStep, // Fc to change order step
  fetchOrderThreadMsgList,
  updateBasicOrderthreadData,
  sendOrderMessageToStore,
  fetchOrderMessageList,
  getOrderThreadWithId,
})(Message);
