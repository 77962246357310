import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  currencyCode,
  currencyRate,
} from "../../../components/Common/product-model";
import {
  timeDiffCalc,
  classNames,
  timeDiffRemaining,
} from "../../../components/Common/constant";
import {
  renderFieldText,
  renderFieldCheckbox,
  number,
} from "../../../components/Common/base-component";
import {
  openBidPlaceModal,
  getBiddingPrice,
  getProductCounter,
  placeBid,
  fetchPublicBids,
} from "../actions";
import { fetchMyWatchlist } from "../../Watchlist/actions";
import { Dialog, Transition } from "@headlessui/react";
import EyeIcon from "../../../assets/images/eye.svg";
import HammerIcon from "../../../assets/images/hammer.svg";

function PlaceBid(props) {
  const {
    handleSubmit,
    bidPlaceModal,
    bidSuggestion,
    productCounter,
    session,
    localCurrency,
    pristine,
    invalid,
    submitting,
    publicBids,
  } = props;
  const [loading, setLoading] = useState(false);
  const [timeInterval, setTimeInterval] = useState(0);
  const [timeText, setTimeText] = useState("");
  const [remainingTime, setRemainingTime] = useState({});
  const cancelButtonRef = useRef(null);

  let currency = currencyCode(localCurrency);
  let currencyrate = currencyRate(localCurrency);

  var auctionText = "";
  var endDate = "";

  let currentDate = moment();
  if (
    bidPlaceModal &&
    bidPlaceModal.auction_start_date &&
    bidPlaceModal.auction_end_date &&
    currentDate.isAfter(moment(bidPlaceModal.auction_start_date)) &&
    currentDate.isBefore(moment(bidPlaceModal.auction_end_date))
  ) {
    auctionText = "Ends in";
    endDate = bidPlaceModal.auction_end_date;
  } else if (
    bidPlaceModal.auction_start_date &&
    bidPlaceModal.auction_end_date &&
    currentDate.isBefore(moment(bidPlaceModal.auction_start_date)) &&
    currentDate.isBefore(moment(bidPlaceModal.auction_end_date))
  ) {
    auctionText = "Starts in";
    endDate = bidPlaceModal.auction_start_date;
  }

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 2000);

  useEffect(() => {
    if (bidPlaceModal && bidPlaceModal.productid && bidPlaceModal.variantid) {
      let variantid = bidPlaceModal.variantid;
      let productid = bidPlaceModal.productid;
      props.fetchPublicBids(session, variantid);
      props.getBiddingPrice(session, productid, variantid);
      props.getProductCounter(session, productid, variantid);
      let timeText = timeDiffCalc(
        new Date(endDate.replace(/-/g, "/")),
        new Date()
      );
      setTimeText(timeText);

      let remObj = timeDiffRemaining(
        new Date(endDate.replace(/-/g, "/")),
        new Date()
      );
      setRemainingTime(remObj);
    }
  }, [timeInterval, endDate]);

  const onSubmitForm = (values) => {
    //console.log("values", values);
    //debugger;
    setLoading(true);
    let currency = currencyCode(localCurrency);
    let currencyrate = currencyRate(localCurrency);

    let variantid = bidPlaceModal.variantid;
    let productid = bidPlaceModal.productid;
    values.productid = productid;
    values.variantid = variantid;
    values.isprivate = values.isprivate ? 1 : 0;
    values.bidprice = values.bidprice;

    props.placeBid(session, values, (res) => {
      setLoading(false);
      if (res.success == 0) {
      } else {
        props.reset();
        props.setIschanged(true);
        props.getBiddingPrice(session, productid, variantid);
        props.getProductCounter(session, productid, variantid);
        props.fetchPublicBids(session, variantid);
        props.fetchMyWatchlist(session, "watchlist");
        props.openBidPlaceModal(false);
      }
    });
  };

  const makeOffer = (price) => {
    props.autofill("bidprice", price);
  };

  const onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) event.preventDefault();
  };

  let myPlacesBid = [];
  let myHighestBid = [];
  if (publicBids && publicBids.data && publicBids.data.length > 0) {
    let variantid = bidPlaceModal.variantid;
    let productid = bidPlaceModal.productid;
    myPlacesBid = _.filter(publicBids.data, function (o) {
      return (
        o.variantid == variantid &&
        o.userid == session.userid &&
        o.productid == productid
      );
    });
    console.log("myPlacesBid", myPlacesBid);
    if (myPlacesBid && myPlacesBid.length > 0) {
      myHighestBid = _.orderBy(myPlacesBid, ["bidprice"], ["desc"]);
    }
  }

  //console.log("myHighestBid", myHighestBid);
  //console.log("bidSuggestion", bidSuggestion);
  return (
    <Transition.Root
      show={bidPlaceModal && bidPlaceModal.showModal}
      as={Fragment}
    >
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={bidPlaceModal && bidPlaceModal.showModal}
        onClose={() => props.openBidPlaceModal(false)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg pt-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="px-5 grid grid-cols-2 gap-1 border-b pb-3">
                <div className="text-left text-primary font-normal text-xl">
                  {LocaleStrings.place_a_bid}
                </div>
                <div
                  className="text-right text-link font-normal text-base cursor-pointer"
                  onClick={() => props.openBidPlaceModal(false)}
                  ref={cancelButtonRef}
                >
                  {LocaleStrings.cancel}
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <div className="pt-4">
                  <div className="mt-3 ml-4 mr-4 border-b pb-3.5">
                    <div className="flex">
                      <div className="pr-5">
                        <div className="bg-bid rounded-full w-46 h-46 flex items-center justify-center">
                          <img src={HammerIcon} />
                        </div>
                      </div>
                      <div className="mt-0">
                        <div className="pb-2">
                          {bidPlaceModal && bidPlaceModal.product_name
                            ? bidPlaceModal.product_name
                            : ""}
                        </div>
                        <div className="grid grid-cols-2">
                          <div>
                            <div className="text-secondary text-xs">
                              {publicBids &&
                              publicBids.data &&
                              publicBids.data.length > 0
                                ? LocaleStrings.highest_bid
                                : LocaleStrings.starting_bid}
                            </div>
                            <div className="pb-1 text-primary text-base">
                              {bidSuggestion && bidSuggestion.lastbiddingprice
                                ? `${currency} ${bidSuggestion.lastbiddingprice}`
                                : bidSuggestion && bidSuggestion.minbidprice
                                ? `${currency} ${bidSuggestion.minbidprice}`
                                : ""}
                            </div>
                          </div>
                          <div className="text-right">
                            <div className="text-secondary text-xs">
                              {myHighestBid && myHighestBid.length > 0
                                ? LocaleStrings.your_last_bid
                                : ""}
                            </div>
                            <div className="pb-1 text-primary text-base">
                              {myHighestBid &&
                              myHighestBid.length > 0 &&
                              myHighestBid[0].bidprice
                                ? `${currency} ${myHighestBid[0].bidprice}`
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-3">
                          {auctionText && endDate ? (
                            remainingTime.days <= 0 &&
                            remainingTime.hours <= 24 ? (
                              <div className="flex items-center justify-center bg-red-500w-full text-white rounded-xl pt-1 pb-1 text-xs">
                                {timeText}
                              </div>
                            ) : (
                              <div className="flex items-center justify-center bg-topnav w-full text-white rounded-xl pt-1 pb-1 text-xs">
                                {timeText}
                              </div>
                            )
                          ) : (
                            ""
                          )}
                          {productCounter &&
                          productCounter.numbids != undefined &&
                          productCounter.numwatching != undefined ? (
                            <div className="text-right grid grid-cols-2 gap-3">
                              <div className="text-xs flex items-center">
                                <div className="pr-1.5 text-lg text-secondary">
                                  {productCounter.numbids}
                                </div>
                                <div className="text-lg text-secondary">
                                  {" "}
                                  {productCounter.numbids > 1
                                    ? LocaleStrings.bid
                                    : LocaleStrings.nobid}
                                </div>
                              </div>
                              <div className="text-xs flex items-center">
                                <div className="pr-0.5">
                                  <img src={EyeIcon} className="w-6" />
                                </div>
                                <div className="text-xs text-cardfooter">
                                  {productCounter.numwatching}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 ml-4 mr-4">
                    <div className="">{LocaleStrings.make_offer}</div>
                    <div className="mt-2 grid grid-cols-3 md:grid-cols-5 gap-1">
                      {bidSuggestion &&
                      bidSuggestion.suggestions &&
                      bidSuggestion.suggestions.length > 0
                        ? _.map(bidSuggestion.suggestions, (sitem, index) => {
                            return (
                              <div
                                key={index}
                                className="rounded border border-bidbox text-primary text-sm flex items-center justify-center h-41 cursor-pointer"
                                onClick={() => makeOffer(sitem)}
                              >
                                {`${currency} ${sitem}`}
                              </div>
                            );
                          })
                        : ""}
                    </div>
                    <div className="mt-md relative">
                      <Field
                        name="bidprice"
                        type="number"
                        component={renderFieldText}
                        onKeyPress={onKeyPress}
                        currency={currency}
                        classNameEye="absolute flex items-center text-sm top-3 left-2 cursor-pointer"
                        className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <div className="mt-md">
                      <button
                        type="submit"
                        className="text-white h-44 bg-btn text-sm font-normal rounded w-full inline-flex items-center justify-center"
                        disabled={pristine || invalid || submitting || loading}
                      >
                        {loading ? (
                          <svg
                            className="animate-spin h-5 w-5 rounded-full mr-3"
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                        ) : (
                          ""
                        )}
                        <span className="text-sm">
                          {LocaleStrings.place_bid}
                        </span>
                      </button>
                    </div>
                    <div className="mt-md">
                      <Field
                        name="isprivate"
                        label={LocaleStrings.bid_private_text}
                        type="checkbox"
                        className="focus:ring-primary h-4 w-4 text-primary border-ternary rounded"
                        component={renderFieldCheckbox}
                        classNameLabel="text-primary text-sm font-normal"
                      />
                    </div>
                    <div className="mt-md">
                      <Field
                        name="bid_commited"
                        label={LocaleStrings.bid_commited_text}
                        component={renderFieldCheckbox}
                        type="checkbox"
                        className="focus:ring-primary h-4 w-4 text-primary border-ternary rounded"
                        classNameLabel="text-primary text-sm font-normal"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const validate = (values, ownProps) => {
  //console.log("values", values);
  //console.log("ownProps", ownProps);
  var errors = {};

  if (!values.bidprice) {
    errors.bidprice = LocaleStrings.place_a_bid_price_error;
  } else if (values.bidprice && number(values.bidprice)) {
    errors.bidprice = "Please enter number";
  }
  if (
    ownProps.bidSuggestion &&
    ownProps.bidSuggestion.minbidprice &&
    parseFloat(values.bidprice) < parseFloat(ownProps.bidSuggestion.minbidprice)
  ) {
    errors.bidprice = LocaleStrings.place_a_bid_min_price_error;
  }
  if (!values.bid_commited) {
    errors.bid_commited = LocaleStrings.place_a_bid_commited_error;
  }
  //console.log("errors", errors);
  return errors;
};

const mapStateToProps = (state) => {
  var initVals = {};

  initVals = { isprivate: false, bid_commited: true };

  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    initialValues: initVals,
    localLanguage: state.localLanguage,
    deviceSize: state.deviceSize,
    bidPlaceModal: state.bidPlaceModal,
    bidSuggestion: state.bidSuggestion,
    productCounter: state.productCounter,
    localCurrency: state.localCurrency,
    publicBids: state.publicBids,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openBidPlaceModal,
      getBiddingPrice,
      getProductCounter,
      placeBid,
      fetchPublicBids,
      fetchMyWatchlist,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "BidForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(PlaceBid)
);
