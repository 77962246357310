import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LocaleStrings from "../../languages/index";
import _ from "lodash";
import { classNames } from "../Common/constant";
import { Transition, Dialog, RadioGroup } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { currencyModalOpen, setCurrency } from "../../actions";
import { fetchCart } from "../../pages/Product/actions";
import { fetchOrderDetails } from "../../pages/Myorders/actions"
import UsdIocn from "../../assets/images/usd.png";
import QarIocn from "../../assets/images/qar.png";
import { useLocation } from "react-router-dom";
const settings = [
  { name: "QAR", description: LocaleStrings.qatari_riyal, src: QarIocn },
  { name: "USD", description: LocaleStrings.dollar, src: UsdIocn },
];
function Currency(props) {
  const location = useLocation();

  const { currencyModal, localCurrency, session } = props;
  const [selected, setSelected] = useState(settings[1]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localCurrency == "QAR") {
      setSelected(settings[0]);
    }
  }, []);

  const closeModal1 = () => {};

  const closeModal = () => {
    props.currencyModalOpen(false);
  };

  const onRadioChange = (e) => {
    setSelected(e);
  };

  const saveCurrency = () => {
    setLoading(true);
    //debugger;
    let pathname = location.pathname;
    let pathnameArr = pathname.split('/');
    console.log('pathnameArr',pathnameArr)

    props.setCurrency(session, selected.name, (res) => {
      setLoading(false);
      props.fetchCart(session, (res) => {});
      if(pathnameArr.length >= 2 && pathnameArr[1] == 'order-details' && pathnameArr[2] != ''){
        let orderid = pathnameArr[2];
        props.fetchOrderDetails(session, orderid, (res) => {
          
        });
      }
      closeModal();
    });
  };

  //console.log("localCurrency", localCurrency);
  return (
    <>
      <Transition.Root show={currencyModal} as={Fragment}>
        <Dialog
          // initialFocus={this.myRef}
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={currencyModal}
          onClose={closeModal1}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                style={{ padding: 0 }}
              >
                {/* MOdal Header */}
                <div className="grid grid-cols-2 border-b border-primary pb-4 sticky-header z-10">
                  <div className="col-span-2 flex flex-wrap justify-start mt-4 pl-4">
                    <div className="text-lg leading-6 font-medium text-ads">
                      {LocaleStrings.change_currency}
                    </div>
                  </div>
                  <div className="block absolute top-0 right-0 pt-4 pr-4 ">
                    <button
                      type="button"
                      className="btn-closeModal focus:outline-none"
                      onClick={() => closeModal()}
                    >
                      <span className="sr-only">{LocaleStrings.close}</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                {/* Modal Body */}

                <div className="mx-6 mt-2">
                  <div>{LocaleStrings.select_currency}</div>
                  <div className="py-4">
                    <RadioGroup
                      value={selected}
                      onChange={(e) => onRadioChange(e)}
                    >
                      <RadioGroup.Label className="sr-only"></RadioGroup.Label>
                      <div className="bg-white rounded-md -space-y-px">
                        {settings.map((setting, settingIdx) => (
                          <RadioGroup.Option
                            key={setting.name}
                            value={setting}
                            className={({ checked }) =>
                              classNames(
                                settingIdx === 0
                                  ? "rounded-tl-md rounded-tr-md"
                                  : "",
                                settingIdx === settings.length - 1
                                  ? "rounded-bl-md rounded-br-md"
                                  : "",
                                checked
                                  ? "bg-primaryLight z-10"
                                  : "border-gray-200",
                                "relative border p-4 flex cursor-pointer focus:outline-none"
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <>
                                <span
                                  className={classNames(
                                    checked
                                      ? "bg-btn border-transparent"
                                      : "bg-white border-gray-300",
                                    active
                                      ? "ring-2 ring-offset-2 ring-primaty"
                                      : "",
                                    "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
                                  )}
                                  aria-hidden="true"
                                >
                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <div className="mx-3 flex flex-col">
                                  <RadioGroup.Description
                                    as="span"
                                    className={classNames(
                                      checked ? "text-ads" : "text-primary",
                                      "block text-sm"
                                    )}
                                  >
                                    {setting.description}
                                  </RadioGroup.Description>
                                </div>
                                <div>
                                  <img src={setting.src} className="h-5 w-5" />
                                </div>
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                  <div className="py-4">
                    <button
                      type="button"
                      className="rounded py-2 px-5 w-full flex items-center justify-center bg-btn text-white font-medium text-sm cursor-pointer"
                      onClick={() => saveCurrency()}
                    >
                      {loading ? (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        ""
                      )}
                      {LocaleStrings.btn_save}
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localCurrency: state.localCurrency,
  deviceSize: state.deviceSize,
  currencyModal: state.currencyModal,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ currencyModalOpen, setCurrency, fetchCart,fetchOrderDetails }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Currency);
