import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "../../languages";
import toast, { Toaster } from "react-hot-toast";
import _ from "lodash";
import {
  renderFieldText,
  renderOTP,
} from "../../components/Common/base-component";

import {
  validateEmail,
  classNames,
  LABEL_POSITION_TOP,
} from "../../components/Common/constant";

// Import Actions
import {
  verifyEmail,
  checkVerificationStatus,
  reSentEmail,
  emailExistOrNot,
  changeEmail,
  isVerficationModalOpen,
} from "../../actions";

import {
  fetchUsersDeatils,
  sendEmailOtp,
  verifyEmailOtp,
} from "../Profile/actions";

import { logout } from "../Login/actions";
import { confirmAlert } from "react-confirm-alert";
// Import Component
import Divider from "../../components/Common/divider";
import { withRouter } from "react-router-dom";

class VerifyEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
      emailid: "",
      verificStateCode: "",
      fieldsEmptyState: true,
      isVerified: false,
      sendLoader: false,
      verifyLoader: false,
    };

    // All Binded Functions
    this.reSendEmail = this.reSendEmail.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      /* This will check get [emailverified & phoneverified] column value from "users" table */
      this.props.checkVerificationStatus(this.props.session, (callBackRes) => {
        if (callBackRes.data[0].emailverified) {
          this.props.changeStep(2);
        }
      });
    }, 10000);
  }

  /* Resent email */
  reSendEmail() {
    let { session } = this.props;
    /*
    Sent Veriofication link to registerd email address
    */
    this.props.reSentEmail(session, (callBack) => {
      if (callBack.status === 1) {
        toast.success(LocaleStrings.resent_email_alert_text);
      } else {
        toast.error(LocaleStrings.common_fail_message);
      }
    });
  }

  comspoentWilUnMount() {
    clearInterval(this.interval);
  }

  /***** Form Submit *****/
  // onFormSubmit(values) {
  //   var id = window.setTimeout(function () {}, 0);

  //   while (id--) {
  //     window.clearTimeout(id); // will do nothing if no timeout with id is present
  //   }

  //   let { session } = this.props;
  //   let actualEmail = this.props.session.emailid;

  //   this.setState({ btnLoader: true, isBtnDisable: true }); // Start The button Loader

  //   /* Check supplied email is alraedy exist or not for df */
  //   this.props.emailExistOrNot(session, values.email, (callBack) => {
  //     if (callBack.status === 1) {
  //       if (callBack.data > 0) {
  //         toast.error(LocaleStrings.email_exist_alert_text);
  //         this.setState({
  //           btnLoader: false,
  //           isBtnDisable: false,
  //         });
  //       } else {
  //         this.props.changeEmail(session, values.email, (callBack) => {
  //           if (callBack.status === 1) {
  //             toast.success(LocaleStrings.verification_email_note_text4);
  //             this.setState({
  //               btnLoader: false,
  //               isBtnDisable: false,
  //             });
  //             this.props.isVerficationModalOpen(false);
  //           } else {
  //             toast.error(LocaleStrings.something_went_wrong);
  //             this.setState({
  //               btnLoader: false,
  //               isBtnDisable: false,
  //             });
  //           }
  //         });
  //       }
  //     } else {
  //       toast.error(LocaleStrings.something_went_wrong);
  //       this.setState({
  //         btnLoader: false,
  //         isBtnDisable: false,
  //       });
  //     }
  //   });
  // }

  /***** Form Submit *****/
  onFormSubmit(values) {
    console.log("values", values);
    debugger;
    let actiontaken = values.actiontaken;
    if (actiontaken == "send") {
      if (values.email) {
        this.setState({ sendLoader: true });
        let email = values.email;

        this.props.sendEmailOtp(this.props.session, email, (callback) => {
          this.setState({ sendLoader: false });
          if (callback.success === 1) {
            toast.success(LocaleStrings.email_send_code_success);
          } else {
            toast.error(callback.message);
          }
        });
      } else {
        toast.error(LocaleStrings.verification_code_mismatch);
      }
    } else if (actiontaken == "verify") {
      if (!this.state.isVerified) {
        this.setState({ verifyLoader: true });
        let otp = values.otp;

        this.props.verifyEmailOtp(this.props.session, otp, (callback) => {
          this.setState({ verifyLoader: false });
          if (callback.success === 1) {
            this.setState({ isVerified: true, fieldsEmptyState: true });

            toast.success(LocaleStrings.otp_verified_successfully);
          } else {
            this.setState({ fieldsEmptyState: true });
            toast.error(LocaleStrings.otp_not_verified);
          }
        });
      }
    } else if (actiontaken == "update") {
      if (this.state.isVerified) {
        this.setState({ btnLoader: true });

        let email = values.email;

        this.props.changeEmail(this.props.session, email, (callback) => {
          if (callback.status === 1) {
            this.setState({
              btnLoader: false,
              verificStateCode: "",
              fieldsEmptyState: true,
              isVerified: false,
            });

            toast.success(LocaleStrings.email_updated_successfully);

            this.props.skipEmailVerification();
          }
        });
      } else {
        toast.error(LocaleStrings.email_not_verified);
      }
    }
  }

  handleChange = (otp) => {
    if (otp.length == 6) {
      this.setState({ fieldsEmptyState: false });
    } else {
      this.setState({ fieldsEmptyState: true });
    }
  };

  clickSubmit = (key) => {
    //console.log("key: ", key);
    this.props.autofill("actiontaken", key);
  };

  logOut = () => {
    confirmAlert({
      title: LocaleStrings.logout_confirm_title,
      message: LocaleStrings.logout_confirm_message,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.logout();
            this.props.changeStep(0);
            this.props.history.push("/login");
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    let { initialValues, language, handleSubmit, session } = this.props;

    let { emailid } = this.state;
    let modalFooterText = LocaleStrings.verify;
    let emailIdToShow = emailid !== "" ? emailid : session.emailid;
    //console.log("histor", this.props.history);
    return (
      <>
        <form
          className=" "
          onSubmit={handleSubmit(this.onFormSubmit.bind(this))}
        >
          {/* MOdal Header Ends*/}
          <div className="p-4 bg-topnav">
            <p className="text-white text-xl font-medium">
              {LocaleStrings.verification_header_text1}
            </p>
            <p className="text-white text-xs">
              {LocaleStrings.verification_subheader_text1}
            </p>
          </div>
          {/* In order for us to activate your account */}
          <div>
            <p className="p-4">
              <span className="text-sm">
                {LocaleStrings.verification_email_note_text1}
              </span>
              <span
                className="text-link font-normal text-sm underline"
                id="verify_email_address_id"
              >
                {emailIdToShow}
              </span>
              <span className="text-sm">
                {LocaleStrings.verification_email_note_text2}
              </span>
            </p>
          </div>
          {/* Haven't received the email? Resend */}
          <div className="px-4">
            <span className="verify-email-resend" onClick={this.reSendEmail}>
              {LocaleStrings.verification_email_resend_btn}
            </span>
          </div>
          {/* Divider */}
          <div className="p-4">
            <Divider />
          </div>
          <div className="px-4">
            <span className="text-sm">
              {LocaleStrings.verification_email_note_text3}
            </span>
          </div>
          <div className="p-4">
            <Field
              name="email"
              label={LocaleStrings.email_address}
              placeholder={LocaleStrings.email_address}
              type="text"
              component={renderFieldText}
              mandatory="true"
              labelposition={LABEL_POSITION_TOP}
              classNameLabel="text-sm font-medium text-secondary"
            />
          </div>
          <div className="px-0 md:px-4 py-4">
            <button
              className="rounded py-3 px-5 w-full flex items-center justify-center bg-btn text-white font-medium text-sm cursor-pointer"
              type="submit"
              onClick={() => this.clickSubmit("send")}
              disabled={this.state.sendLoader ? true : false}
            >
              {this.state.sendLoader ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                ""
              )}
              <span className="text-sm">{LocaleStrings.send_code}</span>
            </button>
          </div>
          <div className="flex justify-center py-4">
            <p className=" text-sm font-medium text-custblack">
              {LocaleStrings.email_verfication_text}
            </p>
          </div>

          <div className="flex justify-center pb-4">
            <Field
              name="otp"
              type="text"
              component={renderOTP}
              mandatory="false"
              maxlength="1"
              onChange={this.handleChange}
              inputstyle={{
                width: this.props.deviceSize.isMobile ? 28 : 38,
                height: this.props.deviceSize.isMobile ? 28 : 38,
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                margin: this.props.deviceSize.isMobile ? "0 6px" : "0 15px",
              }}
            />
          </div>
          <div className="px-0 md:px-4 py-4">
            <button
              className={classNames(
                this.state.fieldsEmptyState
                  ? "cursor-not-allowed bg-gray-300"
                  : "bg-btn",
                "rounded py-3 px-5 w-full flex items-center justify-center text-white font-medium text-sm cursor-pointer"
              )}
              type="submit"
              onClick={() => this.clickSubmit("verify")}
              disabled={this.state.fieldsEmptyState ? true : false}
            >
              {this.state.verifyLoader ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                ""
              )}
              <span className="text-sm">
                {LocaleStrings.verify_phone_number}
              </span>
            </button>
          </div>
          <div className="px-0 md:px-4 py-4 border-t-2 border-primary">
            {/* Footer */}
            <button
              className="rounded py-3 px-5 w-full flex items-center justify-center bg-btn text-white font-medium text-sm cursor-pointer"
              type="submit"
              onClick={() => this.clickSubmit("update")}
            >
              {this.state.btnLoader ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                ""
              )}
              <span className="text-sm">{LocaleStrings.update_email}</span>
            </button>
          </div>
        </form>
        <div className="flex justify-between p-4 border-t-2 border-quaternary">
          <div className="" onClick={(e) => this.props.skipEmailVerification()}>
            {/* Footer */}
            <button type="submit" className="btn-primary font-semibold text-sm">
              {LocaleStrings.skip}
            </button>
          </div>
          <div
            className="text-link font-semibold text-sm flex cursor-pointer items-center"
            onClick={() => this.logOut()}
          >
            <span>{LocaleStrings.signout}</span>
          </div>
        </div>
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  //console.log("values", values);

  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (values.actiontaken == "send") {
    if (!values.email) {
      errors.email = LocaleStrings.form_validation_email_required;
    } else if (!values.email.match(validRegex)) {
      errors.email = LocaleStrings.form_validation_email_required;
    }
  }

  return errors;
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    localLanguage: state.localLanguage,
    deviceSize: state.deviceSize,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    verifyEmail,
    checkVerificationStatus,
    reSentEmail,
    emailExistOrNot,
    changeEmail,
    logout,
    isVerficationModalOpen,
    fetchUsersDeatils,
    sendEmailOtp,
    verifyEmailOtp,
  })(
    reduxForm({
      validate,
      form: "verifyEmailForm",
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(VerifyEmail)
  )
);
