import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import ItemList from "./item-list";
import { Carousel } from "react-responsive-carousel";
import Ads from "./ads";
import ShimmerEffect from "../../../components/shimmer-effect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
} from "../../../components/Common/constant";

function NewArrival(props) {
  const history = useHistory();
  const { deviceSize, adsReducer, homePageData } = props;

  //console.log("adsReducer", props.adsReducer);

  const productPerItem =
    deviceSize && deviceSize.width && deviceSize.width > 1440
      ? 6
      : deviceSize &&
        deviceSize.width &&
        deviceSize.width >= 1366 &&
        deviceSize.width <= 1440
      ? 5
      : deviceSize.width >= 1024 && deviceSize.width < 1366
      ? 5
      : deviceSize.width >= 768 && deviceSize.width < 1024
      ? 3
      : 2;

  const adsPerItem =
    deviceSize && deviceSize.width && deviceSize.width > 1024
      ? 2
      : deviceSize.width >= 768 && deviceSize.width <= 1024
      ? 2
      : 1;

  const itemRender = (items) => {
    return _.map(items, (post, index) => {
      return <ItemList item={post} />;
    });
  };

  const adsRender = (items) => {
    return _.map(items, (post, index) => {
      return <Ads item={post} index={index} />;
    });
  };

  const viewCategory = (item) => {
    if (item.linktype == "category") {
      history.push("/view-category/" + item.categoryid);
    } else if (item.linktype == "subcategory") {
      history.push("/product-view/" + item.subcategoryid);
    } else if (item.linktype == "product") {
      history.push(
        "/product-details/" +
          _.kebabCase(item.productname_en) +
          "-" +
          item.productid
      );
    } else {
      window.open(item.externallink, "_blank").focus();
    }
  };

  const renderShimmer = () => {
    let children = [];
    const shimmerCount =
      deviceSize && deviceSize.width && deviceSize.width > 1440
        ? 6
        : deviceSize && deviceSize.width && deviceSize.width > 1366
        ? 5
        : deviceSize.width >= 1024 && deviceSize.width <= 1366
        ? 4
        : deviceSize.width >= 768 && deviceSize.width <= 1024
        ? 4
        : 2;
    _.times(shimmerCount, (i) => {
      children.push(
        <ShimmerEffect
          containerClass="product-shimmer mb-4"
          simmerType1="square-simmer"
          simmerType2="line-simmer1"
          simmerType3="line-simmer2"
          simmerType4="line-simmer3"
        />
      );
    });
    return children;
  };

  let combinedList = [];

  if (homePageData && homePageData.data && homePageData.data.length > 0) {
    combinedList = _.filter(homePageData.data, function (o) {
      return (
        o.section == "product" ||
        (o.section == "banner" && o.bannertype != "header")
      );
    });
  }

  return (
    <>
      {combinedList && combinedList.length > 0
        ? _.map(combinedList, (item, index) => {
            return item.section == "product" ? (
              <div
                className="custom_container rounded-10 mt-5"
                style={
                  props.deviceSize.isMobile
                    ? {
                        paddingLeft: 14,
                        paddingRight: 14,
                        background: `linear-gradient(180deg, ${item.bgcolor} 0%, #f5f5f5 100%)`,
                      }
                    : {
                        background: `linear-gradient(180deg, ${item.bgcolor} 0%, #f5f5f5 100%)`,
                      }
                }
              >
                <div className=" pt-18 md:pt-30 pb-3.5 md:pb-25 text-black text-22 font-bold pl-0 md:pl-18 pr-0 md:pr-18">
                  {item.display_name}
                </div>
                <div className="px-0 md:px-18 pb-3.5 md:pb-8">
                  <div className="grid grid-cols-2  md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-15 md:gap-x-4  gap-y-15 md:gap-y-18 ">
                    {itemRender(item.data)}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="custom_container mt-6"
                style={
                  props.deviceSize.isMobile
                    ? { paddingLeft: 14, paddingRight: 14 }
                    : { paddingLeft: 18, paddingRight: 18 }
                }
              >
                {item.data.length == 1 && item.bannertype == "full_width" ? (
                  <div
                    className={`bg-white grid grid-cols-1 md:grid-cols-${item.data.length} gap-15 md:gap-6`}
                  >
                    {adsRender(item.data)}
                  </div>
                ) : item.data.length > 1 && item.bannertype == "full_width" ? (
                  <div style={{ marginBottom: "-3px" }}>
                    <Carousel
                      autoPlay={true}
                      showArrows={true}
                      showThumbs={false}
                      infiniteLoop={true}
                      showStatus={false}
                    >
                      {_.map(item.data, (obj, index) => {
                        return (
                          <div
                            className="relative cursor-pointer bannerwrapper"
                            onClick={() => viewCategory(obj)}
                          >
                            <LazyLoadImage
                              src={`${FILES_URL}/${obj.image}?api_key=${APP_API_KEY}`}
                              className={classNames(
                                props.deviceSize.isMobile ? "" : "rounded ",
                                "w-auto h-150 md:h-318 object-cover"
                              )}
                              effect="blur"
                            />
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                ) : (
                  <div
                    className={`bg-white grid grid-cols-2 md:grid-cols-${item.data.length} gap-15 md:gap-6`}
                  >
                    {adsRender(item.data)}
                  </div>
                )}
              </div>
            );
          })
        : ""}

      {!homePageData?.data ? (
        <div className="bg-newarrival ">
          <div
            className="py-5 custom_container"
            style={
              props.deviceSize.isMobile
                ? { paddingLeft: 10, paddingRight: 10 }
                : {}
            }
          >
            <div className="grid grid-cols-2  md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 md:gap-8 lg:gap-8 xl:gap-9 2xl:gap-4">
              {renderShimmer()}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  // isLoggedIn: state.isLoggedIn,
  // session: state.session,
  homePageData: state.homePageData,
  deviceSize: state.deviceSize,
  adsReducer: state.adsReducer,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewArrival);
