import axios from "axios";
import _ from "lodash";

import {
  INSTANCE_URL,
  APP_API_KEY,
  FILES_URL,
} from "../../../components/Common/constant";

import {
  getRequestAccess,
  postRequestAccess,
  deleteRequest,
  getRequest,
  postRequest,
  patchRequest,
  uploadEncodedFile,
} from "../../../components/Common/network-call";
import { getHeaders } from "../../../components/Common/common-utils";

export const PRODUCT_LIST = "PRODUCT_LIST";
export const FILTER_OPTION = "FILTER_OPTION";
export const MOBILE_FILTER = "MOBILE_FILTER";
export const MOBILE_SORTBY = "MOBILE_SORTBY";

export function emptyProductList(callback) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_LIST,
      payload: {},
    });
  };
}

export function fetchProductBySubCategory(
  session,
  filterData,
  pageNumber,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/filterscript/filterproduct1`;
  filterData.limit = 12;
  filterData.page = pageNumber;
  if (session) {
    return (dispatch) => {
      postRequest(
        url,
        filterData,
        session,
        dispatch,
        (response) => {
          dispatch({
            type: PRODUCT_LIST,
            payload: {
              data: response.products,
              totalrows: response.totalrows,
              breadcrumb: response.breadcrumb,
            },
          });
          callback({
            success: 1,
          });
        },
        (error) => {}
      );
    };
  } else {
    return (dispatch) => {
      postRequestAccess(
        url,
        filterData,
        null,
        dispatch,
        (response) => {
          dispatch({
            type: PRODUCT_LIST,
            payload: {
              data: response.products,
              totalrows: response.totalrows,
              breadcrumb: response.breadcrumb,
            },
          });
          callback({
            success: 1,
          });
        },
        (error) => {
          //callback({success: 0, error: error});
        }
      );
    };
  }
}

export function fetchFilterOptions(categoryid, callback) {
  var url = `${INSTANCE_URL}/api/v2/filterscript/getfilteroptions`;
  let params = { categoryid: categoryid };

  return (dispatch) => {
    postRequestAccess(
      url,
      params,
      null,
      dispatch,
      (response) => {
        dispatch({
          type: FILTER_OPTION,
          payload: { options: response.options, category: response.category },
        });
        callback({
          success: 1,
          data: response.options,
        });
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function isMobileFilterOpen(isOpen) {
  return { type: MOBILE_FILTER, payload: isOpen };
}

export function isMobileSortbyOpen(isOpen) {
  return { type: MOBILE_SORTBY, payload: isOpen };
}
