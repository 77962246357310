import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
} from "../../../components/Common/constant";
import { SMALL_DATE_FORMAT } from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
  currencyCode,
  currencyRate,
} from "../../../components/Common/product-model";
import Sortby from "../../../assets/images/sort.svg";
import Filter from "../../../assets/images/filter.svg";
import NoProduct from "../../../assets/images/no-product.svg";
import ItemList from "../../Home/components/item-list";
import FilterOption from "./filter-options";
import MobileSort from "./mobile-sort";
import MobileFilter from "./mobile-filter";
import LoadingOverlay from "react-loading-overlay";
import ShimmerEffect from "../../../components/shimmer-effect";
import GoTop from "../../../components/back-top";
import Pagination from "react-js-pagination";
import {
  fetchProductBySubCategory,
  emptyProductList,
  fetchFilterOptions,
  isMobileFilterOpen,
  isMobileSortbyOpen,
} from "../actions";

function ProductView(props) {
  const history = useHistory();
  const refScrollUp = useRef();
  const {
    session,
    localLanguage,
    deviceSize,
    productBySubcategory,
    filterOptions,
    localCurrency,
  } = props;

  const [openOption, setOpenoption] = useState(["categories", "price"]);
  const [categoryid, setCategoryid] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const [specificationParams, setSpecificationParams] = useState({});
  const [isSending, setIsSending] = useState(true);
  const [isCatChange, setIsCatChange] = useState(true);
  const [scrollPosition, setSrollPosition] = useState(0);
  const [showGoTop, setshowGoTop] = useState("goTopHidden");
  const [priceRange, setPriceRange] = useState([1, 50000]);
  const [domain, setDomain] = useState([1, 50000]);
  const [pageNumber, setPagenumber] = useState(1);
  const [sortBy, setSortBy] = useState("newest");
  const [clearAll, setClearAll] = useState(false);
  const sortbyArr = [
    {
      key: "priceasc",
      text: LocaleStrings.sortby_low_to_high,
    },
    {
      key: "pricedesc",
      text: LocaleStrings.sortby_high_to_low,
    },
    { key: "newest", text: LocaleStrings.sortby_newest_first },
    // { key: "bestmatch", text: LocaleStrings.sortby_bestmatch },
    { key: "endingsoonest", text: LocaleStrings.sortby_endingsoonest },
  ];

  useEffect(() => {
    // console.log("isCatChange", isCatChange);
    // debugger;
    if (props.match.params.catid) {
      const catid = props.match.params.catid;
      // console.log("catid", catid);
      let newArr = [];
      newArr.push(parseInt(catid));
      setCategoryid(newArr);
      props.emptyProductList();
      props.fetchFilterOptions(catid, (res) => {
        if (res.data && res.data.length > 0) {
          let newArr = openOption;
          let filterParams = {};
          _.map(res.data, (item, index) => {
            newArr.push(
              _.startCase(
                ProductTextLocalized(item.name_en, item.name_ar, localLanguage)
              )
            );
            filterParams[
              ProductTextLocalized(item.name_en, item.name_ar, localLanguage)
            ] = [];
          });
          setOpenoption(newArr);
          setFilterParams(filterParams);
          setIsCatChange(false);
        }
      });

      window.scrollTo(0, 0);
      //console.log("called");
    }
  }, [props.match.params.catid]);

  useEffect(() => {
    let CURRENCY = currencyCode(localCurrency);
    let CURRENCY_RATE = currencyRate(localCurrency);
    let priceEnd = 50000;
    setPriceRange([1, priceEnd]);
    setDomain([1, priceEnd]);
  }, [localCurrency]);

  useEffect(() => {
    window.addEventListener("scroll", handleVisibleButton);
  }, []);

  useEffect(() => {
    let filterData = {};
    if (isSending) {
      //window.scrollTo(0, 0);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      props.emptyProductList();
      const catid = props.match.params.catid;

      if (categoryid.length > 0) {
        filterData.category = categoryid.join(",");
      } else if (!clearAll) {
        let catArr = [];
        catArr.push(catid);
        filterData.category = catArr.join(",");
      }
      if (priceRange.length == 2) {
        filterData.price = priceRange;
      }
      let specData = {};
      for (const [key, value] of Object.entries(specificationParams)) {
        if (value.length > 0) {
          specData[key] = value;
        }
      }
      if (!_.isEmpty(specData)) {
        filterData.specification = specData;
      }

      for (const [key, value] of Object.entries(filterParams)) {
        if (value.length > 0) {
          filterData[key] = value;
        }
      }
      //console.log("filterData", filterData);
      filterData.sortby = sortBy;
      props.fetchProductBySubCategory(
        session,
        filterData,
        pageNumber,
        (res) => {
          setIsSending(false);
        }
      );

      // if (catid != categoryid[0]) {
      //   props.fetchFilterOptions(categoryid[0], (res) => {
      //     if (res.data && res.data.length > 0) {
      //       let newArr = openOption;

      //       let filterParams = {};
      //       _.map(res.data, (item, index) => {
      //         newArr.push(
      //           _.startCase(
      //             ProductTextLocalized(
      //               item.name_en,
      //               item.name_ar,
      //               localLanguage
      //             )
      //           )
      //         );
      //         filterParams[
      //           ProductTextLocalized(item.name_en, item.name_ar, localLanguage)
      //         ] = [];
      //       });
      //       setOpenoption(newArr);
      //       setFilterParams(filterParams);
      //     }
      //   });
      // }
    }
  }, [isSending]);

  const handleScrollUp = () => {
    //refScrollUp.current.scrollIntoView({ behavior: "smooth" });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleVisibleButton = () => {
    const position = window.pageYOffset;

    setSrollPosition(position);

    if (position > 150) {
      return setshowGoTop("goTop");
    } else if (position < 150) {
      return setshowGoTop("goTopHidden");
    }
  };

  const itemRender = () => {
    return _.map(productBySubcategory.data, (post, index) => {
      return <ItemList key={post.id} item={post} />;
    });
  };

  const renderShimmer = () => {
    let children = [];
    const shimmerCount =
      deviceSize && deviceSize.width && deviceSize.width > 1366
        ? 8
        : deviceSize.width >= 1024 && deviceSize.width <= 1366
        ? 8
        : deviceSize.width >= 768 && deviceSize.width <= 1024
        ? 4
        : 4;
    _.times(shimmerCount, (i) => {
      children.push(
        <ShimmerEffect
          containerClass="product-shimmer mb-4"
          simmerType1="square-simmer"
          simmerType2="line-simmer1"
          simmerType3="line-simmer2"
          simmerType4="line-simmer3"
        />
      );
    });
    return children;
  };

  const sorting = (e) => {
    let sortby = e.target.value;
    setSortBy(sortby);
    setIsSending(true);
    //console.log("sortby", sortby);
  };

  const paginationCallback = (pageNumber) => {
    //console.log("pageNumber", pageNumber);
    setIsSending(true);
    setPagenumber(pageNumber);
  };

  // console.log("productBySubcategory", productBySubcategory);
  // console.log("isSending", isSending);

  //console.log("filterParams", filterParams);
  let breacrumb = [];
  if (productBySubcategory?.breadcrumb) {
    breacrumb.push({
      key: ProductTextLocalized(
        productBySubcategory?.breadcrumb?.categoryname_en,
        productBySubcategory?.breadcrumb?.categoryname_ar,
        localLanguage
      ),
      href: "/view-category/" + productBySubcategory?.breadcrumb?.id,
    });
    if (
      productBySubcategory?.breadcrumb?.parent_sub_en ||
      productBySubcategory?.breadcrumb?.parent_sub_ar
    ) {
      breacrumb.push({
        key: ProductTextLocalized(
          productBySubcategory?.breadcrumb?.parent_sub_en,
          productBySubcategory?.breadcrumb?.parent_sub_ar,
          localLanguage
        ),
        href: "/view-category/" + productBySubcategory?.breadcrumb?.id,
      });
    }

    breacrumb.push({
      key: ProductTextLocalized(
        productBySubcategory?.breadcrumb?.subcategoryname_en,
        productBySubcategory?.breadcrumb?.subcategoryname_ar,
        localLanguage
      ),
      href: null,
    });
  }
  //console.log("categoryid", categoryid);
  return (
    <>
      <div ref={refScrollUp}> </div>
      <div className="bg-newarrival ">
        <div className="custom_container pb-25">
          <div className="grid grid-cols-2 border-b border-sortby pb-2 pt-25 mb-4">
            <div className="text-left">
              <div className="text-primary text-base font-medium">
                {LocaleStrings.product_title}
              </div>

              <div className="text-primary text-xs md:text-sm font-normal">
                <a
                  onClick={() => history.push("/")}
                  className="cursor-pointer text-vendorLink hover:underline visited:text-purple-600"
                >
                  {LocaleStrings.menu_home}
                </a>
                {breacrumb.map((bread, index) =>
                  bread.href ? (
                    <>
                      <span>&nbsp;/&nbsp;</span>
                      <a
                        onClick={() => history.push(bread.href)}
                        className="cursor-pointer text-vendorLink hover:underline visited:text-purple-600"
                      >
                        {bread.key}
                      </a>
                    </>
                  ) : (
                    <>
                      <span>&nbsp;/&nbsp;</span> {bread.key}{" "}
                    </>
                  )
                )}
              </div>
            </div>
            <div className="flex items-center justify-end">
              {deviceSize.isMobile ? (
                <>
                  <div
                    className="pr-25 cursor-pointer"
                    onClick={() => props.isMobileSortbyOpen(true)}
                  >
                    <img src={Sortby} className="w-6" />
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => props.isMobileFilterOpen(true)}
                  >
                    <img src={Filter} className="w-6" />
                  </div>
                </>
              ) : (
                <>
                  <div className="flex">
                    <span className="text-primary text-base font-medium pr-2">
                      {productBySubcategory?.totalrows}
                    </span>
                    <span className="text-primary text-base font-normal">
                      {productBySubcategory?.totalrows > 1
                        ? LocaleStrings.items_text
                        : LocaleStrings.item_text}
                    </span>
                  </div>
                  <div className="border-r border-sortby pl-4">&nbsp;</div>
                  <div className="text-primary text-base font-medium pl-4 flex items-center">
                    <div>{LocaleStrings.sortby} : </div>
                    <div>
                      <select
                        className="text-base text-primary font-normal bg-newarrival focus:outline-none cursor-pointer"
                        onChange={(e) => sorting(e)}
                      >
                        {sortbyArr.map((item, tabIdx) => (
                          <option
                            value={item.key}
                            selected={sortBy == item.key ? "selected" : ""}
                          >
                            {item.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex">
            {!deviceSize.isMobile && filterOptions.category ? (
              filterOptions.category.length > 0 ? (
                <div className="bg-white w-0 md:w-mdprodviewleft  lg:w-lgprodviewleft xl:w-xlprodviewleft 2xl:w-xxlprodviewleft rounded">
                  <FilterOption
                    openOption={openOption}
                    setOpenoption={setOpenoption}
                    categoryid={categoryid}
                    setCategoryid={setCategoryid}
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                    isSending={isSending}
                    setIsSending={setIsSending}
                    setIsCatChange={setIsCatChange}
                    priceRange={priceRange}
                    domain={domain}
                    setPriceRange={setPriceRange}
                    setPagenumber={setPagenumber}
                    setClearAll={setClearAll}
                    specificationParams={specificationParams}
                    setSpecificationParams={setSpecificationParams}
                  />
                </div>
              ) : (
                ""
              )
            ) : (
              <div className="bg-white w-0 md:w-mdprodviewleft  lg:w-lgprodviewleft xl:w-xlprodviewleft 2xl:w-xxlprodviewleft rounded">
                <ShimmerEffect
                  containerClass="line-shimmer-mysessionslist mb-4"
                  simmerType1="filter-simmer"
                  simmerType2="filterline-simmer"
                  simmerType3="line-simmer2"
                  simmerType4="line-simmer3"
                />
              </div>
            )}

            {/* <LoadingOverlay
              active={isSending}
              spinner
              text="Loading ..."
              styles={{
                spinner: (base) => ({
                  ...base,
                  width: "50px",
                  "& svg circle": {
                    stroke: "#1688EF",
                  },
                }),
                overlay: (base) => ({
                  ...base,
                  background: "rgba(98, 98, 98,1)",
                  opacity: 0.75,
                }),
              }}
            > */}
            {!isSending ? (
              <div className="w-full md:w-mdprodviewright lg:w-lgprodviewright xl:w-xlprodviewright 2xl:w-xxlprodviewright pl-0 md:pl-8">
                {productBySubcategory &&
                productBySubcategory.data &&
                productBySubcategory?.data.length > 0 ? (
                  <>
                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-9 lg:gap-8 xl:gap-9 2xl:gap-8">
                      {itemRender()}
                    </div>

                    <GoTop showGoTop={showGoTop} scrollUp={handleScrollUp} />
                    {productBySubcategory.totalrows > 12 ? (
                      <Pagination
                        activePage={pageNumber}
                        itemsCountPerPage={12}
                        totalItemsCount={productBySubcategory.totalrows}
                        pageRangeDisplayed={10}
                        onChange={(e) => paginationCallback(e)}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <div className="flex h-screen lg:h-catview items-center justify-center">
                    <div>
                      <div className="flex items-center justify-center pb-5">
                        <img src={NoProduct} />
                      </div>
                      <div className="flex items-center justify-center text-cutoff text-base font-medium">
                        {LocaleStrings.no_product_title}
                      </div>
                      <div className="flex items-center justify-center text-cutoff text-sm">
                        {LocaleStrings.no_product_found}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            {isSending ? (
              <div className="w-full md:w-mdprodviewright lg:w-lgprodviewright xl:w-xlprodviewright 2xl:w-xxlprodviewright pl-0 md:pl-8">
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-9 lg:gap-8 xl:gap-9 2xl:gap-8">
                  {renderShimmer()}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <MobileSort
        sortbyArr={sortbyArr}
        setSortBy={setSortBy}
        isSending={isSending}
        setIsSending={setIsSending}
      />
      <MobileFilter
        openOption={openOption}
        setOpenoption={setOpenoption}
        categoryid={categoryid}
        setCategoryid={setCategoryid}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        isSending={isSending}
        setIsSending={setIsSending}
        priceRange={priceRange}
        domain={domain}
        setPriceRange={setPriceRange}
        setClearAll={setClearAll}
        setPagenumber={setPagenumber}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  deviceSize: state.deviceSize,
  localLanguage: state.localLanguage,
  productBySubcategory: state.productBySubcategory,
  filterOptions: state.filterOptions,
  mobileFilter: state.mobileFilter,
  localCurrency: state.localCurrency,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchProductBySubCategory,
      emptyProductList,
      fetchFilterOptions,
      isMobileFilterOpen,
      isMobileSortbyOpen,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);
