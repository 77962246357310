import {
  PRODUCT_LIST,
  FILTER_OPTION,
  MOBILE_FILTER,
  MOBILE_SORTBY,
} from "../actions";

export function productBySubcategory(state = {}, action) {
  switch (action.type) {
    case PRODUCT_LIST:
      return action.payload;
    default:
      return state;
  }
}

export function filterOptions(state = {}, action) {
  switch (action.type) {
    case FILTER_OPTION:
      return action.payload;
    default:
      return state;
  }
}

export function mobileFilter(state = false, action) {
  switch (action.type) {
    case MOBILE_FILTER:
      return action.payload;
    default:
      return state;
  }
}

export function mobileSort(state = false, action) {
  switch (action.type) {
    case MOBILE_SORTBY:
      return action.payload;
    default:
      return state;
  }
}
