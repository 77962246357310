import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "../../../../languages/index";
import _ from "lodash";
import { renderFieldText } from "../../../../components/Common/base-component";
import toast, { Toaster } from "react-hot-toast";
import Dropzone from "react-dropzone";
import { confirmAlert } from "react-confirm-alert";
import CameraIcon from "../../../../assets/images/camera.svg";

// Import constant
import {
  APP_API_KEY,
  FILES_URL,
  GOOGLE_MAP_KEY,
  classNames,
  isEmpty,
} from "../../../../components/Common/constant";

// Import Actions
import {
  uploadMediaLargeProfileInfo,
  updateStoreUsers,
  fetchUsersDeatils,
  selectedProfileSettingsMenu,
  isProfileinfoCommonOpen,
} from "../../actions/index";

import { reSentEmail } from "../../../../actions/";

// Component Import
import ProfileInfoCommonModal from "./profile-info-common-modal.js";

// Constant
var threeArr = [{}];

// Constant
let validateExtention = ["doc", "docm", "docx", "docx", "pdf"];

class ProfileInformationEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      fileUrl: "",
      fileArrNew: {},
      imageLoader: false,
      userName: "",
      userInputShow: false,
      dynaicHeaderText: "",
      dynaicFooterText: "",
      callFromState: "",
      selectedGender: "",
      verifyLoading: false,
    };

    // All Binded Functions
  }

  componentDidMount() {}

  /**** Toggle User Name Input  ****/
  toggleUserInput(e) {
    let { userInputShow } = this.state;
    this.setState({ userInputShow: userInputShow ? false : true });
  }

  /**** Save ****/
  saveUserInput(e) {
    let username = document.getElementsByName("username");
    if (username[0].value != "" && username[0].value != null) {
      this.setState({ userName: username[0].value });
      this.toggleUserInput();
    }
  }

  // Add File
  onDrop = async (files) => {
    this.setState({ imageLoader: true });

    let file = files[0];
    let filearr = [];

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = (e) => {
      file.url = e.target.result;
      filearr.push(file);
      this.setState({ imageLoader: false });
    };

    this.setState({ fileArrNew: filearr });
    this.setState({ fileUrl: URL.createObjectURL(file) });
  };

  /**** Open Data with Modal ****/
  onProfileInfoMenuSelect(e, callfor) {
    if (callfor === "list-gender-key") {
      this.setState({ dynaicHeaderText: LocaleStrings.gender });
      this.setState({ dynaicFooterText: LocaleStrings.done });
    } else if (callfor === "list-phone-key") {
      this.setState({ dynaicHeaderText: LocaleStrings.update_phone_number });
      this.setState({ dynaicFooterText: LocaleStrings.update_phone_number });
    } else if (callfor === "list-pass-key") {
      this.setState({ dynaicHeaderText: LocaleStrings.update_password });
      this.setState({ dynaicFooterText: LocaleStrings.update_password });
    } else if (callfor === "list-email-key") {
      this.setState({ dynaicHeaderText: LocaleStrings.update_email });
      this.setState({ dynaicFooterText: LocaleStrings.update_email });
    }
    this.setState({ callFromState: callfor });
    this.props.isProfileinfoCommonOpen(true);
  }

  verifyEmail = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ verifyLoading: true });
    this.props.reSentEmail(this.props.session, (callBack) => {
      this.setState({ verifyLoading: false });
      if (callBack.status === 1) {
        toast.success(LocaleStrings.resent_email_alert_text);
      } else {
        toast.error(LocaleStrings.common_fail_message);
      }
    });
  };

  /***** Form Submit *****/
  onFormSubmit(values) {
    this.setState({ btnLoader: true });

    let { fileArrNew } = this.state;
    let { storeSelectedGender } = this.props;

    let object = {
      username: values.username,
      userid: values.userid,
      gender: storeSelectedGender,
    };

    if (Object.keys(fileArrNew).length === 0) {
      // With out image
      // toast.error(LocaleStrings.attachfile_to_update_error_text);
      // After file upload
      this.props.updateStoreUsers(this.props.session, object, (callback) => {
        if (callback.status === 1) {
          this.setState({ btnLoader: false });
          this.setState({ userInputShow: false });
          toast.success(LocaleStrings.profile_updated_successfully);

          // Fetch Vendor Details With LoginIdStore
          this.props.fetchUsersDeatils(
            this.props.session,
            (callBackResponce) => {}
          );
        }
      });
    } else {
      // Upload Section
      let uploaderCounter = 0;
      let filetobeupload = [];
      let arrPromise = [];
      var medianame = parseInt(this.props.session.userid) + "." + "jpg"; // Image Name Creation
      // Blank File Nmae array

      _.forEach(fileArrNew, function (value, index) {
        if (Object.keys(value).length > 0) {
          let coverJson = {
            type: "image",
            name: medianame,
            media: value.url,
          };
          filetobeupload.push(coverJson); //
        }
      });

      let counter = filetobeupload.length;

      // Store File to server first
      filetobeupload.map((item, index) => {
        arrPromise.push(
          new Promise((resolve, reject) => {
            this.props.uploadMediaLargeProfileInfo(
              this.props.session,
              item,
              (response) => {
                if (response.success === 0) {
                  resolve();
                } else {
                  uploaderCounter++;
                  resolve();
                }
              }
            );
          })
        );
      });

      Promise.all(arrPromise).then(() => {
        if (uploaderCounter === counter) {
          // After file upload
          this.props.updateStoreUsers(
            this.props.session,
            object,
            (callback) => {
              if (callback.status === 1) {
                this.setState({ btnLoader: false });
                this.setState({ fileArrNew: {} });
                this.setState({ userInputShow: false });
                toast.success(LocaleStrings.profile_updated_successfully);

                // Fetch Vendor Details With LoginIdStore
                this.props.fetchUsersDeatils(
                  this.props.session,
                  (callBackResponce) => {
                    // this.props.session.userid
                  }
                );
              }
            }
          );
        } else {
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    }
  }

  render() {
    let { initialValues, language, handleSubmit, user, storeSelectedGender } =
      this.props;

    //console.log("storeSelectedGender", storeSelectedGender);

    let { fileUrl, userName, userInputShow, selectedGender } = this.state;
    let usertype = user.usertype;
    let coverimage = "";

    if (userName === "" || userName === null) {
      userName = user.username;
    }

    if (selectedGender === "" || selectedGender === null) {
      selectedGender = storeSelectedGender;
    }
    let gender = "";
    if (storeSelectedGender === "male") {
      gender = LocaleStrings.male;
    } else if (storeSelectedGender === "female") {
      gender = LocaleStrings.female;
    } else {
      gender = LocaleStrings.rather_not_say;
    }

    // Profile Image
    if (isEmpty(fileUrl)) {
      coverimage = `${FILES_URL}/users/${user.userid}.jpg?api_key=${APP_API_KEY}`;
    } else {
      coverimage = fileUrl;
    }

    return (
      <>
        <div className="px-2 md:px-10 pt-1 bg-white ">
          {/* Body */}
          <div className="flow-root mt-8 bg-custWhite">
            <form
              className=" "
              onSubmit={handleSubmit(this.onFormSubmit.bind(this))}
            >
              {/* Upper Part */}
              <div className="max-w-7xl mx-auto py-2 px-4 text-center sm:px-6 lg:px-8 lg:py-2">
                <div className="space-y-5">
                  <div className="space-y-2 sm:mx-auto sm:max-w-xl sm:space-y-2 lg:max-w-5xl">
                    {/* Image Upload Start */}
                    <div className="dropzone-round" key={`drop`}>
                      <div className="img_wrpcover relative">
                        <img
                          src={coverimage}
                          className="mx-auto h-20 w-20 rounded-full md:w-40 md:h-40"
                          alt=""
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "../images/demo.jpeg";
                          }}
                        />

                        <Dropzone
                          onDrop={(e) => this.onDrop(e)}
                          accept="image/jpeg, image/png ,image.jpg"
                          multiple={false}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps()}
                              className="h-20 w-20 md:w-40 md:h-40 absolute -top-3 right-12 md:right-40 lg:right-64 xl:right-80 2xl:right-96 cursor-pointer"
                              align="center"
                            >
                              <input {...getInputProps()} />
                              {this.state.imageLoader ? (
                                <svg
                                  className={classNames(
                                    this.state.imageLoader ? "" : "hidden",
                                    "animate-spin-medium h-5 w-5 rounded-full mx-2"
                                  )}
                                  viewBox="0 0 1024 1024"
                                  focusable="false"
                                  data-icon="loading"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                </svg>
                              ) : (
                                <img
                                  className="w-6 h-6 cursor-pointer"
                                  src={CameraIcon}
                                />
                              )}
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                    {/* Image Upload Ends */}
                    {userInputShow ? (
                      <div className="grid grid-cols-6 gap-4">
                        <div className="col-start-3 col-span-2 mt-1">
                          <Field
                            name="username"
                            placeholder={LocaleStrings.name}
                            type="text"
                            component={renderFieldText}
                            mandatory="false"
                            //labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-sm font-medium text-secondary"
                          />
                        </div>
                        <div className="flex mt-2">
                          <div className="mx-1 flex flex-col">
                            <span onClick={(e) => this.saveUserInput(e)}>
                              <svg
                                className="h-6 w-6 text-success cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="var(--color-green)"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </span>
                          </div>
                          <div className="mx-1 flex flex-col">
                            <span onClick={(e) => this.toggleUserInput(e)}>
                              <svg
                                className="h-6 w-6 cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="var(--color-red)"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex mt-2 justify-center">
                        {/* [NOTE USER.VERIFIED {TRUE / FALSE} SHOW HIDE DIVE] img right sign*/}
                        <img
                          className={classNames(
                            user.verified ? "" : "hidden",
                            "h-4 w-4 mt-1.5"
                          )}
                          src={
                            "../profile_settings_images/rightcircle-yellow.svg"
                          }
                        />
                        <div className="mx-1 flex flex-col">
                          <p className="text-xl text-black">{userName}</p>
                        </div>
                        <img
                          className="h-4 w-4 mt-1.5 cursor-pointer"
                          src={"../profile_settings_images/pencil.svg"}
                          onClick={(e) => this.toggleUserInput(e)}
                        />
                      </div>
                    )}

                    {/* <div className="flex mt-2 justify-center">
                      <img
                        className="h-4 w-4"
                        src={"../product_management_images/star.svg"}
                      />
                      <div className="mx-1 flex flex-col">
                        <p className="text-sm font-medium text-ternary">
                          {LocaleStrings.no_rating}
                        </p>
                      </div>
                    </div> */}
                    <p className="mt-1 text-sm text-ternary">
                      {LocaleStrings.update_phone_number_tesxt}
                    </p>
                    {/* [NOTE USER.VERIFIED {TRUE / FALSE} SHOW HIDE DIVE] */}
                    <div
                      className={classNames(
                        user.usertype === "vendor" ? "" : "hidden",
                        "grid grid-cols-6 gap-4"
                      )}
                    >
                      <div
                        className=""
                        className={classNames(
                          user.verified ? "hidden" : "",
                          "col-start-3 col-span-2 ... "
                        )}
                        align="center"
                      >
                        <button
                          type="button"
                          className="<button"
                          onClick={(e) =>
                            this.props.selectedProfileSettingsMenu(
                              this.props.session,
                              2
                            )
                          }
                        >
                          {LocaleStrings.get_verified}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* List */}
              <div className="py-10">
                <div className="flow-root ">
                  <ul role="list" className="-my-5 divide-y divide-gray-200">
                    <li
                      key={"list-email-key"}
                      className="py-4 bg-white px-4 cursor-pointer transition ease-in-out duration-500 hover:bg-newarrival"
                      onClick={(e) =>
                        this.onProfileInfoMenuSelect(e, "list-email-key")
                      }
                    >
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <img
                            className="h-6 w-6"
                            src={"../profile_settings_images/envelope.svg"}
                            alt=""
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-ternary truncate">
                            {user.email}
                          </p>
                        </div>
                        <div>
                          {user.emailverified == "1" ? (
                            ""
                          ) : (
                            <span className="btn-primary cursor-pointer mt-4">
                              <button
                                type="button"
                                className="py-2.5 px-9 rounded btn-action btn-heavy"
                                onClick={(e) => this.verifyEmail(e)}
                              >
                                {this.state.verifyLoading ? (
                                  <svg
                                    className="animate-spin h-5 w-5 rounded-full mr-3"
                                    viewBox="0 0 1024 1024"
                                    focusable="false"
                                    data-icon="loading"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                  </svg>
                                ) : (
                                  ""
                                )}
                                {LocaleStrings.verify_email_button}
                              </button>
                            </span>
                          )}
                        </div>
                      </div>
                    </li>
                    {/* gender */}
                    <li
                      key={"list-gender-key"}
                      className="py-4 bg-white px-4 cursor-pointer transition ease-in-out duration-500 hover:bg-newarrival"
                      onClick={(e) =>
                        this.onProfileInfoMenuSelect(e, "list-gender-key")
                      }
                    >
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <img
                            className="h-6 w-6"
                            src={"../profile_settings_images/gender.svg"}
                            alt=""
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-ternary truncate capitalize">
                            {gender}
                          </p>
                        </div>
                        <div>
                          <img
                            className="h-8 transform rotate-180 px-2 "
                            src={"../profile_settings_images/arrow.svg"}
                          />
                        </div>
                      </div>
                    </li>
                    {/* Add Phone Number */}
                    <li
                      key={"list-phone-key"}
                      className="py-4 bg-white px-4 cursor-pointer transition ease-in-out duration-500 hover:bg-newarrival"
                      onClick={(e) =>
                        this.onProfileInfoMenuSelect(e, "list-phone-key")
                      }
                    >
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <img
                            className="h-6 w-6"
                            src={"../profile_settings_images/telephone.svg"}
                            alt=""
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-ternary truncate capitalize">
                            {user.phone !== null &&
                            user.phone !== "null" &&
                            user.phone !== ""
                              ? user.phone
                              : LocaleStrings.add_phone_number}
                          </p>
                        </div>
                        <div>
                          <img
                            className="h-8 transform rotate-180 px-2 "
                            src={"../profile_settings_images/arrow.svg"}
                          />
                        </div>
                      </div>
                    </li>
                    {/* Update Password */}
                    <li
                      key={"list-pass-key"}
                      className="py-4 bg-white px-4 cursor-pointer transition ease-in-out duration-500 hover:bg-newarrival"
                      onClick={(e) =>
                        this.onProfileInfoMenuSelect(e, "list-pass-key")
                      }
                    >
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <img
                            className="h-6 w-6"
                            src={"../profile_settings_images/passwordlock.svg"}
                            alt=""
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-ternary truncate capitalize">
                            {LocaleStrings.update_password}
                          </p>
                        </div>
                        <div>
                          <img
                            className="h-8 transform rotate-180 px-2 "
                            src={"../profile_settings_images/arrow.svg"}
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="p-10 space-y-6 border-t-2 border-quaternary">
                {/* Footer */}
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-start-1 md:col-start-2 col-span-3 md:col-span-1">
                    <div className="w-full">
                      <button
                        className="w-full text-white bg-btn text-sm font-normal pt-2.5 pb-2.5 pl-9 pr-9 rounded border border-variantSel btn-heavy inline-flex items-center justify-center"
                        type="submit"
                      >
                        {this.state.btnLoader ? (
                          <svg
                            className="animate-spin h-5 w-5 rounded-full mr-3"
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                        ) : (
                          ""
                        )}
                        <span className="text-sm md:text-base">
                          {LocaleStrings.update_profile}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <ProfileInfoCommonModal
          headerText={this.state.dynaicHeaderText}
          footerText={this.state.dynaicFooterText}
          callFrom={this.state.callFromState}
          genderData={selectedGender}
        />
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  var requiredFields = ["username"];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = "Required";
    }
  });

  return errors;
}

function mapStateToProps(state) {
  var { session, myProfile } = state;

  let newObj = {};

  if (state.myProfile)
    newObj = {
      username: state.myProfile
        ? state.myProfile.data
          ? state.myProfile.data.username
          : ""
        : "",
      userid: state.myProfile
        ? state.myProfile.data
          ? state.myProfile.data.userid
          : ""
        : "",
      email: state.myProfile
        ? state.myProfile.data
          ? state.myProfile.data.email
          : ""
        : "",
      gender: state.myProfile
        ? state.myProfile.data
          ? state.myProfile.data.gender
          : ""
        : "",
    };

  return {
    session,
    user: state.myProfile.data,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
    initialValues: newObj,
    storeSelectedGender: state.storeSelectedGender
      ? state.storeSelectedGender.data
        ? state.storeSelectedGender.data
        : ""
      : "",
  };
}

export default connect(mapStateToProps, {
  uploadMediaLargeProfileInfo,
  updateStoreUsers,
  fetchUsersDeatils,
  selectedProfileSettingsMenu,
  isProfileinfoCommonOpen,
  reSentEmail,
})(
  reduxForm({
    validate,
    form: "updateProfileInfoForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ProfileInformationEdit)
);
