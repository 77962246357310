import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import HammerNodata from "../../../assets/images/negotiation-nodata.svg";
import ItemList from "./list-item";
import Negotite from "./negotiate";
import {
  fetchNegotiation,
  emptyNegotiation,
  negotiationThread,
  isNegotiationOpen,
} from "../actions";
import { negotiationDetailsAction } from "../../Product/actions";

function Negotiation(props) {
  const history = useHistory();
  const { session, isLoggedIn, myNegotiation } = props;
  const [currentTab, setCurrenTab] = useState("active");
  const tabArr = [
    { key: "active", text: LocaleStrings.negotiation_active },
    { key: "won", text: LocaleStrings.negotiation_won },
    { key: "lost", text: LocaleStrings.negotiation_lost },
  ];

  useEffect(() => {
    if (isLoggedIn) {
      props.fetchNegotiation(session, currentTab);
      const threadid = props.match.params.threadid;

      if (threadid != null) {
        props.negotiationDetailsAction(session, threadid, (res) => {
          if (res.success == 1 && res.thread && res.thread.status) {
            let tab = "active";
            if (res.thread.status == "negotiated") {
              tab = "won";
            } else if (res.thread.status == "closed") {
              tab = "lost";
            } else if (
              res.thread.status == "responded" ||
              res.thread.status == "initiated" ||
              res.thread.status == "requested"
            ) {
              tab = "active";
            }
            setCurrenTab(tab);
          }
        });
        props.negotiationThread(threadid);
        props.isNegotiationOpen(true);
      }
    } else {
      history.push("/login");
    }
  }, []);

  const _handleDateTab = (e, selectedTab) => {
    setCurrenTab(selectedTab);
    props.emptyNegotiation();
    props.fetchNegotiation(session, selectedTab);
  };

  const renderList = () => {
    return _.map(myNegotiation.data, (item, index) => {
      return <ItemList item={item} />;
    });
  };
  return (
    <>
      <div className="mt-5 mb-60 custom_container">
        <div className="text-left text-primary text-xs">
          <a
            onClick={() => history.push("/activity")}
            className="cursor-pointer"
          >
            {LocaleStrings.activity}
          </a>
          &nbsp;/&nbsp; {LocaleStrings.negotiation_text}
        </div>
        <div className="mt-2">
          <nav
            className="relative z-0 flex divide-x divide-gray-200"
            aria-label="Tabs"
          >
            {tabArr.map((tab, tabIdx) => (
              <a
                key={tab.key}
                className={classNames(
                  currentTab == tab.key
                    ? "text-primary "
                    : "text-tabinactive hover:text-primary ",
                  "relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-normal text-center hover:bg-quaternary focus:z-10 cursor-pointer"
                )}
                aria-current={currentTab == tab.key ? "page" : undefined}
                onClick={(e) => _handleDateTab(e, tab.key)}
              >
                <span>{tab.text}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    currentTab == tab.key ? "bg-primary" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div>
        <div className="my-10">
          {myNegotiation && myNegotiation.data ? (
            myNegotiation.data.length > 0 ? (
              <div className="grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 gap-5">
                {renderList()}
              </div>
            ) : (
              <div className="flex h-cardimg items-center justify-center">
                <div>
                  <div className="flex items-center justify-center pb-5">
                    <img src={HammerNodata} />
                  </div>
                  <div className="flex items-center justify-center text-cutoff text-base font-medium">
                    {currentTab == "won"
                      ? LocaleStrings.negotiation_won_title
                      : currentTab == "lost"
                      ? LocaleStrings.negotiation_lost_title
                      : LocaleStrings.negotiation_active_title}
                  </div>
                  <div className="flex items-center justify-center text-cutoff text-sm">
                    {currentTab == "won"
                      ? LocaleStrings.negotiation_won_desc
                      : currentTab == "lost"
                      ? LocaleStrings.negotiation_lost_desc
                      : LocaleStrings.negotiation_active_desc}
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="h-cardimg flex items-center justify-center">
              <Loader />
            </div>
          )}
        </div>
      </div>
      <Negotite currentTab={currentTab} />
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  myNegotiation: state.myNegotiation,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchNegotiation,
      emptyNegotiation,
      negotiationDetailsAction,
      negotiationThread,
      isNegotiationOpen,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Negotiation);
