import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { usePreventScroll } from "react-aria";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  ProductTextLocalized,
  updateMRP,
  currencyCode,
  currencyRate,
} from "../../../components/Common/product-model";
import {
  classNames,
  timeDiffCalc,
  timeDiffRemaining,
  FILES_URL,
  APP_API_KEY,
} from "../../../components/Common/constant";
import {
  openBidPlaceModal,
  getBiddingPrice,
  emptyBiddingPrice,
  getProductCounter,
  emptyNegotiateThread,
  emptyNegotiationDetails,
  isLikesSliderOpen,
  likesByProductId,
  addWatchlist,
  removeWatchlist,
  removeWishlist,
  wishlistProductStore,
  isWishlistModalOpen,
  fetchWishList,
  isCommentModalOpen,
  isbidPlacedSliderOpen,
  fetchPublicBids,
  openReportModal,
  isbidWatchingSliderOpen,
  getProductWatchers,
  negotiationDetailsAction,
  isProductShareModalOpen,
  outofStockReg,
  addCart,
  fetchCart,
  validateUserCart,
  storeCart,
} from "../actions";
import {
  isNegotiationOpen,
  negotiationThread,
} from "../../Negotiation/actions";
import {
  isChangeAddressModal,
  getUserAddressAll,
  fetchCountry,
  isNewAddressModalOpen,
  fetchZone,
} from "../../Cart/actions";
import { editUserAddress } from "../../Profile/actions";
import { showNotificatonCount } from "../../Notification/actions";
import { openSliderPop } from "../../../actions";
import toast, { Toaster } from "react-hot-toast";
import { InformationCircleIcon } from "@heroicons/react/outline";
import PlaceBid from "./place-bid";
import ReportAbuse from "./report-abuse";
import ChooseVariant from "./choose-variant";
import Negotiate from "./negotiate";
import Comment from "./comment";
import LikesSlider from "./likes-slider";
import SlideOver from "../../../components/Common/SlideOver";
import Wishlist from "./wishlist";
import PublicBid from "./public-bid";
import ProductWatchlist from "./user-watchlist";
import ChangeAddress from "../../Cart/components/change-address";
import NewAddress from "../../Cart/components/new-address";
import StarIcon from "../../../assets/images/white-star.svg";
import CopyIcon from "../../../assets/images/copy.svg";
import DeliveryIcon from "../../../assets/images/delivery.svg";
import Ellipse from "../../../assets/images/ellipse.svg";
import CommentIcon from "../../../assets/images/comment.svg";
import LikeIcon from "../../../assets/images/like.svg";
import PlusIcon from "../../../assets/images/watch.svg";
import BluePlusIcon from "../../../assets/images/blue-plus.svg";
import ShareIcon from "../../../assets/images/shareicon.svg";
import BlueBack from "../../../assets/images/blue.svg";
import EyeIcon from "../../../assets/images/eye.svg";
import LikesIcon from "../../../assets/images/likes.svg";
import NoImg from "../../../assets/images/noimg.png";
import WatchingIcon from "../../../assets/images/watching.svg";
import WishlistedIcon from "../../../assets/images/wished.svg";
import { confirmAlert } from "react-confirm-alert";
import ViewNegotition from "../../Negotiation/components/negotiate";
import ProfileView from "../../Activity/components/profile-view";
import ShareModal from "./share-modal";
import NumericInput from "react-numeric-input";
import { CopyToClipboard } from "react-copy-to-clipboard";

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

function DetailSection(props) {
  const {
    session,
    isLoggedIn,
    productList,
    productid,
    localLanguage,
    selectedProduct,
    variantId,
    setVariantId,
    selectedVariant,
    setSelectedVariant,
    setVariantImageId,
    allVariants,
    setAllVariants,
    productVariants,
    setProductVariants,
    deviceSize,
    openBidPlaceModal,
    bidPlaceModal,
    openSliderPop,
    isSliderPop,
    negotiateThread,
    negotiationDetails,
    likes,
    addtoWatchlist,
    setAddtoWatchlist,
    addtoWishlist,
    setAddtoWishlist,
    setIschanged,
    reportAbuseModal,
    forwardedRef,
    localCurrency,
    commentList,
    publicBids,
    profileModal,
    getUserCart,
    numLikes,
    productShareModal,
    myWatchList,
    userDefaultAddress,
    allReviews,
    uaddress,
    deliveryAddress,
    setDeliveryAddress,
    reviewData,
    viewProfileModal,
    reportAbuse,
    setReportAbuse,
    checkFollowUnfollow,
  } = props;

  const history = useHistory();
  const [selectedVariantKeys, setSelectedVariantKeys] = useState([]);
  const [timeText, setTimeText] = useState("");
  const [remainingTime, setRemainingTime] = useState({});
  const [timeInterval, setTimeInterval] = useState(0);
  const [originate, setOriginate] = useState("");
  const [imageError, setImageError] = useState(false);
  const [watchlistLoading, setWatchlistLoading] = useState(false);
  const [wishlistLoading, setWishlistLoading] = useState(false);
  const [notifyLoading, setNotifyLoading] = useState(false);
  const [getnotify, setGetnotify] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (endDate) {
      let timeText = timeDiffCalc(
        new Date(endDate.replace(/-/g, "/")),
        new Date()
      );
      setTimeText(timeText);

      let remObj = timeDiffRemaining(
        new Date(endDate.replace(/-/g, "/")),
        new Date()
      );
      setRemainingTime(remObj);
    }
  }, [timeInterval, endDate]);

  usePreventScroll({ isDisabled: isSliderPop && !isSliderPop.showModal });

  var auctionText = "";
  var endDate = "";
  var bidStart = false;
  var bidText = "";
  if (selectedProduct && selectedProduct.saletype == "auction") {
    let currentDate = moment();
    if (
      selectedProduct.auction_start_date &&
      selectedProduct.auction_end_date &&
      currentDate.isAfter(moment(selectedProduct.auction_start_date)) &&
      currentDate.isBefore(moment(selectedProduct.auction_end_date))
    ) {
      auctionText = LocaleStrings.ends_in;
      endDate = selectedProduct.auction_end_date;
      bidStart = true;
    } else if (
      selectedProduct.auction_start_date &&
      selectedProduct.auction_end_date &&
      currentDate.isBefore(moment(selectedProduct.auction_start_date)) &&
      currentDate.isBefore(moment(selectedProduct.auction_end_date))
    ) {
      auctionText = LocaleStrings.starts_in;
      endDate = selectedProduct.auction_start_date;
      bidStart = false;
      bidText = LocaleStrings.bid_not_started;
    } else if (
      selectedProduct.auction_start_date &&
      selectedProduct.auction_end_date &&
      currentDate.isAfter(moment(selectedProduct.auction_start_date)) &&
      currentDate.isAfter(moment(selectedProduct.auction_end_date))
    ) {
      auctionText = LocaleStrings.expired;
      endDate = "";
      bidStart = false;
      bidText = LocaleStrings.bid_over;
    }
  }

  //console.log("bidStart", bidStart);
  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 2000);

  let currency = currencyCode(localCurrency);
  let currencyrate = currencyRate(localCurrency);

  // console.log("selectedVariant", selectedVariant);
  //console.log("selectedProduct", selectedProduct);

  const all_variants =
    selectedProduct && selectedProduct?.price
      ? selectedProduct?.price[localCurrency?.toLowerCase()]?.all_variants
      : [];

  let itemPrice =
    selectedVariant &&
    selectedVariant.length > 0 &&
    selectedVariant[0].price_unit
      ? selectedVariant[0].price_unit
      : 0;
  itemPrice = itemPrice;

  let priceObj = updateMRP(selectedProduct, variantId, localCurrency);
  let discountedPrice = priceObj.discountedPrice;
  discountedPrice = discountedPrice;
  let strProductDiscount = priceObj.strProductDiscount;

  const setVariantDetails = (variantid) => {
    let selectedVariant = _.filter(selectedProduct.all_variants, function (o) {
      return o.id == variantid;
    });

    if (selectedVariant && selectedVariant.length > 0) {
      setSelectedVariant(selectedVariant);
      setVariantId(variantid);
    }

    if (
      selectedProduct.all_variants &&
      selectedProduct.all_variants.length > 0
    ) {
      let allVariants = [];
      _.map(selectedProduct.all_variants, (obj, index) => {
        obj.selected = obj.id == variantid ? true : false;
        allVariants.push(obj);
      });
      setAllVariants(allVariants);
    }

    if (
      selectedProduct.product_variants &&
      selectedProduct.product_variants.length > 0
    ) {
      let productVariants = [];
      _.map(selectedProduct.product_variants, (obj, index) => {
        let variantnames = [];
        if (obj.variantnames && obj.variantnames.length > 0) {
          _.map(obj.variantnames, (data, index) => {
            data.selected =
              selectedVariant &&
              selectedVariant.length > 0 &&
              selectedVariant[0].refkey.indexOf(data.refkey) !== -1
                ? true
                : false;
            variantnames.push(data);
          });
        }
        obj.variantnames = variantnames;
        productVariants.push(obj);
      });
      setProductVariants(productVariants);
    }

    if (
      selectedVariant &&
      selectedVariant.length > 0 &&
      selectedVariant[0].media &&
      selectedVariant[0].media.length > 0
    ) {
      let variantImageId = selectedVariant[0].media[0].id;
      setVariantImageId(variantImageId);
    }
  };

  const renderProductVariant = (variantnames, pos) => {
    if (variantnames && variantnames.length > 0) {
      return _.map(variantnames, (vari, index) => {
        return (
          <div
            onClick={
              vari.selected
                ? null
                : () => makeVariantSelection(vari.refkey, pos)
            }
            className={classNames(
              vari.selected
                ? "border border-variantSel text-vendorLink bg-white"
                : " text-footers bg-shipping ",
              "px-2 py-6 rounded text-xs md:text-base font-medium flex items-center justify-center cursor-pointer"
            )}
            key={index}
          >
            {ProductTextLocalized(vari.name_en, vari.name_ar, localLanguage)}
          </div>
        );
      });
    }
  };

  const makeVariantSelection = (refkey, pos) => {
    if (
      selectedProduct.product_variants &&
      selectedProduct.product_variants.length > 0
    ) {
      let productVariants = [];
      let selectedVariantKeys = [];
      _.map(selectedProduct.product_variants, (obj, row) => {
        let variantnames = [];
        if (obj.variantnames && obj.variantnames.length > 0) {
          _.map(obj.variantnames, (data, index) => {
            if (pos == row) {
              data.selected = data.refkey == refkey ? true : false;
              if (data.selected) {
                selectedVariantKeys.push(refkey);
              }
            } else {
              data.selected = data.selected;
              if (data.selected) {
                selectedVariantKeys.push(data.refkey);
              }
            }

            variantnames.push(data);
          });
        }

        obj.variantnames = variantnames;
        productVariants.push(obj);
      });
      setProductVariants(productVariants);

      let selectedVariantKey = selectedVariantKeys.join("-");
      //console.log("selectedVariantKey", selectedVariantKey);
      if (
        selectedProduct.all_variants &&
        selectedProduct.all_variants.length > 0
      ) {
        let allVariants = [];

        _.map(selectedProduct.all_variants, (obj, index) => {
          obj.selected = obj.refkey == selectedVariantKey ? true : false;
          allVariants.push(obj);
        });
        setAllVariants(allVariants);
        let selectedVariant = _.filter(allVariants, function (o) {
          return o.selected;
        });
        //console.log("selectedVariant", selectedVariant);
        if (selectedVariant && selectedVariant.length > 0) {
          setSelectedVariant(selectedVariant);
          setVariantId(selectedVariant[0].id);
          let product_str =
            _.kebabCase(selectedProduct.productname_en) +
            "-" +
            productid +
            "-" +
            selectedVariant[0].id;

          props.fetchPublicBids(session, selectedVariant[0].id);

          history.push({
            pathname: "/product-details/" + product_str,
            state: { from: "/" },
          });
        }
        if (
          selectedVariant &&
          selectedVariant.length > 0 &&
          selectedVariant[0].media &&
          selectedVariant[0].media.length > 0
        ) {
          let variantImageId = selectedVariant[0].media[0].id;
          //console.log("variantImageId", variantImageId);
          setVariantImageId(variantImageId);
        }
      }
    }
  };

  const updateQuantity = (quantity) => {
    if (
      selectedProduct.all_variants &&
      selectedProduct.all_variants.length > 0
    ) {
      let allVariants = [];

      _.map(selectedProduct.all_variants, (obj, index) => {
        if (obj.id == variantId) {
          obj.quantity = parseInt(obj.quantity) - quantity;
        }
        allVariants.push(obj);
      });
      setAllVariants(allVariants);
      let selectedVariant = _.filter(allVariants, function (o) {
        return o.selected;
      });
      //console.log("selectedVariant", selectedVariant);
      if (selectedVariant && selectedVariant.length > 0) {
        setSelectedVariant(selectedVariant);
      }
    }
  };

  const updateBeingNegotiated = () => {
    if (
      selectedProduct.all_variants &&
      selectedProduct.all_variants.length > 0
    ) {
      let allVariants = [];

      _.map(selectedProduct.all_variants, (obj, index) => {
        if (obj.id == variantId) {
          obj.beingnegotiated = 1;
        }
        allVariants.push(obj);
      });
      setAllVariants(allVariants);
      let selectedVariant = _.filter(allVariants, function (o) {
        return o.selected;
      });
      //console.log("selectedVariant", selectedVariant);
      if (selectedVariant && selectedVariant.length > 0) {
        setSelectedVariant(selectedVariant);
      }
    }
  };

  const placeBid = () => {
    if (isLoggedIn) {
      let product_name = ProductTextLocalized(
        selectedProduct.productname_en,
        selectedProduct.productname_ar,
        localLanguage
      );

      if (selectedVariant && selectedVariant.length > 0) {
        product_name +=
          " (" +
          ProductTextLocalized(
            selectedVariant[0].name_en,
            selectedVariant[0].name_ar,
            localLanguage
          ) +
          " )";
      }

      props.emptyBiddingPrice();
      props.getBiddingPrice(session, productid, variantId);
      props.getProductCounter(session, productid, variantId);
      openBidPlaceModal(
        true,
        productid,
        variantId,
        selectedProduct.auction_start_date,
        selectedProduct.auction_end_date,
        product_name
      );
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  // const addtoCart = () => {
  //   if (isLoggedIn) {
  //     setOriginate("addtocart");
  //     openSliderPop(true);
  //   } else {
  //     history.push({
  //       pathname: "/login",
  //       state: { from: "/product-details" },
  //     });
  //   }
  // };

  const incrementValue = () => {
    var currentVal = quantity;

    if (!isNaN(currentVal)) {
      let finalQuantity = currentVal + 1;
      if (
        selectedVariant &&
        selectedVariant.length > 0 &&
        parseInt(selectedVariant[0].quantity) > 0 &&
        parseInt(selectedVariant[0].quantity) >= finalQuantity
      ) {
        setQuantity(currentVal + 1);
      } else {
        toast.error(LocaleStrings.quantity_not_available);
      }
    } else {
      setQuantity(1);
    }
  };
  const decrementValue = () => {
    var currentVal = quantity;

    if (!isNaN(currentVal) && currentVal > 0) {
      setQuantity(currentVal == 1 ? currentVal : currentVal - 1);
    } else {
      setQuantity(1);
    }
  };

  const makeInput = (currentVal) => {
    if (!isNaN(currentVal) && currentVal > 0) {
      if (
        selectedVariant &&
        selectedVariant.length > 0 &&
        parseInt(selectedVariant[0].quantity) > 0 &&
        parseInt(selectedVariant[0].quantity) >= currentVal
      ) {
        setQuantity(currentVal);
      } else {
        toast.error(LocaleStrings.quantity_not_available);
      }
    }
  };

  const addtoCart = () => {
    if (isLoggedIn) {
      let alreadyinCart = [];
      if (getUserCart && getUserCart.items) {
        alreadyinCart = _.filter(getUserCart.items, function (o) {
          return o.productid == productid && o.variantid == variantId;
        });
      }

      if (alreadyinCart.length > 0 && alreadyinCart[0].negotiated == 1) {
        toast.error(LocaleStrings.negotiated_won_variant_error);
      } else if (
        alreadyinCart.length > 0 &&
        alreadyinCart[0].addedfrombid == 1
      ) {
        toast.error(LocaleStrings.negotiated_won_variant_error);
      } else {
        let values = {};
        setLoading(true);
        props.validateUserCart(
          session,
          { variantid: parseInt(variantId) },
          (res) => {
            if (res.success && res.valid) {
              values.productid = parseInt(productid);
              values.variantid = parseInt(variantId);
              values.quantity = quantity;
              if (
                selectedVariant.length > 0 &&
                selectedVariant[0].addedfrombid &&
                selectedVariant[0].addedfrombid == 1
              ) {
                values.addedfrombid = 1;
                values.bidprice = selectedVariant[0].bidprice;
              }
              values.hasdiscount = selectedProduct.discountactive ? 1 : 0;
              if (parseInt(selectedProduct.discountactive) == 1) {
                values.discount = selectedProduct.discount;
              }

              props.addCart(session, values, (res) => {
                setLoading(false);
                props.openSliderPop(false);
                window.scrollTo(0, 0);
                if (res.success == 0) {
                  toast.error(LocaleStrings.common_fail_message);
                } else {
                  if (values.addedfrombid) {
                    props.fetchMybids(session, "won");
                  }
                  props.showNotificatonCount(session);
                  props.fetchCart(session);
                  toast.success(LocaleStrings.add_to_cart_success);
                }
              });
            } else {
              setLoading(false);
              toast.error(LocaleStrings.quantity_not_available);
            }
          }
        );
      }
    } else {
      let cartVal = {};
      cartVal.productid = parseInt(productid);
      cartVal.variantid = parseInt(variantId);
      cartVal.quantity = quantity;
      cartVal.hasdiscount = selectedProduct.discountactive ? 1 : 0;
      if (parseInt(selectedProduct.discountactive) == 1) {
        cartVal.discount = selectedProduct.discount;
      }
      props.storeCart(cartVal);
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  const negotiate = () => {
    if (isLoggedIn) {
      props.emptyNegotiateThread();
      props.emptyNegotiationDetails();
      setOriginate("negotiateini");
      openSliderPop(true);
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  const viewNegotiation = (threadid) => {
    //console.log("threadid", threadid);
    props.negotiationDetailsAction(session, threadid, (res) => {});
    props.negotiationThread(threadid);
    props.isNegotiationOpen(true);
  };

  const onImageError = () => {
    setImageError(true);
  };
  const onImageLoaded = () => {
    setImageError(false);
  };

  const renderLikes = () => {
    return (
      <div className="flex items-center space-x-2">
        <div className="flex flex-shrink-0 -space-x-1">
          {likes.map((like) => (
            <img
              key={like.id}
              className="max-w-none h-6 w-6 rounded-full ring ring-white"
              src={`${FILES_URL}/users/${like.likedby}.jpg?api_key=${APP_API_KEY}`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = NoImg;
              }}
            />
          ))}
          {numLikes > likes.length ? (
            <span className="max-w-none h-6 w-6 rounded-full ring ring-white bg-btn text-white text-lg font-bold leading-4 flex justify-center ">
              ...
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const openLikesSilder = () => {
    props.likesByProductId(session, productid);
    props.isLikesSliderOpen(true);
  };

  const addRemoveWatchlist = () => {
    if (isLoggedIn) {
      if (addtoWatchlist) {
        setWatchlistLoading(true);
        props.removeWatchlist(session, productid, (res) => {
          setWatchlistLoading(false);
          if (res.success == 1) {
            setAddtoWatchlist(false);
            toast.success(LocaleStrings.watchlist_remove_success);
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      } else {
        let values = {};
        values.userid = session.userid;
        values.productid = productid;
        setWatchlistLoading(true);
        props.addWatchlist(session, values, (res) => {
          setWatchlistLoading(false);
          if (res.success == 1) {
            setAddtoWatchlist(true);
            toast.success(LocaleStrings.watchlist_add_success);
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      }
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  const addRemoveWishlist = () => {
    if (isLoggedIn) {
      if (addtoWishlist && selectedProduct && selectedProduct.wishlistid) {
        let id = parseInt(selectedProduct.wishlistid);
        setWishlistLoading(true);
        props.removeWishlist(session, id, (res) => {
          setWishlistLoading(false);
          if (res.success == 1) {
            setAddtoWishlist(false);
            toast.success(LocaleStrings.wishlist_remove_success);
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      } else {
        props.fetchWishList(session);
        props.wishlistProductStore(productid);
        props.isWishlistModalOpen(true);
      }
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  const viewComment = () => {
    // if (isLoggedIn) {
    //   props.isCommentModalOpen(true);
    // } else {
    //   history.push({
    //     pathname: "/login",
    //     state: { from: "/product-details" },
    //   });
    // }

    props.isCommentModalOpen(true);
  };
  const bidPlaced = () => {
    let variantid = selectedVariant[0].id;
    props.fetchPublicBids(session, variantid);
    props.isbidPlacedSliderOpen(true);
  };
  const productWatching = () => {
    props.getProductWatchers(session, productid);
    props.isbidWatchingSliderOpen(true);
  };
  const reportProduct = () => {
    if (isLoggedIn) {
      confirmAlert({
        title: LocaleStrings.report_confirm_product_title,
        message: LocaleStrings.report_confirm_product_message,
        buttons: [
          {
            label: LocaleStrings.button_yes,
            onClick: () => {
              props.openReportModal(true, productid, "product");
            },
          },
          {
            label: LocaleStrings.button_no,
            onClick: () => {},
          },
        ],
      });
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  const share = () => {
    props.isProductShareModalOpen(true);
  };

  const outofstockReg = () => {
    if (isLoggedIn) {
      let values = {};
      values.productid = productid;
      values.variantid = variantId;
      setNotifyLoading(true);
      props.outofStockReg(session, values, (res) => {
        setNotifyLoading(false);
        if (res.success == 1) {
          setGetnotify(true);
          toast.success(LocaleStrings.outofstock_notify_success);
        } else {
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  const copyCode = () => {
    toast.success(LocaleStrings.cart_coupon_copied);
  };
  const changeAddress = () => {
    if (isLoggedIn) {
      props.getUserAddressAll(session);
      props.isChangeAddressModal(true);
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  const addNewAddress = () => {
    if (isLoggedIn) {
      props.fetchZone(session);
      props.fetchCountry(props.session);
      props.editUserAddress({});
      props.isNewAddressModalOpen(true);
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  //console.log("productShareModal", productShareModal);
  let bid_btn_text = LocaleStrings.place_bid;
  if (publicBids && publicBids.data && publicBids.data.length > 0) {
    let userBid = _.filter(publicBids.data, function (o) {
      return (
        o.variantid == variantId &&
        o.userid == session.userid &&
        o.productid == productid
      );
    });

    if (userBid && userBid.length > 0) {
      bid_btn_text = LocaleStrings.place_re_bid;
    }
  }

  let watchList = [];
  if (myWatchList && myWatchList.data && myWatchList.data.length > 0) {
    watchList = _.filter(myWatchList.data, function (o) {
      return o.productid == productid;
    });
  }

  console.log("productVariants", productVariants);
  return (
    <>
      <div className="pl-0 md:px-4">
        <div className="grid grid-cols-2 gap-1">
          <div className="grid grid-cols-3 gap-1">
            <div className="col-span-2">
              <div className="flex items-center justify-start">
                {selectedProduct.store && selectedProduct.store.userid ? (
                  <a
                    className="text-link font-bold text-base cursor-pointer"
                    target="_blank"
                    href={
                      isLoggedIn
                        ? `/seller-profile/${selectedProduct.store.userid}`
                        : `/login`
                    }
                  >
                    {selectedProduct &&
                    selectedProduct.store &&
                    selectedProduct.store.businessname
                      ? selectedProduct.store.businessname
                      : ""}
                  </a>
                ) : (
                  <div className="text-link font-bold text-base">
                    <a
                      className="text-link font-bold text-base cursor-pointer"
                      onClick={() =>
                        viewProfileModal(selectedProduct.store.createdby)
                      }
                    >
                      {selectedProduct &&
                      selectedProduct.store &&
                      selectedProduct.store.storename
                        ? selectedProduct.store.storename
                        : ""}
                    </a>
                  </div>
                )}
              </div>
            </div>
            {/* <div
              className="flex items-center justify-center w-full h-41 border border-variantSel text-vendorLink text-xs md:text-sm font-normal rounded cursor-pointer btn-light"
              onClick={() => reportProduct()}
            >
              <button>{LocaleStrings.report_title}</button>
            </div> */}
          </div>
          <div>
            {reportAbuse == 0 ? (
              <div
                className="mb-2 flex items-center justify-end pr-3  text-vendorLink text-xs md:text-sm font-normal cursor-pointer"
                onClick={() => reportProduct()}
              >
                <button>{LocaleStrings.report_title}</button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="text-left text-base md:text-lg lg:text-28 text-primary font-medium mt-2">
          {ProductTextLocalized(
            selectedProduct.productname_en,
            selectedProduct.productname_ar,
            localLanguage
          )}
          &nbsp;
          {selectedProduct.hasvariant == 1 &&
          selectedVariant &&
          selectedVariant.length > 0
            ? " (" +
              ProductTextLocalized(
                selectedVariant[0].name_en,
                selectedVariant[0].name_ar,
                localLanguage
              ) +
              " )"
            : ""}
        </div>

        {selectedProduct && selectedProduct.saletype == "auction" ? (
          <div className="py-5 grid grid-cols-2 gap-1 ">
            {auctionText ? (
              endDate ? (
                <div>
                  <div className="text-xs text-secondary">{auctionText}</div>
                  <div>
                    {remainingTime.days <= 0 && remainingTime.hours <= 24 ? (
                      <div className="flex items-center justify-center bg-red-500 w-4/5 text-white rounded-xl pt-1 pb-1 text-xs">
                        {timeText}
                      </div>
                    ) : (
                      <div className="flex items-center justify-center bg-topnav w-4/5 text-white rounded-xl pt-1 pb-1 text-xs">
                        {timeText}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="text-xs text-secondary">
                    {selectedProduct.bidcancelled
                      ? LocaleStrings.cancelled
                      : auctionText}
                  </div>
                  <div>
                    <div className="flex items-center justify-center bg-red-600 w-4/5 text-white rounded-xl pt-1 pb-1 text-xs">
                      {LocaleStrings.bid_over}
                    </div>
                  </div>
                </div>
              )
            ) : (
              ""
            )}
            <div className="flex items-center justify-between">
              <div
                className="text-xs flex items-center cursor-pointer mt-3"
                onClick={() => bidPlaced()}
              >
                <div className=" pr-1.5 text-lg text-secondary">
                  {publicBids && publicBids.data && publicBids.data.length > 0
                    ? publicBids.data.length
                    : 0}
                </div>
                <div className=" text-lg text-secondary ">
                  {publicBids && publicBids.data && publicBids.data.length > 1
                    ? LocaleStrings.bid
                    : LocaleStrings.nobid}
                </div>
              </div>

              <div className="flex items-center justify-end">
                <div
                  className={`flex items-center justify-center mt-1 ml-1 cursor-pointer border border-header rounded-lg p-2`}
                  onClick={() => share()}
                >
                  <img src={ShareIcon} className="w-7" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="py-5 text-left flex justify-between">
            <div className="flex">
              <div
                className={classNames(
                  selectedProduct.ratings &&
                    selectedProduct.ratings.avgrating &&
                    selectedProduct.ratings.avgrating >= 4
                    ? "bg-progress_green"
                    : selectedProduct.ratings &&
                      selectedProduct.ratings.avgrating &&
                      selectedProduct.ratings.avgrating >= 2 &&
                      selectedProduct.ratings.avgrating < 4
                    ? "bg-progress_orange"
                    : selectedProduct.ratings &&
                      selectedProduct.ratings.avgrating &&
                      selectedProduct.ratings.avgrating > 0 &&
                      selectedProduct.ratings.avgrating < 2
                    ? "bg-progress_red"
                    : "bg-progress_darkgray",
                  "px-2.5 py-1.5 rounded-lg flex items-center justify-center mr-3"
                )}
                onClick={() => props.scrollToReview()}
              >
                <div className="pr-1">
                  <img src={StarIcon} />
                </div>
                <div className="text-sm md:text-base font-bold text-white">
                  {selectedProduct.ratings && selectedProduct.ratings.avgrating
                    ? selectedProduct.ratings.avgrating
                    : 0}
                </div>
              </div>
              <div
                className="flex items-center pr-6 cursor-pointer"
                onClick={() => props.scrollToReview()}
              >
                <p className="text-sm md:text-base font-medium text-vendorLink underline">
                  {reviewData && reviewData.length > 0
                    ? reviewData.length + " " + LocaleStrings.reviews_txt
                    : 0 + " " + LocaleStrings.review_txt}
                </p>
              </div>
              <div className="flex items-center mx-15">
                <img src={Ellipse} />
              </div>
              <div className="flex items-center pl-6">
                <p className="text-sm md:text-base font-normal text-sold">
                  {selectedVariant && selectedVariant.length > 0
                    ? selectedVariant[0].numsold
                    : 0}{" "}
                  {LocaleStrings.sold_txt}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-end">
              <div
                className={`flex items-center justify-center mt-1 ml-1 cursor-pointer border border-header rounded-lg p-2`}
                onClick={() => share()}
              >
                <img src={ShareIcon} className="w-7" />
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-2 gap-2 md:gap-1 border-t border-b border-header py-10">
          {likes.length > 0 ? (
            <div
              className="cursor-pointer flex items-center"
              onClick={() => openLikesSilder()}
            >
              {renderLikes()}

              {numLikes > 0 ? (
                <div
                  className="flex cursor-pointer items-center pl-2"
                  onClick={() => openLikesSilder()}
                >
                  {/* <div className="pr-2.5">
                <img src={LikesIcon} className="w-6" />
              </div> */}
                  <div className="text-numbid text-xs md:text-base font-medium">
                    {numLikes > 1
                      ? numLikes + " " + LocaleStrings.likes_text
                      : numLikes + " " + LocaleStrings.like_text}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="flex"></div>
          )}

          <div className="flex items-center justify-end">
            <div
              className="flex items-center justify-center cursor-pointer"
              onClick={() => viewComment()}
            >
              <div className="pr-10">
                <img src={CommentIcon} />
              </div>
              <div className="text-numbid text-xs md:text-base font-medium">
                {commentList && commentList.data && commentList.data.length > 0
                  ? commentList.data.length + " " + LocaleStrings.comment
                  : LocaleStrings.view_comments_btn}
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex items-center">
            <div className="text-left text-28 font-bold text-footers">
              {`${currency} ${parseFloat(discountedPrice).toFixed(2)}`}
            </div>

            {strProductDiscount ? (
              <div className="pl-18 text-sm md:text-lg font-semibold text-linethrough line-through">
                {`${currency} ${itemPrice}`}
              </div>
            ) : (
              ""
            )}
            <div className="pl-18 text-sm text-medium text-discount">
              {strProductDiscount}
            </div>
          </div>
          <div className="flex items-center">
            <div className="text-sm md:text-base text-numbid bg-shipping px-3 py-1 rounded-3xl">
              +{currency}&nbsp;
              {selectedProduct && selectedProduct.converted_shippingprice
                ? selectedProduct.converted_shippingprice
                : "Free "}
              &nbsp; {LocaleStrings.shipping}
            </div>
          </div>
        </div>

        {selectedProduct && selectedProduct.hasvariant == 1 ? (
          <div className="">
            {productVariants && productVariants.length > 0
              ? _.map(productVariants, (prodV, index) => {
                  return (
                    <div key={index} className="pt-5">
                      <div className="text-left text-variant font-medium text-sm md:text-base">
                        {ProductTextLocalized(
                          prodV.name_en,
                          prodV.name_ar,
                          localLanguage
                        )}
                      </div>
                      <div
                        className={classNames(
                          index + 1 < productVariants.length ? "" : "",
                          "mt-2"
                        )}
                      >
                        <div className="flex space-x-4">
                          {renderProductVariant(prodV.variantnames, index)}
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}

            <div className="flex text-left mt-5 text-primary font-normal text-sm">
              <div className="text-left text-cutoff font-normal text-sm">
                {LocaleStrings.selected_variant} : &nbsp;
              </div>
              <div className="text-left text-primary font-normal text-sm">
                {selectedVariant && selectedVariant.length > 0
                  ? ProductTextLocalized(
                      selectedVariant[0].name_en,
                      selectedVariant[0].name_ar,
                      localLanguage
                    )
                  : ""}
              </div>
            </div>
            {/* <div className="mt-2.5 border-b border-primary">
              <div className="pb-2.5 grid grid-cols-3 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-4">
                {allVariants && allVariants.length > 0
                  ? _.map(allVariants, (vari, index) => {
                      return (
                        <div
                          className={classNames(
                            vari.selected
                              ? "border-variantSel text-vendorLink"
                              : "border-variant text-cardfooter ",
                            "p-1 border rounded text-xs  flex items-center justify-center pr-sm cursor-pointer"
                          )}
                          onClick={() => setVariantDetails(vari.id)}
                          key={vari.id}
                        >
                          {ProductTextLocalized(
                            vari.name_en,
                            vari.name_ar,
                            localLanguage
                          )}
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div> */}
          </div>
        ) : (
          ""
        )}

        {isLoggedIn ? (
          !_.isEmpty(deliveryAddress) ? (
            <>
              <div className="pt-5 text-variant font-medium text-base">
                {LocaleStrings.delivery}
              </div>
              <div className="mt-2">
                <div className="flex p-3 items-center bg-productdelivery rounded">
                  <div className="pr-3 md:pr-4">
                    <img src={DeliveryIcon} className="w-37" />
                  </div>
                  <div className="w-11/12">
                    <div className="flex items-center justify-between">
                      <div className="text-numbid font-bold text-xs md:text-base flex-nowrap">
                        {LocaleStrings.deliverto}
                      </div>
                      <div className="text-link font-medium text-sm md:text-base pl-1">
                        {deliveryAddress.useraddress}
                      </div>
                      <div>
                        <button
                          className="text-white bg-btn text-sm font-normal p-2 rounded btn-heavy"
                          onClick={() => changeAddress()}
                        >
                          Change
                        </button>
                      </div>
                    </div>
                    <div className="text-footers font-normal text-base">
                      {selectedProduct &&
                      selectedProduct.handling &&
                      selectedProduct.handling.name_en
                        ? ProductTextLocalized(
                            selectedProduct.handling.name_en,
                            selectedProduct.handling.name_ar,
                            localLanguage
                          )
                        : ""}{" "}
                      &nbsp;&nbsp; ${selectedProduct.shipping_cost}{" "}
                      {LocaleStrings.shipping}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="pt-5 text-variant font-medium text-base">
                {LocaleStrings.delivery}
              </div>
              <div className="mt-2">
                <div className="flex p-3 items-center bg-productdelivery rounded">
                  <div className="pr-3 md:pr-4">
                    <img src={DeliveryIcon} className="w-37" />
                  </div>
                  <div className="w-11/12">
                    <div className="flex items-center justify-between">
                      <div className="text-numbid font-bold text-xs md:text-base flex-nowrap">
                        {LocaleStrings.deliverto}
                      </div>

                      <div>
                        <button
                          className="text-white bg-btn text-sm font-normal p-2 rounded btn-heavy"
                          onClick={() => addNewAddress()}
                        >
                          {LocaleStrings.button_add_new_address}
                        </button>
                      </div>
                    </div>
                    <div className="text-footers font-normal text-base">
                      {selectedProduct &&
                      selectedProduct.handling &&
                      selectedProduct.handling.name_en
                        ? ProductTextLocalized(
                            selectedProduct.handling.name_en,
                            selectedProduct.handling.name_ar,
                            localLanguage
                          )
                        : ""}{" "}
                      &nbsp;&nbsp; ${selectedProduct.shipping_cost}{" "}
                      {LocaleStrings.shipping}
                    </div>
                  </div>
                </div>
              </div>
              {selectedProduct &&
              selectedProduct.saletype &&
              selectedProduct.saletype == "auction" ? (
                <div className="mt-2">
                  <span className="font-semibold">Note:</span>&nbsp;Please add
                  an address to place a bid
                </div>
              ) : (
                ""
              )}
            </>
          )
        ) : (
          ""
        )}

        {selectedProduct &&
        selectedProduct.coupons &&
        selectedProduct.coupons.length > 0 ? (
          <div className="pt-5">
            <div className="text-left text-variant font-medium text-sm md:text-base">
              {LocaleStrings.product_coupons}
            </div>
            <div>
              {_.map(selectedProduct.coupons, (cpn, index) => {
                return (
                  <div
                    key={index}
                    className="mt-2 rounded bg-coupon p-3 grid grid-cols-3"
                  >
                    <div className="flex items-center justify-between bg-white rounded border border-dashed border-coupon px-2 md:px-3.5 py-1 ">
                      <div className="pr-2 md:pr-1 text-xs md:text-sm font-medium text-coupon">
                        {cpn.code}
                      </div>
                      {!deviceSize.isMobile ? (
                        <div class="group cursor-pointer relative">
                          <InformationCircleIcon
                            className="h-4 w-4 cursor-pointer"
                            aria-hidden="true"
                          />
                          <div class="group-hover:opacity-100 transition-opacity p-2 top-1 left-4 -translate-x-1/2 translate-y-full absolute w-72 rounded-md z-50 bg-white  shadow-lg opacity-0">
                            <div className="">
                              <span className="text-numbid font-normal text-sm">
                                Description:&nbsp;
                              </span>
                              <span className="text-sold font-normal text-sm">
                                {cpn.coupon_desc}
                              </span>
                            </div>
                            <div className="">
                              <span className="text-numbid font-normal text-sm">
                                Discount:&nbsp;
                              </span>
                              <span className="text-sold font-normal text-sm">
                                {cpn.discount}
                                {cpn.discounttype == "percentage" ? "%" : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-span-2 pl-3.5 text-base text-primary">
                      {cpn.title}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}

        <div
          className={classNames(
            deviceSize.isMobile
              ? productShareModal && productShareModal.showModal
                ? "fixed inset-x-0 bottom-0 py-5 px-10 bg-white z-10 "
                : "fixed inset-x-0 bottom-0 py-5 px-10 bg-white z-50 "
              : "mt-5 ",
            ""
          )}
        >
          {selectedProduct &&
          selectedProduct.createdby != session.userid &&
          selectedProduct.saletype &&
          selectedProduct.saletype == "auction" ? (
            bidStart ? (
              selectedProduct.banfrom_auction == 0 ? (
                !_.isEmpty(deliveryAddress) && deliveryAddress.isdeliverable ? (
                  <div className="grid grid-cols-1 gap-1">
                    <button
                      className="text-white bg-btn text-sm font-normal pt-2.5 pb-2.5 pl-9 pr-9 rounded btn-heavy"
                      onClick={() => placeBid()}
                    >
                      {bid_btn_text}
                    </button>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 gap-1">
                    <button
                      className="text-white bg-red-600 text-sm font-normal pt-2.5 pb-2.5 pl-9 pr-9 rounded cursor-not-allowed w-full"
                      disabled
                    >
                      {LocaleStrings.not_deliverable}
                    </button>
                  </div>
                )
              ) : (
                <div className="grid grid-cols-1 gap-1">
                  <button
                    className="text-white bg-red-300 text-sm font-normal pt-2.5 pb-2.5 pl-9 pr-9 rounded cursor-not-allowed"
                    disabled
                  >
                    {LocaleStrings.place_bid}
                  </button>
                </div>
              )
            ) : endDate ? (
              <div className="grid grid-cols-1 gap-1">
                <button
                  className="text-negotiationPending bg-negotiationPending text-sm font-normal pt-2.5 pb-2.5 pl-9 pr-9 rounded cursor-not-allowed"
                  disabled
                >
                  {bidText}
                </button>
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-1">
                <button
                  className="text-white bg-red-600 text-sm font-normal pt-2.5 pb-2.5 pl-9 pr-9 rounded cursor-not-allowed"
                  disabled
                >
                  {LocaleStrings.bid_over}
                </button>
              </div>
            )
          ) : selectedProduct.createdby != session.userid ? (
            <>
              {selectedVariant &&
              selectedVariant.length > 0 &&
              selectedVariant[0].outofstock == 1 ? (
                <div className="grid grid-cols-2 gap-1">
                  <div className="text-outofstock font-medium text-lg flex justify-center items-center">
                    {LocaleStrings.out_of_stock}
                  </div>
                  {selectedVariant[0].beingnotified == 1 || getnotify ? (
                    <div className="flex items-center justify-center w-full h-41 border border-outofstock text-outofstock text-xs md:text-sm font-normal rounded cursor-not-allowed">
                      {LocaleStrings.outofstock_already_opted}
                    </div>
                  ) : (
                    <div
                      onClick={() => outofstockReg()}
                      className="flex items-center justify-center w-full h-41 border border-variantSel text-vendorLink text-xs md:text-sm font-normal rounded cursor-pointer btn-light"
                    >
                      {notifyLoading ? (
                        <svg
                          className="animate-spin h-5 w-5 rounded-full mr-3"
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      ) : (
                        ""
                      )}
                      {LocaleStrings.notify_available_text}
                    </div>
                  )}
                </div>
              ) : !_.isEmpty(deliveryAddress) &&
                deliveryAddress.isdeliverable ? (
                <div className="grid grid-cols-5 gap-2">
                  <div className="col-span-5 text-variant font-medium text-base">
                    {LocaleStrings.product_quantity_text}
                  </div>
                  <div className="prodquantity">
                    <NumericInput
                      onChange={(value) => makeInput(value)}
                      className="bg-review_search w-full h-41 text-sm md:text-base font-bold rounded focus:outline-none"
                      min={1}
                      max={100}
                      value={quantity}
                    />
                  </div>
                  <div className="col-span-4">
                    <button
                      className="w-full h-41 text-white bg-btn text-sm md:text-base font-bold rounded btn-heavy inline-flex items-center justify-center"
                      onClick={() => addtoCart()}
                    >
                      {loading ? (
                        <svg
                          className="animate-spin h-5 w-5 rounded-full mr-3"
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      ) : (
                        ""
                      )}
                      <span>{LocaleStrings.add_to_cart_btn}</span>
                    </button>
                  </div>
                </div>
              ) : _.isEmpty(deliveryAddress) && !deliveryAddress.useraddress ? (
                <div className="grid grid-cols-5 gap-2">
                  <div className="col-span-5 text-variant font-medium text-base">
                    {LocaleStrings.product_quantity_text}
                  </div>
                  <div className="prodquantity">
                    <NumericInput
                      onChange={(value) => makeInput(value)}
                      className="bg-review_search w-full h-41 text-sm md:text-base font-bold rounded focus:outline-none"
                      min={1}
                      max={100}
                      value={quantity}
                    />
                  </div>
                  <div className="col-span-4">
                    <button
                      className="w-full h-41 text-white bg-btn text-sm md:text-base font-bold rounded btn-heavy inline-flex items-center justify-center"
                      onClick={() => addtoCart()}
                    >
                      {loading ? (
                        <svg
                          className="animate-spin h-5 w-5 rounded-full mr-3"
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      ) : (
                        ""
                      )}
                      <span>{LocaleStrings.add_to_cart_btn}</span>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="">
                  <button
                    className="text-white bg-red-600 text-sm font-normal pt-2.5 pb-2.5 pl-9 pr-9 rounded cursor-not-allowed w-full"
                    disabled
                  >
                    {LocaleStrings.not_deliverable}
                  </button>
                </div>
              )}
            </>
          ) : (
            ""
          )}

          {selectedProduct.createdby != session.userid ? (
            <div className="pt-14">
              {selectedProduct && selectedProduct.saletype == "auction" ? (
                <div className="grid grid-cols-1">
                  <div
                    className="flex items-center justify-center w-full h-41 border border-btn text-numbid text-xs md:text-base font-medium rounded cursor-pointer btn-light"
                    onClick={() => addRemoveWatchlist()}
                  >
                    <span>
                      {watchlistLoading ? (
                        <svg
                          className="animate-spin h-5 w-5 rounded-full mr-3"
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      ) : (
                        <img
                          src={
                            (isLoggedIn && addtoWatchlist) ||
                            watchList.length > 0
                              ? WatchingIcon
                              : BluePlusIcon
                          }
                          className="max-w-none h-13 w-13"
                        />
                      )}
                    </span>
                    <span className="ml-1">
                      {(isLoggedIn && addtoWatchlist) || watchList.length > 0
                        ? LocaleStrings.watching_txt
                        : LocaleStrings.watchlist_txt}
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  className={
                    selectedProduct &&
                    selectedProduct.bestofferenabled &&
                    selectedProduct.bestofferenabled == "yes" &&
                    !_.isEmpty(deliveryAddress) &&
                    deliveryAddress.isdeliverable &&
                    selectedVariant &&
                    selectedVariant.length > 0 &&
                    selectedVariant[0].outofstock != 1
                      ? "grid grid-cols-2 gap-3"
                      : "grid grid-cols-1"
                  }
                >
                  {selectedVariant &&
                  selectedVariant.length > 0 &&
                  selectedVariant[0].beingnegotiated == 1 ? (
                    <div
                      className="flex items-center justify-center w-full h-41 border border-btn text-numbid text-xs md:text-base font-medium rounded btn-light cursor-pointer"
                      onClick={() =>
                        viewNegotiation(
                          selectedVariant[0].activenegotiationthread.id
                        )
                      }
                    >
                      {LocaleStrings.negotiate_view_btn}
                    </div>
                  ) : selectedProduct &&
                    selectedProduct.bestofferenabled &&
                    selectedProduct.bestofferenabled == "yes" &&
                    selectedProduct.store &&
                    selectedProduct.store.usertype &&
                    selectedProduct.store.usertype != "personal" &&
                    !_.isEmpty(deliveryAddress) &&
                    deliveryAddress.isdeliverable &&
                    selectedVariant &&
                    selectedVariant.length > 0 &&
                    selectedVariant[0].outofstock != 1 ? (
                    <div
                      onClick={() => negotiate()}
                      className="flex items-center justify-center w-full h-41 border border-btn text-numbid text-xs md:text-base font-medium rounded cursor-pointer btn-light"
                    >
                      {LocaleStrings.negotiate_btn}
                    </div>
                  ) : (
                    ""
                  )}

                  <div
                    onClick={() => addRemoveWishlist()}
                    className="flex items-center justify-center w-full h-41 border border-btn text-numbid text-xs md:text-base font-medium rounded cursor-pointer btn-light"
                  >
                    <span>
                      {wishlistLoading ? (
                        <svg
                          className="animate-spin h-5 w-5 rounded-full mr-3"
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      ) : (
                        <img
                          src={
                            isLoggedIn && addtoWishlist
                              ? WishlistedIcon
                              : BluePlusIcon
                          }
                          className="max-w-none h-13 w-13"
                        />
                      )}
                    </span>
                    <span className="ml-1">
                      {isLoggedIn && addtoWishlist
                        ? LocaleStrings.wishlisted_txt
                        : LocaleStrings.wishlist_txt}
                    </span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {bidPlaceModal && bidPlaceModal.showModal ? (
        <PlaceBid setIschanged={setIschanged} />
      ) : (
        ""
      )}
      {productShareModal && productShareModal.showModal ? (
        <ShareModal selectedProduct={selectedProduct} variantId={variantId} />
      ) : (
        ""
      )}

      {originate == "negotiateini" &&
      negotiateThread &&
      negotiateThread.threadid ? (
        <Negotiate
          title={LocaleStrings.slider_title}
          productid={productid}
          variantId={variantId}
          allVariants={allVariants}
          productVariants={productVariants}
          selectedProduct={selectedProduct}
          selectedVariant={selectedVariant}
          renderProductVariant={renderProductVariant}
        />
      ) : (
        <SlideOver
          title={originate == "negotiateini" ? LocaleStrings.slider_title : ""}
        >
          <ChooseVariant
            productid={productid}
            variantId={variantId}
            allVariants={allVariants}
            productVariants={productVariants}
            selectedProduct={selectedProduct}
            selectedVariant={selectedVariant}
            renderProductVariant={renderProductVariant}
            updateQuantity={updateQuantity}
            originate={originate}
            updateBeingNegotiated={updateBeingNegotiated}
            setIschanged={setIschanged}
          />
        </SlideOver>
      )}
      <Wishlist
        setAddtoWishlist={setAddtoWishlist}
        addtoWishlist={addtoWishlist}
        setIschanged={setIschanged}
      />
      <LikesSlider likes={likes} />
      <PublicBid viewProfileModal={viewProfileModal} />
      <ProductWatchlist />
      {reportAbuseModal && reportAbuseModal.showModal ? (
        <ReportAbuse setReportAbuse={setReportAbuse} productid={productid} />
      ) : (
        ""
      )}
      <Comment productid={productid} setIschanged={setIschanged} />
      <ViewNegotition currentTab={"active"} />

      {profileModal && profileModal.showModal ? (
        <ProfileView checkFollowUnfollow={checkFollowUnfollow} />
      ) : (
        ""
      )}
      <ChangeAddress
        useraddressid={deliveryAddress.id}
        setIschanged={setIschanged}
      />
      <NewAddress source="product" setIschanged={setIschanged} />
    </>
  );
}

const mapStateToProps = (state) => {
  let uaddress = false;
  if (state.userDefaultAddress && state.userDefaultAddress.length > 0) {
    uaddress = true;
  }
  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    localLanguage: state.localLanguage,
    productList: state.productList,
    deviceSize: state.deviceSize,
    bidPlaceModal: state.bidPlaceModal,
    productCounter: state.productCounter,
    isSliderPop: state.isSliderPop,
    negotiateThread: state.negotiateThread,
    negotiationDetails: state.negotiationDetails,
    reportAbuseModal: state.reportAbuseModal,
    localCurrency: state.localCurrency,
    commentList: state.commentList,
    publicBids: state.publicBids,
    profileModal: state.profileModal,
    getUserCart: state.getUserCart,
    productShareModal: state.productShareModal,
    myWatchList: state.myWatchList,
    userDefaultAddress: state.userDefaultAddress,
    allReviews: state.allReviews,
    uaddress,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openBidPlaceModal,
      getBiddingPrice,
      emptyBiddingPrice,
      getProductCounter,
      openSliderPop,
      emptyNegotiateThread,
      emptyNegotiationDetails,
      isLikesSliderOpen,
      likesByProductId,
      addWatchlist,
      removeWatchlist,
      removeWishlist,
      wishlistProductStore,
      isWishlistModalOpen,
      fetchWishList,
      isCommentModalOpen,
      isbidPlacedSliderOpen,
      fetchPublicBids,
      openReportModal,
      isbidWatchingSliderOpen,
      getProductWatchers,
      isNegotiationOpen,
      negotiationThread,
      negotiationDetailsAction,
      isProductShareModalOpen,
      outofStockReg,
      addCart,
      fetchCart,
      validateUserCart,
      storeCart,
      isChangeAddressModal,
      getUserAddressAll,
      showNotificatonCount,
      fetchCountry,
      isNewAddressModalOpen,
      editUserAddress,
      fetchZone,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DetailSection);
