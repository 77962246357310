import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import Loader from "../../../components/Common/loader";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
} from "../../../components/Common/constant";
import { fetchProducts, fetchProductByID } from "../../Home/actions";

import {
  getUserAddress,
  questionListByProduct,
  followUnfollow,
  isSendMessageStoreOpen,
  messageInitiate,
  fetchMessageThread,
  updateViews,
  fetchPublicBids,
  isViewAllDetailsModalOpen,
  fetchCart,
  fetchAllReviews,
  isbidPlacedSliderOpen,
  isCommentModalOpen,
} from "../actions";

import {
  profileModalOpen,
  selectedFollowerUserDetails,
  selectedFollowersDetails,
  selectedFollowersShearedWishlist,
  emptySharedWishlist,
  profileViewNavigation,
  viewPublicProfile,
} from "../../Activity/actions";

import ImageSection from "./image-section";
import DetailSection from "./detail-section";
import SendMessage from "./send-message";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import BreadCrumb from "./breadcrumb";
import Reviews from "./reviews";
import QuestionAnswer from "./question-answer";
import SimilarProduct from "./similar-products";
import RightArrow from "../../../assets/images/right-arrow.svg";
import DeliverytoIcon from "../../../assets/images/deliverto.svg";
import DeliverybyIcon from "../../../assets/images/deliverby.svg";
import SoldbyIcon from "../../../assets/images/soldby.svg";
import GoldIcon from "../../../assets/images/goldtick.svg";
import BlueIcon from "../../../assets/images/bluetick.jpeg";
import ReturnpolicyIcon from "../../../assets/images/returnpolicy.svg";
import BuyerprotectionIcon from "../../../assets/images/buyerprotection.svg";
import NoImg from "../../../assets/images/vendor-noimg.png";
import ShimmerEffect from "../../../components/shimmer-effect";
import ViewDetails from "./view-details";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { TrashOutline } from "heroicons-react";
import NoContentSlideOver from "../../../components/Common/no-content-slideover";
import BannedIcon from "../../../assets/images/banned.svg";

function ProductDetails(props) {
  const history = useHistory();
  const productStr = props.match.params.id;
  const productStrArr = productStr.split("-");

  let variantArr = _.nth(productStrArr, -1);
  let prodArr = _.nth(productStrArr, -2);

  var variantid = "";
  const productid = isNaN(prodArr) ? variantArr : prodArr;
  variantid = isNaN(prodArr) ? "" : variantArr;

  const {
    productList,
    localLanguage,
    isLoggedIn,
    session,
    userDefaultAddress,
    navigateUser,
    localCurrency,
  } = props;

  const reviewRef = useRef();
  const sellerRef = useRef();
  const overviewRef = useRef();

  const [variantId, setVariantId] = useState("");
  const [refKey, setRefKey] = useState("");
  const [selectedVariant, setSelectedVariant] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [variantImageId, setVariantImageId] = useState("");
  const [allVariants, setAllVariants] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [likes, setLikes] = useState([]);
  const [addtoWatchlist, setAddtoWatchlist] = useState(false);
  const [addtoWishlist, setAddtoWishlist] = useState(false);
  const [following, setFollowing] = useState(false);
  const [followingCount, setFollowingCount] = useState(0);
  const [followLoader, setFollowLoader] = useState(false);
  const [isChanged, setIschanged] = useState(true);
  const [viewMore, setViewMore] = useState(true);
  const [liked, setLiked] = useState(false);
  const [numLikes, setNumLikes] = useState(0);
  const [reviewData, setReviewData] = useState([]);
  const [questionData, setQuestionData] = useState([]);
  const [viewSpec, setViewSpec] = useState(true);
  const [isbanned, setIsBanned] = useState(false);
  const [isVendorBanned, setIsVendorBanned] = useState(false);
  const [islisted, setIsListed] = useState(false);
  const [reportAbuse, setReportAbuse] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState({});

  useEffect(() => {
    //console.log("props.match.params", props.match.params.id);
    if (productid && isChanged) {
      //console.log("productid", productid);
      // props.fetchProductByID(props.session, prodId, (res) => {});

      let values = {};
      values.productid = productid;
      values.viewtype = "product";
      if (props.isLoggedIn) {
        props.updateViews(props.session, values, (res) => {});
      }

      props.questionListByProduct(props.session, productid, "", 1, (res) => {
        //console.log("res", res);
        if (res.data) {
          setQuestionData(res.data);
        }
      });

      props.fetchAllReviews(props.session, productid, null, null, 1, (res) => {
        if (res.data) {
          setReviewData(res.data);
        }
      });
      //console.log("selectedProduct", selectedProduct);
      setLoading(true);
      props.fetchProductByID(props.session, productid, (res) => {
        if (res.success == 1) {
          if (res.products && res.products.id) {
            //console.log("productid", productid);
            // let productDetails = _.filter(res.products, function (o) {
            //   return o.id == productid;
            // });
            //console.log("productDetails", productDetails);
            // let selectedProduct =
            //   productDetails && productDetails.length > 0
            //     ? productDetails[0]
            //     : "";
            let selectedProduct = res.products;
            //console.log("selectedProduct", selectedProduct);
            variantid = variantid == "" ? selectedProduct.variantid : variantid;
            setSelectedProduct(selectedProduct);
            setVariantId(variantid);
            setIsBanned(res.products.isbanned);
            if (
              selectedProduct.store &&
              selectedProduct.store.isactive &&
              (selectedProduct.store.isactive == "0" ||
                selectedProduct.store.isactive == 0)
            ) {
              setIsVendorBanned(true);
            }
            if (
              selectedProduct.reportabuse &&
              selectedProduct.reportabuse == 1
            ) {
              setReportAbuse(true);
            }

            setIsListed(
              res.products.listed == 1 || res.products.listed == "1"
                ? false
                : true
            );
            if (selectedProduct.liked && isLoggedIn) {
              setLiked(true);
            }
            if (selectedProduct.numlikes > 0) {
              setNumLikes(selectedProduct.numlikes);
            }

            if (selectedProduct.likes && selectedProduct.likes.length > 0) {
              let likesArr = [];
              _.map(selectedProduct.likes, (obj, index) => {
                likesArr.push(obj);
              });
              setLikes(likesArr);
            }
            if (selectedProduct.addedtowatchlist) {
              setAddtoWatchlist(
                selectedProduct.addedtowatchlist == 1 ? true : false
              );
            }

            if (selectedProduct.addedtowishlist) {
              setAddtoWishlist(
                selectedProduct.addedtowishlist == 1 ? true : false
              );
            }

            if (selectedProduct.store) {
              setFollowing(selectedProduct.store.following ? true : false);
            }
            if (selectedProduct.store && selectedProduct.store.followers) {
              setFollowingCount(selectedProduct.store.followers);
            }

            if (
              selectedProduct.all_variants &&
              selectedProduct.all_variants.length > 0
            ) {
              let allVariants = [];
              _.map(selectedProduct.all_variants, (obj, index) => {
                obj.selected = obj.id == variantid ? true : false;
                allVariants.push(obj);
              });
              setAllVariants(allVariants);
            }
            let selectedVariant = [];
            if (
              selectedProduct &&
              selectedProduct.price &&
              selectedProduct.price[localCurrency.toLowerCase()] &&
              selectedProduct.price[localCurrency.toLowerCase()].all_variants
            ) {
              let all_variants =
                selectedProduct.price[localCurrency.toLowerCase()].all_variants;
              selectedVariant = _.filter(all_variants, function (o) {
                return o.id == variantid;
              });
            }

            if (selectedVariant && selectedVariant.length > 0) {
              //console.log("selectedVariant", selectedVariant);
              setSelectedVariant(selectedVariant);
              setRefKey(selectedVariant[0].refkey);
              props.fetchPublicBids(session, selectedVariant[0].id);
            }

            if (
              selectedProduct.product_variants &&
              selectedProduct.product_variants.length > 0
            ) {
              let productVariants = [];
              _.map(selectedProduct.product_variants, (obj, index) => {
                let variantnames = [];
                if (obj.variantnames && obj.variantnames.length > 0) {
                  _.map(obj.variantnames, (data, index) => {
                    data.selected =
                      selectedVariant &&
                      selectedVariant.length > 0 &&
                      selectedVariant[0].refkey.indexOf(data.refkey) !== -1
                        ? true
                        : false;
                    variantnames.push(data);
                  });
                }
                obj.variantnames = variantnames;
                productVariants.push(obj);
              });
              setProductVariants(productVariants);
            }

            if (
              selectedVariant &&
              selectedVariant.length > 0 &&
              selectedVariant[0].media &&
              selectedVariant[0].media.length > 0
            ) {
              let variantImageId = selectedVariant[0].media[0].id;
              setVariantImageId(variantImageId);
            }
            setLoading(false);
            setIschanged(false);

            if (isLoggedIn && session && session.userid) {
              props.getUserAddress(session, (res) => {
                if (res.data && res.data.length > 0) {
                  let useraddress = null;
                  let isdeliverable = false;
                  let addressid = null;

                  let useonceAddress = _.filter(res.data, function (obj) {
                    return obj.useonce == 1;
                  });
                  let defaultaddress = [];
                  if (useonceAddress.length > 0) {
                    defaultaddress = useonceAddress;
                  } else {
                    defaultaddress = _.filter(res.data, function (obj) {
                      return obj.defaultaddress == 1;
                    });
                    if (defaultaddress.length == 0) {
                      let notbillingAddress = _.filter(res.data, function (o) {
                        return !o.isbillingaddress;
                      });
                      if (notbillingAddress.length > 0) {
                        defaultaddress = notbillingAddress;
                      }
                    }
                  }

                  // console.log("defaultaddress", defaultaddress);
                  if (
                    defaultaddress.length > 0 &&
                    defaultaddress[0].buildingno
                  ) {
                    useraddress = `${defaultaddress[0].buildingno}${
                      defaultaddress[0]?.zone_master_by_zoneno?.zone
                        ? "," + defaultaddress[0]?.zone_master_by_zoneno?.zone
                        : ""
                    },${defaultaddress[0].poboxno}`;
                    addressid = defaultaddress[0].id;
                  } else {
                    useraddress = defaultaddress[0].address;
                    addressid = defaultaddress[0].id;
                  }

                  isdeliverable = isProductDeliverable(
                    defaultaddress,
                    selectedProduct
                  );

                  let obj = {};
                  obj.useraddress = useraddress;
                  obj.isdeliverable = isdeliverable;
                  obj.id = addressid;
                  setDeliveryAddress(obj);
                }
              });
              props.fetchCart(session, (res) => {});
            }

            const ptype = props.match.params.type;

            if (ptype != null && ptype == "bid") {
              props.fetchPublicBids(session, variantid);
              props.isbidPlacedSliderOpen(true);
            }

            if (ptype != null && ptype == "message") {
              props.isCommentModalOpen(true);
            }
          }
        }
      });
    }
  }, [productid, isChanged]);

  const isProductDeliverable = (address, cartitem) => {
    // console.log("address", address);
    // console.log("deliverablearea", cartitem);

    if (
      cartitem.product_exclude_country &&
      cartitem.product_exclude_country.length > 0 &&
      address.length > 0
    ) {
      if (address[0].country_id) {
        let countryArr = _.filter(
          cartitem.product_exclude_country,
          function (obj) {
            return obj == address[0].country_id;
          }
        );
        return countryArr.length > 0 ? false : true;
      } else {
        return false;
      }
    } else {
      return true;
    }

    // Previous Code
    // if (
    //   cartitem.deliverablearea &&
    //   cartitem.deliverablearea.length > 0 &&
    //   address.length > 0
    // ) {
    //   //console.log("deliverablearea", cartitem.deliverablearea);
    //   if (address[0].zip) {
    //     let pincodeArr = _.filter(cartitem.deliverablearea, function (obj) {
    //       return obj.pin_code == address[0].zip;
    //     });
    //     return pincodeArr.length > 0 ? true : false;
    //   } else if (address[0].zoneno) {
    //     let zoneArr = _.filter(cartitem.deliverablearea, function (obj) {
    //       return obj.zone == address[0].zoneno;
    //     });
    //     return zoneArr.length > 0 ? true : false;
    //   } else {
    //     return false;
    //   }
    // } else {
    //   return true;
    // }
  };

  const checkFollowUnfollow = () => {
    setFollowingCount(following ? followingCount - 1 : followingCount + 1);
    setFollowing(!following);
  };

  const followUnfollow = () => {
    if (isLoggedIn) {
      let userid = selectedProduct.store.userid
        ? selectedProduct.store.userid
        : selectedProduct.store.createdby;
      if (userid) {
        setFollowLoader(true);
        props.followUnfollow(session, parseInt(userid), (res) => {
          setFollowLoader(false);
          if (res.success == 1) {
            setFollowingCount(
              following ? followingCount - 1 : followingCount + 1
            );
            setFollowing(!following);
          }
        });
      }
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  const sendMessage = () => {
    //console.log("store", selectedProduct.store);
    if (isLoggedIn) {
      let userid = selectedProduct.store.userid
        ? selectedProduct.store.userid
        : selectedProduct.store.createdby;
      props.messageInitiate(session, userid, productid, (res) => {
        props.fetchMessageThread(session);
      });
      props.isSendMessageStoreOpen(true);
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  const viewSeller = (sellerid) => {
    //console.log("sellerid", sellerid);
    history.push("/seller-profile/" + sellerid);
  };

  const viewProfileModal = (userid) => {
    if (isLoggedIn) {
      let userArr = navigateUser;
      props.profileModalOpen(true, false);
      props.emptySharedWishlist();
      props.selectedFollowerUserDetails(session, userid, (callback) => {});
      props.selectedFollowersDetails(session, userid, (callback) => {});
      props.selectedFollowersShearedWishlist(session, userid, (callback) => {});
      props.viewPublicProfile(session, userid);
      if (!_.includes(userArr, userid)) {
        userArr.push(userid);
        props.profileViewNavigation(userArr);
      }
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  const scrollToReview = () => {
    reviewRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const scrollToSeller = () => {
    sellerRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const moreView = () => {
    setViewMore(!viewMore);
  };

  const moreOverView = () => {
    setViewSpec(!viewSpec);
    if (!viewSpec) {
      overviewRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const viewAll = () => {
    props.isViewAllDetailsModalOpen(true);
  };

  const desiredPage = (item) => {
    if (item.add_area == "category") {
      history.push("/view-category/" + item.categoryids);
    } else if (item.add_area == "subcategory") {
      history.push("/view-category/" + item.subcategoryids);
    } else if (item.add_area == "product") {
      setIschanged(true);
      history.push(
        "/product-details/" +
          _.kebabCase(item.productname_en) +
          "-" +
          item.productid
      );
    } else if (item.externallink) {
      window.open(item.externallink, "_blank").focus();
    }
  };

  let productDetails = "";

  if (selectedProduct && selectedProduct.product_desc_en) {
    productDetails = ProductTextLocalized(
      selectedProduct.product_desc_en,
      selectedProduct.product_desc_ar,
      localLanguage
    );
  }
  let productSpec = "";
  if (selectedProduct && selectedProduct.specification_desc_en) {
    productSpec = ProductTextLocalized(
      selectedProduct.specification_desc_en,
      selectedProduct.specification_desc_ar,
      localLanguage
    );
  }

  let specification = [];
  if (
    selectedProduct.globalspecifications &&
    selectedProduct.globalspecifications.length > 0
  ) {
    selectedProduct.globalspecifications.map((obj, index) => {
      specification.push(obj);
    });
  }

  if (
    selectedProduct.specifications &&
    selectedProduct.specifications.length > 0
  ) {
    selectedProduct.specifications.map((obj, index) => {
      specification.push(obj);
    });
  }

  if (selectedProduct.categories && selectedProduct.categories.length > 0) {
    let obj = {};
    obj.name_en = LocaleStrings.category;
    obj.name_ar = LocaleStrings.category;
    obj.value_en = selectedProduct.categories[0].categoryname_en;
    obj.value_ar = selectedProduct.categories[0].categoryname_ar;
    specification.push(obj);
  }

  if (
    selectedProduct.subcategories &&
    selectedProduct.subcategories.length > 0
  ) {
    let obj = {};
    obj.name_en = LocaleStrings.sub_category;
    obj.name_ar = LocaleStrings.sub_category;
    obj.value_en = selectedProduct.subcategories[0].subcategoryname_en;
    obj.value_ar = selectedProduct.subcategories[0].subcategoryname_ar;
    specification.push(obj);
  }
  if (
    selectedProduct.product_condition &&
    selectedProduct.product_condition.condition_en
  ) {
    let obj = {};
    obj.name_en = LocaleStrings.condition;
    obj.name_ar = LocaleStrings.condition;
    obj.value_en = selectedProduct.product_condition.condition_en;
    obj.value_ar = selectedProduct.product_condition.condition_ar;
    specification.push(obj);
  }
  //console.log("specification_desc_en", selectedProduct.specification_desc_en);
  console.log("selectedProduct", selectedProduct);
  console.log("followingCount", followingCount);
  //console.log("selectedVariant", selectedVariant);
  return (
    <>
      {loading ? (
        <div
          className="mt-5 mb-60 custom_container"
          style={
            props.deviceSize.isMobile
              ? { paddingLeft: 10, paddingRight: 10 }
              : {}
          }
        >
          {/* <div className="text-left text-primary text-base font-medium">
            {LocaleStrings.products}
          </div> */}
          <div className="pt-3 border-b border-primary"></div>
          <div className="mt-18 grid grid-cols-1 md:grid-cols-12  gap-0 md:gap-2 lg:gap-4">
            <div class="col-span-4 animate-pulse w-full pr-0">
              <div
                className=" bg-gray-200 rounded"
                style={{ height: 545 }}
              ></div>
              {!props.deviceSize.isMobile ? (
                <div className="grid grid-cols-5 gap-2 mt-3">
                  <div className="w-82 h-82 bg-gray-200 rounded ">&nbsp;</div>
                  <div className="w-82 h-82 bg-gray-200 rounded">&nbsp;</div>
                  <div className="w-82 h-82 bg-gray-200 rounded">&nbsp;</div>
                  <div className="w-82 h-82 bg-gray-200 rounded">&nbsp;</div>
                  <div className="w-82 h-82 bg-gray-200 rounded">&nbsp;</div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-span-5 w-full md:w-auto mt-4 md:mt-0">
              <div class="shadow rounded-md p-4 w-full">
                <div class="animate-pulse space-x-4">
                  <div class="grid grid-cols-3 ml-4">
                    <div className="h-4 bg-gray-200 rounded"></div>
                    <div></div>
                    <div className="h-4 bg-gray-200 rounded "></div>
                  </div>
                  <div className="h-4 bg-gray-200 rounded mt-2"></div>
                  <div className="grid grid-cols-3 gap-3 mt-5 py-3">
                    <div className="h-4 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded "></div>
                  </div>

                  <div className="mt-sm">
                    <div className="grid grid-cols-3 gap-3 border border-primary px-2 md:px-14 py-10">
                      <div className="flex items-center space-x-2">
                        <div className="flex flex-shrink-0 -space-x-1">
                          <div className="rounded-full bg-gray-200 h-6 w-6"></div>
                          <div className="rounded-full bg-gray-200 h-6 w-6"></div>
                          <div className="rounded-full bg-gray-200 h-6 w-6"></div>
                          <div className="rounded-full bg-gray-200 h-6 w-6"></div>
                          <span className="max-w-none h-6 w-6 rounded-full ring ring-white bg-gray-200 text-white text-lg font-bold leading-4 flex justify-center ">
                            ...
                          </span>
                        </div>
                      </div>

                      <div className="flex items-center justify-center">
                        <div className="w-full h-4 bg-gray-200 rounded">
                          &nbsp;
                        </div>
                      </div>
                      <div className="h-8 bg-gray-200 rounded"></div>
                    </div>
                  </div>
                  <div className="pt-14">
                    <div className="grid grid-cols-3 gap-1 mt-3">
                      <div className="col-span-2">
                        <div className="flex items-center space-x-4">
                          <div className="h-4 bg-gray-200 rounded w-14">
                            &nbsp;
                          </div>
                          <div className="w-14 h-4 bg-gray-200 rounded">
                            &nbsp;
                          </div>
                          <div className="w-14 h-4 bg-gray-200 rounded">
                            &nbsp;
                          </div>
                        </div>
                        <div className="h-4 bg-gray-200 rounded w-14 mt-2">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-14">
                    <div className="border-t border-primary">
                      <div className="pt-14 h-6 w-10 bg-gray-200 rounded mt-3"></div>
                    </div>
                  </div>
                  <div className="flex space-x-4 pb-3">
                    <div className="h-6 w-10 bg-gray-200 rounded mt-3"></div>
                    <div className="h-6 w-10 bg-gray-200 rounded mt-3"></div>
                    <div className="h-6 w-10 bg-gray-200 rounded mt-3"></div>
                    <div className="h-6 w-10 bg-gray-200 rounded mt-3"></div>
                  </div>

                  <div className="pt-14 ">
                    <div className="border-t border-primary">
                      <div className="pt-14 h-6 w-10 bg-gray-200 rounded mt-3"></div>
                    </div>
                  </div>
                  <div className="pb-3 flex space-x-4 ">
                    <div className="h-6 w-10 bg-gray-200 rounded mt-3"></div>
                    <div className="h-6 w-10 bg-gray-200 rounded mt-3"></div>
                    <div className="h-6 w-10 bg-gray-200 rounded mt-3"></div>
                    <div className="h-6 w-10 bg-gray-200 rounded mt-3"></div>
                  </div>
                  <div className="grid grid-cols-2 gap-3 mt-5">
                    <div className="h-8 bg-gray-200 rounded"></div>
                    <div className="h-8 bg-gray-200 rounded"></div>
                  </div>
                  <div className="grid grid-cols-2 gap-3 mt-1">
                    <div className="h-8 bg-gray-200 rounded"></div>
                    <div className="h-8 bg-gray-200 rounded"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-3 animate-pulse space-x-4 w-full pr-0">
              <div
                className="bg-gray-200 rounded"
                style={{ height: 320, width: 320 }}
              ></div>
            </div>
          </div>
        </div>
      ) : isVendorBanned || isbanned || islisted ? (
        <div
          className="mt-5 mb-60 custom_container"
          style={
            props.deviceSize.isMobile
              ? { paddingLeft: 10, paddingRight: 10 }
              : {}
          }
        >
          <NoContentSlideOver
            imageprop={BannedIcon}
            type="banned"
            headerprop={LocaleStrings.banned_item_header}
            subheaderprop={LocaleStrings.banned_item_subheader}
          />
        </div>
      ) : (
        <div
          className="mt-5 mb-60 custom_container"
          style={
            props.deviceSize.isMobile
              ? { paddingLeft: 10, paddingRight: 10 }
              : {}
          }
        >
          {/* <div className="text-left text-primary text-base font-medium">
            {LocaleStrings.products}
          </div> */}
          <BreadCrumb selectedProduct={selectedProduct} />
          <div className="pt-3 border-b border-primary"></div>
          <div className="mt-18 grid grid-cols-1 md:grid-cols-12  gap-0 md:gap-2 lg:gap-4">
            <div className="col-span-4">
              <ImageSection
                productid={productid}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                setLikes={setLikes}
                likes={likes}
                selectedVariant={selectedVariant}
                variantId={variantId}
                variantImageId={variantImageId}
                setVariantImageId={setVariantImageId}
                liked={liked}
                setLiked={setLiked}
                numLikes={numLikes}
                setNumLikes={setNumLikes}
              />
            </div>
            <div className="col-span-5 mt-4 md:mt-0">
              <DetailSection
                productid={productid}
                selectedProduct={selectedProduct}
                selectedVariant={selectedVariant}
                variantId={variantId}
                setVariantId={setVariantId}
                setSelectedVariant={setSelectedVariant}
                setVariantImageId={setVariantImageId}
                allVariants={allVariants}
                setAllVariants={setAllVariants}
                productVariants={productVariants}
                setProductVariants={setProductVariants}
                likes={likes}
                addtoWatchlist={addtoWatchlist}
                setAddtoWatchlist={setAddtoWatchlist}
                addtoWishlist={addtoWishlist}
                setAddtoWishlist={setAddtoWishlist}
                setIschanged={setIschanged}
                scrollToReview={scrollToReview}
                scrollToSeller={scrollToSeller}
                numLikes={numLikes}
                deliveryAddress={deliveryAddress}
                setDeliveryAddress={setDeliveryAddress}
                reviewData={reviewData}
                viewProfileModal={viewProfileModal}
                setReportAbuse={setReportAbuse}
                reportAbuse={reportAbuse}
                checkFollowUnfollow={checkFollowUnfollow}
              />
            </div>

            <div className="col-span-3 hidden lg:block">
              {selectedProduct &&
              selectedProduct.ads &&
              selectedProduct.ads.length > 0 ? (
                <div className="w-full pr-0">
                  <div
                    className="rounded-lg cursor-pointer flex  justify-center md:flex-shrink-0"
                    onClick={() => desiredPage(selectedProduct.ads[0])}
                  >
                    <LazyLoadImage
                      src={`${FILES_URL}/adbanners/${selectedProduct.ads[0].banner}?api_key=${APP_API_KEY}`}
                      className="h-full w-full rounded-lg "
                      effect="blur"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div>
                <div className="flex mt-7">
                  <div className="pr-4">
                    <img src={SoldbyIcon} className=" w-12" />
                  </div>
                  <div>
                    <div className="flex items-center">
                      <div className="text-base font-medium ">
                        {LocaleStrings.soldby}
                      </div>
                      <div className="flex items-center  gap-3">
                        <div className="pl-2 ">
                          {selectedProduct.store &&
                          selectedProduct.store.userid ? (
                            <a
                              className="text-link font-bold text-base cursor-pointer"
                              target="_blank"
                              href={
                                isLoggedIn
                                  ? `/seller-profile/${selectedProduct.store.userid}`
                                  : `/login`
                              }
                            >
                              {selectedProduct &&
                              selectedProduct.store &&
                              selectedProduct.store.businessname
                                ? selectedProduct.store.businessname
                                : ""}
                            </a>
                          ) : (
                            <div
                              className="text-link font-bold text-base cursor-pointer"
                              onClick={() =>
                                viewProfileModal(
                                  selectedProduct.store.createdby
                                )
                              }
                            >
                              {selectedProduct &&
                              selectedProduct.store &&
                              selectedProduct.store.storename
                                ? selectedProduct.store.storename
                                : ""}
                            </div>
                          )}
                        </div>

                        <div>
                          {selectedProduct.store &&
                          (selectedProduct.store.isgold == "1" ||
                            selectedProduct.store.isgold == 1) ? (
                            <img src={GoldIcon} className="w-7" />
                          ) : selectedProduct.store &&
                            (selectedProduct.store.verified == "1" ||
                              selectedProduct.store.verified == 1) ? (
                            <img src={BlueIcon} className="w-7" />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="text-primary font-normal text-xs">
                      {(selectedProduct &&
                        selectedProduct.store &&
                        selectedProduct.store.followers) ||
                      followingCount > 0
                        ? followingCount + " " + LocaleStrings.follower
                        : ""}
                    </div>
                    <div className="flex pt-4">
                      <div className="pr-4">
                        <button
                          className="h-10 md:h-8 pl-sm pr-sm bg-white border border-variantSel text-vendorLink rounded font-normal text-sm btn-light"
                          onClick={() => followUnfollow()}
                        >
                          {followLoader ? (
                            <svg
                              className="animate-spin h-5 w-5 rounded-full mr-3"
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                          ) : following ? (
                            LocaleStrings.unfollow_btn
                          ) : (
                            LocaleStrings.follow_btn
                          )}
                        </button>
                      </div>
                      <div>
                        <button
                          className="h-10 md:h-8 pl-sm pr-sm bg-white border border-variantSel text-vendorLink rounded font-normal text-sm btn-light"
                          onClick={() => sendMessage()}
                        >
                          {LocaleStrings.send_message_btn}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex mt-7">
                  <div className="pr-4">
                    <img src={ReturnpolicyIcon} className="w-12" />
                  </div>
                  <div>
                    <div className="text-base font-medium ">
                      {" "}
                      {LocaleStrings.return_policy}
                    </div>
                    <div>
                      {selectedProduct &&
                      selectedProduct.product_refundtype &&
                      selectedProduct.product_refundtype.name_en
                        ? selectedProduct.product_refundtype.type == "moneyback"
                          ? LocaleStrings.btn_return + " : "
                          : ProductTextLocalized(
                              selectedProduct.product_refundtype.name_en,
                              selectedProduct.product_refundtype.name_ar,
                              localLanguage
                            ) + " : "
                        : ""}
                      {selectedProduct &&
                      selectedProduct.return_time_details &&
                      selectedProduct.return_time_details.name_en
                        ? ProductTextLocalized(
                            selectedProduct.return_time_details.name_en,
                            selectedProduct.return_time_details.name_ar,
                            localLanguage
                          )
                        : ""}
                    </div>
                  </div>
                </div>
                {/* <div className="flex mt-7">
                  <div className="pr-4">
                    <img src={BuyerprotectionIcon} className="w-12" />
                  </div>
                  <div className="text-base font-medium ">
                    Shopez Buyer Protection
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="mt-5 block md:hidden">
            {selectedProduct &&
            selectedProduct.ads &&
            selectedProduct.ads.length > 0 ? (
              <div className="w-full pr-0">
                <div
                  className="rounded-lg cursor-pointer flex  justify-center md:flex-shrink-0"
                  onClick={() => desiredPage(selectedProduct.ads[0])}
                >
                  <LazyLoadImage
                    src={`${FILES_URL}/adbanners/${selectedProduct.ads[0].banner}?api_key=${APP_API_KEY}`}
                    className="h-full w-full rounded-lg "
                    effect="blur"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div>
              <div className="flex mt-7">
                <div className="pr-4">
                  <img src={SoldbyIcon} className=" w-12" />
                </div>
                <div>
                  <div className="flex items-center">
                    <div className="text-base font-medium ">
                      {LocaleStrings.soldby}
                    </div>
                    <div className="flex items-center gap-3">
                      <div className="pl-2 ">
                        {selectedProduct.store &&
                        selectedProduct.store.userid ? (
                          <a
                            className="text-link font-bold text-base cursor-pointer"
                            href={
                              isLoggedIn
                                ? `/seller-profile/${selectedProduct.store.userid}`
                                : `/login`
                            }
                          >
                            {selectedProduct &&
                            selectedProduct.store &&
                            selectedProduct.store.businessname
                              ? selectedProduct.store.businessname
                              : ""}
                          </a>
                        ) : (
                          <div
                            className="text-link font-bold text-base cursor-pointer"
                            onClick={() =>
                              viewProfileModal(selectedProduct.store.createdby)
                            }
                          >
                            {selectedProduct &&
                            selectedProduct.store &&
                            selectedProduct.store.storename
                              ? selectedProduct.store.storename
                              : ""}
                          </div>
                        )}
                      </div>
                      <div>
                        {selectedProduct.store &&
                        (selectedProduct.store.isgold == "1" ||
                          selectedProduct.store.isgold == 1) ? (
                          <img src={GoldIcon} className="w-7" />
                        ) : selectedProduct.store &&
                          (selectedProduct.store.verified == "1" ||
                            selectedProduct.store.verified == 1) ? (
                          <img src={BlueIcon} className="w-7" />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-primary font-normal text-xs">
                    {selectedProduct &&
                    selectedProduct.store &&
                    selectedProduct.store.followers
                      ? selectedProduct.store.followers +
                        " " +
                        LocaleStrings.follower
                      : ""}
                  </div>
                  <div className="flex pt-4">
                    <div className="pr-4">
                      <button
                        className="h-10 md:h-8 pl-sm pr-sm bg-white border border-variantSel text-vendorLink rounded font-normal text-sm btn-light"
                        onClick={() => followUnfollow()}
                      >
                        {followLoader ? (
                          <svg
                            className="animate-spin h-5 w-5 rounded-full mr-3"
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                        ) : following ? (
                          LocaleStrings.unfollow_btn
                        ) : (
                          LocaleStrings.follow_btn
                        )}
                      </button>
                    </div>
                    <div>
                      <button
                        className="h-10 md:h-8 pl-sm pr-sm bg-white border border-variantSel text-vendorLink rounded font-normal text-sm btn-light"
                        onClick={() => sendMessage()}
                      >
                        {LocaleStrings.send_message_btn}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mt-7">
                <div className="pr-4">
                  <img src={ReturnpolicyIcon} className="w-12" />
                </div>
                <div>
                  <div className="text-base font-medium ">
                    {" "}
                    {LocaleStrings.return_policy}
                  </div>
                  <div>
                    {selectedProduct &&
                    selectedProduct.product_refundtype &&
                    selectedProduct.product_refundtype.name_en
                      ? selectedProduct.product_refundtype.type == "moneyback"
                        ? LocaleStrings.btn_return + " : "
                        : ProductTextLocalized(
                            selectedProduct.product_refundtype.name_en,
                            selectedProduct.product_refundtype.name_ar,
                            localLanguage
                          ) + " : "
                      : ""}
                    {selectedProduct &&
                    selectedProduct.return_time_details &&
                    selectedProduct.return_time_details.name_en
                      ? ProductTextLocalized(
                          selectedProduct.return_time_details.name_en,
                          selectedProduct.return_time_details.name_ar,
                          localLanguage
                        )
                      : ""}
                  </div>
                </div>
              </div>
              {/* <div className="flex mt-7">
                <div className="pr-4">
                  <img src={BuyerprotectionIcon} className="w-12" />
                </div>
                <div className="text-base font-medium ">
                  Shopez Buyer Protection
                </div>
              </div> */}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-12  gap-0 md:gap-2 lg:gap-4">
            <div className="col-span-4"></div>
            <div className="col-span-8">
              <div className="pt-5 pl-0 xl:pl-4 lg:pl-5 md:pl-4">
                <div className="">
                  <div className="py-4 text-left text-numbid font-bold text-base md:text-xltext-22 border-t border-b border-header">
                    {LocaleStrings.product_desc}
                  </div>
                  <div
                    className="py-5 text-left text-base text-sold font-normal"
                    id="view_details"
                  >
                    {productDetails.length > 200
                      ? viewMore
                        ? productDetails.substr(0, 200)
                        : productDetails
                      : productDetails}
                    {productDetails.length > 200 ? (
                      <>
                        ...
                        <a
                          className="text-vendorLink cursor-pointer"
                          onClick={() => moreView()}
                        >
                          {viewMore
                            ? LocaleStrings.readmore
                            : LocaleStrings.readless}
                        </a>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <div
                    ref={overviewRef}
                    className="py-4 text-left text-numbid font-bold text-base md:text-xltext-22 border-t border-b border-header"
                  >
                    {LocaleStrings.product_overview}
                  </div>
                  <div
                    className="py-5 text-left text-base text-sold font-normal"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {productSpec.length > 200
                      ? viewSpec
                        ? productSpec.substr(0, 200)
                        : productSpec
                      : productSpec}
                    {productSpec.length > 200 ? (
                      <>
                        ...
                        <a
                          className="text-vendorLink cursor-pointer"
                          onClick={() => moreOverView()}
                        >
                          {viewSpec
                            ? LocaleStrings.readmore
                            : LocaleStrings.readless}
                        </a>
                      </>
                    ) : (
                      ""
                    )}
                  </div> */}
                  <div className="py-4 text-left text-numbid font-bold text-base md:text-xltext-22 border-t border-b border-header">
                    {LocaleStrings.product_spec}
                  </div>
                  <div className="text-specification font-normal text-base">
                    {specification.length > 0
                      ? specification.map((obj, index) => {
                          return (
                            <div
                              className={classNames(
                                index % 2 == 0 ? "bg-oddrow" : "bg-white",
                                "flex items-center py-3"
                              )}
                              key={`obj${index}`}
                            >
                              <div className="text-specification font-normal text-base pl-8 w-2/5  lg:w-1/3">
                                {ProductTextLocalized(
                                  obj.name_en,
                                  obj.name_ar,
                                  localLanguage
                                )}
                                :
                              </div>
                              <div className="text-numbid  font-normal text-base pl-3 w-3/5  lg:w-2/3">
                                {ProductTextLocalized(
                                  obj.value_en,
                                  obj.value_ar,
                                  localLanguage
                                )}
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>

                  {/* <div
  className="mt-1 border-t border-b border-primary cursor-pointer"
  onClick={() => viewAll()}
>
  <div className="grid grid-cols-2 gap-1 pt-md pb-md ">
    <div className="text-left text-link text-sm font-normal">
      {LocaleStrings.view_all_details}
    </div>
    <div className="flex justify-end">
      <img
        src={RightArrow}
        className="w-9 transform animation duration-500 ease-in-out"
      />
    </div>
  </div>
</div> */}
                </div>

                {/* <div className="pl-0 md:pl-5 pt-5 md:pt-0">
<div className="bg-delivery rounded pb-6">
  <div className="px-30 pt-2.5 pb-2.5 border-b border-primary text-primary font-normal text-base">
    {LocaleStrings.delivery}
  </div>
  {isLoggedIn &&
  userDefaultAddress &&
  userDefaultAddress.length > 0 ? (
    <div className="px-30 pt-2.5 text-primary font-normal text-base pb-4">
      <div className="text-primary font-normal text-base pb-1">
        {LocaleStrings.deliverto}
      </div>
      <div className="flex pb-3.5 items-center">
        <div className="pr-3.5">
          <img src={DeliverytoIcon} className="w-6" />
        </div>
        <div>
          <div className="text-primary font-normal text-base">
            {userDefaultAddress[0].addresstype}
            {" : "}
            {userDefaultAddress[0].username}
          </div>
          <div className="text-primary font-normal text-xs">
            {userDefaultAddress[0].address}
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  )}
  {selectedProduct && selectedProduct.handling ? (
    <div className="px-30 pt-2.5 pb-4">
      <div className="text-primary font-normal text-base pb-1">
        {LocaleStrings.deliveryby}
      </div>
      <div className="flex pb-3.5 items-center">
        <div className="pr-3.5">
          <img src={DeliverybyIcon} className="w-6" />
        </div>
        <div>
          <div className="text-primary font-normal text-base">
            
            {ProductTextLocalized(
              selectedProduct.handling.name_en,
              selectedProduct.handling.name_ar,
              localLanguage
            )}
          </div>
          <div className="text-primary font-normal text-xs">
            ${selectedProduct.shipping_cost}{" "}
            {LocaleStrings.shipping}
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  )}

  {selectedProduct && selectedProduct.return_time_details ? (
    <div className="px-30 pt-2.5 pb-4">
      <div className="text-primary font-normal text-base pb-1">
        {LocaleStrings.return_policy}
      </div>
      <div className="text-primary text-xs font-normal pb-4">
        {selectedProduct && selectedProduct.product_refundtype
          ? selectedProduct.product_refundtype.type == "moneyback"
            ? LocaleStrings.btn_return + " : "
            : ProductTextLocalized(
                selectedProduct.product_refundtype.name_en,
                selectedProduct.product_refundtype.name_ar,
                localLanguage
              ) + " : "
          : ""}
        {ProductTextLocalized(
          selectedProduct.return_time_details.name_en,
          selectedProduct.return_time_details.name_ar,
          localLanguage
        )}
      </div>
    </div>
  ) : (
    ""
  )}

  

  {selectedProduct && selectedProduct.store ? (
    <div
      className="px-30 pt-2.5 text-primary font-normal text-base"
      ref={sellerRef}
    >
      <div className="text-primary font-normal text-base pb-1">
        {LocaleStrings.soldby}
      </div>
      <div className="flex pb-3.5">
        {selectedProduct.store.userid ? (
          <a
            className="cursor-pointer"
            href={`/seller-profile/${selectedProduct.store.userid}`}
            // onClick={() =>
            //   viewSeller(selectedProduct.store.userid)
            // }
          >
            <img
              src={`${FILES_URL}/users/${selectedProduct.store.userid}.jpg?api_key=${APP_API_KEY}`}
              className="rounded-full w-46 h-46 object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = NoImg;
              }}
            />
          </a>
        ) : (
          <div
            className="cursor-pointer"
            onClick={() =>
              viewProfileModal(selectedProduct.store.createdby)
            }
          >
            <img
              src={`${FILES_URL}/users/${selectedProduct.store.createdby}.jpg?api_key=${APP_API_KEY}`}
              className="rounded-full w-46 h-46 object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = NoImg;
              }}
            />
          </div>
        )}

        <div className="pl-3.5">
          {selectedProduct.store.userid ? (
            <a
              className="text-primary font-normal text-base cursor-pointer"
              // onClick={() =>
              //   viewSeller(selectedProduct.store.userid)
              // }
              href={`/seller-profile/${selectedProduct.store.userid}`}
            >
              {selectedProduct &&
              selectedProduct.store &&
              selectedProduct.store.businessname
                ? selectedProduct.store.businessname
                : ""}
            </a>
          ) : (
            <div
              className="text-primary font-normal text-base cursor-pointer"
              onClick={() =>
                viewProfileModal(selectedProduct.store.createdby)
              }
            >
              {selectedProduct &&
              selectedProduct.store &&
              selectedProduct.store.storename
                ? selectedProduct.store.storename
                : ""}
            </div>
          )}

          <div className="text-primary font-normal text-xs">
            {selectedProduct &&
            selectedProduct.store &&
            selectedProduct.store.followers
              ? selectedProduct.store.followers +
                " " +
                LocaleStrings.follower
              : ""}
          </div>

          <div className="flex pt-4">
            <div className="pr-4">
              <button
                className="h-10 md:h-8 pl-sm pr-sm bg-white border border-variantSel text-vendorLink rounded font-normal text-sm btn-light"
                onClick={() => followUnfollow()}
              >
                {followLoader ? (
                  <svg
                    className="animate-spin h-5 w-5 rounded-full mr-3"
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                ) : following ? (
                  LocaleStrings.unfollow_btn
                ) : (
                  LocaleStrings.follow_btn
                )}
              </button>
            </div>
            <div>
              <button
                className="h-10 md:h-8 pl-sm pr-sm bg-white border border-variantSel text-vendorLink rounded font-normal text-sm btn-light"
                onClick={() => sendMessage()}
              >
                {LocaleStrings.send_message_btn}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  )}
</div>
</div> */}
              </div>
              {selectedProduct && selectedProduct.saletype != "auction" ? (
                <div ref={reviewRef} className="pl-0 xl:pl-4 lg:pl-5 md:pl-4">
                  <Reviews
                    productid={productid}
                    selectedProduct={selectedProduct}
                    reviewData={reviewData}
                    setReviewData={setReviewData}
                  />
                </div>
              ) : (
                ""
              )}

              <div className="pl-0 xl:pl-4 lg:pl-5 md:pl-4">
                <QuestionAnswer
                  productid={productid}
                  selectedProduct={selectedProduct}
                  setQuestionData={setQuestionData}
                  questionData={questionData}
                />
              </div>
            </div>
          </div>
          <div className="pt-5">
            <SimilarProduct
              productid={productid}
              setIschanged={setIschanged}
              isChanged={isChanged}
            />
          </div>
        </div>
      )}

      <SendMessage />
      <ViewDetails
        selectedProduct={selectedProduct}
        selectedVariant={selectedVariant}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  productList: state.productList,
  localLanguage: state.localLanguage,
  userDefaultAddress: state.userDefaultAddress,
  selectedProduct: state.selectedProduct,
  storeMessageOpen: state.storeMessageOpen,
  navigateUser: state.navigateUser,
  deviceSize: state.deviceSize,
  localCurrency: state.localCurrency,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchProducts,

      getUserAddress,
      fetchProductByID,
      questionListByProduct,
      followUnfollow,
      isSendMessageStoreOpen,
      messageInitiate,
      fetchMessageThread,
      updateViews,
      fetchPublicBids,
      isViewAllDetailsModalOpen,
      profileModalOpen,
      selectedFollowerUserDetails,
      selectedFollowersDetails,
      selectedFollowersShearedWishlist,
      emptySharedWishlist,
      profileViewNavigation,
      viewPublicProfile,
      fetchCart,
      fetchAllReviews,
      isbidPlacedSliderOpen,
      isCommentModalOpen,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
