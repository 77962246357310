import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import HammerNodata from "../../../assets/images/hammer-nodata.svg";
import ItemList from "./list-item";
import PublicBid from "../..//Product/components/public-bid";
import SlideOver from "../../../components/Common/SlideOver";
import ChooseVariant from "../../Product/components/choose-variant";
import { fetchMybids, emptyMybids } from "../actions";
import { fetchCart } from "../../Product/actions";

function Mybids(props) {
  const history = useHistory();
  const { session, isLoggedIn, myBid, localLanguage } = props;

  const [originate, setOriginate] = useState("");
  const [variantId, setVariantId] = useState("");
  const [productId, setProductId] = useState("");
  const [selectedVariant, setSelectedVariant] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [allVariants, setAllVariants] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [currentTab, setCurrenTab] = useState("active");
  const tabArr = [
    { key: "active", text: LocaleStrings.negotiation_active },
    { key: "won", text: LocaleStrings.negotiation_won },
    { key: "lost", text: LocaleStrings.negotiation_lost },
  ];

  useEffect(() => {
    if (isLoggedIn) {
      props.fetchMybids(session, currentTab);
    } else {
      history.push("/login");
    }
  }, []);

  const _handleDateTab = (e, selectedTab) => {
    setCurrenTab(selectedTab);
    props.emptyMybids();
    props.fetchMybids(session, selectedTab);
    if (selectedTab == "won") props.fetchCart(session);
  };

  const renderList = () => {
    return _.map(myBid.data, (item, index) => {
      return (
        <ItemList
          item={item.product}
          bidprice={item.bidprice}
          status={item.status}
          data={item}
          setProductId={setProductId}
          setVariantId={setVariantId}
          setAllVariants={setAllVariants}
          setProductVariants={setProductVariants}
          setSelectedProduct={setSelectedProduct}
          setSelectedVariant={setSelectedVariant}
        />
      );
    });
  };

  const renderProductVariant = (variantnames, pos) => {
    if (variantnames && variantnames.length > 0) {
      return _.map(variantnames, (vari, index) => {
        return (
          <div
            onClick={
              vari.selected
                ? null
                : () => makeVariantSelection(vari.refkey, pos)
            }
            className={classNames(
              vari.selected
                ? "border-variantSel text-vendorLink"
                : "border-variant text-cardfooter ",
              "p-1 border rounded text-xs flex items-center justify-center cursor-pointer"
            )}
            key={index}
          >
            {ProductTextLocalized(vari.name_en, vari.name_ar, localLanguage)}
          </div>
        );
      });
    }
  };

  const makeVariantSelection = (refkey, pos) => {
    if (productVariants.length > 0) {
      let productVariants = [];
      let selectedVariantKeys = [];
      _.map(selectedProduct.product_variants, (obj, row) => {
        let variantnames = [];
        if (obj.variantnames && obj.variantnames.length > 0) {
          _.map(obj.variantnames, (data, index) => {
            if (pos == row) {
              data.selected = data.refkey == refkey ? true : false;
              if (data.selected) {
                selectedVariantKeys.push(refkey);
              }
            } else {
              data.selected = data.selected;
              if (data.selected) {
                selectedVariantKeys.push(data.refkey);
              }
            }

            variantnames.push(data);
          });
        }

        obj.variantnames = variantnames;
        productVariants.push(obj);
      });
      setProductVariants(productVariants);

      let selectedVariantKey = selectedVariantKeys.join("-");
      //console.log("selectedVariantKey", selectedVariantKey);
      if (
        selectedProduct.all_variants &&
        selectedProduct.all_variants.length > 0
      ) {
        let allVariants = [];

        _.map(selectedProduct.all_variants, (obj, index) => {
          obj.selected = obj.refkey == selectedVariantKey ? true : false;
          allVariants.push(obj);
        });
        //console.log("allVariants", allVariants);
        setAllVariants(allVariants);

        let selectedVariant = _.filter(allVariants, function (o) {
          return o.selected;
        });
        //console.log("selectedVariant", selectedVariant);
        if (selectedVariant && selectedVariant.length > 0) {
          setSelectedVariant(selectedVariant);
          console.log("variantid", selectedVariant[0].id);
          setVariantId(selectedVariant[0].id);
        }
      }
    }
  };
  return (
    <>
      <div className="mt-5 mb-60 custom_container">
        <div className="text-left text-primary text-xs">
          <a
            onClick={() => history.push("/activity")}
            className="cursor-pointer"
          >
            {LocaleStrings.activity}
          </a>
          &nbsp;/&nbsp; {LocaleStrings.buying_history_bids_placed}
        </div>
        <div className="mt-2">
          <nav
            className="relative z-0 flex divide-x divide-gray-200"
            aria-label="Tabs"
          >
            {tabArr.map((tab, tabIdx) => (
              <a
                key={tab.key}
                className={classNames(
                  currentTab == tab.key
                    ? "text-primary "
                    : "text-tabinactive hover:text-primary ",
                  "relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-normal text-center hover:bg-quaternary focus:z-10 cursor-pointer"
                )}
                aria-current={currentTab == tab.key ? "page" : undefined}
                onClick={(e) => _handleDateTab(e, tab.key)}
              >
                <span>{tab.text}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    currentTab == tab.key ? "bg-primary" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div>
        <div className="my-2">
          {myBid && myBid.data ? (
            myBid.data.length > 0 ? (
              <div className="grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 gap-5">
                {renderList()}
              </div>
            ) : (
              <div className="flex h-cardimg items-center justify-center">
                <div>
                  <div className="flex items-center justify-center pb-5">
                    <img src={HammerNodata} />
                  </div>
                  <div className="flex items-center justify-center text-cutoff text-base font-medium">
                    {currentTab == "won"
                      ? LocaleStrings.no_bids_won_title
                      : currentTab == "lost"
                      ? LocaleStrings.no_bids_lost_title
                      : LocaleStrings.no_bids_active_title}
                  </div>
                  <div className="flex items-center justify-center text-cutoff text-sm">
                    {currentTab == "won"
                      ? LocaleStrings.no_bids_won_desc
                      : currentTab == "lost"
                      ? LocaleStrings.no_bids_lost_desc
                      : LocaleStrings.no_bids_active_desc}
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="flex h-cardimg items-center justify-center">
              <Loader />
            </div>
          )}
        </div>
      </div>
      <PublicBid />
      <SlideOver title={""}>
        <ChooseVariant
          productid={productId}
          variantId={variantId}
          allVariants={allVariants}
          productVariants={productVariants}
          selectedProduct={selectedProduct}
          selectedVariant={selectedVariant}
          originate={originate}
          renderProductVariant={renderProductVariant}
        />
      </SlideOver>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  myBid: state.myBid,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchMybids, emptyMybids, fetchCart }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Mybids);
