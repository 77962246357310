import { WATCH_LIST } from "../actions";

export function myWatchList(state = [], action) {
  switch (action.type) {
    case WATCH_LIST:
      return action.payload;
    default:
      return state;
  }
}
