import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LangLogo from "../assets/images/lang.svg";
import EnglishLogo from "../assets/images/english_white.svg";
import ArabicLogo from "../assets/images/arabic_white.svg";

import LanguageSelect from "./language-select";
import CurrencySelect from "./currency-select";

function NavLeft(props) {
  const { deviceSize, localLanguage, isLoggedIn, session, localCurrency } =
    props;
  return (
    <>
      <div className="text-left flex items-center">
        {/* <div className="text-white text-sm flex items-center">
          <span className="pr-sm">
            <img
              src={localLanguage == "en" ? EnglishLogo : ArabicLogo}
              style={{ width: 26 }}
            />
          </span>
          <LanguageSelect />
        </div>
        <div className="text-white text-sm flex items-center">
          <CurrencySelect />
        </div> */}
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  deviceSize: state.deviceSize,
  localLanguage: state.localLanguage,
  localCurrency: state.localCurrency,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NavLeft);
