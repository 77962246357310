import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import AutoSuggest from "react-autosuggest";
import Loader from "../../../components/Common/loader";
import SendbtnLogo from "../../../assets/images/send_btn.svg";
import { Fragment } from "react";
import { Transition, Dialog, Menu } from "@headlessui/react";
import { XIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import NoImg from "../../../assets/images/noimg.png";
import toast, { Toaster } from "react-hot-toast";
import {
  isCommentModalOpen,
  searchTaggable,
  fetchCommentList,
  addComment,
  logActivity,
  deletComment,
  updateComment,
  emptyCommentList,
  fetchMentionUser,
  openReportModal,
  mentionEmail,
} from "../actions";
import {
  profileModalOpen,
  selectedFollowerUserDetails,
  selectedFollowersDetails,
  selectedFollowersShearedWishlist,
  emptySharedWishlist,
  profileViewNavigation,
  viewPublicProfile,
} from "../../Activity/actions";
import { confirmAlert } from "react-confirm-alert";

function Comment(props) {
  const history = useHistory();
  const {
    session,
    isLoggedIn,
    localLanguage,
    isCommentOpen,
    productid,
    commentList,
    setIschanged,
    mentionUserList,
    navigateUser,
    highlightComment,
  } = props;
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  const [rawMessage, setRawMessage] = useState([]);
  const [userTags, setUserTags] = useState([]);
  const [commentId, setCommentId] = useState("");

  useEffect(() => {
    if (productid) {
      props.fetchCommentList(session, productid, (res) => {});
      props.fetchMentionUser(session, "");
    }
  }, [productid]);

  const closeSlideOvers = () => {
    setValue("");
    setUserTags([]);
    props.isCommentModalOpen(false);
  };

  const getSuggestionValue = (suggestion) => {
    //console.log("suggestion", suggestion);
    let newArr = userTags;
    newArr.push(parseInt(suggestion.userid));
    setUserTags(newArr);
    let usernameArr = rawMessage;
    usernameArr.push(suggestion.username);
    setRawMessage(usernameArr);

    let combineText = value;
    let pos = combineText.lastIndexOf("@");
    if (pos >= 0) {
      let inputValue = combineText.substr(pos + 1);
      combineText = combineText.replace(inputValue, suggestion.username);
    }
    return combineText;
  };

  const renderSuggestion = (suggestion) => {
    return (
      <div className="flex items-center">
        <div className="pr-4">
          <img
            key={suggestion.userid}
            className="max-w-none h-6 w-6 rounded-full ring-2 ring-white"
            src={`${FILES_URL}/users/${suggestion.userid}.jpg?api_key=${APP_API_KEY}`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = NoImg;
            }}
          />
        </div>
        <div>{suggestion.username}</div>
      </div>
    );
  };
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const onSuggestionsFetchRequested = ({ value }) => {
    let pos = value.lastIndexOf("@");
    if (pos >= 0) {
      let inputValue = value.substr(pos + 1);
      props.searchTaggable(session, inputValue, (res) => {
        if (res.success == 1 && res.users && res.users.length > 0) {
          setSuggestions(res.users);
        }
      });
    }
  };

  const onChange = (e, { newValue }) => {
    var regex = /^\s+$/;
    if (!newValue.match(regex)) {
      setValue(newValue);
    }
  };
  const inputProps = {
    placeholder: "",
    value,
    onChange: onChange,
  };

  const renderInputComponent = (props) => {
    return (
      <input
        {...props}
        placeholder={LocaleStrings.type_your_message}
        className="bg-newarrival w-full rounded-full h-44 text-loginLabel text-15 pl-5 focus:outline-none"
      />
    );
  };

  const replaceTags = (comment, tags) => {
    let tagsArr = tags.split(",");
    let returnText = comment;
    _.map(tagsArr, (item, index) => {
      let sent1 = "<a href='http://" + parseInt(item) + "'>";
      if (returnText.indexOf(sent1) >= 0) {
        returnText = returnText.replace(sent1, "@");
        returnText = returnText.replace("</a>", "");
      }
    });
    let finalArr = returnText.split(" ");

    return finalArr;
  };

  const replaceUsername = (tags) => {
    let tagsArr = tags.split(",");

    if (tagsArr.length > 0) {
      let usernameArr = rawMessage;
      let userList =
        mentionUserList &&
        mentionUserList.data &&
        mentionUserList.data.length > 0
          ? mentionUserList.data
          : [];

      _.map(tagsArr, (item, index) => {
        let selectedUser = _.filter(userList, function (o) {
          return parseInt(o.userid) == parseInt(item);
        });

        if (selectedUser.length > 0) {
          usernameArr.push(selectedUser[0].username);
        }
      });
      setRawMessage(usernameArr);
    }
  };

  const reportComment = (commentid) => {
    if (isLoggedIn) {
      confirmAlert({
        title: LocaleStrings.report_confirm_comment_title,
        message: LocaleStrings.report_confirm_comment_message,
        buttons: [
          {
            label: LocaleStrings.button_yes,
            onClick: () => {
              props.openReportModal(true, commentid, "comment");
            },
          },
          {
            label: LocaleStrings.button_no,
            onClick: () => {},
          },
        ],
      });
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  const rederComments = (item) => {
    let titleStrArr = replaceTags(item.comment, item.tags);
    let tagsArr = item.tags.split(",");
    //console.log("highlightComment", highlightComment);
    return (
      <div
        className={
          highlightComment &&
          highlightComment.commentid &&
          parseInt(highlightComment.commentid) == item.id
            ? "flex mb-6 highlight"
            : "flex mb-6"
        }
        key={item.id}
      >
        <div
          className="w-wishdetailbox pt-1 cursor-pointer"
          onClick={() =>
            isLoggedIn ? redirectYou(item.commentby.userid) : null
          }
        >
          <img
            key={item.commentby.userid}
            className="h-5 md:h-10 w-5 md:w-10 rounded-full ring-2 ring-white object-cover"
            src={`${FILES_URL}/users/${item.commentby.userid}.jpg?api_key=${APP_API_KEY}`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = NoImg;
            }}
          />
        </div>
        <div className="w-4/5 pl-3 grid grid-cols-3">
          <div
            className="col-span-2 font-normal text-primary text-xs md:text-sm cursor-pointer"
            onClick={() =>
              isLoggedIn ? redirectYou(item.commentby.userid) : null
            }
          >
            {item.commentby.businessname
              ? item.commentby.businessname
              : item.commentby.username}
          </div>
          <div>
            <div className="text-right font-normal text-loginLabel text-10 md:text-xs">
              {timeAgo(item.createdat)}
            </div>
          </div>

          <div
            className="col-span-3 font-normal text-primary text-sm pt-1"
            style={{
              textAlign: "left",
              overflowWrap: "break-word",
              hyphens: "auto",
            }}
          >
            {/* {_.map(replaceTags(item.comment, item.tags), (obj, pos) => {
              return obj.indexOf("@") >= 0 ? (
                <span className="text-link pr-1">{obj}</span>
              ) : (
                obj
              );
            })} */}

            {titleStrArr.map((word, k) => {
              if (word.includes("@")) {
                return (
                  <span
                    className="text-link pr-1 cursor-pointer"
                    key={`title_key_${k}`}
                    onClick={() => redirectYou(tagsArr[k])}
                  >
                    {word}
                  </span>
                );
              }
              return <span key={`title_key_${k}`}>{word}&nbsp;</span>;
            })}
          </div>
        </div>

        <div className="w-wishdetailbox text-right" key={item.id}>
          {item.reportabuse == 0 ? (
            <Menu as="div" className="relative inline-block text-left">
              {({ open }) => (
                <>
                  <div className="">
                    <Menu.Button className="btn-card">
                      <span className="sr-only">Open options</span>
                      <DotsVerticalIcon
                        className="h-5 w-5 hover:shadow"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer z-50"
                    >
                      <div className="py-1 divide-y z-50">
                        {item.commentby.userid == session.userid ? (
                          <>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  onClick={() => editComment(item)}
                                  className={classNames(
                                    active ? "bg-quaternary " : " ",
                                    "block px-4 py-2 text-sm text-primary"
                                  )}
                                >
                                  {LocaleStrings.button_edit}
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  onClick={() => deletComment(item.id)}
                                  className={classNames(
                                    active ? "bg-quaternary " : " ",
                                    "block px-4 py-2 text-sm text-primary"
                                  )}
                                >
                                  {LocaleStrings.button_delete}
                                </a>
                              )}
                            </Menu.Item>
                          </>
                        ) : (
                          ""
                        )}
                        {item.commentby.userid != session.userid ? (
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                onClick={() => reportComment(item.id)}
                                className={classNames(
                                  active ? "bg-quaternary " : " ",
                                  "block px-4 py-2 text-sm text-primary"
                                )}
                              >
                                {LocaleStrings.report_as_abusive}
                              </a>
                            )}
                          </Menu.Item>
                        ) : (
                          ""
                        )}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const sendMessage = () => {
    if (value) {
      setLoading(true);
      let values = {};
      values.commentby = session.userid;

      values.productid = productid;
      let message = value;
      let newUserTags = [];
      if (rawMessage.length > 0) {
        _.map(rawMessage, (item, index) => {
          if (message.indexOf("@" + item) >= 0) {
            message = message.replace(
              "@" + item,
              "<<" + userTags[index] + ">>"
            );
            newUserTags.push(userTags[index]);
          }
        });
      }
      values.tags = newUserTags.length > 0 ? newUserTags.join(",") : "";
      values.comment = message;
      if (commentId) {
        let id = parseInt(commentId);
        props.updateComment(session, values, id, (res) => {
          if (res.success == 1) {
            props.fetchMentionUser(session, "");
            props.emptyCommentList();
            props.fetchCommentList(session, productid, (res) => {
              setLoading(false);
              setValue("");
              setUserTags([]);
              setCommentId("");
              setRawMessage([]);
              toast.success(LocaleStrings.mention_update_success);
            });
          } else {
            setLoading(false);
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      } else {
        props.addComment(session, values, (res) => {
          if (res.success == 1) {
            if (res.id) {
              let data = {};
              data.commentid = res.id;
              data.emailtype = "mention";
              props.mentionEmail(session, data);
              props.logActivity(session, "comment", res.id);
            }
            props.fetchMentionUser(session, "");
            props.emptyCommentList();
            props.fetchCommentList(session, productid, (res) => {
              setLoading(false);
              //setIschanged(true);
              setValue("");
              setUserTags([]);
              setRawMessage([]);
              toast.success(LocaleStrings.mention_add_success);
            });
          } else {
            setLoading(false);
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      }
    }
  };

  const deletComment = (id) => {
    if (isLoggedIn) {
      props.deletComment(session, id, (res) => {
        if (res.success == 1) {
          props.fetchMentionUser(session, "");
          props.emptyCommentList();
          props.fetchCommentList(session, productid, (res) => {
            //setIschanged(true);
          });
        } else {
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  const editComment = (item) => {
    if (isLoggedIn) {
      let value = replaceTags(item.comment, item.tags);
      replaceUsername(item.tags);
      //console.log("value", value);
      setValue(value.join(" "));
      let tags = item.tags;
      let tagsArr = tags.split(",");
      setUserTags(tagsArr);
      setCommentId(item.id);
    } else {
      history.push({
        pathname: "/login",
        state: { from: "/product-details" },
      });
    }
  };

  const redirectYou = (userid) => {
    let userArr = navigateUser;
    props.profileModalOpen(true, false);
    props.emptySharedWishlist();
    props.selectedFollowerUserDetails(session, userid, (callback) => {});
    props.selectedFollowersDetails(session, userid, (callback) => {});
    props.selectedFollowersShearedWishlist(session, userid, (callback) => {});
    props.viewPublicProfile(session, userid);
    if (!_.includes(userArr, userid)) {
      userArr.push(userid);
      props.profileViewNavigation(userArr);
    }
    closeSlideOvers();
  };

  //console.log("rawMessage", rawMessage);
  return (
    <>
      <Transition.Root show={isCommentOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          open={isCommentOpen}
          onClose={() => closeSlideOvers()}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full bg-white flex flex-col  shadow-xl">
                    <div className="min-h-0 flex-1 flex flex-col pt-3 overflow-y-scroll">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="slider-modal-headtext">
                            <h2
                              id="slide-over-heading"
                              className="text-xl font-normal text-primary"
                            >
                              {LocaleStrings.mention_slider_title}
                            </h2>
                          </Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              onClick={() => closeSlideOvers()}
                              className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 relative flex-1 px-6 md:px-4">
                        {commentList && commentList.data ? (
                          commentList.data.length > 0 ? (
                            _.map(commentList.data, (item, index) => {
                              return rederComments(item);
                            })
                          ) : (
                            <div className="flex h-full font-normal items-center justify-center">
                              {LocaleStrings.mention_no_comment}
                            </div>
                          )
                        ) : (
                          <div className="flex h-full items-center justify-center">
                            <Loader />
                          </div>
                        )}
                      </div>
                    </div>
                    {isLoggedIn ? (
                      <div>
                        <div className="flex-shrink-0 px-4 py-3.5 flex relative">
                          <AutoSuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={(value) =>
                              onSuggestionsFetchRequested(value)
                            }
                            onSuggestionsClearRequested={
                              onSuggestionsClearRequested
                            }
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            renderInputComponent={renderInputComponent}
                            inputProps={inputProps}
                          />
                          <div
                            className="absolute right-9 top-6 cursor-pointer"
                            //onClick={() => sendPrice()}
                          >
                            {loading ? (
                              <svg
                                className="animate-spin h-5 w-5 rounded-full mr-3"
                                viewBox="0 0 1024 1024"
                                focusable="false"
                                data-icon="loading"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                              </svg>
                            ) : (
                              <img
                                src={SendbtnLogo}
                                onClick={() => sendMessage()}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const validate = (values, ownProps) => {
  var errors = {};

  return errors;
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    localLanguage: state.localLanguage,
    deviceSize: state.deviceSize,
    isCommentOpen: state.isCommentOpen,
    commentList: state.commentList,
    mentionUserList: state.mentionUserList,
    navigateUser: state.navigateUser,
    highlightComment: state.highlightComment,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isCommentModalOpen,
      searchTaggable,
      fetchCommentList,
      addComment,
      logActivity,
      deletComment,
      updateComment,
      emptyCommentList,
      fetchMentionUser,
      openReportModal,
      profileModalOpen,
      selectedFollowerUserDetails,
      selectedFollowersDetails,
      selectedFollowersShearedWishlist,
      emptySharedWishlist,
      profileViewNavigation,
      viewPublicProfile,
      mentionEmail,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "CommentForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Comment)
);
