import {
  NEGOTIATION_LIST,
  IS_NEGO_SLIDEOVERS_MODAL_OPEN,
  NEGOTIATION_DETAILS_VIEW,
} from "../actions";

export function myNegotiation(state = [], action) {
  switch (action.type) {
    case NEGOTIATION_LIST:
      return action.payload;
    default:
      return state;
  }
}

export function isNegotiationopen(state = false, action) {
  if (action.type === IS_NEGO_SLIDEOVERS_MODAL_OPEN) {
    state = action.payload;
  }
  return state;
}

export function negotiationView(state = "", action) {
  if (action.type === NEGOTIATION_DETAILS_VIEW) {
    state = action.payload;
  }
  return state;
}
