import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import { SMALL_DATE_FORMAT } from "../../../components/Common/constant";
import toast, { Toaster } from "react-hot-toast";
import CouponBg from "../../../assets/images/coupon-bg.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { availableCoupons } from "../../Cart/actions";

function MyCoupons(props) {
  const history = useHistory();
  const { session, isLoggedIn, getCoupon } = props;

  useEffect(() => {
    if (isLoggedIn) {
      props.availableCoupons(session, (res) => {});
    } else {
      history.push("/login");
    }
  }, []);

  const CouponList = (item) => {
    //console.log("item", item);
    let discountText =
      item.discounttype == "percentage"
        ? item.discount + "% " + LocaleStrings.cart_discount
        : item.discount +
          " " +
          LocaleStrings.cart_flat_discount +
          " " +
          LocaleStrings.cart_discount;
    let totStr = item.title + " - " + discountText;

    return (
      <div className="relative pt-7 flex justify-center">
        <img src={CouponBg} />
        <div class="absolute pt-5 px-4  pb-3 leading-4 w-full">
          <div className="text-primary text-sm font-normal">
            {totStr.length > 36 ? totStr.substr(0, 36) + "..." : totStr}
          </div>
          <div className="text-cardfooter text-xs font-medium mt-3">
            {moment(item.validity_end).format(SMALL_DATE_FORMAT)}
          </div>
          <div className="flex mt-2">
            <div className="w-couponcartleft">
              {item.expired == 1 ? (
                <div className="border-gray-200 text-gray-400 border-dashed border  flex items-center justify-center  text-xs rounded h-7 w-11/12 disabled:opacity-50">
                  {item.code}
                </div>
              ) : (
                <CopyToClipboard
                  text={item.code}
                  onCopy={() => copyCode(item.code)}
                >
                  <div className="border-variantSel text-primary cursor-pointer border-dashed border  flex items-center justify-center  text-xs rounded h-7 w-11/12 disabled:opacity-50">
                    {item.code}
                  </div>
                </CopyToClipboard>
              )}
              {/* <div className="border-variantSel text-primary cursor-pointer border-dashed border border-r-0  flex items-center justify-center  text-xs rounded rounded-tr-none rounded-br-none h-7 w-full disabled:opacity-50">
                {item.code}
              </div> */}
            </div>
            <div className="w-couponcartright flex justify-end">
              {item.expired == 1 ? (
                <div className="bg-progress_red text-white flex items-center justify-center text-xs rounded font-medium h-7 w-5/6 ">
                  {LocaleStrings.cart_coupon_expired}
                </div>
              ) : (
                <CopyToClipboard
                  text={item.code}
                  onCopy={() => copyCode(item.code)}
                >
                  <div className="bg-ask border border-variantSel text-vendorLink flex items-center justify-center text-xs rounded font-medium h-7 w-full cursor-pointer">
                    {LocaleStrings.coupon_copy}
                  </div>
                </CopyToClipboard>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const copyCode = (code) => {
    if (code) {
      toast.success(LocaleStrings.cart_coupon_copied);
    }
  };

  return (
    <>
      <div className="mt-5 mb-2.5 custom_container">
        <div>{LocaleStrings.my_coupons}</div>
      </div>
      <div className="py-25">
        <div className="custom_container">
          {getCoupon && getCoupon.data ? (
            getCoupon.data.length > 0 ? (
              <div className="bg-newarrival grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                {getCoupon.data.map((item, index) => {
                  return CouponList(item);
                })}
              </div>
            ) : (
              ""
            )
          ) : (
            <div className="h-50">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  getCoupon: state.getCoupon,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ availableCoupons }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyCoupons);
