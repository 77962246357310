import {
  PRODUCT_LISTING,
  SIMILAR_PRODUCT,
  SEARCH_PRODUCT,
  PRODUCT_DETAILS,
  HOME_PAGE_DATA,
  SEARCH_KEY,
} from "../actions";

export function productList(state = [], action) {
  switch (action.type) {
    case PRODUCT_LISTING:
      return action.payload;
    default:
      return state;
  }
}

export function selectedProduct(state = [], action) {
  switch (action.type) {
    case PRODUCT_DETAILS:
      return action.payload;
    default:
      return state;
  }
}

export function similarProductList(state = [], action) {
  switch (action.type) {
    case SIMILAR_PRODUCT:
      return action.payload;
    default:
      return state;
  }
}

export function searchList(state = [], action) {
  switch (action.type) {
    case SEARCH_PRODUCT:
      return action.payload;
    default:
      return state;
  }
}

export function homePageData(state = [], action) {
  switch (action.type) {
    case HOME_PAGE_DATA:
      return action.payload;
    default:
      return state;
  }
}

export function getSearchKey(state = {}, action) {
  switch (action.type) {
    case SEARCH_KEY:
      return action.payload;
    default:
      return state;
  }
}
