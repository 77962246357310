import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
  SMALL_DATE_FORMAT,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import ItemList from "../../Home/components/item-list";
import { fetchMyRecentView, emptyRecentView } from "../actions";

function RecentViewed(props) {
  const history = useHistory();
  const { session, isLoggedIn, myRecentViewed } = props;

  useEffect(() => {
    if (isLoggedIn) {
      props.fetchMyRecentView(session);
    } else {
      history.push("/login");
    }
  }, []);

  const renderList = (items) => {
    return _.map(items, (item, index) => {
      return (
        <ItemList
          item={item}
          itemid={item.id}
          type="similar"
          prevpage="recent-viewed"
          pagetitle={LocaleStrings.acitvity_recently_viewed}
        />
      );
    });
  };
  return (
    <>
      <div className="py-25 custom_container">
        <div className="text-left text-primary text-xs pb-5">
          <a
            onClick={() => history.push("/activity")}
            className="cursor-pointer"
          >
            {LocaleStrings.activity}
          </a>
          &nbsp;/&nbsp; {LocaleStrings.acitvity_recently_viewed}
        </div>
        <div className="my-2 custom_container">
          {myRecentViewed && myRecentViewed.data ? (
            myRecentViewed.data.length > 0 ? (
              <div className="">
                {_.map(myRecentViewed.data, (item, index) => {
                  return (
                    <div>
                      <div className="text-primary text-base font-medium">
                        {moment(item.date).format(SMALL_DATE_FORMAT)}
                      </div>
                      <div
                        className="pt-5 pb-5 grid
                        grid-cols-2
                        xl:grid-cols-6
                        lg:grid-cols-4
                        md:grid-cols-4
                        sm:grid-cols-4
                        gap-5"
                      >
                        {renderList(item.products)}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex items-center justify-center">
                {LocaleStrings.recently_viewed_not_found}
              </div>
            )
          ) : (
            <div className="h-50">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  myRecentViewed: state.myRecentViewed,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchMyRecentView, emptyRecentView }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RecentViewed);
