import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../../languages/index";

// Import Components

// Import Actions
import {
  fetchUsersDeatils,
  isProfileinfoCommonOpen,
  setSelectedGender,
} from "../../../actions/index";

// Functions Import
import { classNames } from "../../../../../components/Common/constant";

/* This example requires Tailwind CSS v2.0+ */

class GenderSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_profile_information,
      genderState: "",
    };

    // All Binded Functions
  }

  componentDidMount() {
    this.setState({ genderState: this.props.storeSelectedGender });
  }

  /**** On Click gender Change ****/
  _handleGenderChange(e, gparam) {
    this.setState({ genderState: gparam });
  }

  /**** local Gender Change ****/
  _handleDone() {
    let { genderState } = this.state;
    //console.log("genderState", genderState);
    this.props.setSelectedGender(genderState);
    this.props.isProfileinfoCommonOpen(false);
  }

  render() {
    // Note:- storeSelectedGender -> db gender
    let { headerText, footerText, callFrom, storeSelectedGender } = this.props;
    let { genderState } = this.state;

    return (
      <>
        <div className="flow-root my-40">
          <ul role="list" className="-my-5 divide-y divide-gray-200">
            <li
              key={"list-male-key"}
              className="py-4 bg-custWhite px-4 cursor-pointer table-hover-transition"
              onClick={(e) => this._handleGenderChange(e, "male")}
            >
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0"></div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-ternary truncate capitalize">
                    {LocaleStrings.male}
                  </p>
                </div>
                <div>
                  <svg
                    className={classNames(
                      genderState === "male" ? "" : "hidden",
                      "h-8 w-8 text-success cursor-pointer"
                    )}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="var(--theme-color)"
                  >
                    <path
                      stroke-linecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              </div>
            </li>

            <li
              key={"list-female-key"}
              className="py-4 bg-custWhite px-4 cursor-pointer table-hover-transition"
              onClick={(e) => this._handleGenderChange(e, "female")}
            >
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0"></div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-ternary truncate capitalize">
                    {LocaleStrings.female}
                  </p>
                </div>
                <div>
                  <svg
                    className={classNames(
                      genderState === "female" ? "" : "hidden",
                      "h-8 w-8 text-success cursor-pointer"
                    )}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="var(--theme-color)"
                  >
                    <path
                      stroke-linecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              </div>
            </li>

            <li
              key={"list-others-key"}
              className="py-4 bg-custWhite px-4 cursor-pointer table-hover-transition"
              onClick={(e) => this._handleGenderChange(e, "")}
            >
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0"></div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-ternary truncate capitalize">
                    {LocaleStrings.rather_not_say}
                  </p>
                </div>
                <div>
                  <svg
                    className={classNames(
                      genderState === "" ? "" : "hidden",
                      "h-8 w-8 text-success cursor-pointer"
                    )}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="var(--theme-color)"
                  >
                    <path
                      stroke-linecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="p-6 border-t-2 border-quaternary text-center">
          {/* Footer */}
          <button
            className="text-white bg-btn text-sm font-normal pt-2.5 pb-2.5 pl-9 pr-9 rounded btn-heavy"
            type="button"
            onClick={(e) => this._handleDone()}
          >
            {LocaleStrings.done}
            <svg
              className={classNames(
                this.state.btnLoader ? "" : "hidden",
                "animate-spin-medium h-5 w-5 rounded-full mx-2"
              )}
              viewBox="0 0 1024 1024"
              focusable="false"
              data-icon="loading"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
            </svg>
          </button>
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let { headerText, footerText } = ownProps;

  return {
    isLoggedIn: state.isLoggedIn,
    user: state.myProfile.data,
    session: state.session,
    isOpenProfileInfoModal: state.isOpenProfileInfoModal,
    storeSelectedGender: state.myProfile
      ? state.myProfile.data
        ? state.myProfile.data.gender
        : ""
      : "",
  };
}

export default connect(mapStateToProps, {
  fetchUsersDeatils,
  isProfileinfoCommonOpen,
  setSelectedGender,
})(GenderSection);
