import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
  SMALL_DATE_FORMAT,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import NoImg from "../../../assets/images/noimg.png";
import YouNodata from "../../../assets/images/you-nodat.svg";
import ProfileView from "./profile-view";
import ReportAbuse from "../../Product/components/report-abuse";
import {
  fetchMyFollowers,
  emptyMyFollowers,
  profileModalOpen,
  selectedFollowerUserDetails,
  selectedFollowersDetails,
  selectedFollowersShearedWishlist,
  emptySharedWishlist,
  profileViewNavigation,
  viewPublicProfile,
} from "../actions";

function MyFollower(props) {
  const history = useHistory();
  const {
    session,
    isLoggedIn,
    myFollower,
    profileModal,
    reportAbuseModal,
    navigateUser,
  } = props;

  useEffect(() => {
    if (isLoggedIn) {
      props.emptyMyFollowers();
      props.fetchMyFollowers(session, "");
    } else {
      history.push("/login");
    }
  }, []);

  const gotoPage = (page) => {
    history.push("/" + page);
  };

  const renderList = () => {
    var item = _.map(myFollower.data, (valObj, index) => {
      var usersByFollowerid = valObj.users_by_followerid;

      var productImageUrl = `${FILES_URL}/users/${usersByFollowerid.userid}.jpg?api_key=${APP_API_KEY}`;

      return (
        <li
          key={index}
          className="py-4 bg-white px-4 cursor-pointer"
          onClick={(e) => openFollowersDelaisModal(valObj)}
        >
          <div className="flex items-center space-x-4">
            <div className="flex-shrink-0">
              <img
                className="h-10 w-10 rounded-full"
                src={productImageUrl}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = NoImg;
                }}
                alt="Image"
              />
            </div>
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-900 truncate">
                {usersByFollowerid.username}
              </p>
              <p className="text-sm text-gray-500 truncate">
                {moment(usersByFollowerid.updatedon).format(SMALL_DATE_FORMAT)}
              </p>
            </div>
            <div></div>
          </div>
        </li>
      );
    });

    return item;
  };

  const openFollowersDelaisModal = (valObj) => {
    let userArr = navigateUser;
    props.profileModalOpen(true);
    props.emptySharedWishlist();
    props.selectedFollowerUserDetails(
      session,
      valObj.followerid,
      (callback) => {}
    );
    props.selectedFollowersDetails(
      session,
      valObj.followerid,
      (callback) => {}
    );
    props.selectedFollowersShearedWishlist(
      session,
      valObj.followerid,
      (callback) => {}
    );

    props.viewPublicProfile(session, valObj.followerid);

    if (!_.includes(userArr, valObj.followerid)) {
      userArr.push(valObj.followerid);
      props.profileViewNavigation(userArr);
    }
  };

  //console.log("myFollower", myFollower);
  return (
    <>
      {/* <div className="mb-2.5 ml-5 xl:ml-298 lg:ml-298 md:ml-5 sm:ml-5  mr-5 xl:mr-298 lg:mr-298 md:mr-5 sm:mr-5 ">
        <div>{LocaleStrings.my_followers}</div>
      </div> */}
      <div className="bg-newarrival py-25">
        <div className="custom_container">
          <div className="text-left text-primary text-xs pb-5">
            <a
              onClick={() => history.push("/activity")}
              className="cursor-pointer"
            >
              {LocaleStrings.activity}
            </a>
            &nbsp;/&nbsp; {LocaleStrings.my_followers}
          </div>
        </div>
        <div className="custom_container">
          {myFollower && myFollower.data ? (
            myFollower.data.length > 0 ? (
              <div className="flow-root">
                <ul role="list" className="divide-y divide-primary">
                  {renderList()}
                </ul>
              </div>
            ) : (
              <div className="flex h-cardimg items-center justify-center bg-white">
                <div>
                  <div className="flex items-center justify-center pb-5">
                    <img src={YouNodata} />
                  </div>
                  <div className="flex items-center justify-center text-cutoff text-base font-medium">
                    {LocaleStrings.my_followers_no_data_title}
                  </div>
                  <div className="flex items-center justify-center text-cutoff text-sm">
                    {LocaleStrings.my_followers_no_data_desc}
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="flex h-cardimg items-center justify-center">
              <Loader />
            </div>
          )}
        </div>
      </div>
      {profileModal && profileModal.showModal ? <ProfileView /> : ""}
      {reportAbuseModal && reportAbuseModal.showModal ? <ReportAbuse /> : ""}
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  myFollower: state.myFollower,
  profileModal: state.profileModal,
  reportAbuseModal: state.reportAbuseModal,
  navigateUser: state.navigateUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMyFollowers,
      emptyMyFollowers,
      profileModalOpen,
      selectedFollowerUserDetails,
      selectedFollowersDetails,
      selectedFollowersShearedWishlist,
      emptySharedWishlist,
      profileViewNavigation,
      viewPublicProfile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyFollower);
