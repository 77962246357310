import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import { confirmAlert } from "react-confirm-alert";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import {
  fetchWishList,
  emptyWishList,
  clearWishlist,
  deletWishlist,
  removeWishlist,
  shareWishlist,
  makePrivateWishlist
} from "../../Product/actions";
import ItemList from "../../Home/components/item-list";
import toast, { Toaster } from "react-hot-toast";
import PublicIcon from "../../../assets/images/public.svg";
import PrivateIcon from "../../../assets/images/private.svg";

function WishlistDetails(props) {
  const history = useHistory();
  const listid = props.match.params.id;
  const { session, isLoggedIn, wishListed, deviceSize } = props;
  const [listDetails, setListDetails] = useState([]);
  const [delItem, setDelItem] = useState(false);
  const [delloading, setDelloading] = useState(false);
  const [checkedItem, setCheckedItem] = useState([]);

  useEffect(() => {
    if (listid) {
      console.log("listid", listid);
      props.emptyWishList();
      props.fetchWishList(session, (res) => {
        if (res.success == 1 && res.data && res.data.length > 0) {
          let detailsArr = _.filter(res.data, function (o) {
            return o.id == listid;
          });
          setListDetails(detailsArr);
        }
      });
    }
  }, [listid]);

  const renderList = () => {
    return _.map(listDetails[0].items, (item, index) => {
      return (
        <ItemList
          item={item.productdetails}
          itemid={item.id}
          type="similar"
          prevpage="wishlist"
          pagetitle={LocaleStrings.wishlist_txt}
          delItem={delItem}
          checkedItem={checkedItem}
          setCheckedItem={setCheckedItem}
        />
      );
    });
  };

  const clearItem = () => {
    setDelItem(false);
    confirmAlert({
      title: LocaleStrings.wishlist_clear_title,
      message: LocaleStrings.wishlist_clear_msg,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            props.clearWishlist(session, listid, (res) => {
              if (res.success == 1) {
                props.emptyWishList();
                props.fetchWishList(session, (res) => {
                  if (res.success == 1 && res.data && res.data.length > 0) {
                    let detailsArr = _.filter(res.data, function (o) {
                      return o.id == listid;
                    });
                    setListDetails(detailsArr);
                  }
                });
                toast.success(LocaleStrings.wishlist_clear_success);
              } else {
                toast.error(LocaleStrings.common_fail_message);
              }
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };
  const deleteWishlist = () => {
    setDelItem(false);
    confirmAlert({
      title: LocaleStrings.wishlist_delete_title,
      message: LocaleStrings.wishlist_delete_msg,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            props.deletWishlist(session, listid, (res) => {
              if (res.success == 1) {
                props.emptyWishList();
                props.fetchWishList(session, (res) => {
                  if (res.success == 1) {
                    history.push("/wishlist");
                    toast.success(LocaleStrings.wishlist_delete_success);
                  }
                });
              } else {
                toast.error(LocaleStrings.common_fail_message);
              }
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  const deleteItem = () => {
    setDelItem(true);
  };

  const deleteSelected = () => {
    if (checkedItem.length > 0) {
      let ids = checkedItem.join(",");
      setDelloading(true);
      props.removeWishlist(session, ids, (res) => {
        setDelItem(false);
        setCheckedItem([]);
        if (res.success == 1) {
          props.emptyWishList();
          props.fetchWishList(session, (res) => {
            if (res.success == 1 && res.data && res.data.length > 0) {
              let detailsArr = _.filter(res.data, function (o) {
                return o.id == listid;
              });
              setListDetails(detailsArr);
              setDelloading(false);
              toast.success(LocaleStrings.wishlist_delete_item_success);
            }
          });
        } else {
          setDelloading(false);
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    }
  };

  const shareWishList = () => {
    props.shareWishlist(session, listid, (res) => {
      if (res.success == 1) {
        props.fetchWishList(session, (res) => {
          if (res.success == 1 && res.data && res.data.length > 0) {
            let detailsArr = _.filter(res.data, function (o) {
              return o.id == listid;
            });
            setListDetails(detailsArr);
          }
        });
        toast.success(LocaleStrings.wishlist_share_success);
      } else {
        toast.error(LocaleStrings.common_fail_message);
      }
    });
  };

  const makePrivate = () =>{
    props.makePrivateWishlist(session, listid, (res) => {
      if (res.success == 1) {
        props.fetchWishList(session, (res) => {
          if (res.success == 1 && res.data && res.data.length > 0) {
            let detailsArr = _.filter(res.data, function (o) {
              return o.id == listid;
            });
            setListDetails(detailsArr);
          }
        });
        toast.success(LocaleStrings.wishlist_private_success);
      } else {
        toast.error(LocaleStrings.common_fail_message);
      }
    });
  }

  //console.log("checkedItem", checkedItem);
  return (
    <>
      <div className="mt-5 mb-60 custom_container">
        {wishListed && wishListed.data ? (
          wishListed.data.length > 0 && listDetails.length > 0 ? (
            <div className="mt-2">
              <div className="p-3 flex items-center">
                {props.source && props.source == "activity" ? (
                  <div className="w-wishdetailbox">
                    <ArrowLeftIcon
                      className="h-6 w-6 cursor-pointer"
                      onClick={() => history.goBack()}
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="w-4/5">
                  <div className="text-primary text-base font-normal pb-xs">
                    {listDetails[0].name}
                  </div>
                  <div
                    className={classNames(
                      listDetails[0].items && listDetails[0].items.length > 0
                        ? "divide-x divide-primary "
                        : " ",
                      "flex"
                    )}
                  >
                    <div className="flex items-center text-gray-300 text-sm pr-md">
                      <div className="pr-2">
                        <img
                          src={
                            listDetails[0].privacy == "public"
                              ? PublicIcon
                              : PrivateIcon
                          }
                        />
                      </div>
                      <div>{_.startCase(listDetails[0].privacy)}</div>
                    </div>

                    {listDetails[0].items && listDetails[0].items.length > 0 ? (
                      <div className="pl-md text-gray-300 text-sm">
                        {listDetails[0].items.length}&nbsp;Items
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="w-wishdetailbox text-right">
                  <Menu as="div" className="relative inline-block text-left">
                    {({ open }) => (
                      <>
                        <div className="">
                          <Menu.Button className="btn-card">
                            <span className="sr-only">Open options</span>
                            <DotsVerticalIcon
                              className="h-5 w-5 hover:shadow"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            <div className="py-1 divide-y">
                              {listDetails[0].items &&
                              listDetails[0].items.length > 0 ? (
                                <>
                                  {listDetails[0].isshare == 0 &&
                                  listDetails[0].privacy == "public" ? (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          onClick={() => shareWishList()}
                                          className={classNames(
                                            active ? "bg-quaternary " : " ",
                                            "block px-4 py-2 text-sm text-primary cursor-pointer"
                                          )}
                                        >
                                          {LocaleStrings.wishlist_share_option}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  ) : (
                                    ""
                                  )}
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        onClick={() => deleteItem()}
                                        className={classNames(
                                          active ? "bg-quaternary " : " ",
                                          "block px-4 py-2 text-sm text-primary cursor-pointer"
                                        )}
                                      >
                                        {
                                          LocaleStrings.wishlist_item_delete_option
                                        }
                                      </a>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        onClick={() => clearItem()}
                                        className={classNames(
                                          active ? "bg-quaternary " : " ",
                                          "block px-4 py-2 text-sm text-primary cursor-pointer"
                                        )}
                                      >
                                        {LocaleStrings.wishlist_clear_option}
                                      </a>
                                    )}
                                  </Menu.Item>
                                  {listDetails[0].isshare == 1 &&
                                  listDetails[0].privacy == "public" ? (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          onClick={() => makePrivate()}
                                          className={classNames(
                                            active ? "bg-quaternary " : " ",
                                            "block px-4 py-2 text-sm text-primary cursor-pointer"
                                          )}
                                        >
                                          {LocaleStrings.make_private}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  ) : (
                                    ""
                                  )}
                                  
                                </>
                              ) : (
                                ""
                              )}
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    onClick={() => deleteWishlist()}
                                    className={classNames(
                                      active ? "bg-quaternary " : " ",
                                      "block px-4 py-2 text-sm text-primary cursor-pointer"
                                    )}
                                  >
                                    {LocaleStrings.wishlist_delete_option}
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            </div>
          ) : (
            ""
          )
        ) : (
          <div className="h-50">
            <Loader />
          </div>
        )}
        <div className="my-3">
          {listDetails.length > 0 ? (
            listDetails[0].items && listDetails[0].items.length > 0 ? (
              <>
                <div className="grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 gap-5">
                  {renderList()}
                </div>
                {checkedItem.length > 0 ? (
                  <div className="mt-6">
                    <button
                      type="submit"
                      className="text-white h-44 bg-delitem text-sm font-normal rounded w-full inline-flex items-center justify-center"
                      onClick={() => deleteSelected()}
                    >
                      {delloading ? (
                        <svg
                          className="animate-spin h-5 w-5 rounded-full mr-3"
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      ) : (
                        ""
                      )}
                      <span className="text-sm">
                        {LocaleStrings.button_delete} &nbsp;
                        {checkedItem.length}&nbsp;
                        {checkedItem.length == 1
                          ? LocaleStrings.item
                          : LocaleStrings.items}
                      </span>
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              <div className="flex items-center justify-center">
                {LocaleStrings.wishlist_no_product}
              </div>
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  wishListed: state.wishListed,
  deviceSize: state.deviceSize,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchWishList,
      emptyWishList,
      clearWishlist,
      deletWishlist,
      removeWishlist,
      shareWishlist,
      makePrivateWishlist
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WishlistDetails);
