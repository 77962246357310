import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

class LegalDocsList extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }

  componentDidMount() {}

  /**** On Profile Menu Select ****/
  onLegalDocTabSelect(href) {
    this.props.history.push("/legaldocs/" + href);
  }

  /**** Render Profile settings Div List *****/
  renderList() {
    let { LegalDocsList, session } = this.props;

    var item = _.map(this.props.LegalDocsList, (valObj, index) => {
      let position = valObj.position;
      return (
        <a
          onClick={(e) => this.onLegalDocTabSelect(valObj.href)}
          key={"a-" + index}
          className=""
        >
          <li
            key={index}
            className="py-4 bg-white px-4 cursor-pointer transition ease-in-out duration-500 hover:bg-newarrival"
          >
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <img
                  className="h-8 w-8"
                  src={"../legal_docs/" + valObj.icon + ".svg"}
                  alt=""
                />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-gray-900 truncate">
                  {valObj.name}
                </p>
                <p className="text-sm text-gray-500 truncate">
                  {valObj.subname}
                </p>
              </div>
              <div>
                <img
                  className="h-8 transform rotate-180 px-2 "
                  src={"../profile_settings_images/arrow.svg"}
                />
              </div>
            </div>
          </li>
        </a>
      );
    });

    return item;
  }

  render() {
    return (
      <>
        <div className="px-10">
          <div className="flow-root mt-6">
            <ul role="list" className="-my-5 divide-y divide-gray-200">
              {this.renderList()}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    LegalDocsList: state.consumerLegalDocsList,
  };
}

export default connect(mapStateToProps, {})(LegalDocsList);
