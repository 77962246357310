import axios from "axios";
import _ from "lodash";

import {
  INSTANCE_URL,
  APP_API_KEY,
  FILES_URL,
  LOGIN,
} from "../../../components/Common/constant";

import {
  getRequestAccess,
  postRequestAccess,
  deleteRequest,
  getRequest,
  postRequest,
  patchRequest,
  uploadEncodedFile,
  uploadEncodedFileLarge,
} from "../../../components/Common/network-call";
import { getHeaders } from "../../../components/Common/common-utils";
import { SESSION_TOKEN, USER_NAME } from "../../Login/actions";
import {
  PHONE_VERIFIED,
  EMAIL_VERIFIED,
  SET_CURRENCY,
  LOCAL_LANGUAGE,
} from "../../../actions";

export const MYORDER_LIST = "MYORDER_LIST";
export const ORDER_DETAILS = "ORDER_DETAILS";
export const RATE_PRODUCT_MODAL = "RATE_PRODUCT_MODAL";
export const RETURN_REPLACE_MODAL = "RETURN_REPLACE_MODAL";
export const ORDER_CANCEL_MODAL = "ORDER_CANCEL_MODAL";
export const ORDER_REPLACE = "ORDER_REPLACE";
export const MASTER_ORDER_DETAILS = "MASTER_ORDER_DETAILS";

export function emptyOrderDetails(callback) {
  return (dispatch) => {
    dispatch({
      type: ORDER_DETAILS,
      payload: {},
    });
  };
}

export function fetchMyorders(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/myorders`;
  let values = {};

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.result == "success") {
          dispatch({
            type: MYORDER_LIST,
            payload: { data: response.orders },
          });
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function fetchOrderDetails(session, orderdetailid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/myorderdetail`;

  var params = { orderdetailid: parseInt(orderdetailid) };
  return (dispatch) => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.result == "success") {
          dispatch({
            type: ORDER_DETAILS,
            payload: response.order,
          });
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function isRateProductModalOpen(obj) {
  return { type: RATE_PRODUCT_MODAL, payload: obj };
}

export function isReturnReplaceOpen(isOpen) {
  return { type: RETURN_REPLACE_MODAL, payload: isOpen };
}

export function isCancelModalOpen(isOpen) {
  return { type: ORDER_CANCEL_MODAL, payload: isOpen };
}

export function updateOrder(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/updateorder`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.result == "success") {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function uploadMedia(session, uploadFiles, callback) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.medianame;
  const dataMedia = new FormData();
  var blob = new Blob([filecontent], { type: "image/jpeg" });
  dataMedia.append("files", blob, filename);

  return (dispatch) => {
    uploadEncodedFileLarge(
      `${FILES_URL}/reviews/`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        callback({ success: 1, message: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function addReview(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/product_reviews`;
  var body = { resource: [values] };
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (response.resource && response.resource.length > 0) {
          callback({ success: 1, reviewid: response.resource[0].id });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function updateReview(session, values, id, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/product_reviews/${id}`;
  return (dispatch) => {
    patchRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function addReviewMedia(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/product_reviews_media`;
  var body = { resource: [values] };
  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (response.resource && response.resource.length > 0) {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({success: 0, error: error});
      }
    );
  };
}

export function uploadStatusMedia(session, uploadFiles, callback) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.medianame;
  const dataMedia = new FormData();
  var blob = new Blob([filecontent], { type: "image/jpeg" });
  dataMedia.append("files", blob, filename);

  return (dispatch) => {
    uploadEncodedFileLarge(
      `${FILES_URL}/orders/`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function orderPlacedMail(session, orderid, callback) {
  var url = `${INSTANCE_URL}/api/v2/sendemail`;
  let values = { emailtype: "orderplaced", orderid: orderid };
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        callback({ success: 1 });
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function fetchReplaceVariant(session, orderdetailid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/replacevariant`;

  var params = { orderdetailid: parseInt(orderdetailid) };
  return (dispatch) => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.result == "success") {
          dispatch({
            type: ORDER_REPLACE,
            payload: response.data,
          });
          callback({ success: 1, data: response.data });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function makeReplaceOrder(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/replacepayment`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (
          response.success &&
          response.checkout_url
        ) {
          callback({ success: 1, checkout_url: response.checkout_url });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function makeReplaceRefund(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/replacerefund`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.result && response.result == "success") {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function makeReplaceNoPay(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/replaceorder`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.result && response.result == "success") {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function fetchOrderDetailsbyMasterId(
  session,
  masterid,
  isAppCall = false,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/fetchordersbymasterid`;

  var params = { order_master_id: masterid, isAppCall: isAppCall };
  return (dispatch) => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.result == "success") {
          dispatch({
            type: MASTER_ORDER_DETAILS,
            payload: response.orders,
          });
          if (isAppCall && response.userdata) {
            let userdata = response.userdata;
            userdata.session_token = session.sessionToken;
            dispatch({ type: SESSION_TOKEN, payload: userdata });
            dispatch({ type: PHONE_VERIFIED, payload: userdata.phoneverified });
            dispatch({ type: EMAIL_VERIFIED, payload: userdata.emailverified });
            dispatch({ type: USER_NAME, payload: userdata.username });
            dispatch({ type: SET_CURRENCY, payload: userdata.currencychoice });
            dispatch({
              type: LOCAL_LANGUAGE,
              payload: userdata.languagechoice,
            });

            dispatch({ type: LOGIN, payload: true });
          }
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function makePaymentonly(session, values, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/makepayment`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
          if (
          response.success &&
          response.checkout_url
        ) {
          callback({ success: 1, checkout_url: response.checkout_url });
        } else if(response.success){
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}
