import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Home from "./pages/Home/components/";
import ProductDetails from "./pages/Product/components/product-details";
import ProductSearch from "./pages/Product/components/product-search";
import Negotiation from "./pages/Negotiation/components/index";
import Signin from "./pages/Login/components/signin";
import Signup from "./pages/Login/components/signup";
import Watchlist from "./pages/Watchlist/components/index";
import Wishlist from "./pages/Wishlist/components/index";
import WishlistDetails from "./pages/Wishlist/components/list-details";
import SharedDetails from "./pages/Wishlist/components/shared-details";
import PublicWishlist from "./pages/Wishlist/components/public-details";
import Activity from "./pages/Activity/components/index";
import RecentViewed from "./pages/Activity/components/recent-viewed";
import Mybids from "./pages/Mybids/components/index";
import MyFollower from "./pages/Activity/components/my-follower";
import SellerProfile from "./pages/Seller/components/index";
import Cart from "./pages/Cart/components/index";
import MyCoupons from "./pages/Mycoupons/components/index";
import MyOrders from "./pages/Myorders/components/index";
import OrderDetails from "./pages/Myorders/components/order-details";
import CategoryView from "./pages/Category/components/index";
import ProductView from "./pages/Productview/components/index";
import Profile from "./pages/Profile/components/index";
import Faq from "./pages/Faq/components/index";
import Legaldocs from "./pages/Legaldoc/components/index";
import LegalDocsTermsNCons from "./pages/Legaldoc/components/termsncons/index";
import LegalDocsPrivacyPolicy from "./pages/Legaldoc/components/privacypolicy/index";
import LegalDocsSalesPolicy from "./pages/Legaldoc/components/salespolicy/index";
import LegalDocsAboutUS from "./pages/Legaldoc/components/aboutus/index";
import Suggestions from "./pages/Suggestions/components/index";
import Invitation from "./pages/Invitation/components/index";
import PayFail from "./pages/Myorders/components/payfail";
import PayCancel from "./pages/Myorders/components/paycancel";
import SellerProductList from "./pages/Seller/components/product-list";

import ResetPassword from "./pages/Login/components/resetpwd";
import VerifyEmail from "./pages/Login/components/verifyemail";

import ReturnPolicy from "./pages/Legaldoc/components/returnpolicy/index";
import ShipmentPolicy from "./pages/Legaldoc/components/shipmentpolicy/index";
import Intellectual from "./pages/Legaldoc/components/intellectualproperty/index";

var routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    display: true,
    key: "home",
  },
  {
    path: "/login",
    name: "Login",
    component: Signin,
    display: true,
    key: "login",
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    display: true,
    key: "signup",
  },
  {
    path: "/product-details/:id/:type?",
    name: "ProductDetails",
    component: ProductDetails,
    display: true,
    key: "product-details",
  },
  {
    path: "/product-search/:searchterm/:catid?",
    name: "ProductSearch",
    component: ProductSearch,
    display: true,
    key: "product-search",
  },
  {
    path: "/negotiation/:threadid?",
    name: "Negotiation",
    component: Negotiation,
    display: true,
    key: "negotiation",
  },
  {
    path: "/watchlist",
    name: "Watchlist",
    component: Watchlist,
    display: true,
    key: "watchlist",
  },
  {
    path: "/wishlist",
    name: "Wishlist",
    component: Wishlist,
    display: true,
    key: "wishlist",
  },
  {
    path: "/wishlist-details/:id",
    name: "WishlistDetails",
    component: WishlistDetails,
    display: true,
    key: "wishlist-details",
  },
  {
    path: "/shared-wishlist/:profileid/:listid",
    name: "SharedDetails",
    component: SharedDetails,
    display: true,
    key: "shared-wishlist",
  },
  {
    path: "/public-wishlist/:listid",
    name: "PublicWishlist",
    component: PublicWishlist,
    display: true,
    key: "public-wishlist",
  },
  {
    path: "/activity/:userid?",
    name: "Activity",
    component: Activity,
    display: true,
    key: "activity",
  },
  {
    path: "/recent-viewed",
    name: "RecentViewed",
    component: RecentViewed,
    display: true,
    key: "recent-viewed",
  },
  {
    path: "/mybids",
    name: "Mybids",
    component: Mybids,
    display: true,
    key: "mybids",
  },
  {
    path: "/my-follower",
    name: "MyFollower",
    component: MyFollower,
    display: true,
    key: "my-follower",
  },
  {
    path: "/seller-profile/:sellerid",
    name: "SellerProfile",
    component: SellerProfile,
    display: true,
    key: "seller-profile",
  },
  {
    path: "/seller-product/:sellerid",
    name: "SellerProductList",
    component: SellerProductList,
    display: true,
    key: "seller-product",
  },

  {
    path: "/cart",
    name: "Cart",
    component: Cart,
    display: true,
    key: "cart",
  },
  {
    path: "/mycoupons",
    name: "MyCoupons",
    component: MyCoupons,
    display: true,
    key: "mycoupons",
  },
  {
    path: "/myorders/:code?",
    name: "MyOrders",
    component: MyOrders,
    display: true,
    key: "myorders",
  },
  {
    path: "/order-details/:id",
    name: "OrderDetails",
    component: OrderDetails,
    display: true,
    key: "OrderDetails",
  },
  {
    path: "/view-category/:id?",
    name: "CategoryView",
    component: CategoryView,
    display: true,
    key: "CategoryView",
  },
  {
    path: "/product-view/:catid",
    name: "ProductView",
    component: ProductView,
    display: true,
    key: "ProductView",
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    display: true,
    key: "Profile",
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
    display: true,
    key: "Faq",
  },
  {
    path: "/legaldocs",
    name: "Legaldocs",
    component: Legaldocs,
    display: true,
    key: "Legaldocs",
  },
  {
    path: "/legaldocs/termsncons",
    name: "LegalDocsTermsNCons",
    component: LegalDocsTermsNCons,
    display: true,
    key: "LegalDocsTermsNCons",
  },
  {
    path: "/legaldocs/privacypolicy",
    name: "LegalDocsPrivacyPolicy",
    component: LegalDocsPrivacyPolicy,
    display: true,
    key: "LegalDocsPrivacyPolicy",
  },
  {
    path: "/legaldocs/salespolicy",
    name: "LegalDocsSalesPolicy",
    component: LegalDocsSalesPolicy,
    display: true,
    key: "LegalDocsSalesPolicy",
  },
  {
    path: "/legaldocs/aboutus",
    name: "LegalDocsAboutUS",
    component: LegalDocsAboutUS,
    display: true,
    key: "LegalDocsAboutUS",
  },
  {
    path: "/suggestions",
    name: "Suggestions",
    component: Suggestions,
    display: true,
    key: "Suggestions",
  },
  {
    path: "/payfail",
    name: "PayFail",
    component: PayFail,
    display: true,
    key: "payfail",
  },
  {
    path: "/paycancel",
    name: "PayCancel",
    component: PayCancel,
    display: true,
    key: "paycancel",
  },
  {
    path: "/reset-password",
    name: "",
    component: ResetPassword,
    display: true,
    key: "login",
  },
  {
    path: "/invitation",
    name: "",
    component: Invitation,
    display: true,
    key: "invitation",
  },
  {
    path: "/verifyemail/:email",
    name: "",
    component: VerifyEmail,
    display: true,
    key: "login",
  },
  {
    path: "/legaldocs/returnpolicy",
    name: "ReturnPolicy",
    component: ReturnPolicy,
    display: true,
    key: "ReturnPolicy",
  },
  {
    path: "/legaldocs/shipmentpolicy",
    name: "ShipmentPolicy",
    component: ShipmentPolicy,
    display: true,
    key: "ShipmentPolicy",
  },
  {
    path: "/legaldocs/intellectual",
    name: "Intellectual",
    component: Intellectual,
    display: true,
    key: "Intellectual",
  },
];

export default routes;
