import {
  postRequest,
  uploadEncodedFileLarge,
} from "../../../components/Common/network-call";

import { INSTANCE_URL, FILES_URL } from "../../../components/Common/constant";

import LocaleStrings from "../../../languages/index";

//export var SELECTED_PROFILESETTINGS_MENU = 'SELECTED_PROFILESETTINGS_MENU';

/**** Upload Suggestion Media ****/
export function uploadMediaLargeAppSuggestion(session, uploadFiles, callback) {
  var filecontent = uploadFiles.media;
  var filename = uploadFiles.name;
  var filetype = uploadFiles.type;

  const dataMedia = new FormData();
  if (filetype === "video") {
    var blob = new Blob([filecontent], { type: "video/mp4" });
  } else if (filetype === "audio") {
    var blob = new Blob([filecontent], { type: "audio/mp3" });
  } else if (filetype === "image") {
    var blob = new Blob([filecontent], { type: "image/jpeg" });
  } else {
    var blob = new Blob([filecontent], { type: filetype });
  }

  dataMedia.append("files", blob, filename);

  return (dispatch) => {
    uploadEncodedFileLarge(
      `${FILES_URL}/suggestions/`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        callback({ success: 1, message: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

/**** Send suggestions ****/
export function sendsuggestions(session, object, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/sendsuggestions`;
  // Actual Data Call
  return (dispatch) => {
    postRequest(
      url,
      object,
      session,
      dispatch,
      (response) => {
        callback({ status: 1 });
      },
      (error) => {
        callback({ status: 0 });
      }
    );
  };
}
