import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import { classNames } from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/outline";
import Collapsible from "react-collapsible";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import OnHoverScrollContainer from "../../../components/CustomScrollDiv";
import {
  fetchProductBySubCategory,
  emptyProductList,
  isMobileFilterOpen,
} from "../actions";
import "../../../assets/css/filter.css";

const sliderStyle = {
  // Give the slider some width
  position: "relative",
  width: "90%",
  height: 40,
};

const railStyle = {
  position: "absolute",
  width: "100%",
  height: 4,
  marginTop: 25,
  borderRadius: 5,
  backgroundColor: "rgb(210 211 213)",
};

export function Handle({ handle: { id, value, percent }, getHandleProps }) {
  return (
    <div
      style={{
        left: `${percent}%`,
        position: "absolute",
        marginLeft: 0,
        marginTop: 15,
        zIndex: 2,
        width: 20,
        height: 20,
        border: 0,
        textAlign: "center",
        cursor: "pointer",
        borderRadius: "50%",
        backgroundColor: "#1688EF",
        color: "#333",
      }}
      {...getHandleProps(id)}
    >
      {/* <div
        style={{
          fontSize: 13,
          fontFamily: "DM Sans",
          color: "#929EB5",
          marginTop: -25,
          marginLeft: percent >= 80 ? "-28px" : 0,
        }}
      >
       
      </div> */}
    </div>
  );
}

function Track({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: "absolute",
        height: 4,
        zIndex: 1,
        marginTop: 25,
        backgroundColor: "#1688EF",
        borderRadius: 5,
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {
        ...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */
      }
    />
  );
}

export function Tick({ tick, count, format }) {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          marginTop: 14,
          width: 1,
          height: 5,
          backgroundColor: "rgb(200,200,200)",
          left: `${tick.percent}%`,
        }}
      />
      <div
        style={{
          position: "absolute",
          marginTop: 22,
          fontSize: 10,
          textAlign: "center",
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  );
}

Tick.defaultProps = {
  format: (d) => d,
};

function FilterOption(props) {
  const history = useHistory();
  const {
    filterOptions,
    localLanguage,
    openOption,
    setOpenoption,
    categoryid,
    setCategoryid,
    filterParams,
    setFilterParams,
    isSending,
    setIsSending,
    priceRange,
    setPriceRange,
    mobileFilter,
    setPagenumber,
    domain,
    setClearAll,
    urlcatid,
    productBySubcategory,
    setIsCatChange,
    specificationParams,
    setSpecificationParams,
  } = props;
  const [labelRange, setLabelRange] = useState([1, 50000]);

  useEffect(() => {
    setLabelRange(priceRange);
  }, []);

  useEffect(() => {
    var toggler = document.getElementsByClassName("caret");
    var i;
    var selection = document.querySelector(".nested");
    if (selection !== null) {
      document.querySelector(".nested").classList.remove("active");
      for (i = 0; i < toggler.length; i++) {
        toggler[i].addEventListener("click", function () {
          this.parentElement
            .querySelector(".nested")
            .classList.toggle("active");
          this.classList.toggle("caret-down");
        });
      }
    }
  }, []);

  const redirectHyperlink = (catid, type) => {
    for (const [key, value] of Object.entries(filterParams)) {
      setFilterParams((prevState) => ({ ...prevState, [key]: [] }));
    }
    setPriceRange([1, 50000]);
    setLabelRange([1, 50000]);
    if (mobileFilter) {
      props.isMobileFilterOpen(false);
    }
    setCategoryid([]);
    setPagenumber(1);
    setIsCatChange(true);
    setIsSending(true);
    history.push("/product-view/" + catid);
  };

  const changeCategory = (e, catid) => {
    //console.log("changeCategory");
    if (e.target.checked) {
      let valuesArr = categoryid;
      valuesArr.push(catid);
      setCategoryid(valuesArr);
      if (mobileFilter) {
        props.isMobileFilterOpen(false);
      }
      setIsSending(true);
      setPagenumber(1);
    } else {
      let valuesArr = categoryid;

      let newArr = _.filter(valuesArr, function (item) {
        return item != catid;
      });

      setCategoryid(newArr);
      if (mobileFilter) {
        props.isMobileFilterOpen(false);
      }
      setIsSending(true);
      setPagenumber(1);
    }
  };

  const onPriceChange = (values) => {
    //console.log("onPriceChange");
    setPriceRange(values);
    if (mobileFilter) {
      props.isMobileFilterOpen(false);
    }
    setIsSending(true);
    setPagenumber(1);
  };

  const onPriceUpdate = (update) => {
    setLabelRange(update);
  };
  const clearAll = () => {
    //console.log("clearAll");
    for (const [key, value] of Object.entries(filterParams)) {
      setFilterParams((prevState) => ({ ...prevState, [key]: [] }));
    }
    setPriceRange([1, 50000]);
    setLabelRange([1, 50000]);
    if (mobileFilter) {
      props.isMobileFilterOpen(false);
    }
    setCategoryid([]);
    setPagenumber(1);
    setIsSending(true);
    setClearAll(true);

    // if (props.productBySubcategory?.breadcrumb?.id) {
    //   history.push(
    //     "/view-category/" + props.productBySubcategory?.breadcrumb?.id
    //   );
    // } else {
    //   history.push("/view-category/");
    // }
  };

  const changeSubset = (e, value, section, type) => {
    console.log("type", type);

    // specificationParams,
    // setSpecificationParams,
    if (e.target.checked) {
      if (type == "variant") {
        let valuesArr = filterParams[section];
        valuesArr.push(value);
        setFilterParams((prevState) => ({
          ...prevState,
          [section]: valuesArr,
        }));
      } else {
        if (specificationParams.hasOwnProperty([section])) {
          let valuesArr = specificationParams[section];
          valuesArr.push(value);
          setSpecificationParams((prevState) => ({
            ...prevState,
            [section]: valuesArr,
          }));
        } else {
          let valuesArr = [];
          valuesArr.push(value);
          setSpecificationParams((prevState) => ({
            ...prevState,
            [section]: valuesArr,
          }));
        }
      }

      if (mobileFilter) {
        props.isMobileFilterOpen(false);
      }
      setIsSending(true);
      setPagenumber(1);
    } else {
      if (type == "variant") {
        let valuesArr = filterParams[section];

        let newArr = _.filter(valuesArr, function (item) {
          return item != value;
        });

        setFilterParams((prevState) => ({ ...prevState, [section]: newArr }));
      } else {
        let valuesArr = specificationParams[section];

        let newArr = _.filter(valuesArr, function (item) {
          return item != value;
        });

        setSpecificationParams((prevState) => ({
          ...prevState,
          [section]: newArr,
        }));
      }
      if (mobileFilter) {
        props.isMobileFilterOpen(false);
      }
      setIsSending(true);
      setPagenumber(1);
    }
  };

  const handleTrigegr = (section) => {
    if (_.includes(openOption, section)) {
      let newArr = _.filter(openOption, function (item) {
        return item != section;
      });
      setOpenoption(newArr);
    } else {
      let newArr = openOption;

      newArr.push(section);
      setOpenoption(newArr);
    }
  };

  const renderSub = (category) => {
    return _.map(category, (data, index) => {
      return (
        <div
          className="flex items-center"
          onChange={(e) => changeCategory(e, data.id)}
          key={"divchk" + data.id}
        >
          {/* <a
            className={classNames(
              _.includes(categoryid, data.id)
                ? "text-filtersel "
                : "text-uname  ",
              "text-base md:text-sm font-normal  cursor-pointer"
            )}
            onClick={() => changeCategory(data.id)}
          >
            {ProductTextLocalized(
              data.subcategoryname_en,
              data.subcategoryname_ar,
              localLanguage
            )}
          </a> */}

          <div className="pr-4">
            <input
              type="checkbox"
              key={"check" + data.id}
              checked={_.includes(categoryid, data.id) ? true : false}
            />
          </div>
          <div key={"divtxt" + data.id}>
            {ProductTextLocalized(
              data.subcategoryname_en,
              data.subcategoryname_ar,
              localLanguage
            )}
          </div>
        </div>
      );
    });
  };

  const renderSubset = (subset, section, type) => {
    let newArr = [];
    if (type == "variant") {
      newArr = filterParams[section];
    } else {
      newArr = specificationParams[section];
    }

    //console.log("newArr", newArr);
    return _.map(subset, (data, index) => {
      return (
        <div
          className="flex items-center text-uname text-base md:text-sm font-normal"
          key={"main" + data.name_en}
          onChange={(e) => changeSubset(e, data.name_en, section, type)}
        >
          <div className="pr-4" key={"divchk" + data.name_en}>
            <input
              type="checkbox"
              key={"check" + data.name_en}
              checked={_.includes(newArr, data.name_en) ? true : false}
            />
          </div>
          <div key={"divtxt" + data.name_en}>
            {ProductTextLocalized(data.name_en, data.name_ar, localLanguage)}
          </div>
        </div>
      );
    });
  };

  const renderCatList = () => {
    let childrenHtml = [];

    if (filterOptions.category && filterOptions.category.length > 0) {
      let objHtml = [];
      objHtml.push(
        <Collapsible
          key={"categories"}
          trigger={
            <div className="grid grid-cols-2 cursor-pointer mt-8">
              <div className=" text-primary text-base md:text-sm font-medium">
                {LocaleStrings.subcategories}
              </div>
              <div className="flex justify-end">
                {_.includes(openOption, "categories") ? (
                  <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                ) : (
                  <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                )}
              </div>
            </div>
          }
          open={true}
          onOpening={() => handleTrigegr("categories")}
          onClosing={() => handleTrigegr("categories")}
        >
          <div className="pt-6">
            <OnHoverScrollContainer>
              {renderSub(filterOptions.category)}
            </OnHoverScrollContainer>
          </div>
        </Collapsible>
      );

      childrenHtml.push(objHtml);
    }

    return childrenHtml;
  };

  const renderFilterList = () => {
    let childrenHtml = [];

    if (filterOptions.options && filterOptions.options.length > 0) {
      _.map(filterOptions.options, (option, index) => {
        let isOpen = _.includes(
          openOption,
          _.startCase(
            ProductTextLocalized(option.name_en, option.name_ar, localLanguage)
          )
        );
        // console.log("isOpen", isOpen);
        let html = (
          <Collapsible
            key={_.startCase(
              ProductTextLocalized(
                option.name_en,
                option.name_ar,
                localLanguage
              )
            )}
            trigger={
              <div className="grid grid-cols-2 cursor-pointer mt-5">
                <div className=" text-primary text-base md:text-sm font-medium">
                  {_.startCase(
                    ProductTextLocalized(
                      option.name_en,
                      option.name_ar,
                      localLanguage
                    )
                  )}
                </div>
                <div className="flex justify-end">
                  {_.includes(
                    openOption,
                    _.startCase(
                      ProductTextLocalized(
                        option.name_en,
                        option.name_ar,
                        localLanguage
                      )
                    )
                  ) ? (
                    <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                  ) : (
                    <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                  )}
                </div>
              </div>
            }
            open={true}
            onOpening={() =>
              handleTrigegr(
                _.startCase(
                  ProductTextLocalized(
                    option.name_en,
                    option.name_ar,
                    localLanguage
                  )
                )
              )
            }
            onClosing={() =>
              handleTrigegr(
                _.startCase(
                  ProductTextLocalized(
                    option.name_en,
                    option.name_ar,
                    localLanguage
                  )
                )
              )
            }
          >
            <div className="pt-4" key={index}>
              <OnHoverScrollContainer>
                {renderSubset(
                  option.subset,
                  ProductTextLocalized(
                    option.name_en,
                    option.name_ar,
                    localLanguage
                  ),
                  option.type
                )}
              </OnHoverScrollContainer>
            </div>
          </Collapsible>
        );

        childrenHtml.push(html);
      });
    }

    return childrenHtml;
  };

  const renderTreeSub = (subcat, parentid) => {
    return _.map(subcat, (data, index) => {
      return (
        <li
          key={"child_" + data.id}
          className="a-spacing-micro apb-browse-refinements-indent-2"
        >
          <span className="a-list-item">
            <label className="flex items-center cursor-pointer">
              <div className="pr-1">
                <input
                  type="checkbox"
                  onClick={(e) =>
                    onCheckAllcheckbox(e, parseInt(data.id), subcat, parentid)
                  }
                  checked={
                    _.includes(categoryid, parseInt(data.id)) ? true : false
                  }
                />
              </div>
              <span
                className={classNames(
                  categoryid[0] == data.id
                    ? "text-link font-semibold"
                    : "a-link-normal text-primary",
                  "text-base md:text-sm"
                )}
              >
                {ProductTextLocalized(
                  data.subcategoryname_en,
                  data.subcategoryname_ar,
                  localLanguage
                )}
              </span>
            </label>
          </span>
        </li>
      );
    });
  };

  const onCheckAllcheckbox = (event, id, subcategories, parent = null) => {
    console.log("id", id);
    console.log("checked", event.target.checked);
    console.log("parent", parent);
    console.log("subcategories", subcategories.length);
    if (event.target.checked) {
      let catArr = [];
      catArr = categoryid;
      if (!_.includes(categoryid, id)) catArr.push(id);

      if (parent == null && subcategories.length > 0) {
        _.map(subcategories, (data, index) => {
          if (!_.includes(categoryid, parseInt(data.id)))
            catArr.push(parseInt(data.id));
        });
      }

      setCategoryid(catArr);
    } else {
      let catArr = [];
      catArr = categoryid;

      let newArr = _.filter(catArr, function (item) {
        return item != id;
      });
      //console.log("newArr 1", newArr);
      if (parent == null && subcategories.length > 0) {
        _.map(subcategories, (data, index) => {
          newArr = _.filter(newArr, function (item) {
            return item != parseInt(data.id);
          });
        });
      } else if (parent != null && subcategories.length > 0) {
        let isFounded = subcategories.some((ai) =>
          newArr.includes(parseInt(ai.id))
        );
        if (!isFounded) {
          newArr = _.filter(newArr, function (item) {
            return item != parseInt(parent);
          });
        }
      } else if (
        parent != null &&
        subcategories.length == 0 &&
        newArr.length == 1
      ) {
        newArr = _.filter(newArr, function (item) {
          return item != parseInt(parent);
        });
        //console.log("newArr 2", newArr);
      }
      setCategoryid(newArr);
    }

    if (mobileFilter) {
      props.isMobileFilterOpen(false);
    }
    setIsSending(true);
    setPagenumber(1);
  };

  const renderCatTreeView = (subcat) => {
    //console.log("subcat", subcat);
    //console.log("categoryid", categoryid[0]);
    return _.map(subcat, (data, index) => {
      return (
        <>
          <li
            key={"parent_" + data.id}
            className="apb-browse-refinements-indent-1 a-spacing-micro"
          >
            <span className="a-list-item">
              {data.subcategories.length > 0 ? (
                <label className="flex items-center cursor-pointer">
                  <div className="pr-1">
                    <input
                      type="checkbox"
                      onClick={(e) =>
                        onCheckAllcheckbox(
                          e,
                          parseInt(data.id),
                          data.subcategories
                        )
                      }
                      checked={
                        _.includes(categoryid, parseInt(data.id)) ? true : false
                      }
                    />
                  </div>
                  <span dir="auto">
                    {ProductTextLocalized(
                      data.subcategoryname_en,
                      data.subcategoryname_ar,
                      localLanguage
                    )}
                  </span>
                </label>
              ) : categoryid[0] == parseInt(data.id) ? (
                <label className="flex items-center cursor-pointer">
                  <div className="pr-1">
                    <input
                      type="checkbox"
                      onClick={(e) =>
                        onCheckAllcheckbox(
                          e,
                          parseInt(data.id),
                          data.subcategories
                        )
                      }
                      checked={
                        _.includes(categoryid, parseInt(data.id)) ? true : false
                      }
                    />
                  </div>
                  <span
                    className="text-link text-base font-semibold md:text-sm"
                    dir="auto"
                  >
                    {ProductTextLocalized(
                      data.subcategoryname_en,
                      data.subcategoryname_ar,
                      localLanguage
                    )}
                  </span>
                </label>
              ) : (
                <label className="flex items-center cursor-pointer">
                  <div className="pr-1">
                    <input
                      type="checkbox"
                      onClick={(e) =>
                        onCheckAllcheckbox(
                          e,
                          parseInt(data.id),
                          data.subcategories
                        )
                      }
                      checked={
                        _.includes(categoryid, parseInt(data.id)) ? true : false
                      }
                    />
                  </div>
                  <span dir="auto">
                    {ProductTextLocalized(
                      data.subcategoryname_en,
                      data.subcategoryname_ar,
                      localLanguage
                    )}
                  </span>
                </label>
              )}
            </span>
            {data.subcategories.length > 0 ? (
              <ul
                className={classNames(
                  productBySubcategory?.breadcrumb?.id == data.categoryid
                    ? "active"
                    : "nested",
                  ""
                )}
              >
                {renderTreeSub(data.subcategories, data.id)}
              </ul>
            ) : (
              ""
            )}
          </li>
        </>
      );
    });
  };

  const handleTree = (id) => {
    //console.log({ id });
    var toggler = document.getElementsByClassName("caret");
    var i;
    // console.log("toggler.length", toggler.length);
    for (i = 0; i < toggler.length; i++) {
      //console.log(toggler[i]);
      toggler[i].addEventListener("click", function () {
        this.parentElement.querySelector(".nested").classList.toggle("active");
        this.classList.toggle("caret-down");
      });
    }

    // document.getElementById(id).addEventListener("click", function () {
    //   this.parentElement.querySelector(".nested").classList.toggle("active");
    //   this.classList.toggle("caret-down");
    // });
  };
  //console.log("productBySubcategory", productBySubcategory);
  return (
    <>
      <div className="p-3">
        <div className="">
          <a
            onClick={() => history.push("/view-category")}
            className="cursor-pointer text-vendorLink hover:underline visited:text-purple-600 text-sm font-normal"
          >
            {LocaleStrings.all_category}
          </a>
        </div>
        <div className="flex justify-between pt-3">
          <div className="text-primary text-base font-medium">
            {LocaleStrings.filter_text}
          </div>
          <div
            className="text-right text-primary text-sm font-normal cursor-pointer hover:underline"
            onClick={() => clearAll()}
          >
            {LocaleStrings.clear_all}
          </div>
        </div>
        {/* <div className="border-b border-primary pb-5">{renderCatList()}</div> */}
        <div className="border-b border-primary pb-5 pt-3">
          {filterOptions.category && filterOptions.category.length > 0 ? (
            <ul className="a-unordered-list a-nostyle a-vertical a-spacing-medium">
              {filterOptions.category.map((cat, index) => {
                return (
                  <>
                    <li key={"cat_" + cat.id} className="a-spacing-micro">
                      <span
                        //onClick={() => handleTree(cat.id)}
                        className={classNames(
                          cat.subcategory.length > 0
                            ? productBySubcategory?.breadcrumb?.id == cat.id
                              ? "caret caret-down "
                              : "caret "
                            : "",
                          "text-primary a-link-normal text-base md:text-sm"
                        )}
                        id={cat.id}
                      >
                        {ProductTextLocalized(
                          cat.categoryname_en,
                          cat.categoryname_ar,
                          localLanguage
                        )}
                      </span>
                      {cat.subcategory.length > 0 ? (
                        <ul
                          className={classNames(
                            productBySubcategory?.breadcrumb?.id == cat.id
                              ? "active"
                              : "nested",
                            ""
                          )}
                        >
                          {renderCatTreeView(cat.subcategory)}
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  </>
                );
              })}
            </ul>
          ) : (
            ""
          )}
        </div>

        <div
          className={classNames(
            filterOptions.options && filterOptions.options.length > 0
              ? "border-b border-primary "
              : "",
            "space-y-5 divide-y divide-primary pb-5"
          )}
        >
          {renderFilterList()}
        </div>
        <div className="mt-8">
          {filterOptions.category && filterOptions.category.length > 0 ? (
            <Collapsible
              key={"price"}
              trigger={
                <div className="grid grid-cols-2 cursor-pointer mt-8">
                  <div className=" text-primary text-base md:text-sm font-medium">
                    {LocaleStrings.price_title}
                  </div>
                  <div className="flex justify-end">
                    {_.includes(openOption, "price") ? (
                      <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                    ) : (
                      <ChevronRightIcon
                        className="h-4 w-4"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                </div>
              }
              open={true}
              onOpening={() => handleTrigegr("price")}
              onClosing={() => handleTrigegr("price")}
            >
              <div className="flex item-center justify-between">
                <div className="font-normal text-sm text-uname">
                  {labelRange[0]}
                </div>
                <div className="font-normal text-sm text-uname">
                  {labelRange[1]}
                </div>
              </div>
              <div className="pt-0">
                <Slider
                  rootStyle={sliderStyle}
                  domain={domain}
                  mode={2}
                  step={200}
                  onChange={(e) => onPriceChange(e)}
                  values={priceRange}
                  onUpdate={(e) => onPriceUpdate(e)}
                >
                  <Rail>
                    {({ getRailProps }) => (
                      <div style={railStyle} {...getRailProps()} />
                    )}
                  </Rail>
                  <Handles>
                    {({ handles, getHandleProps }) => (
                      <div className="slider-handles">
                        {handles.map((handle) => (
                          <Handle
                            key={handle.id}
                            handle={handle}
                            getHandleProps={getHandleProps}
                          />
                        ))}
                      </div>
                    )}
                  </Handles>
                  <Tracks left={false} right={false}>
                    {({ tracks, getTrackProps }) => (
                      <div className="slider-tracks">
                        {tracks.map(({ id, source, target }) => (
                          <Track
                            key={id}
                            source={source}
                            target={target}
                            getTrackProps={getTrackProps}
                          />
                        ))}
                      </div>
                    )}
                  </Tracks>
                  {/* <Ticks count={5}>
                    {({ ticks }) => (
                      <div className="slider-ticks">
                        {ticks.map((tick) => (
                          <Tick
                            key={tick.id}
                            tick={tick}
                            count={ticks.length}
                          />
                        ))}
                      </div>
                    )}
                  </Ticks> */}
                </Slider>
              </div>
            </Collapsible>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  deviceSize: state.deviceSize,
  localLanguage: state.localLanguage,
  filterOptions: state.filterOptions,
  mobileFilter: state.mobileFilter,
  productBySubcategory: state.productBySubcategory,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchProductBySubCategory, emptyProductList, isMobileFilterOpen },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FilterOption);
