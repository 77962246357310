import React from "react";
import {
  LABEL_POSITION_TOP,
  LABEL_POSITION_LEFT,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DATE_FORMAT_SM,
} from "./constant";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import moment from "moment";
import { subYears, parse } from "date-fns";
import _ from "lodash";
import { classNames } from "./constant";
import EditIcon from "../../assets/images/editicon.svg";

export const renderFieldText = (field) => {
  var labelposition = field.labelposition;
  var formdiv = "";
  if (field.formdiv !== undefined && field.formdiv === "false") {
    formdiv = "";
  }
  var divClassName = `form-group row ${formdiv} ${
    field.meta.touched && field.meta.error ? "has-danger" : ""
  }`;
  divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
  var labelClasses = field.classNameLabel;
  var inputClasses = "col-sm-9 col-sm-offset-0";

  if (labelposition === LABEL_POSITION_TOP) {
    labelClasses = field.classNameLabel;
    inputClasses = "mt-1 col-sm-12 col-sm-offset-0";
  }

  return (
    <div className={divClassName}>
      {field.label !== "" ? (
        <label className={labelClasses}>
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}
      <div className={inputClasses}>
        {field.type === "password" ? (
          <>
            <input
              className="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 text-sm md:text-base"
              type={field.show ? "text" : "password"}
              placeholder={field.placeholder}
              {...field.input}
              autoComplete="off"
              autoCorrect="off"
            />
            <div className={field.classNameEye}>
              {field.show ? (
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="eye"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  onClick={() => field.setShow(!field.show)}
                >
                  <path
                    d="M81.8 537.8a60.3 60.3 0 010-51.5C176.6 286.5 319.8 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c-192.1 0-335.4-100.5-430.2-300.2z"
                    fill="#e6f7ff"
                  ></path>
                  <path
                    d="M512 258c-161.3 0-279.4 81.8-362.7 254C232.6 684.2 350.7 766 512 766c161.4 0 279.5-81.8 362.7-254C791.4 339.8 673.3 258 512 258zm-4 430c-97.2 0-176-78.8-176-176s78.8-176 176-176 176 78.8 176 176-78.8 176-176 176z"
                    fill="#e6f7ff"
                  ></path>
                  <path
                    d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258s279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766z"
                    fill="#1890ff"
                  ></path>
                  <path
                    d="M508 336c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"
                    fill="#1890ff"
                  ></path>
                </svg>
              ) : (
                <svg
                  onClick={() => field.setShow(!field.show)}
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="eye-invisible"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z"
                    fill="#969FA7"
                  ></path>
                  <path
                    d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z"
                    fill="#969FA7"
                  ></path>
                </svg>
              )}
            </div>
          </>
        ) : (
          <>
            <input
              className={
                field.currency
                  ? "appearance-none block w-full pl-9 pr-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 text-sm md:text-base"
                  : "appearance-none block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 text-sm md:text-base"
              }
              type={field.type}
              placeholder={field.placeholder}
              {...field.input}
              maxLength={field.maxlength ? field.maxlength : ""}
              readOnly={field.readonly ? true : false}
              onKeyPress={field.onKeyPress ? (e) => field.onKeyPress(e) : null}
            />
            {field.currency ? (
              <div className={field.classNameEye}>{field.currency}</div>
            ) : (
              ""
            )}
          </>
        )}
        <div className="text-error label-text-help">
          {field.meta.touched ? field.meta.error : ""}
        </div>
      </div>
    </div>
  );
};

export const renderHiddenFieldText = (field) => {
  var divClassName = `row ${field.meta.error ? "has-danger" : ""}`;
  divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
  var inputClasses = "col-sm-12 col-sm-offset-0";

  return (
    <div className={divClassName}>
      <div className={inputClasses} style={{ display: "none" }}>
        <input
          className="form-control"
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
        />
      </div>
      <div className="col-sm-12 text-help label-text-help">
        {field.meta.error ? field.meta.error : ""}
      </div>
    </div>
  );
};

export const renderFieldTextarea = (field) => {
  var labelposition = field.labelposition;
  var divClassName = `form-group row ${
    field.meta.touched && field.meta.error ? "has-danger" : ""
  }`;
  divClassName = `${divClassName} ${field.bsClass}`;

  var labelClasses = field.classNameLabel;
  var inputClasses = "col-sm-9 col-sm-offset-0";

  if (labelposition === LABEL_POSITION_TOP) {
    labelClasses = field.classNameLabel;
    inputClasses = "col-sm-12 col-sm-offset-0";
  }
  return (
    <div className={divClassName}>
      <label className={labelClasses}>
        {field.label}
        <span className="text-primary">
          {field.mandatory === "true" ? "*" : ""}
        </span>
      </label>
      <div className={inputClasses}>
        <textarea
          className={field.className}
          placeholder={field.placeholder}
          {...field.input}
          maxLength={field.maxlength ? field.maxlength : ""}
          rows={field.rows ? field.rows : ""}
        ></textarea>
        <div className="text-error label-text-help">
          {field.meta.touched ? field.meta.error : ""}
        </div>
      </div>
    </div>
  );
};

export const renderCheckbox = (field) => {
  let { label, opts, input, meta } = field;
  var divClassName = `form-group row ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;

  return (
    <div className={divClassName}>
      <label className="control-label-top col-sm-12">
        {label}
        <span className="text-primary">
          {field.mandatory === "true" ? "*" : ""}
        </span>
      </label>
      <div className="col-sm-12 col-sm-offset-0">
        {opts.map((option, index) => {
          return (
            <div key={index}>
              <label
                className="custom-container-checkbox"
                style={{ marginBottom: 5 }}
              >
                {option.text}
                <input
                  type="checkbox"
                  name={`${input.name}[${index}]`}
                  value={option.value}
                  checked={input.value.indexOf(option.value) !== -1}
                  onChange={(event) => {
                    const newValue = [...input.value];
                    if (event.target.checked) {
                      newValue.push(option.value);
                    } else {
                      newValue.splice(newValue.indexOf(option.value), 1);
                    }
                    return input.onChange(newValue);
                  }}
                />
                <span className="checkmark" />
              </label>
            </div>
          );
        })}
        <div className="text-help label-text-help">
          {meta.touched ? meta.error : ""}
        </div>
      </div>
    </div>
  );
};

export const renderSelect = (field) => {
  var divClassName = `form-group row ${
    field.meta.touched && field.meta.error ? "has-danger" : ""
  }`;

  var labelClasses = field.classNameLabel;
  var inputClasses = "col-sm-9 col-sm-offset-0";

  if (field.labelposition === LABEL_POSITION_TOP) {
    labelClasses = field.classNameLabel;
    inputClasses = "col-sm-12 col-sm-offset-0";
  }
  return (
    <div className={divClassName}>
      <label className={labelClasses}>
        {field.label}
        <span className="text-primary">
          {field.mandatory === "true" ? "*" : ""}
        </span>
      </label>
      <div className={inputClasses}>
        <select className={field.className} {...field.input}>
          <option value="" key={field.label}>
            {field.placeholder}
          </option>
          {field.opts.map((object, index) => {
            return (
              <option key={`${object.value}-${index}`} value={object.value}>
                {object.name ? object.name : object.text}
              </option>
            );
          })}
        </select>
        <div className="text-help label-text-help">
          {field.meta.touched ? field.meta.error : ""}
        </div>
      </div>
    </div>
  );
};

export const renderDatalistSelect = (field) => {
  var divClassName = `form-group row ${
    field.meta.touched && field.meta.error ? "has-danger" : ""
  }`;

  var labelClasses = "control-label col-sm-3";
  var inputClasses = "col-sm-9 col-sm-offset-0";

  if (field.labelposition === LABEL_POSITION_TOP) {
    labelClasses = "control-label-top col-sm-12";
    inputClasses = "col-sm-12 col-sm-offset-0";
  }
  return (
    <div className={divClassName}>
      <label className={labelClasses}>
        {field.label}
        <span className="text-primary">
          {field.mandatory === "true" ? "*" : ""}
        </span>
      </label>
      <div className="col-sm-12 col-sm-offset-0">
        <input
          className="form-control"
          type={field.type}
          placeholder={field.placeholder}
          {...field.input}
          list={field.list}
        />
        <datalist id={field.list}>
          {field.datalistoptn.map((item, key) => (
            <option key={key} value={item.value} />
          ))}
        </datalist>
        <div className="text-help label-text-help">
          {field.meta.touched ? field.meta.error : ""}
        </div>
      </div>
    </div>
  );
};

export const renderSelectWithDelete = (field) => {
  var divClassName = `form-group row ${
    field.meta.touched && field.meta.error ? "has-danger" : ""
  }`;
  return (
    <div className={divClassName}>
      <label className="control-label-top col-sm-12 mt-0">
        {field.label}
        <span className="text-primary">
          {field.mandatory === "true" ? "*" : ""}
        </span>
      </label>
      <div className="col-sm-12 col-sm-offset-0 d-flex">
        <select className="form-control" {...field.input}>
          <option value="" key={field.label}>
            {field.placeholder}
          </option>
          {field.opts.map((object, index) => {
            return (
              <option key={`${object.value}-${index}`} value={object.value}>
                {object.name}
              </option>
            );
          })}
        </select>
        <div className="text-help label-text-help">
          {field.meta.touched ? field.meta.error : ""}
        </div>
        {/* </div> */}
        {/* <div className="col-sm-2"> */}
        <button
          bsStyle=""
          type="button"
          className="i-custom-table-delete ml-10"
          onClick={field.deleteaction}
        >
          <img
            className="i-custom-table"
            src="style/images/custom-icons/shape-1317-copy-3.svg"
          />
        </button>
      </div>
    </div>
  );
};

export const renderFieldCheckbox = (field) => {
  return (
    // <div>
    //   <label
    //     className="custom-container-checkbox"
    //     style={{ marginBottom: 5 }}
    //   >
    //     {field.label}
    //     <input type={field.type} {...field.input} />
    //     <span className="checkmark" />
    //   </label>
    // </div>
    <div>
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            type={field.type}
            {...field.input}
            className={field.className}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="candidates" className={field.classNameLabel}>
            {field.label}
          </label>
        </div>
      </div>
    </div>
  );
};

export const renderFieldRadio = (field) => {
  //console.log("field", field);
  return (
    <div
      className="cursor-pointer"
      //onClick={() => field.radioOnchange(field.input.value)}
    >
      <input {...field.input} type={field.type} />
      <label
        for={field.label}
        className="pl-3 font-normal text-base text-loginLabel cursor-pointer"
      >
        &nbsp;
        <span className="radiomark"></span>
        {field.label}
      </label>
    </div>
  );
};

export const renderFieldRadio2 = (field) => {
  //console.log("field", field);
  return (
    <div
      className="cursor-pointer flex"
      //onClick={() => field.radioOnchange(field.input.value)}
    >
      <input {...field.input} type={field.type} />
      <label for={field.label} className={field.classNameLabel}>
        &nbsp;
        <span className="radiomark"></span>
        {field.label}
      </label>
    </div>
  );
};

export const renderFieldRadio1 = (field) => {
  return (
    <div className=" flex items-center">
      {field.lebelAlign ? "" : field.label}
      <input
        {...field.input}
        type={field.type}
        className={field.className}
        disabled={field.disabled ?? false}
      />
      &nbsp;
      <span className="radiomark"></span>
      {field.lebelAlign === "R" ? field.label : ""}
    </div>
  );
};

export const renderPhonewithFlag = (field) => {
  var labelposition = field.labelposition;
  var formdiv = "";
  if (field.formdiv !== undefined && field.formdiv === "false") {
    formdiv = "";
  }
  var divClassName = `form-group row ${formdiv} ${
    field.meta.touched && field.meta.error ? "has-danger" : ""
  }`;
  divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
  var labelClasses = field.classNameLabel;
  var inputClasses = "col-sm-9 col-sm-offset-0";

  if (labelposition === LABEL_POSITION_TOP) {
    labelClasses = field.classNameLabel;
    inputClasses = "col-sm-12 col-sm-offset-0";
  }
  return (
    <div className={divClassName}>
      {field.label !== "" ? (
        <label className={labelClasses}>
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}
      <div className={inputClasses}>
        <PhoneInput
          {...field.input}
          country={"qa"}
          placeholder={field.placeholder}
          inputStyle={{
            width: "100%",
            height: 46,
            border: "1px solid rgb(209 213 219)",
            boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
          }}
          enableSearch={true}
          value={field.phone}
          onChange={(value, country, e, formattedValue) =>
            field.phoneChange(value, country, e, formattedValue)
          }
          inputProps={{
            name: field.name,
          }}
          autoFormat={true}
          countryCodeEditable={false}
          //disableCountryCode={true}
        />
        <div className="text-error label-text-help">
          {field.meta.touched ? field.meta.error : ""}
        </div>
      </div>
    </div>
  );
};

export const renderDatePickerWithLabel = (field) => {
  //debugger;
  var { input, selected, disabled } = field;

  var value = input.value ? input.value : null;

  var selectedValue = input?.value
    ? input.value
    : field?.selected
    ? field.selected
    : null;

  if (typeof selectedValue === "string") {
    selectedValue = parse(
      moment(selectedValue).format("DD-MM-YYYY"),
      "dd-MM-yyyy",
      new Date()
    );
  }

  var divClassName = `form-group row ${
    field.meta.touched && field.meta.error ? "has-danger" : ""
  }`;
  divClassName = `${divClassName} ${field.bsClass}`;
  var labelClasses = field.classNameLabel;
  var inputClasses = "col-sm-12 col-sm-offset-0";
  let today = new Date();
  const days_to_subtract = 4745;
  return (
    <div className={divClassName}>
      <label className={labelClasses}>
        {field.label}
        <span className="text-primary">
          {field.mandatory === "true" ? "*" : ""}
        </span>
      </label>
      <div className={inputClasses}>
        {/* <DatePicker
          {...input}
          value={value}
          selected={selectedValue}
          dateFormat={DATE_FORMAT_SM}
          disabled={disabled}
          name={input.name}
          className={field.className}
          style={{ width: "100%" }}
          maxDate={
            new Date(today.valueOf() - days_to_subtract * 24 * 60 * 60 * 1000)
          }
        /> */}

        <DatePicker
          isClearable
          placeholderText={field?.placeholder ? field.placeholder : ""}
          name={input.name}
          {...input}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="off"
          selected={selectedValue}
          dateFormat={"dd-MM-yyyy"}
          maxDate={subYears(new Date(), 13)}
          disabled={disabled}
          className={field.className}
          style={{ width: "100%" }}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          yearDropdownItemNumber={50}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />

        <div className="text-help label-text-help">
          {field.meta.touched ? field.meta.error : ""}
        </div>
      </div>
    </div>
  );
};

export const renderOTP = (field) => {
  var labelposition = field.labelposition;
  var formdiv = "";
  if (field.formdiv !== undefined && field.formdiv === "false") {
    formdiv = "";
  }
  var divClassName = `form-group row ${formdiv} ${
    field.meta.touched && field.meta.error ? "has-danger" : ""
  }`;
  divClassName = `${divClassName} ${field.bsClass ? field.bsClass : ""}`;
  var labelClasses = field.classNameLabel;
  var inputClasses = "col-sm-9 col-sm-offset-0";

  if (labelposition === LABEL_POSITION_TOP) {
    labelClasses = field.classNameLabel;
    inputClasses = "col-sm-12 col-sm-offset-0";
  }
  return (
    <div className={divClassName}>
      {field.label !== "" ? (
        <label className={labelClasses}>
          {field.label}
          <span className="text-primary">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}
      <div className={inputClasses}>
        <OtpInput
          {...field.input}
          numInputs={6}
          isInputNum={true}
          separator={<span style={{ color: "#d9d9d9" }}>-</span>}
          inputStyle={field.inputstyle}
        />
        <div className="text-error label-text-help">
          {field.meta.touched ? field.meta.error : ""}
        </div>
      </div>
    </div>
  );
};

export const number = (value) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;

export const renderFieldCheckboxwithLink = (field) => {
  return (
    <div>
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            type={field.type}
            {...field.input}
            className={field.className}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="candidates" className={field.classNameLabel}>
            <a href={field.link} target="_blank">
              {field.label}
            </a>
          </label>
        </div>
      </div>
      <div className="text-error label-text-help">
        {field.meta.touched ? field.meta.error : ""}
      </div>
    </div>
  );
};
