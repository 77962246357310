import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import { classNames } from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import { isMobileSortbyOpen } from "../actions";

function MobileSort(props) {
  const { mobileSort, sortbyArr, isSending, setIsSending, setSortBy } = props;

  const sortBy = (e) => {
    let sortby = e.target.value;
    //console.log("sortby", sortby);
    setSortBy(sortby);
    setIsSending(true);
    props.isMobileSortbyOpen(false);
  };
  return (
    <>
      <article
        className={
          mobileSort
            ? "mobile_popup_show bg-white rounded-t-3xl border-t"
            : "mobile_popup_hide "
        }
        style={
          mobileSort
            ? {
                top: "50%",
                boxShadow: "0px -4px 3px rgba(0, 0, 0, 0.30)",
              }
            : {}
        }
      >
        <div className="grid grid-cols-2 border-b pb-md">
          <div className="text-left text-base text-primary font-normal">
            {_.upperCase(LocaleStrings.sortby)}
          </div>
          <div className="text-right">
            <button
              className="vs_popup_close_btn text-link text-sm"
              onClick={() => props.isMobileSortbyOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
        <div className="">
          {sortbyArr.map((item, tabIdx) => (
            <div className="flex items-center text-base py-3.5">
              <div className="w-4/5 text-primary">{item.text}</div>
              <div className="text-right w-1/5">
                <input
                  type="radio"
                  value={item.key}
                  name="sortby"
                  onChange={(e) => sortBy(e)}
                />
              </div>
            </div>
          ))}
        </div>
      </article>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  deviceSize: state.deviceSize,
  localLanguage: state.localLanguage,
  mobileSort: state.mobileSort,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ isMobileSortbyOpen }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MobileSort);
