import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import HammerNodata from "../../../assets/images/hammer-nodata.svg";
import EyeGrey from "../../../assets/images/eye.svg";
import ItemList from "../../Home/components/item-list";
import {
  fetchMyWatchlist,
  emptyMyWatchlist,
  removeWatchlist,
} from "../actions";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import toast, { Toaster } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
function Watchlist(props) {
  const history = useHistory();
  const { session, isLoggedIn, myWatchList } = props;
  const [currentTab, setCurrenTab] = useState("watchlist");
  const [delItem, setDelItem] = useState(false);
  const [delloading, setDelloading] = useState(false);
  const [checkedItem, setCheckedItem] = useState([]);
  const tabArr = [
    { key: "watchlist", text: LocaleStrings.watchlist_tab_watching },
    { key: "bids", text: LocaleStrings.watchlist_tab_mybids },
  ];

  useEffect(() => {
    if (isLoggedIn) {
      props.fetchMyWatchlist(session, currentTab);
    } else {
      history.push("/login");
    }
  }, []);

  const _handleDateTab = (e, selectedTab) => {
    setDelItem(false);
    setCurrenTab(selectedTab);
    props.emptyMyWatchlist();
    props.fetchMyWatchlist(session, selectedTab);
  };

  const renderList = () => {
    return _.map(myWatchList.data, (item, index) => {
      return (
        <ItemList
          item={item.product}
          itemid={item.productid}
          type="similar"
          prevpage="watchlist"
          delItem={delItem}
          checkedItem={checkedItem}
          setCheckedItem={setCheckedItem}
          pagetitle={LocaleStrings.watchlist_txt}
        />
      );
    });
  };

  const deleteItem = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDelItem(true);
  };

  const deleteSelected = () => {
    if (checkedItem.length > 0) {
      let ids = checkedItem.join(",");
      setDelloading(true);
      props.removeWatchlist(session, ids, (res) => {
        setDelItem(false);
        setCheckedItem([]);
        if (res.success == 1) {
          props.emptyMyWatchlist();
          props.fetchMyWatchlist(session, currentTab, (res) => {
            if (res.success == 1) {
              toast.success(LocaleStrings.watchlist_delete_item_success);
            }
          });
        } else {
          setDelloading(false);
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    }
  };

  return (
    <>
      <div className="mt-5 custom_container">
        <div className="text-left text-primary text-xs pb-5">
          <a
            onClick={() => history.push("/activity")}
            className="cursor-pointer"
          >
            {LocaleStrings.activity}
          </a>
          &nbsp;/&nbsp; {LocaleStrings.watchlist_txt}
        </div>
        <div className="mt-2">
          <nav
            className="relative z-0 flex divide-x divide-gray-200"
            aria-label="Tabs"
          >
            {tabArr.map((tab, tabIdx) => (
              <>
                <a
                  key={tab.key}
                  className={classNames(
                    currentTab == tab.key
                      ? "text-primary "
                      : "text-tabinactive hover:text-primary ",
                    "relative min-w-0 flex-1 bg-white py-4 px-4 text-sm font-normal text-center hover:bg-quaternary focus:z-10 cursor-pointer"
                  )}
                  aria-current={currentTab == tab.key ? "page" : undefined}
                  onClick={(e) => _handleDateTab(e, tab.key)}
                >
                  <span>{tab.text}</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      currentTab == tab.key ? "bg-primary" : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-0.5"
                    )}
                  />

                  {currentTab == "watchlist" && tab.key == "watchlist" ? (
                    <Menu as="div" className="absolute top-3 right-1">
                      {({ open }) => (
                        <>
                          <div className="">
                            <Menu.Button className="btn-card">
                              <span className="sr-only">Open options</span>
                              <DotsVerticalIcon
                                className="h-5 w-5 hover:shadow"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>

                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              <div className="py-1 divide-y">
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      onClick={(e) => deleteItem(e)}
                                      className={classNames(
                                        active ? "bg-quaternary " : " ",
                                        "block px-4 py-2 text-sm text-primary cursor-pointer"
                                      )}
                                    >
                                      {
                                        LocaleStrings.wishlist_item_delete_option
                                      }
                                    </a>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  ) : (
                    ""
                  )}
                </a>
              </>
            ))}
          </nav>
        </div>
        <div className="my-11">
          {myWatchList && myWatchList.data ? (
            myWatchList.data.length > 0 ? (
              <>
                <div className="grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 gap-5">
                  {renderList()}
                </div>
                {checkedItem.length > 0 ? (
                  <div className="mt-6">
                    <button
                      type="submit"
                      className="text-white h-44 bg-delitem text-sm font-normal rounded w-full inline-flex items-center justify-center"
                      onClick={() => deleteSelected()}
                    >
                      {delloading ? (
                        <svg
                          className="animate-spin h-5 w-5 rounded-full mr-3"
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      ) : (
                        ""
                      )}
                      <span className="text-sm">
                        {LocaleStrings.button_delete} &nbsp;
                        {checkedItem.length}&nbsp;
                        {checkedItem.length == 1
                          ? LocaleStrings.item
                          : LocaleStrings.items}
                      </span>
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : currentTab == "watchlist" ? (
              <div className="flex h-cardimg items-center justify-center">
                <div>
                  <div className="flex items-center justify-center pb-5">
                    <img src={EyeGrey} className="w-8" />
                  </div>
                  <div className="flex items-center justify-center text-cutoff text-base font-medium">
                    {LocaleStrings.no_watching_title}
                  </div>
                  <div className="flex items-center justify-center text-cutoff text-sm">
                    {LocaleStrings.no_watching_desc}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex h-cardimg items-center justify-center">
                <div>
                  <div className="flex items-center justify-center pb-5">
                    <img src={HammerNodata} />
                  </div>
                  <div className="flex items-center justify-center text-cutoff text-base font-medium">
                    {LocaleStrings.no_bids_title}
                  </div>
                  <div className="flex items-center justify-center text-cutoff text-sm">
                    {LocaleStrings.no_bids_desc}
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="flex h-cardimg items-center justify-center">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  myWatchList: state.myWatchList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchMyWatchlist, emptyMyWatchlist, removeWatchlist },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Watchlist);
