import React, { useState, useRef } from "react";
import { createPopper } from "@popperjs/core";
import Tooltip from "../../../components/Common/tooltip";

const MapMarker = (props) => {
  const [tooltipShow, setTooltipShow] = React.useState(false);
  const btnRef = useRef(null);

  const openLeftTooltip = () => {
    setTooltipShow(true);
  };
  const closeLeftTooltip = () => {
    setTooltipShow(false);
  };
  return (
    <div
      ref={btnRef}
      onMouseEnter={openLeftTooltip}
      onMouseLeave={closeLeftTooltip}
      className="w-292"
    >
      <img
        src="https://icon-library.com/images/pin-icon-png/pin-icon-png-9.jpg"
        height="35"
        width="25"
      />
      {tooltipShow && (
        <Tooltip tooltipShow={tooltipShow} tooltext={props.address} />
      )}
    </div>
  );
};

export default MapMarker;
