import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import Loader from "../../../components/Common/loader";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
  CURRENCY_RATE_QR,
  phoneCount,
  isValidQatarPhoneNumber,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
  currencyCode,
  currencyRate,
  priceConversion,
} from "../../../components/Common/product-model";
import {
  renderFieldText,
  renderFieldCheckbox,
  renderPhonewithFlag,
  renderSelect,
  renderFieldTextarea,
  renderHiddenFieldText,
  renderFieldRadio1,
} from "../../../components/Common/base-component";
import DeliverytoIcon from "../../../assets/images/deliverto.svg";
import DeliverybyIcon from "../../../assets/images/delivery.svg";
import CouponIcon from "../../../assets/images/couponicon.svg";
import RemoveIcon from "../../../assets/images/remove.svg";
import EditIcon from "../../../assets/images/editicon.svg";
import CashIcon from "../../../assets/images/cash.svg";
import PayMethod from "../../../assets/images/payment-method.svg";
import PlusIcon from "../../../assets/images/plusquantity.svg";
import MinusIcon from "../../../assets/images/minusquantity.svg";
import CouponSlider from "./coupon-list";
import ChangeAddress from "./change-address";
import NewAddress from "./new-address";
import { fetchCart } from "../../Product/actions";
import {
  updateQuantity,
  togglecartSelection,
  deleteCartItem,
  isCouponlistModalOpen,
  availableCoupons,
  fetchCountry,
  setdefaultCountry,
  isChangeAddressModal,
  getUserAddressAll,
  isNewAddressModalOpen,
  createOrder,
  makePayment,
  removeCouponCode,
  paymentBypass,
  applyCoupon,
  makeDefaultAddress,
  updateCart,
  fetchZone,
  getPartnerPrice,
} from "../actions";
import { addAddress, updateAddress } from "../../Profile/actions";
import { showNotificatonCount } from "../../Notification/actions";
import toast, { Toaster } from "react-hot-toast";
import CartEmpty from "../../../assets/images/cart_empty.svg";
import RightArrow from "../../../assets/images/right-arrow.svg";
import CartArrow from "../../../assets/images/cart-arrow.svg";
import CouponRemove from "../../../assets/images/couponremove.svg";
import { countryPhoneLength } from "../../../actions";
import { editUserAddress } from "../../Profile/actions";
import { CircleSpinner } from "react-spinners-kit";
import { confirmAlert } from "react-confirm-alert";
import { CubeTransparentOutline } from "heroicons-react";

const scrollIntoViewHelper = (errors) => {
  //console.log("errors", errors);
  const firstError = Object.keys(errors)[0];
  let el = document.querySelector(`[name="${firstError}"]`);

  if (el) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
};

function Cart(props) {
  const history = useHistory();
  const {
    getUserCart,
    isLoggedIn,
    session,
    userAddressAll,
    localLanguage,
    appliedCoupon,
    handleSubmit,
    submitting,
    countryList,
    localCurrency,
    deviceSize,
    zoneList,
    initialValues,
  } = props;
  //const [cartData, setCartData] = useState([]);
  const [totalCal, setTotalCal] = useState(0);
  const [onlyPrice, setOnlyPrice] = useState(0);
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [showBilling, setShowBilling] = useState(true);
  const [loading, setLoading] = useState(false);
  const [billingAddress, setBillingAddress] = useState([]);
  const [addressid, setAddressid] = useState("");
  const [variantId, setVariantId] = useState(null);
  const [couponId, setCouponId] = useState(null);
  const [sameAsBilling, setSameAsBilling] = useState(false);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [countryid, setCountryId] = useState(null);
  const [editcountryId, setEditCountryId] = useState(null);
  const [timeInterval, setTimeInterval] = useState(0);

  let CURRENCY = currencyCode(localCurrency);
  let CURRENCY_RATE = currencyRate(localCurrency);

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 2000);

  useEffect(() => {
    if (timeInterval > 0) {
      props.getPartnerPrice(session, (res) => {
        if (res.success == 1) {
          props.fetchCart(session);
        }
      });
    }
  }, [timeInterval]);

  useEffect(() => {
    if (isLoggedIn && session && session.userid) {
      props.fetchCart(session, (res) => {
        if (res.success && res.data && res.data.length > 0) {
          props.getUserAddressAll(session, (feedback) => {
            if (feedback.success && feedback.data && feedback.data.length > 0) {
              let defaultaddress = [];
              if (res.data[0].addressid) {
                defaultaddress = _.filter(feedback.data, function (obj) {
                  return obj.id == res.data[0].addressid;
                });
                //console.log("defaultaddress", defaultaddress);
                if (defaultaddress.length > 0) {
                  setShippingAddress(defaultaddress);
                }
              } else {
                defaultaddress = _.filter(feedback.data, function (obj) {
                  return obj.defaultaddress == 1;
                });
                //console.log("defaultaddress2", defaultaddress);
                if (defaultaddress.length > 0) {
                  setShippingAddress(defaultaddress);
                }
              }

              let billingAddress = _.filter(feedback.data, function (obj) {
                return obj.isbillingaddress;
              });
              if (billingAddress.length > 0 && billingAddress[0].country_id) {
                setCountryId(billingAddress[0].country_id);
              }
            }
            props.fetchCountry(session);
            props.fetchZone(session);
          });
        }
      });
      //props.setdefaultCountry(session, "QA");
    }
  }, []);

  const callbackAdrress = (defaultaddress) => {
    setShippingAddress(defaultaddress);
  };

  const checkUncheckSelected = (cartid, checked) => {
    let values = {};
    values.selected = checked ? 0 : 1;
    values.cartid = cartid;
    values.type = "single";
    props.togglecartSelection(session, values, (res) => {
      if (res.success == 1) {
        props.fetchCart(session, (res) => {});
      }
    });
  };

  const checkUncheckAll = (checked) => {
    let values = {};
    values.selected = checked ? 0 : 1;
    values.cartid = "";
    values.type = "all";
    props.togglecartSelection(session, values, (res) => {
      if (res.success == 1) {
        props.fetchCart(session, (res) => {});
      }
    });
  };
  const decrementValue = (cartid, quantity) => {
    if (!isNaN(quantity) && quantity > 0) {
      let values = {};
      values.quantity = parseInt(quantity) - 1;
      values.cartid = cartid;
      props.updateQuantity(session, values, (res) => {
        if (res.success == 1) {
          props.fetchCart(session, (res) => {});
        }
      });
    }
  };
  const incrementValue = (cartid, quantity) => {
    if (!isNaN(quantity)) {
      let values = {};
      values.quantity = parseInt(quantity) + 1;
      values.cartid = cartid;
      props.updateQuantity(session, values, (res) => {
        if (res.success == 1) {
          props.fetchCart(session, (res) => {});
        } else {
          toast.error(LocaleStrings.quantity_not_available);
        }
      });
    }
  };

  const removeItem = (cartid) => {
    props.deleteCartItem(session, cartid, (res) => {
      if (res.success == 1) {
        props.showNotificatonCount(session);
        props.fetchCart(session, (res) => {});
      }
    });
  };

  const renderSimpleItem = () => {
    return _.map(getUserCart.items, (item, index) => {
      return (
        <div className="flex px-15 py-5">
          <div className="pr-2 md:pr-4">
            <div className="w-14 h-14 border border-primary rounded flex items-center justify-center">
              <a
                href={`/product-details/${
                  _.kebabCase(item.productname_en) +
                  "-" +
                  item.productid +
                  "-" +
                  item.variantid
                }`}
                className="cursor-pointer"
              >
                <img
                  src={`${FILES_URL}/vendor/variations/${item.productimage}?api_key=${APP_API_KEY}`}
                  alt=""
                  className="w-full object-fill"
                />
              </a>
            </div>
          </div>
          <div className="w-11/12">
            <div>
              <div className="text-sm text-primary font-medium">
                <a
                  href={`/product-details/${
                    _.kebabCase(item.productname_en) +
                    "-" +
                    item.productid +
                    "-" +
                    item.variantid
                  }`}
                  className="cursor-pointer"
                >
                  {ProductTextLocalized(
                    item.productname_en,
                    item.productname_ar,
                    localLanguage
                  )}
                </a>
              </div>
              <div className="text-sm text-cardfooter">
                {LocaleStrings.cart_sku}:{item.sku}
              </div>
              <div className="flex mt-4 items-center">
                <div className="text-base font-normal text-primary pr-2 md:pr-4">
                  {CURRENCY}
                  {parseFloat(item.price).toFixed(2)}
                </div>
                <div className="text-xs font-normal text-cutoff pr-2 md:pr-4 line-through">
                  {CURRENCY}
                  {parseFloat(item.originalprice).toFixed(2)}
                </div>
                <div className="text-xs text-medium text-discount">
                  {CURRENCY}
                  {parseFloat(item.discount).toFixed(1)}
                  {item.discounttype == "percentage" ? "% " : " "}OFF
                </div>
              </div>
              <div className="text-primary font-normal text-xs mt-1">
                + {CURRENCY}
                {parseFloat(item.shippingprice).toFixed(2)}{" "}
                {LocaleStrings.shipping}
              </div>

              {parseInt(item.istaxable) == 1 ? (
                <div className="text-primary font-normal text-xs mt-1">
                  + {CURRENCY}
                  {parseFloat(item.tax_amount).toFixed(2)}{" "}
                  {LocaleStrings.cart_tax_charges}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  const couponSelect = (e, item) => {
    let id = e.target.value;
    if (id) {
      let variantid = item.variantid;
      let currencyrate = currencyRate(localCurrency);
      setVariantId(variantid);
      setCouponId(id);
      let values = {};
      values.couponid = id;
      values.currencyrate = currencyrate;
      values.variantid = variantid;
      values.productid = item.productid;
      props.applyCoupon(session, values, (response) => {
        if (response.success == 1) {
          props.fetchCart(session, (res) => {
            setVariantId(null);
            setCouponId(null);
          });
          toast.success(LocaleStrings.cart_coupon_applied_success);
        } else {
          if (response.errorcode == "error_01") {
            toast.error(LocaleStrings.cart_coupon_error1);
          } else if (response.errorcode == "error_02") {
            toast.error(LocaleStrings.cart_coupon_error2);
          } else if (response.errorcode == "error_03") {
            toast.error(LocaleStrings.cart_coupon_error3);
          } else if (response.errorcode == "error_04") {
            toast.error(LocaleStrings.cart_coupon_error4);
          }
        }
        setVariantId(null);
        setCouponId(null);
      });
    }
  };
  const applycoupon = (id, item) => {
    let variantid = item.variantid;
    let currencyrate = currencyRate(localCurrency);

    setVariantId(variantid);
    setCouponId(id);
    let values = {};
    values.couponid = id;
    values.currencyrate = currencyrate;
    values.variantid = variantid;
    values.productid = item.productid;
    props.applyCoupon(session, values, (response) => {
      if (response.success == 1) {
        props.fetchCart(session, (res) => {
          setVariantId(null);
          setCouponId(null);
        });
        toast.success(LocaleStrings.cart_coupon_applied_success);
      } else {
        if (response.errorcode == "error_01") {
          toast.error(LocaleStrings.cart_coupon_error1);
        } else if (response.errorcode == "error_02") {
          toast.error(LocaleStrings.cart_coupon_error2);
        } else if (response.errorcode == "error_03") {
          toast.error(LocaleStrings.cart_coupon_error3);
        } else if (response.errorcode == "error_04") {
          toast.error(LocaleStrings.cart_coupon_error4);
        }
      }
      setVariantId(null);
      setCouponId(null);
    });
  };

  const renderItem = () => {
    let cartItem = redefineCartItem();

    //console.log("cartItem", cartItem);
    return _.map(cartItem, (item, index) => {
      let availablecoupons = [];
      let remainingcoupons = [];
      let itemtoShow = deviceSize.isMobile ? 1 : 2;
      if (item.availablecoupons && item.availablecoupons.length > 0) {
        availablecoupons = item.availablecoupons.slice(0, itemtoShow);
        remainingcoupons = item.availablecoupons.slice(itemtoShow);
      }
      let otheraction =
        parseInt(item.negotiated) != 1 || parseInt(item.addedfrombid) != 1
          ? false
          : true;
      return (
        <div
          className={classNames(
            !item.active || !item.isdeliverable || item.isbanned
              ? "opacity-50 bg-gray-200 "
              : "bg-white opacity-100",
            "  rounded-lg"
          )}
        >
          <div className="flex px-15 py-5 ">
            <div className="pr-2 md:pr-4">
              <input
                type="checkbox"
                checked={
                  item.selected &&
                  item.active &&
                  item.isdeliverable &&
                  !item.isbanned
                }
                onClick={() =>
                  item.active && item.isdeliverable && !item.isbanned
                    ? checkUncheckSelected(item.cartid, item.selected)
                    : null
                }
                className={classNames(
                  item.active && item.isdeliverable && !item.isbanned
                    ? "cursor-pointer"
                    : "cursor-not-allowed",
                  ""
                )}
              />
            </div>
            <div className="pr-2 md:pr-4">
              <div className=" flex justify-center items-center p-2 border border-header w-82 h-82">
                <a
                  href={`/product-details/${
                    _.kebabCase(item.productname_en) +
                    "-" +
                    item.productid +
                    "-" +
                    item.variantid
                  }`}
                  className="cursor-pointer"
                >
                  <img
                    src={`${FILES_URL}/vendor/variations/${item.productimage}?api_key=${APP_API_KEY}`}
                    alt=""
                    //className="w-full object-fill"
                    className=" object-cover cursor-pointer  max-h-14 "
                  />
                </a>
              </div>
            </div>
            <div className="w-11/12">
              <div className="grid grid-cols-3 lg:grid-cols-4 gap-2">
                <div className="col-span-3">
                  <div className="text-18 text-primary font-bold whitespace-nowrap overflow-hidden overflow-ellipsis ">
                    <a
                      href={`/product-details/${
                        _.kebabCase(item.productname_en) +
                        "-" +
                        item.productid +
                        "-" +
                        item.variantid
                      }`}
                      className="cursor-pointer "
                    >
                      {ProductTextLocalized(
                        item.productname_en,
                        item.productname_ar,
                        localLanguage
                      )}
                    </a>
                  </div>
                  <div className="text-base text-specification">
                    {LocaleStrings.cart_sku}:{item.sku}
                  </div>
                  <div className="flex mt-4 items-center">
                    <div className="text-sm md:text-base lg:text-xl font-bold text-primary pr-2 md:pr-4">
                      {CURRENCY}
                      {parseFloat(item.price).toFixed(2)}
                    </div>
                    {Number(item.discount) > 0 ? (
                      <div className="text-10 md:text-sm font-normal text-linethrough line-through pr-2 md:pr-4">
                        {CURRENCY}
                        {parseFloat(item.originalprice).toFixed(2)}
                      </div>
                    ) : (
                      ""
                    )}
                    {Number(item.discount) > 0 ? (
                      <div className="text-10 md:text-sm font-bold text-discount">
                        {CURRENCY}
                        {parseFloat(item.discount).toFixed(1)}
                        {item.discounttype == "percentage" ? "% " : " "}OFF
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {item.active && item.isdeliverable && !item.isbanned ? (
                    <div>
                      <div className="flex">
                        <div className="text-sm md:text-base text-numbid bg-shipping px-3.5 py-2 rounded-3xl mt-2 whitespace-nowrap">
                          + {CURRENCY}
                          {parseFloat(item.finalShippingprice).toFixed(2)}{" "}
                          {LocaleStrings.shipping}
                        </div>
                      </div>
                      {parseInt(item.istaxable) == 1 ? (
                        <div className="text-primary font-normal text-xs mt-1">
                          + {CURRENCY}
                          {parseFloat(item.tax_amount).toFixed(2)}{" "}
                          {LocaleStrings.cart_tax_charges}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : !item.active ? (
                    <div className="w-40 text-sm md:text-base text-red-600 px-3 py-1 mt-2 whitespace-nowrap">
                      {LocaleStrings.out_of_stock}
                    </div>
                  ) : !item.isdeliverable ? (
                    <div className="w-40 text-sm md:text-base text-red-600 px-3 py-1 mt-2 whitespace-nowrap">
                      {LocaleStrings.not_deliverable}
                    </div>
                  ) : item.isbanned ? (
                    <div className="w-40 text-sm md:text-base text-red-600 px-3 py-1 mt-2 whitespace-nowrap">
                      {LocaleStrings.temporarily_unavailable}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className=" col-span-2 lg:col-span-1 flex items-center justify-start lg:justify-center">
                  <div className="bg-quaternary rounded-lg flex items-center justify-center border border-header divide-x divide-header">
                    <div
                      className={classNames(
                        item.selected && item.active && item.isdeliverable
                          ? " cursor-pointer "
                          : "cursor-not-allowed ",
                        "px-3 py-3 flex flex-shrink-0 items-center justify-center text-counter text-base font-bold"
                      )}
                      onClick={() =>
                        item.negotiated == "1" || item.addedfrombid == "1"
                          ? null
                          : item.selected && item.active && item.isdeliverable
                          ? decrementValue(item.cartid, item.quantity)
                          : null
                      }
                    >
                      <img
                        src={MinusIcon}
                        className={classNames(
                          item.selected && item.active && item.isdeliverable
                            ? "opacity-100 "
                            : "opacity-50 ",
                          "w-3 h-3"
                        )}
                      />
                    </div>
                    <div className="px-18 py-3 flex items-center justify-center text-primary text-base font-bold">
                      {item.quantity}
                    </div>
                    <div
                      className={classNames(
                        item.selected && item.active && item.isdeliverable
                          ? " cursor-pointer "
                          : "cursor-not-allowed ",
                        "px-3 py-3 flex flex-shrink-0 items-center justify-center"
                      )}
                      onClick={() =>
                        item.negotiated == "1" || item.addedfrombid == "1"
                          ? null
                          : item.selected && item.active && item.isdeliverable
                          ? incrementValue(item.cartid, item.quantity)
                          : null
                      }
                    >
                      <img
                        src={PlusIcon}
                        className={classNames(
                          item.selected && item.active && item.isdeliverable
                            ? "opacity-100 "
                            : "opacity-50 ",
                          "w-3 h-3"
                        )}
                      />
                    </div>
                  </div>

                  {/* <div
                  className="flex items-center justify-end text-link text-sm cursor-pointer"
                  onClick={() => removeItem(item.cartid)}
                >
                  {LocaleStrings.cart_remove}
                </div> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`mt-18 border-t border-header p-3.5   md:flex items-center justify-between`}
          >
            <div
              className="flex items-center cursor-pointer"
              onClick={() => removeItem(item.cartid)}
            >
              <div className="pr-1.5">
                <img src={RemoveIcon} />
              </div>
              <div className="text-base text-specification font-medium">
                {LocaleStrings.cart_remove}
              </div>
            </div>
            {availablecoupons.length > 0 && !otheraction ? (
              <div
                className={`mt-3 md:mt-0 flex flex-shrink-0 items-center flex-wrap gap-3 md:gap-1`}
              >
                <div className="flex items-center justify-start md:justify-end">
                  <div className="pr-1.5">
                    <img src={CouponIcon} />
                  </div>
                  <div className="text-base text-specification font-medium">
                    {LocaleStrings.product_coupons}
                  </div>
                </div>
                {availablecoupons.map((couponitem, position) => {
                  return (
                    <div
                      className={classNames(
                        item.couponid == couponitem.id
                          ? item.selected && item.active && item.isdeliverable
                            ? "bg-couponapplied border-couponapplied text-couponapplied flex items-center justify-start md:justify-between opacity-100"
                            : "bg-couponapplied border-couponapplied text-couponapplied flex items-center justify-start md:justify-between opacity-50"
                          : item.selected && item.active && item.isdeliverable
                          ? "bg-couponavailable border-couponavailable text-couponavailable cursor-pointer opacity-100"
                          : "bg-couponavailable border-couponavailable text-couponavailable cursor-not-allowed opacity-50",
                        " rounded border border-dashed px-2.5 py-1.5 text-sm md:text-base font-medium ml-0 md:ml-3 "
                      )}
                    >
                      <div
                        onClick={() =>
                          item.couponid == couponitem.id
                            ? null
                            : item.selected && item.active && item.isdeliverable
                            ? applycoupon(couponitem.id, item)
                            : null
                        }
                        className={
                          couponId == couponitem.id ||
                          item.couponid == couponitem.id
                            ? "flex items-center group relative"
                            : "group relative"
                        }
                      >
                        <span
                          className={
                            couponId == couponitem.id ||
                            item.couponid == couponitem.id
                              ? "pr-1"
                              : ""
                          }
                        >
                          {couponitem.code}
                        </span>
                        {couponId == couponitem.id ? (
                          <CircleSpinner
                            size={20}
                            color={"#009CDE"}
                            loading={true}
                          />
                        ) : null}

                        <div class="group-hover:opacity-100 transition-opacity p-2 top-5 left-6 -translate-x-1/2 translate-y-full absolute w-72 rounded-md z-50 bg-white  shadow-lg opacity-0">
                          <div className="">
                            <span className="text-numbid font-normal text-sm">
                              Description:&nbsp;
                            </span>
                            <span className="text-sold font-normal text-sm">
                              {couponitem.coupon_desc}
                            </span>
                          </div>
                          <div className="">
                            <span className="text-numbid font-normal text-sm">
                              Discount:&nbsp;
                            </span>
                            <span className="text-sold font-normal text-sm">
                              {couponitem.discount}
                              {couponitem.discounttype == "percentage"
                                ? "%"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      {item.couponid == couponitem.id ? (
                        <div
                          className="pl-2.5 cursor-pointer"
                          onClick={() => removeCoupon(item.couponid)}
                        >
                          <img src={CouponRemove} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
                {remainingcoupons.length > 0 ? (
                  <div className=" ml-0 md:ml-3">
                    <select
                      className="w-full h-10 bg-review_search border border-header rounded-md text-review_search_text"
                      onChange={(e) => couponSelect(e, item)}
                    >
                      <option value="">+{remainingcoupons.length} more</option>
                      {remainingcoupons.map((couponitem, position) => {
                        return (
                          <option value={couponitem.id}>
                            {couponitem.code}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    });
  };

  const calculateShipping = () => {
    let totalShippingCost = 0.0;

    _.map(getUserCart.items, (cartObj, index) => {
      if (cartObj.selected == true) {
        let productShipping =
          parseFloat(cartObj.shippingprice) * parseFloat(cartObj.quantity);
        totalShippingCost =
          parseFloat(totalShippingCost) + parseFloat(productShipping);
      }
    });
    return CURRENCY + totalShippingCost.toFixed(1);
  };

  const cartItemShippingPrice = (address, cartitem) => {
    if (cartitem.shipping_type == "calculated") {
      return cartitem.partner_shipping_price
        ? cartitem.partner_shipping_price
        : cartitem.converted_shippingprice;
      // if (
      //   cartitem.deliverablearea &&
      //   cartitem.deliverablearea.length > 0 &&
      //   address.length > 0
      // ) {
      //   if (address[0].zip) {
      //     let pincodeArr = _.filter(cartitem.deliverablearea, function (obj) {
      //       return obj.pin_code == address[0].zip;
      //     });
      //     return pincodeArr.length > 0
      //       ? pincodeArr[0]["charges"]
      //       : cartitem.converted_shippingprice;
      //   } else if (address[0].zoneno) {
      //     let zoneArr = _.filter(cartitem.deliverablearea, function (obj) {
      //       return obj.zone == address[0].zoneno;
      //     });
      //     return zoneArr.length > 0
      //       ? zoneArr[0]["charges"]
      //       : cartitem.converted_shippingprice;
      //   } else {
      //     return cartitem.converted_shippingprice;
      //   }
      // } else {
      //   return cartitem.converted_shippingprice;
      // }
    } else {
      return cartitem.converted_shippingprice;
    }
  };

  const isProductDeliverable = (address, cartitem) => {
    // console.log("address", address);
    // console.log("cartitem", cartitem);

    if (
      cartitem.product_exclude_country &&
      cartitem.product_exclude_country.length > 0 &&
      address.length > 0
    ) {
      if (address[0].country_id) {
        let countryArr = _.filter(
          cartitem.product_exclude_country,
          function (obj) {
            return obj == address[0].country_id;
          }
        );
        return countryArr.length > 0 ? false : true;
      } else {
        return false;
      }
    } else {
      return true;
    }

    // if (
    //   cartitem.deliverablearea &&
    //   cartitem.deliverablearea.length > 0 &&
    //   address.length > 0
    // ) {
    //   if (address[0].zip) {
    //     let pincodeArr = _.filter(cartitem.deliverablearea, function (obj) {
    //       return obj.pin_code == address[0].zip;
    //     });
    //     return pincodeArr.length > 0 ? true : false;
    //   } else if (address[0].zoneno) {
    //     let zoneArr = _.filter(cartitem.deliverablearea, function (obj) {
    //       return obj.zone == address[0].zoneno;
    //     });

    //     return zoneArr.length > 0 ? true : false;
    //   } else {
    //     return false;
    //   }
    // } else {
    //   return true;
    // }
  };

  const updateCalculations = () => {
    let numProducts = 0;
    let grandTotal = 0.0;
    let totalPrice = 0.0;
    let applicableCouponTotal = 0.0;
    let totalOriginalPrice = 0.0;
    let totalDiscount = 0.0;
    let totalShippingCost = 0.0;
    let totalCouponDiscount = 0.0;
    let totalTaxes = 0.0;

    let mincartcoupons = [];
    let shipping_free_amount = 0.0;

    let currencyconversionrate = getUserCart?.currencyrate;

    let cartItem = redefineCartItem();
    shipping_free_amount = cartItem[0].shipping_free_amount;

    let sellerGroup = cartItem.reduce(function (r, a) {
      r[a.seller.userid] = r[a.seller.userid] || [];
      r[a.seller.userid].push(a);
      return r;
    }, Object.create(null));

    _.map(sellerGroup, (selObj, index) => {
      let productShipping = 0;
      let shippingChargesObj = _.filter(selObj, function (obj) {
        return obj.selected && obj.active && obj.isdeliverable;
      });
      if (shippingChargesObj.length > 0) {
        let maxValObj = _.maxBy(shippingChargesObj, "finalShippingprice");
        productShipping = maxValObj.finalShippingprice
          ? maxValObj.finalShippingprice
          : 0;
      }

      totalShippingCost = totalShippingCost + parseFloat(productShipping);
    });

    //console.log("cartItem updateCalculations", cartItem);

    _.map(cartItem, (cartObj, index) => {
      //console.log("cartObj", cartObj);
      if (cartObj.selected && cartObj.active && cartObj.isdeliverable) {
        let appliedCoupon = cartObj?.coupon;
        let productOriginalPrice =
          cartObj.negotiated == "1"
            ? parseFloat(cartObj.originalprice)
            : parseFloat(cartObj.originalprice) * parseFloat(cartObj.quantity);
        totalOriginalPrice =
          parseFloat(totalOriginalPrice) + parseFloat(productOriginalPrice);

        let productTotalPrice =
          cartObj.negotiated == "1"
            ? parseFloat(cartObj.price)
            : parseFloat(cartObj.price) * parseFloat(cartObj.quantity);
        totalPrice = parseFloat(totalPrice) + parseFloat(productTotalPrice);

        let productTax =
          parseInt(cartObj.istaxable) == 1 ? parseFloat(cartObj.tax_amount) : 0;
        totalTaxes = parseFloat(totalTaxes) + parseFloat(productTax);

        numProducts = numProducts + parseInt(cartObj.quantity);
        if (
          appliedCoupon &&
          appliedCoupon.id &&
          cartObj.couponid == appliedCoupon.id
        ) {
          if (cartObj.coupon) {
            let coupon = cartObj.coupon;
            if (coupon.criteria && coupon.criteria.rule) {
              //if (coupon.criteria.rule == "general") {
              let discounttype = coupon.discounttype;
              let discount = coupon.discount;
              //console.log("discount", discount);
              if (discounttype == "fixed") {
                totalCouponDiscount =
                  parseFloat(totalCouponDiscount) + parseFloat(discount);
              } else {
                let discountPer =
                  parseFloat(discount / 100) * parseFloat(productTotalPrice);
                //console.log("discountPer", discountPer);
                totalCouponDiscount =
                  parseFloat(totalCouponDiscount) + parseFloat(discountPer);
              }
              //console.log("totalCouponDiscount", totalCouponDiscount);
              // } else {
              //   applicableCouponTotal =
              //     parseFloat(applicableCouponTotal) +
              //     parseFloat(productTotalPrice);
              //   mincartcoupons.push(coupon);
              // }
            }
          }
        }
      }
    });

    totalDiscount = totalOriginalPrice - totalPrice;
    //console.log("mincartcoupons", mincartcoupons);
    if (totalPrice != onlyPrice) {
      setOnlyPrice(totalPrice);
    }
    if (shipping_free_amount > 0) {
      let revisedPrice = totalPrice + totalTaxes;
      if (revisedPrice >= shipping_free_amount) {
        totalShippingCost = 0.0;
      }
    }

    grandTotal =
      Math.max(0, totalPrice - totalCouponDiscount) +
      totalShippingCost +
      totalTaxes;
    if (grandTotal != totalCal) {
      setTotalCal(grandTotal);
    }

    let summeryHtml = (
      <>
        <div className="grid grid-cols-4 mb-2">
          <div className="col-span-3 text-sold text-base">
            {LocaleStrings.cart_product_price + "(" + numProducts + ")"}
          </div>
          <div className="text-right text-footers text-base">
            {CURRENCY}
            {totalOriginalPrice.toFixed(2)}
          </div>
        </div>
        <div className="grid grid-cols-4 mb-2">
          <div className="col-span-3 text-sold text-base">
            {LocaleStrings.cart_product_discount}
          </div>
          <div className="text-right text-couponapplied text-base">
            - {CURRENCY}
            {totalDiscount == 0.0 ? 0.0 : totalDiscount.toFixed(2)}
          </div>
        </div>
        {totalCouponDiscount > 0 ? (
          <div className="grid grid-cols-4 mb-2">
            <div className="col-span-3 text-sold text-base">
              {LocaleStrings.cart_coupon_discount}
            </div>
            <div className="text-right text-primary font-medium text-xs">
              - {CURRENCY}
              {totalCouponDiscount == 0.0
                ? 0.0
                : totalCouponDiscount.toFixed(2)}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="grid grid-cols-4 mb-2">
          <div className="col-span-3 text-sold text-base">
            {LocaleStrings.cart_shipping_fee}
          </div>
          <div className="text-right text-footers text-base">
            {CURRENCY}
            {totalShippingCost.toFixed(2)}
          </div>
        </div>
        {totalTaxes > 0 ? (
          <div className="grid grid-cols-4 pb-2 mb-2 border-b border-primary">
            <div className="col-span-3 text-sold text-base">
              {LocaleStrings.cart_tax_charges}
            </div>
            <div className="text-right text-footers text-base">
              {CURRENCY}
              {totalTaxes == 0.0 ? 0.0 : totalTaxes.toFixed(2)}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="grid grid-cols-4">
          <div className="col-span-3 text-primary text-xl font-bold">
            {LocaleStrings.cart_total}
          </div>
          <div className="text-right text-primary text-xl font-bold">
            {CURRENCY}
            {grandTotal.toFixed(2)}
          </div>
        </div>
        {CURRENCY == "USD" ? (
          <div className="text-right text-cardfooter font-normal text-xl">
            (= QAR
            {priceConversion(
              CURRENCY,
              "QAR",
              grandTotal,
              currencyconversionrate
            )}
            )
          </div>
        ) : (
          ""
        )}
        <div className="mb-8 text-right text-cardfooter font-normal text-10">
          {LocaleStrings.inclusive_text}
        </div>
      </>
    );

    return summeryHtml;
  };

  const getAvailableCoupon = () => {
    let selectedItem = _.filter(getUserCart.items, function (obj) {
      return obj.selected;
    });
    if (selectedItem.length > 0) {
      props.availableCoupons(session, (res) => {});
      props.isCouponlistModalOpen(true);
    } else {
      toast.error(LocaleStrings.cart_item_not_selected);
    }
  };

  const onSubmitForm = (values) => {
    //console.log("values", values);
    if (values.actionaftersave == "checkout") {
      setLoading(true);
      let cartValidate = true;
      // let arrPromise = [];
      // arrPromise.push(
      //   new Promise((resolve, reject) => {
      //     props.fetchCart(session, (res) => {
      //       let selectedItem = [];
      //       let bannedItem = [];
      //       if (res.success && res.data && res.data.length > 0) {
      //         selectedItem = _.filter(res.data, function (obj) {
      //           return obj.selected;
      //         });

      //         bannedItem = _.filter(res.data, function (obj) {
      //           return !obj.active;
      //         });

      //         //console.log("selectedItem", selectedItem);
      //         //console.log("bannedItem", bannedItem);

      //         if (bannedItem.length > 0) {
      //           if (selectedItem.length > 0) {
      //             cartValidate = true;
      //             resolve();
      //           } else {
      //             cartValidate = false;
      //             resolve();
      //           }
      //         } else if (selectedItem.length > 0) {
      //           cartValidate = true;
      //           resolve();
      //         }
      //       } else {
      //         cartValidate = false;
      //         resolve();
      //       }
      //     });
      //   })
      // );

      //console.log("arrPromise", arrPromise);

      //Promise.all(arrPromise).then(() => {
      if (cartValidate) {
        let orderData = {};
        if (!values.paymentmethod) {
          values.paymentmethod = "cash";
        }
        if (totalCal > 0) {
          values.paymentmethod = values.paymentmethod;
        } else {
          values.paymentmethod = "cash";
        }
        orderData.paymentmethod = values.paymentmethod;
        orderData.callfrom = "web";

        if (values.sameas) {
          orderData.total_amount = totalCal;
          orderData.address_id = shippingAddress[0].id;
          orderData.address_billing_same = 1;
          makeOrder(orderData, orderData.address_id, false); //  true for bypass , false for redirect to  payment gateway
        } else {
          let billing_addressid = billingAddress[0].billing_addressid;
          orderData.total_amount = totalCal;
          orderData.address_id = shippingAddress[0].id;
          orderData.address_billing_same = 0;
          orderData.billing_addressid = billing_addressid;
          makeOrder(orderData, orderData.billing_addressid, false); //  true for bypass , false for redirect to  payment gateway
        }
      } else {
        setLoading(false);
        toast.error(LocaleStrings.order_not_proceed);
      }
      //});
    } else if (values.actionaftersave == "address") {
      //console.log("values", values);
      // debugger;
      if (values.sameas) {
        setStep(3);
      } else {
        setLoading(true);
        values.useridfk = session.userid;
        values.isbillingaddress = 1;
        if (values.phone) {
          values.phone = values.phone.replace(/[- )( +]/g, "");
        }

        let billingAddress = _.filter(userAddressAll, function (o) {
          return o.isbillingaddress;
        });

        if (values.phone) {
          values.phone = values.phone.replace(/[- )( +]/g, "");
        }

        if (billingAddress.length > 0) {
          props.updateAddress(session, values, initialValues.id, (res) => {
            if (res.success == 1) {
              let billingAddress = [
                {
                  billing_addressid: initialValues.id,
                  username: values.username,
                  address: values.address,
                  addresstype: values.addresstype,
                },
              ];

              setBillingAddress(billingAddress);
              setStep(3);
              setLoading(false);
            } else {
              setLoading(false);
              toast.error(LocaleStrings.common_fail_message);
            }
          });
        } else {
          props.addAddress(session, values, (res) => {
            if (res.success == 1) {
              let billing_addressid = res.addressid;
              let billingAddress = [
                {
                  billing_addressid: billing_addressid,
                  username: values.username,
                  address: values.address,
                  addresstype: values.addresstype,
                },
              ];

              setBillingAddress(billingAddress);
              setStep(3);
              setLoading(false);
            } else {
              setLoading(false);
              toast.error(LocaleStrings.common_fail_message);
            }
          });
        }
      }
    }
  };

  const makeOrder = (orderData, address_id, bypass) => {
    props.createOrder(session, orderData, (response) => {
      //console.log("response", response);
      if (response.success == 1) {
        if (orderData.paymentmethod == "cash") {
          toast.success(LocaleStrings.order_placed_success);
          history.push("/myorders");
        } else {
          //console.log("response", response);
          if (response.checkout_url) {
            window.location.href = response.checkout_url;
          } else {
            setLoading(false);
            toast.error(LocaleStrings.common_fail_message);
          }

          // let orderids = response.orderids;
          // // console.log("orderids", orderids);
          // // Redirect to Payment Gateway
          // let products = [];
          // _.map(getUserCart.items, (item, index) => {
          //   let obj = {};
          //   obj.productname_en = item.productname_en;
          //   obj.price = item.price;
          //   obj.quantity = item.quantity;
          //   products.push(obj);
          // });
          // var params = [];
          // params["total_amount"] = totalCal / CURRENCY_RATE;
          // params["userid"] = session.userid;
          // params["orderids"] = orderids;
          // //params["total_amount"] = totalCal / CURRENCY_RATE;
          // params["products"] = JSON.stringify(products);
          // params["origin"] = "web";
          // params["address_id"] = address_id;
          // openPost("https://shopez.teks.co.in/payment/pay.php", params);
        }
      } else {
        setLoading(false);
        toast.error(LocaleStrings.common_fail_message);
      }
    });
  };

  const openPost = (url, params) => {
    var formElement = document.createElement("form");
    formElement.setAttribute("method", "post");
    formElement.setAttribute("action", url);
    formElement.setAttribute("target", "_parent");

    for (let param in params) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("name", param);
      hiddenField.setAttribute("value", params[param]);
      formElement.appendChild(hiddenField);
    }

    document.body.appendChild(formElement);
    formElement.submit();
  };

  const phoneChange = (value, country, e, formattedValue) => {
    //console.log("country", country);
    setPhone(value);
    setCountry(country.dialCode);
    props.setdefaultCountry(country.countryCode);
    // let pLength = phoneCount(country.format, ".");
    // if (country.countryCode == "qa") {
    //   pLength = 11;
    // }
    props.countryPhoneLength(country.dialCode);
  };

  const billingSame = (e) => {
    if (e.target.checked && userAddressAll.length > 0) {
      setShowBilling(false);
      setSameAsBilling(true);
    } else {
      setShowBilling(true);
      setSameAsBilling(false);
      props.autofill("sameas", 0);
    }
  };

  const removeCoupon = (couponid) => {
    props.removeCouponCode(session, couponid, (res) => {
      props.fetchCart(session, (res) => {});
    });
  };

  const submitAction = (key) => {
    props.autofill("actionaftersave", key);
  };

  const gotoReview = () => {
    if (getUserCart?.items?.length > 0 && getUserCart.items[0].addressid) {
      setStep(2);
    } else {
      toast.error(LocaleStrings.cart_add_address);
    }
  };

  const changeStep = (target) => {
    if (target == 2 && step < target) {
      let selectedItem = [];
      if (getUserCart?.items?.length > 0) {
        selectedItem = _.filter(getUserCart.items, function (obj) {
          return obj.selected;
        });
      }

      if (selectedItem.length > 0) {
        setStep(2);
      }
    } else if (target == 2 && target < step) {
      setStep(2);
    } else if (target == 1 && target < step) {
      setStep(1);
    } else if (target == 3 && (sameAsBilling || billingAddress.length > 0)) {
      setStep(3);
    }
  };

  const changeAddress = (e) => {
    setAddressid(e.target.value);
  };

  const addNewAddress = () => {
    props.fetchCountry(props.session);
    props.editUserAddress({});
    props.isNewAddressModalOpen(true);
  };

  const editAddress = (item) => {
    //console.log("item", item);
    props.fetchCountry(props.session);
    props.editUserAddress(item);
    props.isNewAddressModalOpen(true);
    setEditCountryId(item.country_id);
  };

  const shippingAddresChange = (e) => {
    let address = _.filter(userAddressAll, function (obj) {
      return obj.id == e.target.value;
    });
    setShippingAddress(address);
  };

  const addressList = (item) => {
    return item.isbillingaddress == 0 ? (
      <div className="w-full bg-white rounded-lg ">
        <div
          className="flex text-primary font-normal text-base px-5 py-3 mb-5 h-28 cursor-pointer"
          onClick={() => addressConfirm(item.id)}
        >
          <div className="pr-4">
            <label>
              <input
                name="shippingid"
                type="radio"
                value={item.id}
                checked={
                  shippingAddress.length > 0 && item.id == shippingAddress[0].id
                    ? true
                    : false
                }
              />
              <span className="radiomark"></span>
            </label>
            {/* <Field
              name="shippingid"
              label={null}
              lebelAlign="R"
              type="radio"
              component={renderFieldRadio1}
            /> */}
          </div>
          <div>
            <div
              className={classNames(
                shippingAddress.length > 0 && shippingAddress[0].id == item.id
                  ? "text-link"
                  : "text-primary",
                "text-lg font-bold"
              )}
            >
              {_.startCase(item.addresstype)}
              {" : "}
              {item.username}
            </div>
            {item.buildingno ? (
              <>
                {item.buildingno ? (
                  <div className="text-sold text-base">{`${item.buildingno}${
                    item?.zone_master_by_zoneno?.zone
                      ? "," + item?.zone_master_by_zoneno?.zone
                      : ""
                  },${item.poboxno}`}</div>
                ) : (
                  ""
                )}
              </>
            ) : (
              <div className="text-sold text-base">{item.address}</div>
            )}
          </div>
        </div>
        <div
          className="px-5 py-4 border-t border-header flex items-center cursor-pointer"
          onClick={() => editAddress(item)}
        >
          <div className="pr-2">
            <img src={EditIcon} />
          </div>
          <div className="text-specification font-medium text-base">Edit</div>
        </div>
      </div>
    ) : (
      ""
    );
  };

  const addressConfirm = (newaddressid) => {
    //console.log("newaddressid", newaddressid);
    // let address = _.filter(userAddressAll, function (obj) {
    //   return obj.id == e.target.value;
    // });
    // setShippingAddress(address);
    //let newaddressid = e.target.value;

    confirmAlert({
      title: LocaleStrings.make_default_title,
      message: LocaleStrings.make_default_msg,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            let values = {};
            values.defaultaddress = 1;
            let id = parseInt(newaddressid);
            let updateResource = [];
            _.map(getUserCart.items, (item, index) => {
              let obj = {
                id: item.cartid,
                addressid: id,
              };
              updateResource.push(obj);
            });

            props.makeDefaultAddress(session, values, id, (res) => {
              if (res.success == 1) {
                props.fetchCart(session, (res) => {
                  let defaultaddress = _.filter(userAddressAll, function (obj) {
                    return obj.id == id;
                  });
                  if (defaultaddress.length > 0) {
                    setShippingAddress(defaultaddress);
                  }
                });
              } else {
                toast.error(LocaleStrings.common_fail_message);
              }
            });
          },
        },
        {
          label: LocaleStrings.button_once,
          onClick: () => {
            let id = parseInt(newaddressid);
            let updateResource = [];
            _.map(getUserCart.items, (item, index) => {
              let obj = {
                id: item.cartid,
                addressid: id,
              };
              updateResource.push(obj);
            });
            props.updateCart(session, updateResource, (res) => {
              if (res.success == 1) {
                props.fetchCart(session, (res) => {
                  let defaultaddress = _.filter(userAddressAll, function (obj) {
                    return obj.id == id;
                  });
                  if (defaultaddress.length > 0) {
                    setShippingAddress(defaultaddress);
                  }
                });
              } else {
                toast.error(LocaleStrings.common_fail_message);
              }
            });
          },
        },
      ],
    });
  };

  const redefineCartItem = () => {
    let cartItem = [];
    if (getUserCart?.items?.length > 0) {
      _.map(getUserCart.items, (cartObj, index) => {
        let isdeliverable = isProductDeliverable(shippingAddress, cartObj);
        let finalShippingprice = cartItemShippingPrice(
          shippingAddress,
          cartObj
        );
        cartObj.isdeliverable = isdeliverable;
        cartObj.finalShippingprice = finalShippingprice;
        cartItem.push(cartObj);
      });
    }
    return cartItem;
  };

  const handleCountry = (e) => {
    let countryid = e.target.value;
    //console.log("countryid", countryid);
    setCountryId(countryid);
  };

  //console.log("appliedCoupon", appliedCoupon);
  //console.log("step", step);
  let address = [];
  if (getUserCart?.items?.length > 0 && userAddressAll?.length > 0) {
    address = _.filter(userAddressAll, function (obj) {
      return obj.id == getUserCart.items[0].addressid;
    });
  }
  //console.log("userAddressAll", userAddressAll);
  let selectedItem = [];
  let cartItem = redefineCartItem();
  if (cartItem.length > 0) {
    selectedItem = _.filter(cartItem, function (obj) {
      return obj.selected && obj.active && obj.isdeliverable && !obj.isbanned;
    });
  }

  let isCashonDelivery = false;
  if (selectedItem.length > 0) {
    let cashArr = _.filter(selectedItem, function (obj) {
      return obj.cashondelivery == 0;
    });
    isCashonDelivery = cashArr.length > 0 ? false : true;
  }
  //console.log({ isCashonDelivery });
  let countryOptions = [];
  if (countryList?.length > 0) {
    countryList.map((item, index) => {
      let obj = { name: item.name, value: item.id };
      countryOptions.push(obj);
    });
  }

  const homeTypeOptions = [
    { text: "Home", value: "home" },
    { text: "Office", value: "office" },
    { text: "Other", value: "other" },
  ];

  let zoneOptions = [];
  if (zoneList?.length > 0) {
    zoneList.map((item, index) => {
      let obj = { name: item.zone, value: item.id };
      zoneOptions.push(obj);
    });
  }

  let ifBillingOnly = false;
  if (userAddressAll.length > 0) {
    let billingAddress = _.filter(userAddressAll, function (o) {
      return o.isbillingaddress;
    });
    if (billingAddress.length == 0 || userAddressAll.length > 1) {
      ifBillingOnly = true;
    }
  }
  //console.log("shippingAddress", shippingAddress);
  return (
    <>
      {getUserCart && getUserCart.items ? (
        getUserCart.items.length > 0 ? (
          <>
            <form onSubmit={handleSubmit(onSubmitForm)}>
              <Field
                name="actionaftersave"
                type="text"
                component={renderHiddenFieldText}
              />
              <div className="bg-cart py-4">
                <div className="custom_container">
                  <div className="mb-2">
                    <div className="flex items-center">
                      <div
                        className="text-link text-base cursor-pointer"
                        onClick={() => history.push("/")}
                      >
                        {LocaleStrings.breadcrumb_home}
                      </div>
                      <div className="px-15">
                        <img src={RightArrow} />
                      </div>
                      <div className="text-specification text-base">
                        {LocaleStrings.cart_breadcrumb}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3.5">
                    <div className="text-numbid text-25 font-bold">
                      {step == 1
                        ? LocaleStrings.cart_text
                        : step == 2
                        ? LocaleStrings.cart_shipping_address
                        : LocaleStrings.cart_payment}
                    </div>
                  </div>
                  <div className="bg-white py-18 rounded-lg flex items-center justify-center px-2">
                    <div
                      className="flex justify-center items-center cursor-pointer"
                      onClick={() => changeStep(1)}
                    >
                      <div
                        className={classNames(
                          step == 1 || step == 2 || step == 3
                            ? "bg-btn"
                            : "bg-qalogo",
                          "w-5 h-5 rounded-full flex items-center justify-center text-white mr-3 text-xs font-medium"
                        )}
                      >
                        1
                      </div>
                      <div
                        className={classNames(
                          step == 1 || step == 2 || step == 3
                            ? "text-link font-medium"
                            : "text-sold font-normal",
                          "text-base"
                        )}
                      >
                        {LocaleStrings.step1}
                      </div>
                    </div>
                    <div className="px-2 md:px-4">
                      <img src={CartArrow} />
                    </div>
                    <div>
                      <div
                        className="flex justify-center items-center cursor-pointer"
                        onClick={() => changeStep(2)}
                      >
                        <div
                          className={classNames(
                            step == 2 || step == 3 ? "bg-btn" : "bg-qalogo",
                            "w-5 h-5 rounded-full flex items-center justify-center text-white mr-3 text-xs font-medium"
                          )}
                        >
                          2
                        </div>
                        <div
                          className={classNames(
                            step == 2 || step == 3
                              ? "text-link font-medium"
                              : "text-sold font-normal",
                            "text-base"
                          )}
                        >
                          {LocaleStrings.step2}
                        </div>
                      </div>
                    </div>
                    <div className="px-2 md:px-4">
                      <img src={CartArrow} />
                    </div>
                    <div>
                      <div
                        className="flex justify-center items-center cursor-pointer"
                        onClick={() => changeStep(3)}
                      >
                        <div
                          className={classNames(
                            step == 3 ? "bg-btn" : "bg-qalogo",
                            "w-5 h-5 rounded-full flex items-center justify-center text-white mr-3 text-xs font-medium"
                          )}
                        >
                          3
                        </div>
                        <div
                          className={classNames(
                            step == 3
                              ? "text-link font-medium"
                              : "text-sold font-normal",
                            "text-base"
                          )}
                        >
                          {LocaleStrings.step3}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="block md:flex mt-8">
                    <div className="w-full md:w-cartleft mb-4 md:mr-4">
                      {step == 2 ? (
                        <>
                          <div className=" px-15 font-medium text-sm text-primary mb-4">
                            <div className="text-base text-primary font-bold">
                              {LocaleStrings.cart_select_shipping_address}
                            </div>
                          </div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-h-391 overflow-y-auto">
                            {userAddressAll.length > 0
                              ? userAddressAll.map((item, index) => {
                                  return addressList(item);
                                })
                              : ""}
                            <div
                              className="w-full bg-white  flex items-center justify-center h-188 rounded-lg cursor-pointer"
                              onClick={() => addNewAddress()}
                            >
                              + New Address
                            </div>
                          </div>

                          <div className="font-medium text-sm text-primary mb-4 mt-9">
                            <div className="text-base text-primary font-bold">
                              {LocaleStrings.cart_billing_address}
                            </div>
                          </div>

                          <div className="bg-white rounded mb-4">
                            <div className="pb-5">
                              <div className="px-15 py-5">
                                {userAddressAll.length > 0 &&
                                shippingAddress.length > 0 ? (
                                  <Field
                                    name="sameas"
                                    label={LocaleStrings.same_as_shipping}
                                    type="checkbox"
                                    className="focus:ring-primary h-4 w-4 text-primary border-ternary rounded"
                                    component={renderFieldCheckbox}
                                    onChange={(e) => billingSame(e)}
                                    classNameLabel="text-primary text-sm font-normal"
                                  />
                                ) : (
                                  <div class="relative flex items-start">
                                    <div class="flex items-center h-5">
                                      <input
                                        type="checkbox"
                                        name="sameas"
                                        class="focus:ring-primary h-4 w-4 text-primary border-ternary rounded"
                                        value=""
                                        disabled
                                      />
                                    </div>
                                    <div class="ml-3 text-sm">
                                      <label
                                        for="candidates"
                                        class="text-primary text-sm font-normal"
                                      >
                                        Same as Shipping address
                                      </label>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {showBilling ? (
                                <div id="billingad">
                                  <div className="px-15 py-sm grid grid-cols-3">
                                    {_.map(homeTypeOptions, (opt, index) => {
                                      return (
                                        <Field
                                          name="addresstype"
                                          label={opt.text}
                                          value={opt.value}
                                          //checked={index == 0 ? true : false}
                                          //radioOnchange={radioOnchange}
                                          lebelAlign="R"
                                          type="radio"
                                          component={renderFieldRadio1}
                                        />
                                      );
                                    })}
                                  </div>

                                  <div className="px-15 py-sm">
                                    <Field
                                      name="username"
                                      placeholder={LocaleStrings.contact_name}
                                      label={LocaleStrings.contact_name}
                                      type="text"
                                      component={renderFieldText}
                                      className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                                    />
                                  </div>
                                  <div className="px-15 py-sm">
                                    <Field
                                      name="phone"
                                      placeholder={LocaleStrings.phone_no}
                                      label={LocaleStrings.phone_no}
                                      type="text"
                                      phone={
                                        initialValues && initialValues.phone
                                          ? initialValues.phone
                                          : ""
                                      }
                                      phoneChange={phoneChange}
                                      component={renderPhonewithFlag}
                                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                                    />
                                  </div>
                                  <div className="px-15 py-sm">
                                    <Field
                                      name="country_id"
                                      placeholder="Select from options"
                                      label={LocaleStrings.country}
                                      opts={countryOptions}
                                      component={renderSelect}
                                      onChange={(e) => handleCountry(e)}
                                      className="appearance-none block w-full h-38 px-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                                    />
                                  </div>
                                  {countryid == 187 || countryid == null ? (
                                    <>
                                      <div className="px-15 py-sm">
                                        <Field
                                          name="buildingno"
                                          placeholder={LocaleStrings.buildingno}
                                          label={LocaleStrings.buildingno}
                                          type="text"
                                          component={renderFieldText}
                                          className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                                        />
                                      </div>
                                      <div className="px-15 py-sm">
                                        <Field
                                          name="zoneno"
                                          placeholder="Select from options"
                                          label={LocaleStrings.zoneno}
                                          opts={zoneOptions}
                                          component={renderSelect}
                                          className="appearance-none block w-full h-38 px-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                                        />
                                      </div>
                                      <div className="px-15 py-sm">
                                        <Field
                                          name="streetno"
                                          placeholder={LocaleStrings.streetno}
                                          label={LocaleStrings.streetno}
                                          type="text"
                                          component={renderFieldText}
                                          className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                                        />
                                      </div>
                                      <div className="px-15 py-sm">
                                        <Field
                                          name="unitno"
                                          placeholder={LocaleStrings.unitno}
                                          label={LocaleStrings.unitno}
                                          type="text"
                                          component={renderFieldText}
                                          className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                                        />
                                      </div>
                                      <div className="px-15 py-sm">
                                        <Field
                                          name="poboxno"
                                          placeholder={LocaleStrings.poboxno}
                                          label={LocaleStrings.poboxno}
                                          type="text"
                                          component={renderFieldText}
                                          className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="px-15 py-sm">
                                        <Field
                                          name="address"
                                          placeholder={LocaleStrings.address}
                                          label={LocaleStrings.address}
                                          type="textarea"
                                          component={renderFieldTextarea}
                                          className="appearance-none block w-full px-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                                        />
                                      </div>
                                      <div className="px-15 py-sm grid grid-cols-2 gap-4">
                                        <div>
                                          <Field
                                            name="city"
                                            placeholder={LocaleStrings.city}
                                            label={LocaleStrings.city}
                                            type="text"
                                            component={renderFieldText}
                                            className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                                          />
                                        </div>
                                        <div>
                                          <Field
                                            name="zip"
                                            placeholder={LocaleStrings.zip}
                                            label={LocaleStrings.zip}
                                            type="text"
                                            component={renderFieldText}
                                            className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                                          />
                                        </div>
                                      </div>
                                      <div className="px-15 py-sm">
                                        <Field
                                          name="state"
                                          placeholder={LocaleStrings.state}
                                          label={LocaleStrings.state}
                                          type="text"
                                          component={renderFieldText}
                                          className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>
                      ) : step == 3 ? (
                        <>
                          <div className=" px-15 font-medium text-sm text-primary mb-4">
                            <div className="text-base text-primary font-bold">
                              {LocaleStrings.cart_payment_method}
                            </div>
                          </div>
                          <div className="bg-white rounded-lg flex items-center justify-between px-5 py-3 mb-5">
                            <div className="flex items-center">
                              <div className="pr-4 pt-1.5">
                                <Field
                                  name="paymentmethod"
                                  label={null}
                                  lebelAlign="R"
                                  value={"card"}
                                  type="radio"
                                  component={renderFieldRadio1}
                                />
                              </div>
                              <div className="text-lg text-primary font-bold">
                                {LocaleStrings.cart_payment_card}
                              </div>
                            </div>
                            <div className="flex items-center justify-end">
                              <img src={PayMethod} />{" "}
                            </div>
                          </div>
                          {isCashonDelivery ? (
                            <div className="bg-white rounded-lg  px-5 py-3 mb-5 flex items-center justify-between opacity-100">
                              <div className="flex items-center">
                                <div className="pr-4 pt-1.5">
                                  <Field
                                    name="paymentmethod"
                                    label={null}
                                    lebelAlign="R"
                                    type="radio"
                                    value={"cash"}
                                    component={renderFieldRadio1}
                                  />
                                </div>
                                <div className="text-lg text-primary font-bold">
                                  {LocaleStrings.cart_payment_cash}
                                </div>
                              </div>
                              <div className="flex items-center justify-end">
                                <div className="border border-header rounded w-11  px-2 py-2">
                                  <img src={CashIcon} />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="bg-white rounded-lg  px-5 py-3 mb-5 flex items-center justify-between opacity-60">
                              <div className="flex items-center">
                                <div className="pr-4 pt-1.5">
                                  <Field
                                    name="paymentmethod"
                                    label={null}
                                    lebelAlign="R"
                                    type="radio"
                                    value={"cash"}
                                    disabled={true}
                                    component={renderFieldRadio1}
                                  />
                                </div>
                                <div className="text-lg text-gray-300 font-bold">
                                  {
                                    LocaleStrings.cart_payment_cash_not_available
                                  }
                                </div>
                              </div>
                              <div className="flex items-center justify-end">
                                <div className="border border-header rounded w-11 px-2 py-2">
                                  <img src={CashIcon} className="opacity-60" />
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div className=" px-15 font-medium text-sm text-primary mb-4">
                            <div className="flex">
                              <div className="pr-3">
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedItem.length ==
                                    getUserCart?.items.length
                                      ? true
                                      : false
                                  }
                                  onClick={() =>
                                    checkUncheckAll(
                                      selectedItem.length ==
                                        getUserCart?.items.length
                                        ? true
                                        : false
                                    )
                                  }
                                  className="cursor-pointer"
                                />
                              </div>
                              <div className="text-base text-primary font-bold">
                                (
                                {selectedItem.length +
                                  "/" +
                                  getUserCart?.items.length}
                                ) {LocaleStrings.cart_item_selected}
                              </div>
                            </div>
                          </div>
                          <div className="col-span-2 rounded-lg mb-4">
                            <div className=" space-y-4">{renderItem()}</div>
                          </div>
                          <div className="items-center justify-start hidden lg:flex">
                            <div
                              className="text-link font-medium text-sm cursor-pointer px-6 py-3 border border-header bg-white rounded-md"
                              onClick={() => history.push("/")}
                            >
                              {LocaleStrings.cart_continue_shopping}
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="w-full md:w-cartright">
                      {/* {getUserCart?.items?.length > 0 &&
                      !_.isEmpty(getUserCart.items[0].coupon) ? (
                        <div className="bg-white rounded mb-4">
                          <div className="pl-15 py-15 font-medium text-sm text-primary">
                            {LocaleStrings.cart_coupon_applied}
                          </div>
                          <div className="flex pb-3.5 pl-15">
                            <div className="border-variantSel text-primary  border-dashed border  flex items-center justify-center p-2  text-xs rounded">
                              <div> {getUserCart.items[0].coupon.title} </div>
                              <div
                                className="pl-6 cursor-pointer"
                                onClick={() =>
                                  removeCoupon(getUserCart.items[0].coupon.code)
                                }
                              >
                                X
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )} */}

                      <div className="font-medium text-sm text-primary mb-4">
                        <div className="text-base text-primary font-bold">
                          {LocaleStrings.cart_order_summery}
                        </div>
                      </div>
                      <div className="bg-white rounded-lg">
                        {getUserCart?.items?.length > 0 ? (
                          <div className="px-15 py-15">
                            {updateCalculations()}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {step == 3 ? (
                        <>
                          <div className="font-medium text-sm text-primary my-4">
                            <div className="text-base text-primary font-bold">
                              {LocaleStrings.cart_shipping_address}
                            </div>
                          </div>
                          <div className="bg-white rounded-lg">
                            <div className="px-15 py-15">
                              <div className=" text-primary font-normal text-base py-3 mb-5">
                                <div className="text-primary text-lg font-bold">
                                  {_.startCase(shippingAddress[0].addresstype)}
                                  {" : "}
                                  {shippingAddress[0].username}
                                </div>
                                <div className="text-cardfooter font-normal text-xs">
                                  {shippingAddress[0].address}
                                  {shippingAddress[0].buildingno ? (
                                    <>
                                      {shippingAddress[0].buildingno ? (
                                        <div className="text-sold text-base">{`${
                                          shippingAddress[0].buildingno
                                        }${
                                          shippingAddress[0]
                                            ?.zone_master_by_zoneno?.zone
                                            ? "," +
                                              shippingAddress[0]
                                                ?.zone_master_by_zoneno?.zone
                                            : ""
                                        },${shippingAddress[0].poboxno}`}</div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    <div className="text-sold text-base">
                                      {shippingAddress[0].address}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="py-4 border-t border-header flex items-center cursor-pointer"
                                onClick={() => editAddress(shippingAddress[0])}
                              >
                                <div className="pr-2">
                                  <img src={EditIcon} />
                                </div>
                                <div className="text-specification font-medium text-base">
                                  Edit
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {ifBillingOnly ? (
                        <div className="bg-productdelivery rounded my-4">
                          <div className="flex py-3 pl-15">
                            <div className="pr-3.5">
                              <img src={DeliverybyIcon} className="w-37" />
                            </div>
                            <div>
                              <div className="text-base text-footers">
                                {LocaleStrings.deliveryby}
                              </div>
                              <div className="text-base text-footers">
                                {getUserCart?.maxhandlingdays
                                  ? moment()
                                      .add(
                                        parseInt(getUserCart.maxhandlingdays),
                                        "days"
                                      )
                                      .format("dddd, D MMM YYYY")
                                  : moment().format("dddd, D MMM YYYY")}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {step == 1 ? (
                        <>
                          <div className="w-full text-center mb-5">
                            <button
                              type="button"
                              className={classNames(
                                selectedItem.length > 0
                                  ? "cursor-pointer "
                                  : " cursor-not-allowed",
                                "  w-full py-3 px-5 bg-btn text-white rounded font-medium text-sm "
                              )}
                              // className="w-full py-3 px-5 bg-btn text-white rounded font-medium text-sm cursor-pointer"
                              onClick={() =>
                                selectedItem.length > 0 ? changeStep(2) : null
                              }
                            >
                              {LocaleStrings.cart_continue}
                            </button>
                          </div>
                          <div className="items-center justify-start flex lg:hidden">
                            <div
                              className="text-link font-medium text-sm cursor-pointer px-6 py-3 border border-header bg-white rounded-md"
                              onClick={() => history.push("/")}
                            >
                              {LocaleStrings.cart_continue_shopping}
                            </div>
                          </div>
                        </>
                      ) : step == 2 ? (
                        <div className="w-full text-center mb-5">
                          <button
                            type="submit"
                            disabled={
                              selectedItem.length > 0 &&
                              shippingAddress.length > 0
                                ? false
                                : true
                            }
                            className="w-full py-3 px-5 bg-btn text-white rounded font-medium text-sm cursor-pointer"
                            onClick={() => submitAction("address")}
                          >
                            {LocaleStrings.cart_continue}
                          </button>
                        </div>
                      ) : (
                        <div className="w-full text-center mb-5">
                          <button
                            type="submit"
                            className="rounded py-3 px-5 w-full flex items-center justify-center bg-btn text-white font-medium text-sm cursor-pointer"
                            disabled={
                              submitting || loading || selectedItem.length > 0
                                ? false
                                : true
                            }
                            onClick={() => submitAction("checkout")}
                          >
                            {loading ? (
                              <svg
                                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  stroke-width="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            ) : (
                              ""
                            )}
                            <span className="text-sm">
                              {LocaleStrings.cart_place_order}
                            </span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* <CouponSlider totalCal={onlyPrice} /> */}
            <ChangeAddress />
            <NewAddress
              source="cart"
              callbackAdrress={callbackAdrress}
              editcountryId={editcountryId}
            />
          </>
        ) : (
          <div className="bg-cart py-25">
            <div className="custom_container">
              <div className="bg-white rounded py-4 flex items-center justify-center">
                <div>
                  <div className="flex items-center justify-center pb-5">
                    <img src={CartEmpty} />
                  </div>
                  <div className="flex items-center justify-center text-cutoff text-base font-medium">
                    {LocaleStrings.empty_cart_title}
                  </div>
                  <div className="flex items-center justify-center text-cutoff text-sm mt-3">
                    {LocaleStrings.empty_cart_desc}
                  </div>
                  <div className="flex items-center justify-center mt-6">
                    <button
                      type="button"
                      className="h-8 pl-md pr-md bg-btn text-white rounded font-medium text-sm cursor-pointer"
                      onClick={() => history.push("/")}
                    >
                      {LocaleStrings.shop_now}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <Loader data={LocaleStrings.loader_message} />
      )}
    </>
  );
}

const validate = (values, ownProps) => {
  //console.log("ownProps", ownProps);
  var errors = {};
  var regex = /^\s+$/;
  if (values.sameas) {
    if (ownProps.userAddressAll.length == 0) {
      errors.sameas = LocaleStrings.required;
    }
  }
  if (!values.sameas) {
    let onlyPhone = "";
    let countrycode = ownProps.phoneLengthCountry;
    if (values.phone) {
      values.phone = values.phone.replace(/[- )( +]/g, "");
      onlyPhone = values.phone.substring(ownProps.phoneLengthCountry.length);
    }

    if (!values.username) {
      errors.username = LocaleStrings.required;
    }
    if (!values.phone) {
      errors.phone = LocaleStrings.required;
    } else if (countrycode == "974") {
      if (isValidQatarPhoneNumber("+" + values.phone) == false) {
        errors.phone = LocaleStrings.form_validation_phone_no_valid;
      }
    }
    if (!values.country_id) {
      errors.country_id = LocaleStrings.required;
    }

    if (values.country_id == 187) {
      if (!values.buildingno) {
        errors.buildingno = LocaleStrings.required;
      } else if (values.buildingno && values.buildingno.match(regex)) {
        errors.buildingno = LocaleStrings.required;
      }
      if (!values.zoneno) {
        errors.zoneno = LocaleStrings.required;
      }

      if (!values.streetno) {
        errors.streetno = LocaleStrings.required;
      } else if (values.streetno && values.streetno.match(regex)) {
        errors.streetno = LocaleStrings.required;
      }

      if (!values.unitno) {
        errors.unitno = LocaleStrings.required;
      } else if (values.unitno && values.unitno.match(regex)) {
        errors.unitno = LocaleStrings.required;
      }
    } else {
      if (!values.address) {
        errors.address = LocaleStrings.required;
      }
      if (!values.city) {
        errors.city = LocaleStrings.required;
      }
      if (!values.zip) {
        errors.zip = LocaleStrings.required;
      }
      if (!values.state) {
        errors.state = LocaleStrings.required;
      }
    }
  }
  //console.log("errors", errors);
  return errors;
};

const mapStateToProps = (state) => {
  var initVals = {};
  initVals.paymentmethod = "card";
  initVals.addresstype = "home";
  if (state.userAddressAll && state.userAddressAll.length > 0) {
    //console.log("state.userAddressAll", state.userAddressAll);
    let billingAddress = _.filter(state.userAddressAll, function (o) {
      return o.isbillingaddress;
    });
    if (billingAddress.length > 0) {
      initVals = {
        id: billingAddress[0].id,
        country_id: billingAddress[0].country_id,
        username: billingAddress[0].username,
        phone: billingAddress[0].phone,
        address: billingAddress[0].address,
        city: billingAddress[0].city,
        zip: billingAddress[0].zip,
        state: billingAddress[0].state,
        buildingno: billingAddress[0].buildingno,
        zoneno: billingAddress[0].zoneno,
        streetno: billingAddress[0].streetno,
        poboxno: billingAddress[0].poboxno,
        addresstype: billingAddress[0].addresstype,
      };
    } else {
      let country_code = state.defaultCountry;

      if (state.countryList && state.countryList.length > 0) {
        let countryObj = _.filter(state.countryList, function (o) {
          return o.countrycode == country_code.toUpperCase();
        });

        initVals = {
          country_id: countryObj.length > 0 ? countryObj[0].id : "",
        };
      }
    }
  } else if (state.defaultCountry) {
    let country_code = state.defaultCountry;

    if (state.countryList && state.countryList.length > 0) {
      let countryObj = _.filter(state.countryList, function (o) {
        return o.countrycode == country_code.toUpperCase();
      });

      initVals = {
        country_id: countryObj.length > 0 ? countryObj[0].id : "",
      };
    }
  }
  //console.log("initVals", initVals);
  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    localLanguage: state.localLanguage,
    getUserCart: state.getUserCart,
    userAddressAll: state.userAddressAll,
    couponModal: state.couponModal,
    appliedCoupon: state.appliedCoupon,
    countryList: state.countryList,
    initialValues: initVals,
    localCurrency: state.localCurrency,
    phoneLengthCountry: state.phoneLengthCountry,
    deviceSize: state.deviceSize,
    zoneList: state.zoneList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserAddressAll,
      fetchCart,
      updateQuantity,
      togglecartSelection,
      deleteCartItem,
      isCouponlistModalOpen,
      availableCoupons,
      fetchCountry,
      setdefaultCountry,
      isChangeAddressModal,
      isNewAddressModalOpen,
      createOrder,
      makePayment,
      addAddress,
      removeCouponCode,
      paymentBypass,
      countryPhoneLength,
      editUserAddress,
      applyCoupon,
      makeDefaultAddress,
      updateCart,
      fetchZone,
      updateAddress,
      showNotificatonCount,
      getPartnerPrice,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "CartPage",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onSubmitFail: (errors) => scrollIntoViewHelper(errors),
  })(Cart)
);
