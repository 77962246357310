import _ from "lodash";
import {
  CURRENCY_RATE,
  CURRENCY_RATE_USD,
  CURRENCY_RATE_QR,
  CURRENCY_USD,
  CURRENCY_QR,
} from "./constant";

export function ProductTextLocalized(text_en, text_ar, local_lang) {
  let returnText =
    local_lang == "ar"
      ? text_ar != null && text_ar.length > 0
        ? text_ar
        : text_en
      : text_en != null && text_en.length > 0
      ? text_en
      : text_ar;
  return returnText;
}

export function updateMRP(item, variantid, localCurrency) {
  const all_variants =
    item && item?.price
      ? item?.price[localCurrency.toLowerCase()]?.all_variants
      : [];
  const discount =
    item && item?.price
      ? item?.price[localCurrency.toLowerCase()]?.discount
      : {};
  //console.log("discount", discount);
  //let all_variants = item.all_variants;
  let price = _.filter(all_variants, function (o) {
    return o.id == variantid;
  });
  //console.log("price", price);
  let productMRP = price.length > 0 ? price[0].price_unit : 0;
  var discountedPrice = productMRP;
  var discountpercent = 0.0;
  var strProductDiscount = "";
  if (discount && discount.active == 1) {
    let mrp = productMRP;

    if (discount.discount_percentage) {
      discountpercent = discount.discount_percentage;
    }

    if (discount.discounttype) {
      let discounttype = discount.discounttype;
      if (discounttype == "fixed") {
        //strProductDiscount = CURRENCY_RATE + discountpercent + " OFF";
        strProductDiscount = discountpercent + " OFF";
        discountedPrice = mrp - discountpercent;
      } else {
        strProductDiscount = discountpercent + "% OFF";
        discountedPrice = mrp - mrp * (discountpercent / 100);
      }
    } else {
      strProductDiscount = discountpercent + "% OFF";
      discountedPrice = mrp - mrp * (discountpercent / 100);
    }
  }
  return {
    discountedPrice: discountedPrice,
    strProductDiscount: strProductDiscount,
  };
}

export function currencyRate(currency) {
  let returnText = currency == "QAR" ? CURRENCY_RATE_QR : CURRENCY_RATE_USD;
  return returnText;
}

export function currencyCode(currency) {
  let returnText = currency == "QAR" ? CURRENCY_QR : CURRENCY_USD;
  return returnText;
}

export function priceConversion(source, destination, amount, currencyconversionrate) {
    if (source !== destination) {
        if (source === 'QAR' && destination === 'USD') {
            amount = Math.round(amount / CURRENCY_RATE_USD);
        } else if (source === 'USD' && destination === 'QAR') {
            amount = amount * currencyconversionrate;
        }
    }
    return amount.toFixed(2);
}
