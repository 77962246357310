import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "../../../../../languages/index";
import toast, { Toaster } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";

// Import Components
import Loader from "../../../../../components/Common/loader";
import { renderFieldText } from "../../../../../components/Common/base-component";

// Import Actions
import {
  fetchUsersDeatils,
  isProfileinfoCommonOpen,
  changePassword,
} from "../../../actions/index";
import { logout } from "../../../../Login/actions";

// Functions Import
import {
  classNames,
  isEmpty,
  LABEL_POSITION_TOP,
} from "../../../../../components/Common/constant";

/* This example requires Tailwind CSS v2.0+ */

class ChangePassSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      loaderDesc: LocaleStrings.preparing_profile_information,
      show: false,
      show1: false,
      show2: false,
    };

    // All Binded Functions
    this.changeShow = this.changeShow.bind(this);
    this.changeShow1 = this.changeShow1.bind(this);
    this.changeShow2 = this.changeShow2.bind(this);
  }

  componentDidMount() {}

  changeShow() {
    let { show } = this.state;

    this.setState({ show: !show });
  }

  changeShow1() {
    let { show1 } = this.state;

    this.setState({ show1: !show1 });
  }

  changeShow2() {
    let { show2 } = this.state;

    this.setState({ show2: !show2 });
  }

  /**** local Gender Change ****/

  /***** Form Submit *****/
  onFormSubmit(values) {
    this.setState({ btnLoader: true });
    let data = {};
    data.old_password = values.current_password;
    data.new_password = values.new_password;
    data.email = values.email;
    //console.log("data", data);
    this.props.changePassword(this.props.session, data, (feedback) => {
      this.setState({ btnLoader: false });
      if (feedback.success === 0) {
        toast.error(feedback.data);
      } else {
        toast.success(LocaleStrings.password_updated_success);
        //this.props.logout();
        //this.props.history.push("/login");
      }
      this.props.isProfileinfoCommonOpen(false);
    });
  }

  render() {
    let {
      initialValues,
      language,
      handleSubmit,
      vendorDetails,
      storeSelectedGender,
    } = this.props;

    return (
      <>
        <form
          className=""
          onSubmit={handleSubmit(this.onFormSubmit.bind(this))}
        >
          <div className="pb-52 px-6 space-y-8">
            <div className="relative">
              <Field
                name="current_password"
                label={LocaleStrings.current_password}
                placeholder={LocaleStrings.current_password}
                type="password"
                component={renderFieldText}
                mandatory="true"
                show={this.state.show2}
                setShow={this.changeShow2}
                classNameEye="absolute flex items-center text-sm top-9 right-3.5 cursor-pointer"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="relative">
              <Field
                name="new_password"
                label={LocaleStrings.password}
                placeholder={LocaleStrings.password}
                type="password"
                component={renderFieldText}
                mandatory="true"
                show={this.state.show}
                setShow={this.changeShow}
                classNameEye="absolute flex items-center text-sm top-9 right-3.5 cursor-pointer"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="relative">
              <Field
                name="confirm_password"
                label={LocaleStrings.confirm_password}
                placeholder={LocaleStrings.confirm_password}
                show={this.state.show1}
                type="password"
                component={renderFieldText}
                mandatory="true"
                show={this.state.show1}
                setShow={this.changeShow1}
                classNameEye="absolute flex items-center text-sm top-9 right-3.5 cursor-pointer"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="px-6 py-12 border-t-2 border-quaternary flex justify-center">
            {/* Footer */}
            <button
              className="text-white bg-btn text-sm font-normal pt-2.5 pb-2.5 pl-9 pr-9 rounded btn-heavy"
              type="submit"
            >
              {this.state.btnLoader ? (
                <svg
                  className="animate-spin h-5 w-5 rounded-full mr-3"
                  viewBox="0 0 1024 1024"
                  focusable="false"
                  data-icon="loading"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                </svg>
              ) : (
                ""
              )}
              <span className="text-sm">{LocaleStrings.update_password}</span>
            </button>
          </div>
        </form>
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  if (!values.current_password) {
    errors.current_password = "required";
  }

  if (!values.new_password) {
    errors.new_password = "required";
  } else if (values.new_password.length < 6) {
    errors.new_password = "minimum 6 character length";
  }
  if (!values.confirm_password) {
    errors.confirm_password = "required";
  } else if (values.new_password != values.confirm_password) {
    errors.confirm_password = LocaleStrings.form_validation_password_not_match;
  }

  return errors;
}

function mapStateToProps(state, ownProps) {
  let newObj = {};

  if (state.myProfile)
    newObj = {
      username: state.myProfile
        ? state.myProfile.data
          ? state.myProfile.data.username
          : ""
        : "",
      userid: state.myProfile
        ? state.myProfile.data
          ? state.myProfile.data.userid
          : ""
        : "",
      email: state.myProfile
        ? state.myProfile.data
          ? state.myProfile.data.email
          : ""
        : "",
    };

  return {
    isLoggedIn: state.isLoggedIn,
    user: state.myProfile.data,
    session: state.session,
    isOpenProfileInfoModal: state.isOpenProfileInfoModal,
    vendorDetails: state.vendorDetails
      ? state.vendorDetails.data
        ? state.vendorDetails.data
        : []
      : [],
    initialValues: newObj,
  };
}

export default connect(mapStateToProps, {
  fetchUsersDeatils,
  isProfileinfoCommonOpen,
  changePassword,
  logout,
})(
  reduxForm({
    validate,
    form: "updatePasswordForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ChangePassSection)
);
