import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import {
  renderFieldRadio,
  renderFieldText,
  renderFieldTextarea,
} from "../../../components/Common/base-component";
import toast from "react-hot-toast";
import LocaleStrings from "../../../languages/index";
import ImgUploader from "../../../assets/images/attach-file.svg";
import SuggestionIcon from "../../../assets/images/app-suggestion.svg";
import RatingIcon from "../../../assets/images/rating-review.svg";
// Import constant
import {
  validateEmail,
  classNames,
  LABEL_POSITION_TOP,
} from "../../../components/Common/constant";

// Import Actions
import {
  sendsuggestions,
  uploadMediaLargeAppSuggestion,
} from "../actions/index";

class AppSuggestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
      fileName: "",
      fileArr: [],
    };

    // All Binded Functions
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  /**** Modal Open Function  ****/

  /**** Input File select ****/
  _handleSelect(event) {
    let { fileArr } = this.state;
    const reader = new FileReader(); // Define reader
    let file = event.target.files[0]; // Take Selected file
    reader.readAsArrayBuffer(file); // Read the file

    // On File Load
    reader.onload = (e) => {
      file.url = e.target.result;
    };

    //let ext = file.name.split(".").pop(); // File extension

    // if(_.indexOf(validateExtention, ext) === -1) { // Validate File Extensions
    //     toast.error(LocaleStrings.upload_only_doc_and_pdf_file_error_text);
    // }else {

    // }
    this.setState({ fileName: file.name });
    //this.props.autofill(typeindex,file.name);

    fileArr.push(file);
    this.setState({ fileArr: fileArr });
  }

  /**** RED cross Click ****/
  _handleUnSelect = (e) => {
    this.setState({ fileArr: [] });
  };

  /***** Form Submit *****/
  onFormSubmit(values) {
    this.setState({ isBtnDisable: true });
    this.setState({ btnLoader: true });

    let { fileArr } = this.state;

    values["userid"] = this.props.session.userid;
    // Upload Section
    let uploaderCounter = 0;
    let filetobeupload = [];
    let arrPromise = [];
    let attachmentNames = [];

    _.forEach(fileArr, function (value, index) {
      let ext = value.name.split(".").pop();
      let ts = new Date().getTime();
      let covermedia = ts + index + "." + ext;

      let coverJson = {
        type: value.type,
        name: covermedia,
        media: value.url,
      };
      attachmentNames.push(covermedia);
      filetobeupload.push(coverJson); //
    });

    let counter = filetobeupload.length;

    // Store File to server first
    filetobeupload.map((item, index) => {
      arrPromise.push(
        new Promise((resolve, reject) => {
          this.props.uploadMediaLargeAppSuggestion(
            this.props.session,
            item,
            (response) => {
              if (response.success === 0) {
                resolve();
              } else {
                uploaderCounter++;
                resolve();
              }
            }
          );
        })
      );
    });

    if (counter > 0) {
      //Save With Files
      Promise.all(arrPromise).then(() => {
        if (uploaderCounter === counter) {
          values["attachment"] = _.join(attachmentNames, ",");

          // After file upload
          this.props.sendsuggestions(this.props.session, values, (callback) => {
            if (callback.status === 1) {
              this.setState({ isBtnDisable: false });
              this.setState({ btnLoader: false });
              this.setState({ fileName: "" });
              this.setState({ fileArr: [] });

              toast.success(LocaleStrings.app_suggestions_submit_success);
              this.props.reset();
            } else {
              this.setState({ isBtnDisable: false });
              this.setState({ btnLoader: false });

              toast.error(LocaleStrings.common_fail_message);
            }
          });
        } else {
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    } else {
      //Save With out Files
      values["attachment"] = "";

      this.props.sendsuggestions(this.props.session, values, (callback) => {
        if (callback.status === 1) {
          this.setState({ isBtnDisable: false });
          this.setState({ btnLoader: false });
          this.setState({ fileName: "" });
          this.setState({ fileArr: [] });

          toast.success(LocaleStrings.app_suggestions_submit_success);
          this.props.reset();
        } else {
          this.setState({ isBtnDisable: false });
          this.setState({ btnLoader: false });

          toast.error(LocaleStrings.common_fail_message);
        }
      });
    }
  }

  render() {
    let { handleSubmit } = this.props;
    let { fileArr } = this.state;

    return (
      <>
        <div className="bg-newarrival py-25">
          <div className="flow-root bg-white rounded custom_container">
            <form
              className=" "
              onSubmit={handleSubmit(this.onFormSubmit.bind(this))}
            >
              <div className="p-10 space-y-6">
                <div className="">
                  <p className="text-base font-medium text-primary">
                    {LocaleStrings.app_suggestions_header_text}
                  </p>
                </div>

                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <img className="h-5 w-5" src={SuggestionIcon} alt="" />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-ternary truncate">
                      {LocaleStrings.bug}
                    </p>
                  </div>
                  <div>
                    <Field
                      name="requesttype"
                      label={""}
                      component={renderFieldRadio}
                      type="radio"
                      value="bug"
                      className="mx-2"
                      // onChange={this.handleInputLanguageChange.bind(this)}
                    />
                  </div>
                </div>

                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <img className="h-5 w-5" src={RatingIcon} alt="" />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-ternary truncate">
                      {LocaleStrings.app_feature}
                    </p>
                  </div>
                  <div>
                    <Field
                      name="requesttype"
                      label={""}
                      component={renderFieldRadio}
                      type="radio"
                      value="feature"
                      className="mx-2"
                      // onChange={this.handleInputLanguageChange.bind(this)}
                    />
                  </div>
                </div>

                <Field
                  name="emailid"
                  label={LocaleStrings.app_suggestions_lbl_email_address}
                  placeholder={
                    LocaleStrings.app_suggestions_plshldr_email_address
                  }
                  type="text"
                  component={renderFieldText}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-medium text-primary"
                />
                <Field
                  name={"suggestion"}
                  label={LocaleStrings.app_suggestions_lbl_suggestion_desc}
                  placeholder={
                    LocaleStrings.app_suggestions_plshldr_suggestion_desc
                  }
                  mandatory="false"
                  component={renderFieldTextarea}
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-box-lable"
                  className="py-3 px-4 block w-full shadow-sm text-primary border border-warm-gray-300 rounded-md"
                />
                {/* File Attachment */}
                <div className="flex justify-between">
                  <div className=" flex">
                    <span className="text-sm text-ternary">
                      {_.size(fileArr) > 0 ? _.size(fileArr) : ""}{" "}
                      {_.size(fileArr) > 1
                        ? LocaleStrings.files_attached
                        : _.size(fileArr) > 0
                        ? LocaleStrings.file_attached
                        : ""}
                    </span>
                    <img
                      className={classNames(
                        _.size(fileArr) > 0 ? "" : "hidden",
                        "h-4 w-4 mx-1 cursor-pointer"
                      )}
                      src={"../images/red-cross.svg"}
                      onClick={(e) => this._handleUnSelect(e)}
                    />
                  </div>
                  <div className="p-3 border border-blue-200 rounded-md text-loginLabel font-normal text-sm">
                    <label
                      htmlFor="attachment"
                      className="relative flex items-center justify-center cursor-pointer"
                    >
                      <img src={ImgUploader} className="w-2.5" />
                      <span className="pl-2">
                        {LocaleStrings.rate_product_attach}
                      </span>
                      <input
                        id="attachment"
                        name="attachment"
                        type="file"
                        accept="image/*"
                        className="sr-only"
                        onChange={(e) => this._handleSelect(e)}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="p-6 border-t-2 border-quaternary">
                <div className="">
                  <p className="text-sm  text-ternary pb-4">
                    {LocaleStrings.app_suggestions_footer_text}
                  </p>
                </div>
                <div className="flex items-center justify-center">
                  <button
                    type="submit"
                    disabled={this.state.isBtnDisable}
                    className="rounded py-3 px-5 w-full md:w-80 lg:w-2/6 flex items-center justify-center bg-btn text-white font-medium text-sm cursor-pointer"
                  >
                    {this.state.btnLoader ? (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      ""
                    )}
                    <span className="text-sm md:text-base">
                      {LocaleStrings.send_suggestion}
                    </span>
                  </button>
                </div>

                {/* Footer */}
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var requiredFields = ["emailid", "requesttype", "suggestion"];
  var email = values["emailid"];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field].trim() === "") {
      errors[field] = "Required";
    }
  });

  if (email && !validateEmail(email)) {
    errors["emailid"] = "Invalid email";
  }

  return errors;
}

function mapStateToProps(state) {
  var { session, user, language, isLoggedIn } = state;

  return {
    session,
    user,
    isLoggedIn,
    language,
    initialValues: { requesttype: "bug" },
  };
}

export default connect(mapStateToProps, {
  sendsuggestions,
  uploadMediaLargeAppSuggestion,
})(
  reduxForm({
    validate,
    form: "AppSuggestionsForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AppSuggestions)
);
