import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
  SMALL_DATE_FORMAT,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import NoImg from "../../../assets/images/noimg.png";
import YouNodata from "../../../assets/images/you-nodat.svg";
import {
  profileModalOpen,
  selectedFollowerUserDetails,
  selectedFollowersDetails,
  selectedFollowersShearedWishlist,
  emptySharedWishlist,
  setFollowunfollow,
  profileViewNavigation,
  viewPublicProfile,
} from "../actions";

function FollowingList(props) {
  const history = useHistory();
  const {
    session,
    isLoggedIn,
    profileFollower,
    setUserNavigation,
    userNavigation,
    navigateUser,
  } = props;
  const [loading, setLoading] = useState(false);
  const [followingId, setFollowingId] = useState("");

  const renderList = () => {
    //console.log("profileFollower.data", profileFollower.data);
    return _.map(profileFollower.data, (valObj, index) => {
      var usersByFollowerid = valObj.users_by_followerid;
      let followUnfollowBtnText = valObj.following
        ? LocaleStrings.unfollow_btn
        : LocaleStrings.follow_btn;
      var productImageUrl = `${FILES_URL}/users/${valObj.userid}.jpg?api_key=${APP_API_KEY}`;

      return (
        <li key={index} className="py-4 bg-white px-4 ">
          <div className="flex items-center space-x-4 cursor-pointer">
            <div
              className="flex-shrink-0"
              onClick={(e) => openFollowersDelaisModal(valObj)}
            >
              <img
                className="h-10 w-10 rounded-full"
                src={productImageUrl}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = NoImg;
                }}
                alt="Image"
              />
            </div>
            <div
              className="flex-1 min-w-0 cursor-pointer"
              onClick={(e) => openFollowersDelaisModal(valObj)}
            >
              <p className="text-sm font-medium text-gray-900 truncate">
                {valObj.username}
              </p>
              {/* <p className="text-sm text-gray-500 truncate">
                {moment(valObj.createdon).format(SMALL_DATE_FORMAT)}
              </p> */}
            </div>
            <div className="w-24">
              {session.userid == valObj.users_by_followerid.userid ? (
                ""
              ) : (
                <button
                  type="button"
                  className="text-white h-8 bg-btn text-sm font-normal rounded w-full inline-flex items-center justify-center"
                  onClick={(e) => _handleFollowUnfollow(valObj)}
                >
                  {loading && followingId == valObj.userid ? (
                    <svg
                      className="animate-spin h-5 w-5 rounded-full mr-3"
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="loading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                  ) : (
                    followUnfollowBtnText
                  )}
                </button>
              )}
            </div>
          </div>
        </li>
      );
    });
  };

  const _handleFollowUnfollow = (valObj) => {
    setLoading(true);
    setFollowingId(valObj.userid);
    props.setFollowunfollow(session, valObj.userid, (callback) => {
      if (callback.status) {
        props.selectedFollowerUserDetails(
          props.session,
          valObj.users_by_userid.userid,
          (callback) => {
            setLoading(false);
            setFollowingId("");
          }
        );

        props.selectedFollowersDetails(
          session,
          valObj.users_by_userid.userid,
          (callback) => {}
        );
      }
    });
  };

  const openFollowersDelaisModal = (valObj) => {
    let userArr = navigateUser;
    props.profileModalOpen(true);
    props.emptySharedWishlist();
    props.selectedFollowerUserDetails(session, valObj.userid, (callback) => {});
    props.selectedFollowersDetails(session, valObj.userid, (callback) => {});
    props.selectedFollowersShearedWishlist(
      session,
      valObj.userid,
      (callback) => {}
    );
    if (valObj.userid != session.userid) {
      props.viewPublicProfile(session, valObj.userid);
    }

    if (!_.includes(userArr, valObj.userid)) {
      userArr.push(valObj.userid);
      props.profileViewNavigation(userArr);
    }
  };

  return (
    <div className="px-6">
      <div className="flow-root my-8">
        <ul role="list" className=" ">
          {renderList()}
        </ul>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  profileFollower: state.profileFollower,
  navigateUser: state.navigateUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      profileModalOpen,
      selectedFollowerUserDetails,
      selectedFollowersDetails,
      selectedFollowersShearedWishlist,
      emptySharedWishlist,
      setFollowunfollow,
      profileViewNavigation,
      viewPublicProfile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FollowingList);
