import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
  currencyCode,
  currencyRate,
} from "../../../components/Common/product-model";
import {
  isNegotiationOpen,
  negotiationThread,
  fetchNegotiation,
} from "../actions";
import {
  negotiationDetailsAction,
  emptyNegotiationDetails,
  negotiationPurchase,
  fetchCart,
} from "../../Product/actions";
import toast, { Toaster } from "react-hot-toast";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { confirmAlert } from "react-confirm-alert";

function ListItem(props) {
  const { session, isLoggedIn, localLanguage, item, localCurrency } = props;

  var discountpercent = 0.0;
  var strProductDiscount = "";
  let originalprice = 0.0;
  let prodprice = 0.0;

  let currency = currencyCode(localCurrency);
  let currencyrate = currencyRate(localCurrency);
  if (item) {
    originalprice = item.originalprice ? item.originalprice : 0;

    // if (item.discountactive && item.discountactive == true) {
    //   if (item.discount && item.discount.discount_percentage) {
    //     discountpercent = item.discount.discount_percentage;
    //   }

    //   if (item.discount && item.discount.discounttype) {
    //     let discounttype = item.discount.discounttype;
    //     if (discounttype == "fixed") {
    //       prodprice = parseFloat(originalprice) - parseFloat(discountpercent);
    //       strProductDiscount = discountpercent + " OFF";
    //     } else {
    //       strProductDiscount = discountpercent + "% OFF";
    //       prodprice =
    //         parseFloat(originalprice) -
    //         parseFloat((discountpercent / 100) * originalprice);
    //     }
    //   } else {
    //     strProductDiscount = discountpercent + "% OFF";
    //     prodprice =
    //       parseFloat(originalprice) -
    //       parseFloat((discountpercent / 100) * originalprice);
    //   }
    // } else {
    //   prodprice = originalprice;
    // }
    prodprice = originalprice;
    originalprice = originalprice;
  }

  const viewDetails = (threadid, active) => {
    //console.log("active", active);
    if (active == "1") {
      props.negotiationDetailsAction(session, threadid, (res) => {});
      props.negotiationThread(threadid);
      props.isNegotiationOpen(true);
    } else {
      confirmAlert({
        title: null,
        message: LocaleStrings.temporarily_unavailable,
        buttons: [
          {
            label: LocaleStrings.button_ok,
            onClick: () => {},
          },
        ],
      });
    }
  };
  const proceedToPurchase = (negotiationthreadid) => {
    let values = {};
    values.threadid = negotiationthreadid;
    props.negotiationPurchase(session, values, (res) => {
      if (res.success == 0) {
        toast.error(LocaleStrings.common_fail_message);
      } else {
        props.fetchCart(session);
        props.fetchNegotiation(session, "won");
        toast.success(LocaleStrings.add_to_cart_success);
      }

      window.scrollTo(0, 0);
    });
  };
  //console.log("item", item);
  return (
    <>
      <div
        key={item.id}
        className="w-168 flex flex-col rounded-md overflow-hidden"
      >
        <div className="border border-primary rounded-md flex-shrink-0 relative">
          {/* <img
            className="h-cardimg w-full object-cover cursor-pointer rounded-md"
            src={`${FILES_URL}/vendor/variations/${item.productimage}?api_key=${APP_API_KEY}`}
            alt=""
            onClick={() => viewDetails(item.negotiationthreadid)}
          /> */}

          <LazyLoadImage
            className="h-cardimg w-full object-cover cursor-pointer rounded-md"
            src={`${FILES_URL}/vendor/variations/${item.productimage}?api_key=${APP_API_KEY}`}
            alt=""
            onClick={() => viewDetails(item.negotiationthreadid, item.isactive)}
            effect="blur"
          />
        </div>
        <div className="flex-1 bg-white pl-2 p-r-2 flex flex-col justify-between">
          <div className="text-left">
            <a
              onClick={() =>
                viewDetails(item.negotiationthreadid, item.isactive)
              }
              className="block mt-2 cursor-pointer"
            >
              <p className="text-sm font-normal text-primary">
                {ProductTextLocalized(
                  item.productname_en,
                  item.productname_ar,
                  localLanguage
                )}
              </p>
            </a>
          </div>
        </div>
        <div className="bg-white rounded-md rounded-t-none pt-md pl-2 p-r-2">
          {strProductDiscount ? (
            <div>
              <p className="text-xs font-normal text-cutoff line-through">
                {currency}
                {originalprice}
              </p>
            </div>
          ) : (
            ""
          )}
          <div className="flex mb-2.5">
            <div className="pr-3">
              <p className="text-left text-xs font-normal text-primary">
                {currency}
                {prodprice}
              </p>
            </div>

            <div className="text-xs text-medium text-discount">
              {strProductDiscount}
            </div>
          </div>
          {item.status == "negotiated" ? (
            <div className="bg-negotiationAccept text-center text-sm font-normal text-white py-1 rounded-full">
              {LocaleStrings.negotiation_won_text}
            </div>
          ) : (
            ""
          )}
          {item.status == "negotiated" &&
          parseInt(item.itemalreadypurchased) == 0 &&
          item.isactive == "1" ? (
            <div className="mt-2">
              <button
                className="text-white h-44 bg-btn text-sm font-normal rounded w-full inline-flex items-center justify-center"
                onClick={() => proceedToPurchase(item.negotiationthreadid)}
              >
                {LocaleStrings.add_to_cart_btn}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
  localCurrency: state.localCurrency,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isNegotiationOpen,
      negotiationThread,
      negotiationDetailsAction,
      emptyNegotiationDetails,
      negotiationPurchase,
      fetchCart,
      fetchNegotiation,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
