import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import LocaleStrings from "../../languages";
import toast from "react-hot-toast";
import _ from "lodash";
import {
  renderFieldText,
  renderPhonewithFlag,
} from "../../components/Common/base-component";
import {
  validateEmail,
  classNames,
  phoneCount,
  isValidQatarPhoneNumber,
} from "../../components/Common/constant";

// Import Actions
import { VerifyPhoneStat } from "../../actions";
import {
  sendSms,
  verifyOtp,
  fetchUsersDeatils,
  updateStoreUsers,
} from "../Profile/actions";
import { countryPhoneLength } from "../../../src/actions/";
import { logout } from "../Login/actions";
import { confirmAlert } from "react-confirm-alert";
import { withRouter } from "react-router-dom";

class VerifyPhone extends Component {
  constructor(props) {
    super(props);
    this.timer = 0;
    this.state = {
      isVerified: false,
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
      hideNumberField: true,
      sendLoader: false,
      fieldsEmptyState: true,
      resendLoader: false,
      time: {},
      seconds: 60,
      phone: "",
    };

    // All Binded Functions
    this.changeNumber = this.changeNumber.bind(this);
    this.resendVerificationCode = this.resendVerificationCode.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    this.props.fetchUsersDeatils(this.props.session, (callBackResponce) => {});
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  startTimer() {
    this.setState({ resendLoader: true });
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
      this.setState({ resendLoader: false, time: {}, seconds: 60 });
      this.timer = 0;
      let timeLeftVar = this.secondsToTime(60);
      this.setState({ time: timeLeftVar });
    }
  }

  secondsToTime(secs) {
    let obj = {
      s: secs,
    };
    return obj;
  }

  componentWillUnmount() {
    this.setState({
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
      hideNumberField: true,
      sendLoader: false,
      phone: "",
      country: "",
      isVerified: false,
      fieldsEmptyState: true,
      resendLoader: false,
      time: {},
      seconds: 60,
    });
  }

  /* Change number */
  changeNumber() {
    // toast.error(LocaleStrings.feature_under_construction);
    this.setState({ hideNumberField: false });
  }

  /* Verification Code entry */
  _onVerficationCodeEntry(e, fieldNum) {
    if (fieldNum !== 6 && e.target.value !== "") {
      document.getElementsByName("verifiaction_" + (fieldNum + 1))[0].focus();
    }

    /* Remove disable if verify code is 6 digit long */
    let verifiaction_1 = document.getElementsByName("verifiaction_1");
    let verifiaction_2 = document.getElementsByName("verifiaction_2");
    let verifiaction_3 = document.getElementsByName("verifiaction_3");
    let verifiaction_4 = document.getElementsByName("verifiaction_4");
    let verifiaction_5 = document.getElementsByName("verifiaction_5");
    let verifiaction_6 = document.getElementsByName("verifiaction_6");

    let verficationEntryCode =
      verifiaction_1[0].value +
      verifiaction_2[0].value +
      verifiaction_3[0].value +
      verifiaction_4[0].value +
      verifiaction_5[0].value +
      verifiaction_6[0].value;

    if (_.trim(verficationEntryCode).length === 6) {
      this.setState({ fieldsEmptyState: false });
    } else {
      this.setState({ fieldsEmptyState: true });
    }
  }

  phoneChange = (value, country, e, formattedValue) => {
    this.setState({ phone: value, country: country.dialCode });
    // let pLength = phoneCount(country.format, ".");
    // if (country.countryCode == "qa") {
    //   pLength = 11;
    // }
    this.props.countryPhoneLength(country.dialCode);
  };

  clickSubmit = (key) => {
    //console.log("key", key);
    this.props.autofill("actiontaken", key);
  };

  /* Resend verification code */
  resendVerificationCode = () => {
    let { session, vendor } = this.props;

    this.startTimer();
    let { phone } = this.state;

    let newPhoneNumber = "";
    if (phone === undefined || phone == "") {
      /* Given Number */
      newPhoneNumber = vendor.phone;
    } else {
      /* Already register number */
      newPhoneNumber = phone;
    }
    // newPhoneNumber = newPhoneNumber.replace("-", "");
    // newPhoneNumber = newPhoneNumber.replace(" ", "");

    newPhoneNumber = newPhoneNumber.replace(/[- )( +]/g, "");

    this.props.sendSms(this.props.session, newPhoneNumber, true, (callback) => {
      if (callback.success === 1) {
        toast.success(LocaleStrings.otp_has_been_sent_success);
      } else {
        toast.error(callback.message);
      }
    });
  };

  /***** Form Submit *****/
  onFormSubmit(values) {
    //console.log("values", values);
    let { vendor } = this.props;
    let { hideNumberField } = this.state;
    let actiontaken = values.actiontaken;

    // console.log("actiontaken", actiontaken);
    if (actiontaken == "send") {
      if (values.phone) {
        this.setState({ sendLoader: true });

        let phone = values.phone.replace(/[- )( +]/g, "");

        this.props.sendSms(this.props.session, phone, false, (callback) => {
          this.setState({ sendLoader: false });
          if (callback.success === 1) {
            toast.success(LocaleStrings.send_code_success);
          } else {
            toast.error(callback.message);
          }
        });
      } else {
        toast.error(LocaleStrings.verification_code_mismatch);
      }
    } else if (actiontaken == "verify") {
      //console.log("isVerified", this.state.isVerified);
      let verifiaction_1 = document.getElementsByName("verifiaction_1");
      let verifiaction_2 = document.getElementsByName("verifiaction_2");
      let verifiaction_3 = document.getElementsByName("verifiaction_3");
      let verifiaction_4 = document.getElementsByName("verifiaction_4");
      let verifiaction_5 = document.getElementsByName("verifiaction_5");
      let verifiaction_6 = document.getElementsByName("verifiaction_6");

      let verficationEntryCode =
        verifiaction_1[0].value +
        verifiaction_2[0].value +
        verifiaction_3[0].value +
        verifiaction_4[0].value +
        verifiaction_5[0].value +
        verifiaction_6[0].value;

      if (!this.state.isVerified) {
        this.setState({ verifyLoader: true });
        let otp = verficationEntryCode;
        var params = {};
        params.code = otp;
        params.ischange = !hideNumberField;
        this.props.verifyOtp(this.props.session, params, (callback) => {
          this.setState({ verifyLoader: false });
          if (callback.success === 1) {
            this.setState({ isVerified: true });
            this.setState({ fieldsEmptyState: true });
            toast.success(
              params.ischange
                ? LocaleStrings.otp_verified_successfully
                : LocaleStrings.phone_updated_successfully
            );
            if (!params.ischange) {
              this.props.changeStep(0);

              // Fetch Vendor Details With LoginIdStore
              this.props.fetchUsersDeatils(
                this.props.session,
                (callBackResponce) => {}
              );
            }
          } else {
            this.setState({ fieldsEmptyState: true });
            toast.error(LocaleStrings.otp_not_verified);
          }
        });
      }
    } else if (actiontaken == "update") {
      if (values.phone === undefined) {
        toast.error(
          LocaleStrings.verification_phone_change_number_field_placeholder
        );
        return false;
      }

      if (this.state.isVerified) {
        this.setState({ btnLoader: true });
        let data = {};
        let phone = values.phone ? values.phone : vendor.phone;

        phone = phone.replace(/[- )( +]/g, "");

        data.phone = phone;
        data.userid = this.props.session.userid;
        //data.emailverified = 1;
        data.phoneverified = 1;
        data.verified = 1;
        this.props.updateStoreUsers(this.props.session, data, (callback) => {
          if (callback.status === 1) {
            this.setState({ btnLoader: false });
            this.setState({ verificStateCode: "" });
            this.setState({ fieldsEmptyState: true });
            this.setState({ isVerified: false });

            toast.success(LocaleStrings.phone_updated_successfully);

            this.props.changeStep(0);

            // Fetch Vendor Details With LoginIdStore
            this.props.fetchUsersDeatils(
              this.props.session,
              (callBackResponce) => {}
            );
          }
        });
      } else {
        toast.error(LocaleStrings.mobile_not_verified);
      }
    }
  }

  logOut = () => {
    confirmAlert({
      title: LocaleStrings.logout_confirm_title,
      message: LocaleStrings.logout_confirm_message,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.logout();
            this.props.changeStep(0);
            this.props.history.push("/login");
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    let { initialValues, language, handleSubmit, vendor } = this.props;
    let { hideNumberField, resendLoader, fieldsEmptyState } = this.state;
    let modalFooterText = LocaleStrings.verify;
    //console.log("resendLoader", resendLoader);
    //console.log("vendor", vendor);
    //console.log("fieldsEmptyState=", fieldsEmptyState);
    return (
      <>
        <form
          className=" "
          onSubmit={handleSubmit(this.onFormSubmit.bind(this))}
        >
          <div className="p-4  bg-topnav">
            <p className="text-white text-lg font-medium">
              {LocaleStrings.verification_header_text2}
            </p>
            <p className="text-white text-xs">
              {LocaleStrings.verification_subheader_text2}
            </p>
          </div>
          <div className="p-4 ">
            <div>
              <p className=" text-sm font-medium text-custblack">
                {LocaleStrings.phone_number_verfication_text}
              </p>
            </div>
            <div className={classNames(hideNumberField ? "" : "hidden", "")}>
              <p className="py-2">
                <span
                  className="verify-email-resend"
                  onClick={this.changeNumber}
                >
                  {LocaleStrings.verification_phone_change_number}
                </span>
              </p>
            </div>
            <div
              className={classNames(hideNumberField ? "hidden" : "", "py-4")}
            >
              <Field
                name={"phone"}
                label={
                  LocaleStrings.verification_phone_change_number_field_name
                }
                mandatory="true"
                type="text"
                component={renderPhonewithFlag}
                className="cust-input-field"
                classNameLabel="text-box-lable"
                phone={this.state.phone}
                phoneChange={this.phoneChange}
              />
              {/* SEND CODE BUTTON */}
              <div className="mt-6">
                <button
                  className="rounded py-3 px-5 w-full flex items-center justify-center text-white font-medium text-sm bg-btn"
                  type="submit"
                  onClick={() => this.clickSubmit("send")}
                  disabled={this.state.sendLoader ? true : false}
                >
                  {this.state.sendLoader ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    ""
                  )}
                  <span className="text-sm">{LocaleStrings.send_code}</span>
                </button>
              </div>
            </div>
            {/* Verification input boxes */}
            <div className="grid grid-flow-col grid-cols-6 grid-rows-1 gap-4 md:gap-8">
              <div>
                <Field
                  name="verifiaction_1"
                  placeholder={""}
                  type="text"
                  component={renderFieldText}
                  mandatory="false"
                  maxlength="1"
                  className="text-center"
                  classNameLabel="text-sm font-medium text-secondary"
                  onChange={(e) => this._onVerficationCodeEntry(e, 1)}
                />
              </div>
              <div>
                <Field
                  name="verifiaction_2"
                  id="verifiaction_2"
                  placeholder={""}
                  type="text"
                  component={renderFieldText}
                  mandatory="false"
                  maxlength="1"
                  className="text-center"
                  classNameLabel="text-sm font-medium text-secondary"
                  onChange={(e) => this._onVerficationCodeEntry(e, 2)}
                />
              </div>
              <div>
                <Field
                  name="verifiaction_3"
                  id="verifiaction_3"
                  placeholder={""}
                  type="text"
                  component={renderFieldText}
                  mandatory="false"
                  maxlength="1"
                  className="text-center"
                  classNameLabel="text-sm font-medium text-secondary"
                  onChange={(e) => this._onVerficationCodeEntry(e, 3)}
                />
              </div>
              <div>
                <Field
                  name="verifiaction_4"
                  id="verifiaction_4"
                  placeholder={""}
                  type="text"
                  component={renderFieldText}
                  mandatory="false"
                  maxlength="1"
                  className="text-center"
                  classNameLabel="text-sm font-medium text-secondary"
                  onChange={(e) => this._onVerficationCodeEntry(e, 4)}
                />
              </div>
              <div>
                <Field
                  name="verifiaction_5"
                  id="verifiaction_5"
                  placeholder={""}
                  type="text"
                  component={renderFieldText}
                  mandatory="false"
                  maxlength="1"
                  className="text-center"
                  classNameLabel="text-sm font-medium text-secondary"
                  onChange={(e) => this._onVerficationCodeEntry(e, 5)}
                />
              </div>
              <div>
                <Field
                  name="verifiaction_6"
                  id="verifiaction_6"
                  placeholder={""}
                  type="text"
                  component={renderFieldText}
                  mandatory="false"
                  maxlength="1"
                  className="text-center"
                  classNameLabel="text-sm font-medium text-secondary"
                  onChange={(e) => this._onVerficationCodeEntry(e, 6)}
                />
              </div>
            </div>
          </div>
          <div className="p-4 border-t-2 border-quaternary space-y-5">
            {/* Footer */}
            <button
              className={classNames(
                this.state.fieldsEmptyState
                  ? "cursor-not-allowed bg-gray-300 "
                  : "bg-btn cursor-pointer",
                "rounded py-3 px-5 w-full flex items-center justify-center text-white font-medium text-sm"
              )}
              type="submit"
              onClick={() => this.clickSubmit("verify")}
              disabled={this.state.fieldsEmptyState ? true : false}
            >
              {this.state.verifyLoader ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                ""
              )}
              <span className="text-sm">
                {LocaleStrings.verify_phone_number}
              </span>
            </button>
            <button
              className={classNames(
                hideNumberField ? "hidden " : "bg-btn ",
                "rounded py-3 px-5 w-full flex items-center justify-center text-white font-medium text-sm"
              )}
              type="submit"
              onClick={() => this.clickSubmit("update")}
            >
              {this.state.btnLoader ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                ""
              )}
              <span className="text-sm">
                {LocaleStrings.update_phone_number}
              </span>
            </button>
          </div>
          {/* Resend? */}
          <div>
            {resendLoader === false ? (
              <p className="py-2 text-center">
                <span
                  className="verify-email-resend"
                  onClick={this.resendVerificationCode}
                >
                  {LocaleStrings.resend}
                </span>
              </p>
            ) : (
              <p className="py-2 text-center">
                <span className="verify-email-resend-disabled">
                  {LocaleStrings.resend}
                  {" : "}
                  {this.state.time.s}
                </span>
              </p>
            )}
          </div>
        </form>
        <div className="flex justify-between p-6 pb-5 border-t-2 border-quaternary">
          <div
            className="text-link font-semibold text-sm flex cursor-pointer items-center"
            onClick={() => this.logOut()}
          >
            <span>{LocaleStrings.signout}</span>
          </div>
        </div>
      </>
    );
  }
}

function validate(values, ownProps) {
  var errors = {};
  //console.log("values", values);
  if (values.actiontaken == "send") {
    let onlyPhone = "";
    let countrycode = ownProps.phoneLengthCountry;
    if (values.phone) {
      values.phone = values.phone.replace(/[- )( +]/g, "");
      onlyPhone = values.phone.substring(ownProps.phoneLengthCountry.length);
    }
    if (!values.phone) {
      errors.phone = LocaleStrings.form_validation_phone_no_required;
    } else if (countrycode == "974") {
      if (isValidQatarPhoneNumber("+" + values.phone) == false) {
        errors.phone = LocaleStrings.form_validation_phone_no_valid;
      }
    }
  }

  return errors;
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    localLanguage: state.localLanguage,
    vendor: state.myProfile.data,
    phoneLengthCountry: state.phoneLengthCountry,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    VerifyPhoneStat,
    sendSms,
    verifyOtp,
    fetchUsersDeatils,
    updateStoreUsers,
    countryPhoneLength,
    logout,
  })(
    reduxForm({
      validate,
      form: "VerifyPhoneForm",
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(VerifyPhone)
  )
);
