import React, { Component } from "react";
import { connect } from "react-redux";
import { PushSpinner } from "react-spinners-kit";
import LocaleStrings from "../../../languages/index";

// Import Components

import Loader from "../../../components/Common/loader";
import ProfileSettingsList from "./profilesettingslist";
import AccountSettings from "./accountsettings/accountsettings";
import Address from "./address/";
import Notification from "./notification/";
//import StoreInformation from "./storeinformation/storeinformation";
import ProfileInformation from "./profileinformation/profileinformation";

// Import Actions
import { fetchMyProfile } from "../actions/index";

import toast, { Toaster } from "react-hot-toast";

class ProfileSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loaderDesc: LocaleStrings.preparing_profile_settings,
    };

    // All Binded Functions
  }

  componentDidMount() {
    /**** Fetch Store Information ****/
    this.props.fetchMyProfile(this.props.session, (callback) => {});
  }

  render() {
    return (
      <>
        <div className="bg-newarrival pb-25">
          <div className="custom_container">
            {this.state.loading ? (
              <>
                <Loader />
              </>
            ) : this.props.profSettSelectedMenu === 4 ? (
              // <MyFollowers />
              ""
            ) : this.props.profSettSelectedMenu === 3 ? (
              <div className="grid grid-cols-1 pt-25">
                <div className="bg-white rounded">
                  <AccountSettings />
                </div>
              </div>
            ) : this.props.profSettSelectedMenu === 2 ? (
              <div className="grid grid-cols-1 pt-25">
                <div className="bg-white rounded">
                  <Notification />
                </div>
              </div>
            ) : this.props.profSettSelectedMenu === 1 ? (
              <div className="grid grid-cols-1 pt-25">
                <div className="bg-white rounded">
                  <Address />
                </div>
              </div>
            ) : this.props.profSettSelectedMenu === 0 ? (
              <div className="grid grid-cols-1 pt-25">
                <div className="bg-white rounded">
                  <ProfileInformation />
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-1 pt-25">
                <div className="bg-white rounded">
                  <div className="pl-8 py-3">{LocaleStrings.menu_profile}</div>
                  <ProfileSettingsList />
                </div>
              </div>
            )}
          </div>
        </div>
        <Toaster />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    uDetails: state.StoreDetails.data,
    session: state.session,
    profileSettingsList: state.profileSettingsList,
    profSettSelectedMenu: state.ProfileSettingsSelectedMenu,
  };
}

export default connect(mapStateToProps, {
  fetchMyProfile,
})(ProfileSettings);
