import React, { useState, useEffect, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router-dom";
import {
  deviceSize,
  fetchSettings,
  fetchAds,
  countryPhoneLength,
  fetchUsersDeatils,
} from "./actions";
import "./assets/css/App.css";
import { classNames } from "./components/Common/constant";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import toast, { Toaster } from "react-hot-toast";
import routes from "./routes";
import Header from "./components/header";
import Footer from "./components/footer";
import { parseWithOptions } from "date-fns/fp";

function App(props) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [headerMenu, setHeaderMenu] = useState(true);

  const isVendor =
    location.search && location.search == "?origin=vendor" ? true : false;

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let search = new URLSearchParams(location.search);
    let session_token = search.get("session_token");
    if (session_token) {
      setHeaderMenu(false);
    }

    props.fetchSettings(props.session, (res) => {});
    //props.fetchAds(props.session, (res) => {});
    if (props.session) {
      //props.fetchUsersDeatils(props.session, (res) => {});
    }

    props.countryPhoneLength("974");
    const hideMenu = () => {
      if (window.innerWidth > 768 && isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener("resize", hideMenu);

    return () => {
      window.removeEventListener("resize", hideMenu);
    };
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  function updateSize() {
    if (window.innerWidth < 426) {
      props.deviceSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: true,
      });
    } else if (window.screen.width < 426) {
      props.deviceSize({
        width: window.screen.width,
        height: window.screen.height,
        isMobile: true,
      });
    } else {
      props.deviceSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: false,
      });
    }
  }

  const getRoutes = (routes) => {
    return routes.map((item, idx) => {
      return (
        <Route path={item.path} exact component={item.component} key={idx} />
      );
    });
  };
  return (
    <div
      className={classNames(
        props.localLanguage && props.localLanguage == "ar" ? "rtl" : "",
        "antialiased"
      )}
    >
      {/* <ReactNotification /> */}
      <Toaster />
      {location.pathname == "/reset-password" ||
      location.pathname.indexOf("verifyemail") >= 0 ||
      isVendor ? (
        ""
      ) : headerMenu ? (
        <Header />
      ) : (
        ""
      )}
      <div
        className={
          location.pathname.indexOf("verifyemail") >= 0
            ? "bg-newarrival"
            : "mi min-h-full"
        }
        style={
          location.pathname.indexOf("verifyemail") >= 0
            ? { height: "100vh" }
            : {
                //boxShadow: "rgb(0 0 0 / 19%) 2px 1px 8px",
                minHeight:
                  window.innerWidth < 426
                    ? window.innerHeight - (40 + 100 + 57 + 208 + 89)
                    : window.screen.width < 426
                    ? window.screen.height - (40 + 100 + 57 + 208 + 89)
                    : window.innerHeight - (40 + 100 + 57 + 208 + 89),
              }
        }
      >
        <Switch>{getRoutes(routes)}</Switch>
      </div>
      {location.pathname == "/reset-password" ||
      location.pathname.indexOf("verifyemail") >= 0 ||
      isVendor ? (
        ""
      ) : headerMenu ? (
        <Footer />
      ) : (
        ""
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deviceSize,
      fetchSettings,
      fetchAds,
      countryPhoneLength,
      fetchUsersDeatils,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
