import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  classNames,
  FILES_URL,
  APP_API_KEY,
  timeAgo,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import { LazyLoadImage } from "react-lazy-load-image-component";

function WishlistItem(props) {
  const {
    session,
    isLoggedIn,
    localLanguage,
    item,
    position,
    remLength,
    origin,
  } = props;

  return (
    <>
      <div key={item.id} className="flex flex-col rounded-md overflow-hidden ">
        <div className="border border-primary rounded-md  relative flex items-center justify-center">
          <LazyLoadImage
            className="h-82 w-full object-cover rounded-md"
            src={`${FILES_URL}/vendor/variations/${item.productimage}?api_key=${APP_API_KEY}`}
            effect="blur"
          />
          {remLength > 0 && position == 3 ? (
            <div className="absolute w-full h-full flex items-center justify-center bottom-0 bg-gray-700 text-white text-base text-center leading-4 bg-opacity-60 transition-opacity">
              {remLength}&nbsp;+
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WishlistItem);
