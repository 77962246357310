import axios from "axios";
import _ from "lodash";

import { INSTANCE_URL, APP_API_KEY } from "../../../components/Common/constant";

import {
  getRequestAccess,
  postRequestAccess,
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../components/Common/network-call";
import { getHeaders } from "../../../components/Common/common-utils";

export const BUYING_HISTORY = "BUYING_HISTORY";
export const RECENT_VIEWED = "RECENT_VIEWED";
export const MY_FOLLOWER = "MY_FOLLOWER";
export const PROFILE_MODAL = "PROFILE_MODAL";
export const PROFILE_FOLLOWERS = "PROFILE_FOLLOWERS";
export const PROFILE_FOLLOWERS_DETAILS = "PROFILE_FOLLOWERS_DETAILS";
export const PROFILE_SHAREDWISHLIST = "PROFILE_SHAREDWISHLIST";
export const ACTIVITY_FEED = "ACTIVITY_FEED";
export const NAVIGATE_PROFILE_VIEW = "NAVIGATE_PROFILE_VIEW";
export const PUBLIC_PROFILE = "PUBLIC_PROFILE";
export const CHANGE_PROFILE = "CHANGE_PROFILE";

export function profileViewNavigation(userlist) {
  return (dispatch) => {
    dispatch({
      type: NAVIGATE_PROFILE_VIEW,
      payload: userlist,
    });
  };
}

export function emptyMyBuyingHistory(callback) {
  return (dispatch) => {
    dispatch({
      type: BUYING_HISTORY,
      payload: {},
    });
  };
}

export function fetchMyBuyingHistory(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/buyinghistory`;
  let values = {};

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.result == "success") {
          dispatch({
            type: BUYING_HISTORY,
            payload: { data: response.data },
          });
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function emptyRecentView(callback) {
  return (dispatch) => {
    dispatch({
      type: RECENT_VIEWED,
      payload: {},
    });
  };
}

export function fetchMyRecentView(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/recentlyviewed`;
  let values = {};

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.result == "success") {
          dispatch({
            type: RECENT_VIEWED,
            payload: { data: response.dates },
          });
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}

export function emptyMyFollowers(callback) {
  return (dispatch) => {
    dispatch({
      type: MY_FOLLOWER,
      payload: {},
    });
  };
}

export function fetchMyFollowers(session, userid = "", callback) {
  let getuserid = userid ? userid : session.userid;

  var filter = encodeURI(`(userid=${getuserid})`);
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users_followers?related=*&fields=*&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        //console.log("data", data.resource);
        dispatch({ type: MY_FOLLOWER, payload: { data: data.resource } });
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
}

/***** Is Followers Deatls Modal open or not *****/
export function profileModalOpen(showModal = false, isyou = false) {
  return {
    type: PROFILE_MODAL,
    payload: { showModal: showModal, isyou: isyou },
  };
}

/**** Fetch Selected Followrs Details ****/
export function selectedFollowersDetails(session, followerid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/userfollowers`;

  return (dispatch) => {
    postRequest(
      url,
      {
        profileid: followerid,
      },
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PROFILE_FOLLOWERS,
          payload: { data: response.followers },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Fetch Current Selected Follower User Deatils ****/

export function emptyProfileUser(callback) {
  return (dispatch) => {
    dispatch({
      type: PROFILE_FOLLOWERS_DETAILS,
      payload: {},
    });
  };
}
export function selectedFollowerUserDetails(session, followerid, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/users?filter=userid=${followerid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PROFILE_FOLLOWERS_DETAILS,
          payload: { data: response.resource },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Selected Followers Sheared Wishlist ****/
export function emptySharedWishlist(callback) {
  return (dispatch) => {
    dispatch({
      type: PROFILE_SHAREDWISHLIST,
      payload: {},
    });
  };
}

export function selectedFollowersShearedWishlist(
  session,
  followerid,
  callback
) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/usersharedwishlists`;

  return (dispatch) => {
    postRequest(
      url,
      {
        profileid: followerid,
      },
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PROFILE_SHAREDWISHLIST,
          payload: { data: response.data },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Follow/Unfollow ****/
export function setFollowunfollow(session, followerid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/followunfollow`;

  return (dispatch) => {
    postRequest(
      url,
      {
        followeduser: followerid,
      },
      session,
      dispatch,
      (response) => {
        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

/**** Fetch My Following List ****/

export function fetchMyFolloings(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/userfollowers`;

  return (dispatch) => {
    postRequest(
      url,
      {
        profileid: session.userid,
      },
      session,
      dispatch,
      (response) => {
        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

export function emptyActivity(callback) {
  return (dispatch) => {
    dispatch({
      type: ACTIVITY_FEED,
      payload: {},
    });
  };
}

export function fetchActivity(session, pagenumber = 1, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/activities`;

  let values = {};
  values.limit = 20;
  values.page = pagenumber;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        if (response.result == "success") {
          dispatch({
            type: ACTIVITY_FEED,
            payload: { data: response.data, count: response.count },
          });
          callback({ success: 1, data: response.data, count: response.count });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {}
    );
  };
}

export function viewPublicProfile(session, userid, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/publicprofile`;

  return (dispatch) => {
    postRequest(
      url,
      {
        profileid: userid,
      },
      session,
      dispatch,
      (response) => {
        dispatch({
          type: PUBLIC_PROFILE,
          payload: { data: response.data },
        });

        callback({ status: 1 });
      },
      (error) => {}
    );
  };
}

export function profileChange(isChanged) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_PROFILE,
      payload: isChanged,
    });
  };
}
