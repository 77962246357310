import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
  SMALL_DATE_FORMAT,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
} from "../../../components/Common/product-model";
import BuyingHistory from "./buying-history";
import ActivityFeed from "./activity-feed";
import Wishlist from "../../Wishlist/components/index";
import ProfileView from "./profile-view";
import {
  profileModalOpen,
  selectedFollowerUserDetails,
  selectedFollowersDetails,
  selectedFollowersShearedWishlist,
  emptySharedWishlist,
  emptyActivity,
  fetchActivity,
  profileViewNavigation,
  fetchMyRecentView,
  emptyRecentView,
  fetchMyBuyingHistory,
  emptyMyBuyingHistory,
  viewPublicProfile,
  profileChange,
} from "../actions";
import {
  emptyWishList,
  fetchWishList,
  followUnfollow,
  openReportModal,
} from "../../Product/actions";
import { fetchUsersDeatils } from "../../Profile/actions";
import {
  fetchMyWatchlist,
  emptyMyWatchlist,
  removeWatchlist,
} from "../../Watchlist/actions";
import HammerNodata from "../../../assets/images/hammer-nodata.svg";
import EyeGrey from "../../../assets/images/eye.svg";
import NoImg from "../../../assets/images/noimg.png";
import ItemList from "../../Home/components/item-list";
import SharedWishlist from "./shared-wishlist";
import toast, { Toaster } from "react-hot-toast";

function Activity(props) {
  const history = useHistory();
  const profileRef = useRef();
  const [buyingHistoryOpen, setBuyingHistoryOpen] = useState(false);
  const {
    session,
    isLoggedIn,
    profileModal,
    activityFeed,
    navigateUser,
    myProfile,
    deviceSize,
    myRecentViewed,
    publicProfile,
    myWatchList,
    profileViewChange,
  } = props;
  const [currentTab, setCurrenTab] = useState("activity");
  const [feedData, setFeedData] = useState([]);
  const [isChanged, setIschanged] = useState(true);
  const [followLoader, setFollowLoader] = useState(false);
  const [delItem, setDelItem] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [delloading, setDelloading] = useState(false);
  const [checkedItem, setCheckedItem] = useState([]);
  const [pagenumber, setPagenumber] = useState(1);
  const [tabArr, setTabArr] = useState([
    { key: "activity", text: LocaleStrings.activity },
    { key: "recently_viewed", text: LocaleStrings.acitvity_recently_viewed },
    { key: "wishlist", text: LocaleStrings.acitvity_wishlist },
    { key: "watchlist", text: LocaleStrings.acitvity_watchlist },
    { key: "buying_history", text: LocaleStrings.buying_history },
  ]);

  useEffect(() => {
    if (isLoggedIn) {
      props.emptyActivity();
      setTabArr([]);

      setTabArr([
        { key: "activity", text: LocaleStrings.activity },
        {
          key: "recently_viewed",
          text: LocaleStrings.acitvity_recently_viewed,
        },
        { key: "wishlist", text: LocaleStrings.acitvity_wishlist },
        { key: "watchlist", text: LocaleStrings.acitvity_watchlist },
        { key: "buying_history", text: LocaleStrings.buying_history },
      ]);
      setCurrenTab("activity");
      props.fetchUsersDeatils(session, (res) => {});
      props.fetchActivity(session, 1, (res) => {
        if (res.data) {
          setFeedData(res.data);
        }
      });

      props.profileChange(false);
      setPagenumber(1);
    } else {
      history.push("/login");
    }
  }, [isLoggedIn]);

  const _handleDateTab = (e, selectedTab) => {
    if (selectedTab == "recently_viewed") {
      props.emptyRecentView();
      props.fetchMyRecentView(session);
    } else if (selectedTab == "activity") {
      props.emptyActivity();
      props.fetchActivity(session, 1, (res) => {
        if (res.data) {
          setFeedData(res.data);
        }
      });
    } else if (selectedTab == "wishlist") {
      props.emptyWishList();
      props.fetchWishList(props.session);
    } else if (selectedTab == "buying_history") {
      props.emptyMyBuyingHistory();
      props.fetchMyBuyingHistory(session);
    } else if (selectedTab == "watchlist") {
      props.emptyMyWatchlist();
      props.fetchMyWatchlist(session, selectedTab);
    }

    setCurrenTab(selectedTab);
  };

  const gotoPage = (page) => {
    history.push("/" + page);
  };

  const scrollToProfile = () => {
    profileRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const openFollowersDelaisModal = () => {
    let userArr = navigateUser;
    props.profileModalOpen(true, true);
    props.emptySharedWishlist();
    props.selectedFollowerUserDetails(
      session,
      session.userid,
      (callback) => {}
    );
    props.selectedFollowersDetails(session, session.userid, (callback) => {});
    props.selectedFollowersShearedWishlist(
      session,
      session.userid,
      (callback) => {}
    );
    if (!_.includes(userArr, session.userid)) {
      userArr.push(session.userid);
      props.profileViewNavigation(userArr);
    }
  };

  const recentViewList = (items) => {
    return _.map(items, (item, index) => {
      return (
        <ItemList
          item={item}
          itemid={item.id}
          type="similar"
          prevpage="recent-viewed"
          pagetitle={LocaleStrings.acitvity_recently_viewed}
        />
      );
    });
  };

  const followUnfollow = (userid) => {
    if (isLoggedIn) {
      setFollowLoader(true);
      props.followUnfollow(session, parseInt(userid), (res) => {
        if (res.success == 1) {
          props.selectedFollowerUserDetails(
            session,
            userid,
            (callBackResponce) => {}
          );

          props.viewPublicProfile(session, userid, (res) => {
            setFollowLoader(false);
          });
        } else {
          setFollowLoader(false);
        }
      });
    } else {
      history.push("/login");
    }
  };

  const renderWatchList = () => {
    return _.map(myWatchList.data, (item, index) => {
      return (
        <ItemList
          item={item.product}
          itemid={item.productid}
          type="similar"
          prevpage="watchlist"
          delItem={delItem}
          checkedItem={checkedItem}
          setCheckedItem={setCheckedItem}
          pagetitle={LocaleStrings.watchlist_txt}
        />
      );
    });
  };

  const deleteItem = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDelItem(true);
  };
  const clearItem = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDelItem(false);
    setCheckedItem([]);
  };

  const deleteSelected = () => {
    if (checkedItem.length > 0) {
      let ids = checkedItem.join(",");
      setDelloading(true);
      props.removeWatchlist(session, ids, (res) => {
        setDelItem(false);

        setCheckedItem([]);
        if (res.success == 1) {
          props.emptyMyWatchlist();
          props.fetchMyWatchlist(session, currentTab, (res) => {
            if (res.success == 1) {
              toast.success(LocaleStrings.watchlist_delete_item_success);
            }
          });
        } else {
          setDelloading(false);
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    }
  };

  let modalHeadText = "";
  let modalFooterText = "";
  let productImageUrl = "";
  let followerName = "";
  let profileid = "";
  let followbtn = false;

  let follower = "";
  let following = "";
  let email = "";
  if (myProfile && myProfile.data && myProfile.data.userid) {
    let valueObject = myProfile.data;
    //console.log("valueObject", valueObject);
    productImageUrl = `${FILES_URL}/users/${valueObject.userid}.jpg?api_key=${APP_API_KEY}`;
    followerName = valueObject.username;
    profileid = valueObject.userid;
    followbtn = valueObject.userid == session.userid ? false : true;
    //console.log("followbtn", followbtn);
    follower = valueObject.follower;
    following = valueObject.following;
    email = valueObject.email;
  }

  return (
    <>
      {/* <div className="py-25 custom_container">
        <div>{LocaleStrings.activity}</div>
      </div> */}
      <div
        ref={profileRef}
        className="bg-cart pb-4 border-t border-header md:border-none"
      >
        <div
          className="custom_container bg-white"
          style={{
            paddingLeft: deviceSize.width <= 500 ? 0 : "",
            paddingRight: deviceSize.width <= 500 ? 0 : "",
          }}
        >
          <div className="pt-4 pl-4 flex items-center">
            <div className="pr-27">
              <img
                className="w-profile md:w-20 h-profile md:h-20 rounded-full"
                src={productImageUrl}
                alt=""
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = NoImg;
                }}
              />
            </div>
            <div className=" pr-40">
              <div className="text-base md:text-xl text-numbid font-bold text-center">
                {follower}
              </div>
              <div className="text-xs text-numbid">
                {LocaleStrings.follower}
              </div>
            </div>
            <div>
              <div className="text-base md:text-xl text-numbid font-bold text-center">
                {following}
              </div>
              <div className="text-xs text-numbid">
                {LocaleStrings.following}
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="pr-8">
              <div className=" pt-2.5 pl-4 text-xl text-black font-medium">
                {followerName}
              </div>
              <div className="pl-4 text-sm text-numbid ">{email}</div>
            </div>
            <div className="">
              {followbtn && publicProfile?.data?.length > 0 ? (
                <button
                  className="pt-2.5 pb-2.5 pl-9 pr-9  bg-btn text-white rounded font-normal text-sm inline-flex items-center justify-center"
                  onClick={() => followUnfollow(profileid)}
                >
                  {followLoader ? (
                    <svg
                      className="animate-spin h-5 w-5 rounded-full mr-3"
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="loading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                  ) : publicProfile?.data[0]?.following ? (
                    LocaleStrings.unfollow_btn
                  ) : (
                    LocaleStrings.follow_btn
                  )}
                </button>
              ) : (
                <button
                  className="pt-2.5 pb-2.5 pl-9 pr-9  bg-btn text-white rounded font-normal text-sm inline-flex items-center justify-center"
                  onClick={() => history.push("/profile")}
                >
                  {LocaleStrings.button_edit_profile}
                </button>
              )}
            </div>
          </div>
          <div className="pt-4 ">
            <nav
              className="relative z-0 flex divide-x divide-gray-200 border-t border-header w-screen md:w-full overflow-x-auto"
              aria-label="Tabs"
            >
              {tabArr.map((tab, tabIdx) => (
                <a
                  key={tab.key}
                  className={classNames(
                    currentTab == tab.key ? "text-link " : "text-numbid ",
                    "relative min-w-0 flex-none md:flex-1  py-4 px-4 text-xs md:text-base font-medium text-center focus:z-10 cursor-pointer"
                  )}
                  aria-current={currentTab == tab.key ? "page" : undefined}
                  onClick={(e) => _handleDateTab(e, tab.key)}
                >
                  <span>{tab.text}</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      currentTab == tab.key ? "bg-topnav" : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-0.5"
                    )}
                  />
                </a>
              ))}
            </nav>
          </div>
        </div>

        <div className={currentTab === "activity" ? "" : "hidden"}>
          <ActivityFeed
            feedData={feedData}
            setFeedData={setFeedData}
            setIschanged={setIschanged}
            pagenumber={pagenumber}
            setPagenumber={setPagenumber}
          />
        </div>
        <div className={currentTab === "recently_viewed" ? "" : "hidden"}>
          <div className="mt-5 mb-2.5 custom_container ">
            {myRecentViewed && myRecentViewed.data ? (
              myRecentViewed.data.length > 0 ? (
                <div className="">
                  {_.map(myRecentViewed.data, (item, index) => {
                    return (
                      <div>
                        <div className="text-primary text-base font-medium">
                          {moment(item.date).format(SMALL_DATE_FORMAT)}
                        </div>
                        <div
                          className="pt-5 pb-5 grid
                        grid-cols-2
                        xl:grid-cols-6
                        lg:grid-cols-4
                        md:grid-cols-4
                        sm:grid-cols-4
                        gap-5"
                        >
                          {recentViewList(item.products)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  {LocaleStrings.recently_viewed_not_found}
                </div>
              )
            ) : (
              <Loader data={LocaleStrings.loader_message} />
            )}
          </div>
        </div>
        <div className={currentTab === "wishlist" ? "" : "hidden"}>
          <Wishlist source="activity" />
        </div>
        <div className={currentTab === "watchlist" ? "" : "hidden"}>
          <div className="mt-5 mb-2.5 custom_container ">
            {myWatchList && myWatchList.data ? (
              myWatchList.data.length > 0 ? (
                <>
                  <div className="text-link text-base font-medium">
                    {delItem ? (
                      <a
                        onClick={(e) => clearItem(e)}
                        className="block py-2 cursor-pointer"
                      >
                        {LocaleStrings.clear_all}
                      </a>
                    ) : (
                      <a
                        onClick={(e) => deleteItem(e)}
                        className="block py-2 cursor-pointer"
                      >
                        {LocaleStrings.wishlist_item_delete_option}
                      </a>
                    )}
                  </div>
                  <div className="pt-5 pb-5 grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 gap-5">
                    {renderWatchList()}
                  </div>
                  {checkedItem.length > 0 ? (
                    <div className="mt-6">
                      <button
                        type="submit"
                        className="text-white h-44 bg-delitem text-sm font-normal rounded w-full inline-flex items-center justify-center"
                        onClick={() => deleteSelected()}
                      >
                        {delloading ? (
                          <svg
                            className="animate-spin h-5 w-5 rounded-full mr-3"
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                        ) : (
                          ""
                        )}
                        <span className="text-sm">
                          {LocaleStrings.button_delete} &nbsp;
                          {checkedItem.length}&nbsp;
                          {checkedItem.length == 1
                            ? LocaleStrings.item
                            : LocaleStrings.items}
                        </span>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <div className="flex h-cardimg items-center justify-center">
                  <div>
                    <div className="flex items-center justify-center pb-5">
                      <img src={EyeGrey} className="w-8" />
                    </div>
                    <div className="flex items-center justify-center text-cutoff text-base font-medium">
                      {LocaleStrings.no_watching_title}
                    </div>
                    <div className="flex items-center justify-center text-cutoff text-sm">
                      {LocaleStrings.no_watching_desc}
                    </div>
                  </div>
                </div>
              )
            ) : (
              <Loader data={LocaleStrings.loader_message} />
            )}
          </div>
        </div>
        <div className={currentTab === "buying_history" ? "" : "hidden"}>
          <BuyingHistory />
        </div>
        <div className={currentTab === "shared_wishlist" ? "" : "hidden"}>
          <SharedWishlist />
        </div>
      </div>

      {profileModal && profileModal.showModal ? <ProfileView /> : ""}
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  profileModal: state.profileModal,
  activityFeed: state.activityFeed,
  navigateUser: state.navigateUser,
  myProfile: state.myProfile,
  deviceSize: state.deviceSize,
  myRecentViewed: state.myRecentViewed,
  publicProfile: state.publicProfile,
  myWatchList: state.myWatchList,
  profileViewChange: state.profileViewChange,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      profileModalOpen,
      selectedFollowerUserDetails,
      selectedFollowersDetails,
      selectedFollowersShearedWishlist,
      emptySharedWishlist,
      fetchActivity,
      emptyActivity,
      profileViewNavigation,
      fetchUsersDeatils,
      fetchMyRecentView,
      emptyRecentView,
      fetchMyBuyingHistory,
      emptyMyBuyingHistory,
      emptyWishList,
      fetchWishList,
      followUnfollow,
      viewPublicProfile,
      fetchMyWatchlist,
      emptyMyWatchlist,
      removeWatchlist,
      profileChange,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
