import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../../languages/index";
import _ from "lodash";
// Import Components
import Loader from "../../../../components/Common/loader";
import { confirmAlert } from "react-confirm-alert";
import toast, { Toaster } from "react-hot-toast";
import CommonHeaderProfileSettings from "../commonheaderprofilesettings";
// Import Actions
import {
  fetchNotificationSetting,
  updateNotificationSetting,
} from "../../actions";
import { classNames } from "../../../../components/Common/constant";
import ToggleSwitch from "../ToggleSwitch";

class Notification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_profile_information,
      btnLoader: false,
    };

    // All Binded Functions
  }

  componentDidMount() {
    // Fetch Vendor Details With LoginIdStore
    this.props.fetchNotificationSetting(
      this.props.session,
      (callBackResponce) => {
        this.setState({ loading: false });
      }
    );
  }
  onNotificationChecked = (checked, field) => {
    let object = {};
    object[field] = checked ? 1 : 0;

    this.props.updateNotificationSetting(
      this.props.session,
      object,
      (callback) => {
        if (callback.status === 1) {
          // Fetch Vendor Details With LoginIdStore
          this.props.fetchNotificationSetting(
            this.props.session,
            (callBackResponce) => {}
          );
        }
      }
    );
  };
  render() {
    let { notificationSetting, canReceivedNotification } = this.props;
    //console.log("notificationSetting", notificationSetting);
    return (
      <>
        <CommonHeaderProfileSettings
          commonHeaderText={LocaleStrings.profile_settings_list_text3}
        />
        {_.isEmpty(notificationSetting) ? (
          <div className="py-6">
            <Loader />
          </div>
        ) : (
          <div className="px-6">
            <div className="pb-4">
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  {LocaleStrings.nsetting_auction_updates}
                </div>
                <div>
                  <ToggleSwitch
                    label={LocaleStrings.nsetting_auction_updates}
                    notificationchecked={notificationSetting.auction_updates}
                    onChange={this.onNotificationChecked}
                    disabled={canReceivedNotification ? false : true}
                    field="auction_updates"
                  />
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  {LocaleStrings.nsetting_aubid_or_win}
                </div>
                <div>
                  <ToggleSwitch
                    label={LocaleStrings.nsetting_aubid_or_win}
                    notificationchecked={notificationSetting.aubid_or_win}
                    onChange={this.onNotificationChecked}
                    disabled={canReceivedNotification ? false : true}
                    field="aubid_or_win"
                  />
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  {LocaleStrings.nsetting_interested_updated}
                </div>
                <div>
                  <ToggleSwitch
                    label={LocaleStrings.nsetting_interested_updated}
                    notificationchecked={notificationSetting.interested_updated}
                    onChange={this.onNotificationChecked}
                    disabled={canReceivedNotification ? false : true}
                    field="interested_updated"
                  />
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  {LocaleStrings.nsetting_order_update}
                </div>
                <div>
                  <ToggleSwitch
                    label={LocaleStrings.nsetting_order_update}
                    notificationchecked={notificationSetting.order_update}
                    onChange={this.onNotificationChecked}
                    disabled={canReceivedNotification ? false : true}
                    field="order_update"
                  />
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  {LocaleStrings.nsetting_receive_message}
                </div>
                <div>
                  <ToggleSwitch
                    label={LocaleStrings.nsetting_receive_message}
                    notificationchecked={notificationSetting.receive_message}
                    onChange={this.onNotificationChecked}
                    disabled={canReceivedNotification ? false : true}
                    field="receive_message"
                  />
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  {LocaleStrings.nsetting_top_deal}
                </div>
                <div>
                  <ToggleSwitch
                    label={LocaleStrings.nsetting_top_deal}
                    notificationchecked={notificationSetting.top_deal}
                    onChange={this.onNotificationChecked}
                    disabled={canReceivedNotification ? false : true}
                    field="top_deal"
                  />
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  {LocaleStrings.nsetting_followed}
                </div>
                <div>
                  <ToggleSwitch
                    label={LocaleStrings.nsetting_followed}
                    notificationchecked={notificationSetting.followed}
                    onChange={this.onNotificationChecked}
                    disabled={canReceivedNotification ? false : true}
                    field="followed"
                  />
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  {LocaleStrings.nsetting_invited}
                </div>
                <div>
                  <ToggleSwitch
                    label={LocaleStrings.nsetting_invited}
                    notificationchecked={notificationSetting.invited}
                    onChange={this.onNotificationChecked}
                    disabled={canReceivedNotification ? false : true}
                    field="invited"
                  />
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  {LocaleStrings.nsetting_mentioned}
                </div>
                <div>
                  <ToggleSwitch
                    label={LocaleStrings.nsetting_mentioned}
                    notificationchecked={notificationSetting.mentioned}
                    onChange={this.onNotificationChecked}
                    disabled={canReceivedNotification ? false : true}
                    field="mentioned"
                  />
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  {LocaleStrings.nsetting_violation}
                </div>
                <div>
                  <ToggleSwitch
                    label={LocaleStrings.nsetting_violation}
                    notificationchecked={notificationSetting.violation}
                    onChange={this.onNotificationChecked}
                    disabled={canReceivedNotification ? false : true}
                    field="violation"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.myProfile,
    session: state.session,
    notificationSetting:
      state.notificationSetting && state.notificationSetting.data
        ? state.notificationSetting.data
        : {},
    canReceivedNotification:
      state.canReceivedNotification && state.canReceivedNotification.data
        ? state.canReceivedNotification.data
        : false,
  };
}

export default connect(mapStateToProps, {
  fetchNotificationSetting,
  updateNotificationSetting,
})(Notification);
