import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import {
  FILES_URL,
  APP_API_KEY,
  timeDiffCalc,
  timeDiffRemaining,
  classNames,
} from "../../../components/Common/constant";
import {
  ProductTextLocalized,
  updateMRP,
  currencyCode,
  currencyRate,
} from "../../../components/Common/product-model";
import { fetchProducts } from "../../Home/actions";
import StarIcon from "../../../assets/images/star.svg";
import LikeIcon from "../../../assets/images/like.svg";
import EyeGreyIcon from "../../../assets/images/eye.svg";
import LocaleStrings from "../../../languages";
//import LikeIcon from "../../../assets/images/wishlist.svg";
import { parse } from "date-fns";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ItemList(props) {
  const history = useHistory();
  const {
    item,
    localLanguage,
    type,
    prevpage,
    pagetitle,
    delItem,
    setCheckedItem,
    itemid,
    checkedItem,
    setIschanged,
    localCurrency,
    deviceSize,
  } = props;
  const [timeText, setTimeText] = useState("");
  const [timeInterval, setTimeInterval] = useState(0);
  const [remainingTime, setRemainingTime] = useState({});
  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 2000);

  var auctionText = "";
  var endDate = "";
  if (item.saletype == "auction") {
    let currentDate = moment();
    if (
      item.auction_start_date &&
      item.auction_end_date &&
      currentDate.isAfter(moment(item.auction_start_date)) &&
      currentDate.isBefore(moment(item.auction_end_date))
    ) {
      auctionText = LocaleStrings.ends_in;
      endDate = item.auction_end_date;
    } else if (
      item.auction_start_date &&
      item.auction_end_date &&
      currentDate.isBefore(moment(item.auction_start_date)) &&
      currentDate.isBefore(moment(item.auction_end_date))
    ) {
      auctionText = LocaleStrings.starts_in;
      endDate = item.auction_start_date;
    } else if (
      item.auction_start_date &&
      item.auction_end_date &&
      currentDate.isAfter(moment(item.auction_start_date)) &&
      currentDate.isAfter(moment(item.auction_end_date))
    ) {
      auctionText = LocaleStrings.expired;
      endDate = "";
    }
  }

  useEffect(() => {
    if (endDate) {
      let timeText = timeDiffCalc(
        new Date(endDate.replace(/-/g, "/")),
        new Date()
      );
      setTimeText(timeText);
      let remObj = timeDiffRemaining(
        new Date(endDate.replace(/-/g, "/")),
        new Date()
      );
      setRemainingTime(remObj);
    }
  }, [timeInterval, endDate]);

  const detailsView = (productId) => {
    if (setIschanged) {
      setIschanged(true);
    }
    if (prevpage) {
      //history.push("/product-details/" + productId, { from: prevpage });
      // history.push({
      //   pathname: "/product-details/" + productId,
      //   state: { from: prevpage, pagetitle: pagetitle },
      // });

      window.open("/product-details/" + productId, "_blank");
    } else {
      //history.push("/product-details/" + productId);
      history.push({
        pathname: "/product-details/" + productId,
        state: { from: "/" },
      });
    }

    window.scrollTo(0, 0);
  };
  // console.log("localCurrency", localCurrency);
  // console.log("item", item);
  const all_variants = item?.price[localCurrency.toLowerCase()]?.all_variants;
  //console.log("all_variants", all_variants);
  let price = _.filter(all_variants, function (o) {
    return o.id == item.variantid;
  });
  //console.log("price", price);
  let currency = currencyCode(localCurrency);
  //console.log("currency", currency);
  let currencyrate = currencyRate(localCurrency);

  let itemPrice = price.length > 0 ? price[0].price_unit : 0;
  itemPrice = itemPrice;
  let priceObj = updateMRP(item, item.variantid, localCurrency);
  let discountedPrice = priceObj.discountedPrice;
  discountedPrice = discountedPrice;
  let strProductDiscount = priceObj.strProductDiscount;
  //strProductDiscount = strProductDiscount * currencyrate;

  const checkUncheck = (itemid) => {
    let newArr = [];
    if (checkedItem.length > 0) {
      if (_.includes(checkedItem, itemid)) {
        _.map(checkedItem, (obj, index) => {
          if (obj != itemid) {
            newArr.push(obj);
          }
        });
        setCheckedItem(newArr);
      } else {
        _.map(checkedItem, (obj, index) => {
          newArr.push(obj);
        });
        newArr.push(itemid);
        setCheckedItem(newArr);
      }
    } else {
      newArr.push(itemid);
      setCheckedItem(newArr);
    }
    //console.log("itemid", itemid);
  };
  //console.log("item", item);

  let charCount = deviceSize.isMobile ? 17 : 30;
  let varCharCount = deviceSize.isMobile ? 10 : 20;
  return (
    <>
      <div
        key={item.id}
        className="w-168 flex flex-col rounded-md overflow-hidden hover:shadow-md cursor-pointer"
        onClick={
          delItem
            ? null
            : () =>
                detailsView(
                  _.kebabCase(item.productname_en) +
                    "-" +
                    item.id +
                    "-" +
                    item.variantid
                )
        }
      >
        <div
          className={classNames(
            type && type == "similar" ? "border border-primary rounded-md" : "",
            "flex-shrink-0 relative imagewrapper bg-white"
          )}
        >
          <LazyLoadImage
            className={classNames(
              type && type == "similar" ? "rounded-md" : "",
              "h-productimg w-full object-cover"
            )}
            src={`${FILES_URL}/vendor/variations/${item.productimage}?api_key=${APP_API_KEY}`}
            effect="blur"
          />
          {/* <img
            className={classNames(
              type && type == "similar" ? "rounded-md" : "",
              "h-productimg w-full object-cover"
            )}
            src={`${FILES_URL}/vendor/variations/${item.productimage}?api_key=${APP_API_KEY}`}
            alt=""
          /> */}
          {delItem ? (
            <div className="absolute top-2 pl-3">
              <input
                type="checkbox"
                name="delitem"
                value={itemid}
                onClick={() => checkUncheck(itemid)}
              />
            </div>
          ) : (
            ""
          )}
          {item.saletype == "directsale" &&
          item.currentvariant &&
          item.currentvariant.outofstock == 1 ? (
            <div className="absolute top-0 right-0 bg-progress_red text-white text-10  rounded-bl-md px-2">
              {LocaleStrings.out_of_stock}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="flex-1 bg-white pl-2 pr-2 flex flex-col justify-between">
          <div className="text-left">
            <a
              className="block mt-2"
              href={
                prevpage
                  ? ""
                  : `/product-details/${
                      _.kebabCase(item.productname_en) +
                      "-" +
                      item.id +
                      "-" +
                      item.variantid
                    }`
              }
            >
              <p className="text-xs  md:text-base text-primary font-normal">
                {ProductTextLocalized(
                  item.productname_en,
                  item.productname_ar,
                  localLanguage
                ).length >= charCount
                  ? ProductTextLocalized(
                      item.productname_en,
                      item.productname_ar,
                      localLanguage
                    ).substr(0, charCount)
                  : ProductTextLocalized(
                      item.productname_en,
                      item.productname_ar,
                      localLanguage
                    )}
                {!deviceSize.isMobile && !type && item.hasvariant == 1
                  ? ProductTextLocalized(
                      item.variantname_en,
                      item.variantname_en,
                      localLanguage
                    ).length >= varCharCount
                    ? " - " +
                      ProductTextLocalized(
                        item.variantname_en,
                        item.variantname_en,
                        localLanguage
                      ).substr(0, varCharCount)
                    : " - " +
                      ProductTextLocalized(
                        item.variantname_en,
                        item.variantname_en,
                        localLanguage
                      )
                  : ""}
              </p>
            </a>
          </div>
        </div>
        {prevpage && prevpage == "recent-viewed" && item.isbanned ? (
          <div className="bg-white rounded-md rounded-t-none  pt-1.5 md:pt-3">
            <div className="text-sm md:text-base text-red-600 px-3 py-1 mt-2 whitespace-nowrap">
              {LocaleStrings.temporarily_unavailable}
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-md rounded-t-none  pt-1.5 md:pt-3">
            <div className="px-2 mb-1.5">
              {currency == "QAR" ? (
                localLanguage == "en" ? (
                  <p className="text-left text-sm md:text-base lg:text-xl font-bold text-primary">
                    {currency}
                    {parseFloat(discountedPrice).toFixed(2)}
                  </p>
                ) : (
                  <p className="text-left text-sm md:text-base lg:text-xl font-bold text-primary">
                    {parseFloat(discountedPrice).toFixed(2)}
                    {currency}
                  </p>
                )
              ) : localLanguage == "en" ? (
                <div className="flex items-center">
                  <div className="text-left text-sm md:text-base font-bold text-primary pr-0.5">
                    {currency}
                  </div>
                  <div className="text-left text-sm md:text-base lg:text-xl font-bold text-primary">
                    {parseFloat(discountedPrice).toFixed(2)}
                  </div>
                </div>
              ) : (
                <div className="flex items-center">
                  <div className="text-left text-sm md:text-base lg:text-xl font-bold text-primary">
                    {parseFloat(discountedPrice).toFixed(2)}
                  </div>
                  <div className="text-left text-sm md:text-base font-bold text-primary pr-0.5">
                    {currency}
                  </div>
                </div>
              )}
            </div>
            {item.saletype != "auction" ? (
              <div className="grid grid-cols-2 gap-2  px-2">
                <div>
                  {strProductDiscount ? (
                    currency == "QAR" ? (
                      <p className=" text-10 md:text-sm font-normal text-linethrough line-through">
                        {currency}
                        {parseFloat(itemPrice).toFixed(2)}
                      </p>
                    ) : (
                      <p className="text-10 md:text-sm font-normal text-linethrough line-through">
                        {currency}
                        {parseFloat(itemPrice).toFixed(2)}
                      </p>
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className="text-10 md:text-sm font-bold text-discount">
                  {strProductDiscount}
                </div>
              </div>
            ) : (
              ""
            )}
            {item.saletype == "auction" ? (
              <>
                <div className="grid grid-cols-3 gap-1 px-2 pb-3 md:pb-4 mt-1.5  md:mt-3.5">
                  {auctionText ? (
                    endDate ? (
                      <div className="col-span-2">
                        <div className="text-xs text-secondary">
                          {auctionText}
                        </div>
                        <div>
                          {remainingTime.days <= 0 &&
                          remainingTime.hours <= 24 ? (
                            <div className="flex items-center justify-center bg-red-500 w-full text-10 md:text-base text-white  rounded-2xl px-2 py-1">
                              {timeText}
                            </div>
                          ) : (
                            <div className="flex items-center justify-center bg-topnav w-full text-10 md:text-base text-white  rounded-2xl px-2 py-1">
                              {timeText}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="col-span-2">
                        <div className="text-xs text-secondary">
                          {item.bidcancelled
                            ? LocaleStrings.cancelled
                            : auctionText}
                        </div>
                        <div>
                          <div
                            className="flex items-center justify-center bg-red-600 w-full text-white rounded-xl pt-1 pb-1"
                            style={{ fontSize: 10 }}
                          >
                            {LocaleStrings.bid_over}
                          </div>
                        </div>
                      </div>
                    )
                  ) : (
                    ""
                  )}

                  <div className="flex items-end px-2">
                    {localLanguage == "en" ? (
                      <div className="text-xs flex items-center">
                        <div className="pr-1 text-10 md:text-base font-medium text-numbid">
                          {item.numbids}
                        </div>
                        <div className="text-10 md:text-base font-medium text-numbid">
                          {" "}
                          {item.numbids > 1
                            ? LocaleStrings.bid
                            : LocaleStrings.nobid}
                        </div>
                      </div>
                    ) : (
                      <div className="text-xs flex items-center">
                        <div className="text-10 md:text-base font-medium text-numbid">
                          {item.numbids > 1
                            ? LocaleStrings.bid
                            : LocaleStrings.nobid}
                        </div>
                        <div className="pr-1 text-10 md:text-base font-medium text-numbid">
                          {" "}
                          {item.numbids}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {localLanguage == "en" ? (
                  <div className="text-sm flex items-center pb-4 px-2">
                    <div className="pr-1.5">
                      <img src={EyeGreyIcon} className=" w-3.5 md:w-auto" />
                    </div>
                    <div className="text-11  md:text-sm font-medium text-cardfooter">
                      {item.numwatching}
                    </div>
                  </div>
                ) : (
                  <div className="text-sm flex items-center pb-4 px-2">
                    <div className="text-11  md:text-sm font-medium text-cardfooter">
                      {item.numwatching}
                    </div>
                    <div className="pr-1.5">
                      <img src={EyeGreyIcon} className=" w-3.5 md:w-auto" />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="grid grid-cols-3 gap-2 px-2 pb-2.5 md:pb-4 mt-3 md:mt-6">
                <div className="flex items-center">
                  {localLanguage == "en" ? (
                    <>
                      <div className="pr-xs">
                        <img src={StarIcon} className=" w-3.5 md:w-auto" />
                      </div>

                      <div className="text-11 md:text-sm font-medium text-cardfooter">
                        {item.ratings && item.ratings.avgrating
                          ? item.ratings.avgrating
                          : 0}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-11 md:text-sm font-medium text-cardfooter">
                        {item.ratings && item.ratings.avgrating
                          ? item.ratings.avgrating
                          : 0}
                      </div>
                      <div className="pr-xs">
                        <img src={StarIcon} className=" w-3.5 md:w-auto" />
                      </div>
                    </>
                  )}
                </div>
                {localLanguage == "en" ? (
                  <div className="flex items-center">
                    <div className="pr-xs">
                      <img src={LikeIcon} className="w-3.5 md:w-auto" />
                    </div>
                    <div className="text-11 md:text-sm font-medium text-cardfooter">
                      {item.numlikes}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <div className="text-11 md:text-sm font-medium text-cardfooter">
                      {item.numlikes}
                    </div>
                    <div className="pr-xs">
                      <img src={LikeIcon} className="w-3.5 md:w-auto" />
                    </div>
                  </div>
                )}
                <div className="flex items-center">
                  {localLanguage == "en" ? (
                    <p className="text-11 md:text-sm font-medium text-cardfooter">
                      {item.numsold} {LocaleStrings.sold_txt}
                    </p>
                  ) : (
                    <p className="text-11 md:text-sm font-medium text-cardfooter">
                      {LocaleStrings.sold_txt} {item.numsold}
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  // isLoggedIn: state.isLoggedIn,
  // session: state.session
  localLanguage: state.localLanguage,
  localCurrency: state.localCurrency,
  deviceSize: state.deviceSize,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
