import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import {
  renderFieldRadio,
  renderFieldText,
  renderFieldTextarea,
} from "../../../components/Common/base-component";
import toast from "react-hot-toast";
import LocaleStrings from "../../../languages/index";
import { sendInvitation } from "../actions";

// Import constant
import {
  validateEmail,
  classNames,
  LABEL_POSITION_TOP,
} from "../../../components/Common/constant";

class Invitation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      btnLoader: false,
      isBtnDisable: false,
      fileName: "",
      fileArr: [],
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  /***** Form Submit *****/
  onFormSubmit(values) {
    this.setState({ isBtnDisable: true });
    this.setState({ btnLoader: true });
    values.emailtype = "invitation";
    this.props.sendInvitation(this.props.session, values, (res) => {
      this.props.reset();
      if (res.status == 1) {
        this.setState({ isBtnDisable: false });
        this.setState({ btnLoader: false });
        toast.success(LocaleStrings.invitation_submit_success);
      } else {
        this.setState({ isBtnDisable: false });
        this.setState({ btnLoader: false });
        toast.error(LocaleStrings.common_fail_message);
      }
    });
  }

  render() {
    let { handleSubmit } = this.props;
    let { fileArr } = this.state;

    return (
      <>
        <div className="bg-newarrival py-25">
          <div className="flow-root bg-white rounded custom_container">
            <form
              className=" "
              onSubmit={handleSubmit(this.onFormSubmit.bind(this))}
            > 
             
                <div className=" p-5 space-y-6">
                  <div className="">
                    <p className="text-base font-medium text-primary">
                      {LocaleStrings.my_invitation}
                    </p>
                  </div>

                  <Field
                    name={"invite_message"}
                    label={LocaleStrings.invite_message}
                    placeholder={LocaleStrings.invite_message}
                    mandatory="false"
                    component={renderFieldTextarea}
                    labelposition={LABEL_POSITION_TOP}
                    classNameLabel="text-box-lable"
                    className="py-3 px-4 block w-full shadow-sm text-primary border border-warm-gray-300 rounded-md"
                  />
                  <Field
                    name={"emailids"}
                    label={LocaleStrings.email_invitation}
                    placeholder={
                      LocaleStrings.app_suggestions_plshldr_suggestion_desc
                    }
                    mandatory="false"
                    component={renderFieldTextarea}
                    labelposition={LABEL_POSITION_TOP}
                    classNameLabel="text-box-lable"
                    className="py-3 px-4 block w-full shadow-sm text-primary border border-warm-gray-300 rounded-md"
                  />
                </div>
                <div className="p-5 border-t-2 border-quaternary">
                  <div className="flex justify-center items-center">
                    
                      <button
                          type="submit"
                          disabled={this.state.isBtnDisable}
                          className="rounded py-3 px-5 w-full md:w-80 lg:w-2/6 flex items-center justify-center bg-btn text-white font-medium text-sm cursor-pointer"
                        >
                          {this.state.btnLoader ? (
                            <svg
                              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          ) : (
                            ""
                          )}
                          <span className="text-sm md:text-base">
                            {LocaleStrings.send_invitation}
                          </span>
                      </button>
                   
                  </div>

                  {/* Footer */}
                </div>
             
            </form>
          </div>
        </div>
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var requiredFields = ["invite_message", "emailids"];
  var email = values["emailids"];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field].trim() === "") {
      errors[field] = "Required";
    }
  });

  let counter = 0;
  if (email) {
    var emailArr = email.split(",");

    if (emailArr.length > 0) {
      emailArr.forEach((field) => {
        if (!validateEmail(field)) {
          counter++;
        }
      });
      if (counter > 0) {
        errors["emailids"] = "Invalid email(s)";
      }
    }
  } else {
    errors["emailids"] = "Invalid email(s)";
  }

  return errors;
}

function mapStateToProps(state) {
  var { session, user, language, isLoggedIn } = state;

  return {
    session,
    user,
    isLoggedIn,
    language,
    initialValues: {},
  };
}

export default connect(mapStateToProps, { sendInvitation })(
  reduxForm({
    validate,
    form: "InvitationForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Invitation)
);
