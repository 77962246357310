import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  FocusScope,
  OverlayContainer,
  useOverlay,
  useModal,
  useDialog,
} from "react-aria";
import { motion, AnimatePresence } from "framer-motion";
import { classNames } from "../Common/constant";
import { XIcon } from "@heroicons/react/outline";
import { openSliderPop } from "../../actions";

function SlideOverContent(props) {
  const { children, title, handleClose, originate, bgcol } = props;
  const ref = useRef();
  const { overlayProps } = useOverlay(props, ref);
  const { modalProps } = useModal(props, ref);
  const { dialogProps, titleProps } = useDialog(props, ref);

  return (
    <div className="fixed inset-0 overflow-hidden z-50">
      <div className="absolute inset-0 overflow-hidden">
        <motion.div
          className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        ></motion.div>
        <motion.section
          className="fixed inset-y-0 right-0 max-w-full flex"
          aria-labelledby="slide-over-heading"
          ref={ref}
          {...overlayProps}
          {...dialogProps}
          {...modalProps}
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%" }}
          transition={{ ease: "easeInOut", duration: 0.5 }}
        >
          <FocusScope contain restoreFocus autoFocus>
            <div className="w-screen max-w-otherslider md:max-w-md">
              {title ? (
                ""
              ) : (
                <div className="absolute top-0 left-0 -ml-8 pt-4 flex sm:-ml-10 sm:pr-4">
                  <button
                    onClick={handleClose}
                    className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}
              <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                {title ? (
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <div>
                        <h2
                          id="slide-over-heading"
                          className="text-xl font-medium text-primary"
                          {...titleProps}
                        >
                          {title}
                        </h2>
                      </div>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          onClick={handleClose}
                          className="bg-white text-primary hover:text-gray-500 focus:outline-none"
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div
                  className={classNames(
                    title ? "mt-6 " : "",
                    bgcol ? bgcol : " px-4 sm:px-6",
                    "relative flex-1"
                  )}
                >
                  {children}
                </div>
              </div>
            </div>
          </FocusScope>
        </motion.section>
      </div>
    </div>
  );
}

function SlideOver(props) {
  const { children, title, openSliderPop, isSliderPop, originate, bgcol } =
    props;
  const handleClose = () => {
    openSliderPop(false);
  };
  return (
    <OverlayContainer>
      <AnimatePresence>
        {isSliderPop && isSliderPop.showModal && (
          <SlideOverContent
            title={title}
            handleClose={handleClose}
            originate={originate}
            bgcol={bgcol}
            isDismissable
          >
            {children}
          </SlideOverContent>
        )}
      </AnimatePresence>
    </OverlayContainer>
  );
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  localLanguage: state.localLanguage,
  deviceSize: state.deviceSize,
  isSliderPop: state.isSliderPop,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ openSliderPop }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SlideOver);
