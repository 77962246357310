import axios from "axios";
import _ from "lodash";

import { INSTANCE_URL, APP_API_KEY } from "../../../components/Common/constant";

import {
  getRequestAccess,
  postRequestAccess,
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../components/Common/network-call";
import { getHeaders } from "../../../components/Common/common-utils";

export const NEGOTIATION_LIST = "NEGOTIATION_LIST";
export const IS_NEGO_SLIDEOVERS_MODAL_OPEN = "IS_NEGO_SLIDEOVERS_MODAL_OPEN";
export const NEGOTIATION_DETAILS_VIEW = "NEGOTIATION_DETAILS_VIEW";

export function negotiationThread(threadid = "") {
  return { type: NEGOTIATION_DETAILS_VIEW, payload: { threadid: threadid } };
}

export function isNegotiationOpen(isOpen) {
  return { type: IS_NEGO_SLIDEOVERS_MODAL_OPEN, payload: isOpen };
}

export function emptyNegotiation(callback) {
  return (dispatch) => {
    dispatch({
      type: NEGOTIATION_LIST,
      payload: {},
    });
  };
}

export function fetchNegotiation(session, tab, callback) {
  var url = `${INSTANCE_URL}/api/v2/consumer-datascript/negotiations`;
  let values = {};
  values.usertype = "consumer";
  values.status = tab;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        //console.log("response", response);
        if (response.result == "success") {
          dispatch({
            type: NEGOTIATION_LIST,
            payload: { data: response.data },
          });
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        //callback({ success: 0, message: error });
      }
    );
  };
}
