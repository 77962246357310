import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";
import {
  renderFieldText,
  renderFieldTextarea,
  renderFieldCheckbox,
} from "../../../components/Common/base-component";
import AppleLogo from "../../../assets/images/apple.svg";
import FacebookLogo from "../../../assets/images/fb-login.png";
import TwitterLogo from "../../../assets/images/tw-login.png";
import GoogleLogo from "../../../assets/images/google-plus.png";
import toast, { Toaster } from "react-hot-toast";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import ForgotPassword from "./forgotpassword";
import {
  FACEBOOKID,
  GOOGLEID,
  CONSUMER_KEY,
  CONSUMER_SECRET,
  SOCIALPWD,
  randomString,
} from "../../../components/Common/constant";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import TwitterLogin from "react-twitter-login";
import {
  mainLogin,
  resetStore,
  isForgotPasswordOpen,
  validateSocialId,
  socialSignupAvailable,
  addConsumer,
  banAction,
} from "../actions";
import {
  addCart,
  fetchCart,
  validateUserCart,
  storeCart,
} from "../../Product/actions";

function Signin(props) {
  const history = useHistory();
  const location = useLocation();
  const goToPreviousPath = () => {
    history.goBack();
    window.scrollTo(0, 0);
  };
  let {
    session,
    deviceSize,
    handleSubmit,
    localLanguage,
    forgotPwdOpen,
    getStoredCart,
    banAccount,
  } = props;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fbloader, setFBLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (banAccount) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="text-center">
              <div className="text-base text-red-500">
                {LocaleStrings.ban_account_text}
              </div>
              <div className="mt-4">
                <button
                  onClick={() => {
                    props.banAction(false);
                    history.push("/faq");
                    onClose();
                  }}
                  className="rounded py-3 px-5 w-full flex items-center justify-center bg-btn text-white font-medium text-sm cursor-pointer"
                >
                  {LocaleStrings.contact_support}
                </button>
              </div>
            </div>
          );
        },
      });
    }
  }, [banAccount]);

  const onSubmitForm = (values) => {
    setLoading(true);
    var data = {
      email: values.email,
      password: values.password,
      type: values.email.indexOf("@") >= 0 ? "email" : "phone",
    };

    props.mainLogin(data, (response) => {
      if (response.success === 0) {
        setLoading(false);
        toast.error(LocaleStrings.invalid_email_password);
        props.resetStore();
      } else {
        toast.success(LocaleStrings.login_success);
        addtoCart(response.session);
      }
    });
  };

  const addtoCart = (sessionobj) => {
    // console.log("session", sessionobj);

    if (
      getStoredCart.hasOwnProperty("productid") &&
      getStoredCart.hasOwnProperty("variantid") &&
      getStoredCart.hasOwnProperty("quantity")
    ) {
      let values = {};
      props.validateUserCart(
        sessionobj,
        { variantid: getStoredCart.variantid },
        (res) => {
          if (res.success && res.valid) {
            values.productid = getStoredCart.productid;
            values.variantid = getStoredCart.variantid;
            values.quantity = getStoredCart.quantity;
            values.hasdiscount = getStoredCart.discountactive ? 1 : 0;
            if (getStoredCart.discountactive == 1) {
              values.discount = getStoredCart.discount;
            }

            props.addCart(sessionobj, values, (res) => {
              let cartVal = {};
              props.storeCart(cartVal);
              if (res.success == 0) {
                history.push("/");
              } else {
                props.fetchCart(sessionobj);
                history.push("/cart");
              }
            });
          } else {
            let cartVal = {};
            props.storeCart(cartVal);
            history.push("/");
          }
        }
      );
    } else {
      history.push("/");
    }
  };

  const forgotPasswordClick = () => {
    props.isForgotPasswordOpen(true);
  };

  const responseFacebook = (res) => {
    //console.log("res", res);
    //debugger;
    if (res && res.userID) {
      if (res.email) {
        // if user email available
        let uniqueId = res.userID;
        let email = res.email;
        props.socialSignupAvailable(res.email, (response) => {
          if (response.success == 1) {
            socialLogin(email, uniqueId, "fb");
          } else {
            let name = res.name;
            let nameArr = name.split(" ");
            var user = {};
            user.email = email;
            user.socialid = uniqueId;
            user.display_name = name;
            user.first_name = nameArr.length > 0 ? nameArr[0] : "";
            user.last_name = nameArr.length >= 1 ? nameArr[1] : "";
            user.name = name;
            socialLoginRegister(user, "fb");
          }
        });
      } else {
        let uniqueId = res.userID;
        props.validateSocialId(uniqueId, (response) => {
          if (response.success == 1 && response.email) {
            socialLogin(response.email, uniqueId, "fb");
          } else {
            let name = res.name;
            let nameArr = name.split(" ");
            let email = randomString(12) + "@shopez.social";
            var user = {};
            user.email = email;
            user.socialid = uniqueId;
            user.display_name = name;
            user.first_name = nameArr.length > 0 ? nameArr[0] : "";
            user.last_name = nameArr.length >= 1 ? nameArr[1] : "";
            user.name = name;
            socialLoginRegister(user, "fb");
          }
        });
      }
    }
  };

  const socialLogin = (email, uniqueId, socialType) => {
    let values = {};
    values.email = email;
    values.uniqueid = uniqueId;
    values.type = socialType;
    values.password = SOCIALPWD;

    props.mainLogin(values, (response) => {
      if (response.success === 0) {
        toast.error(LocaleStrings.invalid_email_password);
      } else {
        toast.success(LocaleStrings.login_success);
        addtoCart(response.session);
      }
    });
  };

  const socialLoginRegister = (user, socialType) => {
    var params = {};
    params.email = user.email;
    params.logintype = socialType;
    params.display_name = user.display_name;
    params.first_name = user.first_name;
    params.last_name = user.last_name;
    params.name = user.name;
    params.new_password = SOCIALPWD;
    switch (socialType) {
      case "fb":
        params.facebookid = user.socialid;
        break;
      case "google":
        params.googleid = user.socialid;
        break;
      case "tw":
        params.twitterid = user.socialid;
        break;
    }

    props.addConsumer(params, (response) => {
      //console.log("response", response);
      if (response.success == 0) {
        toast.error(LocaleStrings.common_fail_message);
      } else {
        toast.success(LocaleStrings.signup_success);
        history.push("/");
      }
    });
  };

  const responseGoogle = (res) => {
    //console.log("res", res);
    if (res && res.profileObj) {
      let uniqueId = res.profileObj.googleId;
      let email = res.profileObj.email;
      props.socialSignupAvailable(email, (response) => {
        if (response.success == 1) {
          socialLogin(email, uniqueId, "google");
        } else {
          let name = res.profileObj.name;
          let nameArr = name.split(" ");
          var user = {};
          user.email = email;
          user.socialid = uniqueId;
          user.display_name = name;
          user.first_name = nameArr.length > 0 ? nameArr[0] : "";
          user.last_name = nameArr.length >= 1 ? nameArr[1] : "";
          user.name = name;
          socialLoginRegister(user, "google");
        }
      });
    } else {
      //toast.error(LocaleStrings.common_fail_message);
    }
  };

  const twitterHandler = (err, data) => {
    console.log(err, data);
    //debugger;
    if (data && data.user_id) {
      let uniqueId = data.user_id;
      let name = data.screen_name;
      if (data.email) {
        let email = data.email;
        props.socialSignupAvailable(email, (response) => {
          if (response.success == 1) {
            socialLogin(email, uniqueId, "tw");
          } else {
            let nameArr = name.split(" ");
            var user = {};
            user.email = email;
            user.socialid = uniqueId;
            user.display_name = name;
            user.first_name = nameArr.length > 0 ? nameArr[0] : "";
            user.last_name = nameArr.length >= 1 ? nameArr[1] : "";
            user.name = name;
            socialLoginRegister(user, "tw");
          }
        });
      } else {
        props.validateSocialId(uniqueId, (response) => {
          if (response.success == 1 && response.email) {
            socialLogin(response.email, uniqueId, "tw");
          } else {
            let nameArr = name.split(" ");
            let email = randomString(12) + "@shopez.social";
            var user = {};
            user.email = email;
            user.socialid = uniqueId;
            user.display_name = name;
            user.first_name = nameArr.length > 0 ? nameArr[0] : "";
            user.last_name = nameArr.length >= 1 ? nameArr[1] : "";
            user.name = name;
            socialLoginRegister(user, "tw");
          }
        });
      }
    } else {
      //toast.error(LocaleStrings.common_fail_message);
    }
  };
  console.log("getStoredCart", getStoredCart);
  return (
    <>
      <div className="bg-newarrival flex justify-center">
        {location && location.state && location.state.from ? (
          <div
            onClick={() => goToPreviousPath()}
            className="w-11/12  xl:w-4/12  lg:w-5/12 md:w-7/12 pt-30 flex cursor-pointer"
          >
            <img
              className={" h-6 w-6 "}
              src={"../profile_settings_images/back-button.svg"}
            />
            &nbsp;
            <a className="cursor-pointer text-vendorLink">Back to previous</a>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="bg-newarrival flex justify-center">
        <div className="bg-white w-11/12  xl:w-4/12  lg:w-5/12 md:w-7/12 mt-30 mb-30 rounded p-30 md:p-40">
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="text-primary font-normal text-3xl">
              {LocaleStrings.login}
            </div>
            <div className="text-hint font-normal text-base">
              {LocaleStrings.email_password}
            </div>
            <div className="pt-38">
              <label
                htmlFor="email"
                className="block text-xs md:text-base text-loginLabel"
              >
                {LocaleStrings.email}
              </label>
              <div className="mt-1">
                <Field
                  name="email"
                  placeholder={LocaleStrings.email}
                  type="text"
                  component={renderFieldText}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="pt-3.5">
              <label
                htmlFor="password"
                className="block text-xs md:text-base text-loginLabel"
              >
                {LocaleStrings.password}
              </label>
              <div className="mt-1 relative">
                <Field
                  name="password"
                  placeholder={LocaleStrings.password}
                  type="password"
                  show={show}
                  setShow={setShow}
                  component={renderFieldText}
                  classNameEye="absolute flex items-center text-sm top-3.5 right-3.5 cursor-pointer"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="pt-3.5">
              <button
                type="submit"
                className="rounded py-3 px-5 w-full flex items-center justify-center bg-btn text-white font-medium text-sm cursor-pointer"
              >
                {loading ? (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  ""
                )}
                <span className="text-sm md:text-base">
                  {LocaleStrings.login_btn_text}
                </span>
              </button>
            </div>
            <div className="pt-3.5 flex justify-center">
              <a
                onClick={() => forgotPasswordClick()}
                className="font-normal text-vendorLink text-base cursor-pointer"
              >
                {LocaleStrings.forgot_your_password}
              </a>
            </div>
            <div className="pt-3.5 flex justify-center">
              <a className="font-normal text-base text-primary">
                {LocaleStrings.dont_have_account_text}
              </a>
              &nbsp;
              <a
                onClick={() => history.push("/signup")}
                className="font-normal text-vendorLink text-base cursor-pointer"
              >
                {LocaleStrings.signup}
              </a>
            </div>
            <div className="pt-3.5 grid grid-cols-5">
              <div
                className="col-span-2 border-t"
                style={{
                  display: "flex",
                  clear: "both",

                  margin: "17px 0",
                }}
              ></div>
              <div className="text-center" style={{ margin: "6px 0px" }}>
                Or
              </div>
              <div
                className="col-span-2 border-t"
                style={{
                  display: "flex",
                  clear: "both",

                  margin: "17px 0",
                }}
              ></div>
            </div>
            <div className="pt-3.5 text-center text-primary font-normal text-base">
              {LocaleStrings.connect_social_media_text}
            </div>
            <div className="pt-3.5 text-center">
              <div className="grid grid-cols-2 gap-1">
                {/* <div className="flex justify-center">
                  <img src={AppleLogo} />
                </div> */}
                <div className="flex justify-center  cursor-pointer">
                  <TwitterLogin
                    authCallback={twitterHandler}
                    consumerKey={CONSUMER_KEY}
                    consumerSecret={CONSUMER_SECRET}
                    children={<img src={TwitterLogo} className="h-12 w-18" />}
                  />
                </div>
                <div className="flex justify-center cursor-pointer">
                  <GoogleLogin
                    clientId={GOOGLEID}
                    buttonText={""}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                    render={(renderProps) => (
                      <img
                        src={GoogleLogo}
                        className="h-12 w-18"
                        onClick={renderProps.onClick}
                      />
                    )}
                  />
                </div>
                {/* <div className="flex justify-center cursor-pointer">
                  <FacebookLogin
                    appId={FACEBOOKID}
                    //autoLoad={true}
                    size="large"
                    fields="name,email"
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <img
                        src={FacebookLogo}
                        className="h-12 w-18"
                        onClick={renderProps.onClick}
                        loading={fbloader}
                      />
                    )}
                    textButton={LocaleStrings.signup_facebook_option}
                  />
                </div> */}
              </div>
            </div>
          </form>
        </div>
      </div>
      {forgotPwdOpen ? <ForgotPassword /> : ""}
    </>
  );
}

const validate = (values) => {
  var errors = {};

  if (!values.email) {
    errors.email = LocaleStrings.login_form_validation_email_required;
  }

  if (!values.password) {
    errors.password = LocaleStrings.login_form_validation_password_required;
  }
  return errors;
};

const mapStateToProps = (state) => ({
  // isLoggedIn: state.isLoggedIn,
  session: state.session,
  deviceSize: state.deviceSize,
  localLanguage: state.localLanguage,
  forgotPwdOpen: state.forgotPwdOpen,
  getStoredCart: state.getStoredCart,
  banAccount: state.banAccount,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      mainLogin,
      resetStore,
      isForgotPasswordOpen,
      validateSocialId,
      socialSignupAvailable,
      addConsumer,
      addCart,
      fetchCart,
      validateUserCart,
      storeCart,
      banAction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "LoginForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Signin)
);
