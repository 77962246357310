import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchHomePageData } from "../actions";

import Banner from "./banner";
import Category from "./category";
import NewArrival from "./new-arrival";
import GoTop from "../../../components/back-top";
function Home(props) {
  const { isLoggedIn, session } = props;
  const [scrollPosition, setSrollPosition] = useState(0);
  const [showGoTop, setshowGoTop] = useState("goTopHidden");

  useEffect(() => {
    // props.fetchProducts(session, (res) => {});
    props.fetchHomePageData(session, (res) => {});
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleVisibleButton);
  }, []);

  const handleScrollUp = () => {
    //refScrollUp.current.scrollIntoView({ behavior: "smooth" });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleVisibleButton = () => {
    const position = window.pageYOffset;

    setSrollPosition(position);

    if (position > 500) {
      return setshowGoTop("goTop");
    } else if (position < 500) {
      return setshowGoTop("goTopHidden");
    }
  };

  return (
    <>
      {/* Ads */}
      <Banner />
      {/* Categories */}
      <Category />
      {/* New Arrival */}
      <NewArrival />
      <GoTop showGoTop={showGoTop} scrollUp={handleScrollUp} />
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchHomePageData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
