import {
  MYORDER_LIST,
  ORDER_DETAILS,
  RATE_PRODUCT_MODAL,
  RETURN_REPLACE_MODAL,
  ORDER_CANCEL_MODAL,
  ORDER_REPLACE,
  MASTER_ORDER_DETAILS,
} from "../actions";

export function myOrder(state = {}, action) {
  switch (action.type) {
    case MYORDER_LIST:
      return action.payload;
    default:
      return state;
  }
}

export function orderDetails(state = {}, action) {
  switch (action.type) {
    case ORDER_DETAILS:
      return action.payload;
    default:
      return state;
  }
}

export function rateProduct(state = false, action) {
  switch (action.type) {
    case RATE_PRODUCT_MODAL:
      return action.payload;
    default:
      return state;
  }
}

export function returnReplaceModal(state = false, action) {
  switch (action.type) {
    case RETURN_REPLACE_MODAL:
      return action.payload;
    default:
      return state;
  }
}

export function orderCancelModal(state = false, action) {
  switch (action.type) {
    case ORDER_CANCEL_MODAL:
      return action.payload;
    default:
      return state;
  }
}

export function orderReplaceVariant(state = [], action) {
  switch (action.type) {
    case ORDER_REPLACE:
      return action.payload;
    default:
      return state;
  }
}

export function orderMasterDetails(state = {}, action) {
  switch (action.type) {
    case MASTER_ORDER_DETAILS:
      return action.payload;
    default:
      return state;
  }
}
