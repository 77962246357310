import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import LocaleStrings from "../../../languages";
import {
  FILES_URL,
  APP_API_KEY,
  classNames,
} from "../../../components/Common/constant";
import { ProductTextLocalized } from "../../../components/Common/product-model";
import { fetchSettings } from "../../../actions/index";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NextArrow from "../../../assets/images/next.svg";

function SampleNextArrow(props) {
  const { currentSlide, className, style, onClick, categories, catPerItem } =
    props;

  if (categories.length - currentSlide <= catPerItem) {
    return false;
  } else {
    return (
      <div className="slide-arrow next-arrow cursor-pointer" onClick={onClick}>
        <img src={NextArrow} />
      </div>
    );
  }
}

function SamplePrevArrow(props) {
  const { currentSlide, className, style, onClick, categories } = props;

  if (currentSlide === 0) {
    return false;
  } else {
    return (
      <div className="slide-arrow prev-arrow cursor-pointer" onClick={onClick}>
        <img src={NextArrow} />
      </div>
    );
  }
}

function Category(props) {
  const history = useHistory();
  const { appSettings, localLanguage, deviceSize } = props;
  const catPerItem =
    deviceSize && deviceSize.width && deviceSize.width > 1440
      ? 8
      : deviceSize &&
        deviceSize.width &&
        deviceSize.width >= 1366 &&
        deviceSize.width <= 1440
      ? 8
      : deviceSize.width >= 1024 && deviceSize.width < 1366
      ? 8
      : deviceSize.width >= 768 && deviceSize.width < 1024
      ? 8
      : 8;
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: catPerItem,
    slidesToScroll: catPerItem,
    initialSlide: 0,
    className: "center",
    swipeToSlide: false,
    arrows: deviceSize.isMobile ? false : true,
    prevArrow: (
      <SamplePrevArrow
        categories={appSettings.categories}
        catPerItem={catPerItem}
      />
    ),
    nextArrow: (
      <SampleNextArrow
        categories={appSettings.categories}
        catPerItem={catPerItem}
      />
    ),
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: catPerItem,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: catPerItem,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: catPerItem,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: catPerItem,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 6.5,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 5.5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
        },
      },
    ],
  };

  //console.log("appSettings", appSettings);

  const viewCategory = (id) => {
    //props.fetchSettings(props.session, (res) => {});
    // if (id) {
    //   history.push("/view-category/" + id);
    // } else {
    //   history.push("/view-category");
    // }
  };

  const catRender = () => {
    let featuredCat = _.filter(appSettings.categories, function (o) {
      return o.isfeatured == 1;
    });
    return featuredCat.map(function (cat) {
      return (
        <a
          className="flex-item item cursor-pointer categorybox focus:outline-none"
          href={`/view-category/${cat.id}`}
        >
          <div className="flex justify-center items-center">
            <div className="text-center">
              <img
                src={`${FILES_URL}/vendor/productcategories/${cat.image}?api_key=${APP_API_KEY}`}
                className="w-50 lg:w-100 h-50 lg:h-100 rounded-full"
              />
            </div>
          </div>
          <div className="flex items-center justify-center text-numbid text-11 md:text-sm lg:text-base font-medium  lg:font-bold mt-1">
            <span className="text-center">
              {ProductTextLocalized(
                cat.categoryname_en,
                cat.categoryname_ar,
                localLanguage
              )}
            </span>
          </div>
        </a>
      );
    });
  };
  return !deviceSize.isMobile ? (
    <div className="grid grid-cols-2 gap-1 bg-white custom_container mb-7">
      {/* <div className="text-left text-primary text-xl font-normal">
        {LocaleStrings.categories}
      </div>
      <div className="flex items-center justify-end">
        <a
          className="text-link text-base cursor-pointer hover:text-ads focus:outline-none"
          href={`/view-category`}
        >
          {LocaleStrings.show_all}
        </a>
      </div>
      <div className="col-span-2 mt-1 border-b"></div> */}

      <div className="col-span-2 w-full category">
        {appSettings &&
        appSettings.categories &&
        appSettings.categories.length > 0 ? (
          <Slider {...settings}>{catRender()}</Slider>
        ) : (
          ""
        )}
      </div>
    </div>
  ) : (
    <div className="bg-white py-3.5 md:py-5 px-10">
      {/* <div className="grid grid-cols-2 gap-1">
        <div className="text-left text-primary text-base font-normal">
          {LocaleStrings.categories}
        </div>
        <div className="flex items-center justify-end">
          <a
            className="text-link text-sm cursor-pointer hover:text-ads focus:outline-none"
            href={`/view-category`}
            onClick={() => viewCategory("")}
          >
            {LocaleStrings.show_all}
          </a>
        </div>
      </div> */}

      {/* <div className="flex-wrapper flex-nowrap overflow-x-auto no-scrollbar">
        {appSettings &&
        appSettings.categories &&
        appSettings.categories.length > 0
          ? catRender()
          : ""}
      </div> */}
      <div className="col-span-2 w-full pt-2">
        {appSettings &&
        appSettings.categories &&
        appSettings.categories.length > 0 ? (
          <Slider {...settings}>{catRender()}</Slider>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
  deviceSize: state.deviceSize,
  appSettings: state.appSettings,
  localLanguage: state.localLanguage,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchSettings }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Category);
